import React from "react";
import { Menu, Tree } from "antd";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import {
  NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";
import IntlMessages from "../../util/IntlMessages";
import { isAdmin } from "../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { NotificationContainer } from "react-notifications";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import _ from "lodash";
import { setView } from "../../appRedux/actions";
import { ReactComponent as HomeIcon } from "../../assets/vendors/home.svg";
import { ReactComponent as ExploreIcon } from "../../assets/vendors/explore.svg";
import { ReactComponent as LayoutIcon } from "../../assets/vendors/layout.svg";
import { ReactComponent as RxnEditIcon } from "../../assets/vendors/rxn-edit.svg";
import { ReactComponent as StructureEditIcon } from "../../assets/vendors/structure-edit.svg";
import { ReactComponent as ProjectIcon } from "../../assets/vendors/project.svg";
import { ReactComponent as UploadIcon } from "../../assets/vendors/upload.svg";
import { ReactComponent as DocumentIcon } from "../../assets/vendors/document.svg";

import "./style.css";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { InfoCircleOutlined } from "@ant-design/icons";

const SidebarContent = (props) => {
  const { navStyle, themeType } = useSelector(({ settings }) => settings);

  const pathname = useSelector(({ common }) => common.pathname);

  const { sidebarCollapsed, setSidebarCollapsed } = props;

  const roles = useGetRoles();

  const dispatch = useDispatch();

  const { currentWidgets, currentPageName, currentView, currentDatabase } =
    useSelector(({ auth }) => auth);

  const getNoHeaderClass = (navStyle) => {
    if (
      navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
      navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
    ) {
      return "gx-no-header-notifications";
    }
    return "";
  };

  const selectedKeys = pathname.substr(1);
  const defaultOpenKeys = selectedKeys.split("/")[1];

  const onClickMenuItem = (item) => {
    dispatch(setView({ pageName: item?.title }));
  };

  const renderTitle = (item) => {
    const isActive =
      item?.title === currentView
        ? true
        : !currentView
        ? item?.content?.selected
        : false;

    return (
      <div
        className={isActive ? "tree-icon active" : "tree-icon"}
        onClick={() => onClickMenuItem(item)}
      >
        <i className="icon icon-radiobutton" />
        <span>{item?.title}</span>
        {isActive ? <span>&nbsp;(Active)</span> : null}
      </div>
    );
  };

  const renderSearchActions = (item) => {
    const isActive =
      item?.title === currentView
        ? true
        : !currentView
        ? item?.content?.selected
        : false;

    return (
      <Link
        key={item.key}
        className={`${
          isActive ? "tree-icon active" : "tree-icon"
        } tree-icon gx-text-primary gx-mr-2`}
        to={item.key}
      >
        {item.title}
      </Link>
    );
  };

  const treeData = _.map(currentWidgets, (k, v) => {
    return {
      title: v,
      key: v,
      content: k,
    };
  });

  const treeDataFiltered = _.filter(treeData, (data) => {
    if (currentPageName === "home" || currentPageName === "project") {
      if (data?.content?.pageName === currentPageName) return true;
    } else {
      if (
        data?.content?.pageName === currentPageName &&
        data?.content?.database === currentDatabase
      )
        return true;
    }

    return false;
  });

  return (
    <>
      <SidebarLogo
        sidebarCollapsed={sidebarCollapsed}
        setSidebarCollapsed={setSidebarCollapsed}
      />
      <div className="gx-sidebar-content">
        <div
          className={`gx-sidebar-notifications ${getNoHeaderClass(navStyle)}`}
        ></div>
        <CustomScrollbars className="gx-layout-sider-scrollbar">
          <Menu
            defaultOpenKeys={[defaultOpenKeys]}
            selectedKeys={[selectedKeys]}
            theme={themeType === THEME_TYPE_LITE ? "lite" : "dark"}
            mode="inline"
          >
            <Menu.Item key="home">
              <span
                onClick={() => {
                  window.location.assign(`/home`);
                }}
              >
                <HomeIcon
                  className={`icon ${
                    currentPageName === "home" ? "icon-active" : ""
                  }`}
                  alt="home navigation"
                />
                <span>
                  <IntlMessages id="sidebar.home" />
                </span>
              </span>
            </Menu.Item>

            {isAdmin({ roles, targetUrl: "/search" }) ? (
              <Menu.Item key="search" className="settings">
                <ExploreIcon
                  alt="Layout navigation"
                  className={`icon ${
                    _.includes(currentPageName, "search") ? "icon-active" : ""
                  }`}
                  style={{
                    height: "25px",
                    width: "25px",
                    marginLeft: "-2px",
                  }}
                  onClick={() => {
                    window.location.assign(`/search#Structure%20Search`);
                  }}
                />
                {!sidebarCollapsed ? (
                  <span>
                    <IntlMessages id="sidebar.searchPage" />
                  </span>
                ) : (
                  <Tree
                    treeData={[
                      {
                        title: "Generic Search",
                        key: "/search#Generic%20Search",
                      },
                      {
                        title: "Specific Search",
                        key: "/search#Specific%20Search",
                      },
                      {
                        title: "Advanced Search",
                        key: "/search#Advanced%20Search",
                      },
                      {
                        title: "Structure Search",
                        key: "/search#Structure%20Search",
                      },
                    ]}
                    titleRender={renderSearchActions}
                  />
                )}
                {/* <span
                  onClick={() => {
                    window.location.assign(`/search`);
                  }}
                >
                  {" "}
                  <ExploreIcon
                    className={`icon ${
                      _.includes(currentPageName, "search") ? "icon-active" : ""
                    }`}
                    alt="explore navigation"
                  />
                  <span></span>
                </span> */}
              </Menu.Item>
            ) : null}

            {isAdmin({ roles, targetUrl: "/compound-registration" }) ? (
              <Menu.Item key="compound-registration">
                <span
                  onClick={() => {
                    window.location.assign(`/compound-registration`);
                  }}
                >
                  <StructureEditIcon
                    className={`icon ${
                      currentPageName === "compound-registration"
                        ? "icon-active"
                        : ""
                    }`}
                    alt="structure navigation"
                  />
                  <span>
                    <IntlMessages id="sidebar.compoundRegistration" />
                  </span>
                </span>
              </Menu.Item>
            ) : null}

            {isAdmin({ roles, targetUrl: "/register-process" }) ? (
              <Menu.Item key="register-process">
                <span
                  onClick={() => {
                    window.location.assign(`/register-process`);
                  }}
                >
                  <RxnEditIcon
                    alt="Reaction navigation"
                    className={`icon ${
                      currentPageName === "register-process"
                        ? "icon-active"
                        : ""
                    }`}
                  />
                  <span>
                    <IntlMessages id="sidebar.registerProcess" />
                  </span>
                </span>
              </Menu.Item>
            ) : null}

            {isAdmin({ roles, targetUrl: "/projects" }) ? (
              <Menu.Item key="projects">
                <span
                  onClick={() => {
                    window.location.assign(`/projects`);
                  }}
                >
                  <ProjectIcon
                    alt="Project navigation"
                    className={`icon ${
                      currentPageName === "projects" ? "icon-active-stroke" : ""
                    }`}
                  />{" "}
                  <span>
                    <IntlMessages id="sidebar.projectPage" />
                  </span>
                </span>
              </Menu.Item>
            ) : null}

            {isAdmin({ roles, targetUrl: "/collection" }) ? (
              <Menu.Item key="collection">
                <span
                  onClick={() => {
                    window.location.assign(`/collection`);
                  }}
                >
                  <DocumentIcon
                    alt="Collection navigation"
                    className={`icon ${
                      currentPageName === "upload" ? "icon-active-stroke" : ""
                    }`}
                  />{" "}
                  <span>
                    <IntlMessages id="sidebar.collection" />
                  </span>
                </span>
              </Menu.Item>
            ) : null}

            {isAdmin({ roles, targetUrl: "/upload" }) ? (
              <Menu.Item key="upload">
                <span
                  onClick={() => {
                    window.location.assign(`/upload`);
                  }}
                >
                  <UploadIcon
                    alt="Upload navigation"
                    className={`icon ${
                      currentPageName === "upload" ? "icon-active-stroke" : ""
                    }`}
                  />{" "}
                  <span>
                    <IntlMessages id="sidebar.uploadData" />
                  </span>
                </span>
              </Menu.Item>
            ) : null}

            {!_.isEmpty(treeDataFiltered) && (
              <Menu.Item
                key="custom-layout"
                className="settings"
                style={!sidebarCollapsed ? { marginTop: "50px" } : {}}
              >
                <LayoutIcon
                  alt="Layout navigation"
                  className={`icon  ${
                    currentPageName === "upload" ? "icon-active-stroke" : ""
                  }`}
                />
                {!sidebarCollapsed ? (
                  <span style={{ marginTop: "10px !important" }}>
                    Select Layout
                  </span>
                ) : (
                  <Tree
                    treeData={[
                      ...treeDataFiltered,
                      { title: "Switch to Global", key: "Switch-To-Global" },
                    ]}
                    titleRender={renderTitle}
                  />
                )}
              </Menu.Item>
            )}
            <Menu.Item
              key="demo-page"
              style={!sidebarCollapsed ? { marginTop: "105px" } : {}}
            >
              <InfoCircleOutlined
                alt="Graph demo"
                className={`icon info-icon ${
                  currentPageName === "graph-demo" ? "icon-active-stroke" : ""
                }`}
                onClick={() => {
                  window.location.assign(`/graph-demo`);
                }}
              ></InfoCircleOutlined>
              <span
                style={{
                  marginLeft: 0,
                }}
              >
                Explore By Graph
              </span>
            </Menu.Item>

            {!sidebarCollapsed ? (
              <div
                style={{
                  margin: "10px 20px",
                }}
              >
                <Tree
                  treeData={[
                    ...treeDataFiltered,
                    { title: "Switch to Global", key: "Switch-To-Global" },
                  ]}
                  titleRender={renderTitle}
                />
              </div>
            ) : null}
          </Menu>
        </CustomScrollbars>

        <NotificationContainer />
      </div>
    </>
  );
};

export default React.memo(SidebarContent);
