import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import Header from "../Widget/Header";
import { ExpandOutlined, SettingOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import PanelPortal from "../../PanelPortal";
import GraphSettings from "./GraphSettings";
import GraphContent from "./GraphContent";
import { INNER_HEIGHT_OFFSET } from "../../../constants/Config";

function GraphWidget({
  id,
  sourceName,
  title,
  widget,
  widgetOption,
  compoundDetail,
  sampleDetail,
  pageType,
  conceptGraph,
}) {
  const [open, setOpen] = useState(true);

  const [nodes, setNodes] = useState([]);
  const [edges, setEdges] = useState([]);
  const [openSettings, setOpenSettings] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [currentNode, setCurrentNode] = useState();
  const [type, setType] = useState(true);

  const [graphSettings, setGraphSettings] = useState({});

  const [showPopup, setShowPopup] = useState(false);

  const mapping_name = {
    IS_SAMPLE_OF: "has sample",
    IS_INSTANCE_OF: "has quality",
  };

  const titleColor = widgetOption?.titleColor || widget?.titleColor;
  let backgroundColor = widgetOption?.backgroundColor;

  const initialFormValues = {
    type: true,
  };

  const onSaveSettings = (form) => {
    console.log(form);

    setType(form?.type);
  };

  // /**
  //  * Use recursive function,
  //  * and choose lowest level of connection and disregard next.
  //  */
  // const buildGraph = () => {
  //   traverseGraph(data);

  //   let nodes = [];
  //   let edges = [];

  //   const parentId = uuidv4();

  //   nodes.push({
  //     id: parentId,
  //     label: data?.compoundId,
  //     title: data?.compoundId,
  //   });

  //   _.forEach(data?.instances || [], (ins) => {
  //     const insId = uuidv4();

  //     nodes.push({
  //       id: insId,
  //       label: ins?.instanceId,
  //       title: ins?.instanceId,
  //     });

  //     edges.push({
  //       from: parentId,
  //       to: insId,
  //       label: mapping_name[ins?.instanceRelationship],
  //     });

  //     _.forEach(ins?.samples || [], (sample) => {
  //       const sampleId = uuidv4();

  //       nodes.push({
  //         id: sampleId,
  //         label: sample?.sampleId,
  //         title: sample?.sampleId,
  //       });

  //       edges.push({
  //         from: insId,
  //         to: sampleId,
  //         label: mapping_name[sample?.instanceRelationship],
  //       });
  //     });
  //   });

  //   setGraph({
  //     nodes,
  //     edges,
  //   });
  // };

  var nodeMap = new Map();

  useEffect(() => {
    // buildGraph();
    traverseGraph({
      graph: conceptGraph,
    });

    let localNodes = [];
    let localEdges = [];

    // eslint-disable-next-line no-unused-vars
    for (let [key, value] of nodeMap) {
      localNodes.push({
        id: value?.id,
        label: value?.id,
        type: value?.title,
      });

      _.forEach(value?.edges || [], (edge) => {
        if (
          (value?.edges || []).length > 1 &&
          (edge?.from || "").includes(".")
        ) {
          // if there are multiple edges, and its connected to instance we ignore it.
        } else {
          localEdges.push({
            ...edge,
            label: mapping_name[edge?.label],
          });
        }
      });
    }

    setNodes(localNodes);
    setEdges(localEdges);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conceptGraph]);

  const removeNode = (targetNode) => {
    const newNodes = _.filter(nodes, (node) => node?.id !== targetNode);
    const newEdges = _.filter(
      edges,
      (edge) => edge?.from !== targetNode && edge?.to !== targetNode
    );

    setNodes(newNodes);
    setEdges(newEdges);
  };

  // const graph = {
  //   nodes: [
  //     { id: 1, label: "Node 1", title: "node 1 tootip text" },
  //     { id: 2, label: "Node 2", title: "node 2 tootip text" },
  //     { id: 3, label: "Node 3", title: "node 3 tootip text" },
  //     { id: 4, label: "Node 4", title: "node 4 tootip text" },
  //     { id: 5, label: "Node 5", title: "node 5 tootip text" },
  //   ],
  //   edges: [
  //     { from: 1, to: 2 },
  //     { from: 1, to: 3 },
  //     { from: 2, to: 4 },
  //     { from: 2, to: 5 },
  //   ],
  // };

  const options = {
    layout: {
      hierarchical: type,
    },
    edges: {
      color: "#000000",
    },
  };

  const events = {
    select: function (event) {
      // var { nodesArray } = event;
    },
    doubleClick: function (event) {
      setOpenDetail(true);
      setCurrentNode(event?.nodes[0]);
    },
    oncontext: function (event) {
      console.log("right click", event);
      // removeNode(event?.nodes[0]);
    },
  };

  /**
   * Traverse through graph and build graph data structure.
   * @param {*} graph
   */
  const traverseGraph = ({ graph = {}, parentGraph = {} }) => {
    // Find child array.
    if (_.isArray(graph?.samples) && graph.samples.length) {
      _.forEach(graph?.samples, (gSample) => {
        traverseGraph({
          graph: Object.assign({}, gSample),
          parentGraph: Object.assign({}, graph),
        });
      });
    } else if (_.isArray(graph?.instances) && graph?.instances.length) {
      _.forEach(graph?.instances, (gInstance) => {
        traverseGraph({
          graph: Object.assign({}, gInstance),
          parentGraph: Object.assign({}, graph),
        });
      });
    }

    // Add nodes & edges
    if (graph?.compoundId) {
      nodeMap.set(graph?.compoundId, {
        ...(nodeMap.get(graph?.compoundId) || {}),
        id: graph?.compoundId,
        type: "compound",
        edges: [...(nodeMap.get(graph?.compoundId || {})?.edges || [])],
      });
      // nodes.push({
      //   id: graph?.compoundId,
      //   label: graph?.compoundId,
      //   type: "compound",
      // });
    } else if (graph?.sampleId) {
      nodeMap.set(graph?.sampleId, {
        ...(nodeMap.get(graph?.sampleId) || {}),
        id: graph?.sampleId,
        type: "sample",
        edges: [
          ...(nodeMap.get(graph?.sampleId || {})?.edges || []),
          {
            from:
              parentGraph?.compoundId ||
              parentGraph?.instanceId ||
              parentGraph?.sampleId,
            to: graph?.sampleId,
            label: graph?.instanceRelationship,
          },
        ],
      });

      // nodes.push({
      //   id: graph?.sampleId,
      //   label: graph?.sampleId,
      //   type: "sample",
      // });

      // edges.push({
      //   from:
      //     parentGraph?.compoundId ||
      //     parentGraph?.instanceId ||
      //     parentGraph?.sampleId,
      //   to: graph?.sampleId,
      //   label: graph?.instanceRelationship,
      // });
    } else if (graph?.instanceId) {
      nodeMap.set(graph?.instanceId, {
        ...(nodeMap.get(graph?.instanceId) || {}),
        id: graph?.instanceId,
        type: "instance",
        edges: [
          ...(nodeMap.get(graph?.instanceId || {})?.edges || []),
          {
            from:
              parentGraph?.compoundId ||
              parentGraph?.instanceId ||
              parentGraph?.sampleId,
            to: graph?.instanceId,
            label: graph?.instanceRelationship,
          },
        ],
      });

      // nodes.push({
      //   id: graph?.instanceId,
      //   label: graph?.instanceId,
      //   type: "instance",
      // });

      // edges.push({
      //   from:
      //     parentGraph?.compoundId ||
      //     parentGraph?.instanceId ||
      //     parentGraph?.sampleId,
      //   to: graph?.instanceId,
      //   label: graph?.instanceRelationship,
      // });
    }

    // return { nodes, edges };
  };

  const renderActionButtons = (
    <>
      <Popover content="Open settings">
        <SettingOutlined onClick={setOpenSettings} />
      </Popover>

      <Popover content="Open as popup" className="gx-ml-2">
        <ExpandOutlined onClick={setShowPopup} />
      </Popover>
    </>
  );

  return (
    <>
      <div
        className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 double-width ${
          open ? "isOpen" : ""
        }
`}
        key={uuidv4()}
      >
        <Header
          title={title}
          titleColor={titleColor}
          backgroundColor={backgroundColor}
          open={open}
          setOpen={setOpen}
          child={renderActionButtons}
        />
        {open ? (
          <>
            {showPopup ? null : (
              <div
                style={{
                  height: "500px",
                }}
              >
                <GraphContent
                  nodes={nodes}
                  edges={edges}
                  options={options}
                  events={events}
                />
              </div>
            )}
          </>
        ) : null}
      </div>

      <PanelPortal
        id="graph-settings"
        showPanel={openSettings}
        onDeletePanel={() => {
          setOpenSettings(false);
        }}
        title="Graph Settings"
      >
        {openSettings ? (
          <GraphSettings
            onFinish={onSaveSettings}
            initialValues={initialFormValues}
          />
        ) : null}
      </PanelPortal>

      <PanelPortal
        id="graph-details"
        showPanel={openDetail}
        onDeletePanel={() => {
          setOpenDetail(false);
        }}
        title="Graph Detail"
      >
        {openDetail ? <div>Id: {currentNode}</div> : null}
      </PanelPortal>

      <PanelPortal
        id="graph-popup"
        showPanel={showPopup}
        onDeletePanel={() => {
          setShowPopup(false);
        }}
        defaultWidth={window.innerWidth - INNER_HEIGHT_OFFSET}
        defaultHeight={window.innerHeight - INNER_HEIGHT_OFFSET}
      >
        {showPopup ? (
          <GraphContent
            nodes={nodes}
            edges={edges}
            options={options}
            events={events}
          />
        ) : null}
      </PanelPortal>
    </>
  );
}

export default GraphWidget;
