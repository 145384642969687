/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PT from "prop-types";
import CircularProgress from "components/CircularProgress";
import { Button, Drawer, Form, Popconfirm, Table } from "antd";
import Column from "antd/lib/table/Column";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import { TABLE_MAX_HEIGHT } from "../../../constants/Config";
import { useMsal } from "@azure/msal-react";
import { getGroups } from "../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import RolesForm from "./RolesForm";
import { RequestAccessToken } from "../../../util/Graph";

const RolesSettings = ({
  widgetName,
  loading,
  roles,
  onSave,
  title,
  buttonTitle = "Add New Role",
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentEditingObj, setCurrentEditingObj] = useState();
  const [accessToken, setAccessToken] = useState(null);
  const { accounts, instance } = useMsal();
  const { groups } = useSelector(({ graph }) => graph);

  const dispatch = useDispatch();

  const removeRole = (id) => {
    const newRoles = roles.filter((item) => item?.id !== id);
    onSave(newRoles, widgetName);
  };

  const editRole = (cur) => {
    setShowModal(true);
    setEditMode(true);
    setCurrentEditingObj(cur);
  };

  const renderActionButtons = (p, record) => {
    const { id } = record;
    return (
      <>
        <EditTwoTone
          className="gx-ml-2"
          twoToneColor="#52c41a"
          onClick={() => editRole(record)}
        />

        <Popconfirm
          title="Are you sure to delete this role?"
          onConfirm={() => removeRole(id)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteTwoTone
            className=" gx-ml-3 gx-pointer"
            twoToneColor="#f44336"
          />
        </Popconfirm>
      </>
    );
  };

  const onSaveRole = (props) => {
    if (editMode) {
      const newRoles = _.map(roles, (option) => {
        if (option?.id !== props?.id) return option;

        return {
          ...option,
          ...props,
        };
      });

      onSave(newRoles, widgetName);
    } else {
      const { name, displayName, group } = props;

      const groupName = _.find(groupsOptions, { value: group });

      const targetRole = {
        id: group,
        name,
        displayName,
        groupName: groupName?.label,
        registrationDatabases: [],
        processRegistrationDatabases: [],
        structureTypeAccess: [],
        pagesAccess: [],
        access: [],
      };

      onSave([...roles, targetRole], widgetName);
    }

    setShowModal(false);
  };

  const securityGroups = _.filter(groups, (group) => group?.securityEnabled);
  const groupsOptions = _.map(securityGroups, (group) => ({
    label: group?.displayName,
    value: group?.id,
  }));

  useEffect(() => {
    RequestAccessToken({ accounts, instance, setAccessToken });
  }, []);

  useEffect(() => {
    if (accessToken) {
      dispatch(getGroups(accessToken));
    }
  }, [accessToken]);

  return (
    <>
      <h3>{title}: </h3>
      {loading && <CircularProgress />}
      <Form className="gx-ml-3 gx-mt-3">
        <Form.Item>
          <Table
            pagination={false}
            dataSource={roles}
            size="small"
            scroll={{ y: TABLE_MAX_HEIGHT, x: true }}
          >
            <Column title="Group Name" key="groupName" dataIndex="groupName" />
            <Column title="Name" key="name" dataIndex="name" />
            <Column
              title="Display Name"
              key="displayName"
              dataIndex="displayName"
            />
            <Column title="Actions" key="delete" render={renderActionButtons} />
          </Table>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        size="small"
        className="gx-mt-2"
        onClick={() => setShowModal(true)}
      >
        {buttonTitle}
      </Button>

      <Drawer
        title={`${editMode ? "Edit" : "Add New"} Role`}
        open={showModal}
        onClose={() => {
          setShowModal(false);
          setEditMode(false);
        }}
        okText="Save"
      >
        <RolesForm
          onFinish={onSaveRole}
          setShowModal={setShowModal}
          groupsOptions={groupsOptions}
          showModal={showModal}
          editMode={editMode}
          currentEditingObj={currentEditingObj}
        />
      </Drawer>
    </>
  );
};

export default RolesSettings;

RolesSettings.defaultProps = {
  widgetName: "",
  roles: [],
  loading: false,
};

RolesSettings.propTypes = {
  widgetName: PT.string,
  roles: PT.arrayOf(PT.shape()),
  loading: PT.bool,
  onSave: PT.func.isRequired,
};
