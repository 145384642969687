import React from "react";

import { getParent } from "../../util/Widget";
import pluralize from "pluralize";
import { Popover, Spin, Table, Tag } from "antd";
import { renderImage } from "../SearchPage/helper";
import _ from "lodash";
import ImageCarousel from "../Registration/ImageCarousel";
import { getCompoundTypeFromId } from "../../util/url";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import { getImageFromRmoId } from "../../appRedux/services/Search";
import PopoverImage from "../DetailPage/Widget/RowArray/PopoverImage";
import {
  EXPLORE_DOCUMENT_OPERATORS,
  EXPLORE_FULL_OPERATORS,
  EXPLORE_PAGE_TAB_OPTIONS,
} from "../../constants/Config";
import { Link } from "react-router-dom";
import { EditTwoTone } from "@ant-design/icons";

export const renderExplorePageCompoundTable = ({
  sources,
  dataSource = [],
  searchInput,
  field,
  filteredResult = [],
  setFilteredResult,
  filterValue,
  setFilterValue,
  tableKey,
  mapImages,
  setMapImages,
  forceUpdate,
  fields = [],
  availableWidgetParams,
  total,
  page,
  perPage,
  setPage,
  setPerPage,
  currentTab,
  searchTerm,
  availableWidgets = [],
  loading,
  disableStructureImage = false,
  projects = [],
}) => {
  const structureImageColumn = disableStructureImage
    ? [
        {
          title: "Project Name",
          key: "projectName",
          width: 150,
          render: (p, cell) => {
            const projectId = cell?.compoundId || cell?.processId;

            const projectName = _.find(projects, { projectId }) || {};

            const shouldHighlight = _.lowerCase(
              projectName?.name || ""
            ).includes(_.lowerCase(searchTerm));

            return (
              <span style={shouldHighlight ? { color: "#389e0d" } : {}}>
                {projectName?.name || projectId}
              </span>
            );
          },
        },
      ]
    : [
        {
          title: "Structure",
          dataIndex: "structure",
          key: "structure",
          width: 150,
          render: (_, { compoundId, processId }) => {
            const targetId = compoundId || processId;

            return (
              <>
                {mapImages[targetId] === "not-found" ? (
                  <span
                    className="gx-text-danger"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    Structure not found
                  </span>
                ) : mapImages[targetId] ? (
                  <div className="search-page-image-preview">
                    <ImageCarousel
                      structureImage={[mapImages[targetId]]}
                      noBorder
                      setVisible={() => {}}
                    />
                  </div>
                ) : (
                  <Spin />
                )}
              </>
            );
          },
        },
      ];

  return (
    <div>
      <div className="gx-mb-2" style={{ textAlign: "right" }}>
        <span className="gx-text-success">
          Total: {total} {pluralize("result", dataSource.length)}.
        </span>
        {!_.isEmpty(filterValue) ? (
          <span className="gx-ml-2 gx-text-secondary">
            Filtered by "{filterValue}": {filteredResult.length}{" "}
            {pluralize("result", filteredResult.length)}
          </span>
        ) : null}
      </div>
      <Table
        key={tableKey}
        className="gx-table-responsive"
        loading={loading}
        dataSource={dataSource}
        onChange={(page, filters, sorter, { currentDataSource }) => {
          setFilterValue((_.get(filters, "3") || [])[0]);
          setFilteredResult(currentDataSource);
          renderImage({
            currentPage: page.current,
            pageSize: page.pageSize,
            searchResults: currentDataSource,
            mapImages,
            setMapImages,
            forceUpdate,
          });
        }}
        columns={[
          ...structureImageColumn,
          {
            title: "Id",
            width: 150,
            render: (p, { sourceName, compoundId, processId }) => {
              const targetId = compoundId || processId;

              const isHighlighted = (targetId || "").includes(searchTerm);

              return (
                <>
                  <Link
                    to={`/${sourceName}/${getCompoundTypeFromId(
                      targetId,
                      currentTab
                    )}/${targetId}`}
                    replace
                    target="_blank"
                  >
                    <span
                      style={{
                        color: isHighlighted ? "#389e0d" : "#1677ff",
                      }}
                    >
                      {targetId}
                    </span>
                  </Link>
                </>
              );
            },
          },
          {
            title: "Source",
            dataIndex: "sourceName",
            // sorter: (a, b) =>
            //   (a?.sourceName || "").localeCompare(b?.sourceName || ""),
          },
          {
            title: "Data",
            render: (p, cell) => {
              const { parameters = {}, widgetType } = cell;

              // if (_.isEmpty(_.omit(parameters, ["options"]))) {
              //   return (
              //     <span className="gx-text-warning">
              //       You don't have access to this widget.
              //     </span>
              //   );
              // }

              return (
                <div>
                  {_.map(parameters, (item, key) => {
                    const targetParameter =
                      _.find(availableWidgetParams, { value: key }) || {};
                    let targetValue = targetParameter?.field || key;

                    try {
                      if (targetValue.includes("|")) {
                        targetValue = targetValue.split("|")[0];
                      }
                    } catch (error) {}

                    let targetItem = item;

                    if (typeof item === "object") {
                      targetItem = item?.id || "";
                    }

                    const regex = new RegExp(
                      `^\\d{4}-\\}d{2-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$`
                    );

                    if (regex.test(targetItem)) {
                      targetItem = moment(targetItem).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                    }

                    let isHighlighted = false;

                    try {
                      isHighlighted = _.includes(
                        (targetItem || "").toLowerCase(),
                        (searchTerm || "").toLowerCase()
                      );
                    } catch (error) {}

                    const label = findMappedDatabaseFieldName(
                      targetValue,
                      widgetType,
                      availableWidgets
                    );

                    return (
                      <>
                        {item ? (
                          <Popover
                            content={<span>{label || targetValue}</span>}
                          >
                            <Tag
                              key={uuidv4()}
                              style={{ whiteSpace: "break-spaces" }}
                              color={isHighlighted ? "#d9f7be" : "#f0f0f0"}
                            >
                              <span className="gx-text-black">
                                {targetItem}
                              </span>
                            </Tag>
                          </Popover>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              );
            },
            // filterDropdown: ({
            //   setSelectedKeys,
            //   selectedKeys,
            //   confirm,
            //   clearFilters,
            //   close,
            // }) => (
            //   <TableFilter
            //     searchInput={searchInput}
            //     dataIndex={"compoundId"}
            //     selectedKeys={selectedKeys}
            //     setSelectedKeys={setSelectedKeys}
            //     confirm={confirm}
            //     clearFilters={clearFilters}
            //     close={close}
            //   />
            // ),
            // filterIcon: (filtered) => (
            //   <SearchOutlined
            //     style={{ color: filtered ? "#1890ff" : undefined }}
            //   />
            // ),
            // onFilter: (value, record) => {
            //   let containsParameter = false;
            //   const parameters = record["parameters"] || {};

            //   _.forEach(parameters, (item) => {
            //     let targetItem = item;

            //     if (typeof item === "object") {
            //       targetItem = item["id"] || "";
            //     }

            //     if (
            //       _.isString(targetItem) &&
            //       (targetItem || "")
            //         .toLowerCase()
            //         .includes((value || "").toLowerCase())
            //     ) {
            //       containsParameter = true;
            //     }
            //   });

            //   return (
            //     containsParameter ||
            //     checkField(value, record["compoundId"]) ||
            //     checkField(value, record["sourceName"])
            //   );
            // },
          },
        ]}
        pagination={{
          total,
          pageSize: perPage,
          current: page,
          defaultCurrent: 1,
          pageSizeOptions: [5, 10, 15, 20],
          responsive: true,
          showSizeChanger: true,
          // showTotal: (t, range) => {
          //   return (
          //     <span>
          //       Total items: <span className="gx-text-primary">{t}</span>
          //     </span>
          //   );
          // },
          onChange: (newPage, newPageSize) => {
            if (newPage !== page) {
              setPage(newPage);
            }

            if (perPage !== newPageSize) {
              setPerPage(newPageSize);
            }
          },
        }}
      />
    </div>
  );
};

export const populateImageMapping = ({
  currentPage,
  pageSize,
  searchResults = [],
  mapImages,
  setMapImages,
  forceUpdate,
  isEntityIds = false,
}) => {
  const currentResult = searchResults || [];

  (currentResult || []).map(async (result) => {
    const { child } = result;

    const currentItem = _.isArray(child) && child.length > 0 ? child[0] : {};

    const sourceName = result?.source || result?.sourceName;
    const id =
      result?.rmoId ||
      result?.compoundId ||
      result?.processId ||
      result?.name ||
      currentItem?.rmoId ||
      currentItem?.compoundId ||
      currentItem?.name;
    const parentId =
      result?.rmoId ||
      getParent(result?.compoundId) ||
      getParent(result?.processId) ||
      getParent(result?.name) ||
      currentItem?.rmoId ||
      getParent(currentItem?.compoundId);
    const originalMaterial = result?.rmoId || currentItem?.rmoId ? false : true;

    if (isEntityIds) {
      let promises = [];

      _.forEach(result?.entityIds || [], async (item) => {
        // If image is already exists, skip pushing promise.
        if (_.isEmpty(mapImages[item])) {
          promises.push(getImageFromRmoId(item, true, sourceName));
        }
      });

      Promise.allSettled(promises).then((images) => {
        let newImage = {};
        _.forEach(images, (image, index) => {
          if (image.status === "fulfilled") {
            if (image.value?.data) {
              newImage[(result?.entityIds || [])[index]] = image.value?.data;
            }
          }

          if (image.status === "rejected") {
            newImage[(result?.entityIds || [])[index]] = "not-found";
          }
        });
        setMapImages(_.extend(mapImages, newImage));
        forceUpdate();
      });
    }

    if (parentId) {
      return getImageFromRmoId(parentId, originalMaterial, sourceName)
        .then((image) => {
          if (image.data) {
            setMapImages(
              _.extend(mapImages, {
                [id]: image.data,
              })
            );
          }
          forceUpdate();
        })
        .catch(() => {
          setMapImages(
            _.extend(mapImages, {
              [id]: "not-found",
            })
          );
          forceUpdate();
        });
    }
  });
};

const popoverContent = (id, source, componentType = null) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <PopoverImage rmoId={id} sourceName={source} />
      <span className="gx-text-primary" style={{ textAlign: "center" }}>
        {id}
      </span>

      {componentType ? (
        <span className="gx-text-primary" style={{ textAlign: "center" }}>
          {_.capitalize(componentType)}
        </span>
      ) : null}
    </div>
  );
};

export const renderExplorePageStructure = ({
  dataSource,
  mapImages,
  forceUpdate,
  setMapImages,
  loading,
  title,
  key,
}) => {
  return (
    <div>
      {/* <div className="gx-text-success gx-mb-2" style={{ textAlign: "right" }}>
        {dataSource.length} {pluralize("result", dataSource.length)}
      </div> */}
      <Table
        loading={loading}
        className="gx-table-responsive"
        dataSource={dataSource}
        columns={[
          {
            title: "Structure",
            dataIndex: "structure",
            key: "structure",
            width: 150,
            onCell: (record, index) => {
              return {
                rowSpan: record?.rowSpan || 0,
              };
            },
            render: (p, { child }) => {
              const currentItem =
                _.isArray(child) && child.length > 0 ? child[0] : {};

              const { rmoId } = currentItem;

              return (
                <>
                  {mapImages[rmoId] === "not-found" ? (
                    <span
                      className="gx-text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Structure not found
                    </span>
                  ) : mapImages[rmoId] ? (
                    <div
                      className="search-page-image-preview"
                      style={{
                        maxWidth: "200px",
                      }}
                    >
                      <ImageCarousel
                        structureImage={[mapImages[rmoId]]}
                        noBorder
                        setVisible={() => {}}
                        width={50}
                        height={50}
                      />
                    </div>
                  ) : (
                    <Spin />
                  )}
                </>
              );
            },
          },
          {
            title: "Database",
            dataIndex: "source",
            key: "source",
            width: 150,
            render: (p, { source, sourceName }) => {
              return (
                <span className="gx-text-success">{source || sourceName}</span>
              );
            },
          },
          {
            title,
            data: "id",
            key: "id",
            width: 150,
            render: (p, { source, child }) => {
              const currentItems =
                _.isArray(child) && child.length > 0 ? child : [child];

              const uniqItems = _.uniqBy(currentItems, [
                "compoundId",
                "processId",
              ]);

              return _.map(uniqItems, (currentItem = {}) => {
                const targetId =
                  _.get(currentItem, `${key}`) ||
                  _.get(currentItem, `compoundId`) ||
                  _.get(currentItem, `processId`);
                const pageType = key === "compoundId" ? "compound" : "process";
                const componentType = _.get(currentItem, "componentType");

                return (
                  <div>
                    <Popover
                      content={popoverContent(targetId, source, componentType)}
                    >
                      <Link
                        to={`/${source}/${pageType}/${targetId}`}
                        target="_blank"
                        replace
                      >
                        {targetId}{" "}
                        {componentType ? (
                          <span>({_.capitalize(componentType)})</span>
                        ) : null}
                      </Link>
                    </Popover>
                  </div>
                );
              });
            },
          },
        ]}
        onChange={(page, filters, sorted, { currentDataSource }) => {
          renderImage({
            currentPage: page.current,
            pageSize: page.pageSize,
            searchResults: currentDataSource,
            mapImages,
            setMapImages,
            forceUpdate,
          });
        }}
      />
    </div>
  );
};

/**
 * select options for select box according to current tab.
 * @param {String} tabName
 */
export const selectOptionsFromTab = (mainTab, tabName) => {
  let options = [];

  if (mainTab === "1") return EXPLORE_DOCUMENT_OPERATORS;

  switch (tabName) {
    case EXPLORE_PAGE_TAB_OPTIONS.ALL:
    case EXPLORE_PAGE_TAB_OPTIONS.DOCUMENT:
      options = EXPLORE_DOCUMENT_OPERATORS;
      break;
    default:
      options = EXPLORE_FULL_OPERATORS;
  }

  return options;
};

/**
 * Get total number of items from widgets
 */
export const getTotalNumber = (widgetIds) => {
  let total = 0;

  for (const [key, value] of Object.entries(widgetIds)) {
    if (key === "structures" || key === "processes") {
      total += (_.get(value, "hits") || []).length || 0;
    } else {
      total += (value || []).length || 0;
    }
  }

  return total;
};

export const getSvgFromXML = (xmlString) => {
  // Parse the XML string
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(xmlString, "text/xml");

  // Extract the <svg> element
  const svgElement = xmlDoc.querySelector("svg");

  // Convert the <svg> element back to a string
  const svgString = new XMLSerializer().serializeToString(svgElement);

  return svgString;
};

export const renderCurrentQuery = (queries, joinType, onClick) => {
  return (
    <Popover content="Edit Query">
      <div
        className="gx-pointer"
        onClick={onClick}
        style={{
          backgroundColor: "#fff",
          padding: "10px",
          borderRadius: "4px",
          color: "#535353",
        }}
      >
        <EditTwoTone twoToneColor="#52c41a" className="gx-mr-2" />
        <span>Where </span>
        {_.map(queries, (query, index) => (
          <span>
            <span style={{ color: "#003eb3" }}>{query?.field}</span>{" "}
            {_.lowerCase(query?.op)}{" "}
            <span style={{ color: "#ad4e00" }}>{query?.value}</span>{" "}
            {index !== queries.length - 1 ? _.capitalize(query?.joinType) : ""}{" "}
            {index !== queries.length - 1 ? (
              <span className="gx-mr-2">{joinType}</span>
            ) : null}
          </span>
        ))}
      </div>
    </Popover>
  );
};

export const mapKeys = (keys) => {
  return _.map(keys, (item) => {
    const { key } = item;

    const splitted = (key || "").split("-");

    if (splitted.length > 2) {
      return {
        ...item,
        widgetName: splitted[0],
        name: splitted[1],
        field: splitted[2],
      };
    }

    if (splitted.length === 2) {
      return {
        ...item,
        widgetName: splitted[0],
        name: splitted[1],
        field: splitted[1],
      };
    }

    return {
      ...item,
      widgetName: key,
      name: key,
      field: key,
    };
  });
};

/**
 * Find display name from databaseName,
 *
 */
export const findMappedDatabaseFieldName = (
  field,
  systemWidgetName,
  availableWidgets = []
) => {
  const currentWidget = _.find(availableWidgets, { value: systemWidgetName });

  if (currentWidget) {
    const rows = _.get(currentWidget, "widget.rows");

    const currentRow = _.find(rows || [], { name: field });

    return (currentRow || {})?.field;
  }

  return null;
};
