/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  forwardRef,
  useRef,
  useState,
  useImperativeHandle,
} from "react";
import PT from "prop-types";

import { Link } from "react-router-dom";
import { DeleteTwoTone } from "@ant-design/icons";
import ImageCarousel from "../../../../components/Registration/ImageCarousel";
import CompoundNotFound from "../../../../assets/vendors/notfound.png";
import { Form, Input, Select } from "antd";
import {
  additionalComponentTypeOptions,
  componentRoleOptions,
  NAMES_AND_SYNONYMS_WIDGET_METADATA,
} from "../../../../constants/Config";

import "./style.css";
import { getMedataApi } from "../../../../appRedux/services/Metadata";

const AdditionalComponentItem = forwardRef((props, ref) => {
  const {
    id,
    option,
    index,
    deleteAdditionalComponent,
    mainComponent,
    sourceName,
    editMode,
  } = props;

  const [metadata, setMetadata] = useState(null);
  const mainComponentOption = "%";
  const formRef = useRef();
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    if (!metadata && option?.compoundId && firstRender) {
      setFirstRender(false);
      getMedataApi(
        NAMES_AND_SYNONYMS_WIDGET_METADATA,
        option?.compoundId,
        sourceName,
        setMetadata
      );
    }
  }, []);

  const { description } = metadata?.parameters || {};

  useImperativeHandle(ref, () => ({
    saveItem() {
      return {
        id,
        compoundId: option?.compoundId,
        type: mainComponent
          ? option?.type
          : formRef.current?.getFieldValue("role") || option?.type,
        value: formRef.current?.getFieldValue("percentage") || option?.value,
        unit: additionalComponentTypeOptions[0].value,
      };
    },
    getPercentage() {
      return formRef.current?.getFieldValue("percentage") || option?.value;
    },
  }));

  return (
    <>
      <div className="card-item additional-component-card-item">
        <div className="card-item-field">
          {option?.imagePreview ? (
            <ImageCarousel
              structureImage={[option?.imagePreview]}
              setVisible={() => {}}
              width={100}
            />
          ) : (
            <img
              src={CompoundNotFound}
              alt="compound-not-found"
              width={50}
              height={50}
              className="compound-not-found gx-mb-4"
            />
          )}
        </div>
        <div className="additional-component-roles-wrapper card-item-value additional-component-card-value">
          {
            <div className="gx-mt-2 gx-d-justify-right">
              {!editMode && (
                <>
                  {!mainComponent && (
                    <DeleteTwoTone
                      twoToneColor="#f44336"
                      className="gx-ml-2"
                      onClick={() => deleteAdditionalComponent(index)}
                    />
                  )}
                </>
              )}
            </div>
          }

          <div className="additional-component-roles">
            <div className="card-item-field">
              <span>Compound ID</span>
            </div>
            <div>
              <Link
                className="gx-text-success"
                to={`/${sourceName}/compound/${option?.compoundId}`}
                replace
              >
                {option?.compoundId}
              </Link>
            </div>
          </div>

          {!editMode && (
            <div className="additional-component-roles detail">
              <div className="card-item-field">
                <span>Description</span>
              </div>
              <div className="gx-text-success">{description}</div>
            </div>
          )}

          {editMode ? (
            <Form ref={formRef}>
              <div className="additional-component-roles">
                <div className="card-item-value">
                  <span>Role:</span>
                </div>
                {mainComponent ? (
                  <div className="card-item-value">
                    <div className="gx-text-success">{option?.type}</div>
                  </div>
                ) : (
                  <Form.Item
                    name="role"
                    className="additional-component-select"
                  >
                    <Select
                      size="small"
                      className="custom-addtional-role"
                      defaultValue={option?.type}
                      options={componentRoleOptions}
                    ></Select>
                  </Form.Item>
                )}
              </div>
              <div className="additional-component-roles">
                <div className="card-item-value">
                  <span>Percent Composition:</span>
                </div>

                <Form.Item
                  name="percentage"
                  className="additional-component-input"
                  style={{ marginRight: "0" }}
                >
                  <Input
                    className="addtional-component-percentage-input"
                    size="small"
                    defaultValue={option?.value}
                  />
                </Form.Item>
              </div>
            </Form>
          ) : (
            <>
              <div className="additional-component-roles detail">
                <div className="card-item-value">
                  <span>Role:</span>
                </div>
                <div className="card-item-value">
                  <div className="gx-text-success">{option?.type}</div>
                </div>
              </div>
              <div className="additional-component-roles detail">
                <>
                  <div className="card-item-field">
                    <span>Percent Composition</span>
                  </div>
                  <div className="gx-d-justify-right">
                    <span className="gx-text-success">
                      {option?.value}
                      {mainComponentOption}
                    </span>
                  </div>
                </>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
});

AdditionalComponentItem.defaultProps = {
  option: {},
  index: 0,
  deleteAdditionalComponent: () => {},
  mainComponent: false,
  exceededPercent: false,
  sourceName: "",
  editMode: false,
};

AdditionalComponentItem.propTypes = {
  id: PT.string.isRequired,
  deleteAdditionalComponent: PT.func,
  option: PT.shape(),
  index: PT.number,
  mainComponent: PT.bool,
  onChangeForm: PT.func.isRequired,
  onSaveMainComponent: PT.func.isRequired,
  setEdit: PT.func.isRequired,
  exceededPercent: PT.bool,
  sourceName: PT.shape(),
  editMode: PT.bool,
  setEditMode: PT.func.isRequired,
};

export default AdditionalComponentItem;
