/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Layout, Popover } from "antd";

import { toggleCollapsedSideNav } from "../../appRedux/actions";
import UserInfo from "../../components/UserInfo";

import Auxiliary from "util/Auxiliary";

import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  TAB_SIZE,
} from "../../constants/ThemeSetting";
import { useDispatch, useSelector } from "react-redux";
import RoleChanger from "../../components/RoleChanger";

import "./style.css";
import { config } from "../../constants/Config";
import { getBuildId } from "../../appRedux/services/DetailPage";
import { Link } from "react-router-dom";
import {
  changeCurrentDocumentRole,
  changeCurrentRole,
  checkTabPermitted,
  getDocumentRole,
  getRole,
} from "../../util/auth";
import ExploreInput from "../../components/ExploreInput";

const { Header } = Layout;

const Topbar = () => {
  const { navStyle } = useSelector(({ settings }) => settings);
  const navCollapsed = useSelector(({ common }) => common.navCollapsed);
  const width = useSelector(({ common }) => common.width);
  const dispatch = useDispatch();
  const { roles = [], documentRoles = [] } = useSelector(({ auth }) => auth);
  const buildId = config.REACT_APP_BUILD_ID;
  const isDev = (config.SRD_API_URL || "").includes("dev");
  const [serverBuildId, setServerBuildId] = useState();
  const isSRD = window.location.href.includes(".chemantics.com");
  const isLocalHost = window.location.href.includes("localhost");
  const imageUrl =
    isSRD || isLocalHost ? "logo-chemantics.png" : "logo-act.png";

  useEffect(() => {
    if (!serverBuildId) {
      getBuildId(setServerBuildId);
    }
  }, []);

  const hasAccessToGenericSearch = checkTabPermitted(roles, "GENERIC_SEARCH");

  const shouldRenderSearchbox =
    hasAccessToGenericSearch && window.location.pathname !== "/search";

  return (
    <Header className={isDev ? "dev-env" : ""}>
      {navStyle === NAV_STYLE_DRAWER ||
      ((navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR) &&
        width < TAB_SIZE) ? (
        <div className="gx-linebar gx-mr-3">
          <i
            className="gx-icon-btn icon icon-menu"
            onClick={() => {
              dispatch(toggleCollapsedSideNav(!navCollapsed));
            }}
          />
        </div>
      ) : null}

      <Auxiliary>
        <Link
          to={`/home`}
          className="gx-site-logo gx-mr-4"
          style={{ justifyContent: "center" }}
        >
          <img alt="logo" src={`/assets/images/${imageUrl}`} width={75} />
        </Link>
      </Auxiliary>

      <Auxiliary>
        <Popover
          overlayClassName="gx-popover-horizantal"
          placement="bottomLeft"
          trigger="click"
          content={
            <>
              <li>Version: {`${serverBuildId?.buildId}.${buildId || 0}`}</li>
              <li>Build Date: {`${serverBuildId?.buildDate}`}</li>
            </>
          }
        >
          <span>About</span>
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </Popover>
      </Auxiliary>

      {isDev ? <span className="gx-ml-3">DEVELOPMENT ENVIRONMENT</span> : ""}

      {shouldRenderSearchbox ? <ExploreInput /> : null}

      <ul className="gx-header-notifications gx-ml-auto user-right-menu">
        {(documentRoles || []).length > 1 ? (
          <Auxiliary>
            {(documentRoles || []).length > 0 && (
              <li className="gx-user-nav">
                <RoleChanger
                  roles={documentRoles}
                  callbackFn={changeCurrentDocumentRole}
                  getRole={getDocumentRole}
                  title="Document Role"
                />
              </li>
            )}
          </Auxiliary>
        ) : null}

        <Auxiliary>
          {(roles || []).length > 0 && (
            <li className="gx-user-nav">
              <RoleChanger
                roles={roles}
                callbackFn={changeCurrentRole}
                getRole={getRole}
                title="Roles"
              />
            </li>
          )}
        </Auxiliary>
        <Auxiliary>
          <li className="gx-user-nav">
            <UserInfo roles={roles} />
          </li>
        </Auxiliary>
      </ul>
    </Header>
  );
};

export default Topbar;
