import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  LOOKUP_ADD_IMAGE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS,
  LOOKUP_COMPOSITION_TABLE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_SUCCESS,
} from "../../constants/ActionTypes";

import {
  SAFETY_SENTENCES_SAVED_DATA,
  SAFETY_SENTENCES_WIDGET,
} from "../../constants/Config";
import { getParent } from "../../util/Widget";
import {
  getCompoundConceptApi,
  getImageApi,
  getMetadataApi,
} from "../services/Lookup";

// TODO: DElete this code.
// function* fetchSmiles(action) {
//   try {
//     const image = yield call(getImageApi, {
//       ...action.payload,
//       id: getParent(action.payload.id),
//     });

//     if (image && image.data) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: image.data,
//           field: "image",
//         },
//       });
//     }
//   } catch (error) {}

//   try {
//     if (action.payload?.processData) {
//       const compoundInstances = yield call(getCompoundConceptApi, {
//         ...action.payload,
//         id: getParent(action.payload.id),
//       });
//       if (compoundInstances && compoundInstances.data) {
//         yield put({
//           type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//           payload: {
//             id: getParent(action.payload.id),
//             data: compoundInstances.data?.compoundInstances || [],
//             field: "compoundInstances",
//           },
//         });
//       }
//     }
//   } catch (error) {}

//   try {
//     const result = yield call(getSmilesApi, {
//       ...action.payload,
//     });
//     if (result.data) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: result?.data,
//           field: "smiles",
//         },
//       });

//       const elementalAnalysis = yield call(
//         elementalAnalysisRequest,
//         result.data
//       );

//       if (elementalAnalysis.data) {
//         yield put({
//           type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//           payload: {
//             id: action.payload.id,
//             data: elementalAnalysis?.data,
//             field: "elementalAnalysis",
//           },
//         });
//       }
//     }
//   } catch (error) {}

//   try {
//     const metadata = yield call(getMetadataApi, {
//       metadataName: NAMES_AND_SYNONYMS_WIDGET_METADATA,
//       ...action.payload,
//     });

//     if (metadata.data && metadata.data.length && metadata.data[0]?.parameters) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: metadata.data[0]?.parameters,
//           field: "metadata",
//         },
//       });
//     }
//   } catch (error) {}

//   try {
//     const metadata = yield call(getMetadataApi, {
//       metadataName: SAFETY_SENTENCES_WIDGET,
//       ...action.payload,
//       id: action.payload.id,
//     });

//     if (metadata.data && metadata.data.length && metadata.data[0]?.parameters) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: metadata.data[0]?.parameters,
//           field: "metadata",
//         },
//       });
//     }
//   } catch (error) {}

//   try {
//     const hskValue = yield call(getMetadataApi, {
//       metadataName: SAFETY_SENTENCES_SAVED_DATA,
//       ...action.payload,
//       id: action.payload.id,
//     });

//     if (hskValue.data && hskValue.data.length && hskValue.data[0]?.parameters) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: hskValue.data[0]?.parameters,
//           field: "hskValue",
//         },
//       });
//     }
//   } catch (error) {}

//   try {
//     const safetySentences = yield call(getMetadataApi, {
//       metadataName: SAFETY_SENTENCES_SAVED_DATA,
//       ...action.payload,
//       id: getParent(action.payload.id),
//     });

//     if (
//       safetySentences &&
//       safetySentences.data &&
//       safetySentences.data.length
//     ) {
//       yield put({
//         type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
//         payload: {
//           id: action.payload.id,
//           data: safetySentences.data[0],
//           field: "safetySentences",
//         },
//       });
//     }
//   } catch (error) {}
// }

function* fetchCompositionTableMetadata(action) {
  if (action.payload.widgetName) {
    try {
      const metadata = yield call(getMetadataApi, {
        metadataName: action.payload.widgetName,
        ...action.payload,
      });

      if (action.payload.isProcess) {
        if (metadata.data && metadata.data.parameters) {
          yield put({
            type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
            payload: {
              id: action.payload.id,
              data: metadata.data.parameters,
              field: "metadata",
            },
          });
        }
      } else {
        if (
          metadata.data &&
          metadata.data.length &&
          metadata.data[0]?.parameters
        ) {
          yield put({
            type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
            payload: {
              id: action.payload.id,
              data: metadata.data[0]?.parameters,
              field: "metadata",
            },
          });
        }
      }
    } catch (error) {}
  } else {
    /*
     * Fetch Molecular Weight from Compound Concept API
     */
    try {
      const compoundConcept = yield call(getCompoundConceptApi, {
        id: getParent(action.payload.id),
        sourceName: action.payload.sourceName,
      });

      if (compoundConcept && compoundConcept?.data) {
        const { molecularWeight } = compoundConcept?.data;

        yield put({
          type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
          payload: {
            id: action.payload.id,
            data: molecularWeight,
            field: "molecularWeight",
          },
        });
      }
    } catch (error) {
      yield put({
        type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
        payload: {
          id: action.payload.id,
          data: 0,
          field: "molecularWeight",
        },
      });
    }

    /*
     * Fetch widget data
     */
    // (action.payload.widgetNamesToResolve || []).forEach(function* (widgetName) {
    //   console.log('names', widgetName)
    //   const metadata = yield call(getMetadataApi, {
    //     metadataName: widgetName,
    //     ...action.payload,
    //   });

    //   if (metadata.data && metadata.data.length && metadata.data[0]?.parameters) {
    //     yield put({
    //       type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
    //       payload: {
    //         id: action.payload.id,
    //         data: metadata.data[0]?.parameters,
    //         field: "metadata",
    //       },
    //     });
    //   }
    // })

    /**
     * Fetch Image data
     */
    // try {
    //   const image = yield call(getImageApi, {
    //     ...action.payload,
    //     id: getParent(action.payload.id),
    //   });

    //   if (image && image.data) {
    //     yield put({
    //       type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
    //       payload: {
    //         id: action.payload.id,
    //         data: image.data,
    //         field: "image",
    //       },
    //     });
    //   }
    // } catch (error) {}

    /** Fetch Safety Sentences Data */
    try {
      const safetySentences = yield call(getMetadataApi, {
        metadataName: SAFETY_SENTENCES_SAVED_DATA,
        ...action.payload,
        id: getParent(action.payload.id),
      });

      if (
        safetySentences &&
        safetySentences.data &&
        safetySentences.data.length
      ) {
        yield put({
          type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
          payload: {
            id: action.payload.id,
            data: safetySentences.data[0],
            field: "safetySentences",
          },
        });
      }
    } catch (error) {}

    /** Fetch Safety Sentences Widget Data */
    try {
      const safetyWidgetData = yield call(getMetadataApi, {
        metadataName: SAFETY_SENTENCES_WIDGET,
        ...action.payload,
        id: getParent(action.payload.id),
      });

      if (
        safetyWidgetData &&
        safetyWidgetData.data &&
        safetyWidgetData.data.length
      ) {
        yield put({
          type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
          payload: {
            id: action.payload.id,
            data: safetyWidgetData.data[0],
            field: "safetyWidgetData",
          },
        });
      }
    } catch (error) {}
  }
}

function* saveImage(action) {
  if (action.payload?.imageData) {
    yield put({
      type: LOOKUP_COMPOSITION_TABLE_SUCCESS,
      payload: {
        id: action.payload?.id,
        data: action.payload?.imageData,
        field: "image",
      },
    });
  }
}

function* fetchImage(action) {
  try {
    const image = yield call(getImageApi, {
      ...action.payload,
      id: getParent(action.payload.id),
    });

    if (image && image.data) {
      yield put({
        type: LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS,
        payload: {
          id: action.payload.id,
          data: image.data,
          field: "image",
        },
      });
    }
  } catch (error) {}
}

export function* getLookupData() {
  yield takeEvery(
    LOOKUP_COMPOSITION_TABLE_REQUESTED,
    fetchCompositionTableMetadata
  );
}

export function* addImageToLookupTable() {
  yield takeLatest(LOOKUP_ADD_IMAGE_REQUESTED, saveImage);
}

export function* getLookupImage() {
  yield takeEvery(LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED, fetchImage);
}

export default function* rootSaga() {
  yield all([
    fork(getLookupData),
    fork(addImageToLookupTable),
    fork(getLookupImage),
  ]);
}
