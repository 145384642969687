import React, {
  useContext,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import { MainContext } from "../../containers/App";
import Header from "../../components/ExplorePage/Header";
import Auxiliary from "../../util/Auxiliary";
import { Col, Form, Row } from "antd";
import ExploreDetail from "../../components/ExplorePage/ExploreDetail";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchWidgetData,
  searchParameters,
} from "../../appRedux/actions/Explore";
import { filterByAuthorizedRoles } from "../../util/search";
import { getRole, getRoleId } from "../../util/auth";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import {
  getDataSources,
  searchForCompoundData,
  setCurrentWidgets,
} from "../../appRedux/actions";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_PAGE_SIZE,
  DOCUMENT_COLLECTION_OPTIONS,
  EXPLORE_PAGE_MAIN_TAB_OPTIONS,
  EXPLORE_PAGE_TAB_OPTIONS,
  SEARCH_CUTOFF_DEFAULT,
  SEARCH_DATASOURCES,
  SEARCH_OPERATOR_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
  USER_LAYOUT_SETTINGS,
} from "../../constants/Config";
import { paginate } from "../../util/generic";
import _ from "lodash";
import {
  getSystemWidget,
  getUserWidget,
  saveUserWidget,
} from "../../appRedux/services/SystemWidget";
import { useMsal } from "@azure/msal-react";
import { similarityOptions } from "../../components/SearchPage/helper";
import {
  searchForCompound,
  searchForProcess,
} from "../../appRedux/services/Search";
import { useLocation } from "react-router-dom";

const ExplorePage = () => {
  const dispatch = useDispatch();
  const { searchTerm, setSearchTerm, includeDocument, setIncludeDocument } =
    useContext(MainContext);

  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const [selectedDataSources, setSelectedDataSources] = useState([]);

  const { isInitialized, widgetIdsNormalized, widgetIds } = useSelector(
    ({ explore }) => explore
  );
  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const { dataSources } = useSelector(({ search }) => search);
  const roles = useGetRoles();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [cutoff, setCutoff] = useState(Number(SEARCH_CUTOFF_DEFAULT));
  const pageName = "search";

  const [currentMainTab, setCurrentMainTab] = useState(
    EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH
  );
  const [currentTab, setCurrentTab] = useState("");
  const formRef = useRef();

  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [documentWidgets, setDocumentWidgets] = useState([]);

  const [searchOps, setSearchOps] = useState([]);
  const [queryStructure, setQueryStructure] = useState(null);
  const [structureSearchResults, setStructureSearchResults] = useState([]);
  const [structureResultLoading, setStructureResultLoading] = useState(false);
  const [structureImage, setStructureImage] = useState(null);
  const [mapImages, setMapImages] = useState({});
  const [filteredSearchResults, setFilteredSearchResults] = useState([]);

  const [showAdvancedSearchForm, setShowAdvancedSearchForm] = useState(true);
  const [formItems, setFormItems] = useState([]);
  const [showStructureSearchForm, setShowStructureSearchForm] = useState(true);
  const [advancedSearchJoinType, setAdvancedSearchJoinType] = useState("AND");

  const [form] = Form.useForm();
  const matchSearch = Form.useWatch("searchOps", form);
  const userNameWithRole = `${userName}-${getRole()}`;
  // eslint-disable-next-line no-unused-vars
  const [currentSettings, setCurrentSettings] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  // Use location hash to detect current tab.
  const location = useLocation();

  useEffect(() => {
    const { hash } = location;

    const hashValue = (hash || "").slice(1);
    let mainTabHash = hashValue.split("-")[0];
    let subTabHash = "";
    try {
      subTabHash = hashValue.split("-")[1];
    } catch (error) {}

    // setDefaultActiveKey(mainTabHash);
    // setDefaultActiveSubKey(subTabHash);

    if (mainTabHash) {
      setCurrentMainTab(decodeURIComponent(mainTabHash));
    }

    if (subTabHash) {
      setCurrentTab(subTabHash);
    }
  }, [location]);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
  }, [dataWidgets]);

  useEffect(() => {
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
  }, [systemWidgets]);

  useEffect(() => {
    if (!documentWidgets.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setDocumentWidgets);
    }
  }, [documentWidgets]);

  let availableWidgets = [...systemWidgets, ...dataWidgets, ...documentWidgets];

  useEffect(() => {
    if (currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.ALL);
    }

    if (currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND);
    }

    if (currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES);
    }
  }, [currentMainTab]);

  useEffect(() => {
    getUserWidget(
      USER_LAYOUT_SETTINGS,
      userNameWithRole,
      (cur) => {
        dispatch(
          setCurrentWidgets({
            widgets: cur,
            pageName: pageName,
            database: "",
          })
        );

        setCurrentSettings(cur);
      },
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const listItems = [
    {
      label: "All",
      value: EXPLORE_PAGE_TAB_OPTIONS.ALL,
      includeDocument: true,
      target: "all",
    },
    {
      label: "Structures",
      value: EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES,
      includeDocument: true,
      target: "structures.hits",
    },
    {
      label: "Process Steps",
      value: EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STEPS,
      includeDocument: true,
      target: "processes.hits",
    },
    {
      label: "Compounds & Qualities",
      value: EXPLORE_PAGE_TAB_OPTIONS.COMPOUND,
      includeDocument: true,
      target: "compound",
    },
    {
      label: "Processes & Experiments",
      value: EXPLORE_PAGE_TAB_OPTIONS.PROCESS,
      includeDocument: true,
      target: "process",
    },
    {
      label: "Projects",
      value: EXPLORE_PAGE_TAB_OPTIONS.PROJECT,
      includeDocument: true,
      target: "project",
    },
    {
      label: "Documents",
      value: EXPLORE_PAGE_TAB_OPTIONS.DOCUMENT,
      includeDocument: true,
      target: "documents",
    },
  ];

  const listItemsSpecific = [
    {
      label: "Compounds & Qualities",
      value: EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND,
      includeDocument: false,
    },
    {
      label: "Processes & Experiments",
      value: EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS,
      includeDocument: false,
    },
    {
      label: "Projects",
      value: EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS,
      includeDocument: false,
    },
    {
      label: "Documents",
      value: EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_DOCUMENTS,
      includeDocument: true,
    },
    // {
    //   label: "Structures",
    //   value: EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES,
    //   includeDocument: true,
    // },
  ];

  const listItemsStructure = [
    {
      label: "Compound",
      value: EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES,
      includeDocument: true,
    },
    {
      label: "Process",
      value: EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE,
      includeDocument: true,
    },
  ];

  useEffect(() => {
    if (!searchOps.length) {
      getSystemWidget(SEARCH_OPERATOR_OPTIONS, setSearchOps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSelectedDataSources = (sources) => {
    saveUserWidget(
      SEARCH_DATASOURCES,
      userName,
      setSelectedDataSources,
      () => {},
      {
        option: sources,
      },
      false,
      null
    );
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const search = url.searchParams.get("search");

    if (search && search.length) {
      setSearchTerm(search);
    }
  }, [setSearchTerm]);

  useEffect(() => {
    const url = new URL(window.location.href);
    const include = url.searchParams.get("include");

    if (include === "true" || include === true) {
      setIncludeDocument(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      (dataSources || []).length &&
      isInitialized &&
      (widgetIdsNormalized || []).length
    ) {
      const fil = _.filter(
        widgetIdsNormalized,
        (item) => item?.type !== "system" || item?.type !== "user"
      );
      const paginated = paginate(fil, perPage, page);

      _.forEach(paginated, (widget, index) => {
        if (widget?.type && !_.isEmpty(widget?.id)) {
          dispatch(
            fetchWidgetData({
              widget,
              total: (paginated || []).length,
              currentIndex: index,
              availableWidgets,
              role: getRole(),
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    dataSources,
    isInitialized,
    page,
    perPage,
    widgetIdsNormalized,
  ]);

  useEffect(() => {
    if (
      searchTerm &&
      !isInitialized &&
      selectedDataSources &&
      selectedDataSources?.option &&
      (selectedDataSources?.option || []).length
    ) {
      const dataSources =
        (availableDataSources || []).length === 1
          ? availableDataSources[0]?.sourceName
          : selectedDataSources?.option;

      let includeDocument = true;

      if (
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS
      ) {
        includeDocument = false;
      }

      dispatch(
        searchParameters({
          searchTerm,
          dataSources,
          type: currentTab,
          includeDocument,
          searchOps: "CONTAINS",
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentTab,
    dispatch,
    includeDocument,
    isInitialized,
    searchTerm,
    selectedDataSources,
  ]);

  const availableDataSources = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "access"
  );

  useEffect(() => {
    getUserWidget(
      SEARCH_DATASOURCES,
      userName,
      setSelectedDataSources,
      false,
      () => {
        const sourcenames = _.map(
          availableDataSources,
          (item) => item?.sourceName
        );
        setSelectedDataSources({ option: sourcenames });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectedDataSourceOptions = selectedDataSources?.option;

  const filteredWidgets = _.filter(
    documentCollectionOptions?.options || [],
    (widget) => (widget?.userRoles || []).includes(getRoleId())
  );

  const onSearchQuery = ({ searchOps, inputValue }) => {
    const searchDomain =
      currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH
        ? "ALL"
        : currentTab;

    if (currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.ALL);
    }

    let items = [];

    switch (currentMainTab) {
      case EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH:
        items = listItems;
        break;
      case EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH:
        items = listItemsSpecific;
        break;
      case EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH:
        items = listItemsStructure;
        break;
      default:
        items = [];
    }

    const includeDocument = _.find(items, {
      value: currentTab,
    })?.includeDocument;

    const dataSources =
      (availableDataSources || []).length === 1
        ? availableDataSources[0]?.sourceName
        : selectedDataSources?.option;

    dispatch(
      searchParameters({
        searchTerm: inputValue || searchTerm,
        dataSources,
        type: searchDomain,
        includeDocument,
        searchOps,
      })
    );
  };

  /**
   * Search by components
   * @param {*} components
   */
  const onSearchByProcess = async (components) => {
    setShowStructureSearchForm(false);

    const queryComponents = _.map(components, (component) => {
      const type = component?.type || "BINGO_EXACT";

      let componentBody = {
        role: _.upperCase(component?.role),
        searchType: type,
      };

      if (type === "BINGO_EXACT" && component?.compoundId) {
        componentBody = {
          ...componentBody,
          processId: component?.compoundId,
        };
      } else {
        componentBody = {
          ...componentBody,
          structure: component?.smiles,
          cutoff: component?.cutoff || 0,
        };
      }

      return componentBody;
    });

    let body = {
      processQuery: {
        sourceName: _.get(selectedDataSources, "option[0]"),
        queryComponents,
        joinType: "AND",
      },
    };

    await searchForProcess(body, setStructureResultLoading, (items) => {
      setStructureSearchResults(items);
    });
  };

  const onSearch = async (query = {}) => {
    setShowStructureSearchForm(false);
    const { structureTypes = "DS", searchOps } = query;
    setFilteredSearchResults([]);

    const form = {
      queryStructure: queryStructure,
      databases: selectedDataSourceOptions,
      structureTypes,
      searchOps,
      cutoff: similarityOptions.includes(matchSearch) ? cutoff : null,
    };

    await searchForCompound(form, setStructureResultLoading, (items) => {
      setStructureSearchResults(items);
      forceUpdate();
    });
  };

  const onSearchByAdvanced = (formItems, joinType) => {
    let formattedQueries = {};
    formattedQueries["type"] = "COMPOUND";

    formattedQueries["joinType"] = "AND";

    const formItemsWithDatabaseNames = _.map(formItems, (formItem) => {
      const widgetData = _.find(
        availableWidgets,
        (widget) => _.get(widget, "widget.title") === formItem?.widgetName
      );

      if (widgetData && widgetData?.value) {
        return {
          ...formItem,
          widgetDatabaseName: widgetData?.value,
        };
      }

      return {
        ...formItem,
        widgetDatabaseName: formItem?.widgetName,
      };
    });

    const widgetTypes = _.uniq(
      _.map(formItemsWithDatabaseNames, (item) => item?.widgetDatabaseName)
    );

    const parameterQueries = _.map(formItems, (item) => ({
      parameterField: item?.name,
      operator: item?.op === "IS_DEFINED" ? "IS_NULL" : item?.op,
      not: item?.op === "IS_DEFINED",
      values: _.flatten([item?.value]),
      // queryJoinType: item?.joinType,
    }));

    formattedQueries["sourceName"] = {
      values: selectedDataSourceOptions,
      operator: "EQ",
      fieldType: "STRING",
      not: false,
    };

    formattedQueries["widgetType"] = {
      values: widgetTypes,
      operator: "EQ",
      fieldType: "STRING",
    };

    formattedQueries["parameterQueryJoinType"] = joinType;

    const finalQuery = {
      ...formattedQueries,
      parameterQueries,
    };

    dispatch(searchForCompoundData(finalQuery));
    setShowAdvancedSearchForm(false);
  };

  useEffect(() => {
    if (
      currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH &&
      _.isEmpty(currentTab)
    ) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.ALL);
    }

    if (
      currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH &&
      _.isEmpty(currentTab)
    ) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.COMPOUND);
    }

    if (
      currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH &&
      _.isEmpty(currentTab)
    ) {
      setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES);
    }
  }, [currentMainTab, currentTab]);

  return (
    <>
      <Auxiliary>
        <Header
          availableDataSources={availableDataSources}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          dataSource={selectedDataSources}
          selectedDataSources={selectedDataSources}
          selectedDataSourceOptions={selectedDataSourceOptions}
          saveSelectedDataSources={saveSelectedDataSources}
          setPage={setPage}
          setPerPage={setPerPage}
          formRef={formRef}
          onSearchQuery={onSearchQuery}
          widgetIds={widgetIds}
          widgetIdsNormalized={widgetIdsNormalized}
          currentMainTab={currentMainTab}
          setCurrentMainTab={setCurrentMainTab}
          canvasModal={showStructureSearchForm}
          setCanvasModal={setShowStructureSearchForm}
          searchOps={searchOps}
          matchSearch={matchSearch}
          cutoff={cutoff}
          filteredSearchResults={filteredSearchResults}
          structureImage={structureImage}
          listItems={listItems}
          listItemsSpecific={listItemsSpecific}
          listItemsStructure={listItemsStructure}
          showAdvancedSearchForm={showAdvancedSearchForm}
          setShowAdvancedSearchForm={setShowAdvancedSearchForm}
          formItems={formItems}
          setStructureSearchResults={setStructureSearchResults}
          structureSearchResults={structureSearchResults}
          advancedSearchJoinType={advancedSearchJoinType}
        />

        <div className="gx-profile-content">
          <Row>
            <Col xl={24} lg={24} md={24} sm={24} xs={24}>
              <ExploreDetail
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                currentTab={currentTab}
                currentMainTab={currentMainTab}
                setCurrentTab={setCurrentTab}
                selectedDataSources={(selectedDataSources || {})?.option}
                documentCollectionOptions={filteredWidgets}
                structureSearchResults={structureSearchResults}
                structureResultLoading={structureResultLoading}
                mapImages={mapImages}
                setMapImages={setMapImages}
                filteredSearchResults={filteredSearchResults}
                setFilteredSearchResults={setFilteredSearchResults}
                availableWidgets={availableWidgets}
                onSearchByAdvanced={onSearchByAdvanced}
                showAdvancedSearchForm={showAdvancedSearchForm}
                setShowAdvancedSearchForm={setShowAdvancedSearchForm}
                formItems={formItems}
                setFormItems={setFormItems}
                showStructureSearchForm={showStructureSearchForm}
                setShowStructureSearchForm={setShowStructureSearchForm}
                searchOps={searchOps}
                form={form}
                matchSearch={matchSearch}
                cutoff={cutoff}
                setCutoff={setCutoff}
                queryStructure={queryStructure}
                setQueryStructure={setQueryStructure}
                setStructureImage={setStructureImage}
                onSearch={onSearch}
                onSearchByProcess={onSearchByProcess}
                searchTerm={searchTerm}
                advancedSearchJoinType={advancedSearchJoinType}
                setAdvancedSearchJoinType={setAdvancedSearchJoinType}
                availableDataSources={availableDataSources}
                sources={dataSources}
              />
            </Col>
          </Row>
        </div>
      </Auxiliary>
    </>
  );
};

export default ExplorePage;
