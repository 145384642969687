import { useMsal } from "@azure/msal-react";
import { Button, Checkbox, Drawer, Form, Input, Radio, Select } from "antd";
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { XBlock, XMasonry } from "react-xmasonry";
import { getSystemWidgets, setCurrentWidgets } from "../../appRedux/actions";
import {
  getSystemWidget,
  getUserWidget,
  saveUserWidget,
} from "../../appRedux/services/SystemWidget";
import { getSources } from "../../appRedux/services/Widget";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import SearchWidget from "../../components/SearchWidget";
import Widget from "../../components/Widget";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  GLOBAL_LAYOUT_SETTINGS,
  IDENTIFIER_SEPERATOR,
  RESOURCE_LINKS,
  SEARCH_DATASOURCES,
  SEARCH_PAGE_WIDGETS_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  USER_LAYOUT_SETTINGS,
} from "../../constants/Config";
import { getRole, getRoleId } from "../../util/auth";
import { filterByAuthorizedRoles } from "../../util/search";
import { searchDocumentation } from "../UploadPage/helper";

import useGetRoles from "../../hooks/detailPage/useGetRoles";
import ResourceLinks from "./ResourceLinks";
import "./style.css";

const HomePage = () => {
  const history = useHistory();
  const [sources, setSources] = useState([]);
  const [defaultSourceName, setDefaultSourceName] = useState({});
  const [type, setType] = useState("compound");
  const [searchWidgets, setSearchWidgets] = useState([]);
  const [selectedDataSources, setSelectedDataSources] = useState([]);
  const [currentSettings, setCurrentSettings] = useState([]);
  const [globalSettings, setGlobalSettings] = useState([]);

  const [loading, setLoading] = useState(false);

  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);

  const [documentations, setDocumentations] = useState([]);
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);
  const [availableWidgetParams, setAvailableWidgetParams] = useState([]);
  const [availableSearchWidgetParams, setAvailableSearchWidgetParams] =
    useState([]);

  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const [resourceLinks, setResourceLinks] = useState([]);

  const { currentView } = useSelector(({ auth }) => auth);

  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const userNameWithRole = `${userName}-${getRole()}`;
  const dispatch = useDispatch();
  const roles = useGetRoles();

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSystemWidget(RESOURCE_LINKS, setResourceLinks);
  }, []);

  useEffect(() => {
    if (
      (dataWidgets || []).length &&
      (systemWidgets || []).length &&
      !availableWidgetParams.length
    ) {
      let availableWidgets = [...systemWidgets, ...dataWidgets];
      let availableWidgetOptions = [];

      _.forEach(availableWidgets, (widget) => {
        const rows = _.get(widget, "widget.rows") || [];
        const widgetName = widget?.field;

        _.forEach(rows, (row) => {
          const acceptedRowTypes = ["EDITABLE", "EDITABLE_DATE", "LIST"];

          if (acceptedRowTypes.includes(row?.type)) {
            const option = `${widgetName} - ${row?.field || row?.value}`;
            const name = row?.name || row?.value || row?.field;

            availableWidgetOptions.push({
              ...row,
              label: option,
              value: row?.name,
              name,
              field: row?.field,
              widgetName: widget?.value,
            });
          }
        });
      });

      setAvailableWidgetParams(availableWidgetOptions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataWidgets, systemWidgets]);

  useEffect(() => {
    if ((documentCollectionOptions?.options || []).length) {
      let allWidgets = [...(documentCollectionOptions?.options || [])];

      let allSearchWidgetOptions = [];

      _.forEach(allWidgets, (widget) => {
        const rows = _.get(widget, "widget.rows") || [];
        _.forEach(rows, (row) => {
          const option = `${row?.field || row?.value}`;
          const name = row?.value || row?.name || row?.field;

          if (_.get(row, `isAccessible.${getRole()}`)) {
            allSearchWidgetOptions.push({
              label: option,
              value: name,
              widgetName: widget?.value,
            });
          }
        });
      });

      setAvailableSearchWidgetParams(allSearchWidgetOptions);
    }
  }, [documentCollectionOptions]);

  const pageName = "home";

  useEffect(() => {
    setLoading(true);
    getUserWidget(
      USER_LAYOUT_SETTINGS,
      userNameWithRole,
      (cur) => {
        dispatch(
          setCurrentWidgets({
            widgets: cur,
            pageName: pageName,
            database: "",
          })
        );

        setCurrentSettings(cur);
        setLoading(false);
      },
      false,
      () => {
        setLoading(false);
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserWidget(GLOBAL_LAYOUT_SETTINGS, "Admin", setGlobalSettings, false);
  }, []);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!searchWidgets.length) {
      getSystemWidget(SEARCH_PAGE_WIDGETS_OPTIONS, setSearchWidgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const trainingSettings =
    _.find(documentCollectionOptions?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  useEffect(() => {
    if (!(sources || []).length) getSources(setSources, true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userNameWithRole,
      setDefaultSourceName,
      false
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      "home",
      setDocumentations
    );
  }, []);

  // useEffect(() => {
  //   if ((documentCollectionOptions?.options || []).length) {
  //     const allWidgets = [...(documentCollectionOptions?.options || [])];

  //     let allSearchWidgetOptions = [];

  //     _.forEach(allWidgets, (widget) => {
  //       const rows = _.get(widget, "widget.rows") || [];
  //       _.forEach(rows, (row) => {
  //         const option = `${row?.field || row?.value}`;
  //         const name = row?.value || row?.name || row?.field;

  //         allSearchWidgetOptions.push({
  //           label: option,
  //           value: name,
  //           widgetName: widget?.value,
  //         });
  //       });
  //     });

  //     setAvailableSearchWidgetParams(allSearchWidgetOptions);
  //   }
  // }, [documentCollectionOptions]);

  const [sourceName, setSourceName] = useState();

  const onUpdateDocumentations = () => {
    searchDocumentation(defaultSourceName?.option, pageName, setDocumentations);
  };

  const [availableWidgets, setAvailableWidgets] = useState([...searchWidgets]);
  const [existingLayoutSettings, setExistingLayoutSettings] = useState(null);
  const [existingGlobalSettings, setExistingGlobalSettings] = useState(null);

  const [widgetOptions, setWidgetOptions] = useState([]);

  useEffect(() => {
    _.forEach(currentSettings, (k, v) => {
      // Check if database and pageName matches
      if (k?.pageName === pageName && k?.selected) {
        setExistingLayoutSettings(k);
      }
    });

    if (!_.isEmpty(currentView)) {
      setExistingLayoutSettings(_.get(currentSettings, currentView));
    }

    _.forEach(globalSettings, (k, v) => {
      if (k?.role === getRole() && k?.pageName === pageName) {
        setExistingGlobalSettings(k);
      }
    });

    if (existingLayoutSettings) {
      const targetWidgets = searchWidgets.filter((item) => {
        const widgetOptions = existingLayoutSettings?.widgetOptions || [];
        const widgets =
          _.map(widgetOptions, (widgetOption) => widgetOption?.widget) || [];
        return widgets.includes(item?.value);
      });
      setAvailableWidgets(targetWidgets);
    } else if (existingGlobalSettings) {
      const widgets = existingGlobalSettings?.widgets || [];
      const databases = existingGlobalSettings?.database || [];

      const targetWidgets = searchWidgets.filter((item) => {
        if (databases.length) {
          return widgets.includes(item?.value);
        } else {
          return widgets.includes(item?.value);
        }
      });
      setAvailableWidgets(targetWidgets);
    } else {
      setAvailableWidgets([]);
      setWidgetOptions([]);
    }

    if (existingLayoutSettings) {
      setWidgetOptions(existingLayoutSettings?.widgetOptions || []);
    } else if (existingGlobalSettings) {
      setWidgetOptions(existingGlobalSettings?.widgetOptions || []);
    }
  }, [
    currentSettings,
    searchWidgets,
    currentView,
    existingLayoutSettings,
    existingGlobalSettings,
    globalSettings,
  ]);

  const navigationCard = ({ option, targetPages }) => {
    const currentSource = _.find(sources, { sourceName }) || {};

    let prefix = "";

    switch (type) {
      case "compound":
      case "quality":
        prefix = currentSource?.compoundFormat;
        break;
      case "process":
      case "experiment":
        prefix = currentSource?.processFormat;
        break;
      case "project":
        prefix = currentSource?.projectFormat;
        break;
      case "sample":
        prefix = currentSource?.sampleFormat;
        break;
      default:
        prefix = "";
    }

    prefix = (prefix || "").split("-")[0];

    const dataSourceOptions = _.map(filteredDataSources, (item) => ({
      label: item?.sourceName,
      value: item?.sourceName,
    }));

    return (
      <Widget styleName="color-white gx-mr-3">
        <div
          className="ant-row-flex gx-mb-3 gx-dash-search"
          style={
            option?.backgroundColor
              ? { backgroundColor: option?.backgroundColor }
              : {}
          }
        >
          <h2
            className="h4 gx-mb-3 gx-mb-sm-1 gx-mr-2"
            style={option?.titleColor ? { color: option?.titleColor } : {}}
          >
            Navigate to:
          </h2>
          <div className="gx-mx-sm-2">
            <Radio.Group
              size="small"
              className="gx-radio-group-link gx-radio-group-link-news"
              defaultValue={0}
              onChange={(e) => {
                if (
                  targetPages[e.target.value] &&
                  targetPages[e.target.value]?.value
                ) {
                  setType(targetPages[e.target.value]?.value);
                }
              }}
            >
              {
                <>
                  {_.map(targetPages, (page, index) => {
                    return (
                      <Radio.Button value={index} className="gx-mb-1">
                        {page.label}
                      </Radio.Button>
                    );
                  })}
                </>
              }
            </Radio.Group>
          </div>
        </div>

        <div className="gx-pl-2 gx-pr-5">
          <Form
            layout="inline"
            size="small"
            initialValues={{ id: "" }}
            onFinish={({ id }) => {
              const substanceId = `${prefix}-${id}`;
              history.push(`${sourceName}/${type}/${substanceId}`);
            }}
          >
            <Form.Item label="Select Database">
              <Select
                className="gx-ml-3"
                options={dataSourceOptions}
                value={sourceName}
                onChange={(e) => setSourceName(e)}
                placeholder={"Select database"}
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>

            <Form.Item
              name="id"
              rules={[
                {
                  required: true,
                  message: "Please select id",
                },
              ]}
            >
              <Input
                prefix={`${prefix} ${IDENTIFIER_SEPERATOR}`}
                placeholder={`enter ${type} id`}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    const substanceId = `${prefix}-${e.target.value}`;
                    history.push(`${sourceName}/${type}/${substanceId}`);
                  }
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" size="small" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Widget>
    );
  };

  const filteredDataSources = filterByAuthorizedRoles(
    sources,
    roles,
    getRole(),
    "access"
  );

  useEffect(() => {
    if ((filteredDataSources || []).length === 1) {
      setSourceName(filteredDataSources[0]?.sourceName);
    }
  }, [filteredDataSources]);

  useEffect(() => {
    getUserWidget(
      SEARCH_DATASOURCES,
      userName,
      setSelectedDataSources,
      false,
      () => {
        const sourcenames = _.map(
          filteredDataSources,
          (item) => item?.sourceName
        );
        setSelectedDataSources({ option: sourcenames });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const saveSelectedDataSources = (sources) => {
    saveUserWidget(
      SEARCH_DATASOURCES,
      userName,
      setSelectedDataSources,
      () => {},
      {
        option: sources,
      },
      false,
      null
    );
  };

  const selectedDataSourceOptions =
    (filteredDataSources || []).length === 1
      ? [filteredDataSources[0]?.sourceName]
      : selectedDataSources?.option;
  const filteredWidgets = _.filter(
    documentCollectionOptions?.options || [],
    (widget) => (widget?.userRoles || []).includes(getRoleId())
  );

  const dataSourceOptions = _.map(filteredDataSources, (item) => ({
    label: item?.sourceName,
    value: item?.sourceName,
  }));

  const allWidgets = [...systemWidgets, ...dataWidgets];

  return (
    <div>
      <div
        className="gx-profile-banner sticky-header-component"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
            paddingTop: "15px",
          }}
        >
          <h3>Home Page</h3>
          <div>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <DocumentationPopover
                documentations={documentations}
                sourceName={TRAINING_DOCUMENT_SOURCE}
                setOpenDocumentationSettings={setOpenDocumentationSettings}
                hasPermission={isUserAllowedToManageDocumentation}
              />
            ) : null}
          </div>
          <div className="gx-ml-2">
            <ResourceLinks resourceLinks={resourceLinks} />
          </div>
        </div>

        {!(filteredDataSources || []).length && (
          <div className="gx-text-warning gx-mt-3">
            No Data Sources Available. Please contact administrator.
          </div>
        )}

        {(filteredDataSources || []).length ? (
          <div className="gx-d-flex checkbox-selector gx-mt-2">
            {(filteredDataSources || []).length === 1 ? (
              <div className="gx-mr-3 gx-mt-2">
                <span>Data Source:</span>
                <span className="gx-ml-2 gx-text-warning">
                  {filteredDataSources[0]?.sourceName}
                </span>
              </div>
            ) : (
              <>
                {!!selectedDataSourceOptions ? (
                  <Form layout="inline" title="Common">
                    <Form.Item
                      label="Data Sources"
                      name="dataSources"
                      initialValue={selectedDataSourceOptions}
                      className="dataSources__label"
                    >
                      <Checkbox.Group
                        options={(filteredDataSources || []).map((item) => ({
                          label: item?.sourceName,
                          value: item?.sourceName,
                        }))}
                        name="dataSources"
                        defaultValue={selectedDataSourceOptions}
                        className="dataSources__checkboxGroup"
                        onChange={(e) => {
                          saveSelectedDataSources(e);
                          // setSelectedDataSources(e);
                        }}
                      />
                    </Form.Item>
                  </Form>
                ) : null}
              </>
            )}
          </div>
        ) : null}
      </div>

      {loading ? (
        <CircularProgress className="gx-loader-400 loader" />
      ) : (
        <div className="gx-mt-5 sticky-header-content">
          <XMasonry
            maxColumns={10}
            targetBlockWidth={200}
            center={false}
            responsive
            smartUpdate
            smartUpdateCeil={50}
          >
            {_.map(widgetOptions, (widgetOption) => {
              const currentWidget =
                _.find(availableWidgets, {
                  value: widgetOption?.widget,
                }) || {};

              const { widget } = currentWidget || {};

              const width = widgetOption?.width || 3;

              return (
                <XBlock width={width} key={widgetOption?.key}>
                  <SearchWidget
                    title={currentWidget?.field}
                    value={currentWidget?.value}
                    info={widget?.info}
                    description={widget?.description}
                    type={widget?.type}
                    sources={sources}
                    selectedSources={selectedDataSourceOptions}
                    documentCollectionOptions={filteredWidgets}
                    navigationCard={navigationCard}
                    sourceMap={sources}
                    availableWidgetParams={availableWidgetParams}
                    availableSearchWidgetParams={availableSearchWidgetParams}
                    widgetOption={widgetOption}
                    widget={widget}
                    dataSourceOptions={dataSourceOptions}
                    availableWidgets={allWidgets}
                  />
                </XBlock>
              );
            })}
          </XMasonry>
        </div>
      )}

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>
    </div>
  );
};

export default HomePage;
