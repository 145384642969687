import { GET_DOCUMENTS_REQUESTED } from "../../constants/ActionTypes";

/**
 * Create action to get documents
 * @param {String} entityId entity id
 * @param {String} sourceName source name
 */
export const getDocuments = (entityId, sourceName) => {
  return {
    type: GET_DOCUMENTS_REQUESTED,
    payload: {
      entityId,
      sourceName,
    },
  };
};
