import { AppstoreTwoTone } from "@ant-design/icons";
import { Divider, Popover, Tag } from "antd";
import _ from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as DownloadCloud } from "../../../../assets/vendors/downloadCloud.svg";
import { Link } from "react-router-dom";

import "./style.css";
import { downloadDocument } from "../../../../appRedux/services/Document";
import { EMPTY_FILE_NAME } from "../../../../constants/Config";
import { getCompoundType } from "../../../../util/url";
import { useSelector } from "react-redux";
import { popoverContent } from "../../../SearchPage/helper";
import PopoverImage from "../../../DetailPage/Widget/RowArray/PopoverImage";
import { findMappedDatabaseFieldName } from "../../helper";
import { getParent } from "../../../../util/Widget";

const ResultCell = ({
  type,
  title,
  cellItem,
  onSelect,
  searchTerm,
  availableWidgets = [],
  systemWidgetName,
  mapImages,
  sources = [],
}) => {
  let tags = [];

  const { dataSources } = useSelector(({ search }) => search);

  const data = _.omit(cellItem, ["id", "sourceName"]);
  const sourceName = cellItem?.sourceName;

  const fileName = data?.fileName || title || EMPTY_FILE_NAME;

  for (const [key, value] of Object.entries(data)) {
    if (_.isString(value) && !_.isEmpty(value)) {
      if (
        key !== "type" &&
        key !== "cdxml" &&
        key !== "source" &&
        key !== "widgetType" &&
        key !== "rmoId"
      ) {
        tags.push({ label: key, value });
      }
    }

    if (key === "parameters" && _.isObject(value)) {
      for (const [parameterKey, parameterValue] of Object.entries(value)) {
        if (!(typeof parameterValue === "object" && parameterValue !== null)) {
          tags.push({
            label: parameterKey,
            value: parameterValue,
          });
        }
      }
    }

    if (_.isArray(value) && key === "entityIds") {
      _.forEach(value, (entityId) => {
        tags.push({ key, label: "Entity Id", value: entityId });
      });
    }
  }

  let cellItemType = "";

  if (type === "processes") {
    cellItemType = (cellItem?.compoundId || "").includes(".")
      ? "experiment"
      : "process";
  } else {
    cellItemType = (cellItem?.compoundId || "").includes(".")
      ? "quality"
      : "compound";
  }

  if (
    getCompoundType({ sources, sourceName, id: cellItem?.compoundId }) ===
    "sample"
  ) {
    cellItemType = "sample";
  }

  console.log("cellItem", cellItem);

  return (
    <div className="gx-contact-item" key={uuidv4()}>
      <div className="gx-module-list-icon">
        <div className="gx-ml-2 gx-d-none gx-d-sm-flex gx-mr-4">
          {type === "documents" ? (
            <div style={{ width: "50px", height: "50px" }}>
              <AppstoreTwoTone
                className="gx-mt-3 gx-ml-3"
                onClick={() => {
                  onSelect(data);
                }}
              />
            </div>
          ) : (
            <div className="gx-mr-3">
              {type === "structures" || type === "processes" ? (
                <PopoverImage
                  rmoId={data?.rmoId}
                  sourceName={data?.source || data?.sourceName || sourceName}
                  popoverWidth={50}
                  popoverHeight={50}
                  isStructure
                />
              ) : (
                <PopoverImage
                  rmoId={[getParent(fileName)]}
                  sourceName={sourceName}
                  popoverWidth={50}
                  popoverHeight={50}
                />
              )}
            </div>
          )}
        </div>
      </div>

      <div className="gx-module-list-info gx-contact-list-info">
        <div className="gx-module-contact-content">
          <p className="gx-mb-1 gx-mr-5 gx-mt-3" style={{ fontSize: "11px" }}>
            <span
              className={`gx-contact-name gx-mr-3 ${
                fileName === EMPTY_FILE_NAME
                  ? "gx-text-warning"
                  : "explorePage__title"
              }`}
            >
              {type === "documents" ? (
                <Popover content="File Name" className="gx-ml-3">
                  <DownloadCloud
                    className="gx-mr-3 cloud-download-icon gx-pointer explorePage__title"
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      downloadDocument(cellItem?.id, sourceName, fileName);
                    }}
                  />

                  {fileName}
                </Popover>
              ) : (
                <>
                  {type === "structures" || type === "processes" ? (
                    // <Popover
                    //   content={popoverContent(
                    //     cellItem?.rmoId,
                    //     cellItem?.source,
                    //     true
                    //   )}
                    // >
                    <Link
                      to={`/${cellItem?.source}/${cellItemType}/${cellItem?.compoundId}`}
                      target="_blank"
                      replace
                    >
                      {cellItem?.compoundId}
                    </Link>
                  ) : (
                    // </Popover>
                    <Link
                      to={`/${sourceName}/${cellItemType}/${fileName}`}
                      target="_blank"
                    >
                      {fileName}
                    </Link>
                  )}
                </>
              )}
            </span>
            {"|"}
            <span className="gx-ml-2 gx-text-truncate gx-job-title">
              <Popover content="Data Source">
                {sourceName || cellItem?.source}
              </Popover>
            </span>
          </p>

          <Divider />

          <div className="gx-ml-2">
            {_.map(tags, (tag) => {
              let isHit = false;

              try {
                isHit = _.includes(
                  (tag?.value || "").toLowerCase(),
                  (searchTerm || "").toLowerCase()
                );
              } catch (error) {}

              const label = findMappedDatabaseFieldName(
                tag?.label,
                systemWidgetName,
                availableWidgets
              );

              if (_.isEmpty(tag?.value)) {
                return null;
              }

              return (
                <Tag
                  key={uuidv4()}
                  className={`${isHit ? "gx-text-success" : ""}`}
                >
                  {tag?.key === "entityIds" ? (
                    <Popover content={popoverContent(tag?.value, sourceName)}>
                      <Link
                        to={`/${sourceName}/${getCompoundType({
                          sources: dataSources,
                          sourceName: sourceName,
                          id: tag?.value,
                        })}/${tag?.value}`}
                        replace
                        target="_blank"
                        className="gx-text-primary"
                      >
                        {tag?.value}
                      </Link>
                    </Popover>
                  ) : (
                    <Popover
                      key={uuidv4()}
                      content={<span>{label || tag?.label}</span>}
                    >
                      <span style={{ fontSize: "10px" }}>{tag?.value}</span>
                    </Popover>
                  )}
                </Tag>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultCell;
