import React from "react";
import Sidebar from "./Sidebar";
import FormBuilder from "./FormBuilder";
import { v4 as uuidv4 } from "uuid";

const AdvancedSearch = ({
  availableWidgets,
  onSearch,
  formItems,
  setFormItems,
  advancedSearchJoinType,
  setAdvancedSearchJoinType,
}) => {
  const handleAddFormItem = (key) => {
    setFormItems([
      ...formItems,
      { id: uuidv4(), key, op: "CONTAINS", value: "" },
    ]);
  };

  const handleRemoveFormItemByKey = (key) => {
    setFormItems(formItems.filter((item) => item.key !== key));
  };

  const handleRemoveFormItem = (id) => {
    setFormItems(formItems.filter((item) => item.id !== id));
  };

  const handleFormItemChange = (id, field, value) => {
    setFormItems(
      formItems.map((item) => {
        if (item.id === id) {
          return { ...item, [field]: value };
        }
        return item;
      })
    );
  };

  return (
    <div className="gx-d-flex">
      <Sidebar
        availableWidgets={availableWidgets}
        formItems={formItems}
        handleAddFormItem={handleAddFormItem}
        handleRemoveFormItemByKey={handleRemoveFormItemByKey}
      />

      <FormBuilder
        formItems={formItems}
        onSearch={onSearch}
        handleRemoveFormItem={handleRemoveFormItem}
        handleFormItemChange={handleFormItemChange}
        advancedSearchJoinType={advancedSearchJoinType}
        setAdvancedSearchJoinType={setAdvancedSearchJoinType}
      />
    </div>
  );
};

export default AdvancedSearch;
