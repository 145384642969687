import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { Link } from "react-router-dom";
import ImageCarousel from "../ImageCarousel";
import UnknownCompound from "../../../assets/vendors/UnknownCompound.svg";
import { Image, Popover } from "antd";
import PopoverImage from "../../DetailPage/Widget/RowArray/PopoverImage";
import _ from "lodash";
import { fetchImagePreview } from "../../../appRedux/services/Search";

const DrawingResult = ({
  result,
  registerIds,
  generateCompoundImage,
  isKnown,
  sourceName,
  currentCompoundDetail,
}) => {
  const [structureImage, setStructureImage] = useState();

  useEffect(() => {
    fetchImagePreview(
      registerIds[0]?.compoundId,
      setStructureImage,
      true,
      sourceName
    );
  }, []);

  useEffect(() => {
    _.forEach(registerIds, (reg) => {
      generateCompoundImage(reg?.compoundId);
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isMixture = currentCompoundDetail?.mixture;

  const popoverContent = (id, source) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <PopoverImage rmoId={id} sourceName={source} />
        <span className="gx-text-primary" style={{ textAlign: "center" }}>
          {id}
        </span>
      </div>
    );
  };

  return (
    <div
      className="widget-card ant-card ant-card-bordered gx-mr-3 gx-card-widget
    gx-width-400"
    >
      <div className="ant-card-body" style={{ backgroundColor: "white" }}>
        <>
          {isKnown ? (
            <>
              {structureImage && (
                <ImageCarousel
                  structureImage={[structureImage]}
                  width={150}
                  height={150}
                />
              )}
            </>
          ) : (
            <div className="fullImageWrapper">
              <Image width={150} height={150} src={UnknownCompound} />
              <span className="fullImageOverlay">Undefined Structure</span>
            </div>
          )}
        </>

        <ul className="gx-list-group">
          <li>
            {!isKnown && (
              <>
                <span className="gx-text-gray">Unique Descriptor:</span>{" "}
                <span className="gx-text-gray">{result}</span>
              </>
            )}
          </li>
          {registerIds.map((registerItem, index) => {
            if (registerItem.compoundId) {
              return (
                <li className="gx-text-gray" key={`result-${index}`}>
                  {!index ? (
                    <span>
                      This {isMixture ? "mixture" : "structure"} has been
                      registered as:
                    </span>
                  ) : index === 1 ? (
                    <span>It consists of the following components:</span>
                  ) : null}{" "}
                  <div>
                    <Popover
                      content={popoverContent(
                        registerItem?.compoundId,
                        sourceName
                      )}
                    >
                      <Link
                        replace
                        to={`/${sourceName}/compound/${registerItem.compoundId}`}
                        target="_blank"
                      >
                        {registerItem.compoundId}
                      </Link>
                    </Popover>
                  </div>
                </li>
              );
            } else {
              return (
                <li className="gx-text-warning" key={`result-${index}`}>
                  Structure consists of the following substances:{" "}
                  <Link
                    replace
                    to={`/${sourceName}/compound/${registerItem.match}`}
                    target="_blank"
                  >
                    {registerItem.match}
                  </Link>
                </li>
              );
            }
          })}
        </ul>
      </div>
    </div>
  );
};

DrawingResult.defaultProps = {
  sourceName: "",
};

DrawingResult.propTypes = {
  result: PT.string.isRequired,
  registerIds: PT.arrayOf(PT.shape()).isRequired,
  generateCompoundImage: PT.func.isRequired,
  structureImage: PT.array.isRequired,
  sourceName: PT.string,
};

export default DrawingResult;
