import { Form, Select } from "antd";
import React from "react";
import {
  DOCUMENT_UPLOAD_OPTIONS,
  DOCUMENT_UPLOAD_TYPES,
} from "../../../constants/Config";

const SelectType = ({
  dataSources,
  selectedDataSource,
  setSelectedDataSource,
  selectedType,
  setSelectedType,
  documentUploadtype,
}) => {
  const dataSourcesOptions = dataSources.map((item) => ({
    field: item?.sourceName,
    value: item?.sourceName,
  }));

  const onChangeType = (e) => {
    setSelectedType(e);
  };

  return (
    <div>
      <div className="gx-d-flex">
        <h3 className="gx-ml-2 gx-mb-4">
          Select{" "}
          {documentUploadtype !== DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
            ? "Type"
            : "Data Source"}
        </h3>
      </div>
      <div className="gx-d-flex">
        <Form className="gx-ml-4">
          {documentUploadtype !==
          DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA ? (
            <Form.Item label="Select Type" className="gx-ml-1">
              <Select
                defaultValue={selectedType}
                options={DOCUMENT_UPLOAD_TYPES}
                placeholder="Select Type"
                onChange={onChangeType}
                dropdownMatchSelectWidth={false}
              />
            </Form.Item>
          ) : null}

          <Form.Item label="Data Source" className="gx-ml-1">
            <Select
              options={dataSourcesOptions}
              onChange={setSelectedDataSource}
              defaultValue={selectedDataSource}
              showSearch
              dropdownMatchSelectWidth={false}
              placeholder="Select data source"
            ></Select>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default SelectType;
