import { Form, Input, InputNumber, Select } from "antd";
import _ from "lodash";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  options = [],
  mode,
  required = true,
  ...restProps
}) => {
  const [value, setValue] = useState([]);

  const handleChange = (newValues = []) => {
    setValue(newValues);
  };

  let inputNode = <Input required={required} />;
  switch (inputType) {
    case "number":
      inputNode = <InputNumber />;
      break;
    case "select":
      inputNode = (
        <Select
          id={uuidv4()}
          value={value}
          showSearch
          mode={mode}
          onChange={handleChange}
          allowClear
        >
          {_.map(options, (option) => (
            <Select.Option value={option?.value}>{option?.label}</Select.Option>
          ))}
        </Select>
      );
      break;
    default:
      inputNode = <Input />;
  }

  let inputRequired = true;
  switch (title) {
    case "Enable Locking Roles":
    case "Default Page Types":
    case "Allowed Page Type":
    case "Version Access Roles":
      inputRequired = false;
      break;
    default:
      inputRequired = false;
      break;
  }

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: "0 10px",
          }}
          rules={[
            {
              required: inputRequired,
              message: `Please enter ${title}`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
