/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { MW_SEARCH_OPERATORS, SEARCH_OPTIONS } from "../../../constants/Config";

const MolecularWeightForm = ({
  dataSources,
  type,
  currentType,
  setCurrentType,
  setTargetValue,
  targetValue,
  onSearch,
}) => {
  const label = _.find(SEARCH_OPTIONS, { value: type });

  useEffect(() => {
    setCurrentType(MW_SEARCH_OPERATORS[2].value);
  }, []);

  return (
    <Form
      layout="inline"
      onFinish={onSearch}
      className="gx-d-flex"
      style={{ columnGap: "10px", marginRight: "20px" }}
      initialValues={{
        type: MW_SEARCH_OPERATORS[2].value,
        targetValue,
      }}
    >
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: "Please select type",
          },
        ]}
      >
        <Select
          placeholder="Select Type"
          size="small"
          options={MW_SEARCH_OPERATORS}
          onChange={setCurrentType}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>

      {currentType !== "Between" && (
        <Form.Item
          name="targetValue"
          rules={[
            {
              required: true,
              message: "Please select value",
            },
          ]}
        >
          <Input
            size="small"
            placeholder={`Enter ${label?.label}`}
            onChange={(e) => setTargetValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch();
              }
            }}
          />
        </Form.Item>
      )}

      {/* {currentType === "<=" && (
        <Form.Item
          name="lower"
          rules={[
            {
              required: true,
              message: "Please set the lower value",
            },
          ]}
        >
          <Input
            placeholder={`Enter ${label?.label}`}
            type="number"
            size="small"
          />
        </Form.Item>
      )}

      {currentType === ">=" && (
        <Form.Item
          name="upper"
          rules={[
            {
              required: true,
              message: "Please set the upper value",
            },
          ]}
        >
          <Input
            placeholder={`Enter ${label?.label}`}
            type="number"
            size="small"
          />
        </Form.Item>
      )}
*/}
      {currentType === "Between" && (
        <div className="gx-d-flex">
          <Form.Item
            name="upper"
            className="gx-ml-2"
            rules={[
              {
                required: true,
                message: "Please set the upper value",
              },
            ]}
          >
            <div className="gx-d-flex" style={{ columnGap: "20px" }}>
              <Input placeholder={`Lower Limit`} type="number" size="small" />
            </div>
          </Form.Item>
          <Form.Item
            name="lower"
            className="gx-ml-3"
            rules={[
              {
                required: true,
                message: "Please set the lower value",
              },
            ]}
          >
            <div className="gx-d-flex" style={{ columnGap: "20px" }}>
              <span className="gx-mt-1">and</span>
              <Input placeholder={`Upper Limit`} type="number" size="small" />
            </div>
          </Form.Item>
        </div>
      )}

      <Form.Item>
        <Button
          type="primary"
          size="small"
          htmlType="submit"
          style={{ marginTop: "5px" }}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MolecularWeightForm;
