import React from "react";
import PT from "prop-types";

import { Divider } from "antd";

const RowSeparator = ({ title }) => {
  return <Divider>{title}</Divider>;
};

RowSeparator.defaultProps = {
  title: "",
};

RowSeparator.propTypes = {
  title: PT.string,
};

export default RowSeparator;
