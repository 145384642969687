import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Switch } from "antd";

const RowToggleButton = ({ field }) => {
  return (
    <div className={"card-item"} key={uuidv4()}>
      <div className="card-item-field">{field}</div>
      <div className="card-item-value">
        <Switch size="small" />
      </div>
    </div>
  );
};

RowToggleButton.defaultProps = {
  field: "",
};

RowToggleButton.propTypes = {
  field: PT.string,
};

export default RowToggleButton;
