import React from "react";
import { v4 as uuidv4 } from "uuid";
import { getLanguageItem } from "../../../util/SafetySentences";

export const renderStatement = ({ id, category, languageMap }) => {
  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: getLanguageItem(id, languageMap),
        }}
      />
      <br />
      <span
        dangerouslySetInnerHTML={{
          __html: getLanguageItem(category, languageMap),
        }}
      />
    </>
  );
};

export const renderCategory = ({ id, category, languageMap }) => {
  return (
    <>
      <span
        dangerouslySetInnerHTML={{
          __html: getLanguageItem(id, languageMap),
        }}
      />
      <br />
      <span
        className="gx-text-black"
        dangerouslySetInnerHTML={{
          __html: getLanguageItem(category, languageMap),
        }}
      />
    </>
  );
};

export const renderPictogram = ({ pictogramIds, imageMap }) => {
  return pictogramIds.map((pictogram) => {
    const img = getLanguageItem(pictogram, imageMap);

    return (
      <img
        key={uuidv4()}
        alt="pictorgram"
        className="gx-mr-1"
        height={50}
        width={50}
        src={`data:image/jpeg;base64,${img}`}
      />
    );
  });
};
