import { Button, Spin, Table } from "antd";
import _ from "lodash";
import CircularProgress from "components/CircularProgress";
import React, { useRef, useState } from "react";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import "./style.css";

import {
  AlertTwoTone,
  CheckCircleTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import moment from "moment";

const ApplyData = ({
  dataSource,
  selectedId,
  loading,
  loadData,
  tableColumns,
  selectedType,
  dataLoading,
  setDataLoading,
  updateStatus,
  uploadWidgetData,
  onExportExternalDocument,
}) => {
  const [existingClicked, setExistingClicked] = useState(false);
  let successData = useRef([]);
  let existsData = useRef([]);
  let failingData = useRef([]);
  let processingData = useRef([]);

  successData.current = dataSource.filter((item) => item?.status === "success");
  existsData.current = dataSource.filter((item) => item?.status === "exists");
  failingData.current = dataSource.filter((item) => item?.status === "error");
  processingData.current = dataSource.filter(
    (item) => item?.status === "processing"
  );

  const filteredDataSource =
    selectedType === "widget"
      ? _.filter(dataSource, (item) => _.get(item, selectedId))
      : dataSource.map((column) => {
          const normalizeColumn = column;
          _.forEach(column, (value, key) => {
            if (_.isObject(value) && key !== "Substances") {
              normalizeColumn[key] = JSON.stringify(value);
            }
          });

          return normalizeColumn;
        });

  const getSheetData = (data, header) => {
    var fields = Object.keys(data[0] || []);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName]
          ? _.isObject(row[fieldName])
            ? JSON.stringify(row[fieldName])
            : row[fieldName]
          : "";
      });
    });
    sheetData.unshift(header);
    return sheetData;
  };

  const exportFailed = async () => {
    const failedData = dataSource.filter((item) => item?.status !== "success");

    let header = tableColumns.map((col) => col?.title);
    const firstHeaderValue = header[0];
    header.shift();
    header.push(firstHeaderValue);

    const failedDataWithHeaders = failedData.map((data) => {
      let newData = {};
      header.forEach((item) => {
        if (_.get(data, [item])) {
          if (_.isDate(data[item])) {
            newData[item] = moment(data[item]).format("DD.MM.YYYY");
          } else {
            newData[item] = data[item];
          }
        } else if ((item || "").toLowerCase() === "status") {
          newData[item] = data["reason"];
        } else {
          newData[item] = "";
        }
      });
      return newData;
    });

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = getSheetData(failedDataWithHeaders, header);
      const totalColumns = sheetData[0].length || 0;

      try {
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
      } catch (error) {}

      return workbook.outputAsync().then((res) => {
        saveAs(res, "file.xlsx");
      });
    });
  };

  const mapFilterDataSource = _.map(filteredDataSource, (item) =>
    _.omit(item, "Qualities")
  );

  return (
    <>
      {!_.isEmpty(mapFilterDataSource) ? (
        <>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="gx-mt-4">
              {dataLoading ? (
                <div className="gx-d-flex">
                  <div>
                    {(processingData.current || []).length ? (
                      <div className="gx-text-info">
                        <Spin className="gx-mr-2" size="small" />
                        {(processingData.current || []).length} /{" "}
                        {(dataSource || []).length} Processing
                      </div>
                    ) : null}
                    <div className="gx-text-success">
                      <CheckCircleTwoTone
                        className="gx-mr-2"
                        twoToneColor="#52C41A"
                      />
                      {(successData.current || []).length} Success
                    </div>
                    <div className="gx-text-warning gx-mt-2">
                      <WarningTwoTone
                        className="gx-mr-2"
                        twoToneColor="#fa8c16"
                      />
                      {(existsData.current || []).length} Exists
                    </div>
                    <div className="gx-text-danger gx-mt-2 gx-mb-2">
                      <AlertTwoTone twoToneColor="FA8C16" className="gx-mr-2" />
                      {(failingData.current || []).length} Failed
                    </div>
                  </div>
                  <Button
                    type="primary"
                    size="small"
                    className="gx-ml-4"
                    onClick={exportFailed}
                  >
                    Export Failed
                  </Button>
                  {(existingClicked || selectedType === "widget") && (
                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => {
                        setExistingClicked(false);
                        setDataLoading(false);
                      }}
                    >
                      Start Over
                    </Button>
                  )}
                  {selectedType === "document" && !existingClicked && (
                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => {
                        loadData("PUT");
                        setExistingClicked(true);
                      }}
                    >
                      Overwrite existing files.
                    </Button>
                  )}
                  {selectedType === "document" && (
                    <Button
                      type="secondary"
                      size="small"
                      onClick={() => {
                        onExportExternalDocument();
                      }}
                    >
                      Export external document data.
                    </Button>
                  )}
                </div>
              ) : (
                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    if (selectedType === "widget") {
                      uploadWidgetData();
                      setExistingClicked(true);
                    } else {
                      loadData();
                    }

                    setDataLoading(true);
                  }}
                >
                  Start Loading
                </Button>
              )}
              <div className="apply-data">
                <Table
                  columns={tableColumns}
                  dataSource={mapFilterDataSource}
                  pagination={{ pageSize: 100 }}
                />
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

ApplyData.defaultProps = {};

ApplyData.propTypes = {};

export default ApplyData;
