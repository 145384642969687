/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useEffect } from "react";
import { MF_SEARCH_OPERATORS, SEARCH_OPTIONS } from "../../../constants/Config";

const MolecularFormulaForm = ({
  type,
  setCurrentType,
  setTargetValue,
  onSearch,
  targetValue,
}) => {
  const label = _.find(SEARCH_OPTIONS, { value: type });

  useEffect(() => {
    setCurrentType(MF_SEARCH_OPERATORS[0].value);
  }, []);

  return (
    <Form
      layout="inline"
      onFinish={onSearch}
      className="gx-d-flex"
      style={{ columnGap: "30px" }}
      initialValues={{
        type: MF_SEARCH_OPERATORS[0].value,
        targetValue,
      }}
    >
      <Form.Item
        name="type"
        rules={[
          {
            required: true,
            message: "Please select type",
          },
        ]}
      >
        <Select
          placeholder="Select Type"
          options={MF_SEARCH_OPERATORS}
          onChange={setCurrentType}
          size="small"
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>

      <Form.Item
        name="targetValue"
        rules={[
          {
            required: true,
            message: "Please select value",
          },
        ]}
      >
        <Input
          size="small"
          placeholder={`Enter ${label?.label}`}
          onChange={(e) => setTargetValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch();
            }
          }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          size="small"
          htmlType="submit"
          style={{ marginTop: "5px" }}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default MolecularFormulaForm;
