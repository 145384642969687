import { Alert, Button, Divider, Popconfirm, Collapse, Popover } from "antd";
import React from "react";
import PT from "prop-types";
import RowArray from "../Widget/RowArray";
import _ from "lodash";
import { PAGE_TYPES } from "../../../constants/Config";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import PopoverImage from "../Widget/RowArray/PopoverImage";
import { getParent } from "../../../util/Widget";

const { Panel } = Collapse;

const DeleteDrawer = ({
  compoundDetail = {},
  deleteCompound,
  closeDrawer,
  sourceName,
  widgetData,
  availableWidgets,
  allWidgets,
  currentRound,
  pageType,
  associates = [],
}) => {
  const { mixtures = [], compoundInstances = [] } = compoundDetail;
  const canDeleteCompound = !mixtures.length && !compoundInstances.length;
  const hasAssociates = associates.length;

  const targetWidgets = ["mixtureMembership", "processes", "mixtures"];

  const canDelete =
    pageType === PAGE_TYPES.COMPOUND ? canDeleteCompound : !hasAssociates;

  const popoverContent = (id, source) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <PopoverImage rmoId={getParent(id)} sourceName={source} />
        <span className="gx-text-primary" style={{ textAlign: "center" }}>
          {id}
        </span>
      </div>
    );
  };

  const renderItems = (items) => {
    return (
      <div className="gx-d-flex gx-mt-1">
        {_.map(items || [], (item) => (
          <div key={item} className="gx-mr-2">
            <Popover content={popoverContent(item, sourceName)}>
              <Link to={`/${sourceName}/quality/${item}`} target="_blank">
                {item}
              </Link>
            </Popover>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="gx-mt-4">
      {pageType === PAGE_TYPES.QUALITY ? (
        <>
          {!canDelete ? (
            <div>
              <Alert
                type="warning"
                message={
                  <span>
                    This quality cannot be deleted because it is a constituent
                    in the definition of the following qualities:
                  </span>
                }
              ></Alert>
              {renderItems(associates)}
            </div>
          ) : (
            <span>
              This quality is not associated with other compounds. It can be
              safely deleted.
            </span>
          )}
        </>
      ) : null}

      {_.map(targetWidgets, (targetWidget) => {
        const componentsWidget =
          _.find(availableWidgets, {
            value: targetWidget,
          }) || {};

        const row = _.get(componentsWidget, "widget.rows[0]") || {};

        const isExist = (
          _.get(widgetData, `${row?.targetObj}.${row?.value}`) || []
        ).length;

        if (isExist) {
          return (
            <div>
              <Alert
                className="gx-mt-3"
                message={`
        ${
          isExist
            ? `This compound is a component in the following ${row?.value}. ${
                !canDelete ? "It cannot be deleted." : null
              }`
            : `This structure is not a component in any ${row?.value}.`
        }`}
                type={!canDelete ? "warning" : "success"}
              />

              <div className="gx-ml-2 gx-mr-2">
                <Collapse defaultActiveKey={["1"]} bordered={false}>
                  <Panel
                    key="1"
                    header={componentsWidget?.title || componentsWidget?.field}
                  >
                    <RowArray
                      row={row}
                      data={widgetData}
                      info={row?.info}
                      sourceName={sourceName}
                      defaultPageSize={3}
                    />
                  </Panel>
                </Collapse>
              </div>
            </div>
          );
        }
        return null;
      })}

      {compoundInstances.length && pageType === PAGE_TYPES.COMPOUND ? (
        <>
          <Divider />

          <Alert
            type="warning"
            message={
              <span>
                This compound cannot be deleted because it has the following
                qualities defined:
              </span>
            }
          ></Alert>

          {renderItems(_.map(compoundInstances || [], (comp) => comp?.name))}

          {/* <div className="gx-mt-4">
            <CompoundInstanceWidget
              id={id}
              title={`${pluralize(
                "Quality",
                (compoundInstances || []).length
              )} (${(compoundInstances || []).length})`}
              compoundInstances={compoundInstances}
              addInstance={() => {}}
              loading={false}
              setLoading={() => {}}
              sourceName={sourceName}
              pageType={PAGE_TYPES.COMPOUND}
              hideInfo={false}
              roles={[]}
              cloneExperiment={() => {}}
              cloneDocuments={() => {}}
              availableWidgets={availableWidgets}
              selectedCollection={null}
              setSelectedCollection={() => {}}
              allWidgets={allWidgets}
              widgetData={widgetData}
              currentRound={currentRound}
            />
          </div> */}
        </>
      ) : null}

      <div className="gx-mt-3">
        <Popconfirm
          title="Are you want to delete this compound?"
          onConfirm={deleteCompound}
          okText="Yes"
          cancelText="Cancel"
        >
          {canDelete ? (
            <Button size="small" type="danger">
              Delete
            </Button>
          ) : null}
        </Popconfirm>
        <Button size="small" onClick={closeDrawer}>
          Cancel
        </Button>
      </div>
    </div>
  );
};

DeleteDrawer.defaultProps = {
  compoundDetail: {},
};

DeleteDrawer.propTypes = {
  compoundDetail: PT.shape(),
};

export default DeleteDrawer;
