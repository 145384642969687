import { DeleteTwoTone } from "@ant-design/icons";
import { List, Popconfirm } from "antd";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

const ExperimentList = ({
  experiments,
  process,
  sourceName,
  onDeleteExperiment,
  isSearchResult = false,
  hideAction = false,
}) => {
  const filteredExperiment = isSearchResult
    ? _.filter(experiments, (exp) => exp?.foundExperiment)
    : experiments;

  if (_.isEmpty(filteredExperiment)) {
    return null;
  }

  return (
    <div className="gx-ml-5">
      <List
        size="small"
        header={null}
        dataSource={filteredExperiment}
        renderItem={(exp) => (
          <List.Item key={uuidv4()}>
            <div
              className="gx-d-flex gx-justify-content-between"
              style={{ width: "100%" }}
            >
              <Link
                to={`/${sourceName}/experiment/${exp?.experimentId}`}
                target="_blank"
              >
                {isSearchResult && exp?.foundExperiment ? (
                  <div className="gx-mt-1">
                    <span>Experiment: </span>
                    <span className="gx-text-warning">{exp?.experimentId}</span>
                  </div>
                ) : (
                  <span>{exp?.experimentId}</span>
                )}
              </Link>

              <>
                {isSearchResult || hideAction ? null : (
                  <Popconfirm
                    title="Are you sure to remove this experiment?"
                    onConfirm={() => onDeleteExperiment(process, exp)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteTwoTone
                      twoToneColor="#f44336"
                      className="gx-pointer"
                    />
                  </Popconfirm>
                )}
              </>
            </div>
          </List.Item>
        )}
      />
    </div>
  );
};

export default ExperimentList;
