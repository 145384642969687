import { CloseCircleTwoTone, DeleteTwoTone } from "@ant-design/icons";
import React, { useEffect, useState } from "react";

const PanelHeader = ({ onDrag, title, onClosePanel, onDeletePanel }) => {
  const [mouseDown, setMouseDown] = useState(false);

  useEffect(() => {
    const handleMouseUp = () => setMouseDown(false);

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  useEffect(() => {
    const handleMouseMove = (e) => onDrag(e.movementX, e.movementY);

    if (mouseDown) {
      window.addEventListener("mousemove", handleMouseMove);
    }

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, [mouseDown, onDrag]);

  const handleMouseDown = () => setMouseDown(true);

  return (
    <div className="panel__header ant-card-head" onMouseDown={handleMouseDown}>
      <div className="panel__header__content">
        <div>
          <span>{title}</span>
        </div>

        <div>
          <CloseCircleTwoTone
            twoToneColor="#a94442"
            className="panel__header__right"
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();

              onClosePanel();
            }}
          />

          <DeleteTwoTone
            twoToneColor="#f44336"
            className="gx-ml-2"
            onClick={(e) => {
              e.stopPropagation();

              onDeletePanel();
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default PanelHeader;
