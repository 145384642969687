import _ from "lodash";
import {
  GET_DOCUMENT_ROLES_FAILED,
  GET_DOCUMENT_ROLES_REQUESTED,
  GET_DOCUMENT_ROLES_SUCCESS,
  GET_GENERIC_REQUESTED,
  GET_GENERIC_SUCCESS,
  GET_SOURCE_MAP_SUCCESS,
  GET_USER_ROLES_FAILED,
  GET_USER_ROLES_REQUESTED,
  GET_USER_ROLES_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  roles: [],
  documentRoles: [],
  isRoleInitialized: false,
  isDocumentRoleInitialized: false,
};

const GenericDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GENERIC_REQUESTED: {
      return {
        ...state,
        loading: true,
        [action.payload?.id]: {
          initialized: true,
        },
      };
    }

    case GET_GENERIC_SUCCESS: {
      const currentData = _.get(state, action.payload?.id);
      return {
        ...state,
        [action.payload?.id]: {
          ...currentData,
          [action.payload?.field]: action.payload?.data,
        },
        loading: false,
      };
    }

    case GET_SOURCE_MAP_SUCCESS: {
      return {
        ...state,
        sourceMap: action.payload,
      };
    }

    case GET_USER_ROLES_REQUESTED: {
      return {
        ...state,
        isRoleInitialized: true,
      };
    }

    case GET_USER_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
      };
    }

    case GET_DOCUMENT_ROLES_REQUESTED: {
      return {
        ...state,
        documentRoles: [],
        isDocumentRoleInitialized: true,
      };
    }

    case GET_DOCUMENT_ROLES_SUCCESS: {
      return {
        ...state,
        documentRoles: action.payload,
      };
    }

    case GET_USER_ROLES_FAILED: {
      return {
        ...state,
        roles: [],
      };
    }

    case GET_DOCUMENT_ROLES_FAILED: {
      return {
        ...state,
        documentRoles: [],
      };
    }

    default:
      return state;
  }
};

export default GenericDataReducer;
