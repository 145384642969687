import {
  AUTO_ASSIGN_REQUESTED,
  CHECK_UNIQUENESS_REQUESTED,
  CLEAR_ERROR_MESSAGE_REQUESTED,
  CLEAR_REGISTER_DATA_REQUESTED,
  GENERATE_IMAGE_REQUESTED,
  REGISTER_REQUESTED,
  UPDATE_LATEST_COMPOUND_REQUESTED,
  UPDATE_REQUESTED,
} from "../../constants/ActionTypes";

export const autoAssign = (result) => {
  return {
    type: AUTO_ASSIGN_REQUESTED,
    payload: result,
  };
};

export const clearErrorMessage = () => {
  return {
    type: CLEAR_ERROR_MESSAGE_REQUESTED,
  };
};

/**
 * Action for check uniqueness of given compound.
 * @param {String} result
 * @param {String} dataSource
 * @param {Boolean} isKnown structure is known
 * @returns {Object}
 */
export const checkUniqueness = (result, dataSource, isKnown = true) => {
  return {
    type: CHECK_UNIQUENESS_REQUESTED,
    payload: {
      result,
      dataSource,
      isKnown,
    },
  };
};

/**
 * Action for register given compound.
 * @param {String} result
 * @param {String} dataSource
 * @param {Boolean} isKnown structure is known
 * @returns {Object}
 */
export const register = (
  result,
  dataSource,
  isIsomer = false,
  isKnown = true,
  typeName = "",
  molecularWeight = null,
  molecularFormula = null,
  userName,
  compoundId,
  allowDuplicate = false,
) => {
  return {
    type: REGISTER_REQUESTED,
    payload: {
      result,
      dataSource,
      isIsomer,
      isKnown,
      typeName,
      molecularWeight,
      molecularFormula,
      userName,
      compoundId,
      allowDuplicate,
    },
  };
};

/**
 * Action for update given compound.
 * @returns {Object}
 */
export const update = (body, userName) => {
  return {
    type: UPDATE_REQUESTED,
    payload: {
      body,
      userName,
    },
  };
};

export const updateRecentCompound = (currentList, data) => {
  return {
    type: UPDATE_LATEST_COMPOUND_REQUESTED,
    payload: {
      currentList,
      data,
    },
  };
};

export const clearRegisterData = () => {
  return {
    type: CLEAR_REGISTER_DATA_REQUESTED,
  };
};

export const generateImage = (result, backgroundColor, dataSource) => {
  return {
    type: GENERATE_IMAGE_REQUESTED,
    payload: {
      result,
      backgroundColor,
      dataSource,
    },
  };
};
