import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
  takeLeading,
} from "redux-saga/effects";
import {
  EXPLORE_PAGE_FILTER_REQUESTED,
  EXPLORE_PAGE_FILTER_SUCCESS,
  EXPLORE_PAGE_GET_DATA_FAILED,
  EXPLORE_PAGE_GET_DATA_REQUESTED,
  EXPLORE_PAGE_GET_DATA_SUCCESS,
  EXPLORE_PAGE_SEARCH_FAILED,
  EXPLORE_PAGE_SEARCH_REQUESTED,
  EXPLORE_PAGE_SEARCH_SUCCESS,
  EXPLORE_PAGE_RESET_SEARCH_REQUESTED,
  EXPLORE_PAGE_RESET_SEARCH_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "axios";
import { EXPLORE_PAGE_TAB_OPTIONS, config } from "../../constants/Config";
import _ from "lodash";

const searchParamsRequest = async ({
  searchTerm = "",
  dataSources = [],
  type,
  searchOps,
}) => {
  const widgetType =
    type === EXPLORE_PAGE_TAB_OPTIONS.ALL
      ? ["COMPOUND", "PROCESS", "PROJECT"]
      : [type];

  const normalizeWidgetType = _.map(widgetType, (currentType) => {
    let normalizedType = currentType;

    if (currentType === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS) {
      normalizedType = "PROCESS";
    }

    if (currentType === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND) {
      normalizedType = "COMPOUND";
    }

    if (currentType === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS) {
      normalizedType = "PROJECT";
    }

    return normalizedType;
  });

  let sourceName = "";

  if (_.isArray(dataSources) && (dataSources || []).length > 0) {
    sourceName = dataSources[0];
  } else {
    sourceName = dataSources;
  }

  const queryParams = new URLSearchParams({
    "source name": sourceName,
    "join type": "OR",
    "search operator": searchOps,
    "field value or both": "VALUE",
    widgetType: normalizeWidgetType,
  });

  return axios.post(
    `${config.SRD_API_URL}/search/parameters?${queryParams}`,
    _.flatten([searchTerm]),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

const searchParamsDocumentRequest = async ({
  searchTerm = "",
  dataSources = [],
  type,
  searchOps,
}) => {
  const searchDomains =
    type === EXPLORE_PAGE_TAB_OPTIONS.ALL ? ["ALL"] : [type];

  const normalizedDomains = _.map(searchDomains, (domain) => {
    let normalizedType = domain;

    if (domain === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_DOCUMENTS) {
      normalizedType = "DOCUMENTS";
    }

    if (domain === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND) {
      normalizedType = "COMPOUND";
    }

    return normalizedType;
  });

  const queryParams = new URLSearchParams({
    "source names": dataSources,
    "search operator": searchOps,
    "fulltext search operator": searchOps,
    // widgetType: ["COMPOUND", "SYSTEM", "USER", "PROJECT", "PROCESS"],
    "search domains": normalizedDomains,
  });

  return axios.post(
    `${config.SRD_API_URL}/search/global?${queryParams}`,
    [searchTerm],
    {
      headers: {
        Accept: "application/json;charset=utf-8",
        "Content-Type": "application/json",
      },
    }
  );
};

// const fetchAllData = async ({ widgetIds }) => {
//   let promises = [];

//   _.forEach(widgetIds, (widgetDetail) => {
//     const type = (widgetDetail?.type || "").toUpperCase();
//     return axios.get(
//       `${config.SRD_API_URL}/metadata/widget?sourceName=SRD&widgetId=${widgetDetail?.id}&type=${type}`
//     );
//   });

//   return Promise.all(promises).then((results) => {
//     console.log("all", results);
//     return results;
//   });
// };

const fetchData = async ({ widget }) => {
  return axios.get(
    `${config.SRD_API_URL}/metadata/widget?&widgetId=${widget?.id}&type=${(
      widget?.type || ""
    ).toUpperCase()}`
  );
};

const fetchDocumentData = async ({ widget }) => {
  return axios.get(
    `${config.SRD_API_URL}/repository/document?documentId=${widget?.id}`
  );
};

const request = async () => {
  return setTimeout(() => {}, 10);
};

function* fetchParams(action) {
  try {
    const request = action.payload?.includeDocument
      ? searchParamsDocumentRequest
      : searchParamsRequest;

    const result = yield call(request, action.payload);

    if (result && result.data) {
      const widgetIds = result.data;
      let widgetIdsNormalized = [];

      for (const [key, value] of Object.entries(widgetIds)) {
        let targetValue = value;

        if (key === "structures" || key === "processes") {
          targetValue = _.get(value, "hits");
          _.forEach(targetValue, (item) => {
            widgetIdsNormalized.push({
              id: item,
              type: key,
            });
          });
        } else {
          // eslint-disable-next-line no-loop-func
          _.forEach(value, (item) => {
            widgetIdsNormalized.push({
              id: item,
              type: key,
            });
          });
        }
      }

      if (
        action.payload?.type === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS ||
        action.payload?.type === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND ||
        action.payload?.type === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_DOCUMENTS ||
        action.payload?.type === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS
      ) {
        yield put({
          type: EXPLORE_PAGE_SEARCH_SUCCESS,
          payload: {
            widgetIds,
            widgetIdsNormalized,
            structuresTableData: _.map(widgetIdsNormalized, (w) => w?.id),
          },
        });
      } else {
        yield put({
          type: EXPLORE_PAGE_SEARCH_SUCCESS,
          payload: {
            widgetIds,
            widgetIdsNormalized,
          },
        });
      }
    }
  } catch (error) {
    yield put({
      type: EXPLORE_PAGE_SEARCH_FAILED,
    });
  }
}

function* fetchPaginatedData(action) {
  // try {
  if (
    action.payload?.widget?.type !== "structures" &&
    action.payload?.widget?.type !== "processes"
  ) {
    const request =
      action.payload?.widget?.type === "documents"
        ? fetchDocumentData
        : fetchData;

    const result = yield call(request, action.payload);

    if (
      result &&
      result.data
      // checkWidgetAccess(
      //   availableWidgets,
      //   widgetName,
      //   role,
      //   action?.payload?.widget?.type
      // )
    ) {
      // const filteredParameters =
      //   filterParameterByRole(
      //     availableWidgets,
      //     result.data?.widgetType,
      //     result.data?.parameters || {},
      //     role
      //   ) || {};

      const data = {
        ...result.data,
        parameters: result.data?.parameters || {},
      };

      yield put({
        type: EXPLORE_PAGE_GET_DATA_SUCCESS,
        payload: {
          ...data,
          type: action.payload?.widget?.type,
          total: action.payload?.total,
          currentIndex: action.payload?.currentIndex,
        },
      });
    } else {
      yield put({
        type: EXPLORE_PAGE_GET_DATA_FAILED,
      });
    }
  } else {
    // eslint-disable-next-line no-unused-vars
    const result = yield call(request);
    yield put({
      type: EXPLORE_PAGE_GET_DATA_SUCCESS,
      payload: {
        ...action.payload?.widget?.id,
        type: action.payload?.widget?.type,
        total: action.payload?.total,
        isStructure: true,
      },
    });
  }
  // } catch (error) {
  //   yield put({
  //     type: EXPLORE_PAGE_GET_DATA_FAILED,
  //   });
  // }
}

function* fetchFilterByType(action) {
  yield put({
    type: EXPLORE_PAGE_FILTER_SUCCESS,
    payload: {
      type: action.payload.type,
    },
  });
}

function* handleResetSearch() {
  yield put({
    type: EXPLORE_PAGE_RESET_SEARCH_SUCCESS,
  });
}

export function* getParams() {
  yield takeLeading(EXPLORE_PAGE_SEARCH_REQUESTED, fetchParams);
}

export function* getPaginatedData() {
  yield takeEvery(EXPLORE_PAGE_GET_DATA_REQUESTED, fetchPaginatedData);
}

export function* filterByType() {
  yield takeEvery(EXPLORE_PAGE_FILTER_REQUESTED, fetchFilterByType);
}

export function* resetExploreSearchResults() {
  yield takeLatest(EXPLORE_PAGE_RESET_SEARCH_REQUESTED, handleResetSearch);
}

export default function* rootSaga() {
  yield all([
    fork(getParams),
    fork(getPaginatedData),
    fork(filterByType),
    fork(resetExploreSearchResults),
  ]);
}
