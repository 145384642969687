import React, { useState } from "react";
import PT from "prop-types";
import { Link } from "react-router-dom";
import { Button, Select } from "antd";
import { putProject } from "../../../appRedux/services/Project";
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import { useMsal } from "@azure/msal-react";

const ProcessIdCard = ({
  message,
  sourceName,
  id,
  projects,
  onSuccess,
  onError,
}) => {
  const [selectedProject, setSelectedProject] = useState({});
  const [loading, setLoading] = useState(false);
  const [assigned, setAssigned] = useState(false);

  const { accounts } = useMsal();

  const userName = `${accounts[0]?.username}`;

  const onAssign = () => {
    // find project
    const currentProject = _.find(projects, {
      projectId: selectedProject?.projectId,
    });

    putProject(
      sourceName,
      id,
      currentProject?.processSteps || [],
      setLoading,
      () => {
        setAssigned(true);
        onSuccess();
      },
      onError,
      selectedProject,
      userName
    );
  };

  return (
    <div className="gx-mt-3 drawing-result ant-alert ant-alert-info ant-alert-with-description ant-alert-no-icon">
      <div className="ant-alert-content">
        <div className="ant-alert-message">
          <span>
            {message || "Process ID"}:{" "}
            <Link to={`/${sourceName}/process/${id}`} target="_blank" replace>
              {id}
            </Link>
          </span>
        </div>

        {loading ? (
          <CircularProgress
            className="gx-loader-400 loader"
            style={{ width: "50px", height: "50px" }}
          />
        ) : (
          <>
            {!assigned ? (
              <div className="gx-mt-3">
                <div>
                  <span>Assign to a Project:</span>
                </div>

                <Select
                  className="gx-width-150 gx-mt-2"
                  onChange={(e) => {
                    const foundProject = _.find(projects, { projectId: e });
                    setSelectedProject(foundProject);
                  }}
                  placeholder="Select Project"
                >
                  {projects.map((project) => (
                    <Select.Option key={project?.projectId}>
                      {project?.name}
                    </Select.Option>
                  ))}
                </Select>

                <div className="gx-mt-2">
                  <Button
                    size="small"
                    type="primary"
                    disabled={!selectedProject?.id}
                    onClick={onAssign}
                  >
                    Assign
                  </Button>
                </div>
              </div>
            ) : (
              <span>
                Process is assigned to Project{" "}
                <Link
                  to={`/${sourceName}/project/${selectedProject?.id}`}
                  replace
                >
                  {selectedProject?.name}
                </Link>
              </span>
            )}
          </>
        )}
      </div>
    </div>
  );
};

ProcessIdCard.defaultProps = {
  id: "Process Is Registered",
  message: "",
  sourceName: "",
  projects: [],
};

ProcessIdCard.propTypes = {
  id: PT.string,
  message: PT.string,
  sourceName: PT.string,
  projects: PT.arrayOf(PT.shape()),
  onSuccess: PT.func.isRequired,
  onError: PT.func.isRequired,
};

export default ProcessIdCard;
