import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";

import "./style.css";
import { Collapse, Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import RowEditable from "../RowEditable";
import _ from "lodash";

const RowTable = ({
  field,
  value,
  items,
  info,
  addMode,
  isEditable,
  onChange,
  isAccessible,
}) => {
  let form = {};

  const renderHeader = (
    <>
      <span>{field || "Sources"}</span>
      {info.length ? (
        <Tooltip title={info}>
          <InfoCircleTwoTone size="small" className="gx-ml-2 gx-pointer" />
        </Tooltip>
      ) : null}
    </>
  );

  // If value is not editable and value is empty, return null.
  if (!isEditable && !value) {
    return null;
  }

  if (!isAccessible) {
    return null;
  }

  return (
    <div className={"card-item"} key={uuidv4()}>
      <Collapse defaultActiveKey={["1"]} ghost>
        <Collapse.Panel header={renderHeader} key="1">
          {([items] || []).map((item, index) => (
            <RowEditable
              field={item?.field}
              index={index}
              value={_.get(value, item?.field)}
              name={item?.field}
              onChange={(val) => {
                form[item?.field] = val;
                onChange(form);
              }}
              isEditable={isEditable}
              addMode={addMode}
            />
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

RowTable.defaultProps = {
  field: "",
  items: [],
  info: "",
  isEditable: false,
  addMode: false,
  value: {},
};

RowTable.propTypes = {
  field: PT.string,
  items: PT.arrayOf(PT.shape()),
  info: PT.string,
  isEditable: PT.bool,
  addMode: PT.bool,
  onChange: PT.func.isRequired,
  value: PT.shape(),
};

export default RowTable;
