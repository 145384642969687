/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DATA_SEARCH_OPERATORS } from "../../../constants/Config";
import { handleFormValuesChange } from "../../../util/search";
import InputTag from "../../InputTag";

const GenericDataSearchForm = ({
  setTargetValue,
  onSearch,
  field,
  label,
  availableWidgetParams,
  currentSelection,
  setCurrentSelection,
  setCurrentType,
  targetValue,
  currentType,
}) => {
  const [form] = useForm();
  const [targetInputType, setTargetInputType] = useState("text");
  const entityIdsRef = useRef();

  const selectionField =
    _.find(availableWidgetParams, { name: currentSelection }) || {};

  useEffect(() => {
    if (!currentType) {
      setCurrentType(DATA_SEARCH_OPERATORS[1].value);
    }
  }, []);

  const formRef = useRef();

  return (
    <Form
      layout="inline"
      ref={formRef}
      className="gx-d-flex"
      onFinish={({ selectedField }) => {
        if (targetInputType === "tag") {
          const ids = entityIdsRef.current.changeVal();
          onSearch({ inputType: targetInputType, targetValue: ids });
        } else {
          onSearch({ inputType: targetInputType, targetValue: selectedField });
        }
      }}
      initialValues={{
        operator: DATA_SEARCH_OPERATORS[1].value,
        parameter: currentSelection,
      }}
      form={form}
      onValuesChange={(changedValues) =>
        handleFormValuesChange(changedValues, setTargetInputType)
      }
    >
      <Form.Item
        name="parameter"
        rules={[
          {
            required: true,
            message: `Please select parameter`,
          },
        ]}
      >
        <Select
          size="small"
          showSearch
          placeholder="Select parameter"
          options={_.map(availableWidgetParams, (param, index) => ({
            key: index,
            label: param?.label,
            value: `${param?.value}`,
          }))}
          value={currentSelection}
          defaultValue={currentSelection}
          dropdownMatchSelectWidth={false}
          onChange={(e) => {
            const foundName = _.find(availableWidgetParams, { value: e });

            if (foundName) {
              setCurrentSelection(foundName?.name);
            }
          }}
          optionFilterProp="label"
          filterOption={(input, option) =>
            (option?.label ?? "").toLowerCase().includes(input.toLowerCase())
          }
        />
      </Form.Item>
      <Form.Item
        name="operator"
        rules={[
          {
            required: true,
            message: "Please select operator",
          },
        ]}
      >
        <Select
          size="small"
          onChange={setCurrentType}
          placeholder="Select Operator"
          options={DATA_SEARCH_OPERATORS}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item name="selectedField">
        <Input
          size="small"
          type={targetInputType}
          defaultValue={targetValue}
          placeholder={`Enter ${selectionField?.field}`}
          onChange={(e) => setTargetValue(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              onSearch({
                inputType: targetInputType,
                targetValue: e.target.value,
              });
            }
          }}
          style={
            currentSelection &&
            (targetInputType === "text" || targetInputType === "number")
              ? {}
              : { display: "none" }
          }
        />
      </Form.Item>
      {targetInputType === "tag" ? (
        <div style={{ marginTop: "6px" }}>
          <InputTag sourceName={"ACT"} size="small" ref={entityIdsRef} />
        </div>
      ) : null}
      <Form.Item style={{ marginTop: "6px" }}>
        <Button
          className="gx-ml-3"
          type="primary"
          size="small"
          htmlType="submit"
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GenericDataSearchForm;
