/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useReducer,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "../../../DetailPage/Widget/Header";

import PT from "prop-types";

import { Divider } from "antd";
import { useSelector } from "react-redux";
import { getValue, parseItems } from "../../../../util/Widget";
import { checkDocumentAccess, getRoleId } from "../../../../util/auth";
import RowEditable from "../../../DetailPage/Widget/RowEditable";
import RowList from "../../Widget/RowList";
import RowSelectInput from "../../Widget/RowSelectInput";
import RowSeparator from "../../Widget/RowSeparator";
import RowTable from "../../Widget/RowTable";
import RowTableWidget from "../../Widget/RowTableWidget";
import "./style.css";

const MetaWidget = forwardRef((props, ref) => {
  const {
    id,
    systemWidgetName,
    widgetData,
    onSaveWidget,
    loading,
    onDelete,
    isDefaultMode,
    resizableMode,
    sourceName,
    pageType,
    hideInfo,
    modalView,
    document,
    collectionOptions,
  } = props;

  const widgetOptions =
    _.find(collectionOptions, { value: systemWidgetName }) || {} || {};

  const {
    field,
    image,
    titleColor,
    backgroundColor,
    widget = {},
  } = widgetOptions;

  const { rows = [] } = widget;

  const { documentRoles = [] } = useSelector(({ auth }) => auth);
  const documentRoleIds = _.map(documentRoles, (dr) => dr?.id);

  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({});
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const [addMode, setAddMode] = useState(true);

  const { searchSubjects } = document || {};

  useEffect(() => {
    if (!_.isEmpty(searchSubjects)) {
      const params = {};
      searchSubjects.forEach((item) => {
        params[item.key] = item.value;
      });
      setForm({ ...form, ...params });
    } else {
      setForm({});
    }
  }, [searchSubjects, systemWidgetName]);

  // useEffect(() => {
  //   const controller = new AbortController();
  //   if (!_.isEmpty(image)) {
  //     const { baseUrl, params, headers } = image;
  //     const parentId = getParent(id);
  //     getFromGenericUrl(
  //       parentId,
  //       baseUrl,
  //       { ...params, background: "transbg" },
  //       headers,
  //       controller,
  //       sourceName,
  //       pageType
  //     );
  //   }
  //   return () => {
  //     controller.abort();
  //   };
  // }, [widgetOptions]);

  useImperativeHandle(ref, () => ({
    getMetaWidgetForm() {
      return form;
    },
  }));

  const checkRowIsEditable = (isEditable) => {
    let objRoles = [];
    const obj = _.isObject(isEditable) ? isEditable : {};

    for (const [key, value] of Object.entries(obj)) {
      if (value) {
        objRoles.push(key);
      }
    }

    return checkDocumentAccess({
      targetRoleIds: objRoles,
      documentRoles: documentRoleIds,
    });
  };

  const checkIsWidgetEmpty = (rows = []) => {
    const row = rows[0];
    const { targetObj, value } = row || {};

    const items = getValue(widgetData, targetObj, value) || [];

    if (row?.type === "ARRAY" && !items.length) {
      return true;
    }
    return false;
  };

  const residualData = _.map(form, (item, key) => {
    const isFound = _.findIndex(rows, { name: key });
    if (isFound === -1 && key !== "tag") {
      return { field: key, value: item };
    } else {
      return null;
    }
  }).filter((item) => item);

  const onRemove = (field) => {
    const newForm = form;
    delete newForm[field];

    setForm(newForm);
    forceUpdate();
  };

  return (
    <>
      {checkIsWidgetEmpty(rows) ? null : (
        <div
          className={`widget-card ant-card ant-card-bordered gx-mr-3 ${
            (open ? "isOpen" : "", modalView ? "modal-view" : "gx-card-widget")
          }
        ${resizableMode || !isDefaultMode ? "" : "gx-width-400"}
        ${resizableMode && open ? "resizable" : ""}
        `}
          key={uuidv4()}
        >
          <Header
            title={field}
            titleColor={titleColor}
            backgroundColor={backgroundColor}
            isEditable={false}
            addMode={addMode}
            onSave={() => onSaveWidget(form)}
            setAddMode={setAddMode}
            open={open}
            setOpen={setOpen}
            loading={loading}
            info={widgetOptions?.info}
            hasDeleteButton={false}
            onDelete={onDelete}
            isDefaultMode={isDefaultMode}
            hideInfo={hideInfo}
          />

          {open && (
            <>
              <div className="ant-card-body widget-body">
                {loading ? (
                  <CircularProgress className="gx-loader-400 loader" />
                ) : (
                  <>
                    {rows.map((row, index) => {
                      console.log("ww", row, getRoleId());
                      return (
                        <>
                          {(row?.type === "EDITABLE" ||
                            row?.type === "EDITABLE_DATE") && (
                            <RowEditable
                              field={row?.field}
                              index={index}
                              name={row?.name}
                              value={_.get(form, row?.name)}
                              limit={row?.limit}
                              form={form}
                              addMode={addMode}
                              onChange={(val) => {
                                form[row?.name] = val;
                              }}
                              info={row?.info}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                              isAccessible={checkRowIsEditable(
                                row?.isAccessible,
                              )}
                              type={
                                row?.type === "EDITABLE_DATE" ? "date" : "text"
                              }
                              isCopiable={row?.isCopiable}
                              sourceName={sourceName}
                            />
                          )}
                          {row?.type === "LIST" && (
                            <RowList
                              field={row?.field}
                              name={row?.name}
                              value={_.get(form, row?.name)}
                              index={index}
                              form={form}
                              info={row?.info}
                              sourceName={sourceName}
                              onChange={(val) => {
                                form[row?.name] = JSON.stringify(val);
                              }}
                              addmode={addMode}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                              isAccessible={checkRowIsEditable(
                                row?.isAccessible,
                              )}
                              hasLink={row?.hasLink}
                            />
                          )}
                          {row?.type === "NESTED" ? (
                            <RowTable
                              field={row?.field}
                              items={parseItems(row?.items)}
                              info={row?.info}
                              addMode={addMode}
                              value={_.get(form, row?.name)}
                              onChange={(val) => {
                                form[row?.name] = val;
                              }}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                              isAccessible={checkRowIsEditable(
                                row?.isAccessible,
                              )}
                            />
                          ) : null}
                          {row?.type === "TABLE" ? (
                            <RowTableWidget
                              field={row?.field}
                              info={row?.info}
                              index={index}
                              addMode={addMode}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                              isAccessible={checkRowIsEditable(
                                row?.isAccessible,
                              )}
                            />
                          ) : null}
                          {row?.type === "SEPARATOR" && (
                            <RowSeparator title={row?.title} />
                          )}
                          {row?.type === "SELECT_INPUT" && (
                            <RowSelectInput
                              field={row?.field}
                              index={index}
                              name={row?.name}
                              options={row?.items}
                              value={_.get(form, row?.name)}
                              addMode={addMode}
                              onChange={(val) => {
                                form[row?.name] = val;
                              }}
                              info={row?.info}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                            />
                          )}
                        </>
                      );
                    })}

                    {(residualData || []).length ? (
                      <Divider>Unmapped Data</Divider>
                    ) : null}
                    {(residualData || []).map((data, index) => (
                      <RowEditable
                        field={data?.field}
                        index={index}
                        name={data?.field}
                        value={data?.value}
                        form={form}
                        addMode={false}
                        isRemovable
                        onRemove={onRemove}
                        type={data?.type === "EDITABLE_DATE" ? "date" : "text"}
                        sourceName={sourceName}
                      />
                    ))}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
});

MetaWidget.defaultProps = {
  id: "",
  systemWidgetName: "",
  widgetData: {},
  onSaveWidget: () => {},
  loading: false,
  renderResidualData: false,
  hasDeleteButton: false,
  onDelete: () => {},
  isDefaultMode: true,
  resizableMode: false,
  sourceName: "",
  pageType: "",
  hideInfo: false,
  modalView: false,
  targetMetadata: null,
  widgetOptions: [],
};

MetaWidget.propTypes = {
  id: PT.string,
  systemWidgetName: PT.string,
  widgetData: PT.shape(),
  onSaveWidget: PT.func,
  loading: PT.bool,
  renderResidualData: PT.bool,
  hasDeleteButton: PT.bool,
  onDelete: PT.func,
  isDefaultMode: PT.bool,
  resizableMode: PT.bool,
  sourceName: PT.string,
  pageType: PT.string,
  hideInfo: PT.bool,
  modalView: PT.bool,
  targetMetadata: PT.string,
  widgetOptions: PT.arrayOf(PT.shape()),
};

export default MetaWidget;
