/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PT from "prop-types";

import { v4 as uuidv4 } from "uuid";
import Header from "../Widget/Header";
import { getProjects, putProject } from "../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import "./style.css";
import Projects from "./Projects";
import { PlusCircleTwoTone } from "@ant-design/icons";
import { Form, Modal, Popover, Select } from "antd";
import { getParent } from "../../../util/Widget";
import { useMsal } from "@azure/msal-react";

const ProjectDataWidget = ({ id, title, sourceName, pageType }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [modal, setModal] = useState(false);
  const [selectedProject, setSelectedProject] = useState();
  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  const { triggerUpdate, projects = [] } = useSelector(
    ({ project }) => project
  );

  useEffect(() => {
    if (sourceName) {
      dispatch(getProjects(sourceName));
    }
  }, [triggerUpdate]);

  const foundProjects = projects.filter((project) => {
    const steps = project?.processSteps || [];
    const index = _.findIndex(steps, ["processId", id]);

    if (index !== -1) {
      return project;
    }
  });

  const foundExperiments = projects.filter((project) => {
    const steps = project?.processSteps || [];
    let found = null;
    steps.forEach((step) => {
      const { experiments = [] } = step;
      const index = _.findIndex(experiments, ["experimentId", id]);

      if (index !== -1) {
        found = 1;
      }
    });

    if (found) {
      return project;
    }
  });

  const onAddProcessToProjects = () => {
    setModal(true);
  };

  const renderForm = () => {
    return (
      <Form className="gx-p-2">
        <Form.Item name="selectProject" label="Projects">
          <Select
            name="project"
            placeholder="Select Project"
            onChange={(e) => {
              setSelectedProject(e);
            }}
          >
            {projects.map((project) => (
              <Select.Option key={project.id} value={project.projectId}>
                {project.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    );
  };

  const addProcess = () => {
    const currentProject = _.find(projects, { projectId: selectedProject });

    const { processSteps = [] } = currentProject;

    const newStep = {
      processId: id,
      stepNo: processSteps.length + 1,
      experiments: [],
    };

    const data = {
      processSteps: [...processSteps, newStep],
    };

    dispatch(putProject(sourceName, selectedProject, data, userName));
    setSelectedProject(null);
    setModal(false);
  };

  const addExperiment = () => {
    const currentProject = _.find(projects, { projectId: selectedProject });

    const { processSteps = [] } = currentProject;

    const newStep = {
      processId: getParent(id),
      stepNo: processSteps.length + 1,
      experiments: [
        {
          experimentId: id,
        },
      ],
    };

    const data = {
      processSteps: [...processSteps, newStep],
    };

    dispatch(putProject(sourceName, selectedProject, data, userName));
    setSelectedProject(null);
    setModal(false);
  };

  return (
    <>
      <div
        className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
          open ? "isOpen" : ""
        }
  `}
        key={uuidv4()}
      >
        <Header
          title={title}
          open={open}
          setOpen={setOpen}
          additionalChild={
            pageType === "experiment" && foundExperiments.length ? null : (
              <Popover content="Add Project">
                <PlusCircleTwoTone
                  className="gx-pl-2"
                  twoToneColor="#20c997"
                  onClick={onAddProcessToProjects}
                />
              </Popover>
            )
          }
        />

        {open && (
          <Projects
            projects={
              pageType === "experiment" ? foundExperiments : foundProjects
            }
            sourceName={sourceName}
          />
        )}
      </div>
      <Modal
        title={`Add Project To Process`}
        visible={modal}
        okButtonProps={{ disabled: !selectedProject }}
        okText="Add"
        onOk={() =>
          pageType === "experiment" ? addExperiment() : addProcess()
        }
        onCancel={() => setModal(false)}
      >
        {renderForm()}
      </Modal>
    </>
  );
};

ProjectDataWidget.defaultProps = {
  title: "",
  sourceName: "",
  pageType: "",
};

ProjectDataWidget.propTypes = {
  id: PT.string.isRequired,
  title: PT.string,
  sourceName: PT.string,
  pageType: PT.string,
};

export default ProjectDataWidget;
