import _ from "lodash";
import React from "react";
import {
  Button,
  Form,
  Input,
  Popconfirm,
  Popover,
  Spin,
  Table,
  Tag,
} from "antd";
import { v4 as uuidv4 } from "uuid";

import { getImageFromRmoId } from "../../appRedux/services/Search";
import ImageCarousel from "components/Registration/ImageCarousel";
import { Link } from "react-router-dom";
import { downloadDocument } from "../../appRedux/services/Document";
import { SEARCH_CUTOFF_MAX, SEARCH_CUTOFF_MIN } from "../../constants/Config";
import {
  AppstoreTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import pluralize from "pluralize";
import TableFilter from "../TableFilter";
import { checkField, getParent } from "../../util/Widget";
import { getCompoundType } from "../../util/url";
import PopoverImage from "../DetailPage/Widget/RowArray/PopoverImage";
import moment from "moment";
import { getRole } from "../../util/auth";

export const NOT_FOUND = "id not found";
export const PAGE_LIMIT = 10;
export const similarityOptions = ["BINGO_DISSIMILARITY", "BINGO_SIMILARITY"];
export const SEARCH_TYPES = {
  STRUCTURE_SEARCH: 0,
  CONCEPTS: 1,
  DOCUMENT_SEARCH: 2,
  GENERIC_SEARCH: 3,
};

export const languages = [
  { field: "en", value: "en" },
  { field: "da", value: "da" },
  { field: "nl", value: "nl" },
  { field: "fi", value: "fi" },
  { field: "fr", value: "fr" },
  { field: "de", value: "de" },
  { field: "hu", value: "hu" },
  { field: "it", value: "it" },
  { field: "nb", value: "nb" },
  { field: "pt", value: "pt" },
  { field: "ro", value: "ro" },
  { field: "ru", value: "ru" },
  { field: "es", value: "es" },
  { field: "sv", value: "sv" },
  { field: "tr", value: "tr" },
];

export const defaultLanguage = ["en"];

export const supportedMimeTypes = [
  { label: "png", name: "png" },
  { label: "xlsx", name: "application/xlsx" },
];

export const documentFields = ({ documentCollectionOptions }) => {
  const options = (documentCollectionOptions || []).map((item) => ({
    label: item?.field,
    name: item?.value,
  }));

  return [
    { name: "fullTextKeywords", label: "Full Text Keywords" },
    { name: "subset", label: "Subset" },
    { name: "entityIds", label: "entity Ids" },
    {
      name: "collections",
      label: "Collections",
      valueEditorType: "select",
      values: options,
    },
    {
      name: "mimeTypes",
      label: "Mime Types",
      valueEditorType: "select",
      values: supportedMimeTypes,
    },
    { name: "fileNames", label: "File Names" },
    { name: "descriptions", label: "Descriptions" },
    { name: "owners", label: "Owners" },
    { name: "locked", label: "Locked", valueEditorType: "checkbox" },
    { name: "lockedBy", label: "Locked By" },
    { name: "versions", label: "Versions" },
    { name: "searchSubjects", label: "Search Subjects" },
    { name: "createDates", label: "Created Dates" },
  ];
};

export const marks = {
  [SEARCH_CUTOFF_MIN]: SEARCH_CUTOFF_MIN,
  [SEARCH_CUTOFF_MAX]: SEARCH_CUTOFF_MAX,
};

export const formItemLayout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 24,
  },
};

export const renderImageForDocumentTable = ({
  mapImages,
  setMapImages,
  entityIds = [],
  sourceName,
  forceUpdate,
}) => {
  let promises = [];

  _.forEach(entityIds, (entityId) => {
    if (!_.get(entityId)) {
      promises.push(getImageFromRmoId(entityId, true, sourceName));
    }
  });

  Promise.allSettled(promises).then((images) => {
    let newImage = {};
    _.forEach(images, (image, index) => {
      if (image.status === "fulfilled") {
        if (image.value?.data) {
          newImage[images[index]] = image.value?.data;
        }
      }

      if (image.status === "rejected") {
        newImage[images[index]] = "not-found";
      }
    });

    setMapImages(_.extend(mapImages, newImage));
    forceUpdate();
  });
};

/**
 * render image
 * @param {Number} currentPage
 * @param {Number} pageSize
 * @param {Object} searchResults
 * @param {Object} mapImages
 * @param {Function} setMapImages
 */
export const renderImage = ({
  currentPage,
  pageSize,
  searchResults = [],
  mapImages,
  setMapImages,
  forceUpdate,
  isEntityIds = false,
}) => {
  const currentResult = (searchResults || []).slice(
    (currentPage - 1) * pageSize,
    (currentPage + 1) * pageSize
  );

  (currentResult || []).map(async (result) => {
    const { child } = result;

    const currentItem = _.isArray(child) && child.length > 0 ? child[0] : {};

    const sourceName = result?.source || result?.sourceName;
    const id =
      result?.rmoId ||
      result?.compoundId ||
      currentItem?.rmoId ||
      currentItem?.compoundId;
    const parentId =
      result?.rmoId ||
      getParent(result?.compoundId) ||
      currentItem?.rmoId ||
      getParent(currentItem?.compoundId);
    const originalMaterial = result?.rmoId || currentItem?.rmoId ? false : true;

    if (isEntityIds) {
      let promises = [];

      _.forEach(result?.entityIds || [], async (item) => {
        promises.push(getImageFromRmoId(item, true, sourceName));
      });

      Promise.allSettled(promises).then((images) => {
        let newImage = {};
        _.forEach(images, (image, index) => {
          if (image.status === "fulfilled") {
            if (image.value?.data) {
              newImage[(result?.entityIds || [])[index]] = image.value?.data;
            }
          }

          if (image.status === "rejected") {
            newImage[(result?.entityIds || [])[index]] = "not-found";
          }
        });
        setMapImages(_.extend(mapImages, newImage));
        forceUpdate();
      });
    }

    if (parentId) {
      return getImageFromRmoId(parentId, originalMaterial, sourceName)
        .then((image) => {
          if (image.data) {
            setMapImages(
              _.extend(mapImages, {
                [id]: image.data,
              })
            );
          }
          forceUpdate();
        })
        .catch(() => {
          setMapImages(
            _.extend(mapImages, {
              [id]: "not-found",
            })
          );
          forceUpdate();
        });
    }
  });
};

/**
 * render Structure search table
 * @param {Object} dataSource
 * @param {Object} mapImages
 * @param {Object} sourceGroups
 * @param {Object} searchResults
 */
export const renderStructureSearchTable = ({
  dataSource = [],
  mapImages,
  sourceGroups,
  searchResults,
  forceUpdate,
  setMapImages,
  loading = false,
}) => {
  return (
    <div>
      <div className="gx-text-success gx-mb-2" style={{ textAlign: "right" }}>
        {dataSource.length} {pluralize("result", dataSource.length)}
      </div>
      <Table
        loading={loading}
        className="gx-table-responsive"
        dataSource={dataSource}
        columns={[
          {
            title: "Structure",
            dataIndex: "structure",
            key: "structure",
            width: 150,
            onCell: (record, index) => {
              return {
                rowSpan: record?.rowSpan || 0,
              };
            },
            render: (p, { child }) => {
              const currentItem =
                _.isArray(child) && child.length > 0 ? child[0] : {};

              const { rmoId = "" } = currentItem;

              return (
                <>
                  {_.get(mapImages, rmoId) === "not-found" ? (
                    <span
                      className="gx-text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Structure not found
                    </span>
                  ) : _.get(mapImages, rmoId) ? (
                    <div className="search-page-image-preview">
                      <ImageCarousel
                        structureImage={[_.get(mapImages, rmoId)]}
                        noBorder
                        setVisible={() => {}}
                      />
                    </div>
                  ) : (
                    <Spin />
                  )}
                </>
              );
            },
          },
          {
            title: "Database",
            dataIndex: "source",
            key: "source",
            width: 150,
            render: (p, { rmoId, source }) => {
              return (
                <>
                  {_.get(sourceGroups, rmoId) ? (
                    <div>
                      {_.get(sourceGroups, rmoId).map((curSource, index) => (
                        <p
                          className="gx-text-success"
                          key={`source-${rmoId}-${index}`}
                        >
                          {curSource.source}
                        </p>
                      ))}
                    </div>
                  ) : (
                    <span className="gx-text-success">{source}</span>
                  )}
                </>
              );
            },
          },
          {
            title: "Process ID",
            dataIndex: "processId",
            key: "processId",
            width: 150,
            render: (p, { source, child }) => {
              const currentItems =
                _.isArray(child) && child.length > 0 ? child : [child];

              return _.map(currentItems, (currentItem) => {
                const { vendorId } = currentItem || {};

                return (
                  <div>
                    <Popover content={popoverContent(vendorId, source)}>
                      <Link
                        to={`/${source}/compound/${vendorId}`}
                        target="_blank"
                        replace
                      >
                        {vendorId}
                      </Link>
                    </Popover>
                  </div>
                );
              });
            },
          },
        ]}
        onChange={(page, filters, sorted, { currentDataSource }) => {
          renderImage({
            currentPage: page.current,
            pageSize: page.pageSize,
            searchResults: currentDataSource,
            mapImages,
            setMapImages,
            forceUpdate,
          });
        }}
      />
    </div>
  );
};

/**
 * render Structure search table
 * @param {Object} dataSource
 * @param {Object} mapImages
 * @param {Object} sourceGroups
 * @param {Object} searchResults
 */
export const renderProcessTableResults = ({
  availableDataSources = [],
  dataSource,
  loading = false,
  forceUpdate,
}) => {
  const dataSourceGrouped = _.groupBy(dataSource, "rmoId");

  const tableData = _.map(Object.entries(dataSourceGrouped), (row) => {
    const child = row[1];

    return {
      rmoId: row[0],
      child,
    };
  });

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <span className="gx-text-success">
          Total: {(dataSource || []).length}{" "}
          {pluralize("result", (dataSource || []).length)}.
        </span>
      </div>
      <Table
        loading={loading}
        className="gx-table-responsive"
        dataSource={tableData}
        columns={[
          {
            title: "Structure",
            width: 150,
            render: (p, { rmoId, child }) => {
              const { source, vendorId, processId } = child[0];

              return (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <PopoverImage
                    rmoId={vendorId || processId}
                    sourceName={source}
                    popoverWidth={50}
                    popoverHeight={50}
                    isStructure={false}
                    forceUpdate={forceUpdate}
                  />
                </div>
              );
            },
          },
          {
            title: "Database",
            dataIndex: "source",
            key: "source",
            width: 150,
            render: (p, { child }) => {
              return (
                <div
                  className="gx-d-flex"
                  style={{
                    flexDirection: "column",
                  }}
                >
                  {_.map(child, ({ source }, index) => {
                    if (source) {
                      return (
                        <p
                          key={index}
                          className="gx-text-success"
                          style={{
                            marginBottom: 0,
                          }}
                        >
                          {source}
                        </p>
                      );
                    }
                  })}
                </div>
              );
            },
          },
          {
            title: "Compound ID",
            data: "id",
            key: "id",
            width: 150,
            render: (p, { child }) => {
              return _.map(child, (item) => {
                const {
                  source,
                  processId,
                  compoundId,
                  componentType,
                  vendorId,
                } = item;

                const id = processId || compoundId || vendorId;

                return (
                  <div>
                    <Popover content={popoverContent(id, source)}>
                      <Link
                        to={`/${source}/${getCompoundType({
                          sources: availableDataSources,
                          sourceName: source,
                          id,
                        })}/${id}`}
                        target="_blank"
                        replace
                      >
                        {id}{" "}
                        {!_.isEmpty(componentType) ? (
                          <span>{_.capitalize(componentType)}</span>
                        ) : null}
                      </Link>
                    </Popover>
                  </div>
                );
              });
            },
          },
        ]}
      />
    </div>
  );
};

export const renderCompoundDataTable = ({
  sources,
  dataSource = [],
  searchInput,
  field,
  filteredResult = [],
  setFilteredResult,
  filterValue,
  setFilterValue,
  tableKey,
  mapImages,
  setMapImages,
  forceUpdate,
  formItems = [],
  availableWidgetParams,
  loading,
}) => {
  const formItemsWithKey = _.map(formItems || [], (item) => {
    let key = "";

    try {
      key = (item?.key || "").split("-")[1];
    } catch (error) {}

    return {
      key: key || "",
      value: item?.value,
    };
  });

  const fields = _.map(formItemsWithKey, (item) => item?.key);

  return (
    <div>
      <div className="gx-mb-2" style={{ textAlign: "right" }}>
        <span className="gx-text-success">
          Total: {dataSource.length} {pluralize("result", dataSource.length)}.
        </span>
        {!_.isEmpty(filterValue) ? (
          <span className="gx-ml-2 gx-text-secondary">
            Filtered by "{filterValue}": {filteredResult.length}{" "}
            {pluralize("result", filteredResult.length)}
          </span>
        ) : null}
      </div>
      <Table
        key={tableKey}
        loading={loading}
        className="gx-table-responsive"
        dataSource={dataSource}
        onChange={(page, filters, sorter, { currentDataSource }) => {
          setFilterValue((_.get(filters, "3") || [])[0]);
          setFilteredResult(currentDataSource);
          renderImage({
            currentPage: page.current,
            pageSize: page.pageSize,
            searchResults: currentDataSource,
            mapImages,
            setMapImages,
            forceUpdate,
          });
        }}
        columns={[
          {
            title: "Structure",
            dataIndex: "structure",
            key: "structure",
            width: 150,
            render: (_, { compoundId }) => {
              return (
                <>
                  {mapImages[compoundId] === "not-found" ? (
                    <span
                      className="gx-text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Structure not found
                    </span>
                  ) : mapImages[compoundId] ? (
                    <div className="search-page-image-preview">
                      <ImageCarousel
                        structureImage={[mapImages[compoundId]]}
                        noBorder
                        setVisible={() => {}}
                      />
                    </div>
                  ) : (
                    <Spin />
                  )}
                </>
              );
            },
          },
          {
            title: "Id",
            dataIndex: "compoundId",
            width: 100,
            sorter: (a, b) =>
              (a?.compoundId || "").localeCompare(b?.compoundId || ""),
            onFilter: (value, record) =>
              (record?.compoundId || "").indexOf(value) === 0,
            render: (p, { sourceName, compoundId }) => {
              return (
                <>
                  <Link
                    to={`/${sourceName}/${getCompoundType({
                      sources,
                      sourceName,
                      id: compoundId,
                    })}/${compoundId}`}
                    replace
                    target="_blank"
                  >
                    {compoundId}
                  </Link>
                </>
              );
            },
          },
          {
            title: "Source",
            dataIndex: "sourceName",
            sorter: (a, b) =>
              (a?.sourceName || "").localeCompare(b?.sourceName || ""),
          },
          {
            title: "Data",
            render: (p, { parameters = {} }) => {
              return (
                <div>
                  {_.map(parameters, (item, key) => {
                    const isHighlighted =
                      field === key || (fields || []).includes(key);

                    const targetVal = _.find(formItemsWithKey, { key: key });

                    let isValueMatch = false;

                    if (targetVal) {
                      isValueMatch = _.includes(
                        (item || "").toLowerCase(),
                        (targetVal?.value || "").toLowerCase()
                      );
                    }

                    const targetParameter =
                      _.find(availableWidgetParams, { value: key }) || {};
                    const targetValue = targetParameter?.field || key;

                    let targetItem = item;

                    if (typeof item === "object") {
                      targetItem = item?.id || "";
                    }

                    const regex = new RegExp(
                      `^\\d{4}-\\}d{2-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$`
                    );

                    if (regex.test(targetItem)) {
                      targetItem = moment(targetItem).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                    }

                    return (
                      <>
                        {item ? (
                          <Popover content={<span>{targetValue}</span>}>
                            <Tag
                              key={uuidv4()}
                              style={{ whiteSpace: "break-spaces" }}
                              color={
                                isHighlighted && isValueMatch
                                  ? "#d9f7be"
                                  : "#f0f0f0"
                              }
                            >
                              <span className="gx-text-black">
                                {targetItem}
                              </span>
                            </Tag>
                          </Popover>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              );
            },
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              close,
            }) => (
              <TableFilter
                searchInput={searchInput}
                dataIndex={"compoundId"}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                close={close}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
            onFilter: (value, record) => {
              let containsParameter = false;
              const parameters = record["parameters"] || {};

              _.forEach(parameters, (item) => {
                let targetItem = item;

                if (typeof item === "object") {
                  targetItem = item["id"] || "";
                }

                if (
                  _.isString(targetItem) &&
                  (targetItem || "")
                    .toLowerCase()
                    .includes((value || "").toLowerCase())
                ) {
                  containsParameter = true;
                }
              });

              return (
                containsParameter ||
                checkField(value, record["compoundId"]) ||
                checkField(value, record["sourceName"])
              );
            },
          },
        ]}
      />
    </div>
  );
};

export const popoverContent = (id, source, isStructure = false) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <PopoverImage
        rmoId={getParent(id)}
        sourceName={source}
        isStructure={isStructure}
      />
      <span className="gx-text-primary" style={{ textAlign: "center" }}>
        {id}
      </span>
    </div>
  );
};

export const renderDocumentTable = ({
  dataSource,
  onSelect,
  showModal = false,
  searchInput,
  filterValue,
  setFilterValue,
  filteredResult,
  setFilteredResult,
  mapImages,
  setMapImages,
  forceUpdate,
  field,
  sources,
  setShowEditDocument,
  searchTerm,
  onChangePagination = () => {},
  total,
  pageSize,
  page,
  loading,
  documentCollectionOptions,
  setPage,
  perPage,
  setPerPage,
}) => {
  return (
    <div>
      <div className="gx-text-success gx-mb-2" style={{ textAlign: "right" }}>
        <span className="gx-text-success">
          {total} {pluralize("result", total)}
        </span>
        {!_.isEmpty(filterValue) ? (
          <span className="gx-ml-2 gx-text-secondary">
            Filtered by "{filterValue}": {filteredResult.length}{" "}
            {pluralize("result", filteredResult.length)}
          </span>
        ) : null}
      </div>

      {_.isEmpty(dataSource) && !loading ? <span>No data.</span> : null}

      <Table
        className="gx-table-responsive"
        loading={loading}
        pagination={{
          total,
          pageSize,
          page,
          current: page,
          defaultCurrent: 1,
          pageSizeOptions: [5, 10, 15, 20],
          responsive: true,
          showSizeChanger: true,
          // showTotal: (t, range) => {
          //   return (
          //     <span>
          //       Total items: <span className="gx-text-primary">{t}</span>
          //     </span>
          //   );
          // },
          onChange: (newPage, newPageSize) => {
            if (newPage !== page) {
              setPage(newPage);
            }

            if (perPage !== newPageSize) {
              setPerPage(newPageSize);
            }
          },
        }}
        dataSource={dataSource}
        onChange={(page, filters, sorter, { currentDataSource }) => {
          setFilterValue((_.get(filters, "entityIds") || [])[0]);
          setFilteredResult(currentDataSource);
          onChangePagination(page?.current, page?.pageSize);
        }}
        columns={[
          {
            title: "File Name",
            dataIndex: "fileName",
            sorter: (a, b) => {
              const documentNameA =
                a?.fileName || a?.name || a?.uri || "Name is empty";
              const documentNameB =
                b?.fileName || b?.name || b?.uri || "Name is empty";
              return (documentNameA || "").localeCompare(documentNameB || "");
            },
            render: (p, document) => {
              const {
                fileName,
                documentId,
                sourceName,
                name,
                uri,
                collection,
              } = document;
              const documentName = fileName || name || uri || "Name is empty";

              const currentCollection = _.find(documentCollectionOptions, {
                value: collection,
              });

              const hasEditRole = (
                currentCollection?.downloadAccessRoles || []
              ).includes(getRole());

              return (
                <>
                  {showModal ? (
                    <>
                      <Popover content="Open detail">
                        <AppstoreTwoTone
                          className="gx-mr-3"
                          onClick={() => {
                            onSelect(document);
                          }}
                        />
                      </Popover>
                      {/* 
                      <Popover content="Save document">
                        <SaveTwoTone
                          twoToneColor="#52c41a"
                          className="gx-mr-3"
                          onClick={(e) => {
                            e.preventDefault();
                            downloadDocument(document?.id, sourceName, fileName);
                          }}
                        />
                      </Popover> */}
                      {hasEditRole ? (
                        <Popover content="Edit document">
                          <EditTwoTone
                            className="gx-mr-3"
                            onClick={() => setShowEditDocument(document)}
                          />
                        </Popover>
                      ) : null}
                      <Link
                        onClick={(e) => {
                          e.preventDefault();
                          downloadDocument(document?.id, sourceName, fileName);
                        }}
                      >
                        {documentName}
                      </Link>
                    </>
                  ) : (
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        downloadDocument(documentId, sourceName, fileName);
                      }}
                    >
                      {documentName}
                    </Link>
                  )}
                </>
              );
            },
            width: "45%",
          },
          {
            title: "Source",
            sorter: (a, b) =>
              (a?.source || a?.sourceName || "").localeCompare(
                b?.source || b?.sourceName || ""
              ),
            render: (p, { source, sourceName }) => {
              return <span>{source || sourceName}</span>;
            },
          },
          {
            title: "Data",
            render: (
              p,
              { parameters = {}, accessionNumber, source, sourceName }
            ) => {
              const src = source || sourceName;

              return (
                <div>
                  {_.map(parameters, (item, key) => {
                    if (key === "entityIds" && !_.isEmpty(item)) {
                      return (
                        <>
                          {_.map(item.split(","), (compoundId) => {
                            let isHighlighted = false;

                            try {
                              isHighlighted = _.includes(
                                (compoundId || "").toLowerCase(),
                                (searchTerm || "").toLowerCase()
                              );
                            } catch (error) {}

                            return (
                              <Popover
                                content={popoverContent(compoundId, src)}
                              >
                                <Tag
                                  className={`${
                                    isHighlighted ? "gx-text-success" : ""
                                  }`}
                                  key={uuidv4()}
                                  style={{ whiteSpace: "break-spaces" }}
                                  color={field === key ? "#d9f7be" : "#f0f0f0"}
                                >
                                  <Link
                                    to={`/${src}/${getCompoundType({
                                      sources,
                                      sourceName: src,
                                      id: compoundId,
                                    })}/${compoundId}`}
                                    replace
                                    target="_blank"
                                    className="gx-text-primary"
                                  >
                                    {compoundId}
                                  </Link>
                                </Tag>
                              </Popover>
                            );
                          })}
                        </>
                      );
                    }

                    let targetItem = item;

                    if (typeof item === "object") {
                      targetItem = item?.id || "";
                    }

                    let isHighlighted = false;

                    try {
                      if (!_.isEmpty(searchTerm)) {
                        isHighlighted = _.includes(
                          (targetItem || "").toLowerCase(),
                          (searchTerm || "").toLowerCase()
                        );
                      }
                    } catch (error) {}

                    return (
                      <>
                        {!_.isEmpty(targetItem) ? (
                          <Popover content={<span>{key}</span>}>
                            <Tag
                              key={uuidv4()}
                              style={{ whiteSpace: "break-spaces" }}
                              color={
                                field === key || isHighlighted
                                  ? "#d9f7be"
                                  : "#f0f0f0"
                              }
                            >
                              <span
                                className="gx-text-black"
                                style={{
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {targetItem}
                              </span>
                            </Tag>
                          </Popover>
                        ) : null}
                      </>
                    );
                  })}

                  {accessionNumber && (
                    <Popover content={<span>{"Accession Number"}</span>}>
                      <Tag
                        key={uuidv4()}
                        style={{ whiteSpace: "break-spaces" }}
                        color={
                          field === "accessionNumber" ? "#d9f7be" : "#f0f0f0"
                        }
                      >
                        <span
                          className="gx-text-black"
                          style={{
                            whiteSpace: "nowrap",
                          }}
                        >
                          {accessionNumber}
                        </span>
                      </Tag>
                    </Popover>
                  )}
                </div>
              );
            },
            filterDropdown: ({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              close,
            }) => (
              <TableFilter
                searchInput={searchInput}
                dataIndex={"compoundId"}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                close={close}
              />
            ),
            filterIcon: (filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            ),
            onFilter: (value, record) => {
              const entityIds = (record["entityIds"] || []).includes(value);

              return (
                entityIds ||
                checkField(value, record["fileName"]) ||
                checkField(value, record["name"]) ||
                checkField(value, record["uri"]) ||
                checkField(value, record["collection"]) ||
                checkField(value, record["source"])
              );
            },
          },
          // {
          //   title: "Image Preview",
          //   dataIndex: "imagePreview",
          //   render: (p, { entityIds = [], source }) => {
          //     const images = _.map(
          //       entityIds,
          //       (entityId) => mapImages[entityId]
          //     );

          //     return !_.isEmpty(images) ? (
          //       <div className="search-page-image-preview">
          //         <ImageCarousel
          //           structureImage={images}
          //           noBorder
          //           setVisible={() => {}}
          //         />
          //       </div>
          //     ) : null;
          //   },
          // },
        ]}
      />
    </div>
  );
};

export const renderSaveQueryModal = ({ formRef }) => {
  return (
    <Form ref={formRef}>
      <Form.Item label="Name" name="title">
        <Input />
      </Form.Item>
    </Form>
  );
};

export const renderShowQueryModal = ({ options, onSelect, onRemove }) => {
  return (
    <div style={{ width: "100%" }}>
      <Table dataSource={options} pagination={false}>
        <Table.Column title="Name" dataIndex="field"></Table.Column>
        <Table.Column
          title="Actions"
          render={(p, { field }) => (
            <>
              <Button
                type="primary"
                className="gx-mt-2"
                size="small"
                onClick={() => onSelect(field)}
              >
                Select
              </Button>

              <Popconfirm
                title="Are you sure to delete this?"
                onConfirm={() => onRemove(field)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
              </Popconfirm>
            </>
          )}
        ></Table.Column>
      </Table>
    </div>
  );
};

export const renderSearchByTable = ({
  genericData,
  dataSource,
  sources,
  availableWidgetParams,
  availableWidgets,
  currentPage,
  currentPageSize,
  onChangeTable,
  imageMap,
  totalItems,
}) => {
  return (
    <div style={{ overflow: "scroll" }}>
      <span className="gx-text-success">
        {dataSource.length} {pluralize("result", dataSource.length)}
      </span>

      <Table
        dataSource={dataSource}
        columns={[
          {
            title: "Structure",
            width: 100,
            onCell: (record, index) => {
              return {
                rowSpan: record?.rowSpan || 0,
              };
            },
            render: (p, record) => {
              const { image, child } = record;

              const currentItem =
                _.isArray(child) && child.length > 0 ? child[0] : {};

              // Fetch image from generic state.
              const structureImage =
                image || _.get(imageMap, currentItem?.compoundId);

              return (
                <ImageCarousel
                  structureImage={[structureImage]}
                  setVisible={() => {}}
                  noBorder
                  disablePreview={false}
                />
              );
            },
          },
          {
            title: "Id",
            dataIndex: "compoundId",
            // sorter: (a, b) =>
            // (a?.compoundId || "").localeCompare(b?.compoundId || ""),
            render: (p, { child, source }) => {
              let compoundId = "";
              try {
                compoundId = child[0].compoundId;
              } catch (error) {}

              return (
                <Popover content={popoverContent(compoundId, source)}>
                  <Link
                    to={`/${source}/${getCompoundType({
                      sources,
                      sourceName: source,
                      id: compoundId,
                    })}/${compoundId}`}
                    replace
                    target="_blank"
                    className="gx-text-primary"
                  >
                    {compoundId}
                  </Link>
                </Popover>
              );
            },
          },
          {
            title: "Source",
            dataIndex: "source",
            render: (p, { source }) => {
              return <span>{source}</span>;
            },
            // sorter: (a, b) => (a?.source || "").localeCompare(b?.source || ""),
          },
          // {
          //   title: "Widget name",
          //   render: (p, record) => {
          //     const { child } = record;

          //     return _.map(child, ({ widgetType }) => {
          //       const foundWidgetName =
          //         _.find(availableWidgets, { value: widgetType }) || {};

          //       return <p>{foundWidgetName?.field || widgetType}</p>;
          //     });
          //   },
          // },
          {
            title: "Data",
            render: (p, record) => {
              const { child } = record;

              return (
                <Table
                  showHeader={false}
                  footer={false}
                  pagination={false}
                  size="small"
                  dataSource={child}
                  columns={[
                    {
                      title: "",
                      render: (p, { widgetType }) => {
                        const foundWidgetName =
                          _.find(availableWidgets, { value: widgetType }) || {};
                        return <p>{foundWidgetName?.field || widgetType}</p>;
                      },
                    },
                    {
                      title: "",
                      render: (p, { parameters }) => {
                        return (
                          <>
                            {_.isEmpty(parameters) ? (
                              <span>No data found</span>
                            ) : (
                              <div>
                                {_.map(parameters, (item, key) => {
                                  const targetParameter =
                                    _.find(availableWidgetParams, {
                                      value: key,
                                    }) || {};
                                  const targetValue =
                                    targetParameter?.field || key;

                                  return (
                                    <>
                                      {item ? (
                                        <Popover
                                          content={<span>{targetValue}</span>}
                                        >
                                          <Tag
                                            key={uuidv4()}
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                            color={"#f0f0f0"}
                                          >
                                            <span className="gx-text-black">
                                              {item}
                                            </span>
                                          </Tag>
                                        </Popover>
                                      ) : null}
                                    </>
                                  );
                                })}
                              </div>
                            )}
                          </>
                        );
                      },
                    },
                  ]}
                />
              );

              // return _.map(child, ({ widgetType, parameters }) => {
              //   return (
              //     <div>
              //       {_.map(parameters, (item, key) => {
              //         const targetParameter =
              //           _.find(availableWidgetParams, { value: key }) || {};
              //         const targetValue = targetParameter?.field || key;

              //         return (
              //           <>
              //             {item ? (
              //               <Popover content={<span>{targetValue}</span>}>
              //                 <Tag
              //                   key={uuidv4()}
              //                   style={{ whiteSpace: "break-spaces" }}
              //                   color={"#f0f0f0"}
              //                 >
              //                   <span className="gx-text-black">{item}</span>
              //                 </Tag>
              //               </Popover>
              //             ) : null}
              //           </>
              //         );
              //       })}
              //     </div>
              //   );
              // });
            },
          },
        ]}
        pagination={{
          showSizeChanger: true,
          size: "small",
          current: currentPage,
          pageSize: currentPageSize,
          total: totalItems,
          pageSizeOptions: [5, 10, 15, 20],
        }}
        onChange={(
          { current, pageSize },
          filters,
          sorter,
          { currentDataSource }
        ) => {
          onChangeTable(current, pageSize, filters, sorter, currentDataSource);
        }}
      />
    </div>
  );
};
