/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "components/CircularProgress";
import CompoundInstanceHeader from "./CompoundInstanceHeader";
import {
  getAllCompoundInstancesData,
  saveCompoundInstance,
} from "../../../appRedux/services/CompoundInstance";
import _ from "lodash";
import { Descriptions, Drawer, Modal, Table } from "antd";
import XlsxPopulate from "xlsx-populate";
import { saveAs } from "file-saver";
import {
  addCompoundInstance,
  addExperiment,
} from "../../../appRedux/services/Widget";
import { NotificationManager } from "react-notifications";

import "./style.css";
import { getParent } from "../../../util/Widget";
import {
  PAGE_TYPES,
  EXPERIMENT_DATA_WIDGET,
  NAMES_AND_SYNONYMS_WIDGET_METADATA,
  DOCUMENT_COLLECTION_OPTIONS,
} from "../../../constants/Config";
import { checkRowIsAccessible, getRoleId } from "../../../util/auth";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import CloneInstance from "./CloneInstance";
import { useDispatch, useSelector } from "react-redux";
import TableModal from "./TableModal";
import { FileExcelTwoTone } from "@ant-design/icons";
import { renderCustomColumn } from "../CompositionTable/helper";
import { fetchLookupData } from "../../../appRedux/actions";
import useGetRoles from "../../../hooks/detailPage/useGetRoles";
import { getProcessDetail } from "../../../appRedux/services/Metadata";
import { useMsal } from "@azure/msal-react";

const CompoundInstanceWidget = ({
  id,
  title,
  loading,
  setLoading,
  compoundInstances,
  addInstance,
  resizableMode,
  sourceName,
  pageType,
  onDelete,
  hasDeleteButton,
  roles = [],
  cloneExperiment,
  availableWidgets,
  cloneDocuments,
  allWidgets,
  widgetData = {},
  currentRound = 4,
  widget,
}) => {
  const [open, setOpen] = useState(true);
  const [data, setData] = useState([]);
  const [firstRender, setFirstRender] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [showTableModal, setShowTableModal] = useState(false);

  const userRoles = useGetRoles();
  const currentRole = _.find(userRoles, { id: getRoleId() });

  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  const hasPermissionForAction = (
    currentRole?.qualityRegistrationDatabase || []
  ).includes(sourceName);

  const isExperiment =
    pageType === PAGE_TYPES.EXPERIMENT || pageType === PAGE_TYPES.PROCESS;

  const { collections } = useSelector(({ document }) => document);

  const dispatch = useDispatch();
  const lookupData = useSelector(({ lookup }) => lookup);

  useEffect(() => {
    if (!collectionOptions.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setCollectionOptions);
    }
  }, []);

  useEffect(() => {
    if (compoundInstances.length > 0 && !data.length && firstRender) {
      setFirstRender(false);

      const targetWidgetName = isExperiment
        ? EXPERIMENT_DATA_WIDGET
        : NAMES_AND_SYNONYMS_WIDGET_METADATA;

      getAllCompoundInstancesData(
        compoundInstances,
        sourceName,
        setData,
        targetWidgetName
      );
    }
  }, [compoundInstances]);

  const renderName = (p, { name, highlighted }) => {
    const link =
      pageType === "process" || pageType === "experiment"
        ? "experiment"
        : "quality";

    return (
      // <Link
      //   to={`/${sourceName}/${link}/${name}`}
      //   key={name}
      //   replace
      //   className={highlighted ? "gx-text-warning" : ""}
      // >
      <span
        key={name}
        className={`gx-text-primary gx-pointer ${
          highlighted ? "gx-text-warning" : ""
        }`}
        onClick={() => {
          window.location.assign(`/${sourceName}/${link}/${name}`);
        }}
      >
        {name}
      </span>
      // </Link>
    );
  };

  const addNewInstance = () => {
    setLoading(true);
    if (pageType === "process" || pageType === "experiment") {
      addExperiment(
        getParent(id),
        sourceName,
        (data) => {
          addInstance(data);

          getProcessDetail(data?.processId, sourceName, (process) => {
            let body = [];

            _.forEach(process?.processComponents || [], (pc) => {
              body.push({
                compoundId: pc?.id,
                role: pc?.role,
                type: "main_component",
                componentType: "COMPOUND_CONCEPT",
                value: 1,
                unit: "%",
              });
            });

            saveCompoundInstance(
              data?.experimentId,
              sourceName,
              body,
              pageType,
              false,
              userName
            );
          });
        },
        NotificationManager,
        () => {}
      );
    } else {
      addCompoundInstance(
        getParent(id),
        sourceName,
        addInstance,
        NotificationManager,
        () => {}
      );
    }
  };

  const tableSource = compoundInstances.map((instance, index) => {
    const foundData = _.find(
      data,
      (item) => item?.compoundId === instance?.name
    );

    const { description, abbreviation, title } = foundData?.parameters || {};

    return {
      ...instance,
      index,
      name: instance?.name,
      description,
      abbreviation,
      title,
    };
  });

  const tableSourceSorted = _.sortBy(tableSource, [
    function (o) {
      const splitted = (o?.name || "").split(".");
      let num = 0;
      if (splitted.length > 1) num = splitted[1];
      return Number(num);
    },
  ]);

  const onCloneInstance = (selectedWidgets, collection, document) => {
    addExperiment(
      getParent(id),
      sourceName,
      (compound) => {
        addInstance(compound);
        cloneExperiment(id, compound?.experimentId, selectedWidgets);

        if (collection) {
          const currentDocuments = _.get(collections, collection);
          const targetFile = _.find(currentDocuments, { id: document });

          cloneDocuments(compound?.experimentId, targetFile);
        }
      },
      NotificationManager
    );
  };

  const molWeight = _.get(widgetData, "compoundDetail.molecularWeight");

  // const targetWidget =
  //   _.find(allWidgets, { value: NAMES_AND_SYNONYMS_WIDGET_METADATA }) || {};

  // const filteredRows = _.filter(targetWidget?.widget?.rows, (row) =>
  //   checkRowIsAccessible(row?.isAccessible)
  // );

  // const searchInput = useRef(null);

  // const widgetParams = _.map(filteredRows || [], (row) => {
  //   return {
  //     key: row?.name,
  //     title: row?.field,
  //     dataIndex: row?.name,
  //   };
  // });

  // console.log("rows", widget);

  const rows = _.get(widget, "rows") || [];

  // const columns = [
  //   {
  //     title: "Compound Instance",
  //     key: "compoundId",
  //     dataIndex: "compoundId",
  //     fixed: "left",
  //     filterDropdown: ({
  //       setSelectedKeys,
  //       selectedKeys,
  //       confirm,
  //       clearFilters,
  //       close,
  //     }) => (
  //       <TableFilter
  //         searchInput={searchInput}
  //         dataIndex={"compoundId"}
  //         selectedKeys={selectedKeys}
  //         setSelectedKeys={setSelectedKeys}
  //         confirm={confirm}
  //         clearFilters={clearFilters}
  //         close={close}
  //       />
  //     ),
  //     filterIcon: (filtered) => (
  //       <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  //     ),
  //     onFilter: (value, record) => {
  //       let containsValue = false;
  //       // eslint-disable-next-line no-unused-vars
  //       for (const [key, targetItem] of Object.entries(record)) {
  //         if (
  //           _.isString(targetItem) &&
  //           (targetItem || "")
  //             .toLowerCase()
  //             .includes((value || "").toLowerCase())
  //         ) {
  //           containsValue = true;
  //           break;
  //         }
  //       }

  //       return containsValue;
  //     },
  //   },
  //   ...widgetParams,
  // ];

  const dataNormalized = _.map(tableSourceSorted, (instance) => {
    const targetInstance = _.find(data, { compoundId: instance?.name });

    return {
      compoundId: instance?.name,
      ...(targetInstance?.parameters || {}),
    };
  });

  const optionsWithRole = _.filter(rows, (option) =>
    checkRowIsAccessible(option?.isAccessible)
  );

  const widgetNamesToResolve = _.uniq(
    _.map(
      _.filter(optionsWithRole, (role) => role?.widgetName),
      (i) => i?.widgetName
    )
  );

  useEffect(() => {
    if (!_.isEmpty(dataNormalized)) {
      // Update
      _.forEach(dataNormalized || [], (component) => {
        const id =
          component?.compoundId || component?.instanceId || component?.id;

        if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
          _.forEach(widgetNamesToResolve, (widgetName) => {
            dispatch(fetchLookupData({ id, sourceName, widgetName }));
          });
        }
      });
    }
  }, [dataNormalized, dispatch, lookupData, sourceName]);

  const columns = _.map(optionsWithRole || [], (row) => {
    return renderCustomColumn({
      widgetName: row?.widgetName,
      field: row?.field,
      pageType,
      allWidgets,
      sourceName,
      compositions: dataNormalized,
      displayName: row?.displayName,
    });
  });

  const exportData = () => {
    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);

      const excelColumns = [
        {
          key: "name",
          title: "Name",
          dataIndex: "name",
          render: renderName,
        },
        ...columns,
      ];

      const sheetData = _.map(tableSourceSorted, (item) => {
        let row = [];

        _.forEach(item, (value, key) => {
          const index = _.findIndex(
            excelColumns,
            (target) => target?.key === key
          );

          if (index !== -1) {
            row[index] = value;
          }
        });

        return row;
      });

      try {
        const totalColumns = (sheetData || []).length
          ? sheetData[0].length || 0
          : 0;
        const headerValues = _.map(excelColumns, (column) => column?.title);
        sheetData.unshift(headerValues);
        sheet1.cell("A1").value(sheetData);

        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style({
          border: true,
          wrapText: true,
        });
      } catch (error) {
        console.log(error);
      }

      return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
      });
    });
  };

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 has-scrollbar ${
        open ? "isOpen" : ""
      }
      ${resizableMode && open ? "resizable" : ""}
      ${resizableMode ? "" : "gx-width-400"}`}
      key={uuidv4()}
    >
      <CompoundInstanceHeader
        id={id}
        title={title}
        open={open}
        setOpen={setOpen}
        onAddInstance={addNewInstance}
        onDelete={onDelete}
        hasDeleteButton={hasDeleteButton}
        loading={loading}
        hasPermissionForAction={hasPermissionForAction}
        setShowModal={setShowModal}
        pageType={pageType}
        setShowTableModal={setShowTableModal}
      />

      {open && (
        <div
          className="ant-card-body widget-body"
          style={loading ? { height: "200px" } : {}}
        >
          {loading ? (
            <CircularProgress
              className="gx-loader-400 loader"
              style={loading ? { height: "280px" } : {}}
            />
          ) : (
            <>
              <Table
                pagination={false}
                className="gx-table-responsive compound-instance-widget gx-mt-2"
                dataSource={tableSourceSorted}
                scroll={{ y: 300 }}
                columns={[
                  {
                    key: "name",
                    title: "Name",
                    dataIndex: "name",
                    render: renderName,
                  },
                  ...columns,
                ]}
              >
                {/* <Column
                  key="name"
                  title="Name"
                  dataIndex="name"
                  render={renderName}
                />
                {isExperiment ? (
                  <Column key="title" title="Title" dataIndex="title" />
                ) : (
                  <Column
                    key="abbreviation"
                    title={
                      _.find(columns, { dataIndex: "abbreviation" })?.title ||
                      "Abbreviation"
                    }
                    dataIndex="abbreviation"
                  />
                )}
                <Column
                  key="description"
                  title={
                    _.find(columns, { dataIndex: "description" })?.title ||
                    "Remarks"
                  }
                  dataIndex="description"
                /> */}
              </Table>
            </>
          )}
        </div>
      )}
      <Drawer
        placement="right"
        title="Select Widgets"
        size="large"
        open={showModal}
        onClose={() => setShowModal(false)}
      >
        <CloneInstance
          collections={collections}
          onCloneInstance={onCloneInstance}
          setShowModal={setShowModal}
          collectionOptions={collectionOptions}
          availableWidgets={availableWidgets}
        />
      </Drawer>

      <Modal
        open={showTableModal}
        onCancel={() => setShowTableModal(false)}
        onOk={() => setShowTableModal(false)}
        width={1200}
        title={
          <div className="compound-instance-widget-modal">
            <div className="left-content">
              <span>Qualities</span>
              <Descriptions className="gx-ml-3 description-title">
                <Descriptions.Item label="Molecular Formula">
                  {_.get(widgetData, "compoundDetail.molecularFormula")}
                </Descriptions.Item>
                <Descriptions.Item label="Molecular Weight">
                  {_.isNumber(molWeight)
                    ? molWeight.toFixed(currentRound)
                    : molWeight}
                </Descriptions.Item>
              </Descriptions>
            </div>

            <div className="close-icon">
              <FileExcelTwoTone
                twoToneColor="#52c41a"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  exportData();
                }}
              />
            </div>
          </div>
        }
      >
        <TableModal
          columns={[
            {
              key: "name",
              title: "Name",
              dataIndex: "name",
              render: renderName,
            },
            ...columns,
          ]}
          dataSource={tableSourceSorted}
        />
      </Modal>
    </div>
  );
};

CompoundInstanceWidget.defaultProps = {
  systemWidgetName: "",
  loading: false,
  onSaveWidget: () => {},
  title: "",
  compoundInstances: [],
  resizableMode: false,
  sourceName: "",
  pageType: "",
};

CompoundInstanceWidget.propTypes = {
  systemWidgetName: PT.string,
  onSaveWidget: PT.func,
  addInstance: PT.func.isRequired,
  loading: PT.bool,
  title: PT.string,
  compoundInstances: PT.arrayOf(PT.shape()),
  resizableMode: PT.bool,
  sourceName: PT.string,
  pageType: PT.string,
};

export default CompoundInstanceWidget;
