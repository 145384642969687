import {
  EXPLORE_PAGE_FILTER_REQUESTED,
  EXPLORE_PAGE_GET_DATA_REQUESTED,
  EXPLORE_PAGE_RESET_SEARCH_REQUESTED,
  EXPLORE_PAGE_SEARCH_REQUESTED,
} from "../../constants/ActionTypes";

export const resetSearch = () => {
  return {
    type: EXPLORE_PAGE_RESET_SEARCH_REQUESTED,
  };
};

/**
 * Search parameters
 * @param {String} searchTerm search term
 * @param {Array<String>} dataSources
 * @param {String} type {ALL | COMPOUND | PROCESS | DOCUMENT}
 * @param {String} searchOps search operators {EQ | GT | GTE | LT | LTE | STARTS_WITH | CONTAINS | IN | IS_NULL | ENDS_WITH}
 * @returns
 */
export const searchParameters = ({
  searchTerm,
  dataSources,
  type,
  includeDocument,
  searchOps = "CONTAINS",
}) => {
  return {
    type: EXPLORE_PAGE_SEARCH_REQUESTED,
    payload: {
      searchTerm,
      dataSources,
      type,
      includeDocument,
      searchOps,
    },
  };
};

/**
 * Fetch widget data
 * @param {String} widget
 * @param {Number} total number of widget request
 * @param {Number} currentIndex current index
 * @param {Number} currentIndex current index
 * @param {Object} availableWidgets available widget definitions
 * @param {String} role current user role
 * @returns
 */
export const fetchWidgetData = ({
  widget,
  total,
  currentIndex,
  availableWidgets,
  role,
}) => {
  return {
    type: EXPLORE_PAGE_GET_DATA_REQUESTED,
    payload: {
      widget,
      total,
      currentIndex,
      availableWidgets,
      role,
    },
  };
};

/**
 * Filter By Type
 * @param {String} type {ALL | COMPOUND | PROCESS | DOCUMENT}
 * @returns
 */
export const filterByType = ({ type }) => {
  return {
    type: EXPLORE_PAGE_FILTER_REQUESTED,
    payload: {
      type,
    },
  };
};
