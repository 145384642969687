import React from "react";
import PT from "prop-types";
import { Table } from "antd";
import { Link } from "react-router-dom";

const Projects = ({ projects, sourceName }) => {
  const tableData = projects.map((project, index) => ({
    key: index,
    id: project?.id,
    projectId: project?.projectId,
    projectName: project?.name,
  }));

  const renderProjectId = (p, { projectId }) => {
    return (
      <Link to={`/${sourceName}/project/${projectId}`} replace>
        {projectId}
      </Link>
    );
  };

  return (
    <div className="gx-ml-4 gx-mt-3 gx-pb-3">
      <Table
        size="small"
        className="gx-table-responsive register-process-table"
        dataSource={tableData}
        pagination={false}
      >
        <Table.Column
          title="Project Id"
          dataIndex="projectId"
          render={renderProjectId}
        />
        <Table.Column title="Project Name" dataIndex="projectName" />
      </Table>
    </div>
  );
};

Projects.defaultProps = {
  projects: [],
  sourceName: "",
};

Projects.propTypes = {
  projects: PT.arrayOf(PT.shape()),
  sourceName: PT.string,
};

export default Projects;
