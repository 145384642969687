import _ from "lodash";
import moment from "moment";
import { getLanguageItem } from "./SafetySentences";

export const convertValueSeparator = (value = "", separator) => {
  const targetSeparator = separator === "." ? "," : ".";
  return String(value).replace(separator, targetSeparator);
};

/**
 * Populate Composition Table
 * @param {*} targetSheet
 * @param {*} components
 * @param {*} lookup
 * @param {*} startingIndex
 */
export const populateCompositionData = (
  targetSheet,
  components = [],
  lookup,
  startingIndex,
  separator
) => {
  let currentIndex = startingIndex;
  components.forEach((item, index) => {
    const pageId =
      _.get(item, "compoundId") ||
      _.get(item, "instanceId") ||
      _.get(item, "id");
    const { [pageId]: data = {} } = lookup;
    const description = _.get(data, "metadata.description") || "";
    const mass = _.get(data, "molecularWeight") || 0;
    const role = item?.role || item?.type || "";
    const isReactant = role.toLowerCase() === "reactant";

    currentIndex = startingIndex + index;

    let rows = [];

    if (isReactant) {
      rows = [
        item?.compoundId || item?.instanceId || item?.id,
        item?.role || item?.type,
        "",
        description,
        "",
        "",
        "",
        convertValueSeparator(item?.value, separator),
        convertValueSeparator(mass, separator),
        "",
        "",
        {
          formula: `=IF(J${currentIndex}="","",K${currentIndex}/J${currentIndex})`,
        },
        {
          formula: `=K${currentIndex}*H${currentIndex}/100/I${currentIndex}`,
        },
        convertValueSeparator("1.00", separator),
      ];
    } else {
      rows = [
        item?.compoundId || item?.instanceId || item?.id,
        item?.role || item?.type,
        "",
        description,
        "",
        "",
        "",
        convertValueSeparator(item?.value, separator),
        convertValueSeparator(mass, separator),
        "",
        {
          formula: `=IF(N${currentIndex}="","",I${currentIndex}*M${currentIndex}/H${currentIndex}*100)`,
        },
        {
          formula: `=IF(J${currentIndex}="","",K${currentIndex}/J${currentIndex})`,
        },
        {
          formula: `=IF(N${currentIndex}="","",N${currentIndex}/N$${startingIndex}*M$${startingIndex})`,
        },
        "",
      ];
    }

    targetSheet.insertRow(currentIndex, rows);

    try {
      targetSheet.mergeCells(`B${currentIndex}`, `C${currentIndex}`);
    } catch (error) {}

    _.forEach(rows, (row, rowIndex) => {
      const firstLetter = "A".charCodeAt(0);
      const letter = String.fromCharCode(firstLetter + rowIndex);

      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      if (isReactant) {
        const targetColor =
          rowIndex >= 11 && rowIndex <= 13 ? "D6FEFE" : "FFFFA6";

        if (rowIndex >= 7) {
          if (!_.isEmpty(item?.value) && rowIndex === 7) {
          } else if (!_.isEmpty(mass) && rowIndex === 8) {
          } else {
            targetSheet.getCell(`${letter}${currentIndex}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: targetColor },
            };
          }
        }
      } else {
        const targetColor =
          rowIndex >= 10 && rowIndex <= 12 ? "D6FEFE" : "FFFFA6";

        if (rowIndex >= 7) {
          if (!_.isEmpty(item?.value) && rowIndex === 7) {
          } else if (!_.isEmpty(mass) && rowIndex === 8) {
          } else {
            targetSheet.getCell(`${letter}${currentIndex}`).fill = {
              type: "pattern",
              pattern: "solid",
              fgColor: { argb: targetColor },
            };
          }
        }
      }
    });
  });

  // Add total Row
  const totalRow = [
    "Total (Achtung, Volumen stimmt nur wenn alle Dichten bekannt sind!)",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    {
      formula: `=SUM(K${startingIndex}:K${currentIndex})`,
    },
    {
      formula: `=SUM(L${startingIndex}:L${currentIndex})`,
    },
    "",
    "",
  ];
  currentIndex++;
  targetSheet.insertRow(currentIndex, totalRow);
  try {
    targetSheet.mergeCells(`A${currentIndex}`, `G${currentIndex}`);
  } catch (error) {}
  targetSheet.getCell(`K${currentIndex}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D6FEFE" },
  };
  targetSheet.getCell(`L${currentIndex}`).fill = {
    type: "pattern",
    pattern: "solid",
    fgColor: { argb: "D6FEFE" },
  };
  targetSheet.getCell(`A${currentIndex}`).font = {
    bold: true,
  };

  // Add Border
  for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
    const firstLetter = "A".charCodeAt(0);
    const letter = String.fromCharCode(firstLetter + letterIndex);

    if (letterIndex === 10 || letterIndex === 11) {
      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "medium" },
        bottom: { style: "medium" },
        left: { style: "thin" },
        right: { style: "thin" },
      };
    } else if (letterIndex === 0 || letterIndex === 13) {
      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "medium" },
        bottom: { style: "medium" },
        left: { style: "medium" },
        right: { style: "medium" },
      };
    } else {
      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "medium" },
        bottom: { style: "medium" },
      };
    }
  }
  currentIndex++;
};

/**
 * Populate Products Table
 * @param {*} targetSheet
 * @param {*} components
 * @param {*} lookup
 * @param {*} startingIndex
 */
export const populateProductsData = (
  targetSheet,
  components = [],
  lookup,
  startingIndex,
  compositionStartingCell,
  separator
) => {
  components.forEach((item, index) => {
    const pageId =
      _.get(item, "compoundId") ||
      _.get(item, "instanceId") ||
      _.get(item, "id");
    const { [pageId]: data = {} } = lookup;
    const description = _.get(data, "metadata.description") || "";
    const mass = _.get(data, "molecularWeight");

    const currentIndex = startingIndex + index;
    const rows = [
      item?.compoundId || item?.instanceId || item?.id,
      item?.role || item?.type,
      description,
      "",
      "",
      "",
      "",
      convertValueSeparator(item?.value, separator),
      convertValueSeparator(mass, separator),
      "",
      {
        formula: `
        =IF(I${currentIndex}="","",I${currentIndex}*M${currentIndex}/H${currentIndex}*100)`,
      },
      {
        formula: `=IF(J${currentIndex}="","",K${currentIndex}/J${currentIndex})`,
      },
      {
        formula: `=IF(I${currentIndex}="","",M${
          compositionStartingCell + 1
        }*N${currentIndex})`,
      },
      "",
    ];

    targetSheet.insertRow(currentIndex, rows);

    _.forEach(rows, (row, rowIndex) => {
      const firstLetter = "A".charCodeAt(0);
      const letter = String.fromCharCode(firstLetter + rowIndex);

      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };

      const targetColor =
        rowIndex >= 10 && rowIndex <= 12 ? "D6FEFE" : "FFFFA6";

      if (rowIndex >= 7) {
        if (!_.isEmpty(item?.value) && rowIndex === 7) {
        } else if (!_.isEmpty(mass) && rowIndex === 8) {
        } else {
          targetSheet.getCell(`${letter}${currentIndex}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: targetColor },
          };
        }
      }
    });
  });
};

const addBorder = (targetSheet, currentIndex) => {
  for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
    const firstLetter = "A".charCodeAt(0);
    const letter = String.fromCharCode(firstLetter + letterIndex);

    if (letterIndex === 1 || letterIndex === 2) {
    } else {
      targetSheet.getCell(`${letter}${currentIndex}`).border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    }
  }
};

/**
 * Populate controls
 * @param {*} targetSheet
 * @param {*} id
 * @param {*} username
 */
export const populateControls = (targetSheet, id, username) => {
  const projectId = "X-1";
  targetSheet.getCell("C2").value = projectId;
  targetSheet.getCell("C3").value = id;
  targetSheet.getCell("C5").value = username;
  targetSheet.getCell("C6").value = moment().format("DD MMM YYYY");
};

export const populateSafetySentences = async (
  targetSheet,
  components,
  lookup,
  headerName,
  safetySentenceLanguageMap,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  workbook,
  startingIndex,
  disposalColor,
  storageColor,
  preventionColor,
  responseColor
) => {
  let currentIndex = startingIndex;

  components.forEach((item, index) => {
    const pageId = _.get(item, "instanceId") || _.get(item, "id");
    const { [pageId]: data = {} } = lookup;

    if (pageId) {
      // Quality Safety Sentences Data
      const qualitySafetySentences = _.get(data, "hskValue.data") || [];

      // Parent Safety Sentences Data
      const safetySentences =
        _.get(data, "safetySentences.parameters.data") || [];

      const group = _.map(safetySentenceTypes || {}, (item) => item);

      const elements = _.flatten(
        group.map((groupItem) => groupItem?.elements)
      ).filter((item) => item?.active);

      const hskValue = _.get(data, "safetyWidgetData.parameters.ahsk_sap");
      const hskText = _.get(data, "safetyWidgetData.parameters.ahskText_sap");

      const row = [pageId, "AHSK:", hskValue, "", "AHSK Text:", hskText, ""];

      targetSheet.insertRow(currentIndex, row, "o");

      try {
        targetSheet.mergeCells(`C${currentIndex}`, `D${currentIndex}`);
        targetSheet.mergeCells(`E${currentIndex}`, `F${currentIndex}`);
        targetSheet.mergeCells(`G${currentIndex}`, `N${currentIndex}`);
      } catch (error) {}

      try {
        targetSheet.getCell(`B${currentIndex}`).font = {
          bold: true,
        };
        targetSheet.getCell(`E${currentIndex}`).font = {
          bold: true,
        };
      } catch (error) {}
      for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
        const firstLetter = "A".charCodeAt(0);
        const letter = String.fromCharCode(firstLetter + letterIndex);

        try {
          targetSheet.getCell(`${letter}${currentIndex}`).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        } catch (error) {}
      }
      currentIndex++;

      // Add "Safety Sentences" line.
      targetSheet.insertRow(currentIndex, ["", "Safety Sentences"]);

      try {
        targetSheet.mergeCells(`B${currentIndex}`, `N${currentIndex}`);
      } catch (error) {}

      for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
        const firstLetter = "A".charCodeAt(0);
        const letter = String.fromCharCode(firstLetter + letterIndex);
        targetSheet.getCell(`${letter}${currentIndex}`).border = {
          top: { style: "thin" },
          left: { style: "thin" },
          bottom: { style: "thin" },
          right: { style: "thin" },
        };
      }
      currentIndex++;

      /**
       * If Quality Safety Sentences does not exists, use Parent data.
       */
      const usedSafetySentencesData = qualitySafetySentences.length
        ? [...qualitySafetySentences, ...safetySentences]
        : safetySentences;

      const totalData = _.uniqWith(
        usedSafetySentencesData,
        (a, b) => a?.statement === b?.statement && a?.category === b?.category
      );

      // Add H Sentences
      _.forEach(totalData, (safetySentence) => {
        const foundOption = elements.filter(
          (item) => item?.statementId === safetySentence?.statement
        );
        let pictogram = "";
        if (foundOption.length) {
          const pictogramIds = _.get(
            foundOption[0],
            "category[0].pictogramIds"
          );
          pictogram = pictogramIds.length ? pictogramIds[0] : "";
        }
        const img = getLanguageItem(pictogram, safetySentenceImageMetadata);
        const text = getLanguageItem(
          safetySentence?.statement,
          safetySentenceLanguageMap
        );

        // Add Images
        if (img) {
          const imageId = workbook.addImage({
            base64: `data:image/png;base64,${img}`,
            extension: "png",
          });

          targetSheet.addImage(imageId, {
            tl: { col: 1.3, row: currentIndex + 0.8 },
            br: { col: 2.8, row: currentIndex + 5.8 },
          });
        }

        const HSentencesRow = ["", "", "", safetySentence?.statement, "", text];
        targetSheet.insertRow(currentIndex, HSentencesRow);
        for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
          const firstLetter = "A".charCodeAt(0);
          const letter = String.fromCharCode(firstLetter + letterIndex);
          targetSheet.getCell(`${letter}${currentIndex}`).border = {
            top: { style: "thin" },
            left: { style: "thin" },
            bottom: { style: "thin" },
            right: { style: "thin" },
          };
        }
        targetSheet.getCell(`D${currentIndex}`).fill = {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "EEECE2" },
        };
        try {
          targetSheet.mergeCells(`B${currentIndex}`, `C${currentIndex}`);
          targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
          targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
        } catch (error) {}

        currentIndex++;
        targetSheet.insertRow(currentIndex, [
          "",
          "",
          "",
          "",
          "",
          getLanguageItem(safetySentence?.category, safetySentenceLanguageMap),
        ]);
        try {
          targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
          targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
        } catch (error) {}

        addBorder(targetSheet, currentIndex);
        currentIndex++;

        // Add Preventions
        const preventions =
          _.get(foundOption[0], "category[0].prevention") || [];
        preventions.forEach((prevention) => {
          const preventionsRow = [
            "",
            "",
            "",
            prevention,
            "",
            getLanguageItem(prevention, safetySentenceLanguageMap),
          ];
          targetSheet.insertRow(currentIndex, preventionsRow);
          try {
            targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
            targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
          } catch (error) {}
          addBorder(targetSheet, currentIndex);
          targetSheet.getCell(`D${currentIndex}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: preventionColor },
          };
          currentIndex++;
        });

        // Add Responses
        const responses = _.get(foundOption[0], "category[0].response") || [];
        responses.forEach((response) => {
          const responsesRow = [
            "",
            "",
            "",
            response,
            "",
            getLanguageItem(response, safetySentenceLanguageMap),
          ];
          targetSheet.insertRow(currentIndex, responsesRow);
          try {
            targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
            targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
          } catch (error) {}
          addBorder(targetSheet, currentIndex);
          targetSheet.getCell(`D${currentIndex}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: responseColor },
          };
          currentIndex++;
        });

        // Add Disposals
        const disposals = _.get(foundOption[0], "category[0].disposal") || [];
        disposals.forEach((disposal) => {
          const disposalsRow = [
            "",
            "",
            "",
            disposal,
            "",
            getLanguageItem(disposal, safetySentenceLanguageMap),
          ];
          targetSheet.insertRow(currentIndex, disposalsRow);
          try {
            targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
            targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
          } catch (error) {}
          addBorder(targetSheet, currentIndex);
          targetSheet.getCell(`D${currentIndex}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: disposalColor },
          };

          currentIndex++;
        });

        // Add Storages
        const storages = _.get(foundOption[0], "category[0].storage") || [];
        storages.forEach((storage) => {
          const storagesRow = [
            "",
            "",
            "",
            storage,
            "",
            getLanguageItem(storage, safetySentenceLanguageMap),
          ];
          targetSheet.insertRow(currentIndex, storagesRow);
          try {
            targetSheet.mergeCells(`D${currentIndex}`, `E${currentIndex}`);
            targetSheet.mergeCells(`F${currentIndex}`, `N${currentIndex}`);
          } catch (error) {}
          addBorder(targetSheet, currentIndex);
          targetSheet.getCell(`D${currentIndex}`).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: storageColor },
          };

          currentIndex++;
        });

        const totalRow =
          storages.length + disposals.length + preventions.length + 1;

        if (totalRow < 7) {
          _.times(7 - totalRow, () => {
            targetSheet.insertRow(currentIndex, []);

            try {
              targetSheet.mergeCells(`D${currentIndex}`, `N${currentIndex}`);
            } catch (error) {}

            targetSheet.getCell(`N${currentIndex}`).border = {
              right: { style: "thin" },
            };

            targetSheet.getCell(`B${currentIndex}`).border = {
              left: { style: "thin" },
            };

            targetSheet.getCell(`C${currentIndex}`).border = {
              right: { style: "thin" },
            };

            currentIndex++;
          });
        }

        targetSheet.insertRow(currentIndex, []);
        for (let letterIndex = 0; letterIndex < 14; letterIndex++) {
          const firstLetter = "A".charCodeAt(0);
          const letter = String.fromCharCode(firstLetter + letterIndex);
          targetSheet.getCell(`${letter}${currentIndex}`).border = {
            top: { style: "thin" },
          };
        }
        currentIndex++;
      });
    }
  });
};

/**
 * Populate reaction image
 * @param {*} param0
 */
export const renderReactionImage = ({ imageId, targetSheet, col, row }) => {
  targetSheet.addImage(imageId, {
    tl: { col: col, row: row - 0.5 },
    br: { col: col + 5, row: row },
  });
};

/**
 * Converts svg image into png.
 * @param {String} svgData
 * @param {String} width
 * @param {String} height
 * @returns
 */
export function convertSvgToPng(svgData, width = 90, height = 100) {
  return new Promise((resolve) => {
    var canvas = document.createElement("canvas");
    var context = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;
    var img = new Image();
    img.src = svgData;

    img.onload = function () {
      context.clearRect(0, 0, width, height);
      context.drawImage(img, 0, 0, width, height);
      var pngData = canvas.toDataURL("image/png");
      resolve(pngData);
    };
  });
}

/**
 * Check if current file mimetype is belongs to excel sheet.
 * @param {*} mimType
 */
export const isExcelFile = (mimType) => {
  return (
    mimType === "application/vnd.ms-excel" ||
    mimType ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
  );
};
