import React, { useEffect } from "react";
import PT from "prop-types";
import ImageCarousel from "../ImageCarousel";
import { Link } from "react-router-dom";

const UpdateResult = ({
  sourceName,
  updateIds,
  structureImage,
  generateCompoundImage,
  result,
  molecularFormula,
  molecularWeight,
}) => {
  useEffect(() => {
    generateCompoundImage(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="widget-card ant-card ant-card-bordered gx-mr-3 gx-card-widget
    gx-width-400"
    >
      <div className="ant-card-body" style={{ backgroundColor: "white" }}>
        {structureImage && (
          <ImageCarousel
            structureImage={structureImage}
            width={150}
            height={150}
          />
        )}

        <ul className="gx-list-group">
          {updateIds.map((updateId, index) => (
            <li className="gx-text-gray" key={`result-${index}`}>
              Structure has been updated:{" "}
              <Link
                replace
                to={`/${sourceName}/compound/${updateId?.compoundId}`}
                target="_blank"
              >
                {updateId?.compoundId}
              </Link>
            </li>
          ))}
        </ul>
        <div
          className="gx-d-flex"
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          {result && (
            <div className="gx-d-flex" style={{ gap: "50px" }}>
              <span>Unique Descriptor: </span>
              <span>{result}</span>
            </div>
          )}
          {molecularWeight && (
            <div className="gx-d-flex" style={{ gap: "50px" }}>
              <span>Molecular Weight: </span>
              <span>{molecularWeight}</span>
            </div>
          )}
          {molecularFormula && (
            <div className="gx-d-flex" style={{ gap: "50px" }}>
              <span>Molecular Formula: </span>
              <span>{molecularFormula}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

UpdateResult.defaultProps = {
  sourceName: "",
  updateIds: [],
  structureImage: [],
};

UpdateResult.propTypes = {
  sourceName: PT.string,
  updateIds: PT.arrayOf(PT.shape),
  structureImage: PT.arrayOf(PT.any),
};

export default UpdateResult;
