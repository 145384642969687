/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Input from "antd/lib/input";
import Button from "antd/lib/button";
import Dropdown from "antd/lib/dropdown";
import Panel from "rc-color-picker/lib/Panel";
import "./style.css";

export default function InputColor(props) {
  const { color, onChange, placeholder } = props;

  const [internalColor, setInternalColor] = useState(color);

  useEffect(() => {
    if (color !== internalColor) {
      setInternalColor(color);
    }
  }, [color]);

  const handleChange = (color) => {
    setInternalColor(color.color);

    if (onChange) {
      onChange(color);
    }
  };

  const overlay = (
    <div>
      <Panel
        color={internalColor}
        enableAlpha={false}
        onChange={handleChange}
      />
    </div>
  );

  return (
    <>
      <Input
        className="color-picker-input"
        value={internalColor || ""}
        placeholder={placeholder}
        onChange={(e) => {
          setInternalColor(e.target.value);
          // onChange(e.target.value);
        }}
        onPressEnter={(e) => onChange({ color: e.target.value })}
        suffix={
          <Dropdown trigger={["click"]} overlay={overlay}>
            <Button
              className="color-picker-button"
              style={{ background: internalColor }}
            >
              {" "}
            </Button>
          </Dropdown>
        }
      />
    </>
  );
}
