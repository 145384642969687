/* eslint-disable no-unused-vars */
import { DeleteTwoTone, EditTwoTone, SaveTwoTone } from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Checkbox,
  Descriptions,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Tabs,
} from "antd";
import TabPane from "antd/lib/tabs/TabPane";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
  arrayMove,
} from "react-sortable-hoc";
import { getSystemWidgets } from "../../appRedux/actions";
import {
  getSystemWidget,
  getUserWidget,
  saveSystemWidget,
  saveUserWidget,
} from "../../appRedux/services/SystemWidget";
import { getSources } from "../../appRedux/services/Widget";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import PageSettingsForm from "../../components/PageSettingsForm";
import {
  CURRENT_EDITOR_OPTION,
  CURRENT_USER_LAYOUT,
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  GLOBAL_LAYOUT_SETTINGS,
  PAGE_TYPES_OPTIONS,
  SEARCH_PAGE_WIDGETS_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  USER_LAYOUT_SETTINGS,
} from "../../constants/Config";
import {
  checkDocumentAccess,
  getRole,
  getRoleId,
  getUserName,
} from "../../util/auth";
import { searchDocumentation } from "../UploadPage/helper";
import GlobalLayoutSettings from "./GlobalLayoutSettings";
import UserLayoutSettings from "./UserLayoutSettings";

import { ReactComponent as ExpandIcon } from "../../assets/vendors/expand.svg";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import AddWidgetModal from "./AddWidgetModal";
import ManageWidgets from "./ManageWidgets";

const AccountSettings = () => {
  const [form] = Form.useForm();
  const pageSettingsFormRef = useRef();

  const [currentSettings, setCurrentSettings] = useState([]);
  const [globalSettings, setGlobalSettings] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [dataWidgets, setDataWidgets] = useState([]);
  const [searchWidgets, setSearchWidgets] = useState([]);
  const [global, setGlobal] = useState();
  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [sources, setSources] = useState([]);
  const [selectedSource, setSelectedSource] = useState();
  const [selectedProcessSource, setSelectedProcessSource] = useState();
  const [selectedProject, setSelectedProject] = useState();
  const [documentations, setDocumentations] = useState([]);
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);

  const [selectedCollection, setSelectedCollection] = useState();

  const [selectedDefaultEditor, setSelectedDefaultEditor] = useState();

  const [selectedProjectName, setSelectedProjectName] = useState();

  const [currentLayout, setCurrentLayout] = useState();

  const [currentEditing, setCurrentEditing] = useState({});

  const [currentEditorOptions, setCurrentEditorOptions] = useState([]);

  const [showSortOptions, setShowSortOptions] = useState(false);
  const [showGlobalSortOptions, setShowGlobalSortOptions] = useState(false);
  const [current, setCurrent] = useState({});

  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const [defaultSourceName, setDefaultSourceName] = useState(null);
  const [pageSettings, setPageSettings] = useState({});

  const [showModal, setShowModal] = useState();
  const [showGlobalModal, setShowGlobalModal] = useState();
  const [showAddWidgetModal, setShowAddWidgetModal] = useState(false);
  const [showGlobalAddWidgetModal, setGlobalShowAddWidgetModal] =
    useState(false);

  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}-${getRole()}`;
  const dispatch = useDispatch();

  const pageName = "page-settings";
  const showTraining = pageSettings?.showTraining !== "hide";

  const pageSettingsMetadata = `PageSettingsMetadata-${
    accounts[0]?.username || getUserName()
  }`;

  const roles = useGetRoles();

  const onUpdateDocumentations = () => {
    searchDocumentation(defaultSourceName?.option, pageName, setDocumentations);
  };

  const { documentRoles = [] } = useSelector(({ auth }) => auth);
  const documentRoleIds = _.map(documentRoles, (dr) => dr?.id);

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        let op = [];

        if (currentOption?.helmEditor) {
          op.push({ label: "Helm", value: "helm" });
        }

        op.push({
          label: _.capitalize(currentOption?.option),
          value: currentOption?.option,
        });

        setCurrentEditorOptions(op);
      },
      false,
    );
  }, []);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
  }, [dispatch]);

  useEffect(() => {
    getSystemWidget(pageSettingsMetadata, setPageSettings, false);
  }, [pageSettingsMetadata]);

  useEffect(() => {
    searchDocumentation(TRAINING_DOCUMENT_SOURCE, "home", setDocumentations);
  }, []);

  useEffect(() => {
    if (!(sources || []).length) getSources(setSources, true);
  }, [sources]);

  useEffect(() => {
    if (!searchWidgets.length) {
      getSystemWidget(SEARCH_PAGE_WIDGETS_OPTIONS, setSearchWidgets);
    }
  }, [searchWidgets.length]);

  useEffect(() => {
    getUserWidget(DEFAULT_SOURCE_NAME, userName, setDefaultSourceName, false);
  }, [userName]);

  useEffect(() => {
    getUserWidget(USER_LAYOUT_SETTINGS, userName, setCurrentSettings, false);
  }, [userName]);

  useEffect(() => {
    getUserWidget(GLOBAL_LAYOUT_SETTINGS, "Admin", setGlobalSettings, false);
  }, []);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }

    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getUserWidget(CURRENT_USER_LAYOUT, userName, setCurrentLayout, false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rolesOptions = roles.map((item) => ({
    field: item.displayName,
    value: item.name,
  }));

  const availableWidgets = [...systemWidgets, ...dataWidgets];
  const availableWidgetsWithKeys = (availableWidgets || []).map(
    (widget, index) => ({
      ...widget,
      label: `${widget?.field} - ${widget?.value}`,
      key: index,
    }),
  );

  const searchWidgetsWithKeys = (searchWidgets || []).map((widget, index) => ({
    ...widget,
    label: `${widget?.field} - ${widget?.value}`,
    key: index,
  }));

  // Filter search widgets by current user role.
  const filteredSearchWidgets = _.filter(
    searchWidgetsWithKeys || [],
    (widget) => {
      const currentWidgetRoles = widget?.roles || [];
      if (currentWidgetRoles.includes(getRoleId())) {
        return true;
      }

      return true;
    },
  );

  // Filter available widgets by current user role.
  const filteredAvailableWidgets = _.filter(
    availableWidgetsWithKeys || [],
    (widget) => {
      const currentWidgetRoles = widget?.roles || [];

      if (currentWidgetRoles.includes(getRoleId())) {
        return true;
      }

      return false;
    },
  );

  const formRef = useRef();

  const modalFormRef = useRef();
  const manageWidgetsRef = useRef();

  const onSortEnd = ({ oldIndex, newIndex }, name, page) => {
    const currentOptions = _.get(currentSettings, `${name}.widgets`) || [];

    const newOptions = arrayMove(currentOptions, oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      setCurrentSettings({
        ...currentSettings,
        [name]: {
          ..._.get(currentSettings, name),
          pageName: page,
          widgets: newOptions,
        },
      });
    }
  };

  const onSortGlobalEnd = ({ oldIndex, newIndex }, name, page) => {
    const currentOptions = _.get(globalSettings, `${name}.widgets`) || [];

    const newOptions = arrayMove(currentOptions, oldIndex, newIndex);
    if (oldIndex !== newIndex) {
      const currentPageSettings = _.get(globalSettings, name);

      setGlobalSettings({
        ...globalSettings,
        [name]: {
          pageName: page,
          ...currentPageSettings,
          widgets: newOptions,
        },
      });
    }
  };

  const detailPages = ["compound", "quality", "experiment", "process"];

  const DragHandle = SortableHandle(() => (
    <span className="gx-pointer gx-mr-2">
      <i className="icon icon-expand" style={{ fontSize: 12 }} />
    </span>
  ));

  const SortableItem = SortableElement(({ option, index }) => {
    const widgets = [...availableWidgets, ...searchWidgets];
    const currentWidget = _.find(widgets, { value: option });

    const isFound = !!currentWidget;

    return (
      <div className="gx-ml-3" key={index}>
        <DragHandle />
        <span className={`${!isFound ? "gx-text-warning" : ""}`}>
          {isFound ? currentWidget?.field : `${option} (widget not found)`}
        </span>
      </div>
    );
  });

  const SortableList = SortableContainer((options) => {
    return (
      <>
        {(options?.options || []).length > 0 ? (
          <div>
            {options?.options.map((option, index) => (
              <div key={index} className="gx-mt-1 gx-mb-1">
                <SortableItem
                  key={`step-${index}`}
                  index={index}
                  option={option}
                  name={options?.name}
                />
              </div>
            ))}
          </div>
        ) : null}
      </>
    );
  });

  const onSaveSettings = (widgets) => {
    const updatedSettings = {
      ...currentSettings,
      [current?.name]: {
        ..._.get(currentSettings, current?.name),
        widgetOptions: widgets,
      },
    };

    saveUserWidget(
      USER_LAYOUT_SETTINGS,
      userName,
      setCurrentSettings,
      setLoading,
      updatedSettings,
      false,
      NotificationManager,
    );
    setShowSortOptions(false);
  };

  const onSaveGlobalSettings = (widgets) => {
    const updatedSettings = {
      ...globalSettings,
      [global?.name]: {
        ..._.get(globalSettings, global?.name),
        widgetOptions: widgets,
      },
    };

    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      setGlobalSettings,
      setLoading,
      updatedSettings,
      false,
      NotificationManager,
    );
    setShowGlobalSortOptions(false);
  };

  const saveGlobalSorting = () => {
    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      setGlobalSettings,
      setLoading,
      globalSettings,
      false,
      NotificationManager,
    );
  };

  const saveForm = ({ name, page, database }) => {
    // const name = formRef.current?.getFieldValue("name");
    const widgetOptions =
      (_.get(currentSettings, name) || {})?.widgetOptions || [];
    // const pageName = formRef.current?.getFieldValue("page");
    // const database = formRef.current?.getFieldValue("database") || [];

    // Check if database array is unique.
    let currentDatabases = [];
    _.forEach(currentSettings, (k, v) => {
      if (v !== name && k?.pageName === pageName) {
        currentDatabases = _.concat(currentDatabases, k?.database || []);
      }
    });

    if (!_.isEmpty(_.get(currentSettings, name)) && !editMode) {
      formRef.current.setFields([
        {
          name: "name",
          errors: ["Name already exists"],
        },
      ]);
      return;
    }

    let newCurrentSettings = {
      ...currentSettings,
      [name]: {
        ...currentSettings[name],
        pageName: page,
        widgets: options,
        widgetOptions: widgetOptions,
        database,
      },
    };

    if (editMode && currentEditing?.name !== name) {
      const targetWidgets = currentSettings[currentEditing?.name]?.widgets;
      const targetWidgetOptions =
        currentSettings[currentEditing?.name]?.widgetOptions;

      const selected = currentSettings[currentEditing?.name]?.selected;

      newCurrentSettings = {
        ...newCurrentSettings,
        [name]: {
          ...currentSettings[currentEditing?.name],
          pageName: page,
          database,
          widgets: targetWidgets,
          widgetOptions: targetWidgetOptions,
          selected,
        },
      };

      delete newCurrentSettings[currentEditing?.name];
    }

    saveUserWidget(
      USER_LAYOUT_SETTINGS,
      userName,
      setCurrentSettings,
      setLoading,
      newCurrentSettings,
      false,
      NotificationManager,
    );

    formRef.current.setFields([
      {
        name: "",
      },
    ]);

    setShowModal(false);

    // Delete objects after being saved.
    newCurrentSettings = null;
  };

  const saveFormGlobal = ({ page, name, role, database = [] }) => {
    if (!_.isEmpty(_.get(globalSettings, name)) && !editMode) {
      formRef.current.setFields([
        {
          name: "name",
          errors: ["Name already exists"],
        },
      ]);
      return;
    }

    if (!editMode) {
      let alreadyExists = false;
      _.forEach(globalSettings, (obj, key) => {
        if (obj?.pageName === page && obj?.role === role) {
          alreadyExists = true;
        }
      });

      if (alreadyExists) {
        formRef.current.setFields([
          {
            name: "role",
            errors: ["Same layout with same role already exists."],
          },
        ]);
        return;
      }
    }

    const targetName = editMode ? currentEditing?.name : name;

    const widgetOptions =
      (_.get(globalSettings, targetName) || {})?.widgetOptions || [];

    const widgets = (_.get(globalSettings, targetName) || {})?.widgets || [];

    let body = {
      ...globalSettings,
      [name]: {
        pageName: page,
        widgets,
        role,
        database,
        widgetOptions,
      },
    };

    if (editMode && currentEditing?.name !== name) {
      delete body[currentEditing?.name];
    }

    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      setGlobalSettings,
      setLoading,
      body,
      false,
      NotificationManager,
    );

    setShowGlobalModal(false);
    setEditMode(false);

    // Delete objects after being saved.
    body = null;
  };

  const renderModal = (isAdmin = false) => {
    return (
      <Form
        ref={formRef}
        className="gx-ml-4"
        onFinish={(e) => {
          isAdmin ? saveFormGlobal(e) : saveForm(e);
        }}
      >
        <Form.Item
          label="Page Type"
          name="page"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please select page type",
            },
          ]}
        >
          <Select options={PAGE_TYPES_OPTIONS} />
        </Form.Item>

        <Form.Item
          label="Layout name"
          name="name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please enter layout name",
            },
          ]}
        >
          <Input />
        </Form.Item>

        {isAdmin ? (
          <Form.Item
            label="Role"
            name="role"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Please select role",
              },
            ]}
          >
            <Select options={rolesOptions} />
          </Form.Item>
        ) : null}

        <Form.Item
          label="Database"
          name="database"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please select database",
            },
          ]}
        >
          <Select options={sourcesOptions} mode={isAdmin ? "multiple" : null} />
        </Form.Item>

        <Divider />

        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              onClick={() => {
                setShowGlobalModal(false);
                setShowModal(false);
                setEditMode(false);
              }}
            >
              Close
            </Button>
            <Button type="primary" htmlType="submit">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    );
  };

  const dataSource = _.map(currentSettings, (k, v) => ({
    name: v,
    options: k,
  }));

  const globalSettingsDataSource = _.map(globalSettings, (k, v) => ({
    name: v,
    options: k,
  }));

  const renderOptions = (p, { options = [], name, page = "" }) => {
    if (options.length && !_.isEmpty(page || "")) {
      return (
        <SortableList
          helperClass="sortableHelper"
          onSortEnd={(index) => onSortEnd(index, name, page)}
          options={options}
          name={name}
        />
      );
    }

    return null;
  };

  const renderGlobalOptions = (p, { options = [], name, page = "" }) => {
    if (options.length && !_.isEmpty(page || "")) {
      return (
        <SortableList
          helperClass="sortableHelper"
          onSortEnd={(index) => onSortGlobalEnd(index, name, page)}
          options={options}
          name={name}
        />
      );
    }

    return null;
  };

  const onEdit = (item) => {
    setShowModal(true);
    setEditMode(true);
    setCurrentEditing(item);

    setTimeout(() => {
      formRef.current?.setFields([
        {
          name: "name",
          value: item?.name,
        },
        {
          name: "options",
          value: item?.options,
        },
        {
          name: "page",
          value: item?.page,
        },
        {
          name: "database",
          value: item?.database,
        },
      ]);
    }, 300);
  };

  const onGlobalEdit = (item) => {
    setShowGlobalModal(true);
    setEditMode(true);
    setCurrentEditing(item);
    setTimeout(() => {
      formRef.current?.setFields([
        {
          name: "name",
          value: item?.name,
        },
        {
          name: "options",
          value: item?.options,
        },
        {
          name: "role",
          value: item?.role,
        },
        {
          name: "page",
          value: item?.page,
        },
        {
          name: "database",
          value: item?.database,
        },
      ]);
    }, 300);
  };

  const onDelete = (name) => {
    const removedItems = currentSettings;

    if (removedItems[name]) {
      delete removedItems[name];
    }

    saveUserWidget(
      USER_LAYOUT_SETTINGS,
      userName,
      setCurrentSettings,
      setLoading,
      removedItems,
      false,
      NotificationManager,
    );
  };

  const onDeleteGlobal = (name) => {
    const removedItems = globalSettings;

    if (removedItems[name]) {
      delete removedItems[name];
    }

    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      setGlobalSettings,
      setLoading,
      removedItems,
      false,
      NotificationManager,
    );
  };

  /**
   * On Save User Settings.
   */
  const onSaveUserSettings = async () => {
    await saveUserWidget(
      DEFAULT_SOURCE_NAME,
      userName,
      setDefaultSourceName,
      setLoading,
      {
        registration: selectedSource || defaultSourceName?.registration,
        process: selectedProcessSource || defaultSourceName?.process,
        projectName: selectedProjectName || defaultSourceName?.projectName,
        project: selectedProject || defaultSourceName?.project,
        collection: selectedCollection || defaultSourceName?.collection,
        defaultEditor:
          selectedDefaultEditor || defaultSourceName?.defaultEditor,
      },
      false,
      NotificationManager,
    );
  };

  const savePageSettingsMeta = () => {
    const layout = pageSettingsFormRef.current.getFieldValue("layout-settings");
    const hideInfo =
      pageSettingsFormRef.current.getFieldValue("hide-info-settings");
    const showTraining =
      pageSettingsFormRef.current.getFieldValue("training-settings");
    let targetData = {
      ...pageSettings,
    };

    if (layout) {
      targetData = {
        ...targetData,
        layout,
      };
    }

    if (hideInfo) {
      targetData = {
        ...targetData,
        hideInfo,
      };
    }

    if (showTraining) {
      targetData = {
        ...targetData,
        showTraining,
      };
    }

    saveSystemWidget(
      pageSettingsMetadata,
      setPageSettings,
      () => {},
      targetData,
    );

    NotificationManager.success("Successfully saved changes.");
  };

  const resetForm = () => {
    formRef.current?.setFields([
      {
        name: "name",
        value: "",
        errors: [],
      },
      {
        name: "options",
        value: [],
        errors: [],
      },
      {
        name: "role",
        value: "",
        errors: [],
      },
      {
        name: "page",
        value: "",
        errors: [],
      },
      {
        name: "database",
        value: [],
        errors: [],
      },
    ]);
  };

  const onChangeDefaultSelection = (e, name, currentWidget) => {
    const newCur = {
      ...currentWidget,
      selected: e,
    };

    // Deselect all same database
    let newSet = {};
    for (const [key, value] of Object.entries(currentSettings)) {
      if (
        value?.database === currentWidget?.database &&
        value?.pageName === currentWidget?.pageName
      ) {
        newSet = {
          ...newSet,
          [key]: {
            ...value,
            selected: false,
          },
        };
      } else {
        newSet = {
          ...newSet,
          [key]: value,
        };
      }
    }

    const newCurrentSettings = {
      ...newSet,
      [name]: { ...newCur },
    };

    saveUserWidget(
      USER_LAYOUT_SETTINGS,
      userName,
      setCurrentSettings,
      setLoading,
      newCurrentSettings,
      false,
      NotificationManager,
    );
  };

  const renderActionButtons = (p, item) => {
    const currentWidget = {
      name: item?.name,
      ..._.get(currentSettings, item?.name),
    };

    return (
      <div>
        <div className="gx-d-flex">
          <EditTwoTone
            twoToneColor="#038FDE"
            className="gx-mr-3"
            onClick={() => onEdit(item)}
          />
          <ExpandIcon
            className="gx-mr-3 expand-icon"
            onClick={() => {
              setCurrent(currentWidget);
              setShowSortOptions(true);
            }}
          />
          {/* <SaveTwoTone
            twoToneColor="#52c41a"
            className="gx-mr-3"
            onClick={() => saveSorting()}
          /> */}
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onDelete(item.name)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
          </Popconfirm>

          <div>
            <Checkbox
              size="small"
              className="gx-ml-3"
              checked={currentWidget?.selected}
              style={{ marginTop: "-5px" }}
              onChange={(e) =>
                onChangeDefaultSelection(
                  e.target.checked,
                  item?.name,
                  currentWidget,
                )
              }
            >
              {currentWidget?.selected ? (
                <span className="gx-text-primary">
                  Selected as Default on {currentWidget?.pageName} page for{" "}
                  {currentWidget?.database} database
                </span>
              ) : (
                <span>Select</span>
              )}
            </Checkbox>
          </div>
        </div>
      </div>
    );
  };

  const onCopyRole = (targetRole, targetSettings) => {
    let alreadyExists = false;
    _.forEach(globalSettings, (obj, key) => {
      if (obj?.pageName === targetSettings?.page && obj?.role === targetRole) {
        alreadyExists = true;
      }
    });

    if (alreadyExists) {
      NotificationManager.error("Same layout with same role already exists.");
      return;
    }

    const name = `${targetSettings?.name}-${targetRole}`;

    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      setGlobalSettings,
      setLoading,
      {
        ...globalSettings,
        [name]: {
          ...targetSettings,
          name,
          pageName: targetSettings?.page,
          database: targetSettings?.database,
          role: targetRole,
        },
      },
      false,
      NotificationManager,
    );
  };

  const onRemoveWidget = (dataSource, item, index, currentWidgetName) => {
    const filteredWidgetOptions = _.filter(
      dataSource || [],
      (widget) => widget?.widget !== item?.widget,
    );

    const filteredWidgets = _.filter(
      currentSettings[currentWidgetName]?.widgets || [],
      (widget) => widget !== item?.widget,
    );

    const newCurrentSettings = {
      ...currentSettings,
      [currentWidgetName]: {
        ..._.pick(current, [
          "pageName",
          "widgets",
          "widgetOptions",
          "database",
          "selected",
        ]),
        widgets: filteredWidgets,
        widgetOptions: filteredWidgetOptions,
      },
    };

    saveUserWidget(
      USER_LAYOUT_SETTINGS,
      userName,
      (setting) => {
        setCurrentSettings(setting);
        const currentWidget = {
          name: current?.name,
          ..._.get(setting, current?.name),
        };

        setCurrent(currentWidget);
      },
      setLoading,
      newCurrentSettings,
      false,
      NotificationManager,
    );
  };

  const onRemoveGlobalWidget = (dataSource, item, index, currentWidgetName) => {
    const filteredWidgetOptions = _.filter(
      dataSource || [],
      (widget) => widget?.widget !== item?.widget,
    );

    const filteredWidgets = _.filter(
      globalSettings[currentWidgetName]?.widgets || [],
      (widget) => widget !== item?.widget,
    );

    const newCurrentSettings = {
      ...globalSettings,
      [currentWidgetName]: {
        ...global,
        widgets: filteredWidgets,
        widgetOptions: filteredWidgetOptions,
      },
    };

    saveUserWidget(
      GLOBAL_LAYOUT_SETTINGS,
      "Admin",
      (setting) => {
        setGlobalSettings(setting);
        const currentWidget = {
          name: global?.name,
          ..._.get(setting, global?.name),
        };

        setGlobal(currentWidget);
      },
      setLoading,
      newCurrentSettings,
      false,
      NotificationManager,
    );
  };

  const selectCurrent = (name, page) => {
    saveUserWidget(
      CURRENT_USER_LAYOUT,
      userName,
      setCurrentLayout,
      setLoading,
      {
        ...currentLayout,
        [page]: name,
      },
      false,
      NotificationManager,
    );
  };

  const renderAction = (p, { name, page }) => {
    const currentName = _.get(currentLayout, page);

    if (name === currentName) {
      return <span>Selected as Default Layout for {page} page</span>;
    } else {
      return <Button onClick={() => selectCurrent(name, page)}>Select</Button>;
    }
  };

  const renderGlobalActionButton = (p, item) => {
    const currentItem = _.get(globalSettings, item?.name);
    const userRoles = _.get(currentItem, "usedBy") || [];

    const currentWidget = {
      name: item?.name,
      ..._.get(globalSettings, item?.name),
    };

    return (
      <div>
        <div className="gx-d-flex">
          <Popover content="Edit account">
            <EditTwoTone
              twoToneColor="#038FDE"
              className="gx-mr-3"
              onClick={() => onGlobalEdit(item)}
            />
          </Popover>

          <Popover content="Show detail">
            <ExpandIcon
              className="gx-mr-3 expand-icon"
              onClick={() => {
                setGlobal(currentWidget);
                setShowGlobalSortOptions(true);
              }}
            />
          </Popover>

          <Popover content="Save Account">
            <SaveTwoTone
              twoToneColor="#52c41a"
              className="gx-mr-3"
              onClick={() => saveGlobalSorting()}
            />
          </Popover>
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onDeleteGlobal(item.name, true)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
          </Popconfirm>
        </div>

        <div className="gx-mt-2">
          <Form onFinish={(e) => onCopyRole(e?.currentRole, item)}>
            <Form.Item
              name="currentRole"
              label="Copy to Role"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select role",
                },
              ]}
            >
              <Select
                options={rolesOptions}
                size="small"
                placeholder="Select role"
              />
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button type="primary" size="small" htmlType="submit">
                Copy
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    );
  };

  const sourcesOptions = _.map(sources, (source) => ({
    field: source?.sourceName,
    value: source?.sourceName,
  }));

  const currentRole = _.find(roles, { id: getRoleId() });

  const registrationSourcesOptions = _.filter(sourcesOptions, (option) =>
    (currentRole?.registrationDatabases || []).includes(option?.value),
  );

  const processSourcesOptions = _.filter(sourcesOptions, (option) =>
    (currentRole?.processRegistrationDatabases || []).includes(option?.value),
  );

  const dataSourceTableOptions = _.map(dataSource, (item) => ({
    page: item?.options?.pageName,
    name: item?.name,
    options: item?.options?.widgets,
    database: item?.options?.database,
  }));

  const globalTableOptions = _.map(globalSettingsDataSource, (item) => ({
    page: item?.options?.pageName,
    name: item?.name,
    widgets: item?.options?.widgets,
    widgetOptions: item?.options?.widgetOptions,
    role: item?.options?.role,
    database: item?.options?.database,
  }));

  const trainingSettings =
    _.find(documentCollectionOptions?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRoleId());

  const options = _.filter(
    current?.pageName === "home" ||
      current?.pageName === "search" ||
      global?.pageName === "home" ||
      global?.pageName === "search" ||
      global?.pageName === "collection" ||
      current?.pageName === "collection"
      ? filteredSearchWidgets
      : filteredAvailableWidgets,
    (widget) => {
      return !(currentSettings[current?.name]?.widgets || []).includes(
        widget?.value,
      );
    },
  );

  const optionsWithUniqueKeys = _.map(options, (option, index) => ({
    ...option,
    key: index,
  }));

  const selectAllWidgets = () => {
    modalFormRef.current.setFields([
      {
        name: "option",
        value: optionsWithUniqueKeys,
      },
    ]);
  };

  const collections = _.filter(
    documentCollectionOptions?.options || [],
    (item) =>
      checkDocumentAccess({
        targetRoleIds: item?.userRoles,
        documentRoles: documentRoleIds,
      }),
  );

  return (
    <div>
      <div
        className="gx-profile-banner sticky-header-component"
        style={{
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
          }}
        >
          <h3>Account Settings</h3>
          {showTraining && (
            <div>
              {(documentations || []).length ||
              isUserAllowedToManageDocumentation ? (
                <DocumentationPopover
                  documentations={documentations}
                  sourceName={TRAINING_DOCUMENT_SOURCE}
                  setOpenDocumentationSettings={setOpenDocumentationSettings}
                  hasPermission={isUserAllowedToManageDocumentation}
                />
              ) : null}
            </div>
          )}
        </div>
      </div>

      <div
        className="sticky-header-content"
        style={{
          marginTop: "80px",
        }}
      >
        <Tabs>
          <TabPane tab="User Layout Settings" key={1}>
            <UserLayoutSettings
              setShowModal={setShowModal}
              resetForm={resetForm}
              dataSourceTableOptions={dataSourceTableOptions}
              renderOptions={renderOptions}
              renderAction={renderAction}
              renderActionButtons={renderActionButtons}
            />
          </TabPane>
          {(getRole() || "").toLowerCase() === "admin" ||
          (getRole() || "").toLowerCase() === "administrator" ? (
            <TabPane tab="Global Layout Settings" key={2}>
              <GlobalLayoutSettings
                setShowGlobalModal={setShowGlobalModal}
                resetForm={resetForm}
                globalTableOptions={globalTableOptions}
                renderGlobalOptions={renderGlobalOptions}
                renderGlobalActionButton={renderGlobalActionButton}
              />
            </TabPane>
          ) : null}

          <TabPane tab="User Settings" key={3}>
            <Form wrapperCol={{ span: 5 }} className="gx-ml-3">
              <Divider>Compound Registration Page</Divider>

              <Form.Item label="Default Compound Registration Database">
                <Select
                  options={registrationSourcesOptions}
                  onChange={setSelectedSource}
                  defaultValue={defaultSourceName?.registration}
                ></Select>
              </Form.Item>

              <Divider>Process Registration Page</Divider>

              <Form.Item label="Default Process Registration Database">
                <Select
                  options={processSourcesOptions}
                  onChange={setSelectedProcessSource}
                  defaultValue={defaultSourceName?.process}
                ></Select>
              </Form.Item>

              <Divider>Project Page</Divider>

              <Form.Item label="Default Project Database">
                <Select
                  options={processSourcesOptions}
                  onChange={setSelectedProject}
                  defaultValue={defaultSourceName?.project}
                ></Select>
              </Form.Item>

              <Form.Item label="Default Project Prefix">
                <Input
                  onChange={(e) => setSelectedProjectName(e.target.value)}
                  defaultValue={defaultSourceName?.projectName}
                />
              </Form.Item>

              <Divider>Collection Page</Divider>

              <Form.Item label="Default Collection">
                <Select
                  options={_.map(collections, (collection) => ({
                    label: collection?.field,
                    value: collection?.value,
                  }))}
                  onChange={setSelectedCollection}
                  defaultValue={defaultSourceName?.collection}
                />
              </Form.Item>

              {currentEditorOptions.length ? (
                <>
                  <Divider>Default Editor</Divider>

                  <Form.Item label="Default Editor Option">
                    <Select
                      options={currentEditorOptions}
                      onChange={setSelectedDefaultEditor}
                    />
                  </Form.Item>
                </>
              ) : null}
            </Form>

            <Button size="small" type="primary" onClick={onSaveUserSettings}>
              Save Changes
            </Button>
          </TabPane>

          <TabPane tab="Page Settings" key={4}>
            {!_.isNull(pageSettings) ? (
              <PageSettingsForm
                formRef={pageSettingsFormRef}
                pageSettings={pageSettings}
                savePageSettings={savePageSettingsMeta}
              />
            ) : null}
          </TabPane>
        </Tabs>
      </div>

      <Drawer
        placement="right"
        open={showModal}
        title={`${editMode ? "Edit Layout" : "Add New Layout"}`}
        okText="Save"
        onClose={() => {
          setShowModal(false);
          setEditMode(false);
        }}
        onOk={() => {
          // saveForm();
          setEditMode(false);
        }}
        footer={null}
      >
        {renderModal()}
      </Drawer>

      <Drawer
        placement="right"
        open={showGlobalModal}
        title={`${editMode ? "Edit Global Layout" : "Add New Global Layout"}`}
        okText="Save"
        onCancel={() => {
          setShowGlobalModal(false);
          setEditMode(false);
          resetForm();
        }}
        onClose={() => setShowGlobalModal(false)}
        onOk={() => {
          saveFormGlobal();
          setEditMode(false);
        }}
        footer={null}
      >
        {renderModal(true)}
      </Drawer>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>

      <Drawer
        placement="right"
        onClose={() => setShowSortOptions(false)}
        open={showSortOptions}
        title={
          <div
            className="gx-d-flex"
            style={{ justifyContent: "space-between", width: "100%" }}
          >
            <span
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              Manage Widgets
            </span>

            <div
              style={{
                display: "flex",
              }}
            >
              <Button
                size="small"
                type="primary"
                className="gx-mt-3 gx-ml-3"
                onClick={() => setShowAddWidgetModal(true)}
              >
                Add Widget
              </Button>
              <Button
                size="small"
                type="primary"
                className="gx-mt-3"
                onClick={() => {
                  manageWidgetsRef.current.saveSettings();
                }}
              >
                Save
              </Button>
              <Button
                size="small"
                type="secondary"
                className="gx-mt-3"
                onClick={() => setShowSortOptions(false)}
              >
                Close
              </Button>{" "}
            </div>
          </div>
        }
        width={900}
        className="settingsDrawer"
      >
        <ManageWidgets
          ref={manageWidgetsRef}
          currentWidgetName={current?.name}
          widgets={current?.widgetOptions}
          onClose={() => setShowSortOptions(false)}
          onSaveSettings={onSaveSettings}
          showAddWidgetModal={showAddWidgetModal}
          setShowAddWidgetModal={setShowAddWidgetModal}
          onRemoveWidget={onRemoveWidget}
          availableWidgets={availableWidgets}
          searchWidgets={searchWidgets}
        />
      </Drawer>

      <Drawer
        placement="right"
        onClose={() => setShowGlobalSortOptions(false)}
        open={showGlobalSortOptions}
        title={
          <>
            <span className="gx-text-primary">Manage Global Settings</span>
            <Descriptions size="small" className="gx-mt-2">
              <Descriptions.Item label="Role">{global?.role}</Descriptions.Item>
              <Descriptions.Item label="Name">{pageName}</Descriptions.Item>
              <Descriptions.Item label="Page Name">
                {global?.pageName}
              </Descriptions.Item>
              <Descriptions.Item label="Database">
                {global?.database}
              </Descriptions.Item>
            </Descriptions>
          </>
        }
        width={900}
        className="settingsDrawer"
      >
        <ManageWidgets
          currentWidgetName={global?.name}
          widgets={global?.widgetOptions}
          onClose={() => setShowGlobalSortOptions(false)}
          onSaveSettings={onSaveGlobalSettings}
          showAddWidgetModal={showGlobalAddWidgetModal}
          setShowAddWidgetModal={setGlobalShowAddWidgetModal}
          onRemoveWidget={onRemoveGlobalWidget}
          availableWidgets={availableWidgets}
          searchWidgets={searchWidgets}
        />
      </Drawer>

      <Modal
        open={showAddWidgetModal}
        title="Add Widget(s)"
        onCancel={() => setShowAddWidgetModal(false)}
        footer={null}
      >
        <AddWidgetModal
          modalFormRef={modalFormRef}
          current={current}
          setCurrent={setCurrent}
          currentSettings={currentSettings}
          setCurrentSettings={setCurrentSettings}
          userName={userName}
          setLoading={setLoading}
          selectAllWidgets={selectAllWidgets}
          setShowAddWidgetModal={setShowAddWidgetModal}
          optionsWithUniqueKeys={optionsWithUniqueKeys}
          layoutName={USER_LAYOUT_SETTINGS}
        />
      </Modal>

      <Modal
        open={showGlobalAddWidgetModal}
        title="Add Global Widget(s)"
        onCancel={() => setGlobalShowAddWidgetModal(false)}
        footer={null}
      >
        <AddWidgetModal
          modalFormRef={modalFormRef}
          current={global}
          setCurrent={setGlobal}
          currentSettings={globalSettings}
          setCurrentSettings={setGlobalSettings}
          userName={"Admin"}
          setLoading={setLoading}
          selectAllWidgets={selectAllWidgets}
          setShowAddWidgetModal={setGlobalShowAddWidgetModal}
          optionsWithUniqueKeys={optionsWithUniqueKeys}
          layoutName={GLOBAL_LAYOUT_SETTINGS}
        />
      </Modal>
    </div>
  );
};

export default AccountSettings;
