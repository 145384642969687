import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { getValue, parseItems } from "../../../../util/Widget";
import { isJsonString, paginate } from "../../../../util/generic";
import _ from "lodash";
import ImageCarousel from "../../../Registration/ImageCarousel";

import "./style.css";
import { getGenericApi } from "../../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { Popover, Table } from "antd";
import PopoverImage from "./PopoverImage";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/Config";

const RowArray = ({
  row,
  sourceName,
  data,
  info = "",
  defaultPageSize = DEFAULT_PAGE_SIZE,
  hideInfo = false,
}) => {
  const { options = [], targetObj, previewImage: image, value } = row;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const items = getValue(data, targetObj, value) || [];
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(defaultPageSize);

  let optionsMap = [];

  try {
    optionsMap = _.isArray(options) ? options : JSON.parse(options);
  } catch (error) {}

  let previewImage = image;
  if (isJsonString(image)) {
    previewImage = parseItems(image);
  }

  const genericData = useSelector(({ generic }) => generic);

  useEffect(() => {
    let isSubscribed = true;
    const { baseUrl, headers, params } = previewImage || {};

    if (
      isSubscribed &&
      !_.isEmpty(previewImage) &&
      baseUrl &&
      headers &&
      params
    ) {
      const currentItems = paginate(items, currentPageSize, currentPage);

      (currentItems || []).forEach((item, index) => {
        const expectedData = _.get(genericData, [item.name, item?.rmoId]);
        const isItemInitialized = _.get(genericData, [
          item.name,
          "initialized",
        ]);
        const source = item?.source || sourceName;

        if (!isItemInitialized && !(expectedData || "").length) {
          dispatch(
            getGenericApi(
              item.name,
              source,
              item?.rmoId,
              baseUrl,
              params,
              headers
            )
          );
        }
      });
    }

    return () => {
      isSubscribed = false;
    };
  }, [
    currentPage,
    currentPageSize,
    dispatch,
    genericData,
    items,
    previewImage,
    sourceName,
  ]);

  const popoverContent = (id, source) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <PopoverImage rmoId={id} sourceName={source} />
        <span className="gx-text-primary" style={{ textAlign: "center" }}>
          {id}
        </span>
      </div>
    );
  };

  const childColumns = _.map(optionsMap, (option) => ({
    title: option?.field,
    dataIndex: option?.value,
    key: option?.value,
    render: (value, row, index) => {
      const { child } = row;

      const mappedChild = _.map(child, (item) => ({
        ...item,
        uniq: `${item?.name}-${item?.source}`,
      }));
      const uniqChild = _.uniqBy(mappedChild, "uniq");

      return (
        <>
          {_.map(uniqChild, (item, index) => {
            const isLastItem = uniqChild.length - 1 === index;

            /**
             * Target page settings defined in options field.
             */
            const targetPage = _.get(option, "targetPage") || "compound";

            return (
              <span className="gx-mr-1">
                <Popover content={popoverContent(item?.name, item?.source)}>
                  <span
                    className="gx-text-primary gx-pointer"
                    onClick={() => {
                      window.location.assign(
                        `/${item?.source}/${targetPage}/${_.get(
                          item,
                          option?.value
                        )}`
                      );
                    }}
                  >
                    {_.get(item, option?.value)}
                  </span>
                </Popover>
                {isLastItem ? null : <span className="gx-text-primary">,</span>}
              </span>
            );
          })}
        </>
      );
    },
  }));

  const columns = [
    {
      title: "Structure",
      width: 100,
      onCell: (record, index) => {
        return {
          rowSpan: record?.rowSpan || 0,
        };
      },
      render: (p, record) => {
        const { image, child } = record;

        const currentItem =
          _.isArray(child) && child.length > 0 ? child[0] : {};

        // Fetch image from generic state.
        const structureImage =
          image || _.get(genericData, [currentItem?.name, currentItem?.rmoId]);

        return (
          <ImageCarousel
            structureImage={[structureImage]}
            setVisible={() => {}}
            noBorder
            disablePreview={false}
          />
        );
      },
    },
    {
      title: "Database",
      dataIndex: "source",
      render: (text) => <span className="gx-text-success">{text}</span>,
    },
    ...childColumns,
  ];

  const dataSourceGrouped = _.groupBy(items, "rmoId");

  const dataSource = _.map(Object.entries(dataSourceGrouped), (row) => {
    const child = row[1];
    const firstItem = child[0] || {};

    const childGrouped = _.groupBy(child, "source");
    const field = getValue(data, targetObj, previewImage?.value);
    const image = _.get(genericData, [firstItem?.name, field]);

    const childRow = _.map(Object.entries(childGrouped), (childRow) => {
      return {
        source: childRow[0],
        child: childRow[1],
        image,
      };
    });

    return {
      image,
      child: childRow,
    };
  });

  const normalizedDatasource = _.map(dataSource, (ds) => {
    const childLength = (ds?.child || []).length;

    return [
      _.map(ds?.child || [], (childRow, index) => {
        if (!index) {
          return {
            image: ds?.image,
            rowSpan: childLength,
            ...childRow,
          };
        }

        return {
          ...childRow,
        };
      }),
    ];
  });

  const tableDatasource = _.flattenDeep(normalizedDatasource);

  return (
    <div className="row-array-card-wrapper">
      <Table
        dataSource={tableDatasource}
        columns={columns}
        pagination={{
          current: currentPage,
          pageSize: currentPageSize,
          showTotal: () => `Total ${(items || []).length} items`,
          showSizeChanger: true,
        }}
        size="small"
        onChange={({ current, pageSize }, filters, sorter, record) => {
          setCurrentPage(current);
          setCurrentPageSize(pageSize);
        }}
      />
    </div>
  );
};

RowArray.defaultProps = {
  row: {},
  data: {},
  info: "",
  sourceName: [],
};

RowArray.propTypes = {
  row: PT.shape(),
  data: PT.shape(),
  info: PT.string,
  sourceName: PT.string,
};

export default RowArray;
