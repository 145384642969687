import React, { useReducer, useState } from "react";
import { Button, Form, Popover, Table } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { popoverContent } from "../../../../SearchPage/helper";
import { getCompoundType } from "../../../../../util/url";
import pluralize from "pluralize";
import _ from "lodash";
import PopoverImage from "../../../Widget/RowArray/PopoverImage";
import CanvasSettings from "../../../../ExplorePage/CanvasSettings";

const SearchByStructure = ({
  onSearchByProcess,
  structureSearchResults,
  structureResultLoading,
  availableDataSources = [],
  onSelectProcess,
  searchOps,
  cutoff,
  setCutoff,
  queryStructure,
  setQueryStructure,
}) => {
  const [form] = Form.useForm();
  const matchSearch = Form.useWatch("searchOps", form);
  // eslint-disable-next-line no-unused-vars
  const [showStructureSearchForm, setShowStructureSearchForm] = useState(true);

  const dataSource = structureSearchResults;
  const loading = structureResultLoading;

  const dataSourceGrouped = _.groupBy(dataSource, "rmoId");

  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const tableData = _.map(Object.entries(dataSourceGrouped), (row) => {
    const child = row[1];

    return {
      rmoId: row[0],
      child,
    };
  });

  return (
    <div>
      <CanvasSettings
        searchOps={searchOps}
        form={form}
        matchSearch={matchSearch}
        cutoff={cutoff}
        setCutoff={setCutoff}
        onSearch={onSearchByProcess}
        queryStructure={queryStructure}
        setQueryStructure={setQueryStructure}
        setStructureImage={() => {}}
        onClose={() => setShowStructureSearchForm(false)}
        disableUpdateUserData
      />

      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <span className="gx-text-success">
            Total: {(dataSource || []).length}{" "}
            {pluralize("result", (dataSource || []).length)}.
          </span>
        </div>
        <Table
          loading={loading}
          className="gx-table-responsive"
          dataSource={tableData}
          columns={[
            {
              title: "Structure",
              width: 150,
              render: (p, { rmoId, child }) => {
                const { source } = child[0];

                return (
                  <>
                    <PopoverImage
                      rmoId={rmoId}
                      sourceName={source}
                      popoverWidth={100}
                      popoverHeight={100}
                      isStructure
                      forceUpdate={forceUpdate}
                    />
                  </>
                );
              },
            },
            {
              title: "Database",
              dataIndex: "source",
              key: "source",
              width: 150,
              render: (p, { child }) => {
                return (
                  <div
                    className="gx-d-flex"
                    style={{
                      flexDirection: "column",
                    }}
                  >
                    {_.map(child, ({ source }, index) => {
                      if (source) {
                        return (
                          <p
                            key={index}
                            className="gx-text-success"
                            style={{
                              marginBottom: 0,
                            }}
                          >
                            {source}
                          </p>
                        );
                      }
                    })}
                  </div>
                );
              },
            },
            {
              title: "Compound ID",
              data: "id",
              key: "id",
              width: 150,
              render: (p, { child }) => {
                return _.map(child, (item) => {
                  const {
                    source,
                    processId,
                    compoundId,
                    componentType,
                    vendorId,
                  } = item;

                  const id = processId || compoundId || vendorId;

                  return (
                    <div>
                      <Popover content={popoverContent(id, source)}>
                        <Link
                          to={`/${source}/${getCompoundType({
                            sources: availableDataSources,
                            sourceName: source,
                            id,
                          })}/${id}`}
                          target="_blank"
                          replace
                        >
                          {id}{" "}
                          {!_.isEmpty(componentType) ? (
                            <span>{_.capitalize(componentType)}</span>
                          ) : null}
                        </Link>
                      </Popover>
                    </div>
                  );
                });
              },
            },
            {
              title: "Action",
              render: (p, cell) => {
                if ((cell?.processId || "").includes(".")) {
                  return null;
                }

                const processId =
                  _.get(cell, "child[0].processId") ||
                  _.get(cell, "child[0].vendorId") ||
                  _.get(cell, "child[0].compoundId");

                return (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      onSelectProcess(processId);
                    }}
                  >
                    Select
                  </Button>
                );
              },
            },
          ]}
        />
      </div>
    </div>
  );
};

export default SearchByStructure;
