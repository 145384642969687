/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import PT from "prop-types";

import "./style.css";
import ImageCarousel from "components/Registration/ImageCarousel";
import CompoundNotFound from "../../../assets/vendors/notfound.png";
import { getImageFromSmiles } from "../../../appRedux/services/RegisterProcess";

const NotFoundCard = ({ notFound, register, selectedDataSource }) => {
  const [image, setImage] = useState("");

  useEffect(() => {
    if (!image.length) {
      getImageFromSmiles(notFound?.smiles, setImage);
    }
  }, [notFound]);

  const renderImage = () => {
    if (image) {
      return (
        <ImageCarousel
          structureImage={[image]}
          width={100}
          height={100}
          className="compound-image-preview"
        />
      );
    } else {
      return (
        <img
          src={CompoundNotFound}
          alt="compound-not-found"
          width={100}
          height={100}
          className="compound-not-found"
        />
      );
    }
  };

  return (
    <div className="ant-alert ant-alert-warning ant-alert-with-description not-found-card">
      <div className="ant-alert-description">
        <div className="gx-d-flex">
          {renderImage()}
          <div className="gx-mt-3 gx-ml-3">
            <span>This structure does not exist in our database.</span> Click{" "}
            <a
              onClick={() => register(notFound)}
              className="btn-link"
              alt="not-found-card"
            >
              here
            </a>{" "}
            to register.
          </div>
        </div>
      </div>
    </div>
  );
};

NotFoundCard.defaultProps = {
  notFound: {},
  selectedDataSource: "",
};

NotFoundCard.propTypes = {
  notFound: PT.shape(),
  register: PT.func.isRequired,
  selectedDataSource: PT.string,
};

export default NotFoundCard;
