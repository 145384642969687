import React, { useRef, useState } from "react";
import PT from "prop-types";
import { Button, Checkbox, Form, Input, Select } from "antd";
import ProgressBar from "@ramonak/react-progress-bar";
import { formatBytes, getUploadPercentage } from "../../../../util/url";
import Dropzone from "react-dropzone-uploader";
import DropzoneInputWrapper from "../../../DropzoneInputWrapper";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import {
  editDocument,
  getCounter,
  saveCounter,
  searchDocument,
  uploadDocument,
} from "../../../../appRedux/services/Document";
import { addParametersToDocument } from "../../../../util/Widget";
import { getRole } from "../../../../util/auth";
import InputTag from "../../../InputTag";
import _ from "lodash";
import { getCompoundConceptShallow } from "../../../../appRedux/services/Widget";
import { NotificationManager } from "react-notifications";
import AutoCompleteSelect from "../../../AutoCompleteSelect";
import { useMsal } from "@azure/msal-react";
import MetaWidget from "../../../DetailPage/DocumentCollection/MetaWidget";
import "react-dropzone-uploader/dist/styles.css";
import moment from "moment";

const DocumentForm = ({
  loading,
  currentDocument,
  sourceName,
  collectionOptions,
  pageType,
  setShowDrawer,
  updateDocuments,
  files,
  setFiles,
  progress,
  setProgress,
  selectedCollection,
  isEditMode = false
}) => {
  const [isUrl, setIsUrl] = useState(false);
  const [edit, setEdit] = useState(isEditMode);
  // eslint-disable-next-line no-unused-vars
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef();
  const [mimeTypes, setMimeTypes] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [searchResults, setSearchResults] = useState();
  // eslint-disable-next-line no-unused-vars
  const [fileSelectionModal, setFileSelectionModal] = useState(false);
  const [currentSelectedCollection, setCurrentSelectedCollection] = useState(selectedCollection);
  // eslint-disable-next-line no-unused-vars
  const [defaultEntityIds, setDefaultEntityIds] = useState([
    currentDocument?.id,
  ]);
  const entityIdsRef = useRef();
  const inputTagRef = useRef();
  const ownersTagRef = useRef();
  const { accounts } = useMsal();
  const account = accounts[0];
  const metaWidgetRef = useRef();

  const checkIfCompoundExists = () => {
    const entityIds = entityIdsRef.current.changeVal();

    let promises = [];

    _.forEach(entityIds, (entityId) => {
      promises.push(getCompoundConceptShallow(sourceName, entityId));
    });

    Promise.allSettled(promises).then((results) => {
      const failed = _.filter(
        results,
        (result) => result.status === "rejected"
      );

      const ids = _.map(failed, (item) => _.get(item, "reason.config.url"));

      let messages = [];

      _.forEach(ids, (id) => {
        const match = /id=([^&]+)/.exec(id);

        if (match) {
          const idValue = match[1];
          messages.push(idValue);
        }
      });

      NotificationManager.error(
        `Following ids are not found: ${messages.join(", ")}`
      );
    });
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    if (status === "done") {
      formRef.current.setFields([
        {
          name: "fileName",
          value: file?.name,
        },
      ]);
      setFiles([...files, [file]]);
      setMimeTypes([...mimeTypes, [meta?.type]]);
    }
  };

  const getFilesFromEvent = (e) => {
    return new Promise((resolve) => {
      getDroppedOrSelectedFiles(e).then((chosenFiles) => {
        resolve(chosenFiles.map((f) => f.fileObject));
      });
    });
  };

  /**
   * Search Document
   * @param {String} search search query
   */
  const onSearchDocument = ({ search, operator, widgetOption, collection }) => {
    searchDocument(
      sourceName,
      currentDocument,
      (res = {}) => {
        // add parameters to dataSource table.
        const tableData = addParametersToDocument(res?.hitList);

        setSearchResults(tableData);
        setFileSelectionModal(true);
      },
      () => { },
      {
        search,
        operator,
        collection,
        widgetOption,
      }
    );
  };

  const onChangeCurrentCollection = (currentItem, currentValue) => {
    const { value } = currentValue;

    setCurrentSelectedCollection(value);
  };

  // const collectionsFiltered = (collectionOptions || []).filter((option) => {
  //   return (
  //     (option?.allowedEntityTypes || []).includes(pageType) &&
  //     (option?.viewAccessRoles || []).includes(getRole() || "")
  //   );
  // });

  const currentCollectionOptions = collectionOptions;

  const getMinutes = (counterData, collection) => {
    const lastLockedTime = moment(
      _.get(counterData, `${collection}.timestamp`)
    );
    const currentTime = moment().tz("America/Los_Angeles");
    const duration = moment.duration(currentTime.diff(lastLockedTime));
    var minutes = duration.asMinutes();

    return minutes;
  };

  const onSaveFile = () => {
    // const name = formRef.current.getFieldValue("name");
    const collection = formRef.current.getFieldValue("collection");
    const description = formRef.current.getFieldValue("description");
    const url = formRef.current.getFieldValue("url");
    const tags = inputTagRef.current.changeVal();
    const owners = ownersTagRef.current.getValues();
    const entityIds = entityIdsRef.current.changeVal();

    const metaWidgetForm = metaWidgetRef.current?.getMetaWidgetForm();

    getCounter((counterData) => {
      const minutes = getMinutes(counterData, collection);

      if (_.get(counterData, `${collection}.locked`) && minutes < 5) {
        NotificationManager.error(
          `This collection is currently being updated. Please try again in ${5 - Math.floor(Number(minutes))
          } minutes`
        );
      } else {
        /*
          Validate File field
        */
        if (!files.length && !edit && !isUrl) {
          NotificationManager.error("Please upload or select file.");
          return;
        }

        let file;
        let mimeType;
        if (edit) {
          mimeType = currentDocument?.mimeType;
        } else {
          if (files.length) {
            // eslint-disable-next-line no-unused-vars
            file = files[0];
            mimeType = mimeTypes[0];
          }
        }

        const metaSubjects = _.map(metaWidgetForm, (k, v) => {
          return {
            key: v,
            value: k,
          };
        }).filter((item) => item?.field !== "tag");

        const searchSubjects = tags.map((tag) => ({
          key: "tag",
          value: tag,
        }));

        const targetSearchSubjects = _.uniq([
          ...searchSubjects,
          ...metaSubjects,
        ]);

        if (edit && currentDocument?.id) {
          editDocument(
            currentDocument?.id,
            sourceName,
            currentDocument?.id,
            {
              entityIds: _.uniq([currentDocument?.id, ...entityIds]),
              collection: collection,
              // mimeType: currentDocument?.mimeType,
              description,
              owners,
              searchSubjects: targetSearchSubjects,
              uri: url,
            },
            () => {
              // setShowModal(false);
              NotificationManager.success("Document has been updated.");
              updateDocuments(currentDocument?.collection);
            },
            (e) => {
              setShowDrawer(false);
              NotificationManager.error(e);
            },
            () => { }
          );
        } else {
          // Lock the counter for current collection.
          saveCounter(
            collection,
            counterData,
            _.get(counterData, `${collection}.counter`) || 0,
            true,
            () => {
              // If user submitting document without file. It should increase counter by 1.
              const targetNum =
                Number(_.get(counterData, `${collection}.counter`) || 0) +
                (!files.length ? 1 : files.length);
              uploadDocument(
                currentDocument?.id,
                sourceName,
                files,
                {
                  entityIds: _.uniq([currentDocument?.id, ...entityIds]),
                  collection: collection,
                  mimeType: (mimeType || []).length
                    ? mimeType[0]
                    : currentDocument?.mimeType,
                  description,
                  accessionNumber: targetNum,
                  owners,
                  searchSubjects: targetSearchSubjects,
                  uri: url || "",
                },
                () => {
                  setShowDrawer(false);
                  NotificationManager.success("Document has been added");
                  updateDocuments(currentDocument?.collection);
                },
                (e) => {
                  NotificationManager.error(
                    "A file with this name already exists in this collection."
                  );
                },
                () => { },
                setProgress,
                false,
                [],
                isUrl
              );

              saveCounter(collection, counterData, targetNum, false);
            }
          );
        }
      }

      setIsUrl(false);
    });
  };

  return (
    <div>
      <>
        {loading ? (
          <div>
            <h3>Uploading File.</h3>
            <ProgressBar completed={getUploadPercentage(progress)} />
            <div>
              {formatBytes(progress?.loaded)} / {formatBytes(progress?.total)}
              <p>{progress?.progress}</p>
            </div>
          </div>
        ) : (
          <div>
            <>
              {!isUrl ? (
                <>
                  {edit ? (
                    <>
                      <Form className="gx-form-row0 gx-mt-3">
                        <Form.Item label="File Name:">
                          <div className="gx-d-flex edit-file-header">
                            <p className="gx-text-warning">
                              {currentDocument?.fileName}
                            </p>
                            {!editMode && (
                              <Button
                                size="small"
                                type="primary"
                                className="gx-mt-2"
                                onClick={() => {
                                  setEdit(false);
                                }}
                              >
                                Upload a new file
                              </Button>
                            )}
                          </div>
                        </Form.Item>
                      </Form>
                    </>
                  ) : (
                    <>
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        InputComponent={DropzoneInputWrapper}
                        getFilesFromEvent={getFilesFromEvent}
                      />
                      <div className="gx-mt-3 gx-mb-3 browseFiles">
                        <Button
                          size="small"
                          type="primary"
                          onClick={onSearchDocument}
                        >
                          or Select from Document Repository
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </>

            <Form
              className="document-form gx-form-row0 gx-mt-3"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              autoComplete="off"
              ref={formRef}
            >
              <Form.Item
                name="isUrl"
                label="Submit without document"
                className="gx-mt-3"
                labelCol={{ span: 10 }}
              >
                <Checkbox
                  onChange={(e) => {
                    setIsUrl(e.target.checked);
                  }}
                />
              </Form.Item>

              {isUrl && (
                <Form.Item className="gx-mt-3" label="Enter Url" name="url">
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                label="Collection"
                // rules={[{ required: true, message: "Please enter collection" }]}
                name="collection"
                className="gx-mt-3"
              >
                <Select
                  defaultValue={currentDocument?.collection}
                  value={currentDocument?.collection}
                  onChange={onChangeCurrentCollection}
                >
                  {currentCollectionOptions.map((item, index) => (
                    <Select.Option value={item?.value} key={index}>
                      {item?.field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="name" label="Name" className="gx-mt-3">
                <Input name="name" />
              </Form.Item>

              <Form.Item
                label="Accession Number"
                name="accessionNumber"
                className="gx-mt-3"
              >
                <span>{currentDocument?.accessionNumber}</span>
              </Form.Item>

              <Form.Item
                name="entityIds"
                label="Shared with"
                className="gx-mt-3"
              >
                <div className="gx-d-flex">
                  <InputTag
                    sourceName={sourceName}
                    defaultValues={defaultEntityIds}
                    ref={entityIdsRef}
                  />
                  <Button
                    type="primary"
                    size="small"
                    className="gx-ml-3 gx-mt-3"
                    onClick={checkIfCompoundExists}
                  >
                    Check
                  </Button>
                </div>
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="gx-mt-3"
              >
                <Input name="description" />
              </Form.Item>
              <Form.Item name="tags" label="Tags" className="gx-mt-3">
                <InputTag
                  sourceName={sourceName}
                  defaultValues={currentDocument?.tags || []}
                  ref={inputTagRef}
                />
              </Form.Item>
              <Form.Item name="owners" label="Owners" className="gx-mt-3">
                <AutoCompleteSelect
                  ref={ownersTagRef}
                  defaultValue={
                    (currentDocument?.owners || []).length
                      ? _.map(currentDocument?.owners || [], (item) => ({
                        label: item,
                        value: item,
                      }))
                      : {
                        label: account?.name || getRole(),
                        value: account?.username,
                      }
                  }
                />
              </Form.Item>
            </Form>
            {currentSelectedCollection ? (
              <div className="gx-mt-3">
                <MetaWidget
                  ref={metaWidgetRef}
                  id={currentDocument?.id}
                  systemWidgetName={currentSelectedCollection}
                  onSaveWidget={(form) => { }}
                  renderResidualData
                  sourceName={sourceName}
                  pageType={pageType}
                  modalView
                  document={currentDocument}
                  collectionOptions={collectionOptions}
                />
              </div>
            ) : null}
            <Button type="primary" className="gx-mt-2" onClick={onSaveFile}>
              Save
            </Button>
          </div>
        )}
      </>
    </div>
  );
};

DocumentForm.defaultProps = {
  document: {},
};

DocumentForm.propTypes = {
  document: PT.shape(),
};

export default DocumentForm;
