/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Responsive, WidthProvider } from "react-grid-layout";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import Drawer from "../../../components/Drawer";
import CompoundInstanceWidget from "../../DetailPage/CompoundInstanceWidget";
import AdditionalComponent from "../AdditionalComponent";
import Widget from "../../DetailPage/Widget";
import ChemdrawWidget from "../ChemdrawWidget";

import "./style.css";
import ReactionComponents from "../ReactionComponents";
import { getParent } from "../../../util/Widget";
import CompositionTable from "../CompositionTable";
import ProjectSettingsWidget from "../ProjectSettingsWidget";
import ProjectDataWidget from "../ProjectDataWidget";
import SafetySentencesWidget from "../SafetySentencesWidget";
import DocumentCollection from "../DocumentCollection";
import TableWidget from "../TableWidget";
import pluralize from "pluralize";

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const CustomizableLayout = (props) => {
  const [currentTarget, setCurrentTarget] = useState();

  const {
    id,
    compoundDetail,
    compoundInstances,
    compoundInstanceLoading,
    widgetData,
    metadataLoading,
    compoundAdded,
    onSaveTraits,
    pageType,
    availableWidgets,
    sourceName,
    hideInfo,
    additionalComponents,
    updateCompoundInstance,
    processComponents,
    safetySentenceTypes,
    safetySentenceImageMetadata,
    selectedLanguage,
    safetySentenceLanguageMap,
    isCompoundExists,
    roles,
    editMode,
    setEditMode,
    layout,
    setLayout,
    layoutMetadataName,
    visible,
    setVisible,
    dataSources,
    cloneExperiment,
    cloneDocuments,
    selectedCollection,
    setSelectedCollection,
    currentEditor,
  } = props;

  const [availableItems, setAvailableItems] = useState([]);

  useEffect(() => {
    const filteredAvailableWidgets = availableWidgets.filter(
      (item) => _.findIndex(layout, ["i", item.value]) === -1
    );
    setAvailableItems(filteredAvailableWidgets);
  }, [layout]);

  useEffect(() => {
    getSystemWidget(layoutMetadataName, setLayout);
  }, []);

  const onDrop = (dropLayout, layoutItem, _event) => {
    const el = _.pick(layoutItem, ["x", "y", "w", "h"]);
    el.i = currentTarget.value;
    el.h = 2;
    el.w = 2;
    setLayout([...layout, el]);
    setAvailableItems(
      availableItems.filter((item) => item.value !== currentTarget.value)
    );
  };

  const onDelete = (itemName) => {
    const newLayout = layout.filter((item) => item?.i !== itemName);
    setLayout(newLayout);
  };

  const generateDOM = () => {
    return _.map(layout, function (l, i) {
      const targetMetadata =
        pageType === "project" ? `${l.i}-${pageType}` : l.i;

      if (l.i === "Qualities") {
        return (
          <div key={l.i}>
            <CompoundInstanceWidget
              id={id}
              title={`${
                pageType === "process" || pageType === "experiment"
                  ? pluralize("Experiment", (compoundInstances || []).length)
                  : pluralize("Quality", (compoundInstances || []).length)
              } (${(compoundInstances || []).length})`}
              compoundInstances={compoundInstances}
              addInstance={compoundAdded}
              loading={compoundInstanceLoading}
              resizableMode
              sourceName={sourceName}
              hasDeleteButton={editMode}
              onDelete={() => onDelete(l.i)}
              readMode={editMode}
              pageType={pageType}
              roles={i?.roles || []}
              cloneExperiment={cloneExperiment}
              cloneDocuments={cloneDocuments}
              availableWidgets={availableWidgets}
              selectedCollection={selectedCollection}
              setSelectedCollection={setSelectedCollection}
            />
          </div>
        );
      }
      if (l.i === "AdditionalComponents") {
        return (
          <div key={l.i}>
            <AdditionalComponent
              id={id}
              title="Composition"
              options={additionalComponents || []}
              disableVersion
              withImage
              disableControl
              rmoId={compoundDetail.rmoId}
              componentUpdated={updateCompoundInstance}
              sourceName={sourceName}
              pageType={pageType}
              hideInfo={hideInfo}
              hasDeleteButton={editMode}
              onDelete={() => onDelete(l.i)}
              readMode={editMode}
            />
          </div>
        );
      }
      if (l.i === "ReactionComponents") {
        return (
          <div key={l.i}>
            <ReactionComponents
              id={id}
              title="Reaction Component"
              sourceName={sourceName}
              hasDeleteButton={editMode}
              onDelete={() => onDelete(l.i)}
              pageType={pageType}
              roles={l?.roles || []}
            />
          </div>
        );
      }

      if (l.i === "cdxmlRendering") {
        return (
          <div key={l.i}>
            <ChemdrawWidget
              id={getParent(id)}
              title={"CDXML"}
              widgetName={l?.i}
              sourceName={sourceName}
              currentEditor={currentEditor}
            />
          </div>
        );
      }

      if (l.i === "projectSettings") {
        return (
          <div key={l.i}>
            <ProjectSettingsWidget
              id={getParent(id)}
              title={l?.field}
              widgetName={l.i}
              sourceName={sourceName}
            />
          </div>
        );
      }

      if (l.i === "ProjectData") {
        return (
          <div key={l.i}>
            <ProjectDataWidget
              id={id}
              title={l?.field}
              sourceName={sourceName}
              pageType={pageType}
            />
          </div>
        );
      }

      if (l.i === "CompositionTable") {
        return (
          <div key={l.i}>
            <CompositionTable
              id={id}
              title="Composition Table"
              components={additionalComponents || []}
              processComponents={processComponents || []}
              sourceName={sourceName}
              pageType={pageType}
              componentUpdated={updateCompoundInstance}
              safetySentenceTypes={safetySentenceTypes}
              safetySentenceImageMetadata={safetySentenceImageMetadata}
              selectedLanguage={selectedLanguage}
              safetySentenceLanguageMap={safetySentenceLanguageMap}
              dataSources={dataSources}
              roles={l?.roles || []}
              widget={l?.widget}
            />
          </div>
        );
      }

      if (l.i === "SafetySentences") {
        return (
          <div key={l.i}>
            <SafetySentencesWidget
              id={id}
              title={l?.field}
              sourceName={sourceName}
              pageType={pageType}
              widget={l}
              components={additionalComponents || []}
              safetySentenceTypes={safetySentenceTypes}
            />
          </div>
        );
      }

      if (l.i === "DocumentCollections") {
        return (
          <div key={l.i}>
            <DocumentCollection
              id={id}
              title={l?.field}
              sourceName={sourceName}
              pageType={pageType}
              isCompoundExists={isCompoundExists}
              systemWidgetName={l.i}
            />
          </div>
        );
      }

      if (l.i === "TableData") {
        return (
          <div key={l.i}>
            <TableWidget
              id={id}
              title={l?.field}
              systemWidgetName={l?.i}
              components={additionalComponents || []}
              sourceName={sourceName}
              pageType={pageType}
              componentUpdated={updateCompoundInstance}
              roles={roles}
              widget={l?.widget || {}}
            />
          </div>
        );
      }

      return (
        <div key={l.i}>
          <Widget
            id={id}
            systemWidgetName={l.i}
            title={l?.field}
            widgetData={widgetData}
            onSaveWidget={onSaveTraits}
            loading={metadataLoading}
            renderResidualData
            readMode={editMode}
            hasDeleteButton={editMode}
            onDelete={() => onDelete(l.i)}
            resizableMode
            sourceName={sourceName}
            pageType={pageType}
            hideInfo={hideInfo}
            targetMetadata={targetMetadata}
            widget={l?.widget || {}}
          />
        </div>
      );
    });
  };

  const onDrag = (_layout, oldItem, newItem) => {
    const newLayout = layout.map((item) =>
      item?.i === newItem?.i ? newItem : item
    );
    setLayout(newLayout);
  };

  return (
    <div>
      <div className="grid-layout-wrapper">
        <ResponsiveReactGridLayout
          className={`${editMode ? "edit-mode" : ""}`}
          layouts={{ lg: layout }}
          onDrop={onDrop}
          preventCollision
          isDroppable={true}
          onResizeStop={() => setEditMode(true)}
          isDraggable={editMode}
          resizeHandles={["se"]}
          onDrag={onDrag}
          onResize={onDrag}
          autoSize={false}
        >
          {generateDOM()}
        </ResponsiveReactGridLayout>
      </div>

      <Drawer
        title="Add Widgets"
        placement="right"
        visible={visible}
        onClose={() => setVisible(false)}
      >
        {availableItems.map((item, index) => (
          <div
            key={`item-${index}`}
            className="droppable-element"
            data-grid={item.value}
            draggable={true}
            unselectable="on"
            onDragStart={(e) => {
              setCurrentTarget(item);
              e.dataTransfer.setData("text/plain", "");
            }}
          >
            {item.field}
          </div>
        ))}
      </Drawer>
    </div>
  );
};

CustomizableLayout.defaultProps = {};

CustomizableLayout.propTypes = {};

export default CustomizableLayout;
