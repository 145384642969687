import _ from "lodash";
import React from "react";
import Graph from "react-graph-vis";

const GraphContent = ({ nodes, edges, options, events }) => {
  return (
    <Graph
      graph={{
        nodes: _.reverse(nodes),
        edges,
      }}
      options={options}
      events={events}
      getNetwork={(network) => {
        //  if you want access to vis.js network api you can set the state in a parent component using this property
      }}
    />
  );
};

export default GraphContent;
