import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_DOCUMENT_ROLES_FAILED,
  GET_DOCUMENT_ROLES_REQUESTED,
  GET_DOCUMENT_ROLES_SUCCESS,
  GET_GENERIC_REQUESTED,
  GET_GENERIC_SUCCESS,
  GET_SOURCE_MAP_REQUESTED,
  GET_SOURCE_MAP_SUCCESS,
  GET_USER_ROLES_FAILED,
  GET_USER_ROLES_REQUESTED,
  GET_USER_ROLES_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "axios";
import { DOCUMENT_ROLES, USER_ROLES, config } from "../../constants/Config";

/**
 * Send Get Request from system widget's defined url.
 * @param {String} id current widget id
 * @param {String} sourceName source Name
 * @param {String} baseUrl base url of api
 * @param {Object} params defined query parameters
 * @param {Object} header defined header parameters
 */
const getGenericRequest = async ({
  id,
  sourceName,
  baseUrl,
  params,
  headers,
}) => {
  const queryParams = new URLSearchParams({
    sourceName,
    ...params,
    nostereoflags: true,
  });

  const url = config.SCAS_API_URL;

  let genericHeaders = headers;

  try {
    genericHeaders = JSON.parse(headers);
  } catch (error) {}

  return axios.get(`${url}${baseUrl}/${id}?${queryParams}`, {
    headers: genericHeaders,
  });
};

const getSourceMapRequest = async () => {
  return axios.get(`${config.SRD_API_URL}/sources`);
};

const getUserRolesRequest = async (widgetName) => {
  return axios.get(`${config.SRD_API_URL}/metadata/system/${widgetName}`);
};

function* fetchGetGeneric(action) {
  try {
    const result = yield call(getGenericRequest, action.payload);
    if (result.data) {
      yield put({
        type: GET_GENERIC_SUCCESS,
        payload: {
          id: action.payload.id,
          field: action.payload.field,
          data: result.data,
        },
      });
    }
  } catch (error) {
    yield put({
      type: GET_GENERIC_SUCCESS,
      payload: {
        id: action.payload.id,
        field: action.payload.field,
        data: "",
      },
    });
  }
}

function* fetchSourceMap(action) {
  try {
    const result = yield call(getSourceMapRequest);
    if (result.data) {
      yield put({
        type: GET_SOURCE_MAP_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {}
}

function* fetchUserRoles(action) {
  try {
    const result = yield call(getUserRolesRequest, USER_ROLES);

    if (
      result.data &&
      result.data.parameters &&
      result.data.parameters.options
    ) {
      yield put({
        type: GET_USER_ROLES_SUCCESS,
        payload: result.data.parameters.options,
      });
    }
  } catch (error) {
    yield put({
      type: GET_USER_ROLES_FAILED,
    });
  }
}

function* fetchDocumentRoles(action) {
  try {
    const result = yield call(getUserRolesRequest, DOCUMENT_ROLES);

    if (
      result.data &&
      result.data.parameters &&
      result.data.parameters.options
    ) {
      yield put({
        type: GET_DOCUMENT_ROLES_SUCCESS,
        payload: result.data.parameters.options,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DOCUMENT_ROLES_FAILED,
    });
  }
}

export function* getGeneric() {
  yield takeEvery(GET_GENERIC_REQUESTED, fetchGetGeneric);
}

export function* getSourceMap() {
  yield takeEvery(GET_SOURCE_MAP_REQUESTED, fetchSourceMap);
}

export function* getUserRoles() {
  yield takeEvery(GET_USER_ROLES_REQUESTED, fetchUserRoles);
}

export function* getDocumentRoles() {
  yield takeEvery(GET_DOCUMENT_ROLES_REQUESTED, fetchDocumentRoles);
}

export default function* rootSaga() {
  yield all([
    fork(getGeneric),
    fork(getSourceMap),
    fork(getUserRoles),
    fork(getDocumentRoles),
  ]);
}
