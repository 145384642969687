/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Divider, Drawer, Tabs } from "antd";
import CircularProgress from "components/CircularProgress";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getDataSources, setCurrentWidgets } from "../../appRedux/actions";
import DatabasesForm from "./DatabasesForm";

import { useMsal } from "@azure/msal-react";
import _ from "lodash";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useLocation } from "react-router-dom";
import { indexSource } from "../../appRedux/services/Dashboard";
import { searchForTemplate } from "../../appRedux/services/Document";
import {
  getSystemWidget,
  getUserWidget,
  saveSystemWidget,
} from "../../appRedux/services/SystemWidget";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import {
  ADMIN_DASHBOARD_TAB_MAP,
  CONTROLLED_VOCABULARIES_METADATA,
  CURRENT_EDITOR_OPTION,
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  DOCUMENT_ROLES,
  EDITOR_OPTIONS,
  PROJECT_PAGE_SETTINGS,
  RESOURCE_LINKS,
  SEARCH_PAGE_WIDGETS_OPTIONS,
  SEARCH_STRUCTURE_TYPES,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  USER_ROLES,
} from "../../constants/Config";
import { getRole } from "../../util/auth";
import { searchDocumentation } from "../UploadPage/helper";
import AvailableWidgetsSettings from "./AvailableWidgetsSettings";
import DocumentCollectionSettings from "./DocumentCollectionSettings";
import EditorSettings from "./EditorSettings";
import PagesSettings from "./PagesSettings";
import ProjectPageSettings from "./ProjectPageSettings";
import ResourceLinksSettings from "./ResourceLinksSettings";
import RolesSettings from "./RolesSettings";
import SearchPageTabSettings from "./SearchPageTabSettings";
import SearchWidgets from "./SearchWidgets";
import SpecialPermissions from "./SpecialPermissions";
import StructureTypeSettings from "./StructureTypesSettings";

const AdminDashboard = () => {
  const [roles, setRoles] = useState([]);
  const [documentRoles, setDocumentRoles] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [dataWidgets, setDataWidgets] = useState([]);
  const [defaultActiveKey, setDefaultActiveKey] = useState("1");

  const [controlledVocabularies, setControlledVocabularies] = useState([]);
  const [structureTypes, setStructureTypes] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);
  const [searchOptions, setSearchOptions] = useState([]);
  const [documentations, setDocumentations] = useState([]);
  const [defaultSourceName, setDefaultSourceName] = useState({});
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);
  const [excelTemplates, setExcelTemplates] = useState([]);

  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const [currentRound, setCurrentRound] = useState();
  const [generalSettingsLoaded, setGeneralSettingsLoaded] = useState(false);
  const [availableWidgetParams, setAvailableWidgetParams] = useState([]);
  const [helmEditor, setHelmEditor] = useState();

  const [resourceLinks, setResourceLinks] = useState([]);

  const [projectSettings, setProjectSettings] = useState([]);

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { TabPane } = Tabs;
  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const pageName = "dashboard";
  const location = useLocation();

  const { dataSources, loading: dataSourceLoading } = useSelector(
    ({ search }) => search,
  );

  const onUpdateDocumentations = () => {
    searchDocumentation(defaultSourceName?.option, pageName, setDocumentations);
  };

  const onExcelDocumentUpdate = () => {
    searchForTemplate(setExcelTemplates);
  };

  const onSave = (data, targetWidget) => {
    const body = JSON.stringify({
      options: data,
    });

    saveSystemWidget(targetWidget, setRoles, setLoading, body);
    NotificationManager.success(`Successfully saved data.`, "");
  };

  const onSaveDocumentRoles = (data, targetWidget) => {
    const body = JSON.stringify({
      options: data,
    });

    saveSystemWidget(targetWidget, setDocumentRoles, setLoading, body);
    NotificationManager.success(`Successfully saved data.`, "");
  };

  useEffect(() => {
    if (defaultActiveKey === "14") {
      getSystemWidget(RESOURCE_LINKS, setResourceLinks);
    }
  }, [defaultActiveKey]);

  useEffect(() => {
    if (defaultActiveKey === "15") {
      getSystemWidget(PROJECT_PAGE_SETTINGS, setProjectSettings);
    }
  }, [defaultActiveKey]);

  useEffect(() => {
    const { hash } = location;

    const hashValue = (hash || "").slice(1);
    const activeTabKey = _.get(ADMIN_DASHBOARD_TAB_MAP, hashValue);

    if (activeTabKey) {
      setDefaultActiveKey(activeTabKey);
    }
  }, []);

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: "",
        database: "",
      }),
    );
  }, []);

  useEffect(() => {
    if (
      (dataWidgets || []).length &&
      (systemWidgets || []).length &&
      !availableWidgetParams.length
    ) {
      let availableWidgets = [...systemWidgets, ...dataWidgets];

      let availableWidgetOptions = [];

      _.forEach(availableWidgets, (widget) => {
        const rows = _.get(widget, "widget.rows") || [];
        const widgetName = widget?.field;

        _.forEach(rows, (row) => {
          const acceptedRowTypes = ["EDITABLE", "EDITABLE_DATE", "LIST"];

          if (acceptedRowTypes.includes(row?.type)) {
            const option = `${widgetName} - ${row?.field || row?.value}`;
            const name = row?.name;

            availableWidgetOptions.push({
              key: uuidv4(),
              label: option,
              value: name,
              name,
            });
          }
        });
      });

      setAvailableWidgetParams(availableWidgetOptions);
    }
  }, [dataWidgets, systemWidgets]);

  useEffect(() => {
    searchForTemplate(setExcelTemplates);
  }, []);

  useEffect(() => {
    getUserWidget(DEFAULT_SOURCE_NAME, userName, setDefaultSourceName, false);
  }, []);

  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      "home",
      setDocumentations,
    );
  }, []);

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        setCurrentEditor(currentOption?.option);
        setCurrentRound(currentOption?.roundSettings);
        setHelmEditor(currentOption?.helmEditor);
        setGeneralSettingsLoaded(true);
      },
      false,
    );
  }, []);

  let isInitialized = false;

  useEffect(() => {
    if (!roles.length && !isInitialized) {
      isInitialized = true;
      getSystemWidget(USER_ROLES, setRoles);
    }
  }, []);

  useEffect(() => {
    if (!documentRoles.length) {
      getSystemWidget(DOCUMENT_ROLES, setDocumentRoles);
    }
  }, []);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources(true));
    }

    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }

    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }

    if (!documentOptions.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setDocumentOptions);
    }

    if (!searchOptions.length) {
      getSystemWidget(SEARCH_PAGE_WIDGETS_OPTIONS, setSearchOptions);
    }

    if (!controlledVocabularies.length) {
      getSystemWidget(
        CONTROLLED_VOCABULARIES_METADATA,
        setControlledVocabularies,
      );
    }

    if (!structureTypes.length) {
      getSystemWidget(SEARCH_STRUCTURE_TYPES, setStructureTypes);
    }
  }, []);

  const pages = [
    {
      field: "Project Page",
      value: "/projects",
    },
    {
      field: "Admin Dashboard",
      value: "/dashboard",
    },
    {
      field: "Compound Registration",
      value: "/compound-registration",
    },
    {
      field: "Search Page",
      value: "/search",
    },
    {
      field: "Register Process Page",
      value: "/register-process",
    },
    {
      field: "Upload Page",
      value: "/upload",
    },
    {
      field: "Page Settings",
      value: "/page-settings",
    },
    {
      field: "Explore Page",
      value: "/search",
    },
    {
      field: "Collection Page",
      value: "/collection",
    },
    {
      field: "Sample Page",
      value: "/sample",
    },
  ];

  const onAddResourceLinks = async (form) => {
    let links = [];

    if (_.find(resourceLinks, { id: form?.id })) {
      links = _.map(resourceLinks, (link) =>
        link?.id === form?.id ? form : link,
      );
    } else {
      links = [...resourceLinks, form];
    }

    const newResourceLinks = {
      options: links,
    };

    await saveSystemWidget(
      RESOURCE_LINKS,
      (data) => {
        setResourceLinks(data);
        NotificationManager.success("Successfully added resource link.");
      },
      () => {},
      newResourceLinks,
    );
  };

  const onUpdateResourceLinks = async (form) => {
    await saveSystemWidget(
      RESOURCE_LINKS,
      (data) => {
        setResourceLinks(data);
        NotificationManager.success("Successfully removed resource link.");
      },
      () => {},
      { options: form },
    );
  };

  const onSaveProjectPageSettings = async (form) => {
    await saveSystemWidget(
      PROJECT_PAGE_SETTINGS,
      (data) => {
        setProjectSettings(data);
        NotificationManager.success(
          "Successfully saved project page settings.",
        );
      },
      () => {},
      { options: form },
    );
  };

  const onIndex = (sourceName) => {
    indexSource(
      sourceName,
      setLoading,
      () => {
        NotificationManager.success(`Successfully indexed ${sourceName}.`, "");
        dispatch(getDataSources(true));
      },
      () => {
        NotificationManager.error(
          `Error occured when indexing ${sourceName}.`,
          "",
        );
      },
    );
  };

  const availableWidgets = [...systemWidgets, ...dataWidgets];

  const onSaveSearchWidget = async (widgetName, form) => {
    const newSearchWidgets = _.map(searchOptions, (currentSearchWidget) => {
      const current = currentSearchWidget;

      if (currentSearchWidget?.value === widgetName) {
        const targetWidget = form;

        return {
          ...current,
          widget: targetWidget,
        };
      }

      return current;
    });

    const body = JSON.stringify({
      options: newSearchWidgets,
    });

    await saveSystemWidget(
      SEARCH_PAGE_WIDGETS_OPTIONS,
      (res) => {
        setSearchOptions(res);
      },
      setLoading,
      body,
    );

    NotificationManager.success(`Successfully saved search widgets.`, "");
  };

  const onSaveSystemWidget = async (widgetName, form) => {
    const newSystemWidgets = _.map(systemWidgets, (currentSystemWidget) => {
      const current = currentSystemWidget;

      if (currentSystemWidget?.value === widgetName) {
        const targetWidget = form;

        return {
          ...current,
          widget: targetWidget,
        };
      }

      return current;
    });

    const body = JSON.stringify({
      options: newSystemWidgets,
    });

    await saveSystemWidget(
      SYSTEM_WIDGETS_METADATA,
      (res) => {
        setSystemWidgets(res);
      },
      setLoading,
      body,
    );

    NotificationManager.success(`Successfully saved system widget.`, "");
  };

  const onSaveDocumentWidget = async (widgetName, form) => {
    const newSystemWidgets = _.map(documentOptions, (currentDocumentOption) => {
      if (currentDocumentOption?.value === widgetName) {
        const targetWidget = form;

        return {
          ...currentDocumentOption,
          widget: targetWidget,
        };
      }

      return currentDocumentOption;
    });

    const body = JSON.stringify({
      options: newSystemWidgets,
    });

    await saveSystemWidget(
      DOCUMENT_COLLECTION_OPTIONS,
      (res) => {
        setSystemWidgets(res);
      },
      setLoading,
      body,
    );

    NotificationManager.success(`Successfully saved system widget.`, "");
  };

  const onSaveDataWidget = async (widgetName, form) => {
    const newSystemWidgets = _.map(dataWidgets, (currentSystemWidget) => {
      if (currentSystemWidget?.value === widgetName) {
        return {
          ...currentSystemWidget,
          widget: form,
        };
      }

      return currentSystemWidget;
    });

    const body = JSON.stringify({
      options: newSystemWidgets,
    });

    await saveSystemWidget(
      DATA_WIDGETS_METADATA,
      (res) => {
        setDataWidgets(res);
      },
      setLoading,
      body,
    );

    NotificationManager.success(`Successfully saved system widget.`, "");
  };

  /**
   * Callback function after saving current editor option.
   */
  const onSaveEditor = async (option, roundSettings, helmEditorSettings) => {
    await saveSystemWidget(
      CURRENT_EDITOR_OPTION,
      () => {
        NotificationManager.success("Successfully saved editor option.");
      },
      () => {},
      {
        option,
        roundSettings,
        helmEditor: helmEditorSettings,
      },
    );
  };

  const onSaveWidgets = async (widgetName, setFn, form, isDelete = false) => {
    const body = JSON.stringify({
      options: form,
    });

    await saveSystemWidget(widgetName, setFn, setLoading, body);
    await NotificationManager.success(
      `Successfully ${isDelete ? "deleted" : "saved"} system widgets.`,
      "",
    );
    await getSystemWidget(widgetName, setFn);
  };

  const trainingSettings =
    _.find(documentOptions || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  let sourceName = defaultSourceName?.option;

  const isAdmin =
    (getRole() || "").toLowerCase() === "admin" ||
    (getRole() || "").toLowerCase() === "administrator";

  return (
    <div>
      <div
        className="gx-profile-banner sticky-header-component"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
            paddingTop: "15px",
          }}
        >
          <h3>Admin Dashboard</h3>

          <div>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <DocumentationPopover
                documentations={documentations}
                sourceName={TRAINING_DOCUMENT_SOURCE}
                setOpenDocumentationSettings={setOpenDocumentationSettings}
                hasPermission={isUserAllowedToManageDocumentation}
              />
            ) : null}
          </div>
        </div>
      </div>
      <div
        className="sticky-header-content"
        style={{
          marginTop: "80px",
        }}
      >
        {dataSourceLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Tabs
              activeKey={defaultActiveKey}
              onChange={(tabKey) => {
                let hashLocation = null;

                setDefaultActiveKey(tabKey);

                switch (tabKey) {
                  case "1":
                    hashLocation = "database-settings";
                    break;
                  case "3":
                    hashLocation = "structure-settings";
                    break;
                  case "4":
                    hashLocation = "page-settings";
                    break;
                  case "5":
                    hashLocation = "roles-settings";
                    break;
                  case "6":
                    hashLocation = "system-settings";
                    break;
                  case "7":
                    hashLocation = "data-settings";
                    break;
                  case "8":
                    hashLocation = "controlled-vocabulary-settings";
                    break;
                  case "9":
                    hashLocation = "document-settings";
                    break;
                  case "11":
                    hashLocation = "search-widget-settings";
                    break;
                  case "12":
                    hashLocation = "editor-settings";
                    break;
                  case "13":
                    hashLocation = "search-page-settings";
                    break;
                  case "14":
                    hashLocation = "resource-links";
                    break;
                  case "15":
                    hashLocation = "project-page-settings";
                    break;

                  default:
                    hashLocation = null;
                }

                if (hashLocation) {
                  window.location.hash = hashLocation;
                }
              }}
            >
              <TabPane tab="Database Configuration" key={1} id={1}>
                <DatabasesForm
                  widgetName={USER_ROLES}
                  roles={roles}
                  dataSources={dataSources}
                  onSave={onSave}
                  onIndex={onIndex}
                  loading={loading}
                />
              </TabPane>
              <TabPane tab="Structure Type Settings" key={3}>
                <StructureTypeSettings
                  widgetName={USER_ROLES}
                  dataSources={structureTypes}
                  roles={roles}
                  loading={loading}
                  onSave={onSave}
                />
              </TabPane>
              <TabPane tab="Pages Settings" key={4}>
                <PagesSettings
                  widgetName={USER_ROLES}
                  dataSources={pages}
                  roles={roles}
                  loading={loading}
                  onSave={onSave}
                />
              </TabPane>
              <TabPane tab="Roles Settings" key={5}>
                <RolesSettings
                  roles={roles}
                  onSave={onSave}
                  widgetName={USER_ROLES}
                  title="Data Access Roles"
                />

                <Divider />

                <RolesSettings
                  roles={documentRoles}
                  onSave={onSaveDocumentRoles}
                  widgetName={DOCUMENT_ROLES}
                  title="Document Access Roles"
                  buttonTitle="Add New Document Role"
                />
              </TabPane>
              <TabPane tab="System Widgets" key={6}>
                <AvailableWidgetsSettings
                  title="System Widgets"
                  availableWidgets={systemWidgets}
                  allWidgets={[...systemWidgets, ...dataWidgets]}
                  onSave={(widgetName, data, isDelete = false) =>
                    onSaveWidgets(widgetName, setSystemWidgets, data, isDelete)
                  }
                  widgetName={SYSTEM_WIDGETS_METADATA}
                  disableEdit={!isAdmin}
                  onSaveSystemWidget={onSaveSystemWidget}
                  roles={roles}
                />
              </TabPane>
              <TabPane tab="Data Widgets" key={7}>
                <AvailableWidgetsSettings
                  title="Data Widgets"
                  availableWidgets={dataWidgets}
                  onSave={(widgetName, data) =>
                    onSaveWidgets(widgetName, setDataWidgets, data)
                  }
                  widgetName={DATA_WIDGETS_METADATA}
                  onSaveSystemWidget={onSaveDataWidget}
                  roles={roles}
                />
              </TabPane>
              {/* <TabPane tab="Controlled Vocabulary" key={8}>
                <AvailableWidgetsSettings
                  title="Controlled Vocabulary"
                  availableWidgets={controlledVocabularies}
                  onSave={(widgetName, data) => {
                    onSaveWidgets(widgetName, setControlledVocabularies, data);
                  }}
                  widgetName={CONTROLLED_VOCABULARIES_METADATA}
                  onSaveSystemWidget={onSaveSystemWidget}
                  roles={roles}
                />
              </TabPane> */}
              <TabPane tab="Document Collection Selection" key={9}>
                <DocumentCollectionSettings
                  title="Document Collection"
                  availableWidgets={documentOptions}
                  onSave={(widgetName, data, isDelete = false) => {
                    onSaveWidgets(
                      widgetName,
                      setDocumentOptions,
                      data,
                      isDelete,
                    );
                  }}
                  widgetName={DOCUMENT_COLLECTION_OPTIONS}
                  onSaveSystemWidget={onSaveDocumentWidget}
                  roles={roles}
                  sources={dataSources}
                  excelTemplates={excelTemplates}
                  onUpdateDocumentations={onExcelDocumentUpdate}
                />
              </TabPane>
              {/* <TabPane tab="Excel Template Settings" key={10}>
                <ExcelTemplateSettings />
              </TabPane> */}
              <TabPane tab="Search Widgets" key={11}>
                <SearchWidgets
                  title="Search Widgets"
                  availableWidgets={searchOptions}
                  widgetName={SEARCH_PAGE_WIDGETS_OPTIONS}
                  onSave={(widgetName, data) => {
                    // onSaveWidgets(widgetName, setSearchOptions, data);
                    // onSaveSearchWidget(widgetName, data);
                    onSaveWidgets(widgetName, setSearchOptions, data);
                  }}
                  onSaveSystemWidget={(widgetName, data) => {
                    onSaveSearchWidget(widgetName, data);
                  }}
                  roles={roles}
                  availableWidgetParams={availableWidgetParams}
                  allWidgets={[...systemWidgets, ...dataWidgets]}
                />
              </TabPane>

              <TabPane tab="General Settings" key={12}>
                {generalSettingsLoaded ? (
                  <EditorSettings
                    currentEditor={currentEditor}
                    currentRound={currentRound}
                    onSaveEditor={onSaveEditor}
                    helmEditor={helmEditor}
                  />
                ) : null}
              </TabPane>

              <TabPane tab="Project Page Settings" key={15}>
                <ProjectPageSettings
                  availableWidgets={availableWidgets}
                  onSaveProjectPageSettings={onSaveProjectPageSettings}
                  roles={roles}
                  projectSettings={projectSettings}
                  setProjectSettings={setProjectSettings}
                />
              </TabPane>

              <TabPane tab="Search Page Tab Settings" key={13}>
                <SearchPageTabSettings
                  widgetName={USER_ROLES}
                  roles={roles}
                  onSave={onSave}
                  loading={loading}
                />
              </TabPane>
              <TabPane tab="Resource Links" key={14}>
                <ResourceLinksSettings
                  resourceLinks={resourceLinks}
                  roles={roles}
                  onAddResourceLinks={onAddResourceLinks}
                  onUpdateResourceLinks={onUpdateResourceLinks}
                />
              </TabPane>

              <TabPane tab="Special Permissions" key={16}>
                <SpecialPermissions />
              </TabPane>
            </Tabs>
            <NotificationContainer />
          </>
        )}
      </div>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>
    </div>
  );
};

export default AdminDashboard;

AdminDashboard.propTypes = {};

AdminDashboard.defaultProps = {};
