import { List } from "antd";
import _ from "lodash";
import React from "react";
import { v4 as uuidv4 } from "uuid";
import ContactCell from "../../../components/Contact/ContactList/ContactCell";

const compare = (a = "", b = "") => {
  return _.includes(a.toLowerCase(), b.toLowerCase());
};

const SearchResult = ({ searchTerm, projects, sourceName }) => {
  const indexed = _.map(projects, (project) => {
    let found = false;

    found = compare(project?.name, searchTerm);

    const processSteps = _.map(project?.processSteps || [], (ps) => {
      let foundProcess = false;

      foundProcess = compare(ps?.processId, searchTerm);

      const experiments = _.map(ps?.experiments || [], (exp) => {
        let foundExperiment = false;

        foundExperiment = compare(exp?.experimentId, searchTerm);

        return {
          ...exp,
          foundExperiment,
        };
      });

      return {
        ...ps,
        foundProcess,
        experiments,
      };
    });

    return {
      ...project,
      found,
      processSteps,
    };
  });

  return (
    <div className="gx-mt-3">
      {_.map(indexed, (project, index) => {
        let foundProcess = false;

        _.forEach(project?.processSteps || [], (ps) => {
          if (ps?.foundProcess) {
            foundProcess = true;
          }

          _.forEach(ps?.experiments || [], (exp) => {
            if (exp?.foundExperiment) {
              foundProcess = true;
            }
          });
        });

        if (!project?.found && !foundProcess) {
          return null;
        }
        return (
          <div key={uuidv4()}>
            <span>
              Project Name:{" "}
              <>
                {project?.found ? (
                  <div className="gx-mt-1">
                    <span className="gx-text-warning">{project?.name}</span>
                  </div>
                ) : (
                  <span>{project?.name}</span>
                )}
              </>
            </span>

            <List>
              {_.map(project?.processSteps || [], (processStep) => {
                let foundExp = false;

                _.forEach(processStep?.experiments || [], (exp) => {
                  if (exp?.foundExperiment) {
                    foundExp = true;
                  }
                });

                if (!processStep?.foundProcess && !foundExp) {
                  return null;
                }

                return (
                  <ContactCell
                    key={index}
                    contact={processStep}
                    sourceName={sourceName}
                    renderExperiments
                    isSearchResult
                  />
                );
              })}
            </List>
          </div>
        );
      })}
    </div>
  );
};

export default SearchResult;
