import { DeleteTwoTone, EditTwoTone, FilterTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Switch,
  Table,
  Tag,
} from "antd";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import React, { useRef, useState } from "react";

const ProjectPageSettings = ({
  projectSettings = [],
  setProjectSettings,
  availableWidgets = [],
  onSaveProjectPageSettings,
  roles = [],
}) => {
  const [showModal, setShowModal] = useState(false);
  const [selectedWidget, setSelectedWidget] = useState(null);

  const [form, setForm] = useState({});

  const widgetNames = _.map(availableWidgets, (widget) => ({
    label: widget?.field,
    value: widget?.value,
  }));

  const renderAccessRoles = (_p, p, index) => {
    const rolesFlattened =
      Object.entries(projectSettings[index]?.isAccessible || []) || [];

    const currentRoles = rolesFlattened
      .map((item) => (item[1] === true ? item[0] : null))
      .filter((item) => item);

    return _.map(currentRoles || [], (currentRole) => {
      const currentRoleName = _.find(roles, { id: currentRole }) || {};

      return (
        <Tag color="blue" key={uuidv4()}>
          {currentRoleName?.name}
        </Tag>
      );
    });
  };

  const onDeleteItem = (index) => {
    const newItems = _.filter(projectSettings, (item, i) => index !== i);

    onSaveProjectPageSettings(newItems);
  };

  const onChangeValue = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  const renderActions = (p, index) => {
    return (
      <>
        <Popover content="Edit item">
          <EditTwoTone
            className="gx-mr-2 gx-pointer"
            onClick={() => {
              setForm(p);
              setShowModal(true);
            }}
          />
        </Popover>

        <Popconfirm
          title="Are you sure to delete this project item?"
          onConfirm={(e) => {
            e.stopPropagation();
            onDeleteItem(index);
          }}
          okText="Yes"
          cancelText="No"
        >
          <DeleteTwoTone
            className="gx-pointer"
            twoToneColor="#a94442"
            onClick={(e) => e.stopPropagation()}
          />
        </Popconfirm>
      </>
    );
  };

  const onSelectWidget = (widgetName) => {
    const foundWidget = _.find(availableWidgets, { value: widgetName });

    if (foundWidget) {
      setSelectedWidget(foundWidget);
    }
  };

  const onCloseModal = () => {
    setShowModal(false);
    setSelectedWidget(null);
  };

  const editAccessRolesRef = useRef();
  const rolesOptions = _.map(roles, (role) => ({
    label: role?.displayName,
    value: role?.id,
  }));

  const applyToAll = (targetField, ops) => {
    const newSortableRows = _.map(projectSettings, (sortableRow) => {
      // const target = _.get(sortableRow, [targetField]);

      let newSettings = {};
      _.forEach(ops, (op) => {
        newSettings[op] = true;
      });

      const newTarget = {
        // ...target,
        ...newSettings,
      };

      return {
        ...sortableRow,
        [targetField]: newTarget,
      };
    });

    onSaveProjectPageSettings(newSortableRows);
  };

  const columns = [
    {
      title: "Type",
      dataIndex: "type",
      editable: false,
      responsive: ["sm"],
    },
    {
      title: "Widget Name",
      width: "30%",
      render: (p, item, index) => {
        const { widget } = item;

        const cur = _.find(availableWidgets, { value: widget }) || {};

        return (
          <div>
            <Popover
              placement="top"
              content={<span>{cur?.description || ""}</span>}
            >
              <span>{cur?.field || widget || ""}</span>
            </Popover>
          </div>
        );
      },
    },
    {
      title: "Target Field",
      dataIndex: "field",
      editable: false,
      responsive: ["sm"],
    },
    {
      // title: "Row Access Roles",
      dataIndex: "access",
      editable: false,
      responsive: ["sm"],
      render: renderAccessRoles,
      title: () => {
        return (
          <div>
            <span>Row Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={editAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = editAccessRolesRef.current.getFieldValue("options");
                applyToAll("access", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: "Actions",
      render: (p, rowItem, index) => renderActions(rowItem, index),
    },
  ];

  const onSave = () => {
    const newProjectSettings = _.uniqBy(
      [form, ...projectSettings],
      ["widget", "field"]
    );

    onSaveProjectPageSettings(newProjectSettings);

    setForm({});
    onCloseModal();
  };

  return (
    <>
      <div>
        <Divider orientation="left">PROJECT WIDGETS: </Divider>
        <Table columns={columns} dataSource={projectSettings} />
        <Button
          type="primary"
          size="small"
          className="gx-mt-2"
          onClick={() => setShowModal(true)}
        >
          Add New Item
        </Button>
      </div>

      <Drawer
        className="explorePage__drawer"
        placement="right"
        open={showModal}
        onClose={() => onCloseModal()}
        onOk={() => onCloseModal()}
        title="Row Settings"
      >
        <Form>
          <Form.Item
            label="Type"
            name="type"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={form?.type}
              onChange={(e) => onChangeValue("type", e)}
            >
              <Select.Option label="Process" key={"Process"}>
                Process
              </Select.Option>
              <Select.Option label="Experiment" key={"Experiment"}>
                Experiment
              </Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Display Name"
            name="name"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            required
            rules={[
              {
                required: true,
                message: "Please enter display name",
              },
            ]}
          >
            <Input
              value={form?.name}
              onChange={(e) => onChangeValue("name", e.target.value)}
            />
          </Form.Item>

          <Form.Item
            label="Widget"
            name="widget"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={widgetNames}
              showSearch
              value={form?.widget}
              onChange={(e) => {
                onSelectWidget(e);
                onChangeValue("widget", e);
              }}
            />
          </Form.Item>

          {selectedWidget ? (
            <Form.Item
              label="Field"
              name="field"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              required
              rules={[
                {
                  required: true,
                  message: "Please enter field",
                },
              ]}
            >
              <Select
                value={form?.field}
                onChange={(e) => {
                  onChangeValue("field", e);
                }}
                options={_.map(
                  _.filter(
                    selectedWidget?.widget?.rows || [],
                    (widget) => widget?.type === "EDITABLE"
                  ),
                  (row) => ({
                    label: row?.field,
                    value: row?.name,
                  })
                )}
                showSearch
              />
            </Form.Item>
          ) : null}

          <Divider />

          <div>
            <p className="gx-mb-3">Access Roles:</p>

            <Col className="gx-d-flex">
              {roles.map((role) => (
                <Form.Item
                  className="gx-mr-1"
                  id={role.id}
                  label={role.name}
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Switch
                    checked={_.get(form?.isAccessible, role?.id) || false}
                    onChange={(e) => onChangeAccessRole(role.id, e)}
                  />
                </Form.Item>
              ))}
            </Col>
          </div>

          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onSave();
              }}
            >
              Save
            </Button>

            <Button size="small" onClick={() => onCloseModal()}>
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default ProjectPageSettings;
