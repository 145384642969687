import {
  LOOKUP_ADD_IMAGE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Create action to get generic api request
 * @param {String} id current widget id
 * @param {String} sourceName source name
 * @param {String} widgetNamesToResolve widget Names To Resolve
 */
export const fetchLookupData = ({
  id,
  sourceName,
  processData,
  widgetName,
  isProcess = false,
}) => {
  return {
    type: LOOKUP_COMPOSITION_TABLE_REQUESTED,
    payload: {
      id,
      sourceName,
      processData,
      widgetName,
      isProcess,
    },
  };
};

export const fetchLookupImage = ({
  id,
  sourceName,
  processData,
  widgetName,
}) => {
  return {
    type: LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED,
    payload: {
      id,
      sourceName,
      processData,
      widgetName,
    },
  };
};

export const addImageToLookup = (id, imageData) => {
  return {
    type: LOOKUP_ADD_IMAGE_REQUESTED,
    payload: {
      id,
      imageData,
    },
  };
};
