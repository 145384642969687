import {
  SET_CURRENT_WIDGETS_REQUESTED,
  SET_CURRENT_WIDGETS_SUCCESS,
  SET_DOCUMENT_ROLES_REQUESTED,
  SET_DOCUMENT_ROLES_SUCCESS,
  SET_ROLES_REQUESTED,
  SET_ROLES_SUCCESS,
  SET_VIEW_REQUESTED,
  SET_VIEW_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  roles: [],
  documentRoles: [],
  currentWidgets: [],
  currentPageName: "",
  currentView: "",
};

const AuthReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ROLES_REQUESTED: {
      return {
        ...state,
        roles: [],
      };
    }
    case SET_DOCUMENT_ROLES_REQUESTED: {
      return {
        ...state,
        documentRoles: [],
      };
    }
    case SET_ROLES_SUCCESS: {
      return {
        ...state,
        roles: action.payload,
      };
    }
    case SET_DOCUMENT_ROLES_SUCCESS: {
      return {
        ...state,
        documentRoles: action.payload,
      };
    }

    case SET_CURRENT_WIDGETS_REQUESTED: {
      return {
        ...state,
        currentWidgets: [],
        currentPageName: "",
        currentDatabase: "",
      };
    }

    case SET_CURRENT_WIDGETS_SUCCESS: {
      return {
        ...state,
        currentWidgets: action.payload?.widgets || [],
        currentPageName: action.payload?.pageName,
        currentDatabase: action.payload?.database,
      };
    }

    case SET_VIEW_REQUESTED: {
      return {
        ...state,
        currentView: "",
      };
    }

    case SET_VIEW_SUCCESS: {
      return {
        ...state,
        currentView: action.payload?.pageName,
      };
    }

    default:
      return state;
  }
};

export default AuthReducer;
