import { Button, Table } from "antd";
import React, { useRef } from "react";
import TableFilter from "../../../components/TableFilter";
import { SearchOutlined } from "@ant-design/icons";
import { checkField } from "../../../util/Widget";

const UserLayoutSettings = ({
  setShowModal,
  resetForm,
  dataSourceTableOptions,
  renderOptions,
  renderAction,
  renderActionButtons,
}) => {
  const searchInput = useRef(null);

  return (
    <>
      <Button
        className="gx-mt-4"
        size="small"
        type="primary"
        onClick={() => {
          setShowModal(true);
          resetForm();
        }}
      >
        Add New User Layout
      </Button>
      <Table dataSource={dataSourceTableOptions} pagination={false}>
        <Table.Column
          title="Page Name"
          dataIndex="page"
          sorter={(a, b) => {
            return (a?.page || "").localeCompare(b?.page || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Name"
          dataIndex="name"
          sorter={(a, b) => {
            return (a?.name || "").localeCompare(b?.name || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Databases"
          dataIndex="database"
          width="30%"
          // render={(p, { database = [] }) => <span>{database.join(", ")}</span>}
          sorter={(a, b) => {
            return (a?.name || "").localeCompare(b?.name || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Action"
          render={renderActionButtons}
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
          }) => (
            <TableFilter
              searchInput={searchInput}
              dataIndex={"name"}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              close={close}
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value, record) => {
            return (
              checkField(value, record["page"]) ||
              checkField(value, record["name"])
            );
          }}
        ></Table.Column>
      </Table>
    </>
  );
};

export default UserLayoutSettings;
