/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Form, Input } from "antd";
import PT from "prop-types";

const GroupForm = ({ onChangeValue, form }) => {
  return (
    <>
      <Col>
        <Form.Item
          label="Display Field Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Group Id"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.groupId}
            onChange={(e) => onChangeValue("groupId", e.target.value)}
          />
        </Form.Item>
      </Col>
    </>
  );
};

GroupForm.defaultProps = {
  form: {},
};

GroupForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default GroupForm;
