import {
  GET_GROUPS_REQUESTED,
  GET_MEMBER_GROUPS_REQUESTED,
  SEARCH_USERS_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Get Security Groups from Microsoft Graph API
 * @param {String} accessToken
 */
export const getGroups = (accessToken) => {
  return {
    type: GET_GROUPS_REQUESTED,
    payload: {
      accessToken,
    },
  };
};
/**
 * Search for users from Graph API
 * @param {String} searchTerm user name
 * @param {String} accessToken
 */
export const searchUsers = (searchTerm, accessToken) => {
  return {
    type: SEARCH_USERS_REQUESTED,
    payload: {
      accessToken,
      searchTerm,
    },
  };
};

/**
 * Get Authenticated user's assigned group info.
 * @param {String} accessToken
 */
export const listMemberGroups = (accessToken) => {
  return {
    type: GET_MEMBER_GROUPS_REQUESTED,
    payload: {
      accessToken,
    },
  };
};
