import React, { useState } from "react";
import PT from "prop-types";
import { Button, Form, message, Popconfirm, Radio, Select, Table } from "antd";
import { getLanguageItem } from "../../../../util/SafetySentences";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { DeleteTwoTone } from "@ant-design/icons";
import CircularProgress from "components/CircularProgress";

const SelectSafetySentence = ({
  defaultParams,
  group,
  elements,
  languageMap,
  onFinish,
  onRemove,
  onSave,
  loading,
}) => {
  const [selectedSentences, setSelectedSentences] = useState();
  const [selectCategory, setSelectCategory] = useState();

  const options = _.uniq(
    _.filter(
      (selectedSentences?.category || []).map((item) => item?.classification),
      (item) => (item || "").length
    )
  );

  const onChangeSearch = (statement) => {
    const statementId = _.trim(statement.split("-")[0] || "");

    const currentStatement = _.find(elements, { statementId });

    if (currentStatement) {
      setSelectedSentences(currentStatement);
      setSelectCategory("");
    }
  };

  const renderActionColumn = (p, { statement, category, isParent }) => {
    return (
      <>
        {isParent ? null : (
          <Popconfirm
            title="Are you sure to remove this sentence?"
            onConfirm={() => onRemove({ statement, category })}
            okText="Yes"
            cancelText="No"
          >
            <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
          </Popconfirm>
        )}
      </>
    );
  };

  const validate = ({ statement, category }) => {
    const statementId = _.trim(statement.split("-")[0] || "");

    const isExists = options?.length
      ? _.filter(
          defaultParams,
          (item) =>
            item?.statement === statementId && item?.category === category
        )
      : _.filter(defaultParams, (item) => item?.statement === statementId);

    if (_.isEmpty(selectCategory) && options.length) {
      message.error("Please select category.");
    } else if ((isExists || []).length) {
      message.error("Sentence already exists.");
    } else {
      onFinish({ statement: statementId, category: selectCategory });
    }
  };

  return (
    <div className="gx-mt-3 gx-ml-4">
      <Form onFinish={validate}>
        <Form.Item
          label="Select Statement"
          name="statement"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select onChange={onChangeSearch} showSearch>
            {(group || []).map((groupItem) => (
              <>
                {(groupItem?.elements || []).map((item) => {
                  const text = `${item?.statementId} - ${getLanguageItem(
                    item?.statementId,
                    languageMap
                  )}`;

                  return (
                    <Select.Option value={text} key={uuidv4()}>
                      {item?.statementId} {"- "}
                      <span
                        dangerouslySetInnerHTML={{
                          __html: getLanguageItem(
                            item?.statementId,
                            languageMap
                          ),
                        }}
                      ></span>
                    </Select.Option>
                  );
                })}
              </>
            ))}
          </Select>
        </Form.Item>

        {(options || []).length ? (
          <Form.Item
            label="Select Category"
            name="category"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Radio.Group
              onChange={(e) => {
                setSelectCategory(e.target.value);
              }}
            >
              {options.map((option) => (
                <Radio value={option}>
                  {getLanguageItem(option, languageMap)}
                </Radio>
              ))}
            </Radio.Group>
          </Form.Item>
        ) : null}
        <Form.Item
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          className="gx-mt-3"
        >
          <Button htmlType="submit" size="small">
            Add
          </Button>
          <Button size="small" type="primary" onClick={onSave}>
            Save
          </Button>
        </Form.Item>
      </Form>

      {loading ? (
        <CircularProgress />
      ) : (
        <Table dataSource={defaultParams} pagination={false} bordered>
          <Table.Column
            title="Statement"
            key="statement"
            render={(p, { statement }) => (
              <span
                dangerouslySetInnerHTML={{
                  __html: `<strong>${statement}</strong> - ${getLanguageItem(
                    statement,
                    languageMap
                  )}`,
                }}
              />
            )}
          ></Table.Column>
          <Table.Column
            title="Category"
            key="category"
            render={(p, { category }) => (
              <span
                dangerouslySetInnerHTML={{
                  __html: getLanguageItem(category, languageMap),
                }}
              />
            )}
          ></Table.Column>
          <Table.Column
            title="Action"
            render={renderActionColumn}
          ></Table.Column>
        </Table>
      )}
    </div>
  );
};

SelectSafetySentence.defaultProps = {
  defaultParams: [],
  group: [],
  elements: [],
  loading: false,
};

SelectSafetySentence.propTypes = {
  defaultParams: PT.arrayOf(PT.shape()),
  group: PT.arrayOf(PT.shape()),
  elements: PT.arrayOf(PT.shape()),
  languageMap: PT.shape(),
  onFinish: PT.func.isRequired,
  onRemove: PT.func.isRequired,
  loading: PT.bool,
};

export default SelectSafetySentence;
