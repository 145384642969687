import React, { useEffect, useState } from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";

import "./style.css";
import _ from "lodash";
import { Tooltip } from "antd";
import { getGenericApi } from "../../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { copyToClipboard } from "../../../../util/url";

const RowUrl = ({
  id,
  sourceName,
  row,
  info = "",
  hideInfo = false,
  isAccessible = false,
}) => {
  const dispatch = useDispatch();
  const [data, setData] = useState(null);
  const [copied, setCopied] = useState(false);
  const { field, baseUrl, params, headers, isCopiable, hasTooltip } = row;
  const genericData = useSelector(({ generic }) => generic);
  const [firstRender, setFirstRender] = useState(false);

  useEffect(() => {
    const expectedData = _.get(genericData, [id, field]);
    const isInitialized = _.get(genericData, [id]);

    if (
      !!data === false &&
      !isInitialized &&
      !genericData.loading &&
      !firstRender &&
      isAccessible
    ) {
      setFirstRender(true);
      setData("");
      dispatch(getGenericApi(id, sourceName, field, baseUrl, params, headers));
    } else {
      setData(expectedData);
    }
  }, [
    baseUrl,
    data,
    dispatch,
    field,
    firstRender,
    genericData,
    headers,
    id,
    isAccessible,
    params,
    sourceName,
  ]);

  if (!isAccessible) {
    return null;
  }

  return (
    <div className={"card-item"} key={uuidv4()}>
      <div className="card-item-field">
        <span>{field}</span>
        {info.length && !hideInfo ? (
          <Tooltip title={info}>
            <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
          </Tooltip>
        ) : null}
        {isCopiable && (
          <>
            {copied ? (
              <i
                className="icon icon-check success"
                style={{ marginTop: "3px" }}
              />
            ) : (
              <i
                className="icon icon-copy"
                style={{ marginTop: "3px" }}
                onClick={() => copyToClipboard(data, copied, setCopied)}
              />
            )}
          </>
        )}
      </div>
      <div className="card-item-value half-width">
        {hasTooltip ? (
          <Tooltip title={data} color="#038fdd">
            <span className="trimmed">{data}</span>
          </Tooltip>
        ) : (
          <span>{data}</span>
        )}
      </div>
    </div>
  );
};

RowUrl.defaultProps = {
  id: "",
  row: {},
  info: "",
};

RowUrl.propTypes = {
  id: PT.string,
  row: PT.shape(),
  info: PT.string,
};

export default RowUrl;
