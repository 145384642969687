/* eslint-disable no-unused-vars */
import React, { useReducer, useState } from "react";
import { Button, Spin, Table, Tag, Tooltip } from "antd";

import "./style.css";
import { autoAssignApi, checkUniqApi, registerRequestApi } from "../service";
import _ from "lodash";
import { Link } from "react-router-dom";

const UnKnownResult = ({ options, sourceName }) => {
  const [tableData, setTableData] = useState(options || []);
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const [validateLoading, setValidateLoading] = useState(false);
  const [uniqLoading, setUniqLoading] = useState(false);
  const [registerLoading, setRegisterLoading] = useState(false);

  // Run auto assign agains table data.
  const validate = () => {
    let promises = [];

    _.forEach(tableData, (item) => {
      promises.push(autoAssignApi({ result: item?.value }));
    });

    let newTableSet = tableData;

    setValidateLoading(true);
    Promise.allSettled(promises).then((results) => {
      _.forEach(results, (result, index) => {
        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            validate: {
              ...(result?.value?.data || {}),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            validate: {
              validateError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setValidateLoading(false);
    });
  };

  // Check uniqueness against table data
  const checkUniq = () => {
    let promises = [];

    _.forEach(tableData, (item) => {
      promises.push(
        checkUniqApi({ result: item?.value, sourceName: sourceName })
      );
    });

    let newTableSet = tableData;

    setUniqLoading(true);
    Promise.allSettled(promises).then(async (results) => {
      _.forEach(results, (result, index) => {
        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            uniq: {
              ...(result?.value?.data || {}),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            uniq: {
              uniquenessError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setUniqLoading(false);
    });
  };

  // register
  const register = () => {
    let promises = [];
    let ids = [];
    _.forEach(tableData, (item) => {
      promises.push(
        registerRequestApi(item?.value, sourceName, false, true, null)
      );
      ids.push(item?.id);
    });

    let newTableSet = tableData;

    setRegisterLoading(true);
    Promise.allSettled(promises).then(async (results) => {
      _.forEach(results, (result, resultId) => {
        const index = ids[resultId];

        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            register: {
              registerCompoundId: _.get(result, "value.data[0].compoundId"),
              registerMatch: _.get(result, "value.data[0].match"),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            register: {
              registerError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setRegisterLoading(false);
    });
  };

  const renderMatches = (matches) => {
    return (
      <>
        {_.map(matches, (match, index) => (
          <Tag color="#2db7f5">
            <Link
              key={index}
              to={`/${sourceName}/compound/${match}`}
              target="_blank"
              replace
            >
              {match}
            </Link>
          </Tag>
        ))}
      </>
    );
  };

  return (
    <Table dataSource={[...tableData]} pagination={false}>
      <Table.Column
        title="Structure"
        key="id"
        width={20}
        render={(p, { id }) => <span>{id}</span>}
      ></Table.Column>
      <Table.Column
        title="MOL"
        key="value"
        width={100}
        render={(p, { value }) => (
          <div className="card-item-value half-width">
            <Tooltip title={value} color="#038fdd">
              <span className="molColumn">{value}</span>
            </Tooltip>
          </div>
        )}
      ></Table.Column>
      <Table.Column
        key="noStructure"
        width={100}
        title={
          <div>
            {validateLoading ? (
              <Spin size="small" tip="loading" />
            ) : (
              <Button
                size="small"
                type="primary"
                onClick={validate}
                disabled={uniqLoading || validateLoading || registerLoading}
              >
                Validate
              </Button>
            )}
          </div>
        }
        render={(p, { validate = {} }) => {
          const { achiral, noStructure, validateError, opticalIsomer } =
            validate;
          return (
            <div className="card-item-value">
              {noStructure === true ? <span>No Structure</span> : null}
              {achiral === true ? (
                <span className="gx-text-success">Achiral</span>
              ) : null}
              {opticalIsomer === "undefined" ? (
                <span className="gx-text-secondary">Undefined</span>
              ) : null}
              {validateError ? (
                <span className="gx-text-warning">{validateError}</span>
              ) : null}
            </div>
          );
        }}
      ></Table.Column>
      <Table.Column
        key="unique"
        width={100}
        title={
          <div>
            {uniqLoading ? (
              <Spin size="small" tip="loading" />
            ) : (
              <Button
                size="small"
                type="primary"
                onClick={checkUniq}
                disabled={uniqLoading || validateLoading || registerLoading}
              >
                Uniqueness Check
              </Button>
            )}
          </div>
        }
        render={(p, { uniq = {} }) => {
          const { unique, uniquenessError, exactMatch } = uniq;
          return (
            <div className="card-item-value">
              {unique === true ? <span>Unique</span> : null}
              {(exactMatch || []).length ? (
                <div>
                  <span>Exact Match: </span>
                  {renderMatches(exactMatch)}
                </div>
              ) : null}
              {uniquenessError ? (
                <span className="gx-text-warning">{uniquenessError}</span>
              ) : null}
            </div>
          );
        }}
      ></Table.Column>
      <Table.Column
        key="register"
        width={100}
        title={
          <div>
            {registerLoading ? (
              <Spin size="small" tip="loading" />
            ) : (
              <Button
                size="small"
                type="primary"
                onClick={register}
                disabled={uniqLoading || validateLoading || registerLoading}
              >
                Register
              </Button>
            )}
          </div>
        }
        render={(p, { register = {} }) => {
          const { registerMatch, registerCompoundId, registerError } = register;
          return (
            <div className="card-item-value">
              {(registerMatch || []).length ? (
                <div>
                  <span>Duplicate Match: </span>
                  {renderMatches([registerMatch])}
                </div>
              ) : null}
              {(registerCompoundId || []).length ? (
                <div className="gx-text-success">
                  <span>Registered: </span>
                  {renderMatches([registerCompoundId])}
                </div>
              ) : null}
              {registerError ? (
                <span className="gx-text-warning">{registerError}</span>
              ) : null}
            </div>
          );
        }}
      ></Table.Column>
    </Table>
  );
};

export default UnKnownResult;
