/**
 * Helper functions for Apply page.
 */
import {
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Table,
} from "antd";
import throttleAxios from "../../appRedux/services/apiThrottle";
import _ from "lodash";
import React from "react";
import {
  config,
  DOCUMENT_UPLOAD_OPTIONS,
  MAX_CONCURRENT_REQUEST,
} from "../../constants/Config";
import axiosThrottle from "axios-request-throttle";
import axios from "axios";

const serialAxiosInstance = axios.create();

axiosThrottle.use(throttleAxios, { requestsPerSecond: MAX_CONCURRENT_REQUEST });
axiosThrottle.use(serialAxiosInstance, { requestsPerSecond: 1 });

/**
 * Render Manage Settings Modal Form
 * @param {*} param0
 * @returns
 */
export const renderManageSettingsModal = ({
  formRef,
  availableWidgetsWithKeys,
  selectedCollectionOptions,
  setSelectedWidgetName,
  selectedWidgetName,
  options,
}) => {
  const optionsWithKeys = _.map(options || [], (item, index) => ({
    ...item,
    key: index,
  }));

  return (
    <Form ref={formRef}>
      <Form.Item
        label="Key"
        required
        name="key"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Widget Name"
        name="widgetName"
        required
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Select
          showSearch
          options={availableWidgetsWithKeys}
          defaultValue={selectedCollectionOptions}
          onChange={(e) => setSelectedWidgetName(e)}
        ></Select>
      </Form.Item>
      <Form.Item
        label="Target Field"
        name="field"
        required
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Select
          disabled={!selectedWidgetName}
          showSearch
          options={optionsWithKeys}
        />
      </Form.Item>
      <Form.Item
        label="Additional Field"
        name="additionalField"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Input placeholder="(Optional) Enter additional field" />
      </Form.Item>
    </Form>
  );
};

/**
 * Render settings edit modal
 * @param {*} param0
 * @returns
 */
export const renderSettingsEditModal = ({ settingsFormRef }) => {
  const id = settingsFormRef.current?.getFieldValue("id");
  const item = settingsFormRef.current?.getFieldValue("name");
  return (
    <Form ref={settingsFormRef}>
      <Form.Item label="Name" name="name">
        <Input value={item || ""} />
      </Form.Item>
      <Form.Item label="Id" name="id" style={{ display: "none" }}>
        <Input value={id || ""} />
      </Form.Item>
    </Form>
  );
};

/**
 * Render Mapping Settings Compnonent
 * @param {*} param0
 * @returns
 */
export const renderMappingSettings = ({
  settingsOptions,
  selectedSettings,
  onChangeSettings,
  setSettingsModal,
  selectedType,
  collectionOptions,
  setSelectedCollectionOptions,
  selectedCollectionOptions,
  headerOptions,
  selectedId,
  setSelectedId,
  setIsProject,
  dataSourceMap,
  onEdit,
  onDelete,
  documentUploadtype,
  setOnlyQualities,
}) => {
  const renderActionButtons = (p, item) => {
    return (
      <>
        {item?.unmapped ? (
          <Popover content="Add mapping">
            <PlusCircleTwoTone
              twoToneColor="#038FDE"
              onClick={() => onEdit(item)}
            />
          </Popover>
        ) : (
          <div className="gx-d-flex">
            <Popover content="Edit mapping">
              <EditTwoTone
                twoToneColor="#038FDE"
                className="gx-mr-3"
                onClick={() => onEdit(item)}
              />
            </Popover>
            <Popconfirm
              title="Are you sure to delete?"
              onConfirm={() => onDelete(item?.field)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
            </Popconfirm>
          </div>
        )}
      </>
    );
  };

  /**
   * Get Title Element
   * @param {*} p
   * @param {*} param1
   * @returns
   */
  const renderTitle = (p, { key, unmapped }) => (
    <>
      {unmapped ? (
        <div className="gx-text-warning">{key}</div>
      ) : (
        <div>{key}</div>
      )}
    </>
  );

  /**
   * Render Widget Name Component
   * @param {*} p
   * @param {*} param1
   * @returns
   */
  const renderWidgetName = (p, { widgetName, unmapped }) => (
    <>
      {unmapped ? (
        <div className="gx-text-warning">Not mapped</div>
      ) : (
        <div>{widgetName}</div>
      )}
    </>
  );

  return (
    <>
      <div className="gx-d-flex">
        <Form.Item label="Select Settings" className="gx-ml-1">
          <Select
            options={settingsOptions}
            showSearch
            defaultValue={selectedSettings?.mappingName}
            placeholder="Select Settings"
            onChange={onChangeSettings}
            dropdownMatchSelectWidth={false}
          />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            size="small"
            className="gx-ml-5 gx-mt-2"
            onClick={() => setSettingsModal(true)}
          >
            Manage Settings
          </Button>
        </Form.Item>
      </div>
      {selectedType === "document" && (
        <Form.Item label="Select Collection" className="gx-ml-1">
          <Select
            options={collectionOptions}
            onChange={setSelectedCollectionOptions}
            defaultValue={selectedCollectionOptions}
            showSearch
          ></Select>
        </Form.Item>
      )}
      {selectedType === "widget" && (
        <>
          {documentUploadtype !==
          DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA ? (
            <>
              <Form.Item label="Select Target Id" className="gx-ml-1">
                <Select
                  options={headerOptions}
                  onChange={setSelectedId}
                  defaultValue={selectedId}
                  showSearch
                ></Select>
              </Form.Item>
              <Form.Item label="Is Project" className="gx-ml-1">
                <Checkbox onChange={(e) => setIsProject(e.target.checked)} />
              </Form.Item>
              <Form.Item label="Only upload qualities" className="gx-ml-1">
                <Checkbox
                  onChange={(e) => setOnlyQualities(e.target.checked)}
                />
              </Form.Item>
            </>
          ) : null}
        </>
      )}
      <div>
        {!_.isEmpty(selectedSettings) && (
          <Table dataSource={dataSourceMap} pagination={false} rowKey="Title">
            <Table.Column
              key={1}
              title="Key"
              render={renderTitle}
            ></Table.Column>
            <Table.Column
              key={2}
              title="Widget Name"
              render={renderWidgetName}
            ></Table.Column>
            <Table.Column
              key={3}
              title="Target Field"
              dataIndex="field"
            ></Table.Column>
            <Table.Column
              key={4}
              title="Action"
              render={renderActionButtons}
            ></Table.Column>
          </Table>
        )}
      </div>
    </>
  );
};

/**
 * Normalize and Get Collection Items Options
 * @param {*} param0
 * @returns
 */
export const getOptions = ({ targetFieldOptions = {} }) => {
  const defaultOptions = [
    { field: "Entity Ids", value: "entityIds", type: "LIST" },
    { field: "Name", value: "name", type: "EDITABLE" },
    { field: "Description", value: "description", type: "EDITABLE" },
    { field: "Owners", value: "owners", type: "LIST" },
    { field: "Accession Number", value: "accessionNumber", type: "EDITABLE" },
  ];

  const options = [
    ..._.map(targetFieldOptions?.rows, (item) => ({
      field: item?.field,
      value: item?.name,
      type: item?.type,
    })),
    ...defaultOptions,
  ];

  return options;
};

const saveFileWithExternalDocuments = ({
  sourceName,
  widgetMap,
  widgetName,
  method,
  updateStatus,
  index,
  src,
  updateDocumentId,
  externalDocuments,
  onExternalDocumentUpdate,
}) => {
  const formData = new FormData();

  let body = {};

  body.mimeType = "text/x-uri";
  body.uri = "";
  body.name = _.get(widgetMap, "name");
  body.description = _.get(widgetMap, "description");
  body.owners = _.get(widgetMap, "owners");
  body.accessionNumber = Number(_.get(widgetMap, "accessionNumber"));
  body.locked = true;

  // Remove .0 occurrences from entity ids
  const substances = _.get(widgetMap, "entityIds");
  const substancesMapped = _.map(substances, (substance) => {
    if (substance.includes(".0")) {
      return substance.split(".")[0];
    } else {
      return substance;
    }
  });

  body.entityIds = substancesMapped;
  body.collection = widgetName;

  // Collect Search Subjects.
  let searchSubjects = [];

  const additionalData = _.omit(widgetMap, [
    "name",
    "description",
    "owners",
    "entityIds",
    "accessionNumber",
  ]);

  _.forEach(additionalData, (value, key) => {
    if (key) {
      if (_.isArray(value)) {
        _.forEach(value, (item) => {
          searchSubjects.push({
            key,
            value: item,
          });
        });
      } else {
        if (!_.isEmpty(value)) {
          searchSubjects.push({
            key,
            value,
          });
        }
      }
    }
  });

  body.searchSubjects = searchSubjects;

  formData.append("file", []);
  formData.append("request", JSON.stringify(body));

  const req =
    method === "POST"
      ? throttleAxios.post(
          `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data;charset=UTF-8",
            },
          }
        )
      : src?.documentId
      ? throttleAxios.put(
          `${config.SRD_API_URL}/repository/document?source=${sourceName}&documentId=${src?.documentId}`,
          JSON.stringify(body),
          {
            headers: {
              "Content-Type": "application/json;charset=UTF-8",
            },
          }
        )
      : null;

  if (req) {
    Promise.allSettled([req]).then((results) => {
      if (results[0].status === "fulfilled") {
        const response = results[0].value;
        const id = response?.data?.id;
        const responseMessage =
          _.isArray(response) && response.length
            ? response[0]?.data?.message
            : response?.data?.message;

        // External document promises needs to be uploaded.
        let promises = [];

        _.forEach(externalDocuments, (document) => {
          const formData = new FormData();

          const name = _.get(document, "Display name");
          const uri = _.get(document, "Server filename");
          const type = _.get(document, "Type");
          const description = _.get(document, "Title");

          let searchSubjects = [];
          if (type) {
            searchSubjects.push({
              key: "type",
              value: type,
            });
          }

          const body = {
            name,
            uri,
            mimeType: "text/x-uri",
            description,
            searchSubjects,
            collection: widgetName,
            subset: id,
            locked: true,
          };

          formData.append("file", []);
          formData.append("request", JSON.stringify(body));

          promises.push(
            method === "POST"
              ? throttleAxios.post(
                  `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data;charset=UTF-8",
                    },
                  }
                )
              : throttleAxios.put(
                  `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
                  formData,
                  {
                    headers: {
                      "Content-Type": "multipart/form-data;charset=UTF-8",
                    },
                  }
                )
          );
        });

        // If there is no external documents
        if (!(promises || []).length) {
          if (responseMessage === "file already exists") {
            updateDocumentId(index, id);
            updateStatus(src["id"], index, "exists");
            onExternalDocumentUpdate({
              documentId: id,
              targetSourceName: sourceName,
              path: response?.data?.uri,
            });
          } else {
            updateStatus(src["id"], index, "success");
          }
        } else {
          Promise.allSettled(promises).then((externalDocumentsResults) => {
            updateStatus(src["id"], index, "success");

            _.forEach(externalDocumentsResults, (item) => {
              const { data } = item.value;
              onExternalDocumentUpdate({
                documentId: data?.id,
                targetSourceName: sourceName,
                path: data?.uri,
              });
            });
          });
        }
      } else {
        updateStatus(
          src["id"],
          index,
          "error",
          results[0].value?.response?.data?.message || results[0]?.value
        );
      }
    });
  }
};

/**
 * Upload Documents.
 * @param {*} param0
 */
export const uploadToDocument = async ({
  widgetMap,
  externalDocuments,
  widgetName,
  method,
  sourceName,
  updateStatus,
  index,
  src,
  updateDocumentId,
  forceUpdate,
  onExternalDocumentUpdate,
}) => {
  saveFileWithExternalDocuments({
    sourceName,
    widgetMap,
    widgetName,
    method,
    updateStatus,
    index,
    src,
    updateDocumentId,
    forceUpdate,
    externalDocuments,
    onExternalDocumentUpdate,
  });
  updateStatus(src["id"], index, "processing");
};

export const uploadToSystemWidget = ({
  targetId,
  widgetMap,
  widgetName,
  selectedDataSource,
  src,
  index,
  updateStatus,
}) => {
  throttleAxios
    .post(
      `${config.SRD_API_URL}/metadata/${widgetName}?compoundId=${targetId}&sourceName=${selectedDataSource}`,
      widgetMap,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      updateStatus(src["id"], index, "success");
    })
    .catch((e) => {
      updateStatus(src["id"], index, "error", e?.response?.data?.message || e);
    });
};

export const getCompoundConceptApi = ({ selectedDataSource, targetId }) => {
  return throttleAxios.get(
    `${config.SRD_API_URL}/compoundConcept?source%20name=${selectedDataSource}&id=${targetId}`
  );
};

export const createInstance = async ({ targetId, selectedDataSource }) => {
  return serialAxiosInstance.post(
    `${config.SRD_API_URL}/compoundInstance?sourceName=${selectedDataSource}&compoundId=${targetId}`,
    {
      instanceComponents: [],
      conceptComponents: []
    },
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const uploadQuality = async ({
  targetId,
  widgetMap,
  widgetName,
  selectedDataSource,
}) => {
  return throttleAxios.post(
    `${config.SRD_API_URL}/metadata/${widgetName}?compoundId=${targetId}&sourceName=${selectedDataSource}`,
    widgetMap,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const searchDocumentation = async (sourceName, pageName, setFn) => {
  const query = {
    documentQueries: [
      {
        queryJoinType: "AND",
        documentQuery: {
          sources: {
            values: [sourceName],
            operator: "EQ",
            fieldType: "STRING",
          },
          subset: {
            values: [pageName],
            operator: "EQ",
            fieldType: "STRING",
          },
        },
        order: 0,
        language: "en",
      },
    ],
  };

  return axios
    .post(`${config.SRD_API_URL}/search/documents`, query, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      setFn(res?.data?.hitList);
    });
};
