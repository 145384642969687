/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Drawer, Popover, Spin, Steps, Tag, Tooltip } from "antd";
import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

import "./style.css";
import {
  getSystemWidget,
  getUserWidget,
} from "../../appRedux/services/SystemWidget";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  TYPE_OPTIONS,
  UPLOAD_FILE_MAPPING,
} from "../../constants/Config";
import { getRole, getUserName } from "../../util/auth";
import { useDispatch, useSelector } from "react-redux";
import { getDataSources, setCurrentWidgets } from "../../appRedux/actions";
import {
  CheckCircleTwoTone,
  LoadingOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import SelectFile from "./SelectFile";
import ApplyData from "./ApplyData";
import UploadSettings from "./UploadSettings";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { getPrefix, parseSubstance } from "../../util/Widget";
import SelectType from "./SelectType";
import {
  createInstance,
  getCompoundConceptApi,
  getOptions,
  searchDocumentation,
  uploadQuality,
  uploadToDocument,
  uploadToSystemWidget,
} from "./helper";
import BulkUpdate from "./BulkUpdate";
import { executeSequentially } from "../../util/api";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import SelectUploadType from "./SelectUploadType";
import { DOCUMENT_UPLOAD_OPTIONS } from "../../constants/Config";
import UploadStructures from "./UploadStructures";
import Paragraph from "antd/lib/skeleton/Paragraph";

let externalDocuments = [];

const UploadPage = () => {
  const dispatch = useDispatch();

  const [headers, setHeaders] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [currentSettings, setCurrentSettings] = useState([]);
  const [loading, setLoading] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [isProject, setIsProject] = useState(false);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [dataWidgets, setDataWidgets] = useState([]);
  const [documentOptions, setDocumentOptions] = useState([]);

  const [selectedSettings, setSelectedSettings] = useState();
  const [selectedType, setSelectedType] = useState("widget");
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [selectedCollectionOptions, setSelectedCollectionOptions] = useState();
  const [existingDocuments, setExistingDocuments] = useState([]);
  const [selectedWidgetName, setSelectedWidgetName] = useState();
  const [targetFieldOptions, setTargetFieldOptions] = useState([]);
  const [sdfFile, setSdfFile] = useState();
  const [documentations, setDocumentations] = useState([]);
  const [defaultSourceName, setDefaultSourceName] = useState({});
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);

  const [onlyUploadQualities, setOnlyQualities] = useState(false);

  const [selectedId, setSelectedId] = useState();
  const [dataLoading, setDataLoading] = useState(false);

  // upload type table
  const [tableData, setTableData] = useState([]);

  const [documentUploadtype, setDocumentUploadType] = useState();

  const { dataSources } = useSelector(({ search }) => search);
  const userName = getUserName();

  const options =
    documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
      ? [TYPE_OPTIONS.SDF, TYPE_OPTIONS.Spreadsheet]
      : [TYPE_OPTIONS.JSON, TYPE_OPTIONS.Spreadsheet];

  const [selectedOption, setSelectedOption] = useState(
    documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
      ? TYPE_OPTIONS.SDF
      : options[0]
  );

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: "upload",
        database: "",
      })
    );
  }, []);

  useEffect(() => {
    if (documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA) {
      setSelectedOption(TYPE_OPTIONS.SDF);
    }
  }, [documentUploadtype]);

  useEffect(() => {
    if (!collectionOptions.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setCollectionOptions);
    }
  }, []);

  useEffect(() => {
    getUserWidget(DEFAULT_SOURCE_NAME, userName, setDefaultSourceName, false);
  }, []);

  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      "home",
      setDocumentations
    );
  }, [defaultSourceName]);

  useEffect(() => {
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }

    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }

    if (!documentOptions.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setDocumentOptions);
    }
  }, []);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }

    getUserWidget(UPLOAD_FILE_MAPPING, userName, setCurrentSettings, true);
  }, []);

  const onChange = (e, selectedOption) => {
    const file = e?.originFileObj;
    const reader = new FileReader();

    if (
      documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES ||
      (documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA &&
        selectedOption === TYPE_OPTIONS.SDF)
    ) {
      setSdfFile(file);
    } else {
      reader.onload = (evt) => {
        if (selectedOption === TYPE_OPTIONS.JSON) {
          try {
            const data = JSON.parse(evt.target.result);
            const first = data[0];
            const headerData = _.map(first, (item, key) => key);

            setDataSource(data);
            setHeaders(headerData);
          } catch (error) {
            NotificationManager.error("Invalid JSON file.");
          }
        } else {
          try {
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: "binary" });
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            const headerData = XLSX.utils.sheet_to_json(ws, { header: 1 })[0];

            data.shift();
            const tableData = data.map((item) => {
              let current = {};
              item.forEach((i, currentIndex) => {
                current[headerData[currentIndex]] = i;
              });

              return current;
            });

            const filteredTableData = tableData.filter(
              (data) => !_.isEmpty(data)
            );
            setDataSource(filteredTableData);
            setHeaders(headerData);
          } catch (e) {
            NotificationManager.error("Invalid CSV file.");
          }
        }
      };

      if (selectedOption === TYPE_OPTIONS.JSON) {
        reader.readAsText(file, "utf-8");
      } else {
        reader.readAsBinaryString(file, "utf-8");
      }
    }
  };

  const updateStatus = (id, index, targetStatus, targetReason = "") => {
    if (documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA) {
      tableData[index].status = targetStatus;

      if (targetStatus === "error") {
        tableData[index].reason = targetReason;
      }
    } else {
      dataSource[index].status = targetStatus;

      if (targetStatus === "error") {
        dataSource[index].reason = targetReason;
      }
    }

    forceUpdate();
  };

  const updateDocumentId = (index, documentId) => {
    dataSource[index].documentId = documentId;
  };

  const tableColumns = headers.map((item) => {
    if (item === "Substances") {
      return {
        key: item,
        title: item,
        render: (p, { Substances = [] }) => {
          return (Substances || []).map((sub, index) => (
            <Tag color="#2db7f5" key={index}>
              <Link
                to={`/${selectedDataSource}/compound/${parseSubstance(
                  sub,
                  getPrefix(dataSources, selectedDataSource)
                )}`}
                target="_blank"
                replace
              >
                {parseSubstance(
                  sub,
                  getPrefix(dataSources, selectedDataSource)
                )}
              </Link>
            </Tag>
          ));
        },
      };
    }

    if (item === "Structure") {
      return {
        key: item,
        title: item,
        width: "200px",

        render: (p, { structure }) => {
          return (
            <Popover placement="top" content={<span>{structure}</span>}>
              {(structure || "").slice(0, 10)}...
            </Popover>
          );
        },
      };
    }

    return {
      key: item,
      dataIndex: item,
      title: item,
      ellipsis: true,
      width: "100px",
    };
  });

  const onExternalDocumentUpdate = ({ documentId, targetSourceName, path }) => {
    externalDocuments.push({
      documentId,
      targetSourceName,
      path,
    });
  };

  const onExportExternalDocument = () => {
    const targetData = JSON.stringify({ externalDocuments });
    var blob = new Blob([targetData], {
      type: "application/json;charset=utf-8",
    });
    saveAs(blob, `export-${selectedCollectionOptions}.json`);
  };

  const loadData = async (method = "POST") => {
    const wn = _.groupBy(selectedSettings?.mapping, "widgetName");
    _.forEach(dataSource, async (src, index) => {
      _.forEach(wn, (widget, widgetName) => {
        let widgetMap = {};
        let externalDocuments = [];
        _.forEach(widget, async (item) => {
          const targetData = _.get(src, item?.dataKey);

          const options = getOptions({ targetFieldOptions });

          const currentFieldItem =
            _.find(options, {
              value: item?.field,
            }) || {};

          // Check Special Case: "Entity Ids" field.
          if (currentFieldItem?.value === "entityIds") {
            widgetMap[currentFieldItem?.value] = _.map(
              targetData,
              (eId) => `${getPrefix(dataSources, selectedDataSource)}-${eId}`
            );
          } else {
            switch (currentFieldItem?.type) {
              case "EDITABLE_DATE":
                if (moment(targetData).isValid()) {
                  widgetMap[currentFieldItem?.value] = moment(
                    targetData
                  ).format("YYYY-MM-DD HH:mm:ss");
                }
                break;
              case "FILE":
                externalDocuments = JSON.parse(targetData);
                break;
              case "LIST":
                try {
                  widgetMap[currentFieldItem?.value] = JSON.parse(targetData);
                } catch (error) {
                  widgetMap[currentFieldItem?.value] = targetData;
                }
                break;
              default:
                if (
                  targetData &&
                  !_.isNull(targetData) &&
                  targetData !== "null"
                ) {
                  widgetMap[currentFieldItem?.value] = targetData;
                }
            }
          }

          if (item?.additionalField) {
            widgetMap[item?.additionalField] = targetData;
          }
        });

        uploadToDocument({
          index,
          src,
          widgetName,
          widgetMap,
          sourceName: selectedDataSource,
          externalDocuments,
          method,
          updateStatus,
          updateDocumentId,
          forceUpdate,
          onExternalDocumentUpdate,
        });
      });
    });
  };

  const uploadWidgetData = () => {
    const wn = _.groupBy(selectedSettings?.mapping, "widgetName");

    let currentDataSource = dataSource;
    let currentSelectedId = selectedId;
    if (documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA) {
      currentDataSource = normalizeTableData(tableData);
      currentSelectedId = "targetId";
    }

    _.forEach(currentDataSource, async (src, index) => {
      const qualities = _.get(src, "Qualities") || [];

      if (_.isArray(qualities) && qualities.length > 0) {
        const compoundDetail = _.omit(qualities[0], "CIS no");
        // Combine first Quality data into src.
        const combinedSrc = {
          ...src,
          ...compoundDetail,
        };

        const targetId = `${getPrefix(dataSources, selectedDataSource)}-${_.get(
          combinedSrc,
          currentSelectedId
        )}`;

        _.forEach(wn, (widget, widgetName) => {
          let widgetMap = {};
          _.forEach(widget, (item) => {
            const targetData = _.get(combinedSrc, item?.dataKey);
            if (!_.isNull(targetData)) {
              _.set(widgetMap, [item?.field], targetData);
            }
          });

          /**
           * If only upload qualities is checked. Omit system widget creation call.
           */
          if (!_.isEmpty(widgetMap) && !onlyUploadQualities) {
            uploadToSystemWidget({
              targetId,
              widgetMap,
              widgetName,
              selectedDataSource,
              src: combinedSrc,
              index,
              updateStatus,
            });
          }
        });

        // Create Necessary Qualities.
        getCompoundConceptApi({
          selectedDataSource,
          targetId,
        }).then((result) => {
          const compoundInstances = result?.data?.compoundInstances || [];
          const targetQualityCount = Number(_.get(src, "Quality count")) - 1;
          const promises = [];

          if (compoundInstances.length < targetQualityCount) {
            _.times(targetQualityCount - compoundInstances.length, () => {
              promises.push(() =>
                createInstance({
                  targetId,
                  selectedDataSource,
                })
              );
            });
            // executeSequentially(promises);
          }
          // Upload Qualities
          const compoundQualities = _.get(combinedSrc, "Qualities");
          _.forEach(compoundQualities, (quality) => {
            _.forEach(wn, (widget, widgetName) => {
              let qualityMap = {};
              _.forEach(widget, (item) => {
                const targetData = _.get(quality, item?.dataKey);
                if (!_.isEmpty(targetData)) {
                  _.set(qualityMap, [item?.field], targetData);
                }
              });

              if (_.get(quality, "Quality no") > 0 && !_.isEmpty(qualityMap)) {
                promises.push(() =>
                  uploadQuality({
                    targetId: `${targetId}.${_.get(quality, "Quality no")}`,
                    widgetMap: qualityMap,
                    widgetName,
                    selectedDataSource,
                  })
                );
              }
            });
          });

          executeSequentially(promises);
        });
      } else {
        const combinedSrc = {
          ...src,
        };

        _.forEach(wn, (widget, widgetName) => {
          let widgetMap = {};

          _.forEach(widget, (item) => {
            const targetData = _.get(combinedSrc, item?.dataKey);
            if (!_.isNull(targetData)) {
              _.set(widgetMap, [item?.field], targetData);
            }
          });

          if (!_.isEmpty(widgetMap)) {
            uploadToSystemWidget({
              targetId: _.get(src, "targetId"),
              widgetMap,
              widgetName,
              selectedDataSource,
              src: combinedSrc,
              index,
              updateStatus,
            });
          }
        });
      }
    });
  };

  if (documentUploadtype !== DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA) {
    tableColumns.unshift({
      key: "status",
      title: "Status",
      render: (item) => {
        const currentStatus = item?.status;
        const errorReason = item?.reason;

        if (currentStatus === "loading") {
          return (
            <p className="gx-text-info">
              <LoadingOutlined className="gx-mr-1" />
              Uploading
            </p>
          );
        }
        if (currentStatus === "success") {
          return (
            <p className="gx-text-success">
              <CheckCircleTwoTone className="gx-mr-1" twoToneColor="#52C41A" />
              Uploaded!
            </p>
          );
        }
        if (currentStatus === "processing") {
          return (
            <p className="gx-text-info">
              <Spin className="gx-mr-2 gx-mt-2" size="small" />
              Processing
            </p>
          );
        }
        if (currentStatus === "error") {
          return (
            <Tooltip title={<span>{errorReason}</span>}>
              <p className="gx-text-danger">
                <WarningTwoTone className="gx-mr-1" twoToneColor="#F44336" />
                Error occurred
              </p>
            </Tooltip>
          );
        }

        if (currentStatus === "exists") {
          return <p className="gx-text-warning">File already exists.</p>;
        }

        if (currentStatus === "not_found") {
          return <p className="gx-text-warning">Target not found.</p>;
        }
        return <p className="gx-text-warning"></p>;
      },
    });
  }

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const availableWidgets = [
    ...systemWidgets,
    ...dataWidgets,
    ...documentOptions,
  ];

  const normalizeTableData = (arr) => {
    const newArr = _.map(arr, (item) => {
      return {
        ...item,
        targetId:
          _.get(item, "targetId") ||
          _.get(item, "value.data[0].compoundId") ||
          _.get(item, "uniq.exactMatch[0]"),
      };
    });

    return newArr;
  };

  const commonSteps = [
    {
      title: "Select Upload Type",
      content: (
        <SelectUploadType
          type={documentUploadtype}
          onChangeType={setDocumentUploadType}
        />
      ),
    },
  ];

  const structuresOnlySteps = [
    {
      title: "Select File",
      content: (
        <SelectFile
          onChange={onChange}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          documentUploadtype={documentUploadtype}
        />
      ),
    },
    {
      title: "Upload SDF",
      content: (
        <BulkUpdate
          dataSources={dataSources}
          setSelectedDataSource={setSelectedDataSource}
          selectedDataSource={selectedDataSource}
          file={sdfFile}
        />
      ),
    },
  ];

  const dataOnlySteps = [
    {
      title: "Select File",
      content: (
        <SelectFile
          onChange={onChange}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          documentUploadtype={documentUploadtype}
        />
      ),
    },
    {
      title: "Select Type",
      content: (
        <SelectType
          dataSources={dataSources}
          selectedDataSource={selectedDataSource}
          setSelectedDataSource={setSelectedDataSource}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          documentUploadtype={documentUploadtype}
        />
      ),
    },
    {
      title: "Map keys",
      content: (
        <UploadSettings
          tableColumns={tableColumns}
          currentSettings={currentSettings}
          setCurrentSettings={setCurrentSettings}
          selectedSettings={selectedSettings}
          setSelectedSettings={setSelectedSettings}
          headers={headers}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setIsProject={setIsProject}
          availableWidgets={availableWidgets}
          selectedType={selectedType}
          collectionOptions={collectionOptions}
          setSelectedCollectionOptions={setSelectedCollectionOptions}
          selectedCollectionOptions={selectedCollectionOptions}
          selectedWidgetName={selectedWidgetName}
          setSelectedWidgetName={setSelectedWidgetName}
          targetFieldOptions={targetFieldOptions}
          setTargetFieldOptions={setTargetFieldOptions}
          documentUploadtype={documentUploadtype}
          setOnlyQualities={setOnlyQualities}
        />
      ),
    },
    {
      title: "Apply data",
      content: (
        <ApplyData
          dataSource={dataSource}
          selectedId={selectedId}
          loading={loading}
          tableColumns={tableColumns}
          loadData={loadData}
          selectedType={selectedType}
          dataLoading={dataLoading}
          setDataLoading={setDataLoading}
          updateStatus={updateStatus}
          uploadWidgetData={uploadWidgetData}
          onExportExternalDocument={onExportExternalDocument}
          onlyUploadQualities={onlyUploadQualities}
        />
      ),
    },
  ];

  const structureWithDataSteps = [
    {
      title: "Select File",
      content: (
        <SelectFile
          onChange={onChange}
          options={options}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          documentUploadtype={documentUploadtype}
        />
      ),
    },
    {
      title: "Select Data Source",
      content: (
        <SelectType
          dataSources={dataSources}
          selectedDataSource={selectedDataSource}
          setSelectedDataSource={setSelectedDataSource}
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          documentUploadtype={documentUploadtype}
        />
      ),
    },
    {
      title: "Upload Structures",
      content: (
        <UploadStructures
          tableColumns={tableColumns}
          dataSource={_.map(dataSource, (item, index) => ({
            ...item,
            id: index,
          }))}
          sdfFile={sdfFile}
          selectedOption={selectedOption}
          sourceName={selectedDataSource}
          tableData={tableData}
          setTableData={setTableData}
          setHeaders={setHeaders}
        />
      ),
    },
    {
      title: "Map keys",
      content: (
        <UploadSettings
          tableColumns={tableColumns}
          currentSettings={currentSettings}
          setCurrentSettings={setCurrentSettings}
          selectedSettings={selectedSettings}
          setSelectedSettings={setSelectedSettings}
          headers={headers}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          setIsProject={setIsProject}
          availableWidgets={availableWidgets}
          selectedType={selectedType}
          collectionOptions={collectionOptions}
          setSelectedCollectionOptions={setSelectedCollectionOptions}
          selectedCollectionOptions={selectedCollectionOptions}
          selectedWidgetName={selectedWidgetName}
          setSelectedWidgetName={setSelectedWidgetName}
          targetFieldOptions={targetFieldOptions}
          setTargetFieldOptions={setTargetFieldOptions}
          documentUploadtype={documentUploadtype}
          setOnlyQualities={setOnlyQualities}
        />
      ),
    },
    {
      title: "Apply data",
      content: (
        <ApplyData
          dataSource={
            documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
              ? normalizeTableData(tableData)
              : dataSource
          }
          selectedId={
            documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
              ? "targetId"
              : selectedId
          }
          loading={loading}
          tableColumns={
            documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA
              ? [
                  {
                    key: "status",
                    title: "Status",
                    width: "100px",
                    render: (item) => {
                      const currentStatus = item?.status;
                      const errorReason = item?.reason;

                      if (currentStatus === "loading") {
                        return (
                          <p className="gx-text-info">
                            <LoadingOutlined className="gx-mr-1" />
                            Uploading
                          </p>
                        );
                      }
                      if (currentStatus === "success") {
                        return (
                          <p className="gx-text-success">
                            <CheckCircleTwoTone
                              className="gx-mr-1"
                              twoToneColor="#52C41A"
                            />
                            Uploaded!
                          </p>
                        );
                      }
                      if (currentStatus === "processing") {
                        return (
                          <p className="gx-text-info">
                            <Spin className="gx-mr-2 gx-mt-2" size="small" />
                            Processing
                          </p>
                        );
                      }
                      if (currentStatus === "error") {
                        return (
                          <Tooltip title={<span>{errorReason}</span>}>
                            <p className="gx-text-danger">
                              <WarningTwoTone
                                className="gx-mr-1"
                                twoToneColor="#F44336"
                              />
                              Error occurred
                            </p>
                          </Tooltip>
                        );
                      }

                      if (currentStatus === "exists") {
                        return (
                          <p className="gx-text-warning">
                            File already exists.
                          </p>
                        );
                      }

                      if (currentStatus === "not_found") {
                        return (
                          <p className="gx-text-warning">Target not found.</p>
                        );
                      }
                      return <p className="gx-text-warning"></p>;
                    },
                  },
                  {
                    title: "Id",
                    key: "id",
                    dataIndex: "targetId",
                    width: "100px",
                    render: (p, { targetId }) => {
                      return (
                        <Tag color="#2db7f5" key={targetId}>
                          <Link
                            to={`/${selectedDataSource}/compound/${targetId}`}
                            target="_blank"
                            replace
                          >
                            {targetId}
                          </Link>
                        </Tag>
                      );
                    },
                  },
                  ...tableColumns,
                ]
              : tableColumns
          }
          loadData={loadData}
          selectedType={selectedType}
          dataLoading={dataLoading}
          setDataLoading={setDataLoading}
          updateStatus={updateStatus}
          uploadWidgetData={uploadWidgetData}
          onExportExternalDocument={onExportExternalDocument}
        />
      ),
    },
  ];

  let steps = [];
  switch (documentUploadtype) {
    case DOCUMENT_UPLOAD_OPTIONS.STRUCTURES:
      steps = [...commonSteps, ...structuresOnlySteps];
      break;
    case DOCUMENT_UPLOAD_OPTIONS.DATA:
      steps = [...commonSteps, ...dataOnlySteps];
      break;
    case DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA:
      steps = [...commonSteps, ...structureWithDataSteps];
      break;
    default:
      steps = commonSteps;
  }

  const pageName = "upload";

  const trainingSettings =
    _.find(collectionOptions || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  const onUpdateDocumentations = () => {
    searchDocumentation(defaultSourceName?.option, pageName, setDocumentations);
  };

  let sourceName = defaultSourceName?.option;

  return (
    <div>
      <div
        className="gx-profile-banner sticky-header-component"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
            paddingTop: "15px",
          }}
        >
          <h3>Upload Page</h3>

          {/* Documentation Popup */}
          <div>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <DocumentationPopover
                documentations={documentations}
                sourceName={TRAINING_DOCUMENT_SOURCE}
                setOpenDocumentationSettings={setOpenDocumentationSettings}
                hasPermission={isUserAllowedToManageDocumentation}
              />
            ) : null}
          </div>
        </div>

        {selectedDataSource ? (
          <h5 className="gx-mr-5 gx-mt-3 gx-text-cyan">
            Data Source: {selectedDataSource}
          </h5>
        ) : null}
      </div>
      <div
        className="sticky-header-content"
        style={{
          marginTop: "80px",
        }}
      >
        <>
          <Steps
            current={currentStep}
            onChange={(current) => setCurrentStep(current)}
          >
            {steps.map((item) => (
              <Steps.Step
                key={item.title}
                title={item.title}
                disabled={_.isEmpty(dataSource)}
              />
            ))}
          </Steps>

          <div className="steps-action gx-mt-2 gx-d-flex">
            {currentStep < steps.length - 1 && (
              <Button size="small" type="primary" onClick={() => next()}>
                Next
              </Button>
            )}
            {currentStep > 0 && (
              <Button
                size="small"
                style={{ margin: "0 8px" }}
                onClick={() => prev()}
              >
                Previous
              </Button>
            )}
          </div>
          <div className="steps-content gx-mt-3">
            {steps[currentStep].content}
          </div>
        </>
      </div>

      {/* Manage Documentation Drawer */}
      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>
    </div>
  );
};

export default UploadPage;
