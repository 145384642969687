import React, { useState } from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";

import "./style.css";
import _ from "lodash";
import { Popconfirm, Tooltip } from "antd";
import { DeleteTwoTone, InfoCircleTwoTone } from "@ant-design/icons";
import moment from "moment";
import { convertMolecularFormulaToHTMLSubscript } from "../../../../util/Widget";
import { copyToClipboard } from "../../../../util/url";

const RowString = ({
  field,
  value,
  residualData,
  info = "",
  isDate = false,
  hideInfo = false,
  deleteWidgetParameter = () => {},
  isAccessible = false,
  currentRound = 4,
  hasTooltip = false,
  isCopiable = false,
  addMode,
}) => {
  const targetValue = _.isNumber(value) ? String(value) : value;
  const [copied, setCopied] = useState(false);

  if (!isAccessible) {
    return null;
  }

  const isMolecularWeight = field === "Molecular Formula";

  return (
    <>
      {_.isEmpty(targetValue) ? null : (
        <div className="card-item" key={uuidv4()}>
          <div
            className={`card-item-field ${
              residualData ? "residual-field" : ""
            }`}
          >
            {field}
            {info.length && hasTooltip && !hideInfo ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer gx-mb-1" />
              </Tooltip>
            ) : null}
            {isCopiable && (
              <>
                {copied ? (
                  <i
                    className="icon icon-check success"
                    style={{ marginTop: "3px" }}
                  />
                ) : (
                  <i
                    className="icon icon-copy"
                    style={{ marginTop: "3px" }}
                    onClick={() =>
                      copyToClipboard(targetValue, copied, setCopied)
                    }
                  />
                )}
              </>
            )}
          </div>
          <div
            className={`card-item-value ${
              residualData ? "residual-value" : ""
            }`}
          >
            <div>
              <span>
                {isDate ? (
                  moment(value).format("YYYY-MM-DD HH:mm:ss")
                ) : (
                  <>
                    {isMolecularWeight ? (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: convertMolecularFormulaToHTMLSubscript(value),
                        }}
                      />
                    ) : (
                      <span>
                        {_.isNumber(value)
                          ? value.toFixed(currentRound)
                          : value}
                      </span>
                    )}
                  </>
                )}
              </span>

              {residualData && addMode && (
                <Popconfirm
                  title="Are you sure to delete?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => deleteWidgetParameter(field)}
                >
                  <DeleteTwoTone twoToneColor="#f44336" className="gx-ml-2" />
                </Popconfirm>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

RowString.defaultProps = {
  field: "",
  value: "",
  residualData: false,
  info: "",
  isDate: false,
  addMode: false,
};

RowString.propTypes = {
  field: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
  residualData: PT.bool,
  info: PT.string,
  isDate: PT.bool,
  addMode: PT.bool,
};

export default RowString;
