import axios from "axios";
import _ from "lodash";
import { config } from "../../constants/Config";

/**
 * Get System Widget service call
 * @param {String} widgetName widget name
 * @param {Function} setFn callback function to grab api results
 * @param {Boolean} includeOptions optional parameter for include options
 */
export const getSystemWidget = async (
  widgetName,
  setFn,
  includeOptions = true,
  loadingFn = () => {}
) => {
  loadingFn(true);
  return axios
    .get(`${config.SRD_API_URL}/metadata/system/${widgetName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      if (result?.data) {
        includeOptions
          ? setFn(result.data?.parameters?.options || [])
          : setFn(result.data?.parameters || []);
      }
    })
    .catch(() => {
      setFn([]);
    })
    .finally(() => {
      loadingFn(false);
    });
};

/**
 * Get System Widget service call
 * @param {String} widgetName widget name
 * @param {Function} setFn callback function to grab api results
 * @param {Boolean} includeOptions optional parameter for include options
 */
export const getUserWidget = async (
  widgetName,
  userId,
  setFn,
  includeOptions = true,
  setErrFn = () => {}
) => {
  return axios
    .get(`${config.SRD_API_URL}/metadata/user/${userId}/${widgetName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      if (result?.data) {
        includeOptions
          ? setFn(result.data?.parameters?.options || [])
          : setFn(result.data?.parameters || []);
      }
    })
    .catch(() => {
      setErrFn();
    });
};

/**
 * Save System Widget service call
 * @param {String} widgetName widget name
 * @param {Function} loadingFn loading function to determine
 * @param {Object} data data to save
 */
export const saveSystemWidget = async (
  widgetName,
  setFn = () => {},
  loadingFn = () => {},
  data
) => {
  loadingFn(true);
  return axios
    .post(`${config.SRD_API_URL}/metadata/system/${widgetName}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      setFn(result?.data?.parameters?.options);
    })
    .finally(() => {
      loadingFn(false);
    });
};

/**
 * Save System Widget service call
 * @param {String} widgetName widget name
 * @param {Function} loadingFn loading function to determine
 * @param {Object} data data to save
 */
export const saveUserWidget = async (
  widgetName,
  userId,
  setFn,
  loadingFn,
  data,
  options = false,
  notification = {}
) => {
  loadingFn(true);
  return axios
    .post(`${config.SRD_API_URL}/metadata/user/${userId}/${widgetName}`, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      if (options) {
        setFn(result?.data?.parameters?.options);
      } else {
        setFn(result?.data?.parameters);
      }
      notification?.success("Widget saved successfully.");
    })
    .finally(() => {
      loadingFn(false);
    });
};

/**
 * Send Get Request from system widget's defined url
 * @param {String} id current widget id
 * @param {String} baseUrl base url of api
 * @param {Object} params defined query parameters
 * @param {Object} header defined header parameters
 * @param {Function} setFn callback function to grab api results
 * @param {Function} controller cancel token controller
 */
export const getFromGenericUrl = async (
  id,
  baseUrl,
  params,
  headers,
  setFn,
  controller,
  sourceName,
  pageType,
  setLoading = () => {}
) => {
  const queryParams = new URLSearchParams({
    sourceName,
    ...params,
  });
  setLoading(true);

  const url = config.SCAS_API_URL;

  return axios
    .get(`${url}${baseUrl}/${id}?${queryParams}`, {
      signal: controller.signal,
      headers: headers,
    })
    .then((result) => {
      if (_.isString(result)) {
        setFn(result);
      } else if (result.data) {
        setFn(result.data);
      }
    })
    .finally(() => {
      setLoading(false);
    });
};
