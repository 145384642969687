import { Form, Select } from "antd";
import React from "react";
import PT from "prop-types";

const ProcessRegistrationForm = ({
  setSelectedProcessRegistrationType,
  processRegistrationTypes,
  setCurrentInputType,
  next,
  selectedProcessRegistrationType,
  setRegisteredId,
}) => {
  return (
    <>
      <Form.Item
        label="Process Registration Type"
        className="gx-ml-2 gx-mr-2 registerProcess__label"
      >
        <Select
          placeholder="Select Type"
          dropdownMatchSelectWidth={false}
          onChange={(e) => {
            setSelectedProcessRegistrationType(e);
            const currentItem = processRegistrationTypes.find(
              (item) => item?.type === e
            );
            if (currentItem) {
              setCurrentInputType(currentItem?.input);
            }
            next();

            setRegisteredId(null);
          }}
          defaultValue={
            selectedProcessRegistrationType || processRegistrationTypes[0]?.type
          }
        >
          {processRegistrationTypes.map((item, index) => (
            <Select.Option
              key={`process-registration-type-${index}`}
              value={item.type}
            >
              {item.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
      {/* <Button size="small" type="primary" onClick={() => next()}>
        Select
      </Button> */}
    </>
  );
};

ProcessRegistrationForm.defaultProps = {
  selectedProcessRegistrationType: null,
};

ProcessRegistrationForm.propTypes = {
  setSelectedProcessRegistrationType: PT.func.isRequired,
  processRegistrationTypes: PT.arrayOf(PT.shape()),
  setCurrentInputType: PT.func.isRequired,
  next: PT.func.isRequired,
  selectedProcessRegistrationType: PT.string,
};

export default ProcessRegistrationForm;
