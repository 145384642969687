import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";

import _ from "lodash";
import { Tooltip } from "antd";
import { InfoCircleTwoTone } from "@ant-design/icons";
import { Link } from "react-router-dom";

const RowLink = ({
  shortId,
  field,
  displayValue,
  url = "",
  info = "",
  hideInfo = false,
}) => {
  const targetUrl = url.replace(/:shortId/g, shortId);

  return (
    <>
      {_.isEmpty(displayValue) ? null : (
        <div className="card-item" key={uuidv4()}>
          <div className="card-item-field">
            {field}
            {info.length && !hideInfo ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
          </div>
          <div className="card-item-value">
            <Link to={{ pathname: targetUrl }} target="_blank">
              {displayValue}
            </Link>
          </div>
        </div>
      )}
    </>
  );
};

RowLink.defaultProps = {
  field: "",
  value: "",
  residualData: false,
  info: "",
};

RowLink.propTypes = {
  field: PT.string,
  value: PT.oneOfType([PT.string, PT.number]),
  residualData: PT.bool,
  info: PT.string,
};

export default RowLink;
