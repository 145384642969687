import {
  FETCH_COLLECTION_REQUESTED,
  FETCH_COLLECTION_RESET,
  FETCH_DOCUMENT_DETAILS_REQUESTED,
  FETCH_DOCUMENT_DETAILS_RESET,
  GET_DATA_SOURCES_REQUESTED,
  SEARCH_COMPOUND_REQUESTED,
  SEARCH_DOCUMENTS_REQUESTED,
} from "../../constants/ActionTypes";

export const getDataSources = (useScasEndpoint = false) => {
  return {
    type: GET_DATA_SOURCES_REQUESTED,
    payload: { useScasEndpoint },
  };
};

export const searchForDocuments = (body, type) => {
  return {
    type: SEARCH_DOCUMENTS_REQUESTED,
    payload: {
      body,
      type,
    },
  };
};

export const searchForCompoundData = (body) => {
  return {
    type: SEARCH_COMPOUND_REQUESTED,
    payload: {
      body,
    },
  };
};

export const fetchDocumentDetail = (documentId) => {
  return {
    type: FETCH_DOCUMENT_DETAILS_REQUESTED,
    payload: {
      documentId,
    },
  };
};

export const resetDocumentDetailSearch = ({ total }) => {
  return {
    type: FETCH_DOCUMENT_DETAILS_RESET,
    payload: {
      total,
    },
  };
};

export const resetCollectionSearch = ({ total }) => {
  return {
    type: FETCH_COLLECTION_RESET,
    payload: {
      total,
    },
  };
};

export const fetchCollectionDetail = (documentId) => {
  return {
    type: FETCH_COLLECTION_REQUESTED,
    payload: {
      documentId,
    },
  };
};
