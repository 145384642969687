/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Image } from "antd";
import CircularProgress from "components/CircularProgress";
import PT from "prop-types";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import _ from "lodash";
import { getImageFromCompoundId } from "../../../appRedux/services/Search";
import UnknownCompound from "../../../assets/vendors/UnknownCompound.svg";
import PopoverImage from "../../DetailPage/Widget/RowArray/PopoverImage";
import "./style.css";

const ExactMatchesCard = ({
  title,
  matchedComponents,
  isUndefinedStereoAtoms,
  onRegisterNewQuality,
  loading,
  renderImage,
  backgroundColor,
  renderRegisterButton,
  info,
  sourceName,
  isomers = [],
  registerNewType,
  isIsomerRegistered,
  availableDataSources = [],
  isKnown,
  userEmails,
  currentUserEmail,
  registerDuplicate = () => {},
}) => {
  // eslint-disable-next-line no-unused-vars
  const [imagePreview, setImagePreview] = useState("");
  const hasOpticalIsomers = (isomers || []).length > 0;
  const components = hasOpticalIsomers ? isomers : matchedComponents;

  const hasDuplicateRegisterRole =
    _.includes(userEmails || [], currentUserEmail) && isKnown;

  useEffect(() => {
    if (renderImage) {
      matchedComponents.forEach((exactMatch) => {
        getImageFromCompoundId(exactMatch, sourceName).then((apiResult) => {
          const { data } = apiResult;
          setImagePreview(data);
        });
      });
    }
  }, []);

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-mr-3 gx-card-widget
      gx-width-400`}
      style={{ backgroundColor }}
    >
      <div className="ant-card-head widget-header">
        <div className="ant-card-head-wrapper gx-d-flex justify-content-between">
          <div className="ant-card-head-title widget-header-title">
            <span>{title}</span>
          </div>
        </div>
      </div>

      <div className="ant-card-body" style={{ backgroundColor: "white" }}>
        {components.map((match, index) => (
          <>
            <div className="structure-result gx-d-flex">
              {renderImage && (
                <>
                  {isKnown ? (
                    // <ImageCarousel
                    //   structureImage={[imagePreview]}
                    //   width={75}
                    //   height={75}
                    //   noBorder
                    // />
                    <div>
                      <PopoverImage
                        rmoId={match}
                        sourceName={sourceName}
                        popoverHeight={75}
                        popoverWidth={75}
                      />
                    </div>
                  ) : (
                    <div className="fullImageWrapper">
                      <Image width={150} height={150} src={UnknownCompound} />
                      <span
                        className="fullImageOverlay"
                        style={{
                          textAlign: "center",
                          justifyContent: "center",
                          top: "30%",
                        }}
                      >
                        Undefined Structure
                      </span>
                    </div>
                  )}
                </>
              )}
              <div className={`${renderImage ? "structure-actions" : ""}`}>
                <Link
                  replace
                  target="_blank"
                  to={`/${sourceName}/compound/${match}`}
                  key={`match-${index}`}
                >
                  <span>Compound Id: {match}</span>
                </Link>
                {renderRegisterButton && (
                  <>
                    {loading ? (
                      <CircularProgress />
                    ) : (
                      <Button
                        onClick={() => onRegisterNewQuality(match)}
                        className="register-btn"
                        size="small"
                        type="primary"
                      >
                        Register
                      </Button>
                    )}
                  </>
                )}
              </div>
            </div>
            <Divider />
          </>
        ))}
        {hasOpticalIsomers &&
        !isIsomerRegistered &&
        !_.isEmpty(availableDataSources) ? (
          <>
            {loading ? (
              <CircularProgress />
            ) : (
              <div>
                <Button
                  onClick={() => registerNewType(sourceName, "RACEMATE")}
                  className="register-btn"
                  size="small"
                  type="primary"
                >
                  Register as Racemate
                </Button>
                <Button
                  onClick={() => registerNewType(sourceName, "ISOMER")}
                  className="register-btn"
                  size="small"
                  type="primary"
                >
                  Register new Isomer
                </Button>
              </div>
            )}
          </>
        ) : null}
      </div>

      {hasDuplicateRegisterRole ? (
        <div className="gx-ml-2 gx-mt-2">
          <Button type="primary" size="small" onClick={registerDuplicate}>
            Register again
          </Button>
        </div>
      ) : null}
    </div>
  );
};

ExactMatchesCard.defaultProps = {
  isUndefinedStereoAtoms: false,
  loading: false,
  title: "",
  renderImage: false,
  backgroundColor: "FFF7E6",
  renderRegisterButton: false,
  info: false,
  sourceName: "",
};

ExactMatchesCard.propTypes = {
  matchedComponents: PT.arrayOf(PT.string).isRequired,
  isUndefinedStereoAtoms: PT.bool,
  onRegisterNewQuality: PT.func.isRequired,
  loading: PT.bool,
  title: PT.string,
  renderImage: PT.bool,
  backgroundColor: PT.string,
  renderRegisterButton: PT.bool,
  info: PT.bool,
  sourceName: PT.string,
};

export default ExactMatchesCard;
