import {
  GET_PROCESS_WIDGETS_REQUESTED,
  GET_PROCESS_WIDGETS_SUCCESS,
  GET_SYSTEM_WIDGETS_REQUESTED,
  GET_SYSTEM_WIDGETS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  namesSynonymsDefaults: [],
};

const SystemMetadataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_SYSTEM_WIDGETS_REQUESTED:
    case GET_PROCESS_WIDGETS_REQUESTED: {
      return {
        ...state,
      };
    }
    case GET_SYSTEM_WIDGETS_SUCCESS:
    case GET_PROCESS_WIDGETS_SUCCESS: {
      return {
        ...state,
        [action.payload.name]: action.payload.parameters,
      };
    }
    default:
      return state;
  }
};

export default SystemMetadataReducer;
