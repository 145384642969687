import axios from "axios";
import _ from "lodash";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  AUTO_ASSIGN_FAILED,
  AUTO_ASSIGN_REQUESTED,
  AUTO_ASSIGN_SUCCESS,
  CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS,
  CHECK_UNIQUENESS_FAILED,
  CHECK_UNIQUENESS_REQUESTED,
  CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS,
  CLEAR_ERROR_MESSAGE_REQUESTED,
  CLEAR_ERROR_MESSAGE_SUCCESS,
  CLEAR_REGISTER_DATA_REQUESTED,
  CLEAR_REGISTER_DATA_SUCCESS,
  GENERATE_IMAGE_FAILED,
  GENERATE_IMAGE_REQUESTED,
  GENERATE_IMAGE_SUCCESS,
  REGISTER_FAILED,
  REGISTER_REQUESTED,
  REGISTER_SUCCESS,
  UPDATE_FAILED,
  UPDATE_LATEST_COMPOUND_FAILED,
  UPDATE_LATEST_COMPOUND_REQUESTED,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
} from "../../constants/ActionTypes";
import { RECENT_REGISTERED_COMPOUND, config } from "../../constants/Config";
import { getImageUrlParams } from "../../util/url";
import { saveSystemWidget } from "../services/SystemWidget";

const autoAssignRequest = async (result) =>
  await axios.post(`${config.SRD_API_URL}/autoAssign`, result, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });

const checkUniquenessRequest = async (result, dataSource, isKnown) => {
  const body = isKnown ? result : "";

  const conceptValue = isKnown
    ? {}
    : {
        conceptValue: result,
      };

  const queryParams = new URLSearchParams({
    ...conceptValue,
    sourceName: dataSource || config.SOURCE_NAME,
  });

  return axios.post(`${config.SRD_API_URL}/uniqueness?${queryParams}`, body, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });
};

const registerRequest = async (
  result,
  dataSource,
  isIsomer = false,
  isKnown,
  typeName,
  molecularWeight,
  molecularFormula,
  userName,
  compoundId,
  allowDuplicate = false,
) => {
  const body = isKnown ? result : "";
  const isomer = isIsomer ? { newRegistrationType: typeName } : {};
  const additionalFields = isKnown
    ? {}
    : {
        conceptValue: result,
      };

  if (molecularFormula) {
    additionalFields["molecularFormula"] = molecularFormula;
  }

  if (molecularWeight) {
    additionalFields["molecularWeight"] = molecularWeight;
  }

  if (compoundId) {
    additionalFields["compoundId"] = compoundId;
  }

  const queryParams = new URLSearchParams({
    ...isomer,
    ...additionalFields,
    sourceName: dataSource || config.SOURCE_NAME,
  });

  return axios.post(
    `${config.SRD_API_URL}/register?${queryParams}&SRD_USER=${userName}&allowDuplicate=${allowDuplicate}`,
    body,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
      },
    },
  );
};

const updateRequest = async (body, userName) =>
  axios.put(
    `${config.SRD_API_URL}/compoundConcept?SRD_USER=${userName}`,
    body,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

const generateImageRequest = async (
  result,
  dataSource,
  backgroundColor = "transbg",
) =>
  axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/image/svg/${result}?sourceName=${dataSource}&${getImageUrlParams(
      backgroundColor,
    )}`,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "*/*",
      },
    },
  );

function* fetchAutoAssign(action) {
  try {
    const result = yield call(autoAssignRequest, action.payload);
    yield put({ type: AUTO_ASSIGN_SUCCESS, payload: result.data });
  } catch (e) {
    yield put({ type: AUTO_ASSIGN_FAILED, payload: e.response.data.message });
  }
}

function* fetchClearErrorMessage() {
  yield put({ type: CLEAR_ERROR_MESSAGE_SUCCESS });
}

function* fetchCheckUniqueness(action) {
  try {
    const result = yield call(
      checkUniquenessRequest,
      action.payload.result,
      action.payload.dataSource,
      action.payload.isKnown,
    );
    if (result.data?.unique) {
      yield put({
        type: CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS,
        payload: {
          reason: result.data.reason,
          enantiomer: (result.data?.enantiomer || []).sort(),
          diastereomer: (result.data?.diastereomer || []).sort(),
          exactMatch: (result.data?.exactMatch || []).sort(),
          noStructure: (result.data?.noStructure || []).sort(),
          opticalIsomer: (result.data?.opticalIsomer || []).sort(),
          coordination: (result.data?.coordination || []).sort(),
          geometricIsomer: (result.data?.geometricIsomer || []).sort(),
          racemate: (result.data?.racemate || []).sort(),
          isotope: (result.data?.isotope || []).sort(),
          tautomer: (result.data?.tautomer || []).sort(),
        },
      });
    } else {
      yield put({
        type: CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS,
        payload: {
          exactMatch: (result.data?.exactMatch || []).sort(),
          enantiomer: (result.data?.enantiomer || []).sort(),
          diastereomer: (result.data?.diastereomer || []).sort(),
          noStructure: (result.data?.noStructure || []).sort(),
          opticalIsomer: (result.data?.opticalIsomer || []).sort(),
          coordination: (result.data?.coordination || []).sort(),
          geometricIsomer: (result.data?.geometricIsomer || []).sort(),
          racemate: (result.data?.racemate || []).sort(),
          isotope: (result.data?.isotope || []).sort(),
          tautomer: (result.data?.tautomer || []).sort(),
        },
      });
    }
  } catch (e) {
    yield put({
      type: CHECK_UNIQUENESS_FAILED,
      payload: e.response.data.message,
    });
  }
}

function* fetchRegister(action) {
  try {
    const result = yield call(
      registerRequest,
      action.payload.result,
      action.payload.dataSource,
      action.payload.isIsomer,
      action.payload.isKnown,
      action.payload.typeName,
      action.payload.molecularWeight,
      action.payload.molecularFormula,
      action.payload.userName,
      action.payload.compoundId,
      action.payload.allowDuplicate,
    );
    if (result.data.length > 0) {
      if (result.data[0]?.error === "source not found") {
        yield put({
          type: REGISTER_FAILED,
          payload: result.data[0]?.error,
        });
      } else {
        yield put({
          type: REGISTER_SUCCESS,
          payload: result.data,
        });
      }
    }
  } catch (e) {
    if (e.response.status === 500) {
      yield put({
        type: REGISTER_FAILED,
        payload: e.response?.data?.message || "Error has occurred.",
      });
    } else if (e.response.data.length > 0) {
      yield put({
        type: REGISTER_SUCCESS,
        payload: e.response.data,
      });
    } else {
      yield put({
        type: REGISTER_FAILED,
        payload: e.response?.data?.message || "Error has occurred.",
      });
    }
  }
}

function* fetchUpdate(action) {
  try {
    const result = yield call(
      updateRequest,
      action.payload?.body,
      action.payload?.userName,
    );

    if (_.isArray(result.data) && result.data.length) {
      yield put({
        type: UPDATE_SUCCESS,
        payload: result.data,
      });
    }
  } catch (e) {
    if (e?.response?.status === 500) {
      yield put({
        type: UPDATE_FAILED,
        payload: e.response?.data?.message || "Error has occurred.",
      });
    } else {
      yield put({
        type: UPDATE_FAILED,
        payload: e.response?.data?.message || "Error has occurred.",
      });
    }
  }
}

function* fetchGenerateImage(action) {
  try {
    const result = yield call(
      generateImageRequest,
      action.payload?.result,
      action.payload?.dataSource,
      "transbg",
    );
    if (result.data) {
      yield put({
        type: GENERATE_IMAGE_SUCCESS,
        payload: result.data,
      });
    } else {
      yield put({
        type: GENERATE_IMAGE_FAILED,
        payload: "",
      });
    }
  } catch (e) {
    yield put({
      type: GENERATE_IMAGE_FAILED,
      payload: e.response.message,
    });
  }
}

function* clearData(action) {
  yield put({
    type: CLEAR_REGISTER_DATA_SUCCESS,
  });
}

function* updateLatest(action) {
  let targetData = action.payload?.currentList;
  targetData.push(action.payload?.data);

  if ((action.payload?.currentList || []).length > 5) {
    targetData.shift();
  }

  try {
    const result = yield call(
      saveSystemWidget,
      RECENT_REGISTERED_COMPOUND,
      () => {},
      () => {},
      { options: targetData },
    );
    if (result.data) {
      yield put({
        type: UPDATE_LATEST_COMPOUND_REQUESTED,
        payload: result.data,
      });
    }
  } catch (e) {
    yield put({
      type: UPDATE_LATEST_COMPOUND_FAILED,
    });
  }
}

export function* autoAssign() {
  yield takeLatest(AUTO_ASSIGN_REQUESTED, fetchAutoAssign);
}

export function* clearErrorMessage() {
  yield takeLatest(CLEAR_ERROR_MESSAGE_REQUESTED, fetchClearErrorMessage);
}

export function* checkUniqueness() {
  yield takeLatest(CHECK_UNIQUENESS_REQUESTED, fetchCheckUniqueness);
}

export function* register() {
  yield takeLatest(REGISTER_REQUESTED, fetchRegister);
}

export function* update() {
  yield takeLatest(UPDATE_REQUESTED, fetchUpdate);
}

export function* generateImage() {
  yield takeLatest(GENERATE_IMAGE_REQUESTED, fetchGenerateImage);
}

export function* clearRegisterData() {
  yield takeLatest(CLEAR_REGISTER_DATA_REQUESTED, clearData);
}

export function* updateLatestCompoundRegistration() {
  yield takeLatest(UPDATE_LATEST_COMPOUND_REQUESTED, updateLatest);
}

export default function* rootSaga() {
  yield all([
    fork(autoAssign),
    fork(clearErrorMessage),
    fork(checkUniqueness),
    fork(register),
    fork(generateImage),
    fork(clearRegisterData),
    fork(updateLatestCompoundRegistration),
    fork(update),
  ]);
}
