import { AppstoreAddOutlined } from "@ant-design/icons";
import { Menu } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";

function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}

const Sidebar = ({
  availableWidgets,
  formItems,
  handleAddFormItem,
  handleRemoveFormItemByKey,
}) => {
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    if ((availableWidgets || []).length) {
      let items = [];

      _.forEach(availableWidgets, (widget, index) => {
        let children = [];
        const widgetTitle = widget?.widget?.title || widget?.field;

        const rows = _.get(widget, "widget.rows") || [];

        _.forEach(rows, (row, rowIndex) => {
          if (row?.isSearchable) {
            children.push(
              getItem(row?.field, `${widgetTitle}-${row?.name}-${row?.field}`)
            );
          }
        });

        if (!_.isEmpty(children)) {
          items.push(
            getItem(widgetTitle, index, <AppstoreAddOutlined />, children)
          );
        }
      });

      setMenuItems(items);
    }
  }, [availableWidgets, menuItems.length]);

  return (
    <Menu
      style={{
        width: "fit-content",
      }}
      className="gx-mt-3"
      items={menuItems}
      selectedKeys={formItems}
      mode="inline"
      selectable
      multiple
      onDeselect={({ key }) => {
        handleRemoveFormItemByKey(key);
      }}
      onSelect={({ key }) => {
        handleAddFormItem(key);
      }}
    />
  );
};

export default Sidebar;
