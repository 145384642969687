import React, { useEffect, useReducer, useState } from "react";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/Config";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  findMappedDatabaseFieldName,
  populateImageMapping,
} from "../../../ExplorePage/helper";
import { getCompoundConceptApi } from "../../../../appRedux/services/Lookup";
import { Button, Popover, Spin, Table, Tag } from "antd";
import pluralize from "pluralize";
import { renderImage } from "../../../SearchPage/helper";
import ImageCarousel from "../../../Registration/ImageCarousel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getCompoundTypeFromId } from "../../../../util/url";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { getParent } from "../../../../util/Widget";

const QualitySelection = ({
  id,
  availableWidgets,
  sourceName,
  onSelectProcess,
}) => {
  const [mapImages, setMapImages] = useState({});
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [imageRendered, setImageRendered] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const [dataSource, setDataSource] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if ((dataSource || []).length) {
      setImageRendered(true);

      populateImageMapping({
        currentPage: page,
        pageSize: perPage,
        searchResults: dataSource,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
        isEntityIds: page === "documents",
      });
    }
  }, [page, perPage, mapImages, setMapImages, dataSource]);

  useEffect(() => {
    if (sourceName && !(dataSource || []).length) {
      setLoading(true);

      getCompoundConceptApi({ id: getParent(id), sourceName })
        .then((cc) => {
          setDataSource(cc?.data?.compoundInstances || []);
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, id, sourceName, page, perPage]);

  const total = (dataSource || []).length;
  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState(null);

  return (
    <div>
      <div className="gx-mb-2" style={{ textAlign: "right" }}>
        <span className="gx-text-success">
          Total: {total} {pluralize("result", dataSource.length)}.
        </span>
        {!_.isEmpty(filterValue) ? (
          <span className="gx-ml-2 gx-text-secondary">
            Filtered by "{filterValue}": {filteredResult.length}{" "}
            {pluralize("result", filteredResult.length)}
          </span>
        ) : null}
      </div>
      <Table
        key={1}
        loading={loading}
        className="gx-table-responsive"
        dataSource={dataSource}
        onChange={(page, filters, sorter, { currentDataSource }) => {
          setFilterValue((_.get(filters, "3") || [])[0]);
          setFilteredResult(currentDataSource);
          renderImage({
            currentPage: page.current,
            pageSize: page.pageSize,
            searchResults: currentDataSource,
            mapImages,
            setMapImages,
            forceUpdate,
          });
        }}
        columns={[
          {
            title: "Structure",
            dataIndex: "structure",
            key: "structure",
            width: 150,
            render: (_, { compoundId, processId, name }) => {
              const targetId = compoundId || processId || name;

              return (
                <>
                  {mapImages[targetId] === "not-found" ? (
                    <span
                      className="gx-text-danger"
                      style={{
                        fontSize: "10px",
                      }}
                    >
                      Structure not found
                    </span>
                  ) : mapImages[targetId] ? (
                    <div className="search-page-image-preview">
                      <ImageCarousel
                        structureImage={[mapImages[targetId]]}
                        noBorder
                        setVisible={() => {}}
                      />
                    </div>
                  ) : (
                    <Spin />
                  )}
                </>
              );
            },
          },
          {
            title: "Id",
            width: 150,
            render: (p, { sourceName, compoundId, processId, name }) => {
              const targetId = compoundId || processId || name;

              const isHighlighted = (targetId || "").includes(id || "");

              return (
                <>
                  <Link
                    to={`/${sourceName}/${getCompoundTypeFromId(
                      targetId,
                      "PROCESS"
                    )}/${targetId}`}
                    replace
                    target="_blank"
                  >
                    <span
                      style={{
                        color: isHighlighted ? "#389e0d" : "#1677ff",
                      }}
                    >
                      {targetId}
                    </span>
                  </Link>
                </>
              );
            },
          },
          {
            title: "Source",
            dataIndex: "source",
            width: 100,
            // sorter: (a, b) =>
            //   (a?.sourceName || "").localeCompare(b?.sourceName || ""),
          },
          {
            title: "Action",
            render: (p, cell) => {
              return (
                <Button
                  size="small"
                  type="primary"
                  onClick={() => {
                    onSelectProcess(cell?.name);
                  }}
                >
                  Select
                </Button>
              );
            },
          },
          {
            title: "Data",
            render: (p, cell) => {
              const { parameters = {}, widgetType } = cell;

              // if (_.isEmpty(_.omit(parameters, ["options"]))) {
              //   return (
              //     <span className="gx-text-warning">
              //       You don't have access to this widget.
              //     </span>
              //   );
              // }

              return (
                <div>
                  {_.map(parameters, (item, key) => {
                    const targetParameter = _.find({}, { value: key }) || {};
                    let targetValue = targetParameter?.field || key;

                    try {
                      if (targetValue.includes("|")) {
                        targetValue = targetValue.split("|")[0];
                      }
                    } catch (error) {}

                    let targetItem = item;

                    if (typeof item === "object") {
                      targetItem = item?.id || "";
                    }

                    const regex = new RegExp(
                      `^\\d{4}-\\}d{2-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$`
                    );

                    if (regex.test(targetItem)) {
                      targetItem = moment(targetItem).format(
                        "YYYY-MM-DD HH:mm:ss"
                      );
                    }

                    let isHighlighted = false;

                    try {
                      isHighlighted = _.includes(
                        (targetItem || "").toLowerCase(),
                        (id || "").toLowerCase()
                      );
                    } catch (error) {}

                    const label = findMappedDatabaseFieldName(
                      targetValue,
                      widgetType,
                      availableWidgets
                    );

                    return (
                      <>
                        {item ? (
                          <Popover
                            content={<span>{label || targetValue}</span>}
                          >
                            <Tag
                              key={uuidv4()}
                              style={{ whiteSpace: "break-spaces" }}
                              color={isHighlighted ? "#d9f7be" : "#f0f0f0"}
                            >
                              <span className="gx-text-black">
                                {targetItem}
                              </span>
                            </Tag>
                          </Popover>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              );
            },
          },
        ]}
        pagination={{
          total,
          pageSize: perPage,
          current: page,
          defaultCurrent: 1,
          pageSizeOptions: [5, 10, 15, 20],
          responsive: true,
          showSizeChanger: true,
          onChange: (newPage, newPageSize) => {
            if (newPage !== page) {
              setPage(newPage);
            }

            if (perPage !== newPageSize) {
              setPerPage(newPageSize);
            }
          },
        }}
      />
    </div>
  );
};

export default QualitySelection;
