import React, { useContext, useEffect, useReducer } from "react";
import PT from "prop-types";
import { Button, Checkbox, Form, Input, Popover, Select, Tabs } from "antd";
import { v4 as uuidv4 } from "uuid";

import "./style.css";
import _ from "lodash";
import {
  EXPLORE_PAGE_MAIN_TAB_OPTIONS,
  EXPLORE_PAGE_TAB_OPTIONS,
} from "../../../constants/Config";
import { useDispatch, useSelector } from "react-redux";
import {
  filterByType,
  resetSearch,
  searchParameters,
} from "../../../appRedux/actions";
import { checkTabPermitted, isAdmin } from "../../../util/auth";
import { MainContext } from "../../../containers/App";
import useGetRoles from "../../../hooks/detailPage/useGetRoles";
import pluralize from "pluralize";
import { PAGE_LIMIT, similarityOptions } from "../../SearchPage/helper";
import {
  getSvgFromXML,
  getTotalNumber,
  mapKeys,
  renderCurrentQuery,
  selectOptionsFromTab,
} from "../helper";
import InputTag from "../../InputTag";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import { EditTwoTone } from "@ant-design/icons";

const Header = ({
  availableDataSources,
  currentTab,
  setCurrentTab,
  selectedDataSources,
  selectedDataSourceOptions,
  saveSelectedDataSources,
  setPage,
  setPerPage,
  formRef,
  onSearchQuery,
  widgetIds,
  currentMainTab,
  setCurrentMainTab,
  canvasModal,
  setCanvasModal,
  structureImage,
  matchSearch,
  cutoff,
  searchOps,
  filteredSearchResults,
  listItems,
  listItemsSpecific,
  listItemsStructure,
  showAdvancedSearchForm,
  setShowAdvancedSearchForm,
  formItems,
  setStructureSearchResults,
  structureSearchResults,
  advancedSearchJoinType,
}) => {
  const dispatch = useDispatch();
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const location = useLocation();
  const { searchTerm, setSearchTerm } = useContext(MainContext);

  const { structuresTableData = [] } = useSelector(({ explore }) => explore);

  const roles = useGetRoles();

  const shouldRenderSearchbox =
    isAdmin({
      roles,
      targetUrl: window.location.pathname,
    }) && window.location.pathname === "/search";

  const updateHash = (value) => {
    setCurrentTab(value);

    const { hash } = location;
    const hashValue = (hash || "").slice(1);

    let hashMaintab = hashValue.split("-")[0];

    window.location.hash = `${hashMaintab}-${value}`;
  };

  useEffect(() => {
    const formValue = formRef.current?.getFieldValue("inputValue");
    if (searchTerm && formRef && _.isEmpty(formValue)) {
      formRef.current?.setFieldsValue({
        inputValue: searchTerm,
      });
    }
  }, [formRef, searchTerm, currentTab, currentMainTab]);

  const renderListItemSpecific = (listItem) => {
    let total =
      currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE
        ? (structureSearchResults || []).length
        : (structuresTableData || []).length;

    if (currentTab === EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES) {
      total = (structureSearchResults || []).length;
    }

    if (currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND) {
      total = (structuresTableData || []).length;
    }

    if (currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROJECT) {
      total = 0;
    }

    return (
      <li key={uuidv4()}>
        <span
          className={`gx-link ${
            listItem.value === currentTab ? "gx-link__activeTab" : ""
          } `}
          onClick={() => {
            dispatch(resetSearch());
            setStructureSearchResults([]);

            formRef.current?.setFieldsValue({
              inputValue: "",
            });

            if (currentTab !== listItem.value) {
              setCurrentTab(listItem.value);
              updateHash(listItem.value);

              setPage(1);
              setPerPage(PAGE_LIMIT);

              if (!_.isEmpty(searchTerm)) {
                dispatch(
                  searchParameters({
                    searchTerm,
                    dataSources: selectedDataSources?.option,
                    type: (listItem.value || "").toUpperCase(),
                    includeDocument: listItem.includeDocument,
                  })
                );
              }
            }
          }}
        >
          {listItem.label}
          {listItem.value === currentTab ? (
            <>
              <span>({total})</span>
            </>
          ) : null}
        </span>
      </li>
    );
  };

  const renderListItem = (listItem) => {
    const count =
      listItem?.target === "all"
        ? getTotalNumber(widgetIds)
        : (_.get(widgetIds, listItem?.target) || []).length;

    return (
      <li key={uuidv4()}>
        <span
          className={`gx-link ${
            listItem.value === currentTab ? "gx-link__activeTab" : ""
          } `}
          style={
            count === 0
              ? {
                  pointerEvents: "none",
                }
              : {}
          }
          onClick={() => {
            if (currentTab !== listItem.value) {
              setCurrentTab(listItem.value);
              updateHash(listItem.value);

              setPage(1);
              setPerPage(PAGE_LIMIT);

              dispatch(filterByType({ type: listItem?.target }));
            }
          }}
        >
          {listItem.label} ({count})
        </span>
      </li>
    );
  };

  const options = selectOptionsFromTab(currentMainTab, currentTab);

  const getCurrentSelectedOptionType = () => {
    const current = formRef.current?.getFieldValue("searchOps");

    let type = "";

    switch (current) {
      case "GT":
      case "GTE":
      case "LT":
      case "LTE":
        type = "number";
        break;
      case "IN":
        type = "in";
        break;
      default:
        type = "string";
    }

    return type;
  };

  return (
    <div
      className="gx-profile-banner"
      style={{
        paddingTop: "10px",
      }}
    >
      <div
        className="gx-link gx-profile-setting"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
          flexWrap: "nowrap",
        }}
      >
        <div
          className="rightContent"
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "flex-end",
            alignItems: "flex-end",
          }}
        >
          {(availableDataSources || []).length ? (
            <div className="gx-mr-5 gx-d-flex checkbox-selector gx-mt-2">
              {(availableDataSources || []).length === 1 ? (
                <div className="gx-mr-3 gx-mt-2">
                  <span>Data Source:</span>
                  <span className="gx-ml-2 gx-text-warning">
                    {availableDataSources[0]?.sourceName}
                  </span>
                </div>
              ) : (
                <>
                  {!!selectedDataSourceOptions ? (
                    <Form
                      layout="inline"
                      title="Common"
                      className="explorePage__datasources"
                    >
                      <Form.Item
                        label="Data Sources"
                        name="dataSources"
                        initialValue={selectedDataSourceOptions}
                      >
                        <Checkbox.Group
                          options={(availableDataSources || []).map((item) => ({
                            label: item?.sourceName,
                            value: item?.sourceName,
                          }))}
                          name="dataSources"
                          defaultValue={selectedDataSourceOptions}
                          onChange={(e) => {
                            saveSelectedDataSources(e);
                          }}
                          className="explore-page__checkboxGroup"
                        />
                      </Form.Item>
                    </Form>
                  ) : null}
                </>
              )}
            </div>
          ) : null}
        </div>
        <div
          className="leftContent"
          style={{
            marginTop: "0",
            paddingTop: "0",
          }}
        >
          <div className="gx-profile-banner-top-left">
            {/* <div className="gx-profile-banner-avatar">
              <Avatar className="gx-size-90" alt="explore" src={MoleculeIcon} />
            </div> */}
            <div className="gx-profile-banner-avatar-info">
              <h2
                className="gx-mb-2 gx-mb-sm-3 gx-fs-xxl gx-font-weight-light"
                style={{
                  verticalAlign: "middle",
                  marginTop: "15px",
                }}
              >
                Explore{" "}
                {currentTab === "ALL"
                  ? "All"
                  : pluralize(
                      _.capitalize(
                        (currentTab || currentMainTab || "").replace(/_/g, " ")
                      )
                    )}
              </h2>
            </div>
            {shouldRenderSearchbox ? (
              <>
                {currentMainTab ===
                  EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH ||
                currentMainTab ===
                  EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH ? null : (
                  <Form
                    className="gx-ml-3 gx-mt-4"
                    layout="inline"
                    ref={formRef}
                    onFinish={({ searchOps, inputValue }) => {
                      if (_.isEmpty(inputValue)) {
                        NotificationManager.error(
                          "Please enter a search query"
                        );
                      } else {
                        setSearchTerm(inputValue);
                        if (getCurrentSelectedOptionType() === "number") {
                          onSearchQuery({
                            searchOps,
                            inputValue: Number(inputValue),
                          });
                        } else {
                          onSearchQuery({ searchOps, inputValue });
                        }
                      }
                    }}
                    size="small"
                  >
                    <Form.Item
                      className="searchOps__label"
                      label="Search operators"
                      name="searchOps"
                    >
                      <Select
                        options={options}
                        defaultValue={
                          _.find(options, { value: "CONTAINS" })?.value
                        }
                        dropdownMatchSelectWidth={false}
                        onChange={() => {
                          forceUpdate();
                        }}
                      />
                    </Form.Item>
                    <Form.Item label="" name="inputValue">
                      {getCurrentSelectedOptionType() === "in" ? (
                        <InputTag
                          defaultValue={searchTerm}
                          placeholder="Enter query"
                        />
                      ) : (
                        <Input
                          type={getCurrentSelectedOptionType()}
                          placeholder="Enter query"
                        />
                      )}
                    </Form.Item>

                    <Form.Item>
                      <Button htmlType="submit" size="small">
                        Search
                      </Button>
                    </Form.Item>
                  </Form>
                )}
              </>
            ) : null}
          </div>
        </div>
      </div>

      <div className="gx-profile-container">
        <div className="gx-profile-banner-bottom">
          <Tabs
            className="explorePage__tabs"
            activeKey={currentMainTab}
            size="large"
            onChange={(e) => {
              window.location.hash = e;
              dispatch(resetSearch());
              formRef.current?.setFieldsValue({
                inputValue: "",
              });

              setStructureSearchResults([]);

              setCurrentMainTab(e);

              setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.ALL);
              if (e === "Generic Search") {
                // dispatch(
                //   searchParameters({
                //     searchTerm,
                //     dataSources: selectedDataSources?.option,
                //     type: "ALL",
                //     includeDocument: true,
                //   })
                // );
                setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.ALL);
              } else if (e === "Specific Search") {
                setCurrentTab(EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND);
                // dispatch(
                //   searchParameters({
                //     searchTerm,
                //     dataSources: selectedDataSources?.option,
                //     type: EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND,
                //     includeDocument: false,
                //   })
                // );
              } else {
                setCurrentTab(null);
              }
            }}
          >
            {checkTabPermitted(roles, "GENERIC_SEARCH") ? (
              <Tabs.TabPane
                key={EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH}
                tab={EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH}
                style={{ outline: "none" }}
              >
                <div className="gx-tab-list">
                  <ul className="gx-navbar-nav">
                    {_.map(listItems, (listItem) => {
                      if (checkTabPermitted(roles, listItem?.value)) {
                        return renderListItem(listItem);
                      }
                      return null;
                    })}
                  </ul>
                </div>
              </Tabs.TabPane>
            ) : null}
            {checkTabPermitted(roles, "SPECIFIC_SEARCH") ? (
              <Tabs.TabPane
                key={EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH}
                tab={EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH}
              >
                <div className="gx-tab-list">
                  <ul className="gx-navbar-nav">
                    {_.map(listItemsSpecific, (listItem) => {
                      if (checkTabPermitted(roles, listItem?.value)) {
                        return renderListItemSpecific(listItem);
                      }

                      return null;
                    })}
                  </ul>
                </div>
              </Tabs.TabPane>
            ) : null}
            {checkTabPermitted(roles, "ADVANCED_SEARCH") ? (
              <Tabs.TabPane
                key={EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH}
                tab={EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH}
              ></Tabs.TabPane>
            ) : null}

            {checkTabPermitted(roles, "STRUCTURE_SEARCH") ? (
              <Tabs.TabPane
                key={EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH}
                tab={EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH}
              >
                <div className="gx-tab-list">
                  <ul className="gx-navbar-nav">
                    {_.map(listItemsStructure, (listItem) => {
                      if (checkTabPermitted(roles, listItem?.value)) {
                        return renderListItemSpecific(listItem);
                      }

                      return null;
                    })}
                  </ul>
                </div>
              </Tabs.TabPane>
            ) : null}
          </Tabs>
        </div>

        {currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH &&
        !showAdvancedSearchForm ? (
          <div
            style={{
              display: "flex",
              justifyContent: "end",
            }}
          >
            {renderCurrentQuery(
              mapKeys(formItems),
              advancedSearchJoinType,
              () => setShowAdvancedSearchForm(true)
            )}
          </div>
        ) : null}

        {currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH &&
        structureImage ? (
          <Popover content="Edit query">
            <div
              className="gx-pointer"
              onClick={() => setCanvasModal(!canvasModal)}
              style={{
                position: "absolute",
                right: "50px",
                backgroundColor: "white",
                borderRadius: "25px",
                width: "200px",
                bottom: "0",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignSelf: "center",
                  margin: "0 auto",
                }}
              >
                <img
                  alt="structure"
                  width={100}
                  height={100}
                  src={`data:image/svg+xml;charset=utf-8,${encodeURIComponent(
                    getSvgFromXML(structureImage || "")
                  )}`}
                  style={{
                    margin: "0 auto",
                  }}
                />

                <span
                  className="gx-mt-2 gx-mb-2"
                  style={{ color: "black", margin: "0 auto" }}
                >
                  {similarityOptions.includes(matchSearch)
                    ? `${cutoff}%`
                    : null}{" "}
                  {(_.find(searchOps, { value: matchSearch }) || {})?.field}
                  <EditTwoTone twoToneColor="#52c41a" className="gx-ml-2" />
                </span>
              </div>
            </div>
          </Popover>
        ) : null}
      </div>
    </div>
  );
};

Header.defaultProps = {
  availableDataSources: [],
};

Header.propTypes = {
  availableDataSources: PT.arrayOf(PT.shape),
};

export default Header;
