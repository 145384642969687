import {
  FETCH_COLLECTION_FAILED,
  FETCH_COLLECTION_REQUESTED,
  FETCH_COLLECTION_RESET,
  FETCH_COLLECTION_SUCCESS,
  FETCH_DOCUMENT_DETAILS_FAILED,
  FETCH_DOCUMENT_DETAILS_REQUESTED,
  FETCH_DOCUMENT_DETAILS_RESET,
  FETCH_DOCUMENT_DETAILS_SUCCESS,
  GET_DOCUMENTS_FAILED,
  GET_DOCUMENTS_REQUESTED,
  GET_DOCUMENTS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  collections: {},
  loading: false,
  searchResults: [],
  currentIndex: 1,
  totalIndex: 0,

  // Collection Related
  loadingCollection: false,
  collectionResults: [],
  currentCollectionIndex: 1,
  totalCollectionIndex: 0,
};

const GenericDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DOCUMENTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        collections: {},
      };
    }

    case GET_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        collections: action.payload.documents,
      };
    }

    case GET_DOCUMENTS_FAILED: {
      return {
        ...state,
        loading: false,
        collections: {},
      };
    }

    case FETCH_DOCUMENT_DETAILS_RESET: {
      return {
        ...state,
        searchResults: [],
        totalIndex: action.payload?.total,
        currentIndex: 1,
        loading: false,
      };
    }

    case FETCH_COLLECTION_RESET: {
      return {
        ...state,
        collectionResults: [],
        totalCollectionIndex: action.payload?.total,
        currentCollectionIndex: 1,
        loadingCollection: false,
      };
    }

    case FETCH_DOCUMENT_DETAILS_REQUESTED: {
      return {
        ...state,
      };
    }

    case FETCH_COLLECTION_REQUESTED: {
      return {
        ...state,
      };
    }

    case FETCH_DOCUMENT_DETAILS_FAILED: {
      const isLast = state.currentIndex + 1 >= state.totalIndex;

      return {
        ...state,
        currentIndex: state.currentIndex + 1,
        loading: !isLast,
      };
    }

    case FETCH_COLLECTION_FAILED: {
      const isLast =
        state.currentCollectionIndex + 1 >= state.totalCollectionIndex;

      return {
        ...state,
        currentCollectionIndex: state.currentCollectionIndex + 1,
        loadingCollection: !isLast,
      };
    }

    case FETCH_DOCUMENT_DETAILS_SUCCESS: {
      const isLast = state.currentIndex + 1 >= state.totalIndex;

      return {
        ...state,
        searchResults: [...state.searchResults, action.payload],
        currentIndex: state.currentIndex + 1,
        loading: !isLast,
      };
    }

    case FETCH_COLLECTION_SUCCESS: {
      const isLast =
        state.currentCollectionIndex + 1 >= state.totalCollectionIndex;

      return {
        ...state,
        collectionResults: [...state.collectionResults, action.payload],
        currentCollectionIndex: state.currentCollectionIndex + 1,
        loadingCollection: !isLast,
      };
    }

    default:
      return state;
  }
};

export default GenericDataReducer;
