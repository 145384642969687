/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PT from "prop-types";
import TableContent from "./TableContent";
import Header from "../Widget/Header";
import CircularProgress from "components/CircularProgress";
import {
  CloseCircleTwoTone,
  EditTwoTone,
  PieChartTwoTone,
  PlusCircleTwoTone,
  SaveTwoTone,
  SlidersTwoTone,
} from "@ant-design/icons";

import "./style.css";
import VersioningForCompositionTable from "./VersioningForCompositionTable";

import TableForm from "./TableForm";
import _ from "lodash";
import {
  getCompoundInstance,
  saveCompoundInstance,
} from "../../../appRedux/services/CompoundInstance";
import { getOriginal, getParent, getPrefix } from "../../../util/Widget";
import { Drawer, Form, Input, Modal, Popover, Radio, Select } from "antd";
import { getImageFromCompoundId } from "../../../appRedux/services/Search";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  additionalComponentTypeOptions,
  componentRoleOptions,
  COMPOSITION_TABLE_ORDER,
  IDENTIFIER_SEPERATOR,
  PAGE_TYPES,
  SEARCH_CUTOFF_DEFAULT,
  SEARCH_OPERATOR_OPTIONS,
} from "../../../constants/Config";
import {
  addImageToLookup,
  fetchLookupData,
  fetchLookupImage,
} from "../../../appRedux/actions";
import { useDispatch, useSelector } from "react-redux";
import {
  getMedataApi,
  saveMedataApi,
} from "../../../appRedux/services/Metadata";
import SortableTable from "./SortableTable";
import { checkRowIsAccessible, getRoleId } from "../../../util/auth";
import { useMsal } from "@azure/msal-react";
import CompoundSelection from "../ProjectManagementWidget/CompoundSelection";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";

const CompositionTable = ({
  id,
  title,
  components,
  sourceName,
  pageType,
  componentUpdated,
  processComponents,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  selectedLanguage,
  safetySentenceLanguageMap,
  dataSources,
  roles = [],
  compoundDetail = {},
  currentVersion,
  versions = [],
  renderRestore,
  restoreCompound,
  setAdditionalComponents,
  setCurrentVersion,
  widgetOption,
  currentRound,
  allWidgets,
  widget,
}) => {
  let form = {};
  const formRef = useRef();
  const tableFormRef = useRef();
  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  const dispatch = useDispatch();
  const lookupData = useSelector(({ lookup }) => lookup);

  const [open, setOpen] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [tableForm, setTableForm] = useState([]);
  const [compoundSearch, setCompoundSearch] = useState(false);
  const [viewType, setViewType] = useState("input");
  const [currentEditIndex, setCurrentEditIndex] = useState();
  const [cutoff, setCutoff] = useState(Number(SEARCH_CUTOFF_DEFAULT));
  const [searchOps, setSearchOps] = useState([]);

  const [compositionOrder, setCompositionOrder] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const field =
    pageType === PAGE_TYPES.EXPERIMENT ? "instanceId" : "compoundId";

  const hasAccess = (roles || []).includes(getRoleId());

  const { rows } = widget || {};

  const optionsWithRole = _.filter(rows, (option) =>
    checkRowIsAccessible(option?.isAccessible)
  );

  const widgetNamesToResolve = _.uniq(
    _.map(
      _.filter(optionsWithRole, (role) => role?.widgetName),
      (i) => i?.widgetName
    )
  );

  useEffect(() => {
    if (!searchOps.length) {
      getSystemWidget(SEARCH_OPERATOR_OPTIONS, setSearchOps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Update
    _.forEach(components || [], (component) => {
      const id =
        component?.compoundId || component?.instanceId || component?.id;

      if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
        dispatch(fetchLookupData({ id, sourceName, widgetName: null }));
        dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));

        _.forEach(widgetNamesToResolve, (widgetName) => {
          dispatch(fetchLookupData({ id, sourceName, widgetName }));
          // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
        });
      }
    });
  }, [components, dispatch, lookupData, sourceName]);

  useEffect(() => {
    setTableForm(
      _.map(sortedCompositionData, (item, index) => ({
        ...item,
        index: uuidv4(),
      }))
    );
  }, []);

  useEffect(() => {
    // Update process components
    _.forEach(processComponents || [], (component) => {
      const id =
        component?.compoundId || component?.instanceId || component?.id;

      if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
        dispatch(fetchLookupData({ id, sourceName, widgetName: null }));
        // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));

        _.forEach(widgetNamesToResolve, (widgetName) => {
          dispatch(fetchLookupData({ id, sourceName, widgetName }));
          // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
        });
      }
    });
  }, [dispatch, lookupData, processComponents, sourceName]);

  useEffect(() => {
    if (!compositionOrder.length) {
      getMedataApi(
        COMPOSITION_TABLE_ORDER,
        id,
        sourceName,
        (result = {}) => {
          const orders = result?.parameters?.options || [];
          setCompositionOrder(orders);
          setLoading(false);
        },
        () => {
          setLoading(false);
        }
      );
    }
  }, [components, compositionOrder.length, id, sourceName]);

  const getTotalPercentage = ({ options }) => {
    let sum = 0;
    _.forEach(options, (component) => {
      const value = Number(component?.value || 0);
      sum += value;
    });

    return sum || 0;
  };

  const onSave = (form) => {
    setLoading(true);
    setEditMode(false);

    // save sort order
    const compositionOrderData = _.map(form, (item, index) => ({
      id: item?.instanceId || item?.compoundId,
      order: index,
    }));

    saveMedataApi(
      COMPOSITION_TABLE_ORDER,
      id,
      sourceName,
      compositionOrderData
    );

    const savedOptions = form.map((item) =>
      _.pick(item, "instanceId", "compoundId", "type", "unit", "value")
    );

    if (checkCommonParent({ options: savedOptions })) {
      NotificationManager.error("Compound must be different.");
      setLoading(false);
      setEditMode(true);
      return;
    } else if (getTotalPercentage({ options: savedOptions }) > 100) {
      NotificationManager.error("Percentage cannot exceed 100%.");
      setLoading(false);
      setEditMode(true);
      return;
    } else {
      const processComponentsNames =
        processComponents.map((item) => item?.compoundId || item?.id) || [];

      const filteredOptions = savedOptions.filter(
        (item) => !processComponentsNames.includes(item?.instanceId)
      );

      let targetData = _.map(filteredOptions, (item) => {
        let currentItem = item;
        if (!_.get(currentItem, "unit")) {
          _.set(currentItem, "unit", "%");
        }
        return currentItem;
      });

      if (pageType === PAGE_TYPES.EXPERIMENT) {
        targetData = filteredOptions.map((item) => {
          const instanceId = _.pick(
            item,
            "compoundId",
            "value",
            "type",
            "unit"
          );

          if (instanceId.instanceId) {
            instanceId.compoundId = item?.instanceId;
          }

          instanceId.componentType = (instanceId.compoundId || "").includes(".")
            ? "COMPOUND_INSTANCE"
            : "COMPOUND_CONCEPT";

          return instanceId;
        });
      }

      const instanceComponents = _.filter(targetData, (item) =>
        (item?.compoundId || item?.instanceId || "").includes(".")
      );

      const instanceComponentsNames = _.map(instanceComponents, (comp) => {
        const record = {
          ...comp,
          instanceId: comp?.compoundId || comp?.instanceId,
        };

        delete record["compoundId"];

        return record;
      });

      const conceptComponents = _.filter(
        targetData,
        (item) => !(item?.compoundId || item?.instanceId || "").includes(".")
      );

      const conceptComponentsNames = _.map(conceptComponents, (conc) => {
        const record = {
          ...conc,
          compoundId: conc?.compoundId || conc?.instanceId,
        };

        delete record["instanceId"];
        return record;
      });

      const data = {
        instanceComponents: instanceComponentsNames,
        conceptComponents: conceptComponentsNames,
      };

      saveCompoundInstance(id, sourceName, data, pageType, false, userName)
        .then(() => {
          setLoading(false);
          componentUpdated();
          NotificationManager.success("Successfully updated compositions.");
        })
        .catch((e) => {
          NotificationManager.error(
            e.response?.data?.message || "Error has occurred."
          );
          setLoading(false);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  const checkUniqueness = () => {
    const currentOptions = data.filter(
      (item) =>
        _.get(item, field) &&
        _.get(form, field) &&
        _.get(item, field) === _.get(form, field)
    );

    if (currentOptions.length > 0) {
      return false;
    }
    return true;
  };

  const checkOriginalCompound = () => {
    if (getOriginal(id) === _.get(form, field)) {
      return false;
    }
    return true;
  };

  const checkRoleExistence = () => {
    const compound = form?.role || "";

    if (!compound.length) {
      return false;
    }

    return true;
  };

  const checkCompoundExistence = () => {
    const compound =
      form?.compoundId || formRef.current?.getFieldValue("compoundId") || "";

    if (!compound.length) {
      return false;
    }

    return true;
  };

  const checkCompoundValidity = () => {
    const compound = _.get(form, field) || "";

    if (compound.includes(".")) {
      return false;
    }
    return true;
  };

  const checkPercentage = () => {
    const formPercentage = formRef.current?.getFieldValue("percentage");

    const percentage = Number(formPercentage);

    if (!_.isNumber(percentage)) {
      return false;
    }

    if (percentage <= 0 || percentage > 100) {
      return false;
    }

    return true;
  };

  const checkProcessComponent = () => {
    const id = formRef.current.getFieldValue("compoundId");
    let isExist = true;

    processData.forEach((item) => {
      if (_.get(item, field) === getParent(id)) {
        isExist = false;
      }
    });

    return isExist;
  };

  const checkTotalPercentage = () => {
    const currentPercentage = Number(form?.percentage || 0);
    return currentPercentage + getTotalPercentage({ options: data }) <= 100;
  };

  const checkCommonParent = ({ options }) => {
    const parents = _.map(options, (item) => {
      const id = item?.compoundId || item?.instanceId;

      return id;
    });

    return (parents || []).length !== _.uniq(parents || []).length;
  };

  const checkFormValid = () => {
    let currentFormValid = true;
    if (!checkPercentage()) {
      formRef.current.setFields([
        {
          name: "percentage",
          errors: [
            form?.percentage
              ? "Percentage must be less than 100%."
              : "Percentage must be greater than 0%.",
          ],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkTotalPercentage()) {
      formRef.current.setFields([
        {
          name: "percentage",
          errors: ["Total percentage cannot exceed 100%."],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkProcessComponent()) {
      formRef.current.setFields([
        {
          name: "compoundId",
          errors: ["Compound cannot be reactant."],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkCommonParent([])) {
      formRef.current.setFields([
        {
          name: "compoundId",
          errors: ["Compound should be different."],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkUniqueness()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound is already added.`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkOriginalCompound()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be original.`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkCompoundExistence()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be a empty`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkRoleExistence()) {
      formRef.current?.setFields([
        {
          name: "role",
          errors: [`Role cannot be a empty`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkCompoundValidity()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be a quality`],
        },
      ]);
      currentFormValid = false;
    }

    return currentFormValid;
  };

  const onChangeCompoundId = async (value, index) => {
    const targetEl = document.getElementById(`input-compoundId-${index}`);
    const id = `${getPrefix(
      dataSources,
      sourceName
    )}${IDENTIFIER_SEPERATOR}${value}`;
    try {
      getImageFromCompoundId(id, sourceName).then((image) => {
        if (image?.data) {
          dispatch(addImageToLookup(id, image?.data));
          // formRef.current?.setFields([{ name: "compoundId", id }]);
        }

        targetEl.value = value;
        targetEl.focus();
      });
    } catch (error) {
      // formRef.current?.setFields([{ name: "compoundId", id }]);
      document.getElementById(`input-compoundId-${index}`).focus();
    }
  };

  const componentsData = components.map((item, index) => ({ ...item, index }));
  const processData = processComponents.map((item) => ({
    instanceId: item?.id,
    type: item?.role,
    processData: true,
  }));

  const data = [...processData, ...componentsData];

  const decomposeTable = () => {
    let percent = 100.0;

    if ((compoundDetail?.components || []).length) {
      percent = Math.floor(100.0 / (compoundDetail?.components || []).length);
    }

    form = _.map(compoundDetail?.components || [], (item, ind) => {
      return {
        compoundId: item?.name,
        type: "main_component",
        value: percent,
        unit: "%",
        index: ind,
        isDecomposed: true,
      };
    });

    form.unshift({
      compoundId: getParent(id),
      type: "main_component",
      value: 0,
      unit: "%",
      isDecomposed: true,
    });

    onSave(form);
    forceUpdate();
  };

  const renderCalculatedWeight = () => {
    // Disable for Experiment page.

    if (pageType === PAGE_TYPES.EXPERIMENT) return null;

    let total = 0;

    _.forEach(data, (item) => {
      const percent = item?.value || 0;
      const currentId = item?.compoundId || item?.instanceId;

      try {
        const mass = lookupData[currentId].molecularWeight;
        total += (mass * Number(percent)) / 100.0;
      } catch (error) {}
    });

    total = _.isNaN(total) ? 0 : total.toFixed(2);

    return (
      <span className="gx-mr-3 gx-text-info" style={{ fontSize: "12px" }}>
        Calculated weight: {total}
      </span>
    );
  };

  const onAddRow = async () => {
    let newForm = (await tableFormRef.current?.getForm()) || [];
    newForm.push({
      index: uuidv4(),
      newData: true,
    });

    setTableForm(newForm);
    forceUpdate();
  };

  const renderActionButtons = () => {
    if (!hasAccess) return null;

    return editMode ? (
      <>
        {renderCalculatedWeight()}
        <Popover content="Decompose compositions">
          <PieChartTwoTone onClick={decomposeTable} />
        </Popover>
        <Popover content="Sort compositions">
          <SlidersTwoTone
            className="gx-ml-2"
            onClick={() => setSortModalOpen(true)}
          />
        </Popover>
        <Popover content="Add new row">
          <PlusCircleTwoTone className="gx-ml-2" onClick={onAddRow} />
        </Popover>
        <Popover content="Save table">
          <SaveTwoTone
            className="gx-ml-2"
            twoToneColor="#52c41a"
            onClick={() => {
              const form = tableFormRef.current?.getForm() || [];

              setTableForm(form);

              onSave(form);
            }}
          />
        </Popover>

        <Popover content="Exit edit mode">
          <CloseCircleTwoTone
            className="gx-ml-2"
            twoToneColor="#a94442"
            onClick={() => {
              setEditMode(false);
              if (
                _.isEmpty(
                  _.get(components[components.length - 1], "compoundId")
                )
              ) {
                components.pop();
              }
            }}
          />
        </Popover>
      </>
    ) : (
      <>
        {renderCalculatedWeight()}
        {/* <PlusCircleTwoTone
          onClick={() => {
            // setShowModal(true);
            setEditMode(true);

            if (!_.get(components[components.length - 1], "newData")) {
              components.push({ newData: true });
            }
          }}
        /> */}
        <Popover content="Enter edit mode">
          <EditTwoTone
            className="gx-ml-2"
            twoToneColor="#52c41a"
            onClick={() => {
              setEditMode(true);
            }}
          />
        </Popover>
      </>
    );
  };

  const onChangeForm = (item, value) => {
    form = {
      ...form,
      [item]: value,
    };
  };

  const onSaveSorting = (sortedOptions) => {
    const targetSortedOptions = _.map(sortedOptions, (option, index) => ({
      ...option,
      order: index,
    }));

    saveMedataApi(COMPOSITION_TABLE_ORDER, id, sourceName, targetSortedOptions);

    setCompositionOrder(targetSortedOptions);

    NotificationManager.success("Successfully saved compositions.");

    setSortModalOpen(false);

    forceUpdate();
  };

  const save = () => {
    let filteredOptions = data.filter((item) => !item?.processData);

    checkFormValid();
    const newForm = {
      compoundId: formRef.current?.getFieldValue("compoundId"),
      instanceId: formRef.current?.getFieldValue("compoundId"),
      type: formRef.current?.getFieldValue("role"),
      value: formRef.current?.getFieldValue("percentage"),
      unit:
        formRef.current.getFieldValue("unit") ||
        additionalComponentTypeOptions[0].value,
    };

    filteredOptions.push(newForm);

    if (checkFormValid()) {
      const savedOptions = filteredOptions.map((item) => {
        return {
          ..._.pick(item, "compoundId", "instanceId", "type", "unit", "value"),
          componentType: (item.compoundId || item.instanceId || "").includes(
            "."
          )
            ? "COMPOUND_INSTANCE"
            : "COMPOUND_CONCEPT",
        };
      });

      let targetData = savedOptions;
      if (pageType === "experiment") {
        targetData = savedOptions.map((item) => {
          return {
            ..._.omit(item, "compoundId"),
            instanceId: item?.compoundId || item?.instanceId,
          };
        });
      }

      setLoading(true);
      if (pageType === "quality" || pageType === "experiment") {
        saveCompoundInstance(
          id,
          sourceName,
          targetData,
          pageType,
          false,
          userName
        )
          .then(() => {
            componentUpdated();
            setLoading(false);
            forceUpdate();
          })
          .catch((e) => {
            NotificationManager.error(e.response?.data?.message);
            setLoading(false);
            forceUpdate();
          });
      }
      setEditMode(false);
      setShowModal(false);
    }
  };

  const renderModal = () => {
    return (
      <Form ref={formRef}>
        <div className="additional-component-roles">
          <div className="card-item-value">
            <span>
              {pageType === "experiment" ? "Instance ID: " : "Compound ID:"}
            </span>
          </div>
          <Form.Item
            required
            name="compoundId"
            rules={[
              {
                required: true,
                message: "Please enter compound id",
              },
            ]}
            style={{ marginRight: "0" }}
          >
            <Input
              type="text"
              name="compoundId"
              className="addtional-component-percentage-input"
              value={form?.compoundId}
              onChange={(e) => {
                e.preventDefault();
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onChangeCompoundId(e.target.value);
                  e.preventDefault();
                  onChangeForm("compoundId", e.target.value);
                }
              }}
            />
          </Form.Item>
        </div>
        <div className="additional-component-roles">
          <div className="card-item-value">Role: </div>
          <Form.Item
            required
            name="role"
            rules={[
              {
                required: true,
                message: "Please select role",
              },
            ]}
            className="additional-component-select"
          >
            <Select
              name="role"
              size="small"
              options={componentRoleOptions}
              className="custom-addtional-role"
              onChange={(val) => onChangeForm("role", val)}
            />
          </Form.Item>
        </div>
        <div className="additional-component-roles">
          <div className="card-item-value">Percent Composition:</div>
          <Form.Item
            required
            name="percentage"
            rules={[
              {
                required: true,
                message: "Please enter percentage",
              },
            ]}
            style={{ marginRight: "0" }}
          >
            <Input
              className="addtional-component-percentage-input"
              type="number"
              name="percentage"
              onChange={(e) => {
                e.preventDefault();
                onChangeForm("percentage", e.target.value);
              }}
              step={0.1}
            />
          </Form.Item>
        </div>
      </Form>
    );
  };

  const componentsDataNames = componentsData.map((item) =>
    getParent(item?.instanceId)
  );
  const filteredData = _.sortBy(
    data.filter((item) => !componentsDataNames.includes(item?.instanceId)),
    "instanceId"
  ).map((item) => {
    const process = processComponents.find(
      (processComponent) => processComponent?.id === getParent(item?.instanceId)
    );

    if (process) {
      return {
        ...item,
        qualityData: true,
        role: process?.role,
      };
    } else {
      return item;
    }
  });

  const sortedCompositionData = filteredData.sort((a, b) => {
    const firstId = a?.instanceId || a?.compoundId;
    const secondId = b?.instanceId || b?.compoundId;

    const firstIndex = (_.find(compositionOrder, { id: firstId }) || {})?.order;
    const secondIndex = (_.find(compositionOrder, { id: secondId }) || {})
      ?.order;

    return firstIndex - secondIndex;
  });

  /**
   * If compound structure widget has versions and current version is not a last one, we should highlight header.
   */
  let backgroundColor = widgetOption?.backgroundColor;
  const isLastVersion = (versions || []).length === currentVersion;

  if (
    currentVersion &&
    (versions || []).length > 1 &&
    !isLastVersion &&
    !editMode
  ) {
    backgroundColor = "#ffbb96";
  }

  useEffect(() => {
    if ((sortedCompositionData || []).length === (tableForm || []).length) {
      let isNewData = false;
      _.forEach(tableForm, (tf) => {
        if (tf?.newData) {
          isNewData = true;
        }
      });

      if (
        !_.isEqual(sortedCompositionData, tableForm) &&
        editMode &&
        !isNewData
      ) {
        setTableForm(sortedCompositionData);
      }
    }
  }, [sortedCompositionData]);

  const onAddCompound = (cId) => {
    console.log(cId);
  };

  const onSelectProcess = (cId) => {
    const form = tableFormRef.current?.getForm() || [];

    const newForm = _.map(form, (item) => {
      if (item?.index !== currentEditIndex) return item;

      return {
        ...item,
        compoundId: cId,
      };
    });

    setTableForm(newForm);

    setCompoundSearch(false);
  };

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 double-width ${
        open ? "isOpen" : ""
      }
  `}
      key={uuidv4()}
    >
      <Header
        title={title}
        open={open}
        setOpen={setOpen}
        child={pageType === PAGE_TYPES.PROCESS ? null : renderActionButtons()}
        backgroundColor={backgroundColor}
      />

      {open ? (
        <div>
          {loading ? (
            <div
              style={{
                height: "150px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress className="custom-loader" />
            </div>
          ) : (
            <div>
              {editMode ? (
                <TableForm
                  id={id}
                  sourceName={sourceName}
                  pageType={pageType}
                  ref={tableFormRef}
                  dataSources={dataSources}
                  onRemove={() => {
                    setEditMode(false);
                    const form = tableFormRef.current?.getForm() || [];
                    onSave(form);
                  }}
                  onChangeCompoundId={onChangeCompoundId}
                  onChangeForm={onChangeForm}
                  form={tableForm}
                  setForm={setTableForm}
                  onOpenSearch={(i) => {
                    setCompoundSearch(true);
                    setCurrentEditIndex(i);
                  }}
                />
              ) : (
                <div>
                  <TableContent
                    compositions={sortedCompositionData}
                    sourceName={sourceName}
                    pageType={pageType}
                    safetySentenceTypes={safetySentenceTypes}
                    safetySentenceImageMetadata={safetySentenceImageMetadata}
                    selectedLanguage={selectedLanguage}
                    safetySentenceLanguageMap={safetySentenceLanguageMap}
                    currentRound={currentRound}
                    rows={optionsWithRole}
                    allWidgets={allWidgets}
                  />
                  {(versions || []).length &&
                  (pageType === PAGE_TYPES.QUALITY ||
                    pageType === PAGE_TYPES.PROCESS ||
                    pageType === PAGE_TYPES.EXPERIMENT) ? (
                    <div
                      className="gx-ml-4 gx-mr-4"
                      style={{
                        justifyContent: "flex-end",
                      }}
                    >
                      <VersioningForCompositionTable
                        currentVersion={currentVersion}
                        versions={versions}
                        onChangeVersion={async (page) => {
                          const total = versions.length;
                          let current = page;

                          if (page === total && !renderRestore) {
                            current = null;
                          }

                          setCurrentVersion(page);

                          await getCompoundInstance(
                            id,
                            sourceName,
                            (detail) => {
                              const {
                                instanceComponents = [],
                                conceptComponents = [],
                              } = detail;

                              setAdditionalComponents([
                                ...instanceComponents,
                                ...conceptComponents,
                              ]);

                              // setCurrentVersion(detail?.version || 1);
                            },
                            pageType,
                            current,
                            () => {},
                            setLoading
                          );
                        }}
                        renderRestore={renderRestore}
                        restoreCompound={restoreCompound}
                      />
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          )}
        </div>
      ) : (
        <div>
          {true ? <CircularProgress className="gx-loader-400 loader" /> : null}
        </div>
      )}

      <Modal
        title={`Add New Component`}
        open={showModal}
        okText="Save"
        onOk={save}
        onCancel={() => setShowModal(false)}
      >
        {renderModal()}
      </Modal>

      <Drawer
        title="Sort Composition Table Items"
        open={sortModalOpen}
        okText="Save"
        onClose={() => setSortModalOpen(false)}
        footer={null}
      >
        <SortableTable
          options={sortedCompositionData}
          onCancel={() => setSortModalOpen(false)}
          onSaveSorting={onSaveSorting}
          decomposeTable={decomposeTable}
        />
      </Drawer>

      <Drawer
        title={
          <div className="gx-d-flex">
            <span>Search Compound</span>
            <Radio.Group
              size="small"
              value={viewType}
              onChange={(e) => {
                setViewType(e.target.value);
              }}
              className="gx-mb-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <Radio.Button value="input">Search by Value</Radio.Button>
              <Radio.Button value="structure">Search by Structure</Radio.Button>
            </Radio.Group>
          </div>
        }
        placement="right"
        open={compoundSearch}
        width={900}
        className="settingsDrawer"
        okText="Add Process"
        onOk={onAddCompound}
        onClose={() => {
          setCompoundSearch(false);
        }}
      >
        <CompoundSelection
          sourceName={sourceName}
          availableWidgets={allWidgets}
          viewType={viewType}
          availableDataSources={dataSources}
          searchOps={searchOps}
          cutoff={cutoff}
          setCutoff={setCutoff}
          onSelectProcess={onSelectProcess}
        />
      </Drawer>

      <NotificationContainer />
    </div>
  );
};

CompositionTable.defaultProps = {
  id: "",
  title: "Composition Table",
  components: [],
  sourceName: "",
  pageType: "",
};

CompositionTable.propTypes = {
  id: PT.string,
  title: PT.string,
  components: PT.arrayOf(PT.shape()),
  sourceName: PT.string,
  pageType: PT.string,
  componentUpdated: PT.func.isRequired,
};

export default CompositionTable;
