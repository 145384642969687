import { Button, Form, Input } from "antd";
import React, { useEffect, useState } from "react";
import Graph from "react-graph-vis";
import { getCompoundConceptGraph } from "../../appRedux/services/Lookup";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import GraphWidget from "../../components/DetailPage/GraphWidget";
import {
  DATA_WIDGETS_METADATA,
  EXCEL_TEMPLATE_SOURCE_NAME,
  GRAPH_WIDGET,
  PAGE_TYPES,
} from "../../constants/Config";
import { getSystemWidget } from "../../appRedux/services/SystemWidget";
import { getCompoundConcept } from "../../appRedux/services/Metadata";
import useGetRoles from "../../hooks/detailPage/useGetRoles";

function DemoPage() {
  const [id, setId] = useState();

  const [dataWidgets, setDataWidgets] = useState([]);
  const [additionalComponents, setAdditionalComponents] = useState([]);
  const [compoundDetail, setCompoundDetail] = useState({});
  const [conceptGraph, setConceptGraph] = useState({});

  const sourceName = EXCEL_TEMPLATE_SOURCE_NAME;

  const roles = useGetRoles();

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (id) {
      getCompoundConcept(id, sourceName, null, (detail) => {
        setAdditionalComponents(detail?.components || []);
        setCompoundDetail(detail);
      });

      getCompoundConceptGraph({ id, sourceName }).then((res) => {
        setConceptGraph(res?.data?.graph);
      });
    }
  }, [id]);

  const widget = _.find(dataWidgets, { value: GRAPH_WIDGET });

  return (
    <div>
      <Form
        onFinish={({ id2 }) => {
          setId(id2);
        }}
      >
        <Form.Item name="id">
          <Input placeholder="Enter" />
        </Form.Item>

        <Form.Item>
          <Button size="small" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>
      <GraphWidget
        id={id}
        sourceName={sourceName}
        title={widget?.field}
        systemWidgetName={widget?.value}
        components={additionalComponents || []}
        pageType={PAGE_TYPES.SAMPLE}
        componentUpdated={() => {}}
        roles={roles}
        widget={widget?.widget || {}}
        versionRoles={widget?.versionRoles || []}
        widgetOption={{}}
        compoundDetail={compoundDetail}
        // sampleDetail={sampleDetail}
        conceptGraph={conceptGraph}
      />
    </div>
  );
}

export default DemoPage;
