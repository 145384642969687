import { useMsal } from "@azure/msal-react";
import { Button, Form, Input, Select } from "antd";
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { postBulkFile } from "../../../appRedux/services/uploadData";
import KnownResult from "./KnownResult";
import UnKnownResult from "./UnKnownResult";

const BulkUpdate = ({
  dataSources,
  setSelectedDataSource,
  selectedDataSource,
  file,
}) => {
  const [idField, setIdField] = useState("");
  const [result, setResult] = useState({});
  const [showResult, setShowResult] = useState(false);
  const [tableOptions, setTableOptions] = useState([]);

  const [loading, setLoading] = useState(false);

  const typeOptions = [
    { label: "Register with predefined identifiers", value: 1 },
    { label: "Register without identifiers", value: 0 },
  ];

  const [type, setType] = useState(1);

  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  const dataSourcesOptions = dataSources.map((item) => ({
    field: item?.sourceName,
    value: item?.sourceName,
  }));

  const bulkUpdate = () => {
    setLoading(true);
    postBulkFile({
      sourceName: selectedDataSource,
      idField,
      file,
      userName,
    })
      .then((res) => {
        setResult(res.data);
        setShowResult(true);
      })
      .catch((e) => {
        NotificationManager.error(
          e?.response?.data?.message || "Error occurred while upload.",
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const loadSdfData = () => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      // format with consistent line breaking
      const targetElement = evt.target.result.replaceAll("\r\n", "\n");

      const elements = (targetElement || "").split("$$$$\n");

      const items = _.map(elements, (element, index) => ({
        id: index,
        value: element,
      }));

      setTableOptions(items);
      setShowResult(true);
    };

    reader.readAsText(file, "utf-8");
  };

  return (
    <div>
      <div className="gx-d-flex">
        <h3 className="gx-ml-2 gx-mb-4">Upload SDF File</h3>
      </div>

      {showResult ? (
        <>
          {type ? (
            <KnownResult result={result} />
          ) : (
            <UnKnownResult
              options={tableOptions}
              sourceName={selectedDataSource}
            />
          )}
        </>
      ) : (
        <div className="gx-d-flex">
          <Form className="gx-ml-3">
            <Form.Item label="Select Data Source" className="gx-ml-1">
              <Select
                options={dataSourcesOptions}
                onChange={setSelectedDataSource}
                defaultValue={selectedDataSource}
                showSearch
                dropdownMatchSelectWidth={false}
              ></Select>
            </Form.Item>
            <Form.Item label="Select Type" className="gx-ml-1">
              <Select
                options={typeOptions}
                onChange={setType}
                defaultValue={typeOptions[0]}
                showSearch
                dropdownMatchSelectWidth={false}
              ></Select>
            </Form.Item>
            {type ? (
              <Form.Item label="Enter Id Field">
                <Input
                  name="idField"
                  onChange={(e) => setIdField(e.target.value)}
                />
              </Form.Item>
            ) : null}

            {loading ? (
              <>
                <Button>Loading...</Button>
                <CircularProgress className="gx-loader-400 loader" />
              </>
            ) : (
              <Button
                type="primary"
                onClick={() => {
                  if (type) {
                    bulkUpdate();
                  } else {
                    loadSdfData();
                  }
                }}
              >
                {type ? "Upload" : "Load Data"}
              </Button>
            )}
          </Form>
        </div>
      )}
    </div>
  );
};

export default BulkUpdate;
