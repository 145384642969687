import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  FilterTwoTone,
  MenuOutlined,
} from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import {
  SortableContext,
  arrayMove,
  rectSwappingStrategy,
  useSortable,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as ExpandIcon } from "../../../../assets/vendors/expand.svg";
import FormWrapper from "../FormWrapper";
import "./style.css";

import {
  Badge,
  Descriptions,
  Drawer,
  Form,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import EditableCell from "../../../../components/EditableCell";
import {
  AVAILABLE_DOCUMENT_INPUT_TYPES,
  AVAILABLE_TABLE_INPUT_TYPES,
  AVAILABLE_WIDGET_INPUT_TYPES,
  DOCUMENT_COLLECTION_OPTIONS,
} from "../../../../constants/Config";

const SortableRows = forwardRef((props, ref) => {
  const {
    parentForm,
    sortableRows,
    onRowChange,
    roles,
    widgetName,
    widgetType,
    settingsWidgetName,
    modalOpen,
    setModalOpen,
    isColumnSettings = false,
    availableWidgets = [],
  } = props;

  const [form, setForm] = useState([]);
  const [currentRow, setCurrentRow] = useState({});
  const [currentRowIndex, setCurrentRowIndex] = useState(0);
  const [editingKey, setEditingKey] = useState("");

  const [inlineForm] = Form.useForm();

  const childRef = useRef();

  const isEditing = (record) => record.key === editingKey;

  const rolesOptions = _.map(roles, (role) => ({
    label: role?.displayName,
    value: role?.id,
  }));

  let types =
    widgetType === "table"
      ? AVAILABLE_TABLE_INPUT_TYPES
      : AVAILABLE_WIDGET_INPUT_TYPES;

  if (settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS) {
    types = AVAILABLE_DOCUMENT_INPUT_TYPES;
  }

  useEffect(() => {
    const sortableRowsWithIndex = _.map(sortableRows, (row, index) => ({
      ...row,
    }));

    setForm(sortableRowsWithIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortableRows, parentForm]);

  const onRowsChange = (row, index) => {
    if (!_.isEqual(sortableRows[index], row)) {
      let newRows = sortableRows;
      newRows[index] = row;
      onRowChange(newRows);
    }
  };

  useImperativeHandle(ref, () => ({
    getCurrentRows() {
      return form;
    },
  }));

  const onRemoveRow = (rowIndex) => {
    onRowChange(
      (sortableRows || []).filter((row, index) => index !== rowIndex)
    );
  };

  const save = async (key) => {
    try {
      const row = await inlineForm.validateFields();
      const newData = [...form];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setForm(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setForm(newData);
        setEditingKey("");
      }

      // onSave(widgetName, newData);
      onRowChange(newData);
    } catch (errInfo) {}
  };

  const renderMoreSettings = (rowItem, rowIndex) => {
    const renderPreviewOption = () => {
      const rows = _.map(_.get(rowItem, "isEditable"), (item, key) =>
        item === true ? key : null
      ).filter((item) => item);

      const accessRows = _.map(_.get(rowItem, "isAccessible"), (item, key) =>
        item === true ? key : null
      ).filter((item) => item);

      return (
        <Descriptions
          title="Settings Preview"
          bordered
          layout="horizontal"
          size="small"
          column={1}
          style={{
            maxHeight: "300px",
            overflow: "scroll",
          }}
        >
          <Descriptions.Item label={"Name"}>
            {_.get(rowItem, "name")}
          </Descriptions.Item>
          <Descriptions.Item label={"Type"}>
            {_.get(rowItem, "type")}
          </Descriptions.Item>
          <Descriptions.Item label={"Field"}>
            {_.get(rowItem, "field")}
          </Descriptions.Item>
          <Descriptions.Item label={"Tooltip"}>
            {_.get(rowItem, "info")}
          </Descriptions.Item>
          <Descriptions.Item label={"Target Object"}>
            {_.get(rowItem, "targetObj")}
          </Descriptions.Item>
          <Descriptions.Item label={"Limit"}>
            {_.get(rowItem, "limit")}
          </Descriptions.Item>
          <Descriptions.Item label={"Edit Roles"}>
            {(rows || []).join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label={"Access Roles"}>
            {(accessRows || []).join(", ")}
          </Descriptions.Item>
          <Descriptions.Item label="Display as one line">
            {_.get(rowItem, "isTrimmed") ? "True" : "False"}
          </Descriptions.Item>
          <Descriptions.Item label="Is Copiable">
            {_.get(rowItem, "isCopiable") ? "True" : "False"}
          </Descriptions.Item>
          <Descriptions.Item label="Is Searchable">
            {_.get(rowItem, "isSearchable") ? "True" : "False"}
          </Descriptions.Item>
        </Descriptions>
      );
    };

    const editable = isEditing(rowItem);

    return (
      <>
        {editable ? (
          <>
            <CloseCircleTwoTone
              className="gx-mr-2"
              twoToneColor="#a94442"
              onClick={() => save(rowItem.key)}
            />
            <DeleteTwoTone />
          </>
        ) : (
          <>
            {/* <EditTwoTone
              twoToneColor="#038FDE"
              className="gx-mr-2"
              onClick={() => {
                inlineForm.setFieldsValue({
                  ...rowItem,
                });
                setEditingKey(rowItem.key);
              }}
            /> */}
            <Popover content={renderPreviewOption}>
              <ExpandIcon
                className="expand-icon"
                onClick={() => {
                  setModalOpen(true);
                  setCurrentRow(rowItem);
                  console.log(rowIndex);
                  setCurrentRowIndex(rowIndex);
                }}
              />
            </Popover>

            <Popconfirm
              title="Are you sure to delete this?"
              onConfirm={() => onRemoveRow(rowItem?.index)}
              okText="Yes"
              cancelText="No"
            >
              <DeleteTwoTone
                className="gx-ml-2 gx-pointer"
                twoToneColor="#f44336"
              />
            </Popconfirm>
          </>
        )}
      </>
    );
  };

  // return <SortableList form={form} onSortEnd={onSortEnd} />;

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setForm((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const renderRoles = (p, { roles = [] }, index) => {
    const rolesFlattened = Object.entries(form[index]?.isEditable || []) || [];

    const currentRoles = rolesFlattened
      .map((item) => (item[1] === true ? item[0] : null))
      .filter((item) => item);

    return _.map(currentRoles || [], (currentRole) => {
      const currentRoleName =
        _.find(rolesOptions, { value: currentRole }) || {};

      return (
        <Tag color="blue" key={uuidv4()}>
          {currentRoleName?.label}
        </Tag>
      );
    });
  };

  const renderAccessRoles = (p, { roles = [] }, index) => {
    const rolesFlattened =
      Object.entries(form[index]?.isAccessible || []) || [];

    const currentRoles = rolesFlattened
      .map((item) => (item[1] === true ? item[0] : null))
      .filter((item) => item);

    return _.map(currentRoles || [], (currentRole) => {
      const currentRoleName =
        _.find(rolesOptions, { value: currentRole }) || {};

      return (
        <Tag color="blue" key={uuidv4()}>
          {currentRoleName?.label}
        </Tag>
      );
    });

    // return <span>{(currentRoles || []).join(", ")}</span>;
  };

  const renderAccessRoles2 = (p, { roles = [] }, index) => {
    const rolesFlattened =
      Object.entries(form[index]?.isAccessible || []) || [];

    const currentRoles = rolesFlattened
      .map((item) => (item[1] === true ? item[0] : null))
      .filter((item) => item);

    return _.map(currentRoles || [], (currentRole) => {
      const currentRoleName =
        _.find(rolesOptions, { value: currentRole }) || {};

      return (
        <Tag color="blue" key={uuidv4()}>
          {currentRoleName?.label}
        </Tag>
      );
    });

    // return <span>{(currentRoles || []).join(", ")}</span>;
  };

  const onSaveRow = ({ index, row }) => {
    let newRows = _.map(sortableRows || [], (cRow) =>
      cRow?.key === index ? row : cRow
    );
    onRowChange(newRows);
  };

  const applyToAll = (targetField, ops) => {
    const newSortableRows = _.map(sortableRows, (sortableRow) => {
      // const target = _.get(sortableRow, [targetField]);

      let newSettings = {};
      _.forEach(ops, (op) => {
        newSettings[op] = true;
      });

      const newTarget = {
        // ...target,
        ...newSettings,
      };

      return {
        ...sortableRow,
        [targetField]: newTarget,
      };
    });

    onRowChange(newSortableRows);
  };

  const editRoleRef = useRef();
  const editAccessRolesRef = useRef();
  const editAccessRolesRef2 = useRef();

  const columns = isColumnSettings
    ? [
        {
          key: "sort",
        },
        {
          title: "Widget Name",
          dataIndex: "widgetName",
          editable: true,
          render: (p, { widgetName, type }) => {
            return (
              <>
                {type === "Predefined Column" ? (
                  <span className="gx-text-warning">Predefined column</span>
                ) : (
                  <span>{widgetName}</span>
                )}
              </>
            );
          },
        },
        { title: "Target Field", dataIndex: "field", editable: true },
        {
          dataIndex: "isAccessible",
          mode: "multiple",
          editable: true,
          render: renderAccessRoles2,
          title: () => {
            return (
              <div>
                <span>Row Access Roles</span>
                <Popconfirm
                  okText="Apply"
                  title={
                    <Form ref={editAccessRolesRef2}>
                      <Form.Item
                        label="Apply to all"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="options"
                      >
                        <Select
                          options={rolesOptions}
                          allowClear
                          dropdownMatchSelectWidth
                          mode="multiple"
                        />
                      </Form.Item>
                    </Form>
                  }
                  onConfirm={() => {
                    const ops =
                      editAccessRolesRef2.current.getFieldValue("options");
                    applyToAll("isAccessible", ops);
                  }}
                >
                  <FilterTwoTone className="gx-ml-1 gx-pointer" />
                </Popconfirm>
              </div>
            );
          },
        },
        {
          title: "More Settings",
          render: (p, rowItem) => renderMoreSettings(rowItem, rowItem?.index),
        },
      ]
    : [
        {
          key: "sort",
        },
        {
          title: "Type",
          dataIndex: "type",
          editable: true,
          render: (p, { groupId, type }) => {
            if (type !== "GROUP" && groupId) {
              return (
                <Badge
                  count={`${groupId}`}
                  offset={[25, 0]}
                  color="green"
                  size="small"
                >
                  <span>{type}</span>
                </Badge>
              );
            }

            return <span>{type}</span>;
          },
        },
        { title: "Display Name", dataIndex: "field", editable: true },
        {
          title: () => {
            return (
              <div>
                <span>Row Edit Roles</span>
                <Popconfirm
                  okText="Apply"
                  title={
                    <Form ref={editRoleRef}>
                      <Form.Item
                        label="Apply to all"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="options"
                      >
                        <Select
                          options={rolesOptions}
                          allowClear
                          dropdownMatchSelectWidth
                          mode="multiple"
                        />
                      </Form.Item>
                    </Form>
                  }
                  onConfirm={() => {
                    const ops = editRoleRef.current.getFieldValue("options");
                    applyToAll("isEditable", ops);
                  }}
                >
                  <FilterTwoTone className="gx-ml-1 gx-pointer" />
                </Popconfirm>
              </div>
            );
          },
          dataIndex: "roles",
          mode: "multiple",
          editable: true,
          render: renderRoles,
        },
        {
          title: () => {
            return (
              <div>
                <span>Row Access Roles</span>
                <Popconfirm
                  okText="Apply"
                  title={
                    <Form ref={editAccessRolesRef}>
                      <Form.Item
                        label="Apply to all"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 24 }}
                        name="options"
                      >
                        <Select
                          options={rolesOptions}
                          allowClear
                          dropdownMatchSelectWidth
                          mode="multiple"
                        />
                      </Form.Item>
                    </Form>
                  }
                  onConfirm={() => {
                    const ops =
                      editAccessRolesRef.current.getFieldValue("options");
                    applyToAll("isAccessible", ops);
                  }}
                >
                  <FilterTwoTone className="gx-ml-1 gx-pointer" />
                </Popconfirm>
              </div>
            );
          },
          dataIndex: "accessRoles",
          mode: "multiple",
          editable: true,
          render: renderAccessRoles,
        },
        {
          title: "More Settings",
          render: (p, rowItem, index) =>
            renderMoreSettings(rowItem, rowItem?.index),
        },
      ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    let options = types;

    let inputType = "text";
    switch (col.dataIndex) {
      case "type":
        inputType = "select";
        break;
      case "roles":
        inputType = "select";
        options = (roles || []).map((item) => ({
          field: item.displayName,
          value: item.name,
        }));
        break;
      default:
        inputType = "text";
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: col.required || true,
        mode: col.mode,
        options,
      }),
    };
  });

  const groupRows = _.filter(form, (item) => item?.type === "GROUP");
  const groupOptions = _.map(groupRows, (groupRow) => ({
    field: groupRow?.field,
    value: groupRow?.groupId,
  }));

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={form.map((i) => i.key)}
          strategy={rectSwappingStrategy}
        >
          <Form form={inlineForm} component={false}>
            <Table
              size="small"
              dataSource={_.map(form, (item, index) => ({ ...item, index }))}
              pagination={false}
              components={{
                body: {
                  row: Row,
                  cell: (props) => (
                    <EditableCell {...props} onSaveRow={onSaveRow} />
                  ),
                },
              }}
              rowKey="key"
              columns={mergedColumns}
            />
          </Form>
        </SortableContext>
      </DndContext>

      <Drawer
        className="explorePage__drawer"
        placement="right"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        onOk={() => setModalOpen(false)}
        title="Row Settings"
      >
        <FormWrapper
          ref={childRef}
          row={currentRow}
          rowIndex={currentRowIndex}
          onRowsChange={onRowsChange}
          roles={roles}
          widgetName={widgetName}
          widgetType={widgetType}
          settingsWidgetName={settingsWidgetName}
          setModalOpen={setModalOpen}
          onSaveRow={onSaveRow}
          groupOptions={groupOptions}
          isColumnSettings={isColumnSettings}
          availableWidgets={availableWidgets}
        />
      </Drawer>
    </>
  );
});

export default SortableRows;
