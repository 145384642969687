import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentRoles } from "../../appRedux/actions";

/**
 * Hook for get user roles.
 * @returns {Array<Object>} roles
 */
function useGetDocumentRoles() {
  const { documentRoles, isDocumentRoleInitialized } = useSelector(
    ({ generic }) => generic
  );
  const dispatch = useDispatch();

  /**
   * Get User Roles if its not initialized.
   */
  useEffect(() => {
    if (!isDocumentRoleInitialized) {
      dispatch(getDocumentRoles());
    }
  }, [dispatch, isDocumentRoleInitialized]);

  return documentRoles;
}

export default useGetDocumentRoles;
