import { Button, Table } from "antd";
import _ from "lodash";
import React, { useRef } from "react";
import TableFilter from "../../../components/TableFilter";
import { SearchOutlined } from "@ant-design/icons";
import { checkField } from "../../../util/Widget";

const GlobalLayoutSettings = ({
  setShowGlobalModal,
  resetForm,
  globalTableOptions,
  renderGlobalOptions,
  renderGlobalActionButton,
}) => {
  const searchInput = useRef(null);

  return (
    <>
      <Button
        className="gx-mt-4"
        size="small"
        type="primary"
        onClick={() => {
          setShowGlobalModal(true);
          resetForm();
        }}
      >
        Add New Global Layout
      </Button>

      <Table dataSource={globalTableOptions} pagination={false}>
        <Table.Column
          title="Page Name"
          dataIndex="page"
          sorter={(a, b) => {
            return (a?.page || "").localeCompare(b?.page || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Name"
          dataIndex="name"
          sorter={(a, b) => {
            return (a?.name || "").localeCompare(b?.name || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Role"
          dataIndex="role"
          sorter={(a, b) => {
            return (a?.role || "").localeCompare(b?.role || "");
          }}
        ></Table.Column>
        <Table.Column
          title="Databases"
          dataIndex="database"
          render={(p, { database = [] }) => (
            <span>{_.isArray(database) ? database.join(", ") : database}</span>
          )}
          sorter={(a, b) => {
            return (a?.name || "").localeCompare(b?.name || "");
          }}
        >
          {" "}
        </Table.Column>
        {/* <Table.Column
          title="Widgets"
          render={renderGlobalOptions}
        ></Table.Column> */}
        <Table.Column
          width={250}
          title="Action"
          render={renderGlobalActionButton}
          filterDropdown={({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
            close,
          }) => (
            <TableFilter
              searchInput={searchInput}
              dataIndex={"name"}
              selectedKeys={selectedKeys}
              setSelectedKeys={setSelectedKeys}
              confirm={confirm}
              clearFilters={clearFilters}
              close={close}
            />
          )}
          filterIcon={(filtered) => (
            <SearchOutlined
              style={{ color: filtered ? "#1890ff" : undefined }}
            />
          )}
          onFilter={(value, record) => {
            return (
              checkField(value, record["page"]) ||
              checkField(value, record["name"]) ||
              checkField(value, record["role"])
            );
          }}
        ></Table.Column>
      </Table>
    </>
  );
};

export default GlobalLayoutSettings;
