import {
  CheckSquareTwoTone,
  DeleteOutlined,
  FileSearchOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Form, Image, Input, Popconfirm, Popover } from "antd";
import _ from "lodash";
import { Link } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { IDENTIFIER_SEPERATOR } from "../../../../constants/Config";
import { getPrefix } from "../../../../util/Widget";
import SafetySentences from "../../CompositionTable/TableContent/SafetySentences";

/**
 * Fetch look up value from provided target value
 * @param {String} id
 * @param {Object} lookupData
 * @param {String} targetField
 * @param {Number} currentRound
 */
export const getMetadatafromLookup = (
  id,
  lookupData,
  targetField,
  currentRound = null
) => {
  let targetData;

  if (_.includes(id || "", ".")) {
    const idObject = lookupData[id] || {};
    targetData = _.get(idObject, `${targetField}`);
  } else {
    targetData = _.get(lookupData, `${id}.${targetField}`);
  }

  try {
    if (currentRound && _.isNumber(currentRound)) {
      targetData = Number(targetData).toFixed(currentRound);
    }
  } catch (error) {}

  return targetData;
};

/**
 * Fetch image data from lookup
 * @param {String} id
 * @param {Object} lookupData
 * @returns
 */
export const getImageFromLookup = (id, lookupData) => {
  if (_.includes(id || "", ".")) {
    const idObject = lookupData[id] || {};
    return _.get(idObject, "image");
  }

  return _.get(lookupData, `${id}.image`);
};

/**
 * Fetch metadata from widget data.
 * @param {String} experimentID
 * @param {Object} experimentMetadata
 * @returns
 */
export const getExperimentMetadata = (
  experimentId,
  field,
  experimentMetadata
) => {
  return _.get(experimentMetadata, `${experimentId}.${field}`) || "";
};

/**
 * Render safety sentences
 */
const renderSafetySentences = ({
  composition,
  pageType,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
}) => {
  return (
    <SafetySentences
      option={composition}
      pageType={pageType}
      safetySentenceTypes={safetySentenceTypes}
      safetySentenceImageMetadata={safetySentenceImageMetadata}
      safetySentenceLanguageMap={safetySentenceLanguageMap}
    />
  );
};

const renderWithPrecision = (targetData, currentRound) => {
  let data = targetData;
  try {
    data = Number(Number(targetData).toFixed(currentRound));
  } catch (error) {}

  return <span className="center">{data}</span>;
};

/**
 * Experiment table column definitions.
 * @param {Object} lookupData
 * @param {Object} experimentMetadata
 * @param {Function} handleDelete
 * @param {String} sourceName
 */
export const getColumns = (
  lookupData,
  experimentMetadata,
  handleDelete,
  sourceName,
  currentRound,
  pageType,
  compositions,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  lr,
  editMode,
  onOpenSearch,
  dataSources,
  onChangeItem
) => {
  const sourceNamePrefix = getPrefix(dataSources, sourceName);

  return [
    {
      title: () => <span className="center">Image</span>,
      render: (_p, { id }) => {
        const image = getImageFromLookup(id, lookupData);

        return (
          <div className="center">
            {!_.isEmpty(id) && image ? (
              <Image
                preview={{ visible: false }}
                alt="preview"
                className="disable-preview"
                src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
                height={64}
                width={64}
              />
            ) : (
              <span
                className="gx-text-danger gx-text-center"
                style={{
                  fontSize: "10px",
                }}
              >
                Structure not defined
              </span>
            )}
          </div>
        );
      },
      width: "10%",
      key: uuidv4(),
    },
    {
      title: <span className="center">ID</span>,
      width: "15%",
      dataIndex: "id",
      editable: false,
      type: "text",
      render: (_p, { id, type, key }) => {
        let idBody = "";
        try {
          idBody = (id || "").split("-")[1];
        } catch (error) {}

        const isMain = type === "main_component";

        if (editMode) {
          return (
            <>
              <Form.Item
                className="table-form-item gx-d-flex"
                style={{
                  position: "relative",
                  maxWidth: "90px",
                  justifyContent: "center",
                }}
              >
                {isMain ? (
                  <p
                    className="text-center gx-pt-2 gx-mr-1 gx-text-primary"
                    style={{
                      marginTop: "10px !important",
                      display: "inline-block",
                    }}
                  >
                    <Link
                      to={`/${sourceName}/${
                        (id || "").includes(".") ? "quality" : "compound"
                      }/${id}`}
                      target="_blank"
                    >
                      {id}
                    </Link>
                  </p>
                ) : (
                  <Input
                    id={`input-instanceid-${id}`}
                    size="small"
                    prefix={`${sourceNamePrefix} ${IDENTIFIER_SEPERATOR} `}
                    defaultValue={idBody}
                    style={{
                      width: "90px",
                    }}
                    onChange={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        // onChangeCompoundId(e.target.value, index);
                        e.preventDefault();
                        // onChangeForm("compoundId", e.target.value);

                        onChangeItem(
                          key,
                          "id",
                          `${sourceNamePrefix}${IDENTIFIER_SEPERATOR}${e.target.value}`
                        );
                      }
                    }}
                  />
                )}
                {isMain || type === "main_component_instance" ? (
                  <FileSearchOutlined
                    style={{
                      marginLeft: "10px",
                      marginTop: isMain ? "10px" : "5px",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                    onClick={() =>
                      onOpenSearch(
                        id,
                        key,
                        isMain || type === "main_component_instance"
                      )
                    }
                  />
                ) : (
                  <SearchOutlined
                    style={{
                      marginLeft: "10px",
                      marginTop: "5px",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                    onClick={() => onOpenSearch(id, key, isMain)}
                  />
                )}
              </Form.Item>
            </>
          );
        }

        let linkType = (id || "").includes(".") ? "quality" : "compound";

        return (
          <span className="center">
            <Link to={`/${sourceName}/${linkType}/${id}`} target="_blank">
              {id}
            </Link>
          </span>
        );
      },
      key: uuidv4(),
    },
    {
      title: (
        <Popover content={<span>Role</span>}>
          <span className="center">Role</span>
        </Popover>
      ),
      dataIndex: "role",
      width: "15%",
      key: uuidv4(),
      type: "select",
      editable: true,
      render: (_p, { role }) => {
        return (
          <div className={`${_.isEmpty(role) ? "cell-warning" : ""}`}>
            <span
              style={{
                textAlign: "center",
              }}
            >
              {_.upperCase(role || "")}
            </span>
          </div>
        );
      },
    },
    {
      title: (
        <Popover content={<span>Equivalent</span>}>
          <span className="center">EQ</span>
        </Popover>
      ),
      width: "8%",
      dataIndex: "equivalent",
      editable: true,
      key: uuidv4(),
      type: "number",
      render: (_p, data) => {
        const { id, equivalent } = data;

        const eq = equivalent || _.get(experimentMetadata, `${id}.equivalent`);
        return <span className="center">{eq}</span>;
      },
    },
    {
      title: (
        <Popover
          content={
            <div>
              <p>Limiting Reagent</p>
              {_.isEmpty(lr) ? (
                <p className="gx-text-info">
                  One limiting reagent needs to be selected.
                </p>
              ) : null}
            </div>
          }
        >
          <span className={`center ${_.isEmpty(lr) ? "cell-warning" : ""}`}>
            LR
          </span>
        </Popover>
      ),
      dataIndex: "limitingReagent",
      type: "checkbox",
      width: "8%",
      editable: true,
      key: uuidv4(),
      render: (_p, data) => {
        if (lr === data?.id) {
          return <CheckSquareTwoTone twoToneColor="#52c41a" />;
        }
        return null;
      },
    },
    {
      title: (
        <Popover content={<span>Molecular Weight [g/mol]</span>}>
          <span className="center">MW</span>
        </Popover>
      ),
      dataIndex: "molWeight",
      width: "8%",
      render: (_p, { id }) => {
        return (
          <span className="center">
            {getMetadatafromLookup(
              id,
              lookupData,
              "molecularWeight",
              currentRound
            )}
          </span>
        );
      },
      key: uuidv4(),
      type: "number",
    },
    {
      title: (
        <Popover content={<span>Mol</span>}>
          <span className="center">mol</span>
        </Popover>
      ),
      dataIndex: "mol",
      width: "10%",
      editable: true,
      type: "number",
      render: (_p, { id, mol }) => {
        const m = mol || _.get(experimentMetadata, `${id}.mol`) || "";

        if (!!m) {
          return renderWithPrecision(m, currentRound);
        }

        // if (!_.isEmpty(weight)) {
        //   const mw = getMetadatafromLookup(
        //     id,
        //     lookupData,
        //     "molecularWeight",
        //     currentRound
        //   );

        //   let calculatedMolFromWeight = Number(weight) / Number(mw);

        //   try {
        //     calculatedMolFromWeight = Number(
        //       Number(calculatedMolFromWeight).toFixed(currentRound)
        //     );
        //   } catch (error) {}

        //   return <span>{calculatedMolFromWeight}</span>;
        // }

        // // If no mol if defined, Calculate from experiment data.
        // const calculatedMol = calculateMol(id, experimentMetadata);
        // return <span className="center">{calculatedMol}</span>;
      },
      key: uuidv4(),
    },
    {
      title: (
        <Popover content={<span>Density [g/l]</span>}>
          <span className="center">Density [g/l]</span>
        </Popover>
      ),

      dataIndex: "density",
      width: "10%",
      editable: true,
      key: uuidv4(),
      render: (_p, { id, density }) => {
        const d = density || _.get(experimentMetadata, `${id}.density`);

        return <span className="center">{d}</span>;
      },
    },
    {
      title: (
        <Popover content={<span>Percent composition [%]</span>}>
          <span className="center">PC [%]</span>
        </Popover>
      ),
      dataIndex: "value",
      width: "15%",
      key: uuidv4(),
      editable: true,
      type: "number",
      render: (_p, { value }) => {
        return <span className="center">{value || 100}</span>;
      },
    },
    {
      title: (
        <Popover content={<span>Weight [g]</span>}>
          <span className="center">Weight [g]</span>
        </Popover>
      ),
      dataIndex: "weight",
      width: "15%",
      editable: true,
      type: "number",

      render: (_p, { id, mol, weight, value = 100 }) => {
        if (!_.isEmpty(weight) && _.isEmpty(mol)) {
          return renderWithPrecision(weight);
        }

        /*
         * If weight is not defined use calculated weight.
         */
        let calculatedWeight =
          (calculateWeight(id, experimentMetadata, lookupData, mol) / value) *
          100;

        try {
          calculatedWeight = Number(
            Number(calculatedWeight).toFixed(currentRound)
          );
        } catch (error) {}

        return (
          <span className="center">
            {_.isNumber(calculatedWeight) &&
            !_.isNaN(calculatedWeight) &&
            calculatedWeight !== 0.0
              ? calculatedWeight
              : "not defined"}
          </span>
        );
      },
      key: uuidv4(),
    },
    {
      title: (
        <Popover content={<span>Volume [l]</span>}>
          <span className="center">Volume [l]</span>
        </Popover>
      ),
      dataIndex: "volume",
      width: "15%",
      editable: false,
      render: (_p, { id, weight, mol, value = 100, density }) => {
        const calculatedDensity =
          density || getExperimentMetadata(id, "density", experimentMetadata);

        let calculatedWeight;

        if (!_.isEmpty(weight) && _.isEmpty(mol)) {
          calculatedWeight = weight;
        }

        /*
         * If weight is not defined use calculated weight.
         */
        calculatedWeight =
          (calculateWeight(id, experimentMetadata, lookupData, mol) / value) *
          100;

        try {
          calculatedWeight = Number(
            Number(calculatedWeight).toFixed(currentRound)
          );
        } catch (error) {}

        let volume = Number(calculatedWeight) / Number(calculatedDensity);

        try {
          volume = Number(Number(volume).toFixed(currentRound));
        } catch (error) {}

        return (
          <span className="center">
            {_.isNumber(volume) && !_.isNaN(volume) && _.isFinite(volume)
              ? volume
              : "not defined"}
          </span>
        );
      },
      key: uuidv4(),
    },
    {
      title: (
        <Popover content={<span>Safety Sentences</span>}>
          <span className="center">Safety Sentences</span>
        </Popover>
      ),
      dataIndex: "safetySentences",
      width: "15%",
      key: uuidv4(),
      render: (_, option) =>
        renderSafetySentences({
          pageType,
          composition: option,
          safetySentenceTypes,
          safetySentenceImageMetadata,
          safetySentenceLanguageMap,
        }),
    },
    // {
    //   title: <span className="center">English Name</span>,
    //   width: "15%",
    //   render: (_p, { id }) => {
    //     return (
    //       <span className="center">
    //         {getMetadatafromLookup(id, lookupData, "metadata.englishName")}
    //       </span>
    //     );
    //   },
    //   key: uuidv4(),
    // },
    // {
    //   title: <span className="center">Description</span>,
    //   width: "15%",
    //   render: (_p, { id }) => {
    //     return (
    //       <span className="center">
    //         {getMetadatafromLookup(id, lookupData, "metadata.description")}
    //       </span>
    //     );
    //   },
    //   key: uuidv4(),
    // },
    {
      title: (
        <Popover content={<span>Action</span>}>
          <span className="center">Action</span>
        </Popover>
      ),
      dataIndex: "action",
      width: "15%",
      render: (_, record) => {
        const { isLocked, type } = record;

        if (isLocked || type === "main_component")
          return <span className="gx-text-warning">Main component</span>;

        if (!editMode) return <div />;

        return (
          <Popconfirm
            title="Sure to delete?"
            onConfirm={() => handleDelete(record)}
          >
            <DeleteOutlined
              className="gx-mr-3 gx-pointer gx-text-danger"
              // twoToneColor="#f44336"
            />{" "}
          </Popconfirm>
        );
      },
      key: uuidv4(),
    },
  ];
};

/**
 * Database indexes to be saved.
 */
export const metadataDataIndexes = ["limitingReagent"];

/**
 * Calculates weight.
 */
export const calculateWeight = (
  id,
  experimentMetadata,
  lookupData,
  givenMol
) => {
  const mw = getMetadatafromLookup(id, lookupData, "molecularWeight");
  const mol = givenMol || calculateMol(id, experimentMetadata);
  const percent =
    getExperimentMetadata(id, "value", experimentMetadata) || 100.0;

  const weight = (Number(mw) * Number(mol) * 100.0) / Number(percent);

  return weight;
};

export const calculateMol = (id, experimentMetadata) => {
  // TODO: Include calculation of limiting reagent.
  const eq = getExperimentMetadata(id, "equivalent", experimentMetadata) || 0;
  const mol = Number(eq);
  return mol;
};
