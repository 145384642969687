import React, { forwardRef } from "react";
import { Descriptions, Divider, Table, Tag, Tooltip } from "antd";
import { v4 as uuidv4 } from "uuid";
import { InfoCircleTwoTone, UploadOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import _ from "lodash";
import pluralize from "pluralize";
import {
  filterDocumentDataByRole,
  getDocumentRoleId,
} from "../../../util/auth";

export const renderDocumentName = (
  p,
  { name, collectionOptions, documentCount = 0 }
) => {
  const documentName = (collectionOptions || []).filter(
    (item) => item?.value === name
  );

  return (
    <span className={`${p ? "gx-ml-2" : ""} document__name`}>
      {p && <UploadOutlined className="gx-mr-2" twoToneColor="#038FDE" />}
      {(documentName || []).length ? documentName[0]?.field : name}
      {documentCount ? (
        <span className="gx-ml-2 gx-text-success">
          ({documentCount} {pluralize("Document", documentCount)})
        </span>
      ) : null}
    </span>
  );
};

export const getField = ({ field, widgetOptions }) => {
  const rows = _.get(widgetOptions, "rows") || [];

  const foundField = (rows || []).filter((item) => item?.name === field);

  if (foundField && foundField.length) {
    return foundField[0];
  }

  return null;
};

const isKeyExists = (key, options) => {
  return _.findIndex(options, { name: key }) !== -1;
};

export const renderSafetyModal = (props) => {
  const { currentDocument, selectedDocument, sourceName, collectionOptions } =
    props;

  const widgetOptions =
    (
      _.find(collectionOptions, {
        value: currentDocument?.collection,
      }) || {}
    )?.widget || {};

  const metaItems = (currentDocument || {})?.searchSubjects || [];

  const tags = metaItems.filter((item) => item?.key === "tag");
  const owners = (currentDocument || {})?.owners || [];

  const listFields = _.filter(
    widgetOptions?.rows,
    (option) => option?.type === "LIST"
  );

  const tableLists = _.filter(
    widgetOptions?.rows,
    (option) => option?.type === "TABLE"
  );

  const metaSearchSubjects = metaItems.filter((item) => item?.key !== "tag");
  const metaSearchSubjectsFilteredByKey = _.filter(
    metaSearchSubjects,
    (item) =>
      !isKeyExists(item?.key, listFields) && !isKeyExists(item?.key, tableLists)
  );

  const metaSearchSubjectsFiltered = filterDocumentDataByRole(
    collectionOptions,
    currentDocument?.collection,
    metaSearchSubjectsFilteredByKey,
    getDocumentRoleId()
  );

  const unMappedData = metaSearchSubjects.filter(
    (item) =>
      !getField({ field: item?.key, widgetOptions })?.field &&
      item?.key !== "source" &&
      item?.key !== "externalDocuments" &&
      !isKeyExists(item?.key, listFields) &&
      !isKeyExists(item?.key, tableLists)
  );

  const lists = listFields.map((list) => {
    const values = _.filter(
      metaSearchSubjects,
      (subject) => subject?.key === list?.name
    );
    let valuesMap = [];
    _.forEach(values, (value) => {
      let currentValue = [];

      try {
        currentValue = JSON.parse(value?.value);
      } catch (error) {
        currentValue = value?.value;
      }

      if (_.isArray(currentValue)) {
        _.forEach(currentValue, (item) => {
          valuesMap.push(item);
        });
      } else {
        valuesMap.push(currentValue);
      }
    });

    return {
      ...list,
      values: valuesMap,
    };
  });

  const tables = tableLists.map((tableList) => {
    const values = _.filter(
      metaSearchSubjects,
      (subject) => subject?.key === tableList?.name
    );

    let dataSource = [];

    try {
      dataSource = JSON.parse(values[0]?.value);
    } catch (error) {}

    const columns = _.map(dataSource[0] || [], (item, key) => ({
      title: key,
      dataIndex: key,
      key: key,
    }));

    return {
      ...tableList,
      dataSource,
      columns,
    };
  });

  const sources = _.find(metaSearchSubjects, { key: "source" });

  let sourcesArray = [];

  try {
    sourcesArray = JSON.parse(JSON.parse(sources?.value));
  } catch (error) {}

  const renderDocumentItem = (documentItem, isUnmappedData = false) => {
    const fieldName = getField({
      field: documentItem?.key,
      widgetOptions,
    })?.field;

    if (!isUnmappedData && !fieldName) return null;

    return (
      <Descriptions.Item
        key={uuidv4()}
        className={fieldName ? "" : "not-found-item"}
        label={fieldName ? fieldName : documentItem?.key}
      >
        <span className={fieldName ? "" : "not-found-item"}>
          {documentItem?.value}
        </span>
        {!fieldName && (
          <Tooltip
            title={"Corresponding value is not found in current collection."}
          >
            <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
          </Tooltip>
        )}
      </Descriptions.Item>
    );
  };

  const renderDocumentTable = (items) => {
    const itemsWithKeys = items.map((item, index) => ({ ...item, key: index }));
    const columns = _.map(items[0], (col, colKey) => {
      return {
        title: colKey,
        dataIndex: colKey,
        key: colKey,
      };
    });

    return (
      <div>
        <Table
          size="small"
          pagination={false}
          dataSource={itemsWithKeys}
          columns={columns}
        />
      </div>
    );
  };

  return (
    <Descriptions size="small" bordered column={1} className="gx-mt-3">
      <Descriptions.Item label="Collection Name" span={5}>
        {renderDocumentName(null, {
          name: selectedDocument?.collection,
          collectionOptions: collectionOptions,
          documentCount: 0,
        })}
      </Descriptions.Item>
      {selectedDocument?.name ? (
        <>
          <Descriptions.Item label="Name">
            {selectedDocument?.name}
          </Descriptions.Item>
          <Descriptions.Item label="Accession Number">
            {selectedDocument?.accessionNumber}
          </Descriptions.Item>
          <Descriptions.Item label="URL">
            {selectedDocument?.uri}
          </Descriptions.Item>
          <Descriptions.Item label="File Name">
            <span>{selectedDocument?.fileName}</span>
          </Descriptions.Item>
        </>
      ) : (
        <>
          <Descriptions.Item label="File Name">
            {selectedDocument?.fileName ||
              selectedDocument?.name ||
              "name is empty"}
          </Descriptions.Item>
          <Descriptions.Item label="Accession Number">
            {selectedDocument?.accessionNumber}
          </Descriptions.Item>
        </>
      )}

      <Descriptions.Item label="Description">
        <span
          style={{ whiteSpace: "pre-line" }}
          dangerouslySetInnerHTML={{ __html: selectedDocument?.description }}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Entity Ids">
        {(selectedDocument?.entityIds || []).map((entityId, index) => (
          <Tag color="#2db7f5" style={{ whiteSpace: "break-spaces" }}>
            <Link
              key={index}
              to={`/${sourceName}/compound/${entityId}`}
              target="_blank"
              replace
            >
              {entityId}
            </Link>
          </Tag>
        ))}
      </Descriptions.Item>

      {/* Render Tags */}
      {(tags || []).length ? (
        <Descriptions.Item label="Tags">
          <div>
            {(tags || []).map((tag, index) => (
              <Tag
                key={index}
                color="#13c2c2"
                style={{ whiteSpace: "break-spaces" }}
              >
                {tag?.value}
              </Tag>
            ))}
          </div>
        </Descriptions.Item>
      ) : null}

      {/* Render Owners */}
      {(owners || []).length ? (
        <Descriptions.Item label="Owners">
          <div>
            {(owners || []).map((owner, index) => (
              <Tag
                key={index}
                color="#ffc069"
                style={{ whiteSpace: "break-spaces" }}
              >
                {owner}
              </Tag>
            ))}
          </div>
        </Descriptions.Item>
      ) : null}

      {_.map(lists, (list, key) => {
        const filteredValues = _.filter(
          list.values || [],
          (item) => !_.isNull(item)
        );

        return (
          <>
            {!_.isEmpty(filteredValues) ? (
              <Descriptions.Item label={list?.field} key={key}>
                <div>
                  {(filteredValues || []).map((tag, index) => (
                    <Tag
                      key={index}
                      color="#13c2c2"
                      style={{ whiteSpace: "break-spaces" }}
                    >
                      {tag}
                    </Tag>
                  ))}
                </div>
              </Descriptions.Item>
            ) : null}
          </>
        );
      })}

      {_.map(tables, (table, key) => {
        return (
          <>
            {!_.isEmpty(table?.dataSource) ? (
              <Descriptions.Item label={table?.field} key={key}>
                <div>
                  <Table
                    pagination={false}
                    size="small"
                    dataSource={table?.dataSource}
                    columns={table?.columns}
                  />
                </div>
              </Descriptions.Item>
            ) : null}
          </>
        );
      })}

      {_.map(metaSearchSubjectsFiltered, (documentItem) => {
        if (documentItem?.value) {
          return renderDocumentItem(documentItem);
        }
      })}
      {(sourcesArray || []).length ? (
        <Descriptions.Item span={2} label="Sources">
          {!_.isEmpty(sources) ? renderDocumentTable(sourcesArray) : null}
        </Descriptions.Item>
      ) : null}
      {(unMappedData || []).length ? <Divider>Unmapped Data</Divider> : null}
      {_.map(unMappedData, (documentItem) => {
        return renderDocumentItem(documentItem, true);
      })}
    </Descriptions>
  );
};

export const FileDetailComponent = forwardRef((props, ref) => {
  return <div ref={ref}>{renderSafetyModal({ ...props })}</div>;
});
