import axios from "axios";
import { config } from "../../constants/Config";
import { getSourceNameWithMap } from "../../util/api";
import { getImageFromCompoundId } from "./Search";
import { v4 as uuidv4 } from "uuid";
import { getImageUrlParams } from "../../util/url";

/**
 * Build a form from chemdraw drawing
 * @param {Object} form
 * @param {Boolean} loadingFn
 * @param {Function} setFn
 */
export const buildForm = async (form, loadingFn, setFn, selectedDataSource) => {
  const { ops, database, sourceNamePrefix } = form;

  loadingFn(true);

  if (ops.length) {
    const form = await ops.map(async (op) => {
      const queryParams = new URLSearchParams({
        sourceName: database,
      });

      const result = await axios.post(
        `${config.SRD_API_URL}/uniqueness?${queryParams}`,
        op?.smiles,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "text/plain",
          },
        }
      );
      if (result.data) {
        const searchResults = result.data?.exactMatch || [];

        if (searchResults.length > 0) {
          const foundCompoundId = searchResults.filter((item) =>
            (item || "").startsWith(sourceNamePrefix)
          );
          let compoundId = "";
          if (foundCompoundId.length > 0) {
            compoundId = foundCompoundId[0];
          }
          const substanceNumber = compoundId.split("-")[1];
          let image = "";
          try {
            image = await getImageFromCompoundId(
              compoundId,
              selectedDataSource,
              "transbg"
            );
          } catch (error) {}
          return {
            smiles: op?.smiles,
            role: op?.role,
            index: uuidv4(),
            imagePreview: image?.data,
            substanceNumber,
            compoundId,
            isFound: true,
          };
        } else {
          let image = "";
          try {
            image = await getImageFromSmilesApi(op?.smiles);
          } catch {}

          return {
            smiles: op?.smiles,
            role: op?.role,
            index: uuidv4(),
            imagePreview: image?.data,
            substanceNumber: null,
            compoundId: "",
            isFound: false,
          };
        }
      }
    });

    Promise.all(form)
      .then((results) => {
        setFn(results);
      })
      .finally(() => {
        loadingFn(false);
      });
  } else {
    // wait for initialize chemdraw editor
    setTimeout(() => {
      loadingFn(false);
      setFn([]);
    }, 2000);
  }
};

/**
 * Update smiles string when form input changes
 * @param {String} compoundId
 * @returns
 */
export const updateSmiles = async (compoundId, sourceNameMap) => {
  return axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/structure/${compoundId}?sourceName=${getSourceNameWithMap(
      compoundId,
      sourceNameMap
    )}&originalMaterial=true&format=smiles`
  );
};

/**
 * Register request if drawn component not found
 * @param {String} compoundId
 * @param {String} smiles
 * @returns
 */
export const registerUnknownComponent = async (
  smiles,
  dataSource,
  userName
) => {
  return axios.post(
    `${config.SRD_API_URL}/register?sourceName=${dataSource}&SRD_USER=${userName}`,
    smiles,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
      },
    }
  );
};

/**
 * Get image from smiles format
 * @param {String} smiles
 * @returns
 */
export const getImageFromSmiles = async (
  smiles,
  setFn,
  originalMaterial = true
) => {
  await axios
    .post(
      `${config.SCAS_API_URL}/ontology/image/svg?${getImageUrlParams(
        "transbg",
        300,
        300,
        originalMaterial
      )}`,
      smiles,
      {
        headers: {
          Accept: "image/*",
          "Content-Type": "*/*",
        },
      }
    )
    .then((result) => {
      setFn(result?.data);
    })
    .catch(() => {
      setFn(null);
    });
};

/**
 * Get image from smiles format
 * @param {String} smiles
 * @returns
 */
export const getImageFromSmilesApi = async (
  smiles,
  originalMaterial = true
) => {
  return await axios.post(
    `${config.SCAS_API_URL}/ontology/image/svg?${getImageUrlParams(
      "transbg",
      300,
      300,
      originalMaterial
    )}`,
    smiles,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "*/*",
      },
    }
  );
};

/**
 * RegisterProcess
 * @param {String} sourceName
 * @param {JSON} body
 * @param {Function} setFn callback function
 * @returns
 */
export const registerProcessApi = async (
  sourceName,
  body,
  setFn,
  setLoading,
  setMessageFn,
  notification,
  updateMode
) => {
  setLoading(true);

  if (updateMode) {
    await axios
      .put(`${config.SRD_API_URL}/process?sourceName=${sourceName}`, body, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => {
        if (result.data?.error) {
          notification?.error(result.data?.error);
        } else if (result?.data?.match) {
          setFn(result?.data?.match);
          setMessageFn(result?.data?.error);
        } else {
          setFn(result?.data?.processId);
          setMessageFn("Process Is Updated");
        }
      })
      .catch((err) => {
        if (notification) {
          notification?.error(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    await axios
      .post(
        `${config.SRD_API_URL}/registerProcess?sourceName=${sourceName}`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data?.error) {
          notification?.error(result.data?.error);
        } else if (result?.data?.match) {
          setFn(result?.data?.match);
          setMessageFn(result?.data?.error);
        } else {
          setFn(result?.data?.processId);
          setMessageFn("Process Is Registered");
        }
      })
      .catch((err) => {
        if (notification) {
          notification?.error(err?.response?.data?.message);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
};
