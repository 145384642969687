import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import { checkActionAllowed } from "../../../../util/auth";
import { Popover, Tooltip } from "antd";

const Header = ({
  title,
  titleColor,
  backgroundColor,
  isEditable,
  onSave,
  open,
  setOpen,
  loading,
  addMode,
  setAddMode,
  info = "",
  hasDeleteButton,
  onDelete,
  isDefaultMode,
  hideInfo,
  child,
  additionalChild,
  resetForm = () => {},
}) => {
  return (
    <div
      className={`ant-card-head widget-header ${
        isDefaultMode ? "" : "header-fixed"
      }`}
      key={uuidv4()}
      style={{ backgroundColor }}
    >
      <div className="ant-card-head-wrapper gx-d-flex justify-content-between">
        <div className="ant-card-head-title widget-header-title">
          <span style={{ color: titleColor }}>{title}</span>
          {additionalChild}
          {info.length && !hideInfo ? (
            <Tooltip title={info}>
              <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
            </Tooltip>
          ) : null}
        </div>
        <div className={`ant-card-extra toggle-button`}>
          {child}
          {isEditable && !loading && !addMode && checkActionAllowed() && (
            <Popover content="Edit widget">
              <EditTwoTone
                className="gx-pl-2"
                onClick={() => setAddMode(true)}
              />
            </Popover>
          )}
          {isEditable && !loading && checkActionAllowed() && addMode ? (
            <Popover content="Save widget">
              <SaveTwoTone
                className="gx-pl-2"
                twoToneColor="#52c41a"
                onClick={() => {
                  onSave();
                }}
              />
            </Popover>
          ) : null}
          {hasDeleteButton && !loading && (
            <Popover content="Delete widget">
              <DeleteTwoTone twoToneColor="#ba141a" onClick={onDelete} />
            </Popover>
          )}
          {isEditable && !loading && addMode && checkActionAllowed() && (
            <Popover content="Close widget">
              <CloseCircleTwoTone
                className="gx-pl-2"
                twoToneColor="#a94442"
                onClick={() => {
                  setAddMode(false);
                  resetForm();
                }}
              />
            </Popover>
          )}

          <i
            className={`icon gx-pl-2 ${
              open ? "icon-chevron-up" : "icon-chevron-down"
            }`}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
    </div>
  );
};

Header.defaultProps = {
  title: "",
  titleColor: "",
  backgroundColor: "transbg",
  isEditable: false,
  open: true,
  loading: false,
  addMode: false,
  info: "",
  hasDeleteButton: false,
  onDelete: () => {},
  isDefaultMode: true,
  hideInfo: false,
  child: null,
  additionalChild: null,
};

Header.propTypes = {
  title: PT.string,
  titleColor: PT.string,
  backgroundColor: PT.string,
  isEditable: PT.bool,
  onSave: PT.func.isRequired,
  open: PT.bool,
  setOpen: PT.func.isRequired,
  loading: PT.bool,
  addMode: PT.bool,
  setAddMode: PT.func.isRequired,
  info: PT.string,
  hasDeleteButton: PT.bool,
  onDelete: PT.func,
  isDefaultMode: PT.bool,
  hideInfo: PT.bool,
  child: PT.node,
  additionalChild: PT.node,
};

export default Header;
