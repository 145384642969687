import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_REQUESTED,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUESTED,
  DELETE_PROJECT_SUCCESS,
  FETCH_STEP_NO_IMAGES_REQUESTED,
  FETCH_STEP_NO_IMAGES_SUCCESS,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_REQUESTED,
  GET_PROJECTS_SUCCESS,
  PUT_PROJECT_FAILED,
  PUT_PROJECT_REQUESTED,
  PUT_PROJECT_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "axios";
import { config } from "../../constants/Config";
import { NotificationManager } from "react-notifications";
/**
 * Get all projects request API
 * @param {String} sourceName source Name
 */
const getProjectsRequest = async ({ sourceName }) => {
  return axios.get(
    `${config.SRD_API_URL}/projects?source%20name=${sourceName}`
  );
};

/**
 * delete project request API
 * @param {String} sourceName source Name
 * @param {String} projectId project id
 */
const deleteProjectRequest = async ({ sourceName, projectId }) => {
  return axios.delete(
    `${config.SRD_API_URL}/project/${projectId}?source%20name=${sourceName}`
  );
};

/**
 * create project request API
 * @param {String} sourceName source Name
 * @param {String} projectId project id
 * @param {userName} user name
 */
const createProjectRequest = async ({
  sourceName,
  projectId,
  userName = null,
}) => {
  const body = {
    name: projectId,
  };

  return axios.post(
    `${config.SRD_API_URL}/project?sourceName=${sourceName}&SRD_USER=${userName}`,
    JSON.stringify(body),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * Send PUT request to projects API
 * @param {String} sourceName source name
 * @param {String} projectId project id
 * @param {Array<Object>} data data
 */
const putProjectRequest = async ({ sourceName, projectId, data, userName }) => {
  return axios.put(
    `${config.SRD_API_URL}/project/${projectId}?source%20name=${sourceName}&SRD_USER=${userName}`,
    JSON.stringify(data),
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

export const getProcessRequest = async ({ sourceName, processId }) =>
  axios.get(
    `${config.SRD_API_URL}/process?id=${processId}&source%20name=${sourceName}`
  );

const getImageRequest = async ({ sourceName, rmoId }) =>
  axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/image/svg/${rmoId}?originalMaterial=${false}&sourceName=${sourceName}`,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "image/svg+xml",
      },
    }
  );

function* fetchAllProjects(action) {
  try {
    const result = yield call(getProjectsRequest, action.payload);
    if (result.data) {
      yield put({
        type: GET_PROJECTS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_PROJECTS_FAILED,
    });
  }
}

function* fetchDeleteProject(action) {
  try {
    const result = yield call(deleteProjectRequest, action.payload);
    if (result.data) {
      yield put({
        type: DELETE_PROJECT_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: DELETE_PROJECT_FAILED,
    });
  }
}

function* fetchCreateProject(action) {
  try {
    const result = yield call(createProjectRequest, action.payload);
    if (result.data) {
      yield put({
        type: CREATE_PROJECT_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: CREATE_PROJECT_FAILED,
    });
  }
}

function* fetchPutProject(action) {
  try {
    const result = yield call(putProjectRequest, action.payload);
    if (result.data) {
      yield put({
        type: PUT_PROJECT_SUCCESS,
        payload: result.data,
      });

      NotificationManager.success("Successfully updated project.");
    }
  } catch (error) {
    NotificationManager.error(
      error?.response?.data?.message || "Error occurred while updating project."
    );

    yield put({
      type: PUT_PROJECT_FAILED,
    });
  }
}

function* fetchImages(action) {
  try {
    const result = yield call(getProcessRequest, action.payload);

    if (result.data && result.data.rmoId) {
      const image = yield call(getImageRequest, {
        sourceName: action.payload.sourceName,
        rmoId: result.data.rmoId,
      });

      if (image.data) {
        yield put({
          type: FETCH_STEP_NO_IMAGES_SUCCESS,
          payload: {
            processId: action.payload.processId,
            image: image.data,
          },
        });
      }
    }
  } catch (error) {}
}

export function* getAllProjects() {
  yield takeLatest(GET_PROJECTS_REQUESTED, fetchAllProjects);
}

export function* deleteProject() {
  yield takeLatest(DELETE_PROJECT_REQUESTED, fetchDeleteProject);
}

export function* createProject() {
  yield takeLatest(CREATE_PROJECT_REQUESTED, fetchCreateProject);
}

export function* putProject() {
  yield takeLatest(PUT_PROJECT_REQUESTED, fetchPutProject);
}

export function* getImagesByRmoId() {
  yield takeEvery(FETCH_STEP_NO_IMAGES_REQUESTED, fetchImages);
}

export default function* rootSaga() {
  yield all([
    fork(getAllProjects),
    fork(deleteProject),
    fork(createProject),
    fork(putProject),
    fork(getImagesByRmoId),
  ]);
}
