import { InfoCircleTwoTone, LinkOutlined } from "@ant-design/icons";
import { Popover, Tag, Tooltip } from "antd";
import React, { useRef } from "react";
import InputTag from "../../../InputTag";
import _ from "lodash";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const RowList = ({
  info = "",
  index,
  field,
  name,
  value,
  addmode,
  form,
  sourceName,
  onChange,
  isEditable,
  isAccessible,
  hasLink = false,
  baseUrl,
}) => {
  const inputTagRef = useRef();

  let defaultValue = "";
  try {
    defaultValue = JSON.parse(value);
  } catch (error) {}

  const onTagUpdated = () => {
    onChange(inputTagRef.current.changeVal());
  };

  // If value is not editable and value is empty, return null.
  if (!isEditable && !value) {
    return null;
  }

  if (!isAccessible) {
    return null;
  }

  const renderLink = (item, baseUrl) => {
    const targetUrl = (baseUrl || "").replace(/:shortId/g, item);

    return (
      <Link
        className="gx-text-primary gx-text-sm"
        to={{ pathname: targetUrl }}
        target="_blank"
      >
        <Tag color="blue">{item}</Tag>
      </Link>
    );
  };

  return (
    <>
      {addmode && isEditable ? (
        <div className={"card-item"} key={`editable-input-${index}`}>
          <div className="card-item-field" style={{ marginRight: "10px" }}>
            <span>{field}</span>
            {info.length ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
            {hasLink ? (
              <Tooltip content="Displayed items are hyperlinks.">
                <LinkOutlined />
              </Tooltip>
            ) : null}
          </div>
          <div className="card-item-value full-width-tag">
            <InputTag
              sourceName={sourceName}
              defaultValues={defaultValue || []}
              ref={inputTagRef}
              onTagUpdated={onTagUpdated}
            />
          </div>
        </div>
      ) : (
        <>
          {_.isEmpty(defaultValue) ? null : (
            <div className={"card-item"} key={`editable-input-${index}`}>
              <div className="card-item-field" style={{ marginRight: "10px" }}>
                <span>{field}</span>
                {info.length ? (
                  <Tooltip title={info}>
                    <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
                  </Tooltip>
                ) : null}
                {hasLink ? (
                  <Tooltip title="Displayed items are hyperlinks.">
                    <LinkOutlined className="gx-ml-2 gx-pointer" />
                  </Tooltip>
                ) : null}
              </div>
              <div className="g-d-flex">
                {_.map(defaultValue, (item) => {
                  if (hasLink && baseUrl) {
                    return renderLink(item, baseUrl);
                  }

                  return <Tag color="#87d068">{item}</Tag>;
                })}
              </div>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default RowList;
