import { v4 as uuidv4 } from "uuid";
import { DeleteTwoTone, EditTwoTone } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Popover,
  Switch,
  Table,
} from "antd";
import _ from "lodash";
import React, { useState } from "react";

const ResourceLinksSettings = ({
  resourceLinks = [],
  roles = [],
  onAddResourceLinks,
  onUpdateResourceLinks,
}) => {
  const [showModal, setShowModal] = useState(false);

  const [form, setForm] = useState({});

  const onChangeValue = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.name)
        ? currentRole[item?.name]
        : false;
      role[item?.name] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  const renderRoles = (p, { isEditable = {} }) => {
    let haveAccess = [];
    for (const [key, value] of Object.entries(isEditable)) {
      if (value === true) {
        haveAccess.push(key);
      }
    }

    return <div>{(haveAccess || []).join(",")}</div>;
  };

  const onRemoveLink = (record) => {
    const newLinks = _.filter(
      resourceLinks,
      (link) => !_.isEqual(record, link)
    );

    onUpdateResourceLinks(newLinks);
  };

  const renderActions = (p, record) => {
    return (
      <>
        <Popover content="Edit component">
          <EditTwoTone
            className="gx-ml-2"
            twoToneColor="#52c41a"
            onClick={() => {
              setShowModal(true);
            }}
          />
        </Popover>
        <Popconfirm
          title="Are you sure to delete?"
          onConfirm={() => onRemoveLink(record)}
          okText="Yes"
          cancelText="No"
        >
          <DeleteTwoTone
            className="gx-mt-2 gx-ml-2 gx-pointer"
            twoToneColor="red"
          />
        </Popconfirm>
      </>
    );
  };

  return (
    <>
      <div>
        <Table
          dataSource={resourceLinks}
          columns={[
            { title: "Name", dataIndex: "name" },
            {
              title: "URL",
              dataIndex: "url",
            },
            {
              title: "Access Roles",
              dataIndex: "roles",
              editable: true,
              mode: "multiple",
              render: renderRoles,
              responsive: ["sm"],
            },
            {
              title: "Actions",
              render: renderActions,
            },
          ]}
        />

        <Button
          className="gx-pointer gx-btn-primary gx-mt-2"
          size="small"
          onClick={() => {
            setShowModal(true);
            setForm({
              id: uuidv4(),
            });
          }}
        >
          Add New Resource
        </Button>
      </div>

      <Drawer
        placement="right"
        onClose={() => setShowModal(false)}
        open={showModal}
        destroyOnClose
        autoFocus
        title={"Add new Resource"}
      >
        <div className="gx-ml-2">
          <Form>
            <Form.Item
              name="name"
              label="Enter name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "name should not be empty",
                },
              ]}
            >
              <Input
                defaultValue={form?.name}
                value={form?.name}
                onChange={(e) => onChangeValue("name", e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="description"
              label="Enter Description"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "description should not be empty",
                },
              ]}
            >
              <Input
                defaultValue={form?.description}
                value={form?.description}
                onChange={(e) => onChangeValue("description", e.target.value)}
              />
            </Form.Item>

            <Form.Item
              name="url"
              label="Enter URL"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "url should not be empty",
                },
              ]}
            >
              <Input
                defaultValue={form?.url}
                value={form?.url}
                onChange={(e) => onChangeValue("url", e.target.value)}
              />
            </Form.Item>

            <Divider />

            <div>
              <p className="gx-mb-3">Edit Roles:</p>
              <Col className="gx-d-flex">
                {roles.map((role) => (
                  <Form.Item
                    className="gx-mr-1"
                    id={role.id}
                    label={role.name}
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                  >
                    <Switch
                      checked={_.get(form?.isEditable, role?.name) || false}
                      onChange={(e) => onChangeRole(role.name, e)}
                    />
                  </Form.Item>
                ))}
              </Col>
            </div>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                size="small"
                onClick={() => {
                  onAddResourceLinks(form);
                  setShowModal(false);
                }}
              >
                Submit
              </Button>
              <Button size="small" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    </>
  );
};

ResourceLinksSettings.propTypes = {};

export default ResourceLinksSettings;
