/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Divider,
  Drawer,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Select,
} from "antd";
import {
  fetchLookupData,
  fetchStepNoImages,
  fetchWidgetData,
  getDataSources,
  getProjects,
  putProject,
  saveMetadata,
  searchParameters,
  setCurrentWidgets,
} from "../../appRedux/actions";
import {
  FilePptOutlined,
  LeftCircleTwoTone,
  MenuOutlined,
  PlusCircleOutlined,
  ProjectOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
} from "react-sortable-hoc";
import { SortableHandle } from "react-sortable-hoc";

import _ from "lodash";
import { getProcessDetail } from "../../appRedux/services/Metadata";

import "./style.css";
import {
  DEFAULT_PROJECT_NAME_COUNTER,
  PROJECT_DATA_WIDGET,
  DEFAULT_SOURCE_NAME,
  PROJECT_PAGE_SETTINGS,
  DEFAULT_PAGE_SIZE,
} from "../../constants/Config";
import { EXPLORE_PAGE_TAB_OPTIONS } from "../../constants/Config";
import { filterByAuthorizedRoles } from "../../util/search";
import {
  getSystemWidget,
  getUserWidget,
} from "../../appRedux/services/SystemWidget";
import { checkRowIsAccessible, getRole } from "../../util/auth";
import { useMsal } from "@azure/msal-react";
import { createProject } from "../../appRedux/services/Project";
import {
  getCounter,
  saveDefaultCounter,
} from "../../appRedux/services/Document";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import AppModuleHeader from "../../components/AppModuleHeader";
import ContactList from "../../components/Contact/ContactList";
import CustomScrollbars from "../../util/CustomScrollbars";
import ContactCell from "../../components/Contact/ContactList/ContactCell";
import SearchResult from "./SearchResults";
import { getProcessRequest } from "../../appRedux/sagas/Project";
import { options } from "less";
import {
  populateImageMapping,
  renderExplorePageCompoundTable,
} from "../../components/ExplorePage/helper";
import { paginate } from "../../util/generic";

const ProjectPage = () => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const processFormRef = useRef();
  const experimentFormRef = useRef();
  const { loading, projects = [] } = useSelector(({ project }) => project);
  const { dataSources } = useSelector(({ search }) => search);
  const { accounts } = useMsal();

  const x = 3;
  const y = 2;
  const z = 1;
  const defaultData = [];

  const [expandedKeys, setExpandedKeys] = useState([]);

  const [selectedSourceName, setSelectedSourceName] = useState(null);
  const [pageLoading, setPageLoading] = useState(false);
  const [compoundDetail, setCompoundDetail] = useState({});
  const [projectModal, setProjectModal] = useState(false);
  const [processModal, setProcessModal] = useState(false);

  const [currentProject, setCurrentProject] = useState({});

  const [experimentModal, setExperimentModal] = useState(false);
  const [currentProcessId, setCurrentProcessId] = useState();
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [orderProcessModal, setOrderProcessModal] = useState(false);
  const [orderProcesses, setOrderProcesses] = useState([]);
  const [selectedExp, setSelectedExp] = useState();
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [defaultProjectName, setDefaultProjectName] = useState("");

  const [experiments, setExperiments] = useState([]);
  const [experimentOptions, setExperimentOptions] = useState([]);

  const [sortMode, setSortMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentSearchInput, setCurrentSearchInput] = useState("");
  const [processSteps, setProcessSteps] = useState([]);
  const [currentIds, setCurrentIds] = useState([]);

  const [projectSettings, setProjectSettings] = useState([]);

  const roles = useGetRoles();

  const {
    loading: loadingTable,
    tableData,
    structuresTableData = [],
    widgetIdsNormalized,
  } = useSelector(({ explore }) => explore);

  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentSelection, setCurrentSelection] = useState();
  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState(null);
  const [mapImages, setMapImages] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const searchInput = useRef(null);

  let form = {};

  const userName = `${accounts[0]?.username}-${getRole()}`;

  useEffect(() => {
    getSystemWidget(PROJECT_PAGE_SETTINGS, setProjectSettings);
  }, []);

  const optionsWithRole = _.filter(projectSettings, (option) =>
    checkRowIsAccessible(option?.isAccessible)
  );

  const widgetNamesToResolve = _.uniq(
    _.map(
      _.filter(optionsWithRole, (role) => role?.widget),
      (i) => i?.widget
    )
  );

  useEffect(() => {
    _.forEach(processSteps, (ps) => {
      _.forEach(widgetNamesToResolve, (widgetName) => {
        dispatch(
          fetchLookupData({
            id: ps?.processId,
            sourceName: selectedSourceName,
            widgetName,
            isProcess: true,
          })
        );
      });
    });
  }, [processSteps]);

  useEffect(() => {
    // exclude already added experiments.
    let exp = [];
    _.forEach(projects, (project) => {
      const { processSteps = [] } = project || {};

      _.forEach(processSteps, (processStep) => {
        const { experiments = [] } = processStep || {};

        _.forEach(experiments, (experiment) => {
          if (experiment?.experimentId) {
            exp.push(experiment?.experimentId);
          }
        });
      });
    });

    const filteredExperiments = _.filter(
      experiments,
      (experiment) => !exp.includes(experiment?.name)
    );

    setExperimentOptions(filteredExperiments);
  }, [experiments]);

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userName,
      (defaultOp) => {
        setDefaultProjectName(defaultOp?.projectName);

        if (defaultOp?.project) {
          setSelectedSourceName(defaultOp?.project);
          dispatch(getProjects(defaultOp?.project));
        }
      },
      false
    );
  }, []);

  const pageName = "projects";

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: pageName,
        database: "",
      })
    );
  }, []);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }
  }, []);

  useEffect(() => {
    if (
      selectedSourceName &&
      currentProcessId &&
      dataSources.length &&
      _.isEmpty(compoundDetail)
    ) {
      getProcessDetail(
        currentProcessId,
        selectedSourceName,
        setCompoundDetail,
        () => {},
        setPageLoading,
        "process"
      );
    }
  }, [selectedSourceName, currentProcessId]);

  useEffect(() => {
    if (processSteps && (processSteps || []).length) {
      _.forEach(processSteps || [], (processStep) => {
        dispatch(fetchStepNoImages(selectedSourceName, processStep?.processId));
      });
    }
  }, [processSteps]);

  const onChangeValue = (field, value) => {
    form = {
      ...form,
      [field]: value,
    };
  };

  useEffect(() => {
    const ids = _.map(tableData, (item) => item?.compoundId);
    setCurrentIds(ids);
  }, [tableData]);

  useEffect(() => {
    if ((dataSources || []).length && (widgetIdsNormalized || []).length) {
      const fil = _.filter(
        widgetIdsNormalized,
        (item) => item?.type !== "system" || item?.type !== "user"
      );
      const paginated = paginate(fil, perPage, page);

      _.forEach(paginated, (widget, index) => {
        if (widget?.type && !_.isEmpty(widget?.id)) {
          dispatch(
            fetchWidgetData({
              widget,
              total: (paginated || []).length,
              currentIndex: index,
              availableWidgets: [],
              role: getRole(),
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, dataSources, page, perPage, widgetIdsNormalized]);

  useEffect(() => {
    if ((tableData || []).length && !loadingTable) {
      populateImageMapping({
        currentPage: page,
        pageSize: perPage,
        searchResults: tableData,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
        isEntityIds: page === "documents",
      });
    }
  }, [tableData, page, perPage, loadingTable, mapImages, setMapImages]);

  const mappedData = projects.map((item) => {
    const { processSteps = [] } = item;
    let children = null;

    const sortedSteps = _.sortBy(processSteps, ["stepNo"]);

    if (sortedSteps.length) {
      children = sortedSteps.map((step) => {
        const { experiments = [] } = step;
        let grandChildren = null;

        if (experiments.length) {
          grandChildren = experiments.map((exp) => {
            return {
              key: `${item?.id}-${step?.stepNo}-${exp?.experimentId}`,
              title: exp?.experimentId,
              id: exp?.experimentId,
              type: "experiment",
            };
          });
        }

        if (grandChildren) {
          return {
            key: `${item?.id}-${step?.stepNo}`,
            title: step?.processId,
            id: step?.processId,
            children: grandChildren,
            projectId: item?.projectId,
            isProcess: true,
            stepNo: step?.stepNo,
            type: "process",
          };
        } else {
          return {
            key: `${item?.id}-${step?.stepNo}`,
            title: step?.processId,
            id: step?.processId,
            isProcess: true,
            projectId: item?.projectId,
            stepNo: step?.stepNo,
            type: "process",
          };
        }
      });
    }

    if (children) {
      return {
        key: item?.id,
        title: item?.name,
        children,
        id: item?.projectId,
        isProject: true,
        type: "project",
      };
    } else {
      return {
        key: item?.id,
        title: item?.name,
        id: item?.projectId,
        isProject: true,
        type: "project",
      };
    }
  });

  const onCreateProcess = () => {
    setProcessModal(true);
  };

  const availableDataSources = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "projectRegistrationDatabases"
  );

  useEffect(() => {
    if ((availableDataSources || []).length === 1) {
      setSelectedSourceName(availableDataSources[0]?.sourceName);
      dispatch(getProjects(availableDataSources[0]?.sourceName));
    }
  }, [dataSources, roles, selectedSourceName]);

  /**
   * Action to take when deleting experiment.
   * @param {Object} p process to delete
   * @param {Object} exp experiment to delete
   */
  const onDeleteExperiment = (p, exp) => {
    const newProcessSteps = _.map(currentProject?.processSteps || [], (ps) => {
      if (ps?.processId === p?.processId) {
        const newExperiments = _.filter(
          ps?.experiments || [],
          (psExperiment) => psExperiment?.experimentId !== exp?.experimentId
        );

        return {
          ...ps,
          experiments: newExperiments,
        };
      } else {
        return ps;
      }
    });

    const newProject = {
      ...currentProject,
      processSteps: newProcessSteps,
    };

    dispatch(
      putProject(
        selectedSourceName,
        currentProject?.projectId,
        newProject,
        accounts[0]?.username
      )
    );

    setCurrentProject(newProject);
  };

  /**
   * Action to take when deleting experiment.
   * @param {Object} p process to delete
   */
  const onDeleteProcess = (p) => {
    const newProcessSteps = _.filter(
      currentProject?.processSteps || [],
      (ps) => ps?.processId !== p?.processId
    );

    const newProject = {
      ...currentProject,
      processSteps: newProcessSteps,
    };

    dispatch(
      putProject(
        selectedSourceName,
        currentProject?.projectId,
        newProject,
        accounts[0]?.username
      )
    );

    setCurrentProject(newProject);
  };

  const onAddNewProject = () => {
    if (!(form?.name || "").length) {
      formRef.current.setFields([
        {
          name: "name",
          errors: ["Name cannot be empty."],
        },
      ]);
      return;
    }

    getCounter((counterData) => {
      const prefix =
        formRef.current.getFieldValue("prefix") || defaultProjectName || "";
      let prefixCounter = _.get(counterData, prefix) || 1;

      const name = `${prefix}-${prefixCounter} ${form?.name}`;
      const description = formRef.current.getFieldValue("description");
      const isExist = _.findIndex(projects, ["name", name]);

      if (isExist !== -1) {
        formRef.current.setFields([
          {
            name: "name",
            errors: [`Project ${name} already exists.`],
          },
        ]);
        return;
      }

      createProject(
        selectedSourceName,
        name,
        description || "",
        (res) => {
          const projectId = res?.data?.projectId;

          dispatch(
            saveMetadata(
              projectId,
              {
                registrar: accounts[0]?.username,
                registrationType: "interactive",
                registrationDate: new Date(),
                projectName: res?.data?.name,
              },
              PROJECT_DATA_WIDGET,
              selectedSourceName,
              "project",
              accounts[0].username
            )
          );

          dispatch(getProjects(selectedSourceName));

          saveDefaultCounter(
            () => {},
            counterData,
            prefixCounter + 1,
            prefix,
            DEFAULT_PROJECT_NAME_COUNTER
          );
        },
        accounts[0]?.username
      );
      setProjectModal(false);
      form = {};
    }, DEFAULT_PROJECT_NAME_COUNTER);
  };

  const renderAddProjectModal = () => (
    <div className="gx-ml-5 gx-mr-4 gx-mt-3">
      <Form className="gx-p-2" ref={formRef}>
        <Form.Item name="prefix" label="Prefix">
          <Input
            name="prefix"
            placeholder="Enter prefix"
            defaultValue={defaultProjectName}
            onChange={(e) => onChangeValue("prefix", e.target.value)}
          />
        </Form.Item>
        <Form.Item
          name="name"
          label="Project name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
          ]}
        >
          <Input
            placeholder="Enter name"
            name="name"
            value={form?.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
            onPressEnter={onAddNewProject}
          />
        </Form.Item>
        <Form.Item name="description" label="Project description">
          <Input
            placeholder="Enter description"
            name="description"
            value={form?.description}
            onChange={(e) => onChangeValue("description", e.target.value)}
          />
        </Form.Item>

        <Form.Item>
          <Divider />
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="small" onClick={onAddNewProject}>
            Save
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => setProjectModal(false)}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const onAddProcess = () => {
    const { processId } = form;
    const { name } = currentProject;

    const steps = [
      ...processSteps,
      {
        processId,
        stepNo: 0,
        experiments: [],
      },
    ];

    const newSteps = steps.map((item, index) => ({
      ...item,
      stepNo: index + 1,
    }));

    const data = {
      name,
      processSteps: newSteps,
    };

    dispatch(
      putProject(
        selectedSourceName,
        currentProject?.projectId,
        data,
        accounts[0]?.username
      )
    );
    setProcessModal(false);
    setCurrentProcessId(null);
  };

  const renderProcessModal = (
    <div className="gx-ml-5 gx-mr-4 gx-mt-3">
      <Form className="gx-p-2" ref={processFormRef}>
        <Form.Item name="processId" label="Process id" required>
          <Input
            placeholder="Enter process id"
            name="processId"
            value={form?.processId}
            onChange={(e) => onChangeValue("processId", e.target.value)}
            onPressEnter={onAddProcess}
          />
        </Form.Item>
        <Form.Item>
          <Divider />
        </Form.Item>
        <Form.Item name="actions">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              onAddProcess();
              setCurrentProcessId(null);
              setButtonDisabled(true);
            }}
          >
            Save
          </Button>
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              setProjectModal(false);
              setCurrentProcessId(null);
              setButtonDisabled(true);
              setProcessModal(false);
            }}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const onAddExperiment = () => {
    let isExperimentExists = false;

    _.forEach(projects, (project) => {
      _.forEach(project?.processSteps || [], (pStep) => {
        _.forEach(pStep?.experiments || [], (exp) => {
          if (exp?.experimentId === selectedExp) {
            isExperimentExists = true;
          }
        });
      });
    });

    if (isExperimentExists) {
      experimentFormRef.current?.setFields([
        {
          name: "experimentId",
          errors: [`Experiment already added.`],
        },
      ]);
      return;
    } else {
      const currentProcess = processSteps.find(
        (item) => item?.processId === currentProcessId
      );

      const { experiments = [] } = currentProcess || {};

      const newExperiment = [
        ...experiments,
        {
          experimentId: selectedExp,
        },
      ];

      const newProcesses = processSteps.map((item) => {
        if (item?.processId === currentProcessId) {
          return {
            ...item,
            experiments: newExperiment,
          };
        }
        return item;
      });

      const data = {
        name: currentProject?.name,
        stepNo: currentProcess?.stepNo,
        processSteps: newProcesses,
      };

      dispatch(
        putProject(
          selectedSourceName,
          currentProject?.projectId,
          data,
          accounts[0]?.username
        )
      );
      setExperimentModal(false);
    }
  };

  const renderExperimentModal = () => {
    let allExperiments = [];
    const { processSteps = [] } = currentProject || {};

    processSteps.forEach((step) => {
      const { experiments = [] } = step;
      allExperiments = [...allExperiments, ...experiments];
    });
    const availableExperiments = (compoundDetail?.experiments || []).filter(
      (exp) => {
        const ind = allExperiments.findIndex(
          (item) => item?.experimentId === exp?.name
        );

        if (ind === -1) {
          return exp;
        }
      }
    );

    return (
      <div className="gx-ml-5 gx-mr-4 gx-mt-3">
        {/* {availableExperiments.length > 0 ? ( */}
        <Form className="gx-p-2" ref={experimentFormRef}>
          {/* <Select
                name="experimentId"
                placeholder="Select process id"
                onChange={(e) => {
                  onChangeValue("experimentId", e);
                  setSelectedExp(e);
                  setButtonDisabled(false);
                }}
              >
                {availableExperiments.map((exp) => (
                  <Select.Option key={exp?.name} value={exp?.name}>
                    {exp?.name}
                  </Select.Option>
                ))}
              </Select> */}
          <Form.Item name="experimentId" label="Experiment id" required>
            <Select
              placeholder="Selct experiment"
              name="experimentId"
              value={form?.experimentId}
              onChange={(e) => {
                onChangeValue("experimentId", e);
                setSelectedExp(e);
                setButtonDisabled(false);
                experimentFormRef.current?.setFields([
                  {
                    name: "experimentId",
                    errors: [],
                  },
                ]);
              }}
              options={_.map(experimentOptions, (op) => ({
                label: op?.name,
                value: op?.name,
              }))}
            />
            {/* <Input
              placeholder="Enter experiment id"
              name="experimentId"
              value={form?.experimentId}
              onChange={(e) => {
                onChangeValue("experimentId", e.target.value);
                setSelectedExp(e.target.value);
                setButtonDisabled(false);
                experimentFormRef.current?.setFields([
                  {
                    name: "experimentId",
                    errors: [],
                  },
                ]);
              }}
            /> */}
          </Form.Item>

          <Form.Item>
            <Divider />
          </Form.Item>

          <Form.Item name="actions">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onAddExperiment();
                form = {};
              }}
            >
              Save
            </Button>
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                setExperimentModal(false);
                setCurrentProcessId(null);
                setButtonDisabled(true);
                form = {};
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
        {/* ) : ( */}
        {/* <span>No unassigned experiments available.</span> */}
        {/* )} */}
      </div>
    );
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newSteps = arrayMove(orderProcesses, oldIndex, newIndex);
      setOrderProcesses(newSteps);
    }
  };

  const DragHandle = SortableHandle(() => (
    <span className="gx-pointer gx-mr-2">
      <MenuOutlined className="gx-fs-lg" />
    </span>
  ));

  const SortableItem = SortableElement(({ step, index }) => (
    <List.Item className="gx-mr-5" key={index} style={{ padding: 0 }}>
      <ContactCell key={index} contact={step} sourceName={selectedSourceName} />
      <DragHandle />
    </List.Item>
  ));

  const SortableList = SortableContainer(() => {
    return (
      <>
        {orderProcesses.length > 0 ? (
          <div>
            {orderProcesses.map((step, index) => (
              <List
                bordered
                key={index}
                className="process-sort gx-ml-4 gx-mr-5 gx-mb-2"
              >
                <SortableItem key={`step-${index}`} index={index} step={step} />
              </List>
            ))}
          </div>
        ) : (
          <span>No processes to sort.</span>
        )}
      </>
    );
  });

  const renderOrderProcessModal = () => {
    return <SortableList helperClass="sortableHelper" onSortEnd={onSortEnd} />;
  };

  const saveOrder = () => {
    const newSteps = (orderProcesses || []).map((item, index) => ({
      ...item,
      stepNo: index + 1,
    }));

    const data = {
      name: currentProject?.name,
      processSteps: newSteps,
    };

    dispatch(
      putProject(
        selectedSourceName,
        currentProject?.projectId,
        data,
        accounts[0]?.username
      )
    );
    setOrderProcessModal(false);
    setCurrentProcessId(null);
  };

  const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
      const node = tree[i];
      if (node.children) {
        if (node.children.some((item) => item.key === key)) {
          parentKey = node.key;
        } else if (getParentKey(key, node.children)) {
          parentKey = getParentKey(key, node.children);
        }
      }
    }
    return parentKey;
  };

  const onChange = (e) => {
    const value = (e.target?.value || "").toLowerCase();

    let newExpandedKeys = [];

    _.forEach(mappedData, (item) => {
      if (item.children) {
        _.forEach(item.children, (childrenItem) => {
          if (childrenItem.children) {
            _.forEach(childrenItem.children, (grandChildrenItem) => {
              if (
                (grandChildrenItem.title || "").toLowerCase().includes(value)
              ) {
                newExpandedKeys.push(grandChildrenItem.key);
              }
            });
          }

          if ((childrenItem.title || "").toLowerCase().includes(value)) {
            newExpandedKeys.push(childrenItem.key);
          }
        });
      }

      if ((item.title || "").toLowerCase().includes(value)) {
        newExpandedKeys.push(item.key);
      }
    });

    setExpandedKeys(newExpandedKeys);
    setSearchValue(value);
    setAutoExpandParent(true);
  };

  const onExpand = (newExpandedKeys) => {
    setAutoExpandParent(false);
    setExpandedKeys(newExpandedKeys);
  };

  const loop = (data) =>
    data.map((item) => {
      const strTitle = item.title || "";
      const index = (strTitle || "")
        .toLowerCase()
        .indexOf((searchValue || "").toLowerCase());
      const beforeStr = strTitle.substring(0, index);
      const afterStr = strTitle.slice(index + searchValue.length);
      let found = false;
      const title =
        index > -1 ? (
          <span>
            {beforeStr}
            <span className="gx-text-warning">{searchValue}</span>
            {afterStr}
          </span>
        ) : (
          <span>{strTitle}</span>
        );
      if (item.children) {
        return {
          ...item,
          title,
          key: item.key,
          children: loop(item.children),
          found: index > -1,
        };
      }

      return {
        ...item,
        title,
        found: index > -1,
      };
    });
  const treeData = loop(mappedData);

  const filteredData = _.filter(treeData, (td) => {
    let found = false;
    if (td?.found) {
      found = true;
    }

    _.forEach(td?.children || [], (childrenTd) => {
      if (childrenTd?.found) {
        found = true;
      }

      _.forEach(childrenTd?.children || [], (grandChildrenTd) => {
        if (grandChildrenTd?.found) {
          found = true;
        }
      });
    });

    return found;
  });

  useEffect(() => {
    const currentProcessSteps = _.isEmpty(currentProject)
      ? []
      : _.find(projects, { id: currentProject?.id })?.processSteps || [];

    setProcessSteps(currentProcessSteps);
  }, [currentProject, projects]);

  const filteredProjects = _.filter(projects, (project) => {
    const { processSteps = [] } = project;
    let processFound = false;

    if (_.isEmpty(currentIds)) return true;

    if (currentIds.includes(project?.projectId)) {
      return true;
    }

    for (const step of processSteps) {
      if (currentIds.includes(step?.processId)) {
        return true;
      }
    }

    return processFound;
  });

  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(268);

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      if (isResizing) {
        setSidebarWidth(
          mouseMoveEvent.clientX -
            sidebarRef.current.getBoundingClientRect().left
        );
      }
    },
    [isResizing]
  );

  React.useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  return (
    <>
      <div>
        <div
          className="gx-profile-banner sticky-header-component"
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: "80px",
          }}
        >
          <div className="gx-d-flex compound-detail-left-content">
            <h3>Project Page</h3>
            <div className="gx-d-flex gx-mt-2 gx-mr-5">
              <>
                {!selectedSourceName && availableDataSources.length === 1 ? (
                  <Form.Item label="Data Source" className="gx-ml-2 gx-mr-2">
                    <span className="gx-text-success">
                      {availableDataSources[0].sourceName}
                    </span>
                  </Form.Item>
                ) : (
                  <>
                    {_.isNull(selectedSourceName) &&
                    !_.isEmpty(availableDataSources) ? (
                      <Form.Item
                        label="Data Source"
                        className="gx-ml-3 gx-mr-2 process__dataSourceLabel"
                        initialValue={selectedSourceName}
                      >
                        <Select
                          size="small"
                          placeholder="Select Data Source"
                          onChange={(e) => {
                            setSelectedSourceName(e);
                            dispatch(getProjects(e));
                          }}
                          defaultValue={selectedSourceName}
                        >
                          {availableDataSources.map((item, index) => (
                            <Select.Option
                              key={`data-source-option-${index}`}
                              value={item.sourceName}
                            >
                              {item.sourceName}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    ) : null}
                    {!availableDataSources.length && (
                      <div className="gx-text-warning gx-mt-2">
                        No Data Sources Available. Please contact with
                        administrator.
                      </div>
                    )}
                  </>
                )}
              </>
              {selectedSourceName && (
                <div className="gx-pt-2 gx-pb-2">
                  <h6 className="">
                    {availableDataSources.length > 1 && (
                      <LeftCircleTwoTone
                        size="small"
                        className="gx-mr-2"
                        onClick={() => setSelectedSourceName(null)}
                      />
                    )}
                    Selected Source Name:{" "}
                    <span className="gx-text-success">
                      {selectedSourceName}
                    </span>
                  </h6>
                </div>
              )}
            </div>
          </div>
        </div>

        <div
          className="sticky-header-content"
          style={{
            marginTop: "100px",
          }}
        >
          <div className="gx-main-content">
            <div className="gx-app-module">
              <div
                className="gx-module-sidenav gx-d-none gx-d-lg-flex app-sidebar"
                id="sidebar"
                ref={sidebarRef}
                style={{ width: sidebarWidth }}
                // onMouseDown={(e) => e.preventDefault()}
              >
                <div className="gx-module-side app-sidebar-content">
                  <div className="gx-module-side-header">
                    <div className="gx-module-logo">
                      <ProjectOutlined
                        className="gx-mr-2"
                        style={{
                          fontSize: "25px",
                          marginTop: "2px",
                        }}
                      />
                      <span style={{ fontSize: "20px" }}>Projects</span>
                      <Popover content="Add Project">
                        <PlusCircleOutlined
                          className="gx-ml-2 gx-mt-1 gx-text-primary"
                          onClick={() => {
                            setProjectModal(true);
                            formRef.current?.setFields([
                              // {
                              //   name: "prefix",
                              //   value: "",
                              // },
                              {
                                name: "name",
                                value: "",
                              },
                              {
                                name: "description",
                                value: "",
                              },
                            ]);
                          }}
                        />
                      </Popover>
                    </div>

                    <div
                      className="gx-d-flex gx-mt-4"
                      style={{
                        position: "relative",
                      }}
                    >
                      <Input
                        className="ant-input gx-border-0"
                        type="text"
                        placeholder={"Search..."}
                        value={currentSearchInput}
                        onChange={(e) => {
                          setCurrentSearchInput(e.target.value);
                          setCurrentIds([]);
                        }}
                        onPressEnter={() => {
                          setSearchTerm(currentSearchInput);
                          setCurrentProject(null);
                          setCurrentIds([]);

                          dispatch(
                            searchParameters({
                              searchTerm: currentSearchInput,
                              dataSources: [selectedSourceName],
                              type: EXPLORE_PAGE_TAB_OPTIONS.PROJECT,
                              includeDocument: false,
                              searchOps: "CONTAINS",
                            })
                          );
                        }}
                      />
                      <span
                        className="gx-search-icon gx-pointer"
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "10px",
                        }}
                      >
                        <i className="icon icon-search" />
                      </span>
                    </div>
                  </div>

                  <div className="gx-module-side-content">
                    <CustomScrollbars className="gx-module-side-scroll">
                      <div className="gx-module-add-task"></div>
                      <div className="gx-module-side-nav">
                        <ul className="gx-module-nav">
                          {(filteredProjects || []).map((option) => (
                            <li
                              key={option.id}
                              className="gx-nav-item"
                              onClick={() => {
                                setCurrentProject(option);
                                // setCurrentSearchInput("");
                                // setCurrentIds([]);
                                setSearchTerm(null);
                              }}
                            >
                              <span
                                className={`gx-link gx-mb-1 ${
                                  currentProject?.id === option.id ||
                                  currentIds.length
                                    ? "active"
                                    : ""
                                }`}
                                style={{
                                  flexWrap: "nowrap",
                                }}
                              >
                                <FilePptOutlined className="gx-mr-2" />
                                <span>
                                  {option?.projectId}: {option?.name}
                                </span>
                              </span>
                            </li>
                          ))}
                        </ul>
                      </div>
                    </CustomScrollbars>
                  </div>
                </div>

                <div
                  className="app-sidebar-resizer"
                  id="resizer"
                  onMouseDown={startResizing}
                />
              </div>

              <div className="gx-module-box">
                <div className="gx-module-box-header">
                  <span className="gx-drawer-btn gx-d-flex gx-d-lg-none">
                    <i
                      className="icon icon-menu gx-icon-btn"
                      aria-label="Menu"
                      // onClick={this.onToggleDrawer.bind(this)}
                    />
                  </span>

                  <AppModuleHeader
                    onAddStepNo={onCreateProcess}
                    currentProject={currentProject}
                    sortMode={sortMode}
                    setSortMode={(flag) => {
                      setSortMode(flag);

                      if (flag) {
                        setOrderProcesses(processSteps || []);
                      }
                    }}
                    onSaveSort={saveOrder}
                    sourceName={selectedSourceName}
                  />
                </div>
                <div className="gx-module-box-content gx-pt-2">
                  <CustomScrollbars className="gx-module-content-scroll">
                    {!_.isEmpty(searchTerm) ? (
                      <div className="gx-ml-4">
                        <span>
                          Search results for{" "}
                          <span className="gx-text-warning">{searchTerm}</span>:
                        </span>

                        <div className="gx-mr-5 gx-mt-3">
                          {renderExplorePageCompoundTable({
                            sources: [selectedSourceName],
                            dataSource: tableData,
                            total: (tableData || []).length,
                            page,
                            perPage,
                            setPage,
                            setPerPage,
                            searchInput,
                            field: currentSelection,
                            filteredResult,
                            setFilteredResult,
                            filterValue,
                            setFilterValue,
                            tableKey: 1,
                            mapImages,
                            setMapImages,
                            forceUpdate,
                            availableWidgetParams: {},
                            currentTab: EXPLORE_PAGE_TAB_OPTIONS.PROJECT,
                            searchTerm,
                            availableWidgets: [],
                            loading: loadingTable,
                            disableStructureImage: true,
                            projects,
                          })}
                        </div>

                        {/* <SearchResult
                          searchTerm={searchTerm}
                          projects={projects}
                          sourceName={selectedSourceName}
                        /> */}
                      </div>
                    ) : (
                      <>
                        {_.isEmpty(currentProject) ? (
                          <div className="gx-h-100 gx-d-flex gx-mt-4 gx-justify-content-center">
                            <span>Select project from sidebar.</span>
                          </div>
                        ) : (
                          <>
                            {(processSteps || []).length === 0 ? (
                              <div className="gx-h-100 gx-d-flex gx-mt-4 gx-justify-content-center">
                                <span>
                                  No Process step is found in{" "}
                                  {currentProject?.name}.
                                </span>
                              </div>
                            ) : (
                              <>
                                {sortMode ? (
                                  <SortableList
                                    helperClass="sortableHelper"
                                    onSortEnd={onSortEnd}
                                  />
                                ) : (
                                  <div className="gx-ml-3 gx-mr-5">
                                    <ContactList
                                      sortMode={sortMode}
                                      contactList={processSteps || []}
                                      onAddExperiment={(currentProcess) => {
                                        setCurrentProcessId(
                                          currentProcess?.processId
                                        );

                                        getProcessRequest({
                                          sourceName: selectedSourceName,
                                          processId: currentProcess?.processId,
                                        }).then((res) => {
                                          setExperiments(
                                            res?.data?.experiments
                                          );
                                        });

                                        setExperimentModal(true);
                                      }}
                                      sourceName={selectedSourceName}
                                      onDeleteExperiment={onDeleteExperiment}
                                      onDeleteProcess={onDeleteProcess}
                                      projectSettings={projectSettings}
                                      hideAction
                                    />
                                  </div>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </CustomScrollbars>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Drawer
        title="Add New Project"
        placement="right"
        open={projectModal}
        okText="Add"
        onClose={() => setProjectModal(false)}
        onOk={onAddNewProject}
      >
        {renderAddProjectModal()}
      </Drawer>

      <Drawer
        title={`Associate a process step to project ${currentProject?.projectId}`}
        placement="right"
        open={processModal}
        okText="Add Process"
        // onClose={() => setProcessModal(false)}
        onOk={onAddProcess}
        onClose={() => {
          setCurrentProcessId(null);
          setButtonDisabled(true);
          setProcessModal(false);
        }}
      >
        {renderProcessModal}
      </Drawer>

      <Drawer
        title={`Project: ${currentProject?.projectId}. Attach Experiment to process: ${currentProcessId}`}
        open={experimentModal}
        okText="Add Experiment"
        okButtonProps={{ disabled: buttonDisabled }}
        onClose={() => {
          setExperimentModal(false);
        }}
        onOk={onAddExperiment}
        afterClose={() => {
          setCurrentProcessId(null);
          setButtonDisabled(true);
        }}
      >
        {renderExperimentModal()}
      </Drawer>

      <Modal
        title={`Project Id: ${currentProject?.projectId}: Order Processes.`}
        visible={orderProcessModal}
        okText="Save Order"
        okButtonProps={{ disabled: !(orderProcesses || []).length }}
        onCancel={() => setOrderProcessModal(false)}
        onOk={saveOrder}
      >
        {renderOrderProcessModal()}
      </Modal>
    </>
  );
};

export default ProjectPage;
