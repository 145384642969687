import { Table } from "antd";
import React from "react";
import { EditableCell, EditableRow } from "./EditableRow";

import _ from "lodash";
import {
  renderCustomColumn,
  renderPredefinedColumn,
} from "../../CompositionTable/helper";
import { getColumns } from "./helper";
import "./style.css";

const TableContent = ({
  sourceName,
  dataSource,
  setDataSource,
  lookupData,
  experimentMetadata,
  currentRound,
  pageType,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  limitingReagent,
  setLimitingReagent,
  forceUpdate = () => {},
  editMode,
  onOpenSearch,
  dataSources,
  onChangeItem,
  handleDelete,
  actualDataSource,
  rows = [],
  allWidgets,
}) => {
  /**
   * Calculate table rows.
   * @param {*} data
   * @param {*} rowId
   * @param {*} lr limiting reagent
   * @returns
   */
  const reCalculateRows = (data, rowId, lr) => {
    const limitingReagentRow = _.find(data, { id: lr }) || {};

    let baseEQ = Number(_.get(limitingReagentRow, "equivalent") || 1);
    let baseMol = Number(_.get(limitingReagentRow, "mol") || 1);

    const newRows = _.map(data, (item) => {
      const eq = Number(_.get(item, "equivalent")) || 1;

      // If it's current editing row, skip.
      if (rowId === item?.id) return item;

      // If it's limiting reagent row, skip.
      if (item?.id === limitingReagentRow?.id) return item;

      return {
        ...item,
        mol: (baseMol * eq) / baseEQ,
      };
    });

    return newRows;
  };

  const handleSave = (row) => {
    const newData = [...actualDataSource];
    const index = newData.findIndex((item) => row.id === item.id);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });

    const dataSourceCalculated = reCalculateRows(
      newData,
      row?.id,
      limitingReagent
    );

    setDataSource(dataSourceCalculated);
    forceUpdate();
  };

  const handleSaveChecked = ({ id, checked }) => {
    if (checked) {
      setLimitingReagent(id);
    } else {
      setLimitingReagent(null);
    }

    if (checked) {
      // Re-calculate after changing limiting reagent.
      const dataSourceCalculated = reCalculateRows(dataSource, id, id);

      setDataSource(dataSourceCalculated);
      forceUpdate();
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: (props) => (
        <EditableCell {...props} experimentMetadata={experimentMetadata} />
      ),
    },
  };

  const compositionsWithIndex = dataSource.map((item, index) => ({
    ...item,
    index,
  }));

  const defaultColumns = getColumns(
    lookupData,
    experimentMetadata,
    handleDelete,
    sourceName,
    currentRound,
    pageType,
    dataSource,
    safetySentenceTypes,
    safetySentenceImageMetadata,
    safetySentenceLanguageMap,
    limitingReagent,
    editMode,
    onOpenSearch,
    dataSources,
    onChangeItem
  );

  const customOptions = _.map(rows, (op) => {
    if (op?.type === "Predefined Column") {
      return renderPredefinedColumn({
        field: op?.field,
        pageType,
        compositions: compositionsWithIndex,
        currentRound,
        safetySentenceTypes,
        safetySentenceImageMetadata,
        safetySentenceLanguageMap,
        sourceName,
      });
    } else {
      return renderCustomColumn({
        widgetName: op?.widgetName,
        field: op?.field,
        allWidgets,
        pageType,
        sourceName,
        compositions: compositionsWithIndex,
        safetySentenceTypes,
        safetySentenceImageMetadata,
        safetySentenceLanguageMap,
        displayName: op?.displayName,
      });
    }
  });

  const aCols = defaultColumns.slice(0, defaultColumns.length - 1);

  const columns = [...aCols, ...customOptions, ...[defaultColumns.pop()]].map(
    (col) => {
      if (!editMode) {
        return col;
      }

      if (!col.editable) {
        return col;
      }
      return {
        ...col,
        onCell: (record) => ({
          record,
          editable: col?.editable,
          type: col?.type || "number",
          dataIndex: col.dataIndex,
          title: col.title,
          handleSave,
          handleSaveChecked,
          limitingReagent,
          currentRound,
        }),
      };
    }
  );

  return (
    <div>
      <Table
        components={components}
        size="small"
        rowClassName={() => "editable-row"}
        bordered
        dataSource={compositionsWithIndex}
        columns={columns}
        scroll={{
          x: 1800,
          y: 900,
          scrollToFirstRowOnChange: true,
        }}
        pagination={false}
      />
    </div>
  );
};

TableContent.defaultProps = {};

TableContent.propTypes = {};

export default TableContent;
