import axios from "axios";
import { config } from "../../constants/Config";

/**
 * Index Source Name to database
 * @param {String} sourceName
 * @param {Function} setLoading call function to set loading
 * @param {Function} onFinish callback function after finished
 * @param {Function} onError callback function after error occured
 * @returns
 */
export const indexSource = async (
  sourceName,
  setLoading,
  onFinish,
  onError
) => {
  setLoading(true);
  return axios
    .post(
      `${config.SCAS_API_URL}/ontology/source/index?name=${sourceName}&deleteOnly=false​`,
      "",
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then(() => {
      onFinish();
    })
    .catch(() => {
      onError();
    })
    .finally(() => {
      setLoading(false);
    });
};
