import React from "react";

import ContactCell from "./ContactCell/index";
import { Collapse } from "antd";
import _ from "lodash";
import ExperimentList from "../../ExperimentList";

const ContactList = ({
  contactList,
  onAddExperiment,
  sourceName,
  onDeleteExperiment,
  onDeleteProcess,
  projectSettings = [],
  hideAction = false,
}) => {
  return (
    <div className="gx-contact-main-content">
      <Collapse expandIconPosition="end" defaultActiveKey={["0"]}>
        {_.map(contactList, (contact, index) => (
          <Collapse.Panel
            key={index}
            header={
              <ContactCell
                key={index}
                contact={contact}
                sourceName={sourceName}
                onDeleteProcess={onDeleteProcess}
                onAddExperiment={onAddExperiment}
                projectSettings={projectSettings}
                hideAction={hideAction}
              />
            }
          >
            <ExperimentList
              experiments={contact?.experiments || []}
              process={contact}
              sourceName={sourceName}
              onDeleteExperiment={onDeleteExperiment}
              hideAction={hideAction}
            ></ExperimentList>
          </Collapse.Panel>
        ))}
      </Collapse>
    </div>
  );
};

export default ContactList;
