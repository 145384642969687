import React from "react";
import { Popover } from "antd";
import { DownOutlined } from "@ant-design/icons";
import _ from "lodash";

const RoleChanger = ({ title, roles, callbackFn, getRole }) => {
  const userMenuOptions = (
    <ul className="gx-user-popover">
      {roles
        .filter((item) => item.role !== getRole())
        .map((role) => (
          <li
            key={role?.name}
            onClick={() => {
              callbackFn({ name: role?.name, id: role?.id });
              window.location.reload();
            }}
          >
            {role?.displayName || role?.name}
          </li>
        ))}
    </ul>
  );

  const roleDisplayName = _.find(roles, { name: getRole() });

  return (
    <Popover
      overlayClassName="gx-popover-horizantal"
      placement="bottomRight"
      content={userMenuOptions}
      trigger="click"
    >
      <div className="gx-pointer" style={{ fontSize: "14px" }}>
        {title}: {roleDisplayName?.displayName || roleDisplayName?.name}{" "}
        <DownOutlined />
      </div>
    </Popover>
  );
};

export default RoleChanger;
