import _ from "lodash";
import React from "react";
import FormItem from "./FormItem";
import { Button } from "antd";
import { mapKeys } from "../../helper";

const FormBuilder = ({
  formItems,
  onSearch,
  handleRemoveFormItem,
  handleFormItemChange,
  advancedSearchJoinType,
  setAdvancedSearchJoinType,
}) => {
  return (
    <div
      className="gx-mt-3 gx-ml-3"
      style={{
        width: "100%",
      }}
    >
      {_.map(mapKeys(formItems), (formItem, index) => {
        const isLastItem = formItems.length - 1 === index;

        return (
          <FormItem
            key={index}
            handleFormItemChange={handleFormItemChange}
            handleRemoveFormItem={handleRemoveFormItem}
            isLastItem={isLastItem}
            advancedSearchJoinType={advancedSearchJoinType}
            setAdvancedSearchJoinType={setAdvancedSearchJoinType}
            {...formItem}
          />
        );
      })}

      {_.isEmpty(formItems) ? (
        <p className="gx-text-info" style={{ textAlign: "center" }}>
          Select parameter from left tab.
        </p>
      ) : (
        <Button
          size="small"
          type="primary"
          onClick={() => onSearch(mapKeys(formItems))}
        >
          Search
        </Button>
      )}
    </div>
  );
};

export default FormBuilder;
