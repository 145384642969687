import _ from "lodash";
import {
  LOOKUP_COMPOSITION_TABLE_FAILED,
  LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS,
  LOOKUP_COMPOSITION_TABLE_REQUESTED,
  LOOKUP_COMPOSITION_TABLE_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {};

const LookupReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case LOOKUP_COMPOSITION_TABLE_REQUESTED: {
      const currentData = _.get(state, action.payload?.id) || {};

      if (action.payload?.id === "S-1") {
        console.log("current data", action.payload, currentData);
      }

      return {
        ...state,
        [action.payload?.id]: {
          ...currentData,
          loading: true,
        },
      };
    }

    case LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED: {
      const currentData = _.get(state, action.payload?.id);

      return {
        ...state,
        [action.payload?.id]: {
          ...currentData,
          loading: true,
        },
      };
    }

    case LOOKUP_COMPOSITION_TABLE_SUCCESS: {
      const currentData = _.get(state, action.payload?.id);

      if (_.isNumber(action.payload?.data)) {
        return {
          ...state,
          [action.payload?.id]: {
            ...currentData,
            loading: false,
            [action.payload?.field]: action.payload?.data,
          },
        };
      } else {
        return {
          ...state,
          [action.payload?.id]: {
            ...currentData,
            loading: false,
            [action.payload?.field]: {
              ...(_.get(state, [action.payload?.id, action.payload?.field]) ||
                {}),
              ...(action.payload?.data || {}),
            },
          },
        };
      }
    }

    case LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS: {
      const currentData = _.get(state, action.payload?.id);
      return {
        ...state,
        [action.payload?.id]: {
          ...currentData,
          loading: false,
          [action.payload?.field]: action.payload?.data,
        },
      };
    }

    case LOOKUP_COMPOSITION_TABLE_FAILED: {
      const currentData = _.get(state, action.payload?.id);
      return {
        ...state,
        [action.payload?.id]: {
          ...currentData,
          loading: false,
        },
      };
    }

    default:
      return state;
  }
};

export default LookupReducer;
