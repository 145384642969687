import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation, useRouteMatch } from "react-router-dom";
import { ConfigProvider } from "antd";
import { IntlProvider } from "react-intl";

import AppLocale from "../../lngProvider";
import MainApp from "./MainApp";
import { setDocumentUserRoles, setUserRoles } from "../../appRedux/actions";

import {
  LAYOUT_TYPE_BOXED,
  LAYOUT_TYPE_FRAMED,
  LAYOUT_TYPE_FULL,
  NAV_STYLE_ABOVE_HEADER,
  NAV_STYLE_BELOW_HEADER,
  NAV_STYLE_DARK_HORIZONTAL,
  NAV_STYLE_DEFAULT_HORIZONTAL,
  NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
  THEME_TYPE_DARK,
} from "../../constants/ThemeSetting";
import { LOGIN_REQUEST } from "../../constants/Config";
import {
  MsalAuthenticationTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import { InteractionStatus, InteractionType } from "@azure/msal-browser";
import _ from "lodash";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import useGetDocumentRoles from "../../hooks/detailPage/useGetDocumentRoles";

const RestrictedRoute = ({
  component: Component,
  location,
  authUser,
  ...rest
}) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance, inProgress } = useMsal();
  const dispatch = useDispatch();

  const roles = useGetRoles();

  const documentRoles = useGetDocumentRoles();

  const callLogin = () => {
    if (inProgress === InteractionStatus.None && !isAuthenticated) {
      instance.loginRedirect(LOGIN_REQUEST);
    }
  };

  // Update document title by checking url.
  useEffect(() => {
    const isSRD = window.location.href.includes("chemantics");
    const isLocalHost = window.location.href.includes("localhost");

    if (isSRD || isLocalHost) {
      document.title = "SRD - Semantic Chemistry";
    } else {
      document.title = "ACT - Arxada Chemistry Toolset";
    }
  }, []);

  useEffect(() => {
    callLogin();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if ((accounts || []).length && (roles || []).length) {
      const ids = _.get(accounts[0], "idTokenClaims.groups") || [];

      const filteredRoles = _.filter(roles, (role) => {
        return ids.includes(role?.id);
      });

      const documentFilteredRoles = _.filter(documentRoles, (documentRole) => {
        return ids.includes(documentRole.id);
      });

      if ((filteredRoles || []).length) {
        dispatch(setUserRoles(filteredRoles));
      }

      if ((documentFilteredRoles || []).length) {
        dispatch(setDocumentUserRoles(documentFilteredRoles));
      }
    }
  }, [roles, accounts, dispatch, documentRoles]);

  const authRequest = {
    scopes: ["openid", "profile"],
  };

  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
    >
      <Route {...rest} render={(props) => <Component {...props} />} />;
    </MsalAuthenticationTemplate>
  );
};

const setLayoutType = (layoutType) => {
  if (layoutType === LAYOUT_TYPE_FULL) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("full-layout");
  } else if (layoutType === LAYOUT_TYPE_BOXED) {
    document.body.classList.remove("full-layout");
    document.body.classList.remove("framed-layout");
    document.body.classList.add("boxed-layout");
  } else if (layoutType === LAYOUT_TYPE_FRAMED) {
    document.body.classList.remove("boxed-layout");
    document.body.classList.remove("full-layout");
    document.body.classList.add("framed-layout");
  }
};

const setNavStyle = (navStyle) => {
  if (
    navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
    navStyle === NAV_STYLE_DARK_HORIZONTAL ||
    navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
    navStyle === NAV_STYLE_ABOVE_HEADER ||
    navStyle === NAV_STYLE_BELOW_HEADER
  ) {
    document.body.classList.add("full-scroll");
    document.body.classList.add("horizontal-layout");
  } else {
    document.body.classList.remove("full-scroll");
    document.body.classList.remove("horizontal-layout");
  }
};

const defaultGlobalState = {
  searchTerm: "",
};

export const MainContext = React.createContext();

const App = () => {
  const locale = useSelector(({ settings }) => settings.locale);
  const navStyle = useSelector(({ settings }) => settings.navStyle);
  const layoutType = useSelector(({ settings }) => settings.layoutType);
  const themeType = useSelector(({ settings }) => settings.themeType);
  const isDirectionRTL = useSelector(({ settings }) => settings.isDirectionRTL);

  const { authUser } = useSelector(({ auth }) => auth);

  const location = useLocation();
  const match = useRouteMatch();

  useEffect(() => {
    if (isDirectionRTL) {
      document.documentElement.classList.add("rtl");
      document.documentElement.setAttribute("data-direction", "rtl");
    } else {
      document.documentElement.classList.remove("rtl");
      document.documentElement.setAttribute("data-direction", "ltr");
    }
  }, [isDirectionRTL]);

  useEffect(() => {
    if (locale) document.documentElement.lang = locale.locale;
  }, [locale]);

  useEffect(() => {
    if (themeType === THEME_TYPE_DARK) {
      document.body.classList.add("dark-theme");
    } else if (document.body.classList.contains("dark-theme")) {
      document.body.classList.remove("dark-theme");
    }
  }, [themeType]);

  useEffect(() => {
    setLayoutType(layoutType);
    setNavStyle(navStyle);
  }, [layoutType, navStyle]);

  const currentAppLocale = AppLocale[locale.locale];

  return (
    <ConfigProvider
      locale={currentAppLocale.antd}
      direction={isDirectionRTL ? "rtl" : "ltr"}
    >
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.messages}
      >
        <Switch>
          <RestrictedRoute
            path={`${match.url}`}
            authUser={authUser}
            location={location}
            component={MainApp}
          />
        </Switch>
      </IntlProvider>
    </ConfigProvider>
  );
};

const AppWithContext = () => {
  const [searchTerm, setSearchTerm] = useState(defaultGlobalState.searchTerm);
  const [includeDocument, setIncludeDocument] = useState(false);

  return (
    <MainContext.Provider
      value={{
        searchTerm: searchTerm,
        setSearchTerm: (term) => setSearchTerm(term),
        includeDocument: includeDocument,
        setIncludeDocument: (inc) => {
          setIncludeDocument(inc);
        },
      }}
    >
      <App />
    </MainContext.Provider>
  );
};

export default memo(AppWithContext);
