/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Col, Divider, Form, Select } from "antd";
import { Option } from "antd/lib/mentions";
import PT from "prop-types";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  AVAILABLE_DOCUMENT_INPUT_TYPES,
  AVAILABLE_TABLE_INPUT_TYPES,
  AVAILABLE_WIDGET_INPUT_TYPES,
  DOCUMENT_COLLECTION_OPTIONS,
} from "../../../../constants/Config";
import ArrayForm from "./ArrayForm";
import CustomForm from "./CustomForm";
import EditableForm from "./EditableForm";
import GroupForm from "./GroupForm";
import LinkForm from "./LinkForm";
import ListForm from "./ListForm";
import NestedForm from "./NestedForm";
import SeparatorForm from "./SeparatorForm";
import StringForm from "./StringForm";
import TableRowForm from "./TableRowForm";
import ToggleButtonForm from "./ToggleButtonForm";
import UrlForm from "./UrlForm";
import WidgetForm from "./WidgetForm";

const FormWrapper = forwardRef((props, ref) => {
  const {
    widgetType,
    row,
    rowIndex,
    onRowsChange,
    roles,
    settingsWidgetName,
    setModalOpen,
    onSaveRow,
    groupOptions = [],
    isColumnSettings,
    availableWidgets = [],
  } = props;
  const [form, setForm] = useState(row);

  useEffect(() => {
    setForm(row);
  }, [row]);

  useEffect(() => {
    onRowsChange(form, rowIndex);
  }, [form]);

  const onChangeValue = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  useImperativeHandle(ref, () => ({
    onSubmitRow() {
      return { index: rowIndex, row: form };
    },
  }));

  let types =
    widgetType === "table"
      ? AVAILABLE_TABLE_INPUT_TYPES
      : AVAILABLE_WIDGET_INPUT_TYPES;

  if (settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS) {
    types = AVAILABLE_DOCUMENT_INPUT_TYPES;
  }

  if (isColumnSettings) {
    types = [
      { field: "Widget", value: "Widget" },
      { field: "Predefined Column", value: "Predefined Column" },
    ];
  }

  return (
    <>
      <Form>
        <Col className="gx-ml-2">
          <Form.Item
            label="Type"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              value={form?.type}
              dropdownMatchSelectWidth={5}
              onChange={(e) => onChangeValue("type", e)}
            >
              {types.map((item) => (
                <Option key={item.field}>{item.value}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col className="gx-ml-2">
          {form?.type === "EDITABLE" ||
          form?.type === "PROJECT_REFERENCE" ||
          form?.type === "EDITABLE_DATE" ? (
            <EditableForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              groupOptions={groupOptions}
              settingsWidgetName={settingsWidgetName}
            />
          ) : null}
        </Col>
        <Col>
          {form?.type === "LIST" || form?.type === "FILE" ? (
            <Col className="gx-ml-2">
              <ListForm
                onChangeValue={onChangeValue}
                form={form}
                roles={roles}
                settingsWidgetName={settingsWidgetName}
              />
            </Col>
          ) : null}
        </Col>
        {form?.type === "TABLE" ? (
          <TableRowForm
            onChangeValue={onChangeValue}
            form={form}
            roles={roles}
            hideLimit
            settingsWidgetName={settingsWidgetName}
          />
        ) : null}
        {form?.type === "STRING" || form?.type === "STRING_DATE" ? (
          <Col className="gx-ml-2">
            <StringForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              isColumnSetting={isColumnSettings}
              settingsWidgetName={settingsWidgetName}
            />
          </Col>
        ) : null}
        {form?.type === "TOGGLE_BUTTON" ? (
          <Col className="gx-ml-2">
            <ToggleButtonForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
            />
          </Col>
        ) : null}
        {form?.type === "URL" ? (
          <Col className="gx-ml-2">
            <UrlForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              settingsWidgetName={settingsWidgetName}
            />
          </Col>
        ) : null}
        {form?.type === "ARRAY" ? (
          <Col className="gx-ml-2">
            <ArrayForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              settingsWidgetName={settingsWidgetName}
            />
          </Col>
        ) : null}
        {form?.type === "NESTED" || form?.type === "SELECT_INPUT" ? (
          <Col className="gx-ml-2">
            <NestedForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              settingsWidgetName={settingsWidgetName}
            />
          </Col>
        ) : null}
        {form?.type === "SEPARATOR" ? (
          <Col className="gx-ml-2">
            <SeparatorForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
            />
          </Col>
        ) : null}
        {form?.type === "LINK" ? (
          <Col className="gx-ml-2">
            <LinkForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              settingsWidgetName={settingsWidgetName}
            />
          </Col>
        ) : null}
        {form?.type === "GROUP" ? (
          <Col className="gx-ml-2">
            <GroupForm onChangeValue={onChangeValue} form={form} />
          </Col>
        ) : null}
        {form?.type === "Predefined Column" ? (
          <Col className="gx-ml-2">
            <CustomForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              isColumnSetting={isColumnSettings}
            />
          </Col>
        ) : null}
        {form?.type === "Widget" ? (
          <Col className="gx-ml-2">
            <WidgetForm
              onChangeValue={onChangeValue}
              form={form}
              roles={roles}
              isColumnSetting={isColumnSettings}
              availableWidgets={availableWidgets}
            />
          </Col>
        ) : null}
      </Form>

      <div>
        <Divider />

        <Button size="small" onClick={() => setModalOpen(false)}>
          Cancel
        </Button>
        <Button
          type="primary"
          size="small"
          onClick={() => {
            onSaveRow({ index: rowIndex, row: form });
            setModalOpen(false);
          }}
        >
          Save
        </Button>
      </div>
    </>
  );
});

FormWrapper.defaultProps = {
  row: {},
  rowIndex: 0,
  roles: [],
};

FormWrapper.propTypes = {
  row: PT.shape(),
  rowIndex: PT.number,
  roles: PT.arrayOf(PT.shape()),
  setModalOpen: PT.func.isRequired,
  onSaveRow: PT.func.isRequired,
};

export default FormWrapper;
