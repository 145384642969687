import { Form, Select } from "antd";
import React from "react";
import { DOCUMENT_UPLOAD_SELECT_INPUT_OPTIONS } from "../../../constants/Config";

const SelectUploadType = ({ type, onChangeType }) => {
  return (
    <div>
      <Form>
        <Form.Item label="Select Upload Type">
          <Select
            options={DOCUMENT_UPLOAD_SELECT_INPUT_OPTIONS}
            defaultValue={type}
            placeholder="Select upload type"
            onChange={onChangeType}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default SelectUploadType;
