import axios from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_DATA_SOURCES_REQUESTED,
  GET_DATA_SOURCES_SUCCESS,
  SEARCH_COMPOUND_FAILED,
  SEARCH_COMPOUND_REQUESTED,
  SEARCH_COMPOUND_SUCCESS,
  SEARCH_DOCUMENTS_FAILED,
  SEARCH_DOCUMENTS_REQUESTED,
  SEARCH_DOCUMENTS_SUCCESS,
} from "../../constants/ActionTypes";
import { config } from "../../constants/Config";

const getDataSourcesRequest = async (useScasEndpoint) => {
  const baseUrl = useScasEndpoint
    ? `${config.SCAS_API_URL}/ontology`
    : config.SRD_API_URL;
  return axios.get(`${baseUrl}/sources`);
};

const searchDocumentRequest = async ({ body }) => {
  return axios.post(`${config.SRD_API_URL}/search`, body);
};

const searchCompoundRequest = async ({ body }) => {
  return axios.post(`${config.SRD_API_URL}/search/compoundWidgets`, body);
};

function* fetchDataSources(action) {
  try {
    const result = yield call(
      getDataSourcesRequest,
      action.payload.useScasEndpoint
    );

    if (result.data) {
      yield put({
        type: GET_DATA_SOURCES_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: GET_DATA_SOURCES_SUCCESS,
      payload: [],
    });
  }
}

function* fetchDocuments(action) {
  try {
    const result = yield call(searchDocumentRequest, {
      body: action.payload.body,
    });

    if (result.data) {
      yield put({
        type: SEARCH_DOCUMENTS_SUCCESS,
        payload: {
          data: result.data,
          type: action.payload.type,
        },
      });
    }
  } catch (error) {
    yield put({
      type: SEARCH_DOCUMENTS_FAILED,
    });
  }
}

function* fetchCompounds(action) {
  try {
    const result = yield call(searchCompoundRequest, {
      body: action.payload.body,
    });

    if (result.data) {
      yield put({
        type: SEARCH_COMPOUND_SUCCESS,
        payload: {
          data: result.data?.results || [],
        },
      });
    }
  } catch (error) {
    yield put({
      type: SEARCH_COMPOUND_FAILED,
    });
  }
}

export function* getDataSources() {
  yield takeLatest(GET_DATA_SOURCES_REQUESTED, fetchDataSources);
}

export function* searchDocuments() {
  yield takeLatest(SEARCH_DOCUMENTS_REQUESTED, fetchDocuments);
}

export function* searchCompounds() {
  yield takeLatest(SEARCH_COMPOUND_REQUESTED, fetchCompounds);
}

export default function* rootSaga() {
  yield all([
    fork(getDataSources),
    fork(searchDocuments),
    fork(searchCompounds),
  ]);
}
