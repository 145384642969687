/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef, useState } from "react";
import PT from "prop-types";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "components/CircularProgress";
import {
  Badge,
  Button,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  FilterTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import { ReactComponent as ExpandIcon } from "../../../assets/vendors/expand.svg";

import { getSources } from "../../../appRedux/services/Widget";
import SystemWidgetSettings from "../SystemWidgetSettings";
import { getFieldValueFromDisplayName } from "../../../util/Widget";

import "./style.css";
import EditableCell from "../../../components/EditableCell";

const AvailableWidgetsSettings = ({
  title,
  widgetName,
  loading,
  availableWidgets,
  onSave,
  disableEdit,
  roles,
  onSaveSystemWidget,
  allWidgets,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [sources, setSources] = useState([]);
  const [editingKey, setEditingKey] = useState("");

  const [currentWidget, setCurrentWidget] = useState({});
  const [settingsDetailOpen, setSettingsDetailOpen] = useState(false);

  useEffect(() => {
    if (!(sources || []).length) getSources(setSources);
  }, []);

  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [form] = Form.useForm();

  const formRef = useRef();
  const isEditing = (record) => record.key === editingKey;

  const availableWidgetsWithKeys = availableWidgets.map((widget, index) => ({
    ...widget,
    key: index,
  }));

  const [dataSource, setDataSource] = useState(
    _.sortBy(availableWidgetsWithKeys, "field")
  );

  useEffect(() => {
    setDataSource(
      availableWidgets.map((widget, index) => ({
        ...widget,
        key: index,
      }))
    );
  }, [availableWidgets]);

  const onChangeValue = (field, value) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const onRemove = (field, index) => {
    const newRoles = dataSource.filter((item, i) => i !== index);
    onSave(widgetName, newRoles);
  };

  const rolesOptions = roles.map((item) => ({
    label: item.displayName,
    value: item.id,
  }));

  const sourcesOptions = sources.map((source) => ({
    label: source?.field,
    value: source?.value,
  }));

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }

      onSave(widgetName, newData);
    } catch (errInfo) {}
  };

  const renderActions = (p, cWidget, index) => {
    const { field } = cWidget;

    const editable = isEditing(cWidget);

    return (
      <>
        {editable ? (
          <>
            <Popover content="Save widget">
              <SaveTwoTone
                twoToneColor="#52c41a"
                className="gx-pointer"
                onClick={() => save(cWidget.key)}
              />
            </Popover>

            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => setEditingKey("")}
            >
              <CloseCircleTwoTone className="gx-pl-2" twoToneColor="#a94442" />
            </Popconfirm>
          </>
        ) : (
          <>
            <Popover content="Edit widget">
              <EditTwoTone
                twoToneColor="#038FDE"
                onClick={
                  () => {
                    form.setFieldsValue({
                      name: "",
                      age: "",
                      address: "",
                      ...cWidget,
                    });
                    setEditingKey(cWidget.key);
                    setEditIndex(index);
                  }
                  // onEdit(
                  //   field,
                  //   value,
                  //   belongsTo,
                  //   description,
                  //   roles,
                  //   sourcesBelongsTo,
                  //   width,
                  //   index
                  // )
                }
                className="gx-mr-3"
              />
            </Popover>

            <Popover content="Show detail">
              <ExpandIcon
                className="expand-icon gx-mr-2"
                style={{
                  marginTop: "1px",
                  width: "14px",
                  height: "11px",
                }}
                onClick={() => {
                  setCurrentWidget(cWidget);
                  setSettingsDetailOpen(true);
                }}
              />
            </Popover>

            {!disableEdit && (
              <Popconfirm
                title="Are you sure to delete this widget?"
                onConfirm={() => onRemove(field, index)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteTwoTone
                  className="gx-pointer gx-pl-2"
                  twoToneColor="#f44336"
                />
              </Popconfirm>
            )}
          </>
        )}
      </>
    );
  };

  // const renderWidth = (p, { width }) => {
  //   return <div>{width || 1}</div>;
  // };

  const renderDatabases = (p, { databases }) => {
    return _.map(databases, (database) => {
      return (
        <Tag key={uuidv4()} size="small" color="#73d13d">
          {database}
        </Tag>
      );
    });
  };

  const renderRoles = (p, { roles: versionRoles }) => {
    return _.map(versionRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });

    // return <div>{(roles || []).join(",")}</div>;
  };

  const renderUnmappedDataAccessRoles = (
    p,
    { unmappedDataAccessRoles = [] }
  ) => {
    // return <div>{unmappedDataAccessRoles.join(",")}</div>;

    return _.map(unmappedDataAccessRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });
  };

  const renderVersionRoles = (p, { versionRoles }) => {
    return _.map(versionRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });
  };

  const onConfirmModal = () => {
    const field = formRef.current.getFieldValue("field");
    const value = formRef.current.getFieldValue("value");
    const description = formRef.current.getFieldValue("description");
    const roles = formRef.current.getFieldValue("roles");
    const versionRoles = formRef.current.getFieldValue("versionRoles");
    const databases = formRef.current.getFieldValue("databases");
    const widgetType = formRef.current.getFieldValue("widgetType") || "default";
    const unmappedDataAccessRoles = formRef.current.getFieldValue(
      "unmappedDataAccessRoles"
    );

    const fieldIndex = _.findIndex(dataSource, { field });
    const valueIndex = _.findIndex(dataSource, { value });

    if (!field.length) {
      formRef.current.setFields([
        {
          name: "field",
          errors: ["Field cannot be empty"],
        },
      ]);
      return;
    }

    if (!value.length) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Value cannot be empty"],
        },
      ]);
      return;
    }

    if (valueIndex !== -1) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Widget is already added"],
        },
      ]);
      return;
    }

    // if (width && (Number(width) < 0 || Number(width) > 12)) {
    //   formRef.current.setFields([
    //     {
    //       name: "width",
    //       errors: ["Width must be between 1 to 12"],
    //     },
    //   ]);
    //   return;
    // }

    const targetRole = {
      field,
      value,
      // belongsTo,
      // sourcesBelongsTo,
      // width,
      description,
      roles,
      databases,
      versionRoles,
      widgetType,
      unmappedDataAccessRoles,
      // roles: rolesSelections || [],
      // accessRoles: accessRolesSelections || [],
    };

    let newAvailableWidgets = dataSource;
    newAvailableWidgets.push(targetRole);

    onSave(widgetName, newAvailableWidgets);
    setShowModal(false);
    forceUpdate();
    setDataSource(newAvailableWidgets);
  };

  const addWidget = () => {
    setShowModal(true);
    forceUpdate();

    setTimeout(() => {
      formRef.current.setFieldsValue({
        field: "",
        value: "",
        belongsTo: [],
        width: null,
      });
    }, 20);
  };

  const renderTitle = (
    <div
      style={{ display: "flex", justifyContent: "space-between" }}
      className="gx-mr-5"
    >
      <span>{`${currentWidget?.field} widget settings`}</span>
    </div>
  );

  const applyToAll = (targetField, ops) => {
    const newAvailableWidgets = _.map(dataSource, (ds) => {
      return {
        ...ds,
        [targetField]: ops,
      };
    });

    setDataSource(newAvailableWidgets);
    onSave(widgetName, newAvailableWidgets);
  };

  const databaseAccessRef = useRef();
  const versionAccessRolesRef = useRef();
  const widgetAccessRolesRef = useRef();
  const unmappedWidgetAccessRolesRef = useRef();

  const columns = [
    {
      title: "Display Name",
      dataIndex: "field",
      editable: true,
      responsive: ["sm"],
      render: (p, { field, widgetType }) => {
        return (
          <div>
            {widgetType === "table" ? (
              <Badge
                count={"table"}
                offset={[25, 0]}
                color="green"
                size="small"
              >
                <span>{field}</span>
              </Badge>
            ) : (
              <span>{field}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Database Name",
      dataIndex: "value",
      editable: false,
      responsive: ["sm"],
    },
    {
      title: "Description",
      dataIndex: "description",
      width: "20%",
      editable: true,
      required: false,
      responsive: ["sm"],
      render: (p, { description }) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {description}
        </div>
      ),
    },
    {
      dataIndex: "databases",
      editable: true,
      mode: "multiple",
      render: renderDatabases,
      responsive: ["sm"],
      title: () => {
        return (
          <div>
            <span>Database Access</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={databaseAccessRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={sourcesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = databaseAccessRef.current.getFieldValue("options");
                applyToAll("databases", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "versionRoles",
      editable: true,
      mode: "multiple",
      render: renderVersionRoles,
      responsive: ["sm"],
      required: false,
      title: () => {
        return (
          <div>
            <span>Version Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={versionAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops =
                  versionAccessRolesRef.current.getFieldValue("options");
                applyToAll("versionRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "roles",
      editable: true,
      mode: "multiple",
      render: renderRoles,
      responsive: ["sm"],
      title: () => {
        return (
          <div>
            <span>Widget Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={widgetAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops =
                  widgetAccessRolesRef.current.getFieldValue("options");
                applyToAll("roles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      // title: "Unmapped data Access Roles",
      dataIndex: "unmappedDataAccessRoles",
      editable: true,
      mode: "multiple",
      render: renderUnmappedDataAccessRoles,
      responsive: ["sm"],
      title: () => {
        return (
          <div>
            <span>Unmapped data Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={unmappedWidgetAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops =
                  unmappedWidgetAccessRolesRef.current.getFieldValue("options");
                applyToAll("unmappedDataAccessRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    // {
    //   title: "Width",
    //   dataIndex: "width",
    //   width: "10%",
    //   editable: true,
    //   render: renderWidth,
    //   required: true,
    // },
    {
      title: "Actions",
      width: "7%",
      editable: false,
      render: renderActions,
      responsive: ["sm"],
    },
  ];

  const mergedColumns = columns.map((col) => {
    let options = [];

    if (!col.editable) {
      return col;
    }

    let inputType = "text";
    switch (col.dataIndex) {
      case "width":
        inputType = "number";
        break;
      case "roles":
      case "versionRoles":
      case "unmappedDataAccessRoles":
        inputType = "select";
        options = rolesOptions;
        break;
      case "databases":
        inputType = "select";
        options = sourcesOptions;
        break;
      default:
        inputType = "text";
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options,
        mode: col.mode,
        required: col.required || false,
      }),
    };
  });

  return (
    <>
      <h3>{title}: </h3>
      {loading && <CircularProgress />}
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          size="small"
          dataSource={dataSource}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={false}
        />
      </Form>
      {/* <Form className="gx-ml-3 gx-mt-3">
        <Form.Item>
          <Table
            pagination={false}
            dataSource={dataSource}
            size="small"
            scroll={{ y: TABLE_MAX_HEIGHT, x: "100vw" }}
          >
            <Column
              title="Display Name"
              key="field"
              dataIndex="field"
              editable
            />
            <Column title="Database Name" key="value" dataIndex="value" />
            <Column
              title="Description"
              key="description"
              dataIndex="description"
              render={(p, { description }) => (
                <div
                  style={{ wordWrap: "break-word", wordBreak: "break-word" }}
                >
                  {description}
                </div>
              )}
            />
            <Column
              title="Roles"
              key="value"
              dataIndex="roles"
              render={renderRoles}
            />
            <Column title="Width" key="width" width={75} render={renderWidth} />
            <Column title="Actions" key="delete" render={renderActions} />
          </Table>
        </Form.Item>
      </Form> */}
      <Button
        type="primary"
        size="small"
        className="gx-mt-2"
        onClick={addWidget}
      >
        Add New Widget
      </Button>

      <Drawer
        placement="right"
        title={"Add New Widget"}
        open={showModal}
        okText="Save"
        onClose={() => {
          setShowModal(false);
        }}
        onOk={onConfirmModal}
      >
        <div className="gx-ml-3">
          <Form
            name="id"
            initialValues={{ field: "", value: "", belongsTo: [], roles: [] }}
            ref={formRef}
          >
            <Form.Item
              label="Display Name:"
              name="field"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Display Name should not be empty",
                },
              ]}
            >
              <Input
                placeholder="Enter display name"
                name="field"
                value={form?.field}
                onChange={(e) => {
                  onChangeValue("field", e.target.value);
                  if (!isEditing) {
                    formRef.current.setFields([
                      {
                        name: "value",
                        value: getFieldValueFromDisplayName(e.target.value),
                      },
                    ]);
                  }
                }}
              />
            </Form.Item>
            <Form.Item
              label="Widget Name:"
              className="gx-mt-3"
              name="value"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Widget name should not be empty",
                },
              ]}
            >
              <Input
                placeholder="Enter widget name"
                value={form?.value}
                // disabled
                // onChange={(e) => onChangeValue("value", e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label="Description:"
              className="gx-mt-3"
              name="description"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Description should not be empty",
                },
              ]}
            >
              <Input
                placeholder="Enter description for widget"
                value={form?.description}
                onChange={(e) => onChangeValue("description", e.target.value)}
              />
            </Form.Item>
            {/* <Form.Item
            label="Pages to apply:"
            className="gx-mt-3"
            name="belongsTo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={BELONGS_TO_OPTIONS}
              mode="multiple"
              placeholder="Select Pages to apply"
              value={form?.belongsTo}
              onChange={(e) => onChangeValue("belongsTo", e)}
            />
          </Form.Item> */}
            {/* <Form.Item
            label="Sources to apply:"
            className="gx-mt-3"
            name="sourcesBelongsTo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={sources}
              mode="multiple"
              placeholder="Select Pages to apply"
              value={form?.belongsTo}
              onChange={(e) => onChangeValue("belongsTo", e)}
            />
          </Form.Item> */}
            <Form.Item
              label="Database access:"
              className="gx-mt-3"
              name="databases"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                options={sources}
                placeholder="Select databases"
                value={form?.databases}
                onChange={(e) => onChangeValue("databases", e)}
              />
            </Form.Item>
            <Form.Item
              label="Version access roles:"
              className="gx-mt-3"
              name="versionRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                options={rolesOptions}
                placeholder="Select Version Roles to apply"
                value={form?.versionRoles}
                onChange={(e) => onChangeValue("versionRoles", e)}
              />
            </Form.Item>
            <Form.Item
              label="Widget access roles:"
              className="gx-mt-3"
              name="roles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                options={rolesOptions}
                placeholder="Select Roles to apply"
                value={form?.roles}
                onChange={(e) => onChangeValue("roles", e)}
              />
            </Form.Item>

            <Form.Item
              label="Unmapped data access roles:"
              className="gx-mt-3"
              name="unmappedDataAccessRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                mode="multiple"
                options={rolesOptions}
                placeholder="Select Roles to apply"
                value={form?.unmappedDataAccessRoles}
                onChange={(e) => onChangeValue("unmappedDataAccessRoles", e)}
              />
            </Form.Item>

            <Form.Item
              label="Widget Type"
              className="gx-mt-3"
              name="widgetType"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={[
                  { label: "Default", value: "default" },
                  { label: "Table", value: "table" },
                ]}
                placeholder="Select widget type"
                value={form?.widgetType}
                onChange={(e) => onChangeValue("widgetType", e)}
              />
            </Form.Item>
            {/* <Form.Item
              label="Width  (1 to 12)"
              className="gx-mt-3"
              name="width"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="number"
                max={12}
                min={0}
                value={form?.width}
                onChange={(e) => onChangeValue("width", e)}
              />
            </Form.Item> */}

            <Form.Item>
              <Button size="small" type="primary" onClick={onConfirmModal}>
                Save
              </Button>
              <Button size="small" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <Drawer
        placement="right"
        onClose={() => setSettingsDetailOpen(false)}
        open={settingsDetailOpen}
        width={900}
        className="settingsDrawer"
        destroyOnClose
        autoFocus
        title={renderTitle}
      >
        <div className="gx-ml-5">
          <SystemWidgetSettings
            loading={loading}
            roles={roles}
            currentWidget={currentWidget}
            onSave={onSaveSystemWidget}
            widgetName={widgetName}
            availableWidgets={availableWidgets}
            allWidgets={allWidgets}
            closeDialog={() => setSettingsDetailOpen(false)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default AvailableWidgetsSettings;

AvailableWidgetsSettings.defaultProps = {
  title: "",
  widgetName: "",
  availableWidgets: [],
  loading: false,
  roles: [],
  disableEdit: false,
  onSaveSystemWidget: () => {},
};

AvailableWidgetsSettings.propTypes = {
  title: PT.string,
  widgetName: PT.string,
  availableWidgets: PT.arrayOf(PT.shape()),
  loading: PT.bool,
  onSave: PT.func.isRequired,
  disableEdit: PT.bool,
  roles: PT.arrayOf(PT.shape()),
  onSaveSystemWidget: PT.func,
};
