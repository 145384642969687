import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "./Header";

import PT from "prop-types";

import { getFromGenericUrl } from "../../../appRedux/services/SystemWidget";
import RowString from "./RowString";
import "./style.css";

import { useDispatch, useSelector } from "react-redux";
import { getMetadata } from "../../../appRedux/actions";
import {
  checkFormEditable,
  checkFormValidation,
  getParent,
  getShortIdFromPage,
  getValue,
  parseItems,
} from "../../../util/Widget";
import { checkRowIsAccessible, getRoleId } from "../../../util/auth";
import FullImage from "./FullImage";
import ProjectReference from "./ProjectReference";
import RowArray from "./RowArray";
import RowEditable from "./RowEditable";
import RowLink from "./RowLink";
import RowNested from "./RowNested";
import RowSelectInput from "./RowSelectInput";
import RowSeparator from "./RowSeparator";
import RowToggleButton from "./RowToggleButton";
import RowUrl from "./RowUrl";
import Versioning from "./Versioning";

import { getImageApi } from "../../../appRedux/services/Lookup";
import { getImageFromSmiles } from "../../../appRedux/services/RegisterProcess";
import { PAGE_TYPES, STRUCTURE_WIDGET_PARAMS } from "../../../constants/Config";
import RowGroup from "./RowGroup";
import RowList from "./RowList";
import "./style.css";

const Widget = ({
  id,
  systemWidgetName,
  widgetData,
  onSaveWidget,
  renderResidualData,
  loading,
  readMode,
  hasDeleteButton,
  onDelete,
  isDefaultMode,
  resizableMode = false,
  sourceName,
  pageType,
  hideInfo,
  modalView,
  targetMetadata,
  widget = {},
  widgetOption = {},
  currentVersion,
  versions,
  onChangeVersion,
  compoundDetail,
  widgetMetadata,
  restoreMode = false,
  drawnStructureId,
  setDrawnStructureId,
  isUndefinedVersion,
  setIsUndefinedVersion,
  setCurrentVersion,
  currentCompoundVersion = {},
  setCurrentCompoundVersion,
  restoreCompound,
  deleteWidgetParameter,
  versionRoles = [],
  currentRound,
  isCurator,
  setCompoundImageLoading,
  compoundImageLoading,
}) => {
  const dispatch = useDispatch();
  const { image, rows = [] } = widget;

  const hasVersion = (versionRoles || []).includes(getRoleId());

  const titleColor = widgetOption?.titleColor || widget?.titleColor;
  let backgroundColor =
    widgetOption?.backgroundColor || widget?.backgroundColor;
  const maxHeight = widgetOption?.maxHeight || widget?.maxHeight || null;

  const [open, setOpen] = useState(true);
  const [form, setForm] = useState({});
  const [widgetVersions, setWidgetVersions] = useState(null);
  const [currentWidgetVersion, setCurrentWidgetVersion] = useState();
  const [compoundImage, setCompoundImage] = useState(null);
  const [residualData, setResidualData] = useState([]);
  const [imageCallInitialized, setImageCallInitialized] = useState(false);
  const shouldRenderFullImage = systemWidgetName === STRUCTURE_WIDGET_PARAMS;

  let targetSavedMetadata = targetMetadata || systemWidgetName;

  const { [targetSavedMetadata]: metadataForm } = useSelector(
    ({ metadata }) => metadata,
  );

  const isFormLoading = metadataForm?.loading || false;
  const [addMode, setAddMode] = useState(false);

  let targetMetadataType = null;

  if (pageType === PAGE_TYPES.EXPERIMENT || pageType === PAGE_TYPES.PROCESS) {
    targetMetadataType = "process";
  }

  if (pageType === PAGE_TYPES.PROJECT) {
    targetMetadataType = "project";
  }

  useEffect(() => {
    if ((targetSavedMetadata || "").length && sourceName) {
      dispatch(
        getMetadata(id, sourceName, targetSavedMetadata, targetMetadataType),
      );
    }
  }, [
    dispatch,
    id,
    pageType,
    sourceName,
    targetMetadataType,
    targetSavedMetadata,
  ]);

  const onRemoveResiudalData = (param) => {
    const newResidualData = _.filter(
      residualData || [],
      (data) => data?.field !== param,
    );

    setResidualData(newResidualData);
  };

  const resetForm = useCallback(() => {
    if (!_.isEmpty(metadataForm)) {
      const { parameters, version } = metadataForm;
      setForm({ ...parameters });

      if (version && _.isNull(widgetVersions)) {
        setWidgetVersions(version);
        setCurrentWidgetVersion(version);
      }
    } else {
      setForm({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadataForm, widgetVersions]);

  useEffect(() => {
    resetForm();
  }, [metadataForm, resetForm]);

  useEffect(() => {
    if (!_.isEmpty(widget) && !_.isEmpty(metadataForm)) {
      const rowsArray = (widget?.rows || []).map((item) => item.name);
      let residuals = [];
      for (const [field, value] of Object.entries(
        metadataForm?.parameters || {},
      )) {
        if (!rowsArray.includes(field)) {
          residuals.push({ field, value });
        }
      }

      if (renderResidualData && residuals.length > 0) {
        setResidualData(residuals);
      } else {
        setResidualData([]);
      }
    }
  }, [metadataForm, renderResidualData, widget]);

  const controller = useMemo(() => new AbortController(), []);

  useEffect(() => {
    if (
      !_.isEmpty(image) &&
      image?.baseUrl &&
      image?.params &&
      image?.headers &&
      !imageCallInitialized
    ) {
      setImageCallInitialized(true);
      const { baseUrl, params, headers } = image;
      const parentId = getParent(id);
      getFromGenericUrl(
        parentId,
        baseUrl,
        { ...params, background: "transbg" },
        headers,
        setCompoundImage,
        controller,
        sourceName,
        pageType,
        setCompoundImageLoading,
      );
    }

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    widget,
    imageCallInitialized,
    image,
    id,
    controller,
    sourceName,
    pageType,
  ]);

  useEffect(() => {
    const isLastVersion = (versions || []).length === compoundDetail?.version;

    if (
      (versions || []).length &&
      !_.isEmpty(drawnStructureId) &&
      shouldRenderFullImage &&
      open
    ) {
      if (isLastVersion || drawnStructureId === "DS-") {
        if (drawnStructureId && sourceName) {
          setCompoundImageLoading(true);
          getImageApi({
            id: drawnStructureId,
            sourceName,
            backgroundColor: "transbg",
            originalMaterial: false,
          }).then((res) => {
            setCompoundImage(res?.data);
            setCompoundImageLoading(false);
          });
        }
      } else {
        // Update image when drawnStructure id is changed.
        setCompoundImageLoading(true);
        getImageFromSmiles(
          drawnStructureId,
          (res) => {
            setCompoundImage(res);
            setCompoundImageLoading(false);
          },
          !isLastVersion,
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawnStructureId]);

  // useEffect(() => {
  //   const version = compoundDetail?.version || 1;
  //   const isLastVersion = (versions || []).length + 1 === version;

  //   if (restoreMode) {
  //     if (_.isEmpty(drawnStructureId) && !isLastVersion) {
  //       getCompoundConcept(
  //         getParent(id),
  //         sourceName,
  //         version,
  //         (detail) => {
  //           setCurrentVersion(detail?.version || 1);
  //           setCurrentCompoundVersion(detail);
  //           // setDrawnStructureId(detail?.drawnStructureId);
  //           if ((detail?.conceptValue || "").length) {
  //             setIsUndefinedVersion(true);
  //           } else {
  //             setIsUndefinedVersion(false);
  //             getImageFromSmiles(detail?.drawnStructureId, (res) => {
  //               // setCompoundImage(res);
  //               // setCompoundImageLoading(false);
  //             });
  //           }
  //         },
  //         () => {},
  //         () => {},
  //         () => {}
  //       );
  //     } else {
  //       getImageFromSmiles(drawnStructureId, (res) => {
  //         // setCompoundImage(res);
  //         // setCompoundImageLoading(false);
  //       });
  //     }
  //   } else {
  //     if (
  //       compoundDetail &&
  //       compoundDetail?.version &&
  //       versions.length &&
  //       !_.isEmpty(widget?.image)
  //     ) {
  //       const version = compoundDetail?.version || 1;
  //       const isLastVersion = (versions || []).length === version;

  //       if (version !== currentImageVersion) {
  //         setCurrentImageVersion(version);
  //         if (isLastVersion) {
  //           setImageCallInitialized(false);
  //         }
  //         if (!isLastVersion) {
  //           setImageCallInitialized(true);
  //           setCompoundImageLoading(true);
  //           getImageFromSmiles(drawnStructureId, (res) => {
  //             // setCompoundImage(res);
  //             setCompoundImageLoading(false);
  //           });
  //         }
  //       }
  //     }
  //   }
  // }, [
  //   drawnStructureId,
  //   compoundDetail,
  //   imageCallInitialized,
  //   versions,
  //   restoreMode,
  //   id,
  //   sourceName,
  //   setCurrentVersion,
  //   setCurrentCompoundVersion,
  //   setIsUndefinedVersion,
  //   widget?.image,
  //   currentImageVersion,
  // ]);

  const checkRowIsEditable = (isEditable) => {
    const obj = _.isObject(isEditable) ? isEditable : {};
    const currentRole = getRoleId();
    const access = _.get(obj, currentRole) || false;

    return access;
  };

  const checkIsWidgetEmpty = (rows = []) => {
    if (resizableMode) {
      return false;
    }

    const row = rows[0];
    const { targetObj, value } = row || {};

    const items = getValue(widgetData, targetObj, value) || [];

    if (row?.type === "ARRAY" && !items.length) {
      return true;
    }

    return false;
  };

  const unmappedDataAccessRoles =
    _.get(widgetMetadata, "unmappedDataAccessRoles") || [];
  const shouldRenderResidualData =
    unmappedDataAccessRoles.includes(getRoleId());

  const conceptValue = restoreMode
    ? currentCompoundVersion?.conceptValue || ""
    : _.get(widgetData, "compoundDetail.conceptValue") || "";
  const racemate = _.get(widgetData, "compoundDetail.racemate") || false;
  const isUnknownStructure = !!conceptValue.length;
  const hideStructureEditableFields =
    (systemWidgetName || "") === STRUCTURE_WIDGET_PARAMS &&
    !!_.get(widgetData, "compoundDetail.molecularWeight");

  const isStructureWidget = systemWidgetName === STRUCTURE_WIDGET_PARAMS;

  const isLastVersion = (versions || []).length === currentVersion;

  /**
   * If compound structure widget has versions and current version is not a last one, we should highlight header.
   */
  if (
    currentVersion &&
    (versions || []).length > 1 &&
    !isLastVersion &&
    isStructureWidget &&
    pageType === PAGE_TYPES.COMPOUND
  ) {
    backgroundColor = "#ffbb96";
  }

  // Filter rows has groupId in it.
  const rowsFiltereByGroupId = _.filter(
    rows,
    (row) => row?.type === "GROUP" || _.isEmpty(row?.groupId),
  );

  return (
    <>
      {checkIsWidgetEmpty(rows) && !readMode ? null : (
        <div
          className={`widget-card ant-card ant-card-bordered gx-mr-3 ${
            (open ? "isOpen" : "", modalView ? "modal-view" : "gx-card-widget")
          }
        ${resizableMode || readMode || !isDefaultMode ? "" : "gx-width-400"}
        ${resizableMode && open ? "resizable" : ""}
        `}
          key={uuidv4()}
          style={
            maxHeight && open
              ? {
                  height: `${maxHeight}px`,
                  overflowY: "scroll",
                }
              : { height: "fit-content" }
          }
        >
          <Header
            title={`${widgetMetadata?.field || systemWidgetName}`}
            titleColor={titleColor}
            backgroundColor={backgroundColor}
            isEditable={
              checkFormEditable(rows) &&
              !readMode &&
              !hideStructureEditableFields
            }
            rows={rowsFiltereByGroupId}
            form={form}
            addMode={addMode}
            onSave={() => {
              if (checkFormValidation(rows, form)) {
                onSaveWidget(form, targetSavedMetadata, widgetMetadata?.widget);
                const lastVersion = widgetVersions + 1;
                setCurrentWidgetVersion(lastVersion);
                setWidgetVersions(lastVersion);
                setAddMode(false);
              } else {
              }
            }}
            setAddMode={setAddMode}
            open={open}
            setOpen={setOpen}
            loading={loading}
            info={widget?.info}
            hasDeleteButton={hasDeleteButton}
            onDelete={onDelete}
            isDefaultMode={isDefaultMode}
            hideInfo={hideInfo}
            resetForm={resetForm}
          />
          {!_.isEmpty(compoundImage) && shouldRenderFullImage && (
            <>
              <div className="fullImageWrapper">
                <FullImage
                  id={id}
                  compoundImage={compoundImage}
                  loading={compoundImageLoading}
                  width={image?.width}
                  showCircularProgress={false}
                />

                {conceptValue.length || isUndefinedVersion ? (
                  <div className="fullImageOverlay">
                    <div
                      className="gx-d-flex"
                      style={{ flexDirection: "column" }}
                    >
                      <p>Undefined Structure</p>
                      <p style={{ fontSize: "14px", textAlign: "center" }}>
                        Descriptor:{" "}
                        <span className="gx-text-primary">{conceptValue}</span>
                      </p>
                    </div>
                  </div>
                ) : null}

                {racemate ? (
                  <span className="racemateOverlay gx-text-primary">
                    racemic mixture
                  </span>
                ) : null}
              </div>
            </>
          )}

          {open && (
            <>
              <div className="ant-card-body widget-body">
                {loading || isFormLoading || compoundImageLoading ? (
                  <CircularProgress className="gx-loader-400 loader" />
                ) : (
                  <>
                    {(rowsFiltereByGroupId || []).map((row, index) => (
                      <>
                        {row?.type === "GROUP" ? (
                          <RowGroup
                            groupId={row?.groupId}
                            title={row?.field}
                            rows={rows}
                            form={form}
                            addMode={addMode}
                            systemWidgetName={systemWidgetName}
                            hideInfo={hideInfo}
                            sourceName={sourceName}
                          />
                        ) : null}
                        {row?.type === "STRING" ? (
                          <>
                            <RowString
                              field={row?.field}
                              value={getValue(
                                widgetData,
                                row?.targetObj,
                                row?.value,
                              )}
                              info={row?.info}
                              hideInfo={hideInfo}
                              isAccessible={checkRowIsAccessible(
                                row?.isAccessible,
                              )}
                              currentRound={currentRound}
                              isCopiable={row?.isCopiable}
                              hasTooltip={row?.hasTooltip}
                            />
                          </>
                        ) : null}
                        {row?.type === "STRING_DATE" && (
                          <RowString
                            field={row?.field}
                            value={getValue(
                              widgetData,
                              row?.targetObj,
                              row?.value,
                            )}
                            info={row?.info}
                            isDate
                            hideInfo={hideInfo}
                            isAccessible={checkRowIsAccessible(
                              row?.isAccessible,
                            )}
                            currentRound={currentRound}
                            isCopiable={row?.isCopiable}
                            hasTooltip={row?.hasTooltip}
                          />
                        )}
                        {row?.type === "URL" && (
                          <RowUrl
                            id={
                              pageType === PAGE_TYPES.QUALITY ||
                              pageType === PAGE_TYPES.EXPERIMENT
                                ? getParent(id)
                                : id
                            }
                            sourceName={sourceName}
                            row={row}
                            info={row?.info}
                            hideInfo={hideInfo}
                            isAccessible={checkRowIsAccessible(
                              row?.isAccessible,
                            )}
                          />
                        )}
                        {row?.type === "LINK" && (
                          <RowLink
                            field={row?.field}
                            displayValue={row?.displayValue}
                            url={row?.url}
                            info={row?.info}
                            shortId={getShortIdFromPage(id)}
                            hideInfo={hideInfo}
                          />
                        )}
                        {row?.type === "ARRAY" && (
                          <RowArray
                            row={row}
                            data={widgetData}
                            info={row?.info}
                            sourceName={sourceName}
                            hideInfo={hideInfo}
                          />
                        )}
                        {row?.type === "LIST" && (
                          <RowList
                            field={row?.field}
                            name={row?.name}
                            value={_.get(form, row?.name)}
                            index={index}
                            form={form}
                            info={row?.info}
                            sourceName={sourceName}
                            onChange={(val) => {
                              form[row?.name] = JSON.stringify(val);
                            }}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                            isAccessible={checkRowIsAccessible(
                              row?.isAccessible,
                            )}
                            addmode={addMode}
                            hasLink={row?.hasLink}
                            baseUrl={row?.baseUrl}
                          />
                        )}
                        {row?.type === "EDITABLE" && (
                          <RowEditable
                            field={row?.field}
                            index={index}
                            name={row?.name}
                            value={_.get(form, row?.name)}
                            limit={row?.limit}
                            form={form}
                            addMode={addMode}
                            onChange={(val) => {
                              form[row?.name] = val;
                            }}
                            onChangeUniqueness={(val) => {
                              form[`${row?.name}__unique`] = val;
                            }}
                            info={row?.info}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                            isAccessible={checkRowIsAccessible(
                              row?.isAccessible,
                            )}
                            systemWidgetName={systemWidgetName}
                            isTrimmed={row?.isTrimmed || false}
                            hideInfo={hideInfo}
                            isCopiable={row?.isCopiable}
                            required={row?.required}
                            isUnique={row?.isUnique}
                            sourceName={sourceName}
                          />
                        )}
                        {row?.type === "PROJECT_REFERENCE" && (
                          <ProjectReference
                            field={row?.field}
                            index={index}
                            name={row?.name}
                            value={_.get(form, row?.name)}
                            limit={row?.limit}
                            form={form}
                            addMode={addMode}
                            onChange={(val) => {
                              form[row?.name] = val;
                            }}
                            info={row?.info}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                            hideInfo={hideInfo}
                          />
                        )}
                        {row?.type === "EDITABLE_DATE" && (
                          <>
                            <RowEditable
                              field={row?.field}
                              index={index}
                              name={row?.name}
                              value={_.get(form, row?.name)}
                              addMode={addMode}
                              onChange={(val) => {
                                form[row?.name] = val;
                              }}
                              type="date"
                              info={row?.info}
                              isEditable={checkRowIsEditable(row?.isEditable)}
                              isAccessible={checkRowIsAccessible(
                                row?.isAccessible,
                              )}
                              onChangeUniqueness={(val) => {
                                form[`${row?.name}__unique`] = val;
                              }}
                              hideInfo={hideInfo}
                              isCopiable={row?.isCopiable}
                              required={row?.required}
                              isUnique={row?.isUnique}
                              sourceName={sourceName}
                            />
                          </>
                        )}
                        {row?.type === "TOGGLE_BUTTON" && (
                          <RowToggleButton
                            field={row?.field}
                            value={_.get(form, row?.name)}
                          />
                        )}
                        {row?.type === "NESTED" && (
                          <RowNested
                            field={row?.field}
                            items={parseItems(row?.items)}
                            info={row?.info}
                            addMode={addMode}
                            value={_.get(form, row?.name)}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                            onChange={(val) => {
                              form[row?.name] = val;
                            }}
                          />
                        )}
                        {row?.type === "SEPARATOR" && (
                          <RowSeparator title={row?.title} />
                        )}
                        {row?.type === "SELECT_INPUT" && (
                          <RowSelectInput
                            field={row?.field}
                            index={index}
                            name={row?.name}
                            options={row?.items}
                            value={_.get(form, row?.name)}
                            addMode={addMode}
                            onChange={(val) => {
                              form[row?.name] = val;
                            }}
                            info={row?.info}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                          />
                        )}
                      </>
                    ))}
                    {renderResidualData && shouldRenderResidualData
                      ? (residualData || []).map((item) => (
                          <RowString
                            field={item?.field}
                            value={item?.value}
                            residualData
                            isCopiable={item?.isCopiable}
                            hasTooltip={item?.hasTooltip}
                            hideInfo={hideInfo}
                            deleteWidgetParameter={(param) => {
                              const newForm = form;
                              delete newForm[param];
                              setForm(newForm);
                              // return deleteWidgetParameter(
                              //   systemWidgetName,
                              //   sourceName,
                              //   id,
                              //   metadataForm?.parameters,
                              //   param
                              // );
                              onRemoveResiudalData(param);
                            }}
                            isAccessible={true}
                            currentRound={currentRound}
                            addMode={addMode}
                          />
                        ))
                      : null}

                    {hasVersion &&
                    (versions || []).length &&
                    systemWidgetName === STRUCTURE_WIDGET_PARAMS ? (
                      <Versioning
                        currentVersion={currentVersion}
                        versions={versions}
                        onChangeVersion={(page) => {
                          onChangeVersion(page);
                        }}
                        renderRestore={restoreMode && isCurator}
                        restoreCompound={restoreCompound}
                      />
                    ) : null}
                    {hasVersion &&
                    widgetVersions &&
                    systemWidgetName !== STRUCTURE_WIDGET_PARAMS ? (
                      <Versioning
                        currentVersion={currentWidgetVersion}
                        versions={_.times(widgetVersions, (index) => index + 1)}
                        // onChangeVersion={(page) => {
                        //   onChangeVersion(page);
                        // }}

                        onChangeVersion={(cVersion) => {
                          setCurrentWidgetVersion(cVersion);

                          dispatch(
                            getMetadata(
                              id,
                              sourceName,
                              targetSavedMetadata,
                              targetMetadataType,
                              cVersion,
                              systemWidgetName,
                            ),
                          );
                        }}
                        renderRestore={null}
                        restoreCompound={restoreCompound}
                      />
                    ) : null}
                    {systemWidgetName.includes("ElementalAnalysis") &&
                      _.isEmpty(_.get(widgetData, "elementalAnalysis")) &&
                      isUnknownStructure && (
                        <span
                          className="gx-text-grey gx-mt-4"
                          style={{
                            textAlign: "center",
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          Data cannot be calculated for undefined structures.
                        </span>
                      )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

Widget.defaultProps = {
  id: "",
  systemWidgetName: "",
  widgetData: {},
  onSaveWidget: () => {},
  loading: false,
  renderResidualData: false,
  readMode: false,
  previewData: {},
  hasDeleteButton: false,
  onDelete: () => {},
  isDefaultMode: true,
  resizableMode: false,
  sourceName: "",
  pageType: "",
  hideInfo: false,
  modalView: false,
  targetMetadata: null,
};

Widget.propTypes = {
  id: PT.string,
  systemWidgetName: PT.string,
  widgetData: PT.shape(),
  onSaveWidget: PT.func,
  loading: PT.bool,
  renderResidualData: PT.bool,
  readMode: PT.bool,
  previewData: PT.shape(),
  hasDeleteButton: PT.bool,
  onDelete: PT.func,
  isDefaultMode: PT.bool,
  resizableMode: PT.bool,
  sourceName: PT.string,
  pageType: PT.string,
  hideInfo: PT.bool,
  modalView: PT.bool,
  targetMetadata: PT.string,
};

export default Widget;
