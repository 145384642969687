import {
  AppstoreTwoTone,
  CopyTwoTone,
  DeleteTwoTone,
  LockTwoTone,
  UnlockTwoTone,
} from "@ant-design/icons";
import { downloadDocument } from "../../../appRedux/services/Document";
import { ReactComponent as ExpandIcon } from "../../../assets/vendors/expand.svg";
import { Popconfirm, Popover, Tag } from "antd";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { popoverContent } from "../../SearchPage/helper";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getCompoundType } from "../../../util/url";

export const renderTitleWithActionButtons = (document, onSelectDocument) => {
  const { id, fileName, sourceName } = document;

  return (
    <div>
      <AppstoreTwoTone
        className="gx-mr-2"
        onClick={() => onSelectDocument(document)}
      />
      <span
        className="gx-text-primary gx-pointer"
        onClick={() => downloadDocument(id, sourceName, fileName)}
      >
        {fileName || "name is empty"}
      </span>
    </div>
  );
};

export const renderActionsButtons = (
  document,
  onEdit,
  onDelete,
  cloneCurrentDocument,
  showActionButton,
  isLockingAllowed,
  hasPermission,
  locked,
  onLock
) => {
  return (
    <div className="gx-d-flex">
      {showActionButton && (
        <Popover content="Clone Document">
          <CopyTwoTone
            className="gx-pointer"
            onClick={() => cloneCurrentDocument(document)}
          />
        </Popover>
      )}

      {!locked && (
        <Popover content="Show Detail">
          <ExpandIcon
            className="gx-ml-2 gx-mr-2 expand-icon"
            onClick={() => onEdit(document)}
          />
        </Popover>
      )}

      {showActionButton && (
        <Popconfirm
          title="Are you sure to delete?"
          okText="Yes"
          cancelText="No"
          onConfirm={() => onDelete(document)}
        >
          <DeleteTwoTone className="gx-mr-2" twoToneColor="#f44336" />
        </Popconfirm>
      )}

      {isLockingAllowed && hasPermission && (
        <>
          {locked ? (
            <Popover content="Unlock document">
              <UnlockTwoTone
                onClick={() => onLock(document, false)}
                twoToneColor="#f44336"
              />
            </Popover>
          ) : (
            <Popover content="Lock document">
              <LockTwoTone
                twoToneColor="#52c41a"
                onClick={() => onLock(document, true)}
              />
            </Popover>
          )}
        </>
      )}
    </div>
  );
};

export const renderData = (document, searchTerm, field, sources) => {
  const { parameters = {}, accessionNumber, sourceName } = document;

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        maxWidth: "500px",
      }}
    >
      {_.map(parameters, (item, key) => {
        if (key === "entityIds" && !_.isEmpty(item)) {
          return (
            <>
              {_.map(item.split(","), (compoundId) => {
                const isHit = searchTerm === compoundId;

                return (
                  <Popover content={popoverContent(compoundId, sourceName)}>
                    <Tag
                      className={`${isHit ? "gx-text-success" : ""}`}
                      key={uuidv4()}
                      style={{
                        whiteSpace: "break-spaces",
                        textOverflow: "ellipsis",
                      }}
                      color={field === key ? "#d9f7be" : "#f0f0f0"}
                    >
                      <Link
                        to={`/${sourceName}/${getCompoundType({
                          sources,
                          sourceName: sourceName,
                          id: compoundId,
                        })}/${compoundId}`}
                        replace
                        target="_blank"
                        className="gx-text-primary"
                      >
                        {compoundId}
                      </Link>
                    </Tag>
                  </Popover>
                );
              })}
            </>
          );
        }

        let targetItem = item;

        if (typeof item === "object") {
          targetItem = item?.id || "";
        }

        return (
          <>
            {!_.isEmpty(targetItem) ? (
              <Popover content={<span>{key}</span>}>
                <Tag
                  key={uuidv4()}
                  style={{
                    whiteSpace: "break-spaces",
                    textOverflow: "ellipsis",
                  }}
                  color={field === key ? "#d9f7be" : "#f0f0f0"}
                >
                  <span className="gx-text-black">{targetItem}</span>
                </Tag>
              </Popover>
            ) : null}
          </>
        );
      })}

      {accessionNumber && (
        <Popover content={<span>{"Accession Number"}</span>}>
          <Tag
            key={uuidv4()}
            style={{ whiteSpace: "break-spaces" }}
            color={field === "accessionNumber" ? "#d9f7be" : "#f0f0f0"}
          >
            <span
              className="gx-text-black"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {accessionNumber}
            </span>
          </Tag>
        </Popover>
      )}
    </div>
  );
};
