import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { Button } from "antd";
import PT from "prop-types";
import React from "react";

const UpdateCard = ({
  uniqueReason,
  onUpdateCompound,
  isProcess,
  updateIds,
}) => {
  return (
    <>
      {!(updateIds || []).length ? (
        <div>
          <p className="gx-text-primary">{uniqueReason}</p>
          <Button type="primary" size="small" onClick={onUpdateCompound}>
            Update
          </Button>
        </div>
      ) : null}

      {isProcess ? (
        <p className="gx-text-danger gx-mt-2">
          <ExclamationCircleTwoTone className="gx-mr-2" twoToneColor="F44336" />
          Updating Process is restricted.
        </p>
      ) : null}
    </>
  );
};

UpdateCard.defaultProps = {
  updateIds: [],
  isProcess: false,
};

UpdateCard.propTypes = {
  onUpdateCompound: PT.func.isRequired,
  updateIds: PT.arrayOf(PT.shape),
  isProcess: PT.bool,
};

export default UpdateCard;
