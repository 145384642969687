import { all, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_USER_DATA_REQUESTED,
  GET_USER_DATA_SUCCESS,
  SAVE_USER_DATA_REQUESTED,
  SAVE_USER_DATA_SUCCESS,
} from "../../constants/ActionTypes";

function* fetchUserData(action) {
  yield put({
    type: GET_USER_DATA_SUCCESS,
    payload: action.payload,
  });
}

function* fetchSaveUserData(action) {
  yield put({
    type: SAVE_USER_DATA_SUCCESS,
    payload: {
      type: action.payload.type,
      data: action.payload.data,
    },
  });
}

export function* getUserData() {
  yield takeLatest(GET_USER_DATA_REQUESTED, fetchUserData);
}

export function* saveUserData() {
  yield takeLatest(SAVE_USER_DATA_REQUESTED, fetchSaveUserData);
}

export default function* rootSaga() {
  yield all([fork(getUserData), fork(saveUserData)]);
}
