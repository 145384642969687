import React from "react";
import PT from "prop-types";

import "./style.css";

const SearchBox = ({
  style = {},
  styleName,
  placeholder,
  onChange,
  value,
  onKeyUp,
}) => {
  return (
    <div className={`gx-search-bar gx-search__box ${styleName}`} style={style}>
      <div className="gx-form-group">
        <span className="gx-search-icon gx-pointer">
          <i className="icon icon-search" />
        </span>
        <input
          className="ant-input"
          type="search"
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          onKeyUp={onKeyUp}
        />
      </div>
    </div>
  );
};

SearchBox.propTypes = {
  styleName: PT.shape(),
  value: PT.any,
};

SearchBox.defaultProps = {
  styleName: "",
  value: "",
};

export default SearchBox;
