import { Button, Form, Input, Popover, Select } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { DATA_SEARCH_OPERATORS } from "../../../constants/Config";
import { getRole } from "../../../util/auth";
import InputTag from "../../InputTag";
import { QuestionCircleTwoTone } from "@ant-design/icons";

const DocumentSearchForm = ({
  targetValue,
  setTargetValue,
  onSearch,
  field,
  documentOptions,
  availableSearchWidgetParams = [],
  selectedCollection,
  setSelectedCollection,
  option,
  setOption,
  operator,
  setOperator,
  searchValue,
  setSearchValue,
}) => {
  const formRef = useRef();
  const [widgetOptions, setWidgetOptions] = useState([]);
  const currentSelectedOperator = formRef.current?.getFieldValue("operator");

  const entityIdsRef = useRef();

  const documentOptionsFiltered = _.filter(
    documentOptions,
    ({ viewAccessRoles = [] }) => viewAccessRoles.includes(getRole())
  );
  const mappedDocumentOptions = _.map(documentOptionsFiltered, (item) => ({
    label: item?.field,
    value: item?.value,
  }));

  // Disabled searching when collection is changed.
  useEffect(() => {
    if (selectedCollection) {
      const target = _.filter(
        availableSearchWidgetParams,
        (param) => param?.widgetName === selectedCollection
      );

      setWidgetOptions([
        {
          label: "Accession Number",
          value: "accessionNumber",
        },
        ...target,
      ]);

      // onSearch(selectedCollection);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollection]);

  const onChangeCollection = (systemWidgetName) => {
    setSelectedCollection(systemWidgetName);

    formRef.current.setFieldsValue({
      widgetOption: null,
      search: "",
    });

    const target = _.filter(
      availableSearchWidgetParams,
      (param) => param?.widgetName === systemWidgetName
    );

    setWidgetOptions([
      {
        label: "Accession Number",
        value: "accessionNumber",
      },
      ...target,
    ]);

    setTargetValue(systemWidgetName);
    // onSearch(systemWidgetName);
  };

  return (
    <Form
      layout="inline"
      ref={formRef}
      className="gx-mr-5"
      style={{ flexwrap: "wrap" }}
      onFinish={({ search }) => {
        let searchValue = search;

        if (currentSelectedOperator === "IN") {
          const ids = entityIdsRef.current?.changeVal();
          searchValue = ids;
        }

        setSearchValue(searchValue);

        onSearch(
          targetValue || selectedCollection,
          operator,
          option,
          searchValue
        );
      }}
    >
      <Form.Item
        label={field}
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select collection",
        //   },
        // ]}
      >
        <div className="gx-d-flex">
          <Select
            size="small"
            showSearch
            placeholder="Select collection"
            dropdownMatchSelectWidth={false}
            options={mappedDocumentOptions || []}
            onChange={onChangeCollection}
            defaultValue={selectedCollection}
            allowClear
          />
        </div>
      </Form.Item>
      <Form.Item>
        <span>where</span>
      </Form.Item>

      <Form.Item
        name="widgetOption"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select widget option",
        //   },
        // ]}
      >
        <Select
          size="small"
          showSearch
          placeholder="Select Value"
          options={widgetOptions}
          dropdownMatchSelectWidth={false}
          value={option}
          onChange={(e) => setOption(e)}
          allowClear
        />
      </Form.Item>

      <Form.Item
        name="operator"
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select operator",
        //   },
        // ]}
      >
        <Select
          size="small"
          value={operator}
          onChange={(e) => setOperator(e)}
          placeholder="Select Operator"
          options={DATA_SEARCH_OPERATORS}
          dropdownMatchSelectWidth={false}
          allowClear
        />
      </Form.Item>
      {_.find(DATA_SEARCH_OPERATORS, { value: operator })?.type ===
      "NUMERIC" ? (
        <Popover content="This field only accepts numeric query values.">
          <QuestionCircleTwoTone
            size="small"
            twoToneColor="#52c41a"
            style={{
              marginTop: "10px",
            }}
          />
        </Popover>
      ) : null}

      {currentSelectedOperator === "IN" ? (
        <div className="gx-mb-2">
          <InputTag sourceName={"ACT"} size="small" ref={entityIdsRef} />
        </div>
      ) : (
        <Form.Item
          name="search"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please select search value",
          //   },
          // ]}
        >
          <Input
            placeholder="Enter Value"
            size="small"
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch(targetValue, operator, option, e.target.value);
              }
            }}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </Form.Item>
      )}

      <Form.Item>
        <Button
          type="primary"
          size="small"
          htmlType="submit"
          style={{ marginTop: "5px" }}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DocumentSearchForm;
