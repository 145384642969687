import _ from "lodash";

/**
 * Try to get value defined from system widget.
 * We expect `targetObj` and `value` are available from parent.
 *
 * @param {Array} dataSources data sources
 * @param {Array} roles roles object with authorization
 * @param {String} userRole current user's role
 * @param {String} accessIdentifier access identifier
 */
export const filterByAuthorizedRoles = (
  dataSources,
  roles,
  userRole,
  accessIdentifier
) => {
  const foundRole = roles.filter((item) => item?.name === userRole);

  if (foundRole && foundRole.length > 0) {
    const access = _.get(foundRole[0], accessIdentifier) || "";

    const accessLowered = _.map(access, (a) => _.lowerCase(a));

    const filtered = dataSources.filter((item) =>
      accessLowered.includes(_.lowerCase(item?.sourceName || ""))
    );

    return filtered;
  }

  return [];
};

/**
 * Try to get search ops defined from system widget.
 * We expect `targetObj` and `value` are available from parent.
 *
 * @param {Array} dataSources data sources
 * @param {Array} roles roles object with authorization
 * @param {String} userRole current user's role
 * @param {String} accessIdentifier access identifier
 */
export const filterSearchOpsByRole = (dataSources, roles, userRole) => {
  const foundRole = roles.filter((item) => item?.name === userRole);

  if (foundRole && foundRole.length > 0) {
    const access = _.get(foundRole[0], "structureTypeAccess");
    const filtered = dataSources.filter((item) => access.includes(item?.value));

    return filtered;
  }

  return dataSources;
};

/**
 * Generate individual query
 * @param {String} field
 * @param {Array || String} values
 * @param {String} operator
 * @returns
 */
export const generateIndividualQuery = ({ field, values, operator = "EQ" }) => {
  return {
    [field]: {
      values,
      operator,
    },
  };
};

/**
 * Merge queries and build request body
 * @param {Array} queries
 * @param {Number} order
 * @param {String} language
 * @returns
 */
export const mergeQueries = (queries, order, language) => {
  let documentObject = {};

  _.forEach(queries, (value, field) => {
    documentObject[field] = value;
  });

  return {
    documentQueries: [
      {
        queryJoinType: "AND",
        documentQuery: { ...queries },
        order,
        language,
      },
    ],
  };
};

/**
 * Define input field type based on search type.
 * @param {*} changedValues
 */
export const handleFormValuesChange = (changedValues, setTargetInputType) => {
  const fieldName = Object.keys(changedValues)[0];

  if (fieldName === "operator") {
    const value = changedValues[fieldName];
    let targetInputType = "text";

    switch (value) {
      case "IS_NULL":
      case "IS_DEFINED":
        targetInputType = "null";
        break;
      case "GT":
      case "GTE":
      case "LT":
      case "LTE":
        targetInputType = "number";
        break;

      case "IN":
        targetInputType = "tag";
        break;
      default:
        targetInputType = "text";
        break;
    }

    setTargetInputType(targetInputType);
  }
};
