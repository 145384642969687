import {
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { Button, Modal, Popconfirm, Table } from "antd";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  getUserWidget,
  saveUserWidget,
} from "../../../appRedux/services/SystemWidget";
import { UPLOAD_FILE_MAPPING } from "../../../constants/Config";
import { getUserName } from "../../../util/auth";
import {
  getOptions,
  renderManageSettingsModal,
  renderMappingSettings,
  renderSettingsEditModal,
} from "../helper";

import "./style.css";

const UploadSettings = ({
  tableColumns,
  currentSettings,
  setCurrentSettings,
  selectedSettings,
  setSelectedSettings,
  headers,
  selectedId,
  setSelectedId,
  setIsProject,
  setOnlyQualities,
  availableWidgets,
  selectedType,
  collectionOptions,
  setSelectedCollectionOptions,
  selectedCollectionOptions,
  selectedWidgetName,
  setSelectedWidgetName,
  targetFieldOptions,
  setTargetFieldOptions,
  documentUploadtype,
}) => {
  const formRef = useRef();
  const settingsFormRef = useRef();
  const [showModal, setShowModal] = useState(false);
  const [settingsModal, setSettingsModal] = useState(false);
  const [settingsEditModal, setSettingsEditModal] = useState(false);
  const userName = getUserName();

  const settingsOptions = currentSettings.map((setting) => ({
    field: setting?.mappingName,
    value: setting?.mappingName,
  }));

  const onChangeSettings = (e) => {
    const foundSettings = _.find(currentSettings, { mappingName: e });
    setSelectedSettings(foundSettings);
  };

  useEffect(() => {
    if (selectedWidgetName) {
      const foundOption = _.find(collectionOptions, {
        value: selectedWidgetName,
      });

      if (foundOption) {
        setTargetFieldOptions(foundOption?.widget);
      }
    }
  }, [collectionOptions, selectedWidgetName, setTargetFieldOptions]);

  useEffect(() => {
    if (selectedCollectionOptions) {
      setSelectedWidgetName(selectedCollectionOptions);
    }
  }, [selectedCollectionOptions, setSelectedWidgetName]);

  const options = getOptions({ targetFieldOptions });

  const onEdit = (item) => {
    setShowModal(true);
    setTimeout(() => {
      formRef.current?.setFields([
        {
          name: "key",
          value: item?.key,
        },
        {
          name: "widgetName",
          value: item?.widgetName,
        },
        {
          name: "field",
          value: item?.field,
        },
        {
          name: "type",
          value: item?.type,
        },
        {
          name: "additionalField",
          value: item?.additionalField,
        },
      ]);
    }, 300);
  };

  const onDelete = (field) => {
    const mappingItems = selectedSettings?.mapping || [];
    const removedItems = mappingItems.filter((item) => item?.field !== field);

    const fileMapping = currentSettings.map((item) => {
      if (item?.mappingName !== selectedSettings?.mappingName) {
        return item;
      } else {
        return {
          ...selectedSettings,
          mapping: removedItems,
        };
      }
    });

    saveUserWidget(
      UPLOAD_FILE_MAPPING,
      userName,
      setCurrentSettings,
      () => {},
      JSON.stringify({ options: fileMapping }),
      true,
      NotificationManager
    );
  };
  const saveForm = async () => {
    const dataKey = formRef.current.getFieldValue("key");
    const widgetName = formRef.current.getFieldValue("widgetName");
    const field = formRef.current.getFieldValue("field");
    const additionalField = formRef.current.getFieldValue("additionalField");

    const obj = {
      dataKey,
      widgetName,
      field,
      additionalField,
    };

    let found = false;
    const foundMapping = _.find(currentSettings, {
      mappingName: selectedSettings?.mappingName,
    });
    const newMapping = foundMapping?.mapping.map((item) => {
      if (item?.dataKey !== obj?.dataKey) {
        return item;
      } else {
        found = true;
        return obj;
      }
    });

    if (!found) {
      newMapping.push(obj);
    }

    const newSettings = currentSettings.map((item) => {
      if (item?.mappingName !== selectedSettings?.mappingName) {
        return item;
      } else {
        return {
          ...selectedSettings,
          mapping: newMapping,
        };
      }
    });

    await saveUserWidget(
      UPLOAD_FILE_MAPPING,
      userName,
      setCurrentSettings,
      () => {},
      JSON.stringify({ options: newSettings }),
      true,
      NotificationManager
    );

    setTimeout(() => {
      getUserWidget(UPLOAD_FILE_MAPPING, userName, setCurrentSettings, true);
    }, 1000);

    clearForm();
    setShowModal(false);
  };

  const clearForm = () => {
    formRef.current?.setFields([
      {
        name: "key",
        value: "",
      },
      {
        name: "widgetName",
        value: "",
      },
      {
        name: "field",
        value: "",
      },
      {
        name: "type",
        value: "",
      },
      {
        name: "additionalField",
        value: "",
      },
    ]);
  };

  const onEditSettings = (item) => {
    setSettingsEditModal(true);
    setTimeout(() => {
      settingsFormRef.current?.setFields([
        {
          name: "name",
          value: item?.mappingName,
        },
        {
          name: "id",
          value: item?.index,
        },
      ]);
    }, 300);
  };

  const onDeleteSettings = async (item) => {
    const newSettings = currentSettings.filter(
      (setting, index) => index !== item?.index
    );

    await saveUserWidget(
      UPLOAD_FILE_MAPPING,
      userName,
      setCurrentSettings,
      () => {},
      JSON.stringify({ options: newSettings }),
      true,
      NotificationManager
    );

    setSettingsEditModal(false);
  };

  const renderSettingsModal = () => {
    const currentSettingsWithIndex = currentSettings.map((item, index) => ({
      ...item,
      index,
    }));

    return (
      <>
        <Table dataSource={currentSettingsWithIndex} pagination={false}>
          <Table.Column
            key={1}
            title="Name"
            dataIndex="mappingName"
          ></Table.Column>
          <Table.Column
            key={2}
            title="Action"
            render={(p, item) => {
              return (
                <div className="gx-d-flex">
                  <EditTwoTone
                    twoToneColor="#038FDE"
                    className="gx-mr-3"
                    onClick={() => onEditSettings(item)}
                  />
                  <Popconfirm
                    title="Are you sure to delete?"
                    onConfirm={() => onDeleteSettings(item)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteTwoTone
                      className="gx-pointer"
                      twoToneColor="#f44336"
                    />
                  </Popconfirm>
                </div>
              );
            }}
          ></Table.Column>
        </Table>
        <div className="gx-mt-2">
          <Button
            type="primary"
            size="small"
            onClick={() => {
              settingsFormRef.current?.setFields([
                {
                  name: "name",
                  value: "",
                },
                {
                  name: "id",
                  value: "",
                },
              ]);
              setSettingsEditModal(true);
            }}
          >
            <PlusCircleTwoTone /> Add New Key
          </Button>
        </div>
      </>
    );
  };

  const onSaveSettings = async () => {
    const id = settingsFormRef.current?.getFieldValue("id");
    const name = settingsFormRef.current?.getFieldValue("name");

    let newSettings = [];
    if (!_.isNumber(id)) {
      newSettings = [
        ...currentSettings,
        {
          mappingName: name,
          mapping: [],
        },
      ];
    } else {
      newSettings = currentSettings.map((item, index) => {
        if (index !== id) {
          return item;
        } else {
          return {
            ...item,
            mappingName: name,
          };
        }
      });
    }

    await saveUserWidget(
      UPLOAD_FILE_MAPPING,
      userName,
      setCurrentSettings,
      () => {},
      JSON.stringify({ options: newSettings }),
      true,
      NotificationManager
    );

    setSettingsEditModal(false);
  };

  const availableWidgetsWithKeys = _.map(
    _.uniq(availableWidgets, "value"),
    (item, index) => ({ ...item, key: index })
  );

  const mapping = _.find(currentSettings, {
    mappingName: selectedSettings?.mappingName,
  });

  const dataSource = _.map(mapping?.mapping || [], (k, v) => ({
    key: k?.dataKey,
    widgetName: k?.widgetName,
    field: k?.field,
    type: k?.type,
    additionalField: k?.additionalField,
  }));

  const unmapped = _.differenceBy(tableColumns, dataSource, "key").map(
    (item) => ({ ...item, unmapped: true })
  );

  const dataSourceWith = [...dataSource, ...unmapped];
  const dataSourceMap = _.filter(
    dataSourceWith,
    (data) => data?.key !== "status" && data?.key !== selectedId
  );

  const headerOptions = headers.map((header) => ({
    field: header,
    value: header,
  }));

  return (
    <div>
      {renderMappingSettings({
        settingsOptions,
        selectedSettings,
        onChangeSettings,
        setSettingsModal,
        selectedType,
        collectionOptions,
        setSelectedCollectionOptions,
        selectedCollectionOptions,
        headerOptions,
        selectedId,
        setSelectedId,
        setIsProject,
        dataSourceMap,
        onEdit,
        onDelete,
        documentUploadtype,
        setOnlyQualities,
      })}

      <Modal
        open={showModal}
        title="Add New Key"
        okText="Save"
        onCancel={() => {
          setShowModal(false);
          clearForm();
        }}
        onOk={() => saveForm()}
      >
        {renderManageSettingsModal({
          formRef,
          availableWidgetsWithKeys,
          selectedCollectionOptions,
          setSelectedWidgetName,
          selectedWidgetName,
          options: _.filter(
            options,
            (option) => option?.field && option?.value
          ),
        })}
      </Modal>
      <Modal
        open={settingsModal}
        title="Manage Keys"
        okText="Ok"
        onOk={() => {
          setSettingsModal(false);
        }}
        onCancel={() => {
          setSettingsModal(false);
        }}
      >
        {renderSettingsModal()}
      </Modal>
      <Modal
        open={settingsEditModal}
        title="Manage Keys"
        okText="Save"
        onCancel={() => {
          setSettingsEditModal(false);
        }}
        onOk={onSaveSettings}
      >
        {renderSettingsEditModal({ settingsFormRef })}
      </Modal>
      <NotificationContainer />
    </div>
  );
};

export default UploadSettings;
