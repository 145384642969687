/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Divider, Form, Input, Switch } from "antd";
import PT from "prop-types";
import _ from "lodash";

const SeparatorForm = ({ onChangeValue, form, roles }) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.id) ? currentRole[item?.id] : false;
      role[item?.id] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  return (
    <>
      <Col>
        <Form.Item
          label="Title"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>

      <Divider className="gx-text-primary">Roles Settings</Divider>

      <p className="gx-mb-3 gx-text-secondary">Edit Roles:</p>

      <Col className="gx-d-flex">
        {roles.map((role) => (
          <Form.Item
            className="gx-mr-1"
            id={role.id}
            label={role.name}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form?.isEditable, role?.id) || false}
              onChange={(e) => onChangeRole(role.id, e)}
            />
          </Form.Item>
        ))}
      </Col>
    </>
  );
};

SeparatorForm.defaultProps = {
  form: {},
};

SeparatorForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default SeparatorForm;
