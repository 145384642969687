import { CloseCircleOutlined } from "@ant-design/icons";
import { Drawer, Radio } from "antd";
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import PT from "prop-types";
import React, { useEffect, useReducer, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fetchLookupData, fetchLookupImage } from "../../../appRedux/actions";
import {
  getCompoundInstance,
  saveCompoundInstance,
} from "../../../appRedux/services/CompoundInstance";
import {
  getProcessData,
  saveProcessData,
} from "../../../appRedux/services/Process";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import {
  CURRENT_EDITOR_OPTION,
  EXPERIMENT_TABLE_WIDGET_DATA,
  PAGE_TYPES,
  SEARCH_CUTOFF_DEFAULT,
  SEARCH_OPERATOR_OPTIONS,
} from "../../../constants/Config";
import { getParent } from "../../../util/Widget";
import { checkRowIsAccessible, getRoleId } from "../../../util/auth";
import CompoundSelection from "../ProjectManagementWidget/CompoundSelection";
import Header from "../Widget/Header";
import ActionButtons from "./ActionButtons";
import QualitySelection from "./QualitySelection";
import TableContent from "./TableContent";
import "./style.css";

const ExperimentTable = ({
  id,
  title,
  versions,
  widgetOption,
  currentVersion,
  roles = [],
  compoundDetail,
  sourceName,
  pageType,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  dataSources,
  allWidgets,
  widget,
}) => {
  const dispatch = useDispatch();
  const lookupData = useSelector(({ lookup }) => lookup);

  const [open, setOpen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [experiments, setExperiments] = useState([]);
  const [experimentMetadata, setExperimentMetadata] = useState({});
  const [currentRound, setCurrentRound] = useState(2);
  const [limitingReagent, setLimitingReagent] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openSearchDrawer, setOpenSearchDrawer] = useState(false);
  const [openInstanceDrawer, setOpenInstanceDrawer] = useState(false);
  const [viewType, setViewType] = useState("input");
  const [searchOps, setSearchOps] = useState([]);
  const [cutoff, setCutoff] = useState(Number(SEARCH_CUTOFF_DEFAULT));
  const [currentEditIndex, setCurrentEditIndex] = useState();

  // Force update current table.
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    // Fetch look-up data.
    _.forEach(dataSource, (comp) => {
      const { id } = comp;

      if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
        dispatch(fetchLookupData({ id, sourceName }));
        dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSource]);

  useEffect(() => {
    if (!searchOps.length) {
      getSystemWidget(SEARCH_OPERATOR_OPTIONS, setSearchOps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // /**
  //  * Populate table with data from `compoundConcept.processComponents`
  //  * which is coming from (parent) process
  //  */
  // useEffect(() => {
  //   if (compoundDetail && compoundDetail?.processComponents) {
  //     const { processComponents = [] } = compoundDetail || {};

  //     const newDataSource = _.map(processComponents, (component) => ({
  //       ...component,
  //       key: uuidv4(),
  //       isLocked: true,
  //     }));

  //     // Fetch look-up data.
  //     _.forEach(processComponents, (comp) => {
  //       const { id } = comp;

  //       if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
  //         dispatch(fetchLookupData({ id, sourceName }));
  //         dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
  //       }
  //     });

  //     const components = _.uniqBy([...dataSource, ...newDataSource], "id");

  //     // Add saved exp data.
  //     const componentsWithData = _.map(components, (component) => {
  //       if (_.get(experimentMetadata, component?.id)) {
  //         return {
  //           ...component,
  //           ..._.get(experimentMetadata, component?.id),
  //         };
  //       } else
  //         return {
  //           ...component,
  //         };
  //     });

  //     setDataSource(componentsWithData);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  /**
   * Get experiments.
   */
  useEffect(() => {
    getCompoundInstance(
      id,
      sourceName,
      (dt) => {
        const { experimentComponents = [] } = dt || {};

        if (
          experimentComponents &&
          _.isArray(experimentComponents) &&
          experimentComponents.length
        ) {
          setExperiments(experimentComponents);
        }
      },
      PAGE_TYPES.EXPERIMENT
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { rows } = widget || {};

  const optionsWithRole = _.filter(rows, (option) =>
    checkRowIsAccessible(option?.isAccessible)
  );

  const widgetNamesToResolve = _.uniq(
    _.map(
      _.filter(optionsWithRole, (role) => role?.widgetName),
      (i) => i?.widgetName
    )
  );

  console.log("widgetNamesToResolve", widgetNamesToResolve);

  /**
   * Add experiment data into dynamic form.
   */
  useEffect(() => {
    if (experiments && _.isArray(experiments) && experiments.length) {
      const mappedExperiments = _.map(experiments, (exp) => ({
        id: exp?.compoundId,
        value: exp?.value,
        unit: exp?.unit,
        type: exp?.type,
        role: exp?.role,
      }));

      // Fetch look-up data.
      _.forEach(mappedExperiments, (comp) => {
        const { id } = comp;
        dispatch(fetchLookupData({ id, sourceName }));
        dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));

        _.forEach(widgetNamesToResolve, (widgetName) => {
          dispatch(fetchLookupData({ id: comp?.id, sourceName, widgetName }));
          // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
        });
      });

      const components = _.uniqBy([...dataSource, ...mappedExperiments], "id");

      // Add saved exp data.
      const componentsWithData = _.map(mappedExperiments, (component) => {
        if (_.get(experimentMetadata, component?.id)) {
          return {
            ...component,
            ..._.get(experimentMetadata, component?.id),
          };
        } else
          return {
            ...component,
          };
      });

      setDataSource(componentsWithData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments]);

  /**
   * Add experiment metadata.
   */
  useEffect(() => {
    getProcessData(id, sourceName, EXPERIMENT_TABLE_WIDGET_DATA, (data) => {
      if (_.get(data, "limitingReagent")) {
        setLimitingReagent(_.get(data, "limitingReagent"));
      }

      let newData = {};

      for (const [key, value] of Object.entries(data)) {
        let newKey = (key || "").replace("~", ".");

        newData[newKey] = value;
      }

      setExperimentMetadata(newData);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Get Round Settings
   */
  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        if (currentOption?.roundSettings) {
          setCurrentRound(Number(currentOption?.roundSettings));
        }
      },
      false
    );
  }, []);

  /**
   * If compound structure widget has versions and current version is not a last one, we should highlight header.
   */
  let backgroundColor = widgetOption?.backgroundColor;
  const isLastVersion = (versions || []).length === currentVersion;

  if (currentVersion && (versions || []).length > 1 && !isLastVersion) {
    backgroundColor = "#ffbb96";
  }
  const titleColor = widgetOption?.titleColor || widgetOption?.titleColor;
  const maxHeight = widgetOption?.maxHeight || widgetOption?.maxHeight || null;

  const hasAccess = (roles || []).includes(getRoleId());

  /**
   * Action when a new row is added to table.
   */
  const onAddRow = () => {
    const newData = {
      key: uuidv4(),
      id: "",
    };
    setDataSource([...dataSource, newData]);
  };

  const onOpenSearch = (ind, key, isMain) => {
    if (isMain) {
      setOpenInstanceDrawer(true);
    } else {
      setOpenSearchDrawer(true);
    }

    setCurrentEditIndex(ind || key);
  };

  const onAddCompound = (cId) => {
    console.log(cId);
  };

  const onAddQuality = (cId) => {
    console.log(cId);

    const mc = _.find(dataSource, { id: getParent(cId) }) || {};

    const filteredDs = _.filter(dataSource, (ds) => {
      return !(
        getParent(ds?.id) === getParent(cId) &&
        ds?.type === "main_component_instance"
      );
    });

    const newDataSource = [
      ...filteredDs,
      {
        ...mc,
        id: cId,
        type: "main_component_instance",
      },
    ];

    setDataSource(newDataSource);
    setOpenInstanceDrawer(false);
  };

  const onChangeItem = (key, field, val) => {
    const newDatSource = _.map(dataSource, (ds) => {
      if (ds?.key === key) {
        return {
          ...ds,
          [field]: val,
        };
      }

      return ds;
    });

    setDataSource(newDatSource);
  };

  /**
   * Action to save current table content.
   */
  const onSaveTable = () => {
    // Re-shape form data.
    const body = _.map(dataSource, (dt) => {
      const isInstance = (dt?.id || "").includes(".");

      return {
        compoundId: dt?.id,
        role: dt?.role,
        type: dt?.type,
        unit: "%",
        value: "1",
        componentType: isInstance ? "COMPOUND_INSTANCE" : "COMPOUND_CONCEPT",
      };
    });

    // Fetch metadata from dataSource and save.
    let metadataBody = {
      limitingReagent,
    };
    _.forEach(dataSource, (dt) => {
      let id = (dt?.id || "").replace(".", "~");

      metadataBody[id] = {
        ...experimentMetadata[id],
        ...metadataBody[id],
        ...dt,
      };
    });
    saveProcessData(
      id,
      sourceName,
      EXPERIMENT_TABLE_WIDGET_DATA,
      metadataBody,
      setExperimentMetadata
    );

    // Save actual data to process route.
    saveCompoundInstance(id, sourceName, body, PAGE_TYPES.EXPERIMENT).then(
      () => {
        NotificationManager.success("Saved experiment data.");
        setEditMode(false);
      }
    );
  };

  const onSelectProcess = (cId) => {
    let isMainComp = false;
    let isDuplicate = false;

    _.forEach(dataSource, (ds) => {
      if (
        ds?.type === "main_component" &&
        getParent(ds?.id) === getParent(cId)
      ) {
        isMainComp = false;
      }

      if (
        ds?.type !== "main_component" &&
        getParent(ds?.id) === getParent(cId)
      ) {
        isDuplicate = false;
      }
    });

    if (isMainComp) {
      NotificationManager.error(`Id ${cId} cannot be part of main component.`);
    } else if (isDuplicate) {
      NotificationManager.error(`Id ${cId} or its parent is already added.`);
    } else {
      console.log("c id", cId, dataSource, currentEditIndex);

      const newDAtaSource = _.map(dataSource, (d) => {
        if (d?.key === currentEditIndex || d?.id === currentEditIndex) {
          return {
            ...d,
            id: cId,
          };
        }
        return d;
      });

      setDataSource(newDAtaSource);
      setOpenSearchDrawer(false);
    }
  };

  const mainComps =
    _.filter(dataSource, (ds) => ds?.type === "main_component_instance") || [];

  const currentDataSource = _.filter(dataSource, (ds) => {
    if (ds?.type === "main_component") {
      let isPos = true;
      _.forEach(mainComps, (mcc) => {
        if (getParent(mcc?.id) === ds?.id) {
          isPos = false;
        }
      });

      return isPos;
    } else {
      return true;
    }
  });

  const handleDelete = (targetCell) => {
    const newData = dataSource.filter((item) => !_.isEqual(item, targetCell));
    setDataSource(newData);
  };

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 double-width ${
        open ? "isOpen" : ""
      }
      
  `}
      style={
        maxHeight && open
          ? {
              height: `${maxHeight}px`,
              overflowY: "scroll",
            }
          : { height: "fit-content" }
      }
      key={uuidv4()}
    >
      <Header
        title={title}
        titleColor={titleColor}
        open={open}
        setOpen={setOpen}
        child={
          <ActionButtons
            hasAccess={hasAccess}
            onAddRow={onAddRow}
            onSave={onSaveTable}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        }
        backgroundColor={backgroundColor}
      />

      {open ? (
        <div>
          {loading ? (
            <div
              style={{
                height: "150px",
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress className="custom-loader" />
            </div>
          ) : (
            <div className="gx-pl-3 gx-pr-3 gx-mt-3">
              <TableContent
                dataSource={currentDataSource}
                setDataSource={setDataSource}
                sourceName={sourceName}
                lookupData={lookupData}
                experimentMetadata={experimentMetadata}
                currentRound={currentRound}
                pageType={pageType}
                safetySentenceTypes={safetySentenceTypes}
                safetySentenceImageMetadata={safetySentenceImageMetadata}
                safetySentenceLanguageMap={safetySentenceLanguageMap}
                limitingReagent={limitingReagent}
                setLimitingReagent={setLimitingReagent}
                forceUpdate={forceUpdate}
                editMode={editMode}
                onOpenSearch={onOpenSearch}
                dataSources={dataSources}
                handleDelete={handleDelete}
                onChangeItem={onChangeItem}
                actualDataSource={dataSource}
                rows={optionsWithRole}
                allWidgets={allWidgets}
              />
            </div>
          )}
        </div>
      ) : (
        <div>
          <CircularProgress className="gx-loader-400 loader" />
        </div>
      )}

      <Drawer
        title={
          <div className="gx-d-flex">
            {/* <span>Search Compound</span> */}
            <Radio.Group
              size="small"
              value={viewType}
              onChange={(e) => {
                setViewType(e.target.value);
              }}
              className="gx-mb-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "left",
                marginTop: "10px",
              }}
            >
              <Radio.Button value="input">Search by Value</Radio.Button>
              <Radio.Button value="structure">Search by Structure</Radio.Button>
            </Radio.Group>
          </div>
        }
        placement="right"
        open={openSearchDrawer}
        width={900}
        className="settingsDrawer"
        okText="Add Process"
        onOk={onAddCompound}
        onClose={() => {
          setOpenSearchDrawer(false);
        }}
        closeIcon={<CloseCircleOutlined className="gx-text-danger" />}
        close
      >
        <CompoundSelection
          sourceName={sourceName}
          availableWidgets={allWidgets}
          viewType={viewType}
          availableDataSources={dataSources}
          searchOps={searchOps}
          cutoff={cutoff}
          setCutoff={setCutoff}
          onSelectProcess={onSelectProcess}
        />
      </Drawer>

      <Drawer
        title={`Select quality for main component: ${currentEditIndex}`}
        open={openInstanceDrawer}
        width={900}
        placement="right"
        className="settingsDrawer"
        okText="Add QUality"
        onOk={onAddQuality}
        onClose={() => {
          setOpenInstanceDrawer(false);
        }}
      >
        <QualitySelection
          id={currentEditIndex}
          availableWidgets={allWidgets}
          sourceName={sourceName}
          onSelectProcess={onAddQuality}
        />
      </Drawer>
    </div>
  );
};

ExperimentTable.defaultProps = {
  id: "",
  title: "",
  versions: [],
  widgetOption: {},
  currentVersion: 1,
  roles: [],
  compoundDetail: {},
  sourceName: "",
  lookupData: {},
};

ExperimentTable.propTypes = {
  id: PT.string,
  title: PT.string,
  versions: PT.arrayOf(PT.number),
  widgetOption: PT.shape(),
  currentVersion: PT.number,
  roles: PT.arrayOf(PT.number),
  compoundDetail: PT.shape(),
  sourceName: PT.string,
  lookupData: PT.shape(),
};

export default ExperimentTable;
