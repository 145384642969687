import React from "react";
import PT from "prop-types";
import { Button, Form, Table } from "antd";
import Column from "antd/lib/table/Column";
import { CheckCircleTwoTone } from "@ant-design/icons";
import CircularProgress from "components/CircularProgress";

import "./style.css";
import SwitchForm from "../SwitchForm";
import _ from "lodash";
import { TABLE_MAX_HEIGHT } from "../../../constants/Config";

const DatabasesForm = ({
  widgetName,
  dataSources,
  roles,
  onSave,
  loading,
  onIndex,
}) => {
  const onChangeAccess = (sourceName, role, val, accessIdentifier) => {
    if (loading) {
      return;
    }
    let accessArray = _.get(role, accessIdentifier) || [];

    if (val) {
      accessArray = [...accessArray, sourceName];
    } else {
      accessArray = accessArray.filter((item) => item !== sourceName);
    }

    // Filter old or unused data sources.
    const filteredAccessArray = _.filter(
      accessArray,
      (item) => _.findIndex(dataSources, { sourceName: item }) !== -1
    );

    const newRole = {
      ...role,
      [accessIdentifier]: filteredAccessArray,
    };

    const newRoles = roles.map((item) => {
      if (item.id === newRole.id) {
        return newRole;
      } else {
        return item;
      }
    });

    onSave(newRoles, widgetName);
  };

  const renderIndexed = (p, { indexed, sourceName }) => (
    <div>
      {indexed ? (
        <CheckCircleTwoTone twoToneColor="#52c41a" />
      ) : (
        <Button size="small" type="primary" onClick={() => onIndex(sourceName)}>
          Index
        </Button>
      )}
    </div>
  );

  const renderAccess = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="access"
            onChangeAccess={onChangeAccess}
          />
        ))}
      </div>
    );
  };

  const renderRegistrationDatabase = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="registrationDatabases"
            onChangeAccess={onChangeAccess}
            isDatabase
          />
        ))}
      </div>
    );
  };

  const renderQualityRegistrationDatabase = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="qualityRegistrationDatabase"
            onChangeAccess={onChangeAccess}
            isDatabase
          />
        ))}
      </div>
    );
  };

  const renderCurateDatabase = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="curateDatabase"
            onChangeAccess={onChangeAccess}
            isDatabase
          />
        ))}
      </div>
    );
  };

  const renderProcessRegistrationDatabase = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="processRegistrationDatabases"
            onChangeAccess={onChangeAccess}
            isDatabase
          />
        ))}
      </div>
    );
  };

  const renderProjectRegistrationDatabase = (p, { sourceName }) => {
    return (
      <div>
        {(roles || []).map((role) => (
          <SwitchForm
            role={role}
            sourceName={sourceName}
            accessIdentifier="projectRegistrationDatabases"
            onChangeAccess={onChangeAccess}
            isDatabase
          />
        ))}
      </div>
    );
  };

  return (
    <>
      {loading && <CircularProgress />}
      <Form className="gx-ml-3 gx-mt-3">
        <Form.Item>
          <Table
            dataSource={dataSources}
            size="default"
            pagination={{
              responsive: true,
              showSizeChanger: true,
              defaultPageSize: 50,
              pageSizeOptions: [5, 10, 20, 50],
            }}
            scroll={{ y: TABLE_MAX_HEIGHT, x: true }}
          >
            <Column title="Database" key="sourceName" dataIndex="sourceName" />
            <Column
              title="Indexed"
              key="indexed"
              dataIndex="indexed"
              render={renderIndexed}
            />
            <Column
              title="Data Type"
              key="sourceType"
              dataIndex="sourceType"
              width={100}
            />
            <Column
              title="Access"
              key="access"
              dataIndex="access"
              render={renderAccess}
            />
            <Column
              title="Compound Registration"
              key="registrationDatabase"
              dataIndex="registrationDatabase"
              render={renderRegistrationDatabase}
            />
            <Column
              title="Quality Registration"
              key="qualityRegistrationDatabase"
              dataIndex="qualityRegistrationDatabase"
              render={renderQualityRegistrationDatabase}
            />
            <Column
              title="Compound Curation"
              key="curateDatabase"
              dataIndex="curateDatabase"
              render={renderCurateDatabase}
            />
            <Column
              title="Process Registration"
              key="processRegistrationDatabases"
              dataIndex="processRegistrationDatabases"
              render={renderProcessRegistrationDatabase}
            />
            <Column
              title="Project Registration"
              key="projectRegistrationDatabases"
              dataIndex="projectRegistrationDatabases"
              render={renderProjectRegistrationDatabase}
            />
          </Table>
        </Form.Item>
      </Form>
    </>
  );
};

export default DatabasesForm;

DatabasesForm.defaultProps = {
  widgetName: "",
  dataSources: [],
  roles: [],
  loading: false,
};

DatabasesForm.propTypes = {
  widgetName: PT.string,
  dataSources: PT.arrayOf(PT.shape()),
  roles: PT.arrayOf(PT.shape()),
  onSave: PT.func.isRequired,
  loading: PT.bool,
  onIndex: PT.func.isRequired,
};
