import {
  SET_CURRENT_WIDGETS_REQUESTED,
  SET_DOCUMENT_ROLES_REQUESTED,
  SET_ROLES_REQUESTED,
  SET_VIEW_REQUESTED,
} from "../../constants/ActionTypes";

export const setUserRoles = (roles) => {
  return {
    type: SET_ROLES_REQUESTED,
    payload: roles,
  };
};

export const setDocumentUserRoles = (roles) => {
  return {
    type: SET_DOCUMENT_ROLES_REQUESTED,
    payload: roles,
  };
};

export const setCurrentWidgets = ({ widgets, pageName, database }) => {
  return {
    type: SET_CURRENT_WIDGETS_REQUESTED,
    payload: {
      widgets,
      pageName,
      database,
    },
  };
};

export const setView = ({ pageName }) => {
  return {
    type: SET_VIEW_REQUESTED,
    payload: {
      pageName,
    },
  };
};
