import env from "@ludovicm67/react-dotenv";

export const config = {
  REACT_APP_PROPERTIES_API_URL:
    env?.REACT_APP_PROPERTIES_API_URL ||
    process.env.REACT_APP_PROPERTIES_API_URL,
  SRD_API_URL: env?.REACT_APP_SRD_API_URL || process.env.REACT_APP_SRD_API_URL,
  SCAS_API_URL:
    env?.REACT_APP_SCAS_API_URL || process.env.REACT_APP_SCAS_API_URL,
  SOURCE_NAME: "UI",
  GRAPH_API: "https://graph.microsoft.com/v1.0",
  REACT_APP_BUILD_ID: env?.BUILD_ID || process.env.BUILD_ID,
  REACT_APP_GROUPS_PREFIX:
    env?.REACT_APP_GROUPS_PREFIX ||
    "GLB-GS-ACT,ASIA-GS-Act-scientist,EMEA-GS,Global-GS-All-InternetPlatform-Users",
  REACT_APP_PREFIX: "",
};

export const MSAL_CONFIG = {
  auth: {
    clientId:
      env?.REACT_APP_MSAL_CLIENTID || process.env.REACT_APP_MSAL_CLIENTID,
    authority: `${
      env?.REACT_APP_MSAL_CLOUD_INSTANCE ||
      process.env.REACT_APP_MSAL_CLOUD_INSTANCE
    }/${
      env?.REACT_APP_MSAL_TENANT_INFO || process.env.REACT_APP_MSAL_TENANT_INFO
    }`,
    redirectUri:
      env?.REACT_APP_MSAL_REDIRECT_URI ||
      process.env.REACT_APP_MSAL_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
};

export const CONTACT_EMAIL = "mailto:matthias.nolte@arxada.com";

export const IDENTITIFIER_PREFIX = "UI";
export const IDENTIFIER_SEPERATOR = "-";

export const TABLE_MAX_HEIGHT = 600;

export const identifierOptions = (id, smiles, inchi, inchiKey) => [
  { field: "Substance ID", value: id },
  { field: "Smiles", value: smiles, copyAllowed: true, trimmed: true },
  { field: "InChI", value: inchi, copyAllowed: true, trimmed: true },
  { field: "InChIKey", value: inchiKey, copyAllowed: true, trimmed: true },
];

export const elementalAnalysisOptions = (
  formula,
  mass,
  composition,
  atomCount,
) => [
  { field: "Molecular Formula", value: formula },
  { field: "Molecular Weight", value: mass },
  { field: "Molecular Composition", value: composition },
  { field: "Atom Count", value: atomCount },
];

export const STRUCTURE_SEARCH_DICTIONARY = "structureSearchDictionary";
export const STRUCTURE_TYPE_DICTIONARY = "structureTypeDictionary";

// Search page related System Widget names
export const SEARCH_OPERATOR_OPTIONS = "searchOperatorOptions";
export const SEARCH_STRUCTURE_TYPES = "searchStructureTypes";
export const SEARCH_CUTOFF_MIN = 50;
export const SEARCH_CUTOFF_MAX = 100;
export const SEARCH_CUTOFF_DEFAULT = 75;
export const NAMES_AND_SYNONYMS_WIDGET_METADATA = "NamesAndSynonyms";
export const SAFETY_SENTENCES_WIDGET = "SafetySentences";
export const REGISTRATION_DATA_WIDGET_METADATA = "RegistrationDataParams";
export const PROJECT_DATA_METADATA = "ProjectDataParams";
export const EHS_METADATA = "EhsParams";
export const SAFETY_SENTENCES_METADATA = "SafetySentencesMetadata";
export const SAFETY_SENTENCES_SAVED_DATA = "SAFETY_SENTENCES_DATA";
export const SAFETY_SENTENCES_IMAGE_METADATA = "SafetySentencesImageMetadata";
export const TABLE_WIDGET = "TableData";
export const TABLE_WIDGET_METADATA = "TableWidgetMetadata";

export const SOURCE_NAME_MAP = [
  { prefix: "UI", value: "UI" },
  { prefix: "DC", value: "Drugs" },
  { prefix: "GSID", value: "anotherDrugDB" },
  { prefix: "TDB1", value: "TestDB1" },
  { prefix: "TDB2", value: "TestDB2" },
  { prefix: "RXN", value: "Arxada" },
  { prefix: "A", value: "Arxada" },
  { prefix: "MK", value: "Mark" },
];

export const NAMES_AND_SYNONYMS_WIDGET_PARAMS = "NamesAndSynonyms";
export const ELEMENTAL_ANALYSIS_OPTIONS = "ElementalAnalysisWidget";
export const STRUCTURE_WIDGET_PARAMS = "StructureWidget";
export const IDENTIFIERS_WIDGET_PARAMS = "IdentifiersWidget";
export const COMPOUND_INSTANCES_WIDGET_PARAMS = "CompoundInstancesWidget";
export const COMPONENTS_WIDGET_PARAMS = "ComponentsWidget";
export const TAUTOMERS_WIDGET_PARAMS = "RelatedTautomerWidget";
export const ISOMER_WIDGET_PARAMS = "IsomerWidget";
export const RACEMATE_WIDGET_PARAMS = "RacemateWidget";
export const DIASTEREOMERS_WIDGET_PARAMS = "RelatedDiastereomersWidget";
export const ENANTIOMERS_WIDGET_PARAMS = "RelatedEnantiomersWidget";
export const COORDINATIONS_WIDGET_PARAMS = "CoordinationsWidget";
export const DUPLICATES_WIDGET_PARAMS = "DuplicatesWidget";
export const ISOTOPES_WIDGET_PARAMS = "IsotopesWidget";
export const GEOMETRIC_ISOMERS_WIDGET_PARAMS = "GeometricIsomersWidget";
export const PROJECT_DATA_PARAMS = "ProjectData";
export const EHS_PARAMS = "EhsParams";

export const REGISTRATION_DATA_PARAMS = "RegistrationDataParams";

// Register Process Page
export const PROCESS_STEP_COMPONENTS_ROLES = "ProcessStepComponentRoles";

export const compoundInstanceOptions = (url) => {
  return { field: "Name", value: url };
};

export const componentRoleOptions = [
  // { label: "Main Component", value: "main_component" },
  { label: "Impurity", value: "IMPURITY" },
  { label: "Solvent", value: "SOLVENT" },
  { label: "Byproduct", value: "BYPRODUCT" },
  { label: "Additive", value: "ADDITIVE" },
];

export const mainComponentRoleOptions = [
  { field: "main_ingredient", value: "main ingredient" },
];

export const additionalComponentTypeOptions = [
  { field: "percentage", value: "%" },
];

export const USER_ROLES = "UserRoles";
export const DOCUMENT_ROLES = "DocumentAccessRoles";

// This metadata stores all system widgets
export const SYSTEM_WIDGETS_METADATA = "SystemWidgets";
// This metadata stores all data widgets
export const DATA_WIDGETS_METADATA = "DataWidgets";
export const USER_WIDGETS_METADATA = "UserWidgets";
export const AVAILABLE_WIDGETS_METADATA = "AvailableWidgets";

export const CONTROLLED_VOCABULARIES = "ControlledVocabularies";

// This metadata stores all available user widgets.
export const CONTROLLED_VOCABULARIES_METADATA =
  "ControlledVocabulariesMetadata";

export const AvailableSystemWidgetNames = [
  { field: "Registration Data", value: REGISTRATION_DATA_PARAMS },
  { field: "Names and Synonyms", value: NAMES_AND_SYNONYMS_WIDGET_PARAMS },
  { field: "Project Data", value: PROJECT_DATA_PARAMS },
  { field: "Qualities", value: "Qualities" },
  { field: "Elemental Analysis", value: ELEMENTAL_ANALYSIS_OPTIONS },
  { field: "Structure Widget", value: STRUCTURE_WIDGET_PARAMS },
  { field: "Identifiers", value: IDENTIFIERS_WIDGET_PARAMS },
  { field: "Components", value: COMPONENTS_WIDGET_PARAMS },
  { field: "Tautomers", value: TAUTOMERS_WIDGET_PARAMS },
  { field: "Isomers", value: ISOMER_WIDGET_PARAMS },
  { field: "Diastereomers", value: DIASTEREOMERS_WIDGET_PARAMS },
  { field: "Enantiomers", value: ENANTIOMERS_WIDGET_PARAMS },
  { field: "Coordinations", value: COORDINATIONS_WIDGET_PARAMS },
  { field: "Duplicated", value: DUPLICATES_WIDGET_PARAMS },
  { field: "Isotopes", value: ISOTOPES_WIDGET_PARAMS },
  { field: "Geometric Isomers", value: GEOMETRIC_ISOMERS_WIDGET_PARAMS },
  { field: "EHS", value: EHS_PARAMS },
];

export const AVAILABLE_WIDGET_INPUT_TYPES = [
  { field: "STRING", value: "STRING" },
  { field: "STRING_DATE", value: "STRING_DATE" },
  { field: "EDITABLE", value: "EDITABLE" },
  { field: "EDITABLE_DATE", value: "EDITABLE_DATE" },
  { field: "URL", value: "URL" },
  { field: "ARRAY", value: "ARRAY" },
  // { field: "TOGGLE_BUTTON", value: "TOGGLE_BUTTON" },
  { field: "SEPARATOR", value: "SEPARATOR" },
  { field: "NESTED", value: "NESTED" },
  // { field: "LINK", value: "LINK" },
  // { field: "PROJECT_REFERENCE", value: "PROJECT_REFERENCE" },
  { field: "SELECT_INPUT", value: "SELECT_INPUT" },
  { field: "LIST", value: "LIST" },
  { field: "GROUP", value: "GROUP" },
];

export const AVAILABLE_DOCUMENT_INPUT_TYPES = [
  { field: "STRING", value: "STRING" },
  { field: "EDITABLE", value: "EDITABLE" },
  { field: "EDITABLE_DATE", value: "EDITABLE_DATE" },
  { field: "SEPARATOR", value: "SEPARATOR" },
  { field: "TABLE", value: "TABLE" },
  { field: "LIST", value: "LIST" },
  { field: "FILE", value: "FILE" },
  { field: "SELECT_INPUT", value: "SELECT_INPUT" },
];

export const AVAILABLE_TABLE_INPUT_TYPES = [{ field: "TABLE", value: "TABLE" }];

export const BELONGS_TO_OPTIONS = [
  { field: "Compound", value: "compound" },
  { field: "Quality", value: "quality" },
  { field: "Process", value: "process" },
  { field: "Experiment", value: "experiment" },
  { field: "Project", value: "project" },
];

export const SEARCH_PAGE_BELONGS_TO_OPTIONS = [
  { label: "Home Page", value: "home" },
  { label: "Search Page", value: "search" },
];

export const ALLOWED_ENTITY_TYPES = [
  { field: "Compound", value: "compound" },
  { field: "Quality", value: "quality" },
  { field: "Process", value: "process" },
  { field: "Experiment", value: "experiment" },
  { field: "Collection", value: "collection" },
  { field: "Sample", value: "sample" },
];

export const ProcessRegistrationTypes = "ProcessRegistrationTypes";
export const DOCUMENT_COLLECTION_OPTIONS = "DOCUMENT_COLLECTION_OPTIONS";

export const AVAILABLE_WIDGET_TYPES = [
  { label: "System Widgets", value: SYSTEM_WIDGETS_METADATA },
  { label: "Data Widgets", value: DATA_WIDGETS_METADATA },
  { label: "User Widgets", value: USER_WIDGETS_METADATA },
  { label: "Document Collection", value: DOCUMENT_COLLECTION_OPTIONS },
];

export const USER_LAYOUT_SETTINGS = "USER_LAYOUT_SETTINGS";
export const GLOBAL_LAYOUT_SETTINGS = "GLOBAL_LAYOUT_SETTINGS";

export const CURRENT_USER_LAYOUT = "CURRENT_USER_LAYOUT";
export const CURRENT_GLOBAL_LAYOUT = "CURRENT_GLOBAL_LAYOUT";

export const UPLOAD_FILE_MAPPING = "UPLOAD_FILE_MAPPING";
export const DELAY_TO_LOAD_CANVAS = 3000;

export const PAGE_TYPES = {
  COMPOUND: "compound",
  QUALITY: "quality",
  PROCESS: "process",
  EXPERIMENT: "experiment",
  PROJECT: "project",
  COLLECTION: "collection",
  SAMPLE: "sample",
};

export const PAGE_TYPES_OPTIONS = [
  { label: "Compound", value: "compound", key: 1 },
  { label: "Quality", value: "quality", key: 2 },
  { label: "Process", value: "process", key: 3 },
  { label: "Experiment", value: "experiment", key: 4 },
  { label: "Project", value: "project", key: 5 },
  { label: "Home Page", value: "home", key: 6 },
  { label: "Search Page", value: "search", key: 7 },
  { label: "Collection Page", value: "collection", key: 8 },
  { label: "Sample Page", value: "sample", key: 9 },
];

export const SAVED_SEARCH_PARAMS = "SAVED_SEARCH_PARAMS";

export const DOCUMENT_UPLOAD_TYPES = [
  { label: "Widget Data", value: "widget" },
  { label: "Document Data", value: "document" },
];

export const LOGIN_REQUEST = {
  scopes: [
    // `${MSAL_CONFIG.auth.clientId}/.default`,
    "GroupMember.Read.All",
  ],
};

export const SELECT_TYPE_WIDTH = 350;
export const MAX_CONCURRENT_REQUEST = 5;
export const REGISTRATION_DATA_WIDGET_NAME = "RegistrationData";
export const NAVIGATION_URL_PREFIX = "Navigation_";

export const DEFAULT_SOURCE_NAME = "DEFAULT_SOURCE_NAME";
export const DEFAULT_PROJECT_NAME_COUNTER = "DEFAULT_PROJECT_NAME_COUNTER";
export const SEARCH_DATASOURCES = "SEARCH_DATASOURCES";

export const RECENT_REGISTERED_COMPOUND = "RECENT_REGISTERED_COMPOUND";

export const TargetObjectOptions = [
  { label: "Compound Concept", value: "compoundDetail" },
  { label: "Elemental Analysis", value: "elementalAnalysis" },
  { label: "Sample Detail", value: "sampleDetail" },
  { label: "Compound Instance", value: "compoundInstance" },
];

export const SEARCH_PAGE_WIDGETS_OPTIONS = "SEARCH_PAGE_WIDGETS_OPTIONS";

export const SEARCH_OPTIONS = [
  { label: "Molecular weight", value: "BINGO_MW" },
  { label: "Molecular formula", value: "BINGO_MF" },
  { label: "Data Search", value: "DATA" },
  { label: "Recently Registered Compounds", value: "RECENT" },
  { label: "Navigate to compounds", value: "NAVIGATE" },
  { label: "Search By Ids", value: "SEARCH_BY_ID" },
  { label: "Search Document", value: "SEARCH_DOCUMENT" },
  { label: "Generic Data Search", value: "GENERIC_DATA_SEARCH" },
  { label: "All Parameters Search", value: "SEARCH_BY_ALL_PARAMETERS" },
  { label: "Collection", value: "COLLECTION_MANAGER" },
];

export const SEARCH_OPERATORS = [
  { label: "Less than", value: "LT" },
  { label: "Greater than", value: "GT" },
  { label: "Between", value: "Between" },
  { value: "GT", label: "Greater Than" },
  { value: "GTE", label: "Greater Than or Equals" },
  { value: "LT", label: "Less Than" },
  { value: "LTE", label: "Less Than or Equals" },
  { value: "STARTS_WITH", label: "Starts With" },
  { value: "IN", label: "In" },
  { value: "IS_DEFINED", label: "Is Defined" },
  { value: "IS_NULL", label: "Is Undefined" },
  { value: "ENDS_WITH", label: "Ends With" },
];

export const EXPLORE_DOCUMENT_OPERATORS = [
  { value: "EQ", label: "Equal" },
  { value: "CONTAINS", label: "Contains" },
];

export const EXPLORE_FULL_OPERATORS = [
  { value: "EQ", label: "Equal" },
  { value: "CONTAINS", label: "Contains" },
  // { label: "Greater than", value: "GT" },
  // { value: "GTE", label: "Greater Than or Equals" },
  // { value: "LT", label: "Less Than" },
  // { value: "LTE", label: "Less Than or Equals" },
  { value: "STARTS_WITH", label: "Starts With" },
  { value: "IN", label: "In" },
  { value: "ENDS_WITH", label: "Ends With" },
];

export const DATA_SEARCH_OPERATORS = [
  { value: "EQ", label: "Equals", type: "STRING" },
  { value: "CONTAINS", label: "Contains", type: "STRING" },
  { value: "GT", label: "Greater Than", type: "NUMERIC" },
  { value: "GTE", label: "Greater Than or Equals", type: "NUMERIC" },
  { value: "LT", label: "Less Than", type: "NUMERIC" },
  { value: "LTE", label: "Less Than or Equals", type: "NUMERIC" },
  { value: "STARTS_WITH", label: "Starts With", type: "STRING" },
  { value: "IN", label: "In", type: "STRING" },
  { value: "IS_DEFINED", label: "Is Defined", type: "STRING" },
  { value: "IS_NULL", label: "Is Undefined", type: "STRING" },
  { value: "ENDS_WITH", label: "Ends With", type: "STRING" },
];

export const MF_SEARCH_OPERATORS = [
  { label: "Equal", value: "=" },
  { label: "Less than", value: "<=" },
  { label: "Greater than", value: ">=" },
];

export const JOIN_TYPE_OPERATORS = [
  { label: "AND", value: "AND" },
  { label: "OR", value: "OR" },
];

export const MW_SEARCH_OPERATORS = [
  { label: "Equal", value: "=" },
  { label: "Less than", value: "<=" },
  { label: "Greater than", value: ">=" },
  { label: "Between", value: "Between" },
];

export const NAVIGATE_TO_PAGES = [
  { label: "Compound", value: "compound" },
  { label: "Quality", value: "quality" },
  { label: "Process Step", value: "process" },
  { label: "Experiment", value: "experiment" },
  { label: "Project Page", value: "project" },
  { label: "Sample", value: "sample" },
];

export const ALL_PAGES_LIST = [
  { label: "Compound", value: "compound" },
  { label: "Quality", value: "quality" },
  { label: "Process Step", value: "process" },
  { label: "Experiment", value: "experiment" },
  { label: "Project Page", value: "project" },
  { label: "Home Page", value: "home" },
  { label: "Search Page", value: "search" },
  { label: "Process Registration Page", value: "register-process" },
  { label: "Compound Registration Page", value: "compound-registration" },
  { label: "Admin Dashboard", value: "dashboard" },
  { label: "Upload Page", value: "upload" },
  { label: "Account Settings Page", value: "account-settings" },
];

export const TRAINING_DOCUMENTS_COLLECTION = "TrainingDocuments";
export const EXCEL_TEMPLATES_COLLECTION = "ExperimentTemplates";
export const EXPERIMENT_DATA_WIDGET = "ExperimentData";
export const PROJECT_DATA_WIDGET = "ProjectData";

export const COUNTER_WIDGET = "CounterWidget";

export const COMPOSITION_TABLE_WIDGET = "CompositionTable";
export const COMPOSITION_TABLE_ORDER = "COMPOSITION_TABLE_ORDER";
export const REACTION_COMPONENT_ORDER = "REACTION_COMPONENT_ORDER";

export const ADMIN_DASHBOARD_TAB_MAP = {
  "database-settings": "1",
  "structure-settings": "3",
  "page-settings": "4",
  "roles-settings": "5",
  "system-settings": "6",
  "data-settings": "7",
  "controlled-vocabulary-settings": "8",
  "document-settings": "9",
  "search-widget-settings": "11",
  "editor-settings": "12",
  "search-page-settings": "13",
  "resource-links": "14",
  "project-page-settings": "15",
};

export const TYPE_OPTIONS = {
  Spreadsheet: "SpreadSheet",
  JSON: "JSON",
  SDF: "SDF",
};

export const DOCUMENT_UPLOAD_OPTIONS = {
  STRUCTURES: "STRUCTURES",
  DATA: "DATA",
  STRUCTURES_WITH_DATA: "STRUCTURES_WITH_DATA",
};

export const DOCUMENT_UPLOAD_SELECT_INPUT_OPTIONS = [
  { label: "Structures Only", value: DOCUMENT_UPLOAD_OPTIONS.STRUCTURES },
  { label: "Data Only", value: DOCUMENT_UPLOAD_OPTIONS.DATA },
  {
    label: "Structures with Data",
    value: DOCUMENT_UPLOAD_OPTIONS.STRUCTURES_WITH_DATA,
  },
];

export const TRAINING_DOCUMENT_SOURCE = "TRAINING_DOCUMENT_SOURCE";

export const EDITOR_OPTIONS = {
  KETCHER: "KETCHER",
  CHEMDRAW: "CHEMDRAW",
  // HELM: "HELM",
};

export const CURRENT_EDITOR_OPTION = "CURRENT_EDITOR_OPTION";

export const DEFAULT_PAGE_SIZE = 10;

export const EXCEL_TEMPLATE_SOURCE_NAME = "ACT";

export const EXPLORE_PAGE_MAIN_TAB_OPTIONS = {
  GENERIC_SEARCH: "Generic Search",
  SPECIFIC_SEARCH: "Specific Search",
  ADVANCED_SEARCH: "Advanced Search",
  STRUCTURE_SEARCH: "Structure Search",
};

export const EXPLORE_PAGE_TAB_OPTIONS = {
  ALL: "ALL",
  COMPOUND: "COMPOUND",
  STRUCTURES: "STRUCTURES",
  PROCESS_STEPS: "PROCESS_STEPS",
  PROCESS: "PROCESS",
  PROJECT: "PROJECT",
  DOCUMENT: "DOCUMENTS",

  SPECIFIC_COMPOUND: "SPECIFIC_COMPOUND",
  SPECIFIC_PROCESS: "SPECIFIC_PROCESS",
  SPECIFIC_PROJECTS: "SPECIFIC_PROJECTS",
  SPECIFIC_DOCUMENTS: "SPECIFIC_DOCUMENTS",

  PROCESS_STRUCTURE: "PROCESS_STRUCTURE",
  COMPOUND_STRUCTURE: "COMPOUND_STRUCTURE",
};

export const EXPLORE_PAGE_TAB_LAYOUT = {
  GENERIC_SEARCH: {
    [EXPLORE_PAGE_TAB_OPTIONS.ALL]: "All",
    [EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES]: "Structures",
    [EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STEPS]: "Process Steps",
    [EXPLORE_PAGE_TAB_OPTIONS.COMPOUND]: "Compound & Qualities",
    [EXPLORE_PAGE_TAB_OPTIONS.PROCESS]: "Process & Experiments",
    [EXPLORE_PAGE_TAB_OPTIONS.PROJECT]: "Projects",
    [EXPLORE_PAGE_TAB_OPTIONS.DOCUMENT]: "Documents",
  },
  SPECIFIC_SEARCH: {
    [EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND]: "Compound & Qualities",
    [EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS]: "Processes & Experiments",
    [EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_DOCUMENTS]: "Documents",
  },
  STRUCTURE_SEARCH: {
    [EXPLORE_PAGE_TAB_OPTIONS.COMPOUND_STRUCTURE]: "Compound",
    [EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE]: "Process",
  },
};

export const EMPTY_FILE_NAME = "Unnamed Document";

export const EXPERIMENT_TABLE_WIDGET_DATA = "EXPERIMENT_TABLE_WIDGET_DATA";

export const SEARCH_PAGE_TAB_SETTINGS = "SEARCH_PAGE_TAB_SETTINGS";

export const RESOURCE_LINKS = "RESOURCE_LINKS";

export const PROJECT_PAGE_SETTINGS = "PROJECT_PAGE_SETTINGS";

export const PROJECT_MANAGEMENT_WIDGET = "ProjectManagement";

export const GRAPH_WIDGET = "GraphWidget";

export const INNER_HEIGHT_OFFSET = 120;

export const DUPLICATE_REGISTRATION_USERS = "DUPLICATE_REGISTRATION_USERS";
