import axios from "axios";
import { config } from "../../constants/Config";

/**
 * Get Sample detail
 * @param {String} id
 * @param {String} sourceName
 * @param {Function} setFn
 * @returns
 */
export const getSample = async ({ id, sourceName, version = null, setFn }) => {
  let params = {
    "source name": sourceName,
  };

  if (version) {
    params = {
      ...params,
      version,
    };
  }

  const queryParams = new URLSearchParams(params);

  return axios
    .get(`${config.SRD_API_URL}/sample/${id}?${queryParams}`)
    .then((response) => {
      setFn(response?.data);
    });
};

/**
 * Save Sample detail
 * @param {String} id
 * @param {String} sourceName
 * @param {Array<String>} samples
 * @param {Function} setFn
 * @returns
 */
export const putSample = async ({
  id,
  sourceName,
  samples = [],
  setFn,
  userName,
}) => {
  return axios
    .put(
      `${config.SRD_API_URL}/sample?source%20name=${sourceName}&SRD_USER=${userName}`,
      {
        sampleId: id,
        samples,
      }
    )
    .then((response) => {
      setFn(response?.data);
    });
};
