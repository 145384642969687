import React from "react";
import PT from "prop-types";
import { Form, Table } from "antd";
import CircularProgress from "components/CircularProgress";
import Column from "antd/lib/table/Column";
import SwitchForm from "../SwitchForm";
import _ from "lodash";
import { TABLE_MAX_HEIGHT } from "../../../constants/Config";

const PagesSettings = ({ widgetName, loading, dataSources, roles, onSave }) => {
  const onChangeAccess = (sourceName, role, val, accessIdentifier) => {
    if (loading) {
      return;
    }
    let accessArray = _.get(role, accessIdentifier) || [];

    if (val) {
      accessArray = [...accessArray, sourceName];
    } else {
      accessArray = accessArray.filter((item) => item !== sourceName);
    }

    const newRole = {
      ...role,
      [accessIdentifier]: accessArray,
    };

    const newRoles = roles.map((item) => {
      if (item.id === newRole.id) {
        return newRole;
      } else {
        return item;
      }
    });

    onSave(newRoles, widgetName);
  };

  const filteredRoles = _.filter(
    roles,
    (role) => (role?.name || "").toLowerCase() !== "admin"
  );

  const renderAccess = (p, { value }) => {
    return (
      <div>
        {filteredRoles.map((role) => (
          <SwitchForm
            role={role}
            sourceName={value}
            accessIdentifier="pagesAccess"
            onChangeAccess={onChangeAccess}
          />
        ))}
      </div>
    );
  };

  return (
    <>
      {loading && <CircularProgress />}
      <Form className="gx-ml-3 gx-mt-3">
        <Form.Item>
          <Table
            pagination={false}
            dataSource={dataSources}
            size="small"
            scroll={{ y: TABLE_MAX_HEIGHT, x: true }}
          >
            <Column title="Page Name" key="field" dataIndex="field" />
            <Column title="Route" key="value" dataIndex="value" />
            <Column
              title="Access"
              key="access"
              dataIndex="access"
              render={renderAccess}
            />
          </Table>
        </Form.Item>
      </Form>
    </>
  );
};

PagesSettings.defaultProps = {
  widgetName: "",
  loading: false,
  roles: [],
  dataSources: [],
};

PagesSettings.propTypes = {
  widgetName: PT.string,
  loading: PT.bool,
  dataSources: PT.arrayOf(PT.shape()),
  roles: PT.arrayOf(PT.shape()),
  onSave: PT.func.isRequired,
};

export default PagesSettings;
