import { NotificationManager } from "react-notifications";
import { Redirect, Route } from "react-router-dom";
import { isAdmin } from "./auth";
import _ from "lodash";

let notificationFired = false;

function ProtectedRoute({ children, roles, ...rest }) {
  if (_.isArray(roles) && roles.length) {
    const hasAccess = isAdmin({ roles, targetUrl: window.location.pathname });

    if (!hasAccess && !notificationFired) {
      NotificationManager.error("Access denied.");
      notificationFired = true;
    }

    return (
      <Route
        {...rest}
        render={({ location }) =>
          hasAccess && _.isArray(roles) && roles.length ? (
            children
          ) : (
            <Redirect
              to={{
                pathname: "home",
                state: { from: location },
              }}
            />
          )
        }
      />
    );
  }

  return null;
}

export default ProtectedRoute;
