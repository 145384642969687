import { Button, Checkbox, Form } from "antd";
import React, { useCallback, useEffect } from "react";

const GraphSettings = ({ initialValues, onFinish }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, [form, initialValues.type]);

  const getInitialValues = useCallback(
    () => ({
      type: initialValues.type || true,
    }),
    [initialValues.type]
  );

  return (
    <div>
      <Form form={form} onFinish={onFinish} initialValues={getInitialValues()}>
        <Form.Item
          label="Graph type"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          name="type"
          valuePropName="checked"
        >
          <Checkbox>Hierarchical</Checkbox>
        </Form.Item>

        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Button size="small" type="primary" htmlType="submit">
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GraphSettings;
