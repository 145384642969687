/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Button, Input } from "antd";
import PT from "prop-types";
import _ from "lodash";
import { ExclamationCircleTwoTone } from "@ant-design/icons";
import { NotificationManager } from "react-notifications";
import { getPrefix } from "../../../util/Widget";
import { IDENTIFIER_SEPERATOR } from "../../../constants/Config";

const RegisterCard = ({
  uniqueReason,
  registerCompound,
  registerIds,
  selectedDataSource,
  isKnown,
  value,
  isProcess,
  showIdInput,
  availableDataSources,
  renderCheckButton,
  isRegisterAllowed,
  idValue,
  setIdValue,
}) => {
  const prefix = getPrefix(availableDataSources, selectedDataSource);

  const renderRegisterButton = () => (
    <Button
      size="small"
      disabled={
        !selectedDataSource || (!isKnown && _.isEmpty(value)) || isProcess
      }
      className={`gx-btn-sm gx-btn-primary gx-mb-0 ${
        showIdInput ? "gx-ml-4" : ""
      }`}
      onClick={() => {
        if (showIdInput && _.isEmpty(idValue)) {
          NotificationManager.error("Please enter id.");
        } else {
          registerCompound({
            selectedDataSource,
            id: showIdInput
              ? `${prefix}${IDENTIFIER_SEPERATOR}${idValue}`
              : null,
          });
        }
      }}
    >
      Register
    </Button>
  );

  return (
    <>
      {!(registerIds || []).length ? (
        <div>
          <p className="gx-text-primary">{uniqueReason}</p>
          <div className="gx-d-flex">
            {showIdInput && (
              <Input
                size="small"
                placeholder="Enter Id"
                prefix={`${prefix} ${IDENTIFIER_SEPERATOR}`}
                value={idValue}
                onChange={(e) => setIdValue(e.target.value)}
                style={{ maxWidth: "50%" }}
                maxLength={10}
              />
            )}

            {showIdInput ? (
              <>
                {isRegisterAllowed
                  ? renderRegisterButton()
                  : renderCheckButton()}
              </>
            ) : (
              <>{renderRegisterButton()}</>
            )}
          </div>
        </div>
      ) : null}

      {showIdInput && (
        <p className="gx-text-warning gx-mt-2" style={{ width: "30vw" }}>
          <ExclamationCircleTwoTone className="gx-mr-2" twoToneColor="F44336" />
          The ID needs to be lower than highest registered number. Otherwise the
          database will become corrupted.
        </p>
      )}

      {isProcess ? (
        <p className="gx-text-danger gx-mt-2">
          <ExclamationCircleTwoTone className="gx-mr-2" twoToneColor="F44336" />
          Registering Process is restricted.
        </p>
      ) : null}
    </>
  );
};

RegisterCard.defaultProps = {
  registerIds: [],
  selectedDataSource: null,
  isProcess: false,
  showIdInput: false,
};

RegisterCard.propTypes = {
  uniqueReason: PT.string.isRequired,
  registerCompound: PT.func.isRequired,
  registerIds: PT.arrayOf(PT.shape()),
  selectedDataSource: PT.shape(),
  isProcess: PT.bool,
  showIdInput: PT.bool,
};

export default RegisterCard;
