import React, { useEffect, useState } from "react";
import ImageCarousel from "components/Registration/ImageCarousel";
import { getImageFromSmiles } from "../../../appRedux/services/RegisterProcess";
import PT from "prop-types";

const PreviewStructure = ({ structure }) => {
  const [img, setImg] = useState();

  useEffect(() => {
    getImageFromSmiles(structure, setImg);
  }, [structure]);

  return (
    <ImageCarousel
      structureImage={[img]}
      width={100}
      height={100}
      className="compound-image-preview"
    />
  );
};

PreviewStructure.propTypes = {
  structure: PT.any,
};

export default PreviewStructure;
