import React from "react";
import usePortal from "react-useportal";

import Panel from "../Panel";

const PanelPortal = (props) => {
  const { id, showPanel = true, onDeletePanel } = props;

  const { Portal } = usePortal({
    bindTo: document.getElementById(id || "panel-portal"),
  });

  return (
    <>
      {showPanel ? (
        <Portal>
          <Panel {...props} onDeletePanel={onDeletePanel} />
        </Portal>
      ) : (
        <div>{props.children}</div>
      )}
    </>
  );
};

export default PanelPortal;
