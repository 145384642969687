import {
  GET_GROUPS_REQUESTED,
  GET_GROUPS_SUCCESS,
  GET_MEMBER_GROUPS_REQUESTED,
  GET_MEMBER_GROUPS_SUCCESS,
  SEARCH_USERS_REQUESTED,
  SEARCH_USERS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  groups: [],
  users: [],
  loading: false,
  memberGroups: [],
};

const GraphReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }

    case GET_GROUPS_SUCCESS: {
      return {
        ...state,
        loading: false,
        groups: action.payload?.value,
      };
    }

    case SEARCH_USERS_REQUESTED: {
      return {
        ...state,
        users: [],
      };
    }

    case SEARCH_USERS_SUCCESS: {
      return {
        ...state,
        users: action.payload?.value,
      };
    }

    case GET_MEMBER_GROUPS_REQUESTED: {
      return {
        ...state,
        memberGroups: [],
      };
    }

    case GET_MEMBER_GROUPS_SUCCESS: {
      return {
        ...state,
        memberGroups: action.payload?.value,
      };
    }

    default:
      return state;
  }
};

export default GraphReducer;
