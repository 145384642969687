/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { Button, Checkbox, Form, Select, Spin, Table, Tag } from "antd";
import React, { useEffect, useReducer, useState } from "react";
import PT from "prop-types";
import _ from "lodash";
import {
  autoAssignApi,
  checkUniqApi,
  registerRequestApi,
} from "../BulkUpdate/service";
import { Link } from "react-router-dom";
import { parse } from "sdf-parser";
import { TYPE_OPTIONS } from "../../../constants/Config";
import pluralize from "pluralize";
import PreviewStructure from "../PreviewStructure";
import SelectMatches from "../SelectMatches";

const UploadStructures = ({
  tableColumns,
  dataSource,
  sourceName,
  tableData,
  setTableData,
  sdfFile,
  selectedOption,
  setHeaders,
}) => {
  const [registerLoading, setRegisterLoading] = useState(false);
  const [validateLoading, setValidateLoading] = useState(false);
  const [uniqLoading, setUniqLoading] = useState(false);
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    setTableData(dataSource);
  }, []);

  useEffect(() => {
    if (selectedOption === TYPE_OPTIONS.SDF) {
      const reader = new FileReader();
      reader.onload = (evt) => {
        var result = parse(evt.target.result);

        const dt = _.map(result?.molecules, (item) => {
          let normalizedItems = {};

          for (const [key, value] of Object.entries(item)) {
            let newValue = value;

            if (typeof value === "string") {
              newValue = (value || "").replaceAll("\n", " ");
            }

            normalizedItems[key] = newValue;
          }
          return {
            ...normalizedItems,
            structure: item?.molfile,
            value: item?.SMILES,
          };
        });

        setTableData([...tableData, dt]);
        setHeaders(["Structure", ...result?.labels]);
      };
      reader.readAsText(sdfFile, "utf-8");
    }
  }, []);

  const renderMatches = (matches) => {
    return (
      <>
        {_.map(matches, (match, index) => (
          <Tag color="#2db7f5">
            <Link
              key={index}
              to={`/${sourceName}/compound/${match}`}
              target="_blank"
              replace
            >
              {match}
            </Link>
          </Tag>
        ))}
      </>
    );
  };

  // register
  const register = () => {
    let promises = [];
    let ids = [];
    _.forEach(tableData, (item) => {
      const isIsomer = _.get(item, "registerAsIsomer") || false;

      promises.push(
        registerRequestApi(
          item?.structure,
          sourceName,
          isIsomer,
          true,
          isIsomer ? "ISOMER" : null
        )
      );
      ids.push(item?.id);
    });

    let newTableSet = tableData;

    setRegisterLoading(true);
    Promise.allSettled(promises).then(async (results) => {
      _.forEach(results, (result, resultId) => {
        const index = ids[resultId];

        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            register: {
              registerCompoundId: _.get(result, "value.data[0].compoundId"),
              registerMatch: _.get(result, "value.data[0].match"),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            register: {
              registerError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setRegisterLoading(false);
    });
  };

  // Run auto assign agains table data.
  const validate = () => {
    let promises = [];

    _.forEach(tableData, (item) => {
      promises.push(autoAssignApi({ result: item?.structure }));
    });

    let newTableSet = tableData;

    setValidateLoading(true);
    Promise.allSettled(promises).then((results) => {
      _.forEach(results, (result, index) => {
        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            validate: {
              ...(result?.value?.data || {}),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            validate: {
              validateError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setValidateLoading(false);
    });
  };

  // check uniqueness
  const checkUniq = () => {
    let promises = [];

    _.forEach(tableData, (item) => {
      promises.push(
        checkUniqApi({ result: item?.structure, sourceName: sourceName })
      );
    });

    let newTableSet = tableData;

    setUniqLoading(true);
    Promise.allSettled(promises).then(async (results) => {
      _.forEach(results, (result, index) => {
        if (result.status === "fulfilled") {
          newTableSet[index] = {
            ...newTableSet[index],
            uniq: {
              ...(result?.value?.data || {}),
            },
          };
        }

        if (result.status === "rejected") {
          newTableSet[index] = {
            ...newTableSet[index],
            uniq: {
              uniquenessError: result?.reason?.response?.data?.message,
            },
          };
        }
      });
      setTableData(newTableSet);
      forceUpdate();
      setUniqLoading(false);
    });
  };

  const columns = [
    {
      key: "imagePreview",
      title: "Image Preview",
      width: "200px",
      render: (p, { structure }) => {
        return <PreviewStructure structure={structure} />;
      },
    },
    {
      key: "validate",
      width: "100px",
      title: (
        <>
          {validateLoading ? (
            <Spin size="small" tip="loading" />
          ) : (
            <Button type="primary" size="small" onClick={validate}>
              Validate
            </Button>
          )}
        </>
      ),
      render: (p, { validate = {} }, index) => {
        const { achiral, noStructure, validateError, opticalIsomer } = validate;
        return (
          <div className="card-item-value" style={{ textAlign: "left" }}>
            {noStructure === true ? <span>No Structure</span> : null}

            {opticalIsomer === "undefined" ? (
              <>
                <span className="gx-text-secondary">
                  Optical Isomer Undefined
                </span>

                <Form layout="vertical" size="small" className="gx-mt-3">
                  <Form.Item label="Register as isomer:">
                    <Checkbox
                      onChange={(checked) => {
                        if (checked.target.checked === true) {
                          _.set(tableData[index], "registerAsIsomer", true);
                        }
                      }}
                    />
                  </Form.Item>
                </Form>
              </>
            ) : null}

            {achiral === true && opticalIsomer !== "undefined" ? (
              <span className="gx-text-success">Achiral</span>
            ) : null}

            {opticalIsomer === "defined" ? (
              <span className="gx-text-success">Optical Isomer</span>
            ) : null}
            {validateError ? (
              <span className="gx-text-danger">{validateError}</span>
            ) : null}
          </div>
        );
      },
    },
    {
      key: "uniq",
      width: "200px",
      title: (
        <>
          {uniqLoading ? (
            <Spin size="small" tip="loading" />
          ) : (
            <Button type="primary" size="small" onClick={checkUniq}>
              Uniqueness Check
            </Button>
          )}
        </>
      ),
      render: (p, { uniq = {} }, index) => {
        const { unique, uniquenessError, exactMatch } = uniq;
        return (
          <div className="card-item-value" style={{ textAlign: "left" }}>
            {unique === true ? <span>Unique</span> : null}

            <SelectMatches
              exactMatch={exactMatch}
              onChange={(values) => {
                _.set(tableData[index], "targetId", values[0]);
              }}
            />

            {uniquenessError ? (
              <span className="gx-text-warning">{uniquenessError}</span>
            ) : null}
          </div>
        );
      },
    },
    {
      key: "register",
      width: "100px",
      title: (
        <>
          {registerLoading ? (
            <Spin size="small" tip="loading" />
          ) : (
            <Button type="primary" size="small" onClick={register}>
              Register
            </Button>
          )}
        </>
      ),
      render: (p, { register = {} }) => {
        const { registerMatch, registerCompoundId, registerError } = register;
        return (
          <div className="card-item-value" style={{ textAlign: "left" }}>
            {(registerMatch || []).length ? (
              <div>
                <span>Duplicate Match: </span>
                {renderMatches([registerMatch])}
              </div>
            ) : null}
            {(registerCompoundId || []).length ? (
              <div className="gx-text-success">
                <span>Registered: </span>
                {renderMatches([registerCompoundId])}
              </div>
            ) : null}
            {registerError ? (
              <span className="gx-text-warning">{registerError}</span>
            ) : null}
          </div>
        );
      },
    },
    ...tableColumns,
  ];

  return (
    <div>
      <div
        className="d-flex gx-mb-2 gx-text-primary"
        style={{ textAlign: "left" }}
      >
        Total: <span>{(tableData || []).length}</span>{" "}
        <span>{pluralize("item", (tableData || []).length)}</span>
      </div>
      <Table
        columns={columns}
        size="small"
        pagination={false}
        dataSource={[...tableData]}
        scroll={{ x: 1200 }}
      />
    </div>
  );
};

UploadStructures.defaultProps = {
  tableColumns: [],
  dataSource: [],
};

UploadStructures.propTypes = {
  tableColumns: PT.arrayOf(PT.shape()),
  dataSource: PT.arrayOf(PT.shape()),
};

export default UploadStructures;
