/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { Modal, Popover, Table, Tag } from "antd";
import Widget from "components/Widget";
import "./style.css";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  EXPLORE_PAGE_MAIN_TAB_OPTIONS,
  EXPLORE_PAGE_TAB_OPTIONS,
} from "../../../constants/Config";
import { saveAs } from "file-saver";
import XlsxPopulate from "xlsx-populate";

import {
  PAGE_LIMIT,
  renderCompoundDataTable,
  renderDocumentTable,
  renderImage,
  renderProcessTableResults,
} from "../../SearchPage/helper";
import { addParametersToDocument } from "../../../util/Widget";
import { PrinterTwoTone } from "@ant-design/icons";
import { FileDetailComponent } from "../../DetailPage/DocumentCollection/helper";
import { useReactToPrint } from "react-to-print";
import AllTab from "../AllTab";
import {
  populateImageMapping,
  renderExplorePageCompoundTable,
  renderExplorePageStructure,
} from "../helper";
import AdvancedSearch from "../AdvancesSearch";
import CanvasSettings from "../CanvasSettings";
import ProcessSearch from "../ProcessSearch";

let first = true;

const ExploreDetail = ({
  page,
  setPage,
  perPage,
  setPerPage,
  currentTab,
  selectedDataSources,
  documentCollectionOptions,
  currentMainTab,
  structureSearchResults,
  structureResultLoading,
  mapImages,
  setMapImages,
  filteredSearchResults,
  setFilteredSearchResults,
  availableWidgets,
  onSearchByAdvanced,
  showAdvancedSearchForm,
  setShowAdvancedSearchForm,
  formItems,
  setFormItems,
  showStructureSearchForm,
  setShowStructureSearchForm,
  searchOps,
  form,
  matchSearch,
  cutoff,
  setCutoff,
  queryStructure,
  setQueryStructure,
  setStructureImage,
  onSearch,
  onSearchByProcess,
  searchTerm,
  advancedSearchJoinType,
  setAdvancedSearchJoinType,
  availableDataSources,
  sources,
}) => {
  const {
    loading,
    tableData,
    structuresTableData = [],
    widgetIdsNormalized,
  } = useSelector(({ explore }) => explore);
  const { loading: searchLoading } = useSelector(({ search }) => search);

  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [currentSelection, setCurrentSelection] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  // Process seearch canvas result.
  const [result, setResult] = useState("");
  const [components, setComponents] = useState([]);

  const { concepts } = useSelector(({ search }) => search);

  const [imageRendered, setImageRendered] = useState(false);

  const exportWidgetData = () => {
    const currentResult = _.isEmpty(filteredResult) ? concepts : filteredResult;

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      let aggregatedKeys = [];
      _.forEach(currentResult, (item) => {
        const keys = item?.parameters || {};
        _.forEach(keys, (value, key) => {
          aggregatedKeys.push(key);
        });
      });

      let headers = [];

      headers = ["Id", "Source", ..._.uniq(aggregatedKeys)];

      const sheet1 = workbook.sheet(0);
      const sheetData = _.map(currentResult, (item) => {
        let row = [];
        row = [item?.compoundId, item?.sourceName];
        _.forEach(item?.parameters, (value, key) => {
          const index = _.findIndex(headers, (target) => target === key);
          if (index !== -1) {
            // row = insert(row, index, value);
            row[index] = value;
          }
        });

        return row;
      });

      try {
        const totalColumns = (sheetData || []).length
          ? sheetData[0].length || 0
          : 0;
        sheetData.unshift(headers);
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
      } catch (error) {}

      return workbook.outputAsync().then((res) => {
        saveAs(res, `compound-data.xlsx`);
      });
    });
  };

  // const columns = [
  //   {
  //     title: "Structure",
  //   },
  //   {
  //     title: "Compound ID",
  //     dataIndex: "compoundId",
  //   },
  //   {
  //     title: "Data source",
  //     dataIndex: "sourceName",
  //   },
  //   {
  //     title: "Widget Type",
  //     dataIndex: "type",
  //     render: (p, { type }) => {
  //       return <span>{_.capitalize(type)}</span>;
  //     },
  //   },
  //   {
  //     title: "Data",
  //     render: (p, { parameters }) => {
  //       return (
  //         <div key={uuidv4()}>
  //           {_.map(parameters, (param, key) => {
  //             if (_.isString(param)) {
  //               return (
  //                 <Popover placement="top" content={<span>{key}</span>}>
  //                   <Tag color="#2db7f5">{param}</Tag>
  //                 </Popover>
  //               );
  //             } else {
  //               return null;
  //             }
  //           })}
  //         </div>
  //       );
  //     },
  //   },
  // ];

  // When active tab is either `Structure` or `Process Steps`, fetch by rmoId.
  const isStructureTab =
    currentTab === EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES ||
    EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STEPS ||
    currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE;

  const structureTableDataWithCanvas = structuresTableData;

  useEffect(() => {
    if (
      (tableData || []).length &&
      (currentTab === EXPLORE_PAGE_TAB_OPTIONS.COMPOUND ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS ||
        EXPLORE_PAGE_TAB_OPTIONS.PROJECT ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS) &&
      !loading
    ) {
      setImageRendered(true);

      populateImageMapping({
        currentPage: page,
        pageSize: perPage,
        searchResults: tableData,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
        isEntityIds: page === "documents",
      });
    }
  }, [tableData, page, perPage, currentTab, loading, mapImages, setMapImages]);

  const searchInput = useRef(null);

  const fileDetailRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => fileDetailRef.current,
  });

  const widgetOptions = _.find(documentCollectionOptions, {
    value: currentDocument?.collection,
  });

  useEffect(() => {
    let first = true;

    if (isStructureTab) {
      if (
        _.isArray(structureTableDataWithCanvas) &&
        structureTableDataWithCanvas.length > 0
      ) {
        renderImage({
          currentPage: 1,
          pageSize: PAGE_LIMIT,
          searchResults: structureTableDataWithCanvas,
          mapImages,
          setMapImages,
          forceUpdate,
          loadWithPagination: true,
        });
      }
      const dataSourceGroupedStructured = isStructureTab
        ? _.groupBy(structureTableDataWithCanvas, "rmoId")
        : [];

      const dataSourceStructures = _.map(
        Object.entries(dataSourceGroupedStructured),
        (row) => {
          const child = row[1];
          const firstItem = child[0] || {};

          const childGrouped = _.groupBy(child, "source");

          const childRow = _.map(Object.entries(childGrouped), (childRow) => {
            return {
              source: childRow[0],
              child: childRow[1],
              image: null,
            };
          });

          return {
            image: null,
            child: childRow,
          };
        }
      );

      const normalizedDatasourceStructures = _.map(
        dataSourceStructures,
        (ds) => {
          const childLength = (ds?.child || []).length;

          return [
            _.map(ds?.child || [], (childRow, index) => {
              if (!index) {
                return {
                  image: ds?.image,
                  rowSpan: childLength,
                  ...childRow,
                };
              }

              return {
                ...childRow,
              };
            }),
          ];
        }
      );

      const tableDatasourceStructures = _.flattenDeep(
        normalizedDatasourceStructures
      );

      if (tableDatasourceStructures.length > 0) {
        setFilteredSearchResults(tableDatasourceStructures);
      }
    }

    return () => {
      first = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [structureTableDataWithCanvas, structuresTableData]);

  useEffect(() => {
    let first = true;

    if (concepts.length > 0 && first) {
      renderImage({
        currentPage: 1,
        pageSize: PAGE_LIMIT,
        searchResults: concepts,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [concepts]);

  return (
    <>
      <Widget
        // title={`Explore ${pluralize(_.capitalize(currentTab))}`}
        styleName="gx-card-tabs gx-card-profile explore-detail"
      >
        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.DOCUMENT ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_DOCUMENTS ? (
          <div className="gx-mt-3">
            {renderDocumentTable({
              dataSource: addParametersToDocument(
                _.map(tableData, (td) => ({
                  ...td,
                  source: td?.sourceName,
                }))
              ),
              onSelect: (document) => {
                setShowModal(true);
                setCurrentDocument(document);
              },
              searchInput,
              filterValue,
              setFilterValue,
              filteredResult,
              setFilteredResult,
              mapImages,
              setMapImages,
              forceUpdate,
              showModal: true,
              field: currentSelection,
              sources: availableDataSources,
              showEditButton: false,
              searchTerm,
              total: (structuresTableData || []).length,
              page,
              setPage,
              perPage,
              setPerPage,
              loading,
            })}
          </div>
        ) : null}
        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.COMPOUND ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_COMPOUND ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROJECT ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS ? (
          <div className="gx-mt-3">
            {renderExplorePageCompoundTable({
              sources: selectedDataSources,
              dataSource: tableData,
              total: (structuresTableData || []).length,
              page,
              perPage,
              setPage,
              setPerPage,
              searchInput,
              field: currentSelection,
              filteredResult,
              setFilteredResult,
              filterValue,
              setFilterValue,
              tableKey: 1,
              mapImages,
              setMapImages,
              forceUpdate,
              availableWidgetParams: {},
              currentTab,
              searchTerm,
              availableWidgets,
              loading,
            })}
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH ? (
          <div className="gx-mt-3">
            {renderExplorePageStructure({
              dataSource: filteredSearchResults,
              mapImages,
              setMapImages,
              forceUpdate,
              loading,
              title: "Compound ID",
              key: "compoundId",
            })}
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STEPS &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH ? (
          <div className="gx-mt-3">
            {renderExplorePageStructure({
              dataSource: filteredSearchResults,
              mapImages,
              setMapImages,
              forceUpdate,
              loading,
              title: "Process ID",
              key: "processId",
            })}
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH &&
        !showStructureSearchForm ? (
          <div className="gx-mt-3">
            {renderProcessTableResults({
              availableDataSources: availableDataSources,
              dataSource: structureSearchResults,
              mapImages,
              sourceGroups: {},
              setMapImages,
              forceUpdate,
              loading: structureResultLoading,
            })}
            {/* {renderStructureSearchTable({
              dataSource: structureTableDataWithCanvas,
              loading: structureResultLoading,
            })} */}
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.STRUCTURES &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH ? (
          <div
            style={
              !showStructureSearchForm
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <CanvasSettings
              searchOps={searchOps}
              form={form}
              matchSearch={matchSearch}
              cutoff={cutoff}
              setCutoff={setCutoff}
              onSearch={onSearch}
              queryStructure={queryStructure}
              setQueryStructure={setQueryStructure}
              setStructureImage={setStructureImage}
              onClose={() => setShowStructureSearchForm(false)}
            />
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH ? (
          <div
            style={
              !showStructureSearchForm
                ? {
                    display: "none",
                  }
                : {}
            }
          >
            <ProcessSearch
              onSearch={onSearchByProcess}
              setStructureImage={setStructureImage}
              selectedDataSources={selectedDataSources}
              result={result}
              setResult={setResult}
              components={components}
              setComponents={setComponents}
            />
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROCESS_STRUCTURE &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.STRUCTURE_SEARCH &&
        !showStructureSearchForm ? (
          <div className="gx-mt-3">
            {renderProcessTableResults({
              dataSource: structureSearchResults,
              mapImages,
              sourceGroups: {},
              setMapImages,
              forceUpdate,
              loading: structureResultLoading,
            })}
          </div>
        ) : null}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.ALL &&
        (currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.GENERIC_SEARCH ||
          currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH) ? (
          <AllTab
            tableData={tableData}
            loading={loading}
            page={page}
            perPage={perPage}
            setPerPage={setPerPage}
            setPage={setPage}
            total={(widgetIdsNormalized || []).length}
            onSelect={(document) => {
              setShowModal(true);
              setCurrentDocument(document);
            }}
            searchTerm={searchTerm}
            availableWidgets={availableWidgets}
            mapImages={mapImages}
            sources={sources}
          />
        ) : null}

        {currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH ? (
          <div style={!showAdvancedSearchForm ? { display: "none" } : {}}>
            <AdvancedSearch
              availableWidgets={availableWidgets}
              onSearch={(formItems) => {
                onSearchByAdvanced(formItems, advancedSearchJoinType);
              }}
              formItems={formItems}
              setFormItems={setFormItems}
              advancedSearchJoinType={advancedSearchJoinType}
              setAdvancedSearchJoinType={setAdvancedSearchJoinType}
            />
          </div>
        ) : null}

        {currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.ADVANCED_SEARCH &&
        !showAdvancedSearchForm ? (
          <div className="gx-mt-3">
            {renderCompoundDataTable({
              sources: selectedDataSources,
              dataSource: concepts,
              searchInput,
              formItems,
              filterValue,
              setFilterValue,
              mapImages,
              setMapImages,
              forceUpdate,
              filteredResult,
              setFilteredResult,
              exportWidgetData,
              loading: searchLoading,
            })}
          </div>
        ) : null}

        {/* {currentTab === EXPLORE_PAGE_TAB_OPTIONS.PROJECT ||
        currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROJECTS ? (
          <div
            className="gx-mt-3"
            style={{
              textAlign: "center",
            }}
          >
            {<span>No data found.</span>}
          </div>
        ) : null} */}

        {currentTab === EXPLORE_PAGE_TAB_OPTIONS.SPECIFIC_PROCESS &&
        currentMainTab === EXPLORE_PAGE_MAIN_TAB_OPTIONS.SPECIFIC_SEARCH ? (
          <div className="gx-mt-3">
            {renderExplorePageCompoundTable({
              sources: selectedDataSources,
              dataSource: tableData,
              total: (structuresTableData || []).length,
              page,
              perPage,
              setPage,
              setPerPage,
              searchInput,
              field: currentSelection,
              filteredResult,
              setFilteredResult,
              filterValue,
              setFilterValue,
              tableKey: 1,
              mapImages,
              setMapImages,
              forceUpdate,
              availableWidgetParams: {},
              currentTab,
              searchTerm,
              availableWidgets,
              loading,
            })}
          </div>
        ) : null}
      </Widget>

      <Modal
        open={showModal}
        title={
          <div>
            <span className="gx-mr-3">Document Detail</span>
            <PrinterTwoTone onClick={handlePrint} />
          </div>
        }
        onCancel={() => setShowModal(false)}
        onOk={() => {
          setShowModal(false);
        }}
      >
        <FileDetailComponent
          ref={fileDetailRef}
          currentDocument={currentDocument}
          widgetOptions={widgetOptions}
          selectedDocument={currentDocument}
          sourceName={currentDocument?.source}
          collectionOptions={documentCollectionOptions}
          fileDetailRef={fileDetailRef}
        />
      </Modal>
    </>
  );
};

export default ExploreDetail;
