import React, { useEffect, useRef, useState } from "react";
import GenericEditor from "../../GenericEditor";
import {
  EDITOR_OPTIONS,
  SEARCH_CUTOFF_MAX,
  SEARCH_CUTOFF_MIN,
} from "../../../constants/Config";
import { useDispatch } from "react-redux";
import { saveUserData } from "../../../appRedux/actions";
import { Button, Col, Form, Input, Row, Select, Slider } from "antd";
import _ from "lodash";
import { marks, similarityOptions } from "../../SearchPage/helper";

import "./style.css";

const CanvasSettings = ({
  searchOps,
  form,
  matchSearch,
  cutoff,
  setCutoff,
  onSearch,
  onClose,
  queryStructure,
  setQueryStructure,
  setStructureImage,
  disableUpdateUserData = false
}) => {
  const childRef = useRef();
  // eslint-disable-next-line no-unused-vars
  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const dispatch = useDispatch();

  const formRef = useRef();

  useEffect(() => {
    if (queryStructure && childRef && childRef?.current?.isBlankStructure()) {
      childRef.current?.loadMOL(queryStructure);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const defaultSearchOperation = _.find(searchOps, {
      value: "BINGO_SUBSTRUCTURE",
    });

    formRef.current?.setFieldsValue({
      searchOps: defaultSearchOperation?.value,
    });
  }, [searchOps]);

  return (
    <div
      className="gx-pl-4 px-pr-4 explorePage__canvasSettings"
      style={{
        width: "95%",
        margin: "20px auto",
      }}
    >
      <GenericEditor
        ref={childRef}
        currentEditor={currentEditor}
        setDrawing={(data) => {
          setQueryStructure(data);
        }}
        canvasChanged={() => {
          childRef.current.getCDXML().then((cdxml) => {

            if (!disableUpdateUserData) {
              dispatch(saveUserData("searchPageEditor", cdxml));
            }

            childRef.current.getMol();
          });

          const image = childRef.current?.getSvg();
          setStructureImage(image);
        }}
      />

      <Form
        ref={formRef}
        form={form}
        name="search-form"
        className="gx-pt-3"
        onFinish={(values) => {
          onSearch(values);
        }}
      >
        <Form.Item
          required
          rules={[
            {
              required: true,
              message: "Please select match search",
            },
          ]}
          name="searchOps"
          label="Search Types"
          className="gx-mt-3"
        >
          <Select placeholder="Please Select Match Search">
            {searchOps.map((item, index) => (
              <Select.Option
                key={`search-ops-option-${index}`}
                value={item.value}
              >
                {item.field}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        {similarityOptions.includes(matchSearch) && (
          <Form.Item required name="cutoffs" className="gx-mt-3">
            <Row>
              <Col sm={6} xs={6}>
                <Input
                  type="number"
                  value={cutoff}
                  min={Number(SEARCH_CUTOFF_MIN)}
                  max={Number(SEARCH_CUTOFF_MAX)}
                  maxLength={3}
                  minLength={1}
                  onChange={(e) => {
                    let cur = Number(e.target.value);
                    setCutoff(cur);
                  }}
                />
              </Col>
              <Col sm={16} xs={12} className="gx-ml-3">
                <Slider
                  min={SEARCH_CUTOFF_MIN}
                  max={SEARCH_CUTOFF_MAX}
                  value={cutoff}
                  onChange={setCutoff}
                  trackStyle={{ backgroundColor: "coral" }}
                  handleStyle={{ backgroundColor: "coral" }}
                  tooltipPlacement="bottom"
                  tooltipVisible={false}
                  marks={marks}
                />
              </Col>
            </Row>
          </Form.Item>
        )}

        <Form.Item className="gx-mt-2">
          <Button htmlType="submit" size="small" type="primary">
            Search
          </Button>

          <Button size="small" onClick={onClose}>
            Cancel
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CanvasSettings;
