export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function trimInput(str = "") {
  const trimmed = String(str).trim();
  return trimmed.replace(/  +/g, " ");
}

/**
 * Get paginated slice of array
 * @param {*} array
 * @param {*} page_size
 * @param {*} page_number
 * @returns
 */
export function paginate(array, page_size, page_number) {
  // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
  return array.slice((page_number - 1) * page_size, page_number * page_size);
}
