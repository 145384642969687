/* eslint-disable no-unused-vars */
import { Button, Descriptions, Drawer, Modal, Popover, Table } from "antd";
import PT from "prop-types";
import React, { useEffect, useMemo, useReducer, useState } from "react";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";

import {
  AppstoreTwoTone,
  CloseCircleTwoTone,
  EditTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import _ from "lodash";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import { ReactComponent as ExpandIcon } from "../../../assets/vendors/expand.svg";
import {
  SAFETY_SENTENCES_IMAGE_METADATA,
  SAFETY_SENTENCES_SAVED_DATA,
  SAFETY_SENTENCES_WIDGET,
} from "../../../constants/Config";
import {
  getLanguageItem,
  renderSafetySentencesModal,
} from "../../../util/SafetySentences";
import Header from "../Widget/Header";

import CircularProgress from "components/CircularProgress";
import { useDispatch, useSelector } from "react-redux";
import { getMetadata, saveMetadata } from "../../../appRedux/actions";
import { checkFormEditable, getParent } from "../../../util/Widget";
import RowEditable from "../Widget/RowEditable";

import { getMetadataBatchApi } from "../../../appRedux/services/Metadata";
import {
  checkRowIsAccessible,
  checkRowIsEditable,
  getRoleId,
} from "../../../util/auth";
import Versioning from "../Widget/Versioning";
import SelectSafetySentence from "./SelectSafetySentence";
import { renderCategory, renderPictogram } from "./helper";
import "./style.css";

const SafetySentencesWidget = ({
  id,
  title,
  sourceName,
  widget,
  components,
  versionRoles = [],
  safetySentenceTypes = {},
}) => {
  const [open, setOpen] = useState(true);
  const [selectedLanguage, setSelectedLanguage] = useState(
    "SafetySentencesLangEn",
  );
  const [addMode, setAddMode] = useState(false);
  const [languageMap, setLanguageMap] = useState();
  const [imageMap, setImageMap] = useState();
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showTableModal, setShowTableModal] = useState(false);
  const [selectedSentence, setSelectedSentence] = useState();
  const [form, setForm] = useState({});
  const dispatch = useDispatch();
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [parentData, setParentData] = useState([]);

  const [currentSentencesVersion, setCurrentSentencesVersion] = useState();
  const [totalVersion, setTotalVersion] = useState(null);

  const [currentWidgetVersion, setCurrentWidgetVersion] = useState();
  const [widgetVersions, setWidgetVersions] = useState(null);

  const hasVersion = (versionRoles || []).includes(getRoleId());

  const {
    [SAFETY_SENTENCES_SAVED_DATA]: data,
    [SAFETY_SENTENCES_WIDGET]: metadataForm,
  } = useSelector(({ metadata }) => metadata);

  const lookupData = useSelector(({ lookup }) => lookup);

  const [additionalSafetyData, setAdditionalSafetyData] = useState([]);

  useEffect(() => {
    let sd = [];
    for (const [key, value] of Object.entries(lookupData)) {
      const currentData = _.get(value, "safetySentences.parameters.data");

      sd = [...sd, ...(currentData || [])];
    }

    setAdditionalSafetyData(sd);
  }, [lookupData]);

  useEffect(() => {
    if (data && data?.version && !totalVersion) {
      setTotalVersion(data?.version);
      setCurrentSentencesVersion(data?.version);
    }
  }, [data, totalVersion]);

  useEffect(() => {
    if (!_.isEmpty(metadataForm)) {
      const { parameters, version } = metadataForm;
      setForm({ ...parameters });

      if (version && _.isNull(widgetVersions)) {
        setWidgetVersions(version);
        setCurrentWidgetVersion(version);
      }
    } else {
      setForm({});
    }
  }, [metadataForm, widgetVersions]);

  useEffect(() => {
    if (sourceName) {
      dispatch(getMetadata(id, sourceName, SAFETY_SENTENCES_SAVED_DATA));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // Add components array

    const ids = components.map((item) => item?.compoundId || item?.name);

    const componentData = _.get(data, "parameters.data");

    if (_.isArray(componentData) && !componentData.length) {
      ids.push(getParent(id));
    }

    if (sourceName && _.isArray(componentData) && !parentData.length) {
      getMetadataBatchApi(
        SAFETY_SENTENCES_SAVED_DATA,
        ids,
        sourceName,
        setParentData,
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, data]);

  useEffect(() => {
    if (sourceName) {
      dispatch(getMetadata(id, sourceName, SAFETY_SENTENCES_WIDGET));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!imageMap) {
      getSystemWidget(SAFETY_SENTENCES_IMAGE_METADATA, setImageMap, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSystemWidget(selectedLanguage, setLanguageMap, false);
  }, [selectedLanguage]);

  const [currentData, setCurrentData] = useState([]);
  const defaultParams = useMemo(
    () => (data || {})?.parameters?.data || [],
    [data],
  );

  useEffect(() => {
    if (defaultParams.length) {
      setCurrentData(defaultParams);
    }
  }, [defaultParams]);

  const group = _.map(safetySentenceTypes || {}, (option) => option);
  const url = _.get(safetySentenceTypes, "URL");
  const version = _.get(safetySentenceTypes, "version");
  const componentsParams = _.map(
    (parentData || {})?.parameters?.data || [],
    (parent) => ({ ...parent, isParent: true }),
  );
  const totalData = _.uniqWith(
    [...defaultParams, ...componentsParams, ...additionalSafetyData],
    (a, b) => a?.id === b?.id && a?.category === b?.category,
  );

  const onSaveCurrentData = () => {
    dispatch(
      saveMetadata(
        id,
        {
          data: currentData,
        },
        SAFETY_SENTENCES_SAVED_DATA,
        sourceName,
      ),
    );
    setTotalVersion(null);
    setLoading(false);
    setShowModal(false);
    NotificationManager.success("Successfully saved data.");
  };

  const onSave = ({ statement, category }) => {
    setLoading(true);
    const currentValue = {
      statement,
      category,
    };

    const newData = data?.parameters?.data || [];

    if (statement && category) {
      newData.push(currentValue);
    }

    if (statement && category) {
      // dispatch(
      //   saveMetadata(
      //     id,
      //     {
      //       data: newData,
      //     },
      //     SAFETY_SENTENCES_SAVED_DATA,
      //     sourceName
      //   )
      // );
      // setTotalVersion(null);
      setCurrentData(newData);
    }

    if (form) {
      dispatch(
        saveMetadata(id, form || {}, SAFETY_SENTENCES_WIDGET, sourceName),
      );
      setWidgetVersions(null);
    }

    setTimeout(() => {
      setLoading(false);
      forceUpdate();
    }, 300);
  };

  const renderActionButtons = () => {
    const { rows = [] } = widget;
    const isEditable = checkFormEditable(rows);
    return (
      <>
        {!addMode && isEditable && (
          <Popover content="Edit Safety Sentences">
            <EditTwoTone className="gx-pl-2" onClick={() => setAddMode(true)} />
          </Popover>
        )}
        {isEditable && (
          <Popover content="Show detail">
            <ExpandIcon
              className="gx-ml-2 expand-icon"
              onClick={() => setShowModal(true)}
            />
          </Popover>
        )}
        {isEditable && addMode && (
          <Popover content="Save Safety Sentences">
            <SaveTwoTone
              twoToneColor="#52c41a"
              className="gx-pl-2"
              onClick={onSave}
            />
          </Popover>
        )}
        {addMode && isEditable && (
          <Popover content="Close Safety Sentences">
            <CloseCircleTwoTone
              className="gx-pl-2"
              twoToneColor="#a94442"
              onClick={() => setAddMode(false)}
            />
          </Popover>
        )}
      </>
    );
  };

  const dataSource = totalData.map(
    ({ statement, category, isParent = false }) => ({
      id: statement,
      category,
      name: `${statement} - ${getLanguageItem(statement, languageMap)}`,
      isParent,
    }),
  );

  const elements = _.flatten(
    group.map((groupItem) => groupItem?.elements),
  ).filter((item) => item?.active);

  const renderName = (record, source) => {
    const { id } = source;
    return {
      props: {},
      children: (
        <div key={uuidv4()}>
          <AppstoreTwoTone
            className="gx-mr-2 gx-pointer"
            twoToneColor="#20c997"
            onClick={() => {
              setShowTableModal(true);
              setSelectedSentence(source);
            }}
          />
          <span dangerouslySetInnerHTML={{ __html: id }} />
        </div>
      ),
    };
  };

  const dataSourceWithPictogram = _.map(dataSource, (item) => {
    const data = _.find(
      elements,
      (element) => element?.statementId === item?.id,
    );
    let pictogramIds = [];

    if (data) {
      pictogramIds = data?.category[0]?.pictogramIds;
    }

    return {
      ...item,
      pictogramId: pictogramIds[0],
      pictogramIds,
    };
  });

  const dataSources = _.orderBy(
    dataSourceWithPictogram,
    ["isParent", "pictogramId"],
    ["desc", "asc"],
  );
  const groups = _.uniqWith(
    dataSourceWithPictogram,
    (a, b) => a?.pictogramId === b?.pictogramId && a?.isParent === b?.isParent,
  );

  const grouped = _.map(dataSources, (dataSource) => ({
    ...dataSource,
    shouldRenderPictogram:
      _.findIndex(groups, {
        category: dataSource?.category,
        isParent: dataSource?.isParent,
      }) !== -1,
  }));

  const dataSourceGrouped = _.uniqWith(grouped, _.isEqual);
  const onFinishSelectSafetySentences = ({ statement, category }) => {
    // onSave({ statement, category });
    setCurrentData([...currentData, { statement, category }]);
  };

  const onRemoveSafetySentence = ({ statement, category }) => {
    const newData = _.filter(
      data?.parameters?.data || [],
      (item) => item?.statement !== statement || item?.category !== category,
    );

    setCurrentData(newData);

    // dispatch(
    //   saveMetadata(
    //     id,
    //     {
    //       data: newData,
    //     },
    //     SAFETY_SENTENCES_SAVED_DATA,
    //     sourceName
    //   )
    // );
    // setTotalVersion(null);
  };

  return (
    <>
      <div
        className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
          open ? "isOpen" : ""
        }`}
        key={uuidv4()}
      >
        <Header
          title={title}
          open={open}
          setOpen={setOpen}
          child={renderActionButtons()}
        />
        {open && (
          <div className="gx-ml-4 gx-mt-3 gx-pb-3">
            {loading ? (
              <CircularProgress className="gx-loader-400 loader" />
            ) : (
              <>
                <div className="gx-pr-4 gx-mb-3">
                  {((widget || {})?.rows || []).map((row, index) => {
                    /**
                     * If `ahsk_sap` or `ahskText_sap` values defined. We should ignore `ahsk` and `ahskText` row fields.
                     */
                    if (row?.name === "ahsk" || row?.name === "ahskText") {
                      const isSapValuesExists =
                        _.get(form, "ahsk_sap") || _.get(form, "ahskText_sap");

                      if (isSapValuesExists) {
                        return null;
                      }
                    }

                    return (
                      <>
                        {row?.type === "EDITABLE" && (
                          <RowEditable
                            field={row?.field}
                            index={index}
                            name={row?.name}
                            value={_.get(form, row?.name)}
                            limit={row?.limit}
                            form={form}
                            addMode={addMode}
                            onChange={(val) => {
                              form[row?.name] = val;
                            }}
                            info={row?.info}
                            isEditable={checkRowIsEditable(row?.isEditable)}
                            isAccessible={checkRowIsAccessible(
                              row?.isAccessible,
                            )}
                            isCopiable={row?.isCopiable}
                            systemWidgetName={SAFETY_SENTENCES_WIDGET}
                            sourceName={sourceName}
                          />
                        )}
                      </>
                    );
                  })}
                </div>

                {widgetVersions && hasVersion ? (
                  <Versioning
                    currentVersion={currentWidgetVersion}
                    versions={_.times(widgetVersions, (index) => index + 1)}
                    onChangeVersion={(cVersion) => {
                      setCurrentWidgetVersion(cVersion);

                      dispatch(
                        getMetadata(
                          id,
                          sourceName,
                          SAFETY_SENTENCES_WIDGET,
                          null,
                          cVersion,
                        ),
                      );
                    }}
                  />
                ) : null}

                <Descriptions size="small">
                  <Descriptions.Item label="H/P Sentences Reference Documentation">
                    <a href={url} target="_blank" rel="noreferrer">
                      {version}
                    </a>
                  </Descriptions.Item>
                </Descriptions>

                <Table
                  dataSource={dataSourceGrouped}
                  bordered
                  pagination={false}
                  rowClassName={(record) =>
                    record?.isParent ? "safetySentences__table__parentRow" : ""
                  }
                >
                  <Table.Column
                    title="Name"
                    render={renderName}
                    width={100}
                  ></Table.Column>
                  <Table.Column
                    title="Description"
                    render={(p, { id, category, pictogramId }) =>
                      renderCategory({
                        id,
                        category,
                        languageMap,
                      })
                    }
                  ></Table.Column>
                  <Table.Column
                    title="Pictogram"
                    render={(p, { pictogramIds, shouldRenderPictogram }) => {
                      if (shouldRenderPictogram) {
                        return renderPictogram({ pictogramIds, imageMap });
                      } else {
                        return null;
                      }
                    }}
                    onCell={({
                      id,
                      pictogramId,
                      shouldRenderPictogram,
                      isParent,
                    }) => {
                      const sources = _.filter(
                        dataSourceWithPictogram,
                        (source) => {
                          return (
                            source?.pictogramId === pictogramId &&
                            source?.isParent
                          );
                        },
                      );

                      // Sources for qualities
                      const qualitySources = _.filter(
                        dataSourceWithPictogram,
                        (source) => {
                          return (
                            source?.pictogramId === pictogramId &&
                            !source?.isParent
                          );
                        },
                      );

                      const uniqueQualitySources = _.uniqWith(
                        qualitySources,
                        (recordA, recordB) => {
                          return (
                            recordA?.id === recordB?.id &&
                            recordA?.category === recordB?.category
                          );
                        },
                      );

                      const uniqueSources = _.uniqWith(
                        sources,
                        (recordA, recordB) => {
                          return (
                            recordA?.id === recordB?.id &&
                            recordA?.category === recordB?.category
                          );
                        },
                      );

                      let rowSpanCount;
                      if (!isParent) {
                        rowSpanCount = (uniqueQualitySources || []).length;
                      } else {
                        rowSpanCount = (uniqueSources || []).length;
                      }

                      return {
                        rowSpan: shouldRenderPictogram ? rowSpanCount : 0,
                      };
                    }}
                  ></Table.Column>
                </Table>

                <Versioning
                  currentVersion={currentSentencesVersion}
                  versions={_.times(totalVersion || 1, (index) => index + 1)}
                  onChangeVersion={(cVersion) => {
                    setCurrentSentencesVersion(cVersion);

                    dispatch(
                      getMetadata(
                        id,
                        sourceName,
                        SAFETY_SENTENCES_SAVED_DATA,
                        null,
                        cVersion,
                      ),
                    );
                  }}
                />
              </>
            )}
          </div>
        )}
      </div>
      <Drawer
        open={showModal}
        onOk={onSave}
        placement="right"
        onClose={() => setShowModal(false)}
        okText="Save"
        title="Safety Sentences"
        footer={[<Button onClick={() => setShowModal(false)}>Close</Button>]}
      >
        <SelectSafetySentence
          defaultParams={_.uniqWith(currentData, _.isEqual)}
          group={group}
          languageMap={languageMap}
          elements={elements}
          onFinish={onFinishSelectSafetySentences}
          onSave={onSaveCurrentData}
          onRemove={onRemoveSafetySentence}
          loading={loading}
        />
      </Drawer>

      <Modal
        title="Safety Sentences"
        visible={showTableModal}
        onOk={() => setShowTableModal(false)}
        onCancel={() => setShowTableModal(false)}
        width="80%"
        footer={[
          <Button type="primary" onClick={() => setShowTableModal(false)}>
            OK
          </Button>,
        ]}
      >
        {renderSafetySentencesModal(
          elements,
          selectedSentence,
          languageMap,
          imageMap,
        )}
      </Modal>
    </>
  );
};

SafetySentencesWidget.defaultProps = {
  id: "",
  title: "",
  sourceName: "",
  widget: {},
  components: [],
};

SafetySentencesWidget.propTypes = {
  id: PT.string,
  title: PT.string,
  sourceName: PT.string,
  widget: PT.shape(),
  components: PT.arrayOf(PT.shape()),
};

export default SafetySentencesWidget;
