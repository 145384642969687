import React, { useState } from "react";
import { copyToClipboard } from "../../../util/url";

const CopiableRowItem = ({ title, data }) => {
  const [copied, setCopied] = useState(false);

  return (
    <div className="card-item">
      <div className="card-item-field">
        <span>{title}</span>
        <>
          {copied ? (
            <i
              className="icon icon-check success"
              style={{ marginTop: "3px" }}
            />
          ) : (
            <i
              className="icon icon-copy"
              style={{ marginTop: "3px" }}
              onClick={() => copyToClipboard(data, copied, setCopied)}
            />
          )}
        </>
      </div>
      <div className="card-item-value">
        <span>{data}</span>
      </div>
    </div>
  );
};

export default CopiableRowItem;
