import React, { useState } from "react";
import PT from "prop-types";
import { Carousel, Image } from "antd";

import "./style.css";

const ImageCarousel = ({
  structureImage,
  width,
  height,
  noBorder,
  className,
  disablePreview = false,
  imageClassName = "",
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Carousel
        dotPosition="bottom"
        className={`gx-mb-0 ${className ? className : ""}`}
      >
        {structureImage.map((image, index) => (
          <div className="gx-mt-3 width-fit-content" key={`image-${index}`}>
            <div
              className={
                noBorder
                  ? ""
                  : "ant-card ant-card-bordered gx-card-widget gx-card-full"
              }
            >
              {image ? (
                <Image
                  preview={{ visible: false }}
                  className={imageClassName}
                  alt="preview"
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(image)}`}
                  width={width}
                  height={height}
                  onClick={() => (disablePreview ? null : setVisible(true))}
                />
              ) : (
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <span
                    className="gx-text-danger"
                    style={{
                      fontSize: "10px",
                    }}
                  >
                    Structure not found
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </Carousel>
      {visible ? (
        <>
          {
            <div
              style={{
                display: "none",
              }}
            >
              <Image.PreviewGroup
                preview={{
                  visible,
                  onVisibleChange: (vis) => setVisible(vis),
                }}
              >
                <Image
                  src={`data:image/svg+xml;utf8,${encodeURIComponent(
                    structureImage[0]
                  )}`}
                />
              </Image.PreviewGroup>
            </div>
          }
        </>
      ) : null}
    </>
  );
};

ImageCarousel.propTypes = {
  structureImage: PT.arrayOf(PT.string).isRequired,
  setVisible: PT.func.isRequired,
};

export default ImageCarousel;
