import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import CircularProgress from "components/CircularProgress";
import CompoundNotFound from "../../../../assets/vendors/notfound.png";

import ImageCarousel from "../../../Registration/ImageCarousel";

import "./style.css";
import _ from "lodash";

const FullImage = ({
  compoundImage,
  loading,
  width,
  showCircularProgress = true,
}) => {
  return (
    <>
      {_.isEmpty(compoundImage) ? (
        <img
          src={CompoundNotFound}
          alt="compound-not-found"
          width={150}
          height={150}
          className="compound-not-found"
          style={{
            display: "flex",
            margin: "0 auto",
          }}
        />
      ) : (
        <div
          className="full-image-carousel"
          key={uuidv4()}
          style={{
            maxWidth: `${width}px`,
            height: `${width}px`,
          }}
        >
          {loading ? (
            <>
              {showCircularProgress ? (
                <CircularProgress className="gx-loader-400 loader" />
              ) : null}
            </>
          ) : (
            <ImageCarousel
              structureImage={[compoundImage]}
              setVisible={() => {}}
            />
          )}
        </div>
      )}
    </>
  );
};

FullImage.defaultProps = {
  compoundImage: "",
  loading: false,
  width: 250,
};

FullImage.propTypes = {
  compoundImage: PT.string,
  loading: PT.bool,
  width: PT.oneOf(PT.number, PT.string),
};

export default FullImage;
