import _ from "lodash";
import { config } from "../../constants/Config";
import { parseSubstance } from "../../util/Widget";
import axios from "../services/apiThrottle";

export const getCurrentData = async (
  metadataName,
  id,
  sourceName,
  isProject,
) => {
  const url = isProject
    ? `${config.SRD_API_URL}/metadata/project/${metadataName}?projectId=${id}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${id}`;

  return axios.get(`${url}&sourceName=${sourceName}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });
};

export const saveCurrentData = async (
  metadataName,
  id,
  data,
  sourceName,
  isProject = false,
  userName = null,
) => {
  const url = isProject
    ? `${config.SRD_API_URL}/metadata/project/${metadataName}?projectId=${id}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${id}`;

  return axios.post(
    `${url}&sourceName=${sourceName}&SRD_USER=${userName}`,
    data,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );
};

/**
 * Save Document Data from Upload Page
 * @param {*} param0
 * @returns
 */
export const saveDocumentData = async ({
  sourceName,
  request,
  collection,
  method = "POST",
  documentId,
  selectedDataSource,
}) => {
  const formData = new FormData();

  const additionalData = _.omit(
    request,
    "mimeType",
    "entityIds",
    "remarks",
    "owners",
  );

  let listTypes = [];
  const searchSubjects = _.map(additionalData, (value, key) => {
    if (_.isArray(value)) {
      return {
        key,
        value: value.join(","),
      };
    } else {
      let lists = [];

      try {
        lists = JSON.parse(value);
      } catch (error) {}

      if ((lists || []).length > 0 && _.isObject(lists)) {
        _.forEach(lists, (list) => {
          listTypes.push({
            key,
            value: list,
          });
        });
        return null;
      } else {
        return {
          key,
          value,
        };
      }
    }
  });

  const searchSubjectsWithTypes = [
    ..._.filter(
      searchSubjects,
      (item) =>
        !_.isNull(item) && (_.isString(item?.value) || _.isNumber(item?.value)),
    ),
    ..._.filter(listTypes, (type) => _.isString(type?.value)),
  ];

  let body = {};

  body.mimeType = "text/x-uri";
  body.uri = "";
  body.name = _.get(request, "name");
  body.description = _.get(request, "remarks");
  body.entityIds = _.get(request, "entityIds");
  body.owners = _.get(request, "owners");
  body.collection = collection;
  body.entityIds =
    (body.entityIds || []).map((item) =>
      parseSubstance(item, selectedDataSource),
    ) || [];

  body.searchSubjects = searchSubjectsWithTypes;

  formData.append("file", []);
  formData.append("request", JSON.stringify(body));

  if (method === "POST") {
    return axios.post(
      `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data;charset=UTF-8",
        },
      },
    );
  } else {
    return axios.put(
      `${config.SRD_API_URL}/repository/document?source=${sourceName}&documentId=${documentId}`,
      JSON.stringify(body),
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
  }
};

export const postBulkFile = ({ sourceName, idField, file, userName }) => {
  const formData = new FormData();

  formData.append("file", file);

  return axios.post(
    `${config.SRD_API_URL}/bulk?sourceName=${sourceName}&idField=${idField}&SRD_USER=${userName}`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data;charset=UTF-8",
      },
    },
  );
};
