import React from "react";
import { Popover } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import PT from "prop-types";

import "./style.css";
import { getNextLink, getPreviousLink } from "../../../util/Widget";

const NavButton = ({ sourceName, pageType, id, navigationMapping }) => {
  return (
    <div className="nav-buttons">
      <Popover content={<span>Go to previous {pageType}</span>}>
        <LeftOutlined
          size="small"
          onClick={() => {
            window.location.assign(
              `/${sourceName}/${pageType}/${getPreviousLink(
                id,
                navigationMapping
              )}`
            );
          }}
        />
      </Popover>
      <Popover content={<span>Go to next {pageType}</span>}>
        <RightOutlined
          size="small"
          onClick={() => {
            window.location.assign(
              `/${sourceName}/${pageType}/${getNextLink(id, navigationMapping)}`
            );
          }}
        />
      </Popover>
    </div>
  );
};

NavButton.defaultProps = {
  sourceName: "",
  pageType: "",
  id: "",
};

NavButton.propTypes = {
  sourceName: PT.string,
  pageType: PT.string,
  id: PT.string,
};

export default NavButton;
