import { Card } from "antd";
import React from "react";
import ImageCarousel from "components/Registration/ImageCarousel";
import CompoundNotFound from "../../../assets/vendors/notfound.png";
import PT from "prop-types";
import _ from "lodash";

import "./style.css";
import ProcessRow from "./ProcessRow";

const ProcessTable = ({
  tableContent,
  selectedDataSource,
  sourceName,
  onChangeSubstanceNumber,
  onChangeTypes,
  selectOptions,
  onChangeRole,
  onDeleteRow,
  sourceIdentifier,
  currentInputType,
  renderSearchTypes = false,
  searchOps = [],
  selectedDataSources = [],
}) => {
  const renderImage = (p, row = {}) => {
    const { imagePreview } = row;

    if (imagePreview) {
      return (
        <ImageCarousel
          structureImage={[imagePreview]}
          width={100}
          height={100}
          className="compound-image-preview"
        />
      );
    } else {
      return (
        <img
          src={CompoundNotFound}
          alt="compound-not-found"
          width={100}
          height={100}
          className="compound-not-found"
        />
      );
    }
  };

  return (
    <>
      {(tableContent || []).length ? (
        <div>
          <h5 className="gx-text-black gx-mb-3">Process Components:</h5>
          {_.map(tableContent, (row) => {
            return (
              <Card
                className={`gx-card-widget gx-card-full ${
                  renderSearchTypes ? "processTable__row" : "processTable__card"
                }`}
                key={row?.index}
              >
                <div className="gx-user-wid-body gx-d-flex processTable__rowContainer">
                  <div className="processTable__rowImage">
                    {renderImage(null, row)}
                  </div>
                  <div className="processTable__rowForm">
                    <ProcessRow
                      role={row?.role}
                      substanceNumber={row?.substanceNumber}
                      index={row?.index}
                      sourceIdentifier={sourceIdentifier}
                      onChangeSubstanceNumber={onChangeSubstanceNumber}
                      selectOptions={selectOptions}
                      onChangeRole={onChangeRole}
                      onDeleteRow={onDeleteRow}
                      renderSearchTypes={renderSearchTypes}
                      searchOps={searchOps}
                      onChangeTypes={onChangeTypes}
                      selectedDataSources={selectedDataSources}
                    />
                  </div>
                </div>
                <div className={`processTable__ribbon ${row?.role}`} />
              </Card>
            );
          })}
        </div>
      ) : null}
    </>
  );
};

ProcessTable.defaultProps = {
  tableContent: [],
  selectedDataSource: null,
  sourceName: "",
  sourceIdentifier: "",
};

ProcessTable.propTypes = {
  tableContent: PT.arrayOf(PT.shape()),
  selectedDataSource: PT.string,
  sourceName: PT.string,
  onChangeSubstanceNumber: PT.func.isRequired,
  selectOptions: PT.func.isRequired,
  onChangeRole: PT.func.isRequired,
  onDeleteRow: PT.func.isRequired,
  sourceIdentifier: PT.string,
};

export default ProcessTable;
