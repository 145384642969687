/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Col, Divider, Form, Input, Row, Select } from "antd";
import PT from "prop-types";
import _ from "lodash";
import {
  NAVIGATE_TO_PAGES,
  SEARCH_OPTIONS,
} from "../../../../constants/Config";

const SearchRowSettingsForm = ({
  data,
  widgetName,
  onSave,
  availableWidgetParams = [],
  allWidgets,
}) => {
  const [form, setForm] = useState({});

  useEffect(() => {
    setForm({ ...data });
  }, [data]);

  const onChangeValue = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  return (
    <>
      {!_.isEmpty(data) ? (
        <Form>
          <Divider orientation="left">MAIN SETTINGS: </Divider>

          <Row>
            <Col span={12}>
              <Form.Item
                label="Title"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  value={form?.title}
                  onChange={(e) => onChangeValue("title", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Tooltip"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  value={form?.info}
                  onChange={(e) => onChangeValue("info", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Search Type"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  options={SEARCH_OPTIONS}
                  value={form?.type}
                  onChange={(e) => onChangeValue("type", e)}
                />
              </Form.Item>
            </Col>

            {form?.type === "SEARCH_BY_ID" ? (
              <Col span={12}>
                <Form.Item
                  label="Select Searchable Widgets"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    showSearch
                    options={_.map(allWidgets, (widget) => ({
                      label: widget?.field,
                      value: widget?.value,
                    }))}
                    value={form?.widgets || []}
                    mode="multiple"
                    onChange={(e, p) => {
                      onChangeValue("widgets", p);
                    }}
                  />
                </Form.Item>
              </Col>
            ) : null}
            {form?.type === "NAVIGATE" ? (
              <Col span={12}>
                <Form.Item
                  label="Pages to Apply"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    showSearch
                    options={NAVIGATE_TO_PAGES}
                    value={form?.targetPages || []}
                    mode="multiple"
                    onChange={(e, p) => {
                      onChangeValue("targetPages", p);
                    }}
                  />
                </Form.Item>
              </Col>
            ) : null}

            {form?.type === "DATA" ? (
              <Col span={12}>
                <Form.Item
                  label="Select Field"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                >
                  <Select
                    showSearch
                    options={availableWidgetParams}
                    value={form?.field}
                    onChange={(e, p) => {
                      setForm({
                        ...form,
                        label: p?.label,
                        field: e,
                      });
                    }}
                  />
                </Form.Item>
              </Col>
            ) : null}
            {/* <Col span={4}>
              <Form.Item
                label="Operators"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  options={SEARCH_OPERATORS}
                  onChange={(e) => onChangeValue("operators", e)}
                />
              </Form.Item>
            </Col> */}
          </Row>

          <Button
            type="primary"
            size="small"
            onClick={() => {
              onSave(widgetName, form);
            }}
          >
            Save
          </Button>
        </Form>
      ) : null}
    </>
  );
};

export default SearchRowSettingsForm;

SearchRowSettingsForm.defaultProps = {
  data: {},
  roles: [],
};

SearchRowSettingsForm.propTypes = {
  data: PT.shape(),
  setData: PT.func.isRequired,
  onSave: PT.func.isRequired,
  roles: PT.arrayOf(PT.shape()),
};
