import { Descriptions } from "antd";
import pluralize from "pluralize";
import React from "react";

const KnownResult = ({ result }) => {
  return (
    <div>
      <p className="gx-text-success gx-mb-2">
        {result?.count} {pluralize("Compounds", result?.count)} Uploaded:
      </p>
      <Descriptions size="small" bordered column={1}>
        <Descriptions.Item label="File Name">
          {result?.fileName}
        </Descriptions.Item>
      </Descriptions>
    </div>
  );
};

export default KnownResult;
