/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useReducer, useRef, useState } from "react";

import CircularProgress from "components/CircularProgress";

import "./style.css";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Radio,
  Select,
  Switch,
} from "antd";
import {
  getSystemWidget,
  getUserWidget,
} from "../../appRedux/services/SystemWidget";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  ProcessRegistrationTypes,
  PROCESS_STEP_COMPONENTS_ROLES,
  REGISTRATION_DATA_WIDGET_NAME,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  EDITOR_OPTIONS,
  CURRENT_EDITOR_OPTION,
  IDENTIFIER_SEPERATOR,
} from "../../constants/Config";
import _ from "lodash";
import {
  buildForm,
  registerProcessApi,
  registerUnknownComponent,
  updateSmiles,
} from "../../appRedux/services/RegisterProcess";
import {
  getImageFromCompoundId,
  searchForProcess,
} from "../../appRedux/services/Search";
import { getSourceNamePrefix } from "../../util/api";
import NotFoundCard from "../../components/RegisterProcess/NotFoundCard";
import TextArea from "antd/lib/input/TextArea";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataSources,
  getProjects,
  getSourceMap,
  getSystemWidgets,
  saveMetadata,
  saveUserData,
  setCurrentWidgets,
} from "../../appRedux/actions";
import { filterByAuthorizedRoles } from "../../util/search";
import { getRole } from "../../util/auth";
import ProcessIdCard from "../../components/RegisterProcess/ProcessIdCard";
import { v4 as uuidv4 } from "uuid";
import { NotificationManager } from "react-notifications";
import ProcessRegistrationForm from "../../components/RegisterProcess/ProcessRegistrationForm";
import ProcessTable from "../../components/RegisterProcess/ProcessTable";
import { getParent, getPrefix, getProcessPrefix } from "../../util/Widget";
import {
  BarcodeOutlined,
  BranchesOutlined,
  CheckOutlined,
  DatabaseOutlined,
  SearchOutlined,
  SolutionOutlined,
} from "@ant-design/icons";
import Widget from "../../components/DetailPage/Widget";
import { useMsal } from "@azure/msal-react";
import DocumentationPopover from "../../components/DocumentationPopover";
import { searchDocumentation } from "../UploadPage/helper";
import DocumentationSettings from "../../components/DocumentationSettings";
import { getProcess } from "../../appRedux/services/Process";
import GenericEditor from "../../components/GenericEditor";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import { getCompoundType } from "../../util/url";
import { getCompoundConceptShallow } from "../../appRedux/services/Widget";
import { renderProcessTableResults } from "../../components/SearchPage/helper";
import HelmEditor from "../../components/HelmEditor";
import ProcessSelection from "../../components/DetailPage/ProjectManagementWidget/ProcessSelection";
import { useForm } from "antd/lib/form/Form";
import PanelPortal from "../../components/PanelPortal";

const RegisterProcess = () => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const [result, setResult] = useState("");
  const [firstRender, setFirstRender] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [components, setComponents] = useState([{}]);
  const [cdxml, setCdxml] = useState("");
  const [loading, setLoading] = useState(false);
  const [registeredId, setRegisteredId] = useState(null);
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const { dataSources } = useSelector(({ search }) => search);
  const { sourceMap = [] } = useSelector(({ generic }) => generic);
  const { projects = [] } = useSelector(({ project }) => project);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [registerMessage, setRegisterMessage] = useState("");
  const [currentStep, setCurrentStep] = useState(0);
  const [processRegistrationTypes, setProcessRegistrationTypes] = useState([]);
  const [selectedProcessRegistrationType, setSelectedProcessRegistrationType] =
    useState(null);
  const [currentInputType, setCurrentInputType] = useState(null);
  const [documentations, setDocumentations] = useState([]);
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);
  const [defaultSourceName, setDefaultSourceName] = useState({});
  const [currentProcessId, setCurrentProcessId] = useState();
  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const [updateMode, setUpdateMode] = useState(false);
  const [structureSearchResults, setStructureSearchResults] = useState([]);
  const [structureSearchLoaded, setStructureSearchLoaded] = useState(false);
  const [structureResultLoading, setStructureResultLoading] = useState(false);
  const [mapImages, setMapImages] = useState({});
  const [showHelm, setShowHelm] = useState(false);
  const [helmEditorSettings, setHelmEditorSettings] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [viewType, setViewType] = useState("input");
  const [form] = useForm();
  const [processId, setProcessId] = useState();
  const [showPanel, setShowPanel] = useState(true);

  const processIdFormRef = useRef();

  const { registerProcessPageEditor } = useSelector(
    ({ currentUserData }) => currentUserData
  );
  const roles = useGetRoles();

  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const userNameWithRole = `${userName}-${getRole()}`;

  const curatorRoles = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "curateDatabase"
  );

  const isCurator =
    _.findIndex(curatorRoles || [], { sourceName: selectedDataSource }) !== -1;

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: "",
        database: "",
      })
    );
  }, []);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
  }, []);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
  }, []);

  useEffect(() => {
    getUserWidget(DEFAULT_SOURCE_NAME, userName, setDefaultSourceName, false);
  }, []);

  useEffect(() => {
    getSystemWidget(PROCESS_STEP_COMPONENTS_ROLES, setSelectOptions);
  }, []);

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        setCurrentEditor(currentOption?.option);
        setHelmEditorSettings(currentOption?.helmEditor);
      },
      false
    );
  }, []);

  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      "register-process",
      setDocumentations
    );
  }, []);

  // Register "registrar" and "registration type" after process is registered.
  useEffect(() => {
    if (registeredId) {
      dispatch(
        saveMetadata(
          registeredId,
          {
            registrar: accounts[0]?.username,
            registrationType: "interactive",
            registrationDate: new Date(),
          },
          REGISTRATION_DATA_WIDGET_NAME,
          selectedDataSource,
          "process"
        )
      );
    }
  }, [registeredId]);

  useEffect(() => {
    let subscribed = true;

    if (subscribed && firstRender) {
      childRef.current?.getMol();
      setFirstRender(false);
    }

    return () => {
      subscribed = false;
    };
  }, []);

  useEffect(() => {
    if (
      currentStep === 2 &&
      childRef.current?.isBlankStructure() &&
      childRef.current?.loadCDXML
    ) {
      setTimeout(() => {
        childRef.current?.loadCDXML(registerProcessPageEditor);
      }, 500);
    }
  }, [registerProcessPageEditor, currentStep]);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }

    if (!processRegistrationTypes.length) {
      getSystemWidget(ProcessRegistrationTypes, setProcessRegistrationTypes);
    }

    if (!(sourceMap || []).length) {
      dispatch(getSourceMap());
    }
  }, []);

  useEffect(() => {
    if ((processRegistrationTypes || []).length) {
      setSelectedProcessRegistrationType(processRegistrationTypes[0]?.type);
      setCurrentInputType(processRegistrationTypes[0]?.input);
    }
  }, [processRegistrationTypes]);

  useEffect(() => {
    if (sourceMap.length && !projects.length && selectedDataSource) {
      dispatch(getProjects(selectedDataSource));
    }
  }, [sourceMap, selectedDataSource]);

  const availableDataSources = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "processRegistrationDatabases"
  );

  useEffect(() => {
    if ((availableDataSources || []).length === 1 && !selectedDataSource) {
      setSelectedDataSource(availableDataSources[0]?.sourceName);
    }
  }, [availableDataSources]);

  let availableWidgets = [...systemWidgets, ...dataWidgets];

  const getWidgetData = (systemWidgetName) => {
    const targetWidget =
      _.find(availableWidgets, { value: systemWidgetName }) || {};

    return targetWidget?.widget;
  };

  const onUpdateCanvas = async (ops) => {
    setStructureSearchLoaded(false);

    if (showHelm) {
      setResult(result);
    } else {
      await childRef.current?.getDrawing();
    }

    const form = {
      ops,
      database: selectedDataSource
        ? selectedDataSource
        : availableDataSources[0]?.sourceName,
      sourceNamePrefix: getSourceNamePrefix(selectedDataSource, sourceMap),
    };

    await buildForm(form, setSearchLoading, setComponents, selectedDataSource);
  };

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userNameWithRole,
      (option) => {
        setSelectedDataSource(option?.process);
      },
      false,
      () => {
        setSelectedDataSource("");
      }
    );
  }, []);

  const canvasChanged = () => {
    childRef.current?.getDrawing();
    setRegisteredId(null);
    setCdxml("");

    // childRef.current?.getCDXML().then((cdxml) => {
    //   dispatch(saveUserData("registerProcessPageEditor", cdxml));
    // });
  };

  useEffect(() => {
    if (showHelm) {
      onUpdateCanvas([
        {
          smiles: result,
          index: uuidv4(),
          roles: "reactant",
        },
      ]);
      forceUpdate();
    } else {
      if (result.length > 0) {
        const item = _.isArray(result) ? result[0] : result;
        const details = item.split(">");

        const types = ["reactant", "catalyst", "product"];
        let ops = [];
        details.forEach((detail, index) => {
          if (!_.isEmpty(detail)) {
            const smiles = detail.split(".");
            smiles.forEach((smile) => {
              ops.push({
                smiles: smile,
                role: types[index],
                index: uuidv4(),
              });
            });
          }
        });
        if (ops.length > 0) {
          onUpdateCanvas(ops);
        }
      } else {
        onUpdateCanvas([]);
      }
    }
  }, [result, selectedDataSource]);

  const drawToCanvas = async (newComponents) => {
    let reactants = [];
    let catalysts = [];
    let products = [];

    newComponents.forEach((item) => {
      switch (item?.role) {
        case "reactant":
          reactants.push(item?.smiles);
          break;
        case "product":
        case "by-product":
        case "byproduct":
          products.push(item?.smiles);
          break;
        case "catalyst":
        case "solvent":
          catalysts.push(item?.smiles);
          break;
        default:
          break;
      }
    });

    const finalString = `${reactants.join(".")}>${catalysts.join(
      "."
    )}>${products.join(".")}`;

    childRef.current?.writeToCanvas(finalString);
    setRegisteredId(null);
  };

  const clearStates = () => {
    setStructureSearchLoaded(false);
    setRegisteredId(null);
    setComponents([]);
    childRef.current?.writeToCanvas("");
  };

  const onChangeSubstanceNumber = async (value, index) => {
    let newComponents = components;
    const ind = components.findIndex((item) => item.index === index);

    if (ind !== -1) {
      newComponents[ind].substanceNumber = value;
      const sourceName = getSourceNamePrefix(selectedDataSource, sourceMap);
      const comp = `${sourceName}-${value}`;
      newComponents[ind].compoundId = comp;
      try {
        const image = await getImageFromCompoundId(comp, selectedDataSource);
        if (image?.data) {
          newComponents[ind].imagePreview = image?.data;
          const smiles = await updateSmiles(comp, sourceMap);
          if (smiles?.data) {
            newComponents[ind].smiles = smiles?.data;
            await drawToCanvas(newComponents);
          }
        }
      } catch (error) {
        newComponents[ind].imagePreview = null;
        newComponents[ind].smiles = "";
      }
    }

    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onChangeRole = async (currentRole, index) => {
    let newComponents = components;
    const ind = components.findIndex((item) => item.index === index);

    if (ind !== -1) {
      newComponents[ind].role = currentRole;
      await drawToCanvas(newComponents);
    }

    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onAddRow = () => {
    let newComponents = components;
    newComponents.push({
      index: uuidv4(),
      smiles: "",
      role: "reactant",
      imagePreview: "",
      substanceNumber: null,
      compoundId: "",
      isFound: true,
    });
    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onSaveTraits = (id, form, metadataName) => {
    dispatch(saveMetadata(id, form || {}, metadataName, selectedDataSource));
    NotificationManager.success("Widget saved successfully.");
  };

  const register = (item) => {
    setSearchLoading(true);
    registerUnknownComponent(item?.smiles, selectedDataSource, userName)
      .then((result) => {
        if (result.data && result.data.length > 0) {
          const newLink = result.data[0]?.compoundId || result.data[0]?.match;

          const ind = components.findIndex(
            (comp) => comp.index === item?.index
          );

          dispatch(
            saveMetadata(
              result.data[0]?.compoundId,
              {
                registrar: accounts[0]?.username,
                registrationType: "interactive",
                registrationDate: new Date(),
              },
              REGISTRATION_DATA_WIDGET_NAME,
              selectedDataSource
            )
          );

          if (ind !== -1) {
            components.forEach((comp) => {
              if (comp.index === item.index) {
                getImageFromCompoundId(newLink, selectedDataSource).then(
                  (image) => {
                    const newComponents = components.map((c) => {
                      if (c.index === item.index) {
                        return {
                          ...item,
                          isFound: true,
                          compoundId: newLink,
                          substanceNumber: newLink.split("-")[1],
                          imagePreview: image?.data,
                        };
                      } else {
                        return c;
                      }
                    });
                    setComponents(newComponents);
                  }
                );
              }
            });
          }
        }
      })
      .finally(() => {
        setSearchLoading(false);
        canvasChanged();
        forceUpdate();
      });
  };

  const onDeleteRow = async (index) => {
    const newComponents = components.filter((item) => item.index !== index);
    setComponents(newComponents);
    await drawToCanvas(newComponents);
  };

  const tableContent = components.filter((item) => item?.role && item?.isFound);

  const notFoundItems = components.filter(
    (item) => item?.smiles && item?.role && item?.isFound === false
  );

  const checkInputType = () => {
    if (currentInputType === "compound" && !(result || "").includes(">")) {
      return true;
    }
    return false;
  };

  const registerProcess = async () => {
    if (showHelm) {
      let requestBody = JSON.stringify({
        processType: selectedProcessRegistrationType,
        processComponents: components,
        rxnSmiles: components[0]?.smiles,
      });

      await registerProcessApi(
        selectedDataSource,
        requestBody,
        (id) => {
          if (id) {
            setRegisteredId(id);
          }
        },
        setSearchLoading,
        setRegisterMessage,
        NotificationManager,
        updateMode
      );
    } else {
      childRef.current.getCDXML().then(async (xml) => {
        await childRef.current.getDrawing();

        // If component is compound type, default role should be "REACTANT"
        const filteredComponents = _.filter(
          components,
          (component) => component?.compoundId
        );
        const processComponents = filteredComponents.map((component) => {
          const componentRole =
            (component?.role || "").toUpperCase() || "REACTANT";
          return {
            role: componentRole,
            id: component?.compoundId,
          };
        });

        if (checkInputType() && (processComponents || []).length === 1) {
          processComponents.push({
            id: processComponents[0]?.id,
            role: "PRODUCT",
          });
        }

        let body =
          currentInputType === "reaction"
            ? { rxnSmiles: result }
            : { rxnSmiles: checkInputType() ? `${result}>>${result}` : result };

        if (updateMode) {
          const processId = processIdFormRef.current.getFieldValue("processId");
          body["processId"] = `${getProcessPrefix(
            dataSources,
            selectedDataSource
          )}${IDENTIFIER_SEPERATOR}${processId}`;
        }

        let requestBody = JSON.stringify({
          ...body,
          processType: selectedProcessRegistrationType,
          processComponents,
          cdxml: window.btoa(unescape(encodeURIComponent(xml))),
        });

        await registerProcessApi(
          selectedDataSource,
          requestBody,
          (id) => {
            if (id) {
              setRegisteredId(id);
            }
          },
          setSearchLoading,
          setRegisterMessage,
          NotificationManager,
          updateMode
        );
      });
    }
  };

  const renderStatus = ({ value, step }) => {
    let currentIcon;

    switch (step) {
      case 1:
        currentIcon = <CheckOutlined className="ant-steps-item-icon" />;
        break;
      case 2:
        currentIcon = <BarcodeOutlined className="ant-steps-item-icon" />;
        break;
      default:
        currentIcon = <DatabaseOutlined className="ant-steps-item-icon" />;
        break;
    }

    return (
      <>
        {(value || "").length ? (
          <div className="gx-d-flex gx-mb-3">
            {currentIcon}
            <span
              className="gx-text-primary gx-ml-2"
              style={{ marginTop: "5px" }}
            >
              {value}
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const fetchCdxml = (e) => {
    const val = e.target.value;
    setCurrentProcessId(val);

    const type = getCompoundType({
      sources: dataSources,
      sourceName: selectedDataSource,
      id: val,
    });

    // Is Component.
    if (type === "experiment" || type === "process") {
      getProcess(
        getParent(val),
        selectedDataSource,
        (res) => {
          if (res && res?.cdxml) {
            const target =
              currentEditor === EDITOR_OPTIONS.CHEMDRAW
                ? res?.cdxml
                : res?.rxnSmiles;
            childRef.current?.loadCDXML(target);
            canvasChanged();
          } else {
            NotificationManager.error(
              `Reaction drawing for ${val} is not found.`
            );
          }
        },
        () => {}
      );
    } else {
      getCompoundConceptShallow(selectedDataSource, getParent(val))
        .then((res) => {
          if (res && res.data && res.data.smiles) {
            childRef.current?.writeToCanvas(res.data.smiles);
            canvasChanged();
          }
        })
        .catch(() => {
          NotificationManager.error(
            `Reaction drawing for ${val} is not found.`
          );
        });
    }

    // getCdxml(val, sourceName, (res) => {
    //   childRef.current?.loadCDXML(res);
    //   canvasChanged();
    // });
  };

  const searchReactions = async () => {
    setStructureSearchLoaded(true);

    const queryComponents = _.map(components, (component) => {
      const type = component?.type || "BINGO_EXACT";

      let componentBody = {
        role: _.upperCase(component?.role),
        searchType: type,
      };

      if (type === "BINGO_EXACT" && component?.compoundId) {
        componentBody = {
          ...componentBody,
          processId: component?.compoundId,
        };
      } else {
        componentBody = {
          ...componentBody,
          structure: component?.smiles,
          cutoff: component?.cutoff || 0,
        };
      }

      return componentBody;
    });

    let body = {
      processQuery: {
        sourceName: selectedDataSource,
        queryComponents,
        joinType: "AND",
      },
    };

    await searchForProcess(body, setStructureResultLoading, (items) => {
      setStructureSearchResults(items);
    });
  };

  const fetchDataFromHelm = () => {
    clearStates();

    const contenteditableDivs = document.querySelectorAll(
      "div[contenteditable=true]"
    );

    if (contenteditableDivs && contenteditableDivs.length > 1) {
      const helmString = contenteditableDivs[1].innerHTML;

      setResult(helmString);
      setCurrentStep(2);
    } else {
      console.log("cannot find helm string.");
    }
  };

  const registerProcessContent = (
    <>
      <div className="gx-d-flex">
        <div className="right-content process-components">
          {searchLoading ? (
            <CircularProgress className="gx-loader-400 loader customLoader" />
          ) : (
            <>
              {renderStatus({
                value: `Selected Data Source: ${selectedDataSource}`,
                step: 0,
              })}
              {selectedProcessRegistrationType
                ? renderStatus({
                    value: `Selected Registration Type: ${selectedProcessRegistrationType}`,
                    step: 1,
                  })
                : null}
              {notFoundItems.map((notFound) => (
                <NotFoundCard
                  notFound={notFound}
                  register={() => register(notFound)}
                  selectedDataSource={selectedDataSource}
                />
              ))}
              <ProcessTable
                tableContent={tableContent}
                selectedDataSource={selectedDataSource}
                sourceName={selectedDataSource}
                onChangeSubstanceNumber={onChangeSubstanceNumber}
                selectOptions={selectOptions}
                onChangeRole={onChangeRole}
                onDeleteRow={onDeleteRow}
                sourceIdentifier={getPrefix(sourceMap, selectedDataSource)}
                currentInputType={currentInputType}
              />

              {registeredId ? (
                <Widget
                  id={registeredId}
                  systemWidgetName={REGISTRATION_DATA_WIDGET_NAME}
                  renderResidualData
                  widget={getWidgetData(REGISTRATION_DATA_WIDGET_NAME)}
                  onSaveWidget={(form, metadataName) => {
                    onSaveTraits(registeredId, form, metadataName);
                  }}
                  setCurrentVersion={() => {}}
                />
              ) : null}

              {registeredId ? (
                <ProcessIdCard
                  message={registerMessage}
                  id={registeredId}
                  projects={projects}
                  sourceName={selectedDataSource}
                  onSuccess={() => {
                    NotificationManager.success(
                      "Project successfully assigned."
                    );
                  }}
                  onError={() => {
                    NotificationManager.error("Something went wrong.");
                  }}
                  updateMode
                />
              ) : null}

              <div className="gx-d-flex">
                {!registeredId ? (
                  <Button
                    className="gx-mt-2"
                    type="primary"
                    size="small"
                    onClick={onAddRow}
                  >
                    Add Row
                  </Button>
                ) : null}
                {/* NOTE: Disabled check uniqueness for process temporarily. */}
                {/* {!registeredId && (
                <Button
                  className="gx-mt-2"
                  type="primary"
                  size="small"
                  onClick={checkUniqueness}
                  disabled={!selectedDataSource}
                >
                  Check Uniqueness
                </Button>
              )} */}
                {!registeredId &&
                  !childRef?.current?.isBlankStructure() &&
                  !(notFoundItems || []).length && (
                    <Button
                      className="gx-mt-2"
                      type="primary"
                      size="small"
                      onClick={() => registerProcess(updateMode)}
                      disabled={
                        !availableDataSources.length ||
                        !selectedDataSource ||
                        childRef?.current?.isBlankStructure()
                      }
                    >
                      {updateMode ? "Update" : "Register"}
                    </Button>
                  )}

                {childRef?.current?.isBlankStructure() && (
                  <Form.Item className="gx-ml-3">
                    <Input
                      type="text"
                      placeholder="or Enter Reaction ID"
                      onPressEnter={fetchCdxml}
                      defaultValue={currentProcessId}
                    />
                  </Form.Item>
                )}
              </div>
              <Button
                className="gx-mt-2"
                size="small"
                onClick={searchReactions}
              >
                Search for similar process steps.
              </Button>

              {cdxml.length ? (
                <TextArea height={400} value={cdxml}></TextArea>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );

  const selectDataSource = (
    <>
      {availableDataSources.length === 1 ? (
        <Form.Item
          label="Data Source"
          className="gx-ml-2 gx-mr-2 registerProcess__label"
        >
          <span className="gx-text-warning">
            {availableDataSources[0].sourceName}
          </span>
        </Form.Item>
      ) : (
        <>
          {!_.isNull(selectedDataSource) && !_.isEmpty(availableDataSources) ? (
            <Form.Item
              label="Data Source"
              className="gx-ml-2 gx-mr-2 registerProcess__label"
              initialValue={selectedDataSource}
            >
              <Select
                placeholder="Select Data Source"
                onChange={(e) => {
                  setSelectedDataSource(e);
                  next();
                }}
                defaultValue={selectedDataSource}
                dropdownMatchSelectWidth={false}
              >
                {availableDataSources.map((item, index) => (
                  <Select.Option
                    key={`data-source-option-${index}`}
                    value={item.sourceName}
                  >
                    {item.sourceName}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          ) : null}
          {!availableDataSources.length && (
            <div className="gx-text-warning gx-mt-2">
              No Data Sources Available. Please contact administrator.
            </div>
          )}
        </>
      )}
    </>
  );

  const next = () => {
    setCurrentStep(currentStep + 1);
  };

  // const prev = () => {
  //   setCurrentStep(currentStep - 1);
  // };

  // const steps = [
  //   {
  //     title: selectedDataSource
  //       ? `Selected Data Source: ${selectedDataSource}`
  //       : "Select Data Source",
  //     content: selectDataSource,
  //     icon: <DatabaseOutlined />,
  //   },
  //   {
  //     title: selectedProcessRegistrationType
  //       ? `Selected Type: ${selectedProcessRegistrationType}`
  //       : "Select Process Registration Type",
  //     content: (
  //       <ProcessRegistrationForm
  //         setSelectedProcessRegistrationType={
  //           setSelectedProcessRegistrationType
  //         }
  //         processRegistrationTypes={processRegistrationTypes}
  //         setCurrentInputType={setCurrentInputType}
  //         next={next}
  //         selectedProcessRegistrationType={selectedProcessRegistrationType}
  //         setRegisteredId={setRegisteredId}
  //       />
  //     ),
  //     icon: <BranchesOutlined />,
  //     disabled: !selectedDataSource,
  //   },
  //   {
  //     title: "Register Process",
  //     content: registerProcessContent,
  //     icon: <SolutionOutlined />,
  //     disabled: !selectedProcessRegistrationType || !selectDataSource,
  //   },
  // ];

  let sourceName = defaultSourceName?.option;
  const pageName = "register-process";

  const trainingSettings =
    _.find(documentCollectionOptions?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  const onUpdateDocumentations = () => {
    searchDocumentation(sourceName, pageName, setDocumentations);
  };

  const updateCanvas = (e) => {
    const id = `${getProcessPrefix(dataSources, selectedDataSource)}-${
      e.target.value
    }`;

    getProcess(
      id,
      selectedDataSource,
      (process) => {
        const { cdxml } = process || {};

        if (_.isEmpty(cdxml) || cdxml === "W29iamVjdCBQcm9taXNlXQ==") {
          childRef.current?.writeToCanvas("");
          NotificationManager.info("This process is empty.");
        } else {
          childRef.current?.loadCDXML(cdxml);
          forceUpdate();
          canvasChanged();
        }
      },
      setLoading
    );
  };

  const onAddProcess = () => {};

  const onSelectProcess = (pId) => {
    let id = "";

    try {
      id = (pId || "").split("-")[1];
    } catch (error) {}

    setProcessId(id);

    forceUpdate();

    setProcessModal(false);
  };

  const onSearchByProcess = async (components) => {
    // setShowStructureSearchForm(false);

    const queryComponents = _.map(components, (component) => {
      const type = component?.type || "BINGO_EXACT";

      let componentBody = {
        role: _.upperCase(component?.role),
        searchType: type,
      };

      if (type === "BINGO_EXACT" && component?.compoundId) {
        componentBody = {
          ...componentBody,
          processId: component?.compoundId,
        };
      } else {
        componentBody = {
          ...componentBody,
          structure: component?.smiles,
          cutoff: component?.cutoff || 0,
        };
      }

      return componentBody;
    });

    let body = {
      processQuery: {
        sourceName: sourceName || selectedDataSource,
        queryComponents,
        joinType: "AND",
      },
    };

    await searchForProcess(body, setStructureResultLoading, (items) => {
      setStructureSearchResults(items);
    });
  };

  return (
    <div>
      <div
        className={`gx-profile-banner sticky-header-component ${
          updateMode ? "sticky-header-secondary" : ""
        }`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "100px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
          }}
        >
          <h3>
            {updateMode ? "Update a process step" : "Register a process step"}
          </h3>

          <div>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <div style={{ marginTop: "0px", display: "flex" }}>
                <DocumentationPopover
                  documentations={documentations}
                  sourceName={TRAINING_DOCUMENT_SOURCE}
                  setOpenDocumentationSettings={setOpenDocumentationSettings}
                  hasPermission={isUserAllowedToManageDocumentation}
                />
              </div>
            ) : null}
          </div>
          {isCurator && (
            <div className="gx-d-flex gx-ml-5">
              <Form.Item
                label="Update mode"
                className="registerProcess__label"
                style={{
                  marginTop: "-5px",
                }}
              >
                <Checkbox
                  value={updateMode}
                  onChange={(e) => {
                    clearStates();
                    setUpdateMode(e.target.checked);
                  }}
                />
              </Form.Item>
            </div>
          )}
          {helmEditorSettings && isCurator ? (
            <div
              className="gx-d-flex gx-ml-5"
              style={{
                marginTop: "-4px",
              }}
            >
              <Form.Item
                label="Use HELM Editor"
                className="registerProcess__label"
              >
                <Switch
                  size="small"
                  className="registerNewCompound__checkbox"
                  checked={showHelm}
                  onChange={(e) => {
                    setShowHelm(e);
                  }}
                />
              </Form.Item>
            </div>
          ) : null}

          {updateMode && (
            <div
              className="gx-ml-5"
              style={{
                marginTop: "-4px",
              }}
            >
              <Form ref={processIdFormRef} form={form}>
                <Form.Item
                  label="Process id"
                  name="processId"
                  required
                  rules={[{ required: true, message: "Please enter id" }]}
                >
                  <div className="gx-d-flex">
                    <Input
                      type="number"
                      prefix={`${getProcessPrefix(
                        dataSources,
                        selectedDataSource
                      )} ${IDENTIFIER_SEPERATOR}`}
                      size="small"
                      placeholder="Enter id"
                      onPressEnter={updateCanvas}
                      value={processId}
                      onChange={(e) => setProcessId(e.target.value)}
                    />
                    <SearchOutlined
                      style={{
                        color: "#fff",
                        fontSize: "17px",
                        marginLeft: "10px",
                        marginTop: "4px",
                        cursor: "pointer",
                      }}
                      onClick={() => setProcessModal(true)}
                    />
                  </div>
                </Form.Item>
              </Form>
            </div>
          )}
        </div>

        <div className="gx-mr-5">
          <div className="gx-d-flex">
            {selectDataSource}
            {(processRegistrationTypes || []).length ? (
              <div className="gx-ml-5">
                <ProcessRegistrationForm
                  setSelectedProcessRegistrationType={
                    setSelectedProcessRegistrationType
                  }
                  processRegistrationTypes={processRegistrationTypes}
                  setCurrentInputType={setCurrentInputType}
                  next={next}
                  selectedProcessRegistrationType={
                    selectedProcessRegistrationType
                  }
                  setRegisteredId={setRegisteredId}
                />
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <div
        className="sticky-header-content registerProcess-MainComponent"
        style={{
          marginTop: "100px",
        }}
      >
        {showHelm ? (
          <HelmEditor />
        ) : (
          <>
            {processModal ? null : (
              <GenericEditor
                currentEditor={currentEditor}
                ref={childRef}
                setDrawing={(data) => setResult(data)}
                setCdxml={(value) => {
                  setCdxml(value);
                }}
                canvasChanged={() => canvasChanged()}
              />
            )}
          </>
        )}
        {!showHelm ? registerProcessContent : null}
      </div>

      {showHelm ? (
        <Button size="small" type="primary" onClick={fetchDataFromHelm}>
          Add to Component
        </Button>
      ) : null}

      {showHelm ? (
        <>
          {structureSearchLoaded ? (
            <>
              {!_.isEmpty(structureSearchResults) ? (
                <div className="gx-mt-4 gx-mr-5">
                  <span>Search Results:</span>
                  {renderProcessTableResults({
                    availableDataSources: availableDataSources,
                    dataSource: structureSearchResults,
                    mapImages,
                    sourceGroups: {},
                    setMapImages,
                    forceUpdate,
                    loading: structureResultLoading,
                  })}
                </div>
              ) : (
                <p className="gx-mt-4 gx-text-secondary">
                  No similar process steps exist.
                </p>
              )}
            </>
          ) : null}
        </>
      ) : null}

      <PanelPortal
        showPanel={showHelm ? showPanel : false}
        onDeletePanel={() => {
          setShowPanel(false);
        }}
        title="Process Details"
      >
        <div className="gx-mt-3">
          {loading ? (
            <CircularProgress className="gx-loader-400 loader customLoader" />
          ) : (
            <>{showHelm ? registerProcessContent : null}</>
          )}
        </div>
      </PanelPortal>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>

      <Drawer
        title={
          <div className="gx-d-flex">
            <span>Search Process</span>
            <Radio.Group
              size="small"
              value={viewType}
              onChange={(e) => {
                setViewType(e.target.value);
              }}
              className="gx-mb-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <Radio.Button value="input">Search by Value</Radio.Button>
              <Radio.Button value="structure">Search by Structure</Radio.Button>
            </Radio.Group>
          </div>
        }
        placement="right"
        open={processModal}
        width={900}
        className="settingsDrawer"
        okText="Add Process"
        onOk={onAddProcess}
        onClose={() => {
          setCurrentProcessId(null);
          setProcessModal(false);
        }}
      >
        <ProcessSelection
          sourceName={selectedDataSource || sourceName}
          availableWidgets={availableWidgets}
          onSelectProcess={onSelectProcess}
          viewType={viewType}
          onSearchByProcess={onSearchByProcess}
          structureSearchResults={structureSearchResults}
          structureResultLoading={structureResultLoading}
          availableDataSources={availableDataSources}
        />
      </Drawer>
    </div>
  );
};

export default RegisterProcess;
