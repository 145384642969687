import {
  GET_PROCESS_WIDGETS_REQUESTED,
  GET_SYSTEM_WIDGETS_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Get System Widget Metadata
 * @param {String} widgetName widget name
 */
export const getSystemWidgets = (widgetName) => {
  return {
    type: GET_SYSTEM_WIDGETS_REQUESTED,
    payload: widgetName,
  };
};

/**
 * Get Process Widget Metadata
 * @param {String} widgetName widget name
 */
export const getProcessWidget = (widgetName, processId, sourceName) => {
  return {
    type: GET_PROCESS_WIDGETS_REQUESTED,
    payload: {
      widgetName,
      processId,
      sourceName,
    },
  };
};
