import { Drawer, Modal, Table } from "antd";
import React, { useRef, useState } from "react";
import {
  renderActionsButtons,
  renderData,
  renderTitleWithActionButtons,
} from "./helper";
import { PrinterTwoTone } from "@ant-design/icons";
import { useReactToPrint } from "react-to-print";
import { FileDetailComponent } from "../../DetailPage/DocumentCollection/helper";
import DocumentForm from "./DocumentForm";
import DropzoneWrapper from "../../DetailPage/DocumentCollection/DropzoneWrapper";
import _ from "lodash";
import {
  deleteDocument,
  editDocument,
  getCounter,
  saveCounter,
  uploadDocument,
} from "../../../appRedux/services/Document";
import { NotificationManager } from "react-notifications";
import moment from "moment";
import { getDocumentRoleId } from "../../../util/auth";
import { useMsal } from "@azure/msal-react";

const CollectionManager = ({
  loading,
  setLoading,
  selectedSource,
  selectedCollection,
  dataSource,
  documentCollectionOptions,
  pageType,
  showDrawer,
  setShowDrawer,
  updateDocuments,
  field,
  targetValue,
  dataSources,
}) => {
  const [currentDocument, setCurrentDocument] = useState();
  const [showModal, setShowModal] = useState(false);
  const [files, setFiles] = useState([]);
  const [progress, setProgress] = useState({});

  const { accounts } = useMsal();
  const account = accounts[0];

  const fileDetailRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => fileDetailRef.current,
  });

  const getMinutes = (counterData, collection) => {
    const lastLockedTime = moment(
      _.get(counterData, `${collection}.timestamp`)
    );
    const currentTime = moment().tz("America/Los_Angeles");
    const duration = moment.duration(currentTime.diff(lastLockedTime));
    var minutes = duration.asMinutes();

    return minutes;
  };

  const currentCollection = _.find(documentCollectionOptions, {
    value: selectedCollection,
  });

  const hasPermission = (currentCollection?.userRoles || []).includes(
    getDocumentRoleId()
  );

  const isLockingAllowed = (currentCollection?.allowedRoles || []).includes(
    getDocumentRoleId()
  );

  const cloneCurrentDocument = (currentDocument) => {
    const collection = currentDocument?.collection;

    getCounter((counterData) => {
      const minutes = getMinutes(counterData, collection);

      if (_.get(counterData, `${collection}.locked`) && minutes < 5) {
        NotificationManager.error(
          `This collection is currently being updated. Please try again in ${
            5 - Math.floor(Number(minutes))
          } minutes`
        );
      } else {
        // Lock the counter for current collection.
        saveCounter(
          collection,
          counterData,
          _.get(counterData, `${collection}.counter`) || 0,
          true,
          () => {
            // If user submitting document without file. It should increase counter by 1.
            const targetNum =
              Number(_.get(counterData, `${collection}.counter`) || 0) +
              (!files.length ? 1 : files.length);
            uploadDocument(
              currentDocument?.id,
              currentDocument?.sourceName,
              files,
              {
                ...currentDocument,
                accessionNumber: targetNum,
              },
              () => {
                setShowModal(false);
                NotificationManager.success("Document has been cloned.");
                updateDocuments(currentDocument?.collection);
              },
              (e) => {
                NotificationManager.error(
                  "A file with this name already exists in this collection."
                );
              },
              setLoading,
              setProgress,
              false,
              [],
              true
            );
          }
        );
      }
    });
  };

  const onLock = (document, locked = false, setFn = () => {}) => {
    editDocument(
      document?.id,
      document?.sourceName,
      document?.id,
      {
        entityIds: document?.entityIds,
        collection: document?.collection,
        description: document?.description,
        name: document?.name,
        owners: document?.owners,
        searchSubjects: document?.searchSubjects,
        locked,
        lockedBy: account?.username,
      },
      () => {
        setShowModal(false);
        NotificationManager.success(
          `Document has been ${locked ? "locked" : "unlocked"}.`
        );
        updateDocuments(document?.collection);
        setFn();
      },
      (e) => {
        setShowModal(false);
        NotificationManager.error(e);
      },
      setLoading
    );
  };

  const onSelectDocument = (record) => {
    setCurrentDocument(record);
    setShowModal(true);
  };

  const onEdit = (record) => {
    setCurrentDocument(record);
    setShowDrawer(true);
  };

  const onDelete = (record) => {
    const { id, sourceName } = record;

    setLoading(true);
    deleteDocument(
      id,
      sourceName,
      () => {
        setShowModal(false);
        NotificationManager.success("Document has been deleted.");
        updateDocuments(record?.collection);
        setLoading(false);
      },
      (e) => {
        setShowModal(false);
        NotificationManager.error(e);
        setLoading(false);
      }
    );
  };

  const columns = [
    {
      title: "File name",
      dataIndex: "fileName",
      // eslint-disable-next-line no-undef
      render: (_p, record) =>
        renderTitleWithActionButtons(record, onSelectDocument),
    },
    {
      title: "Source",
      dataIndex: "sourceName",
    },
    {
      title: "Data",
      render: (_p, record) =>
        renderData(record, targetValue, field, dataSources),
    },
    {
      title: "Actions",
      render: (_p, record) => {
        const showActionButton = !record?.locked && hasPermission;

        return renderActionsButtons(
          record,
          onEdit,
          onDelete,
          cloneCurrentDocument,
          showActionButton,
          isLockingAllowed,
          hasPermission,
          record?.locked,
          onLock
        );
      },
    },
  ];

  return (
    <div>
      <DropzoneWrapper
        onDrop={() => {}}
        parentStyle={{
          display: "block",
          padding: "10px",
          borderColor: "#eeeeee",
          borderStyle: "dashed",
        }}
      >
        <Table
          columns={columns}
          loading={_.isEmpty(dataSource) && loading}
          dataSource={dataSource}
          pagination={{
            size: "small",
          }}
        />
      </DropzoneWrapper>

      <Modal
        open={showModal}
        title={
          <div>
            <span className="gx-mr-3">File Detail</span>
            <PrinterTwoTone onClick={handlePrint} />
          </div>
        }
        width={820}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <FileDetailComponent
          ref={fileDetailRef}
          currentDocument={currentDocument}
          selectedDocument={currentDocument}
          sourceName={selectedSource}
          collectionOptions={documentCollectionOptions}
          fileDetailRef={fileDetailRef}
        />
      </Modal>

      <Drawer
        placement="right"
        open={showDrawer}
        title="Upload File"
        size="large"
        // onOk={onSaveFile}
        onClose={() => {
          // setEdit(false);
          setShowDrawer(false);
          // resetForm();
        }}
      >
        <DocumentForm
          loading={loading}
          currentDocument={currentDocument}
          sourceName={selectedSource}
          pageType={pageType}
          collectionOptions={documentCollectionOptions}
          setShowDrawer={setShowDrawer}
          updateDocuments={updateDocuments}
          files={files}
          setFiles={setFiles}
          progress={progress}
          setProgress={setProgress}
          selectedCollection={targetValue || selectedCollection}
          isEditMode
        />
      </Drawer>
    </div>
  );
};

export default CollectionManager;
