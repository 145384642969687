import axios from "axios";
import { config } from "../../constants/Config";

/**
 * Send POST request to projects API
 * @param {String} sourceName source name
 * @param {String} processId process id
 * @param {Object} projects projects
 * @param {Function} setLoading call function to set loading
 * @param {Function} onFinish callback function after finished
 * @returns
 */
export const saveProject = async (
  sourceName,
  processId,
  selectedProject,
  projects,
  setLoading,
  onFinish
) => {
  const newProjects = projects.map((project) => {
    if (project?.name === selectedProject) {
      let processSteps = project?.processSteps || [];
      processSteps.push({
        processId: processId,
      });
      return {
        ...project,
        processSteps,
      };
    } else {
      return project;
    }
  });

  const body = JSON.stringify(newProjects);

  setLoading(true);
  return axios
    .post(`${config.SRD_API_URL}/project?sourceName=${sourceName}`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      onFinish(res.data);
    })
    .finally(() => {
      setLoading(false);
    });
};

/**
 * create project request API
 * @param {String} sourceName source Name
 * @param {String} projectId project id
 */
export const createProject = async (
  sourceName,
  projectId,
  description,
  setFn,
  userName = null
) => {
  const body = {
    name: projectId,
    description,
  };

  return axios
    .post(
      `${config.SRD_API_URL}/project?sourceName=${sourceName}&SRD_USER=${userName}`,
      JSON.stringify(body),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      setFn(res);
    });
};

/**
 * Send PUT request to projects API
 * @param {String} sourceName source name
 * @param {String} processId process id
 * @param {String} projectId project id
 * @param {Array<Object>} currentProcessSteps current process steps
 * @param {Function} setLoading call function to set loading
 * @param {Function} onFinish callback function after finished
 * @returns
 */
export const putProject = async (
  sourceName,
  processId,
  currentProcessSteps = [],
  setLoading,
  onFinish,
  onError,
  project = {},
  userName
) => {
  const steps = [
    ...currentProcessSteps,
    {
      processId,
      stepNo: 0,
      experiments: [],
    },
  ];

  const newSteps = steps.map((item, index) => ({ ...item, stepNo: index }));

  const data = {
    name: project?.name,
    description: project?.description,
    processSteps: newSteps,
  };

  setLoading(true);
  return axios
    .put(
      `${config.SRD_API_URL}/project/${project?.projectId}?source%20name=${sourceName}&SRD_USER=${userName}`,
      JSON.stringify(data),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      onFinish(res.data);
    })
    .catch(() => {
      onError();
    })
    .finally(() => {
      setLoading(false);
    });
};

/**
 * Get all projects request API
 * @param {String} sourceName source Name
 */
export const getProjects = async (sourceName, setFn) => {
  return axios
    .get(`${config.SRD_API_URL}/projects?source%20name=${sourceName}`)
    .then((res) => {
      setFn(res.data);
    });
};

export const getProjectDetail = async (
  projectId,
  sourceName,
  setFn,
  setErrFn = () => {}
) => {
  return axios
    .get(
      `${config.SRD_API_URL}/project/${projectId}?source%20name=${sourceName}`
    )
    .then((res) => {
      setFn(res.data);
    })
    .catch(() => {
      setErrFn();
    });
};
