import { Button, Form, Input, Popover, Spin, Table, Tag } from "antd";
import React, { useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchParameters } from "../../../../../appRedux/actions";
import _ from "lodash";
import pluralize from "pluralize";
import { renderImage } from "../../../../SearchPage/helper";
import ImageCarousel from "../../../../Registration/ImageCarousel";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { getCompoundTypeFromId } from "../../../../../util/url";
import moment from "moment-timezone";
import { findMappedDatabaseFieldName } from "../../../../ExplorePage/helper";
import { v4 as uuidv4 } from "uuid";

const SearchByInput = ({
  sourceName,
  mapImages,
  setMapImages,
  onSelectProcess,
  availableWidgets = [],
  page,
  perPage,
  setPage,
  setPerPage,
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState();

  const {
    loading,
    tableData,
    widgetIdsNormalized = [],
  } = useSelector(({ explore }) => explore);

  const dataSource = tableData;
  const total = (widgetIdsNormalized || []).length;
  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  return (
    <div>
      <Form
        onFinish={({ searchInput }) => {
          setSearchTerm(searchInput);

          const searchDomain = ["COMPOUND"];

          dispatch(
            searchParameters({
              searchTerm: searchInput,
              dataSources: [sourceName],
              type: searchDomain,
              includeDocument: false,
              searchOps: "CONTAINS",
            })
          );
        }}
      >
        <Form.Item
          name="searchInput"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            placeholder="Enter search metadata."
            style={{ maxWidth: "500px" }}
          />
        </Form.Item>

        <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
          <Button size="small" type="primary" htmlType="submit">
            Search
          </Button>
        </Form.Item>
      </Form>

      <div>
        <div className="gx-mb-2" style={{ textAlign: "right" }}>
          <span className="gx-text-success">
            Total: {total} {pluralize("result", dataSource.length)}.
          </span>
          {!_.isEmpty(filterValue) ? (
            <span className="gx-ml-2 gx-text-secondary">
              Filtered by "{filterValue}": {filteredResult.length}{" "}
              {pluralize("result", filteredResult.length)}
            </span>
          ) : null}
        </div>
        <Table
          key={1}
          className="gx-table-responsive"
          loading={loading}
          dataSource={dataSource}
          onChange={(page, filters, sorter, { currentDataSource }) => {
            setFilterValue((_.get(filters, "3") || [])[0]);
            setFilteredResult(currentDataSource);
            renderImage({
              currentPage: page.current,
              pageSize: page.pageSize,
              searchResults: currentDataSource,
              mapImages,
              setMapImages,
              forceUpdate,
            });
          }}
          columns={[
            {
              title: "Structure",
              dataIndex: "structure",
              key: "structure",
              width: 150,
              render: (_, { compoundId, processId }) => {
                const targetId = compoundId || processId;

                return (
                  <>
                    {mapImages[targetId] === "not-found" ? (
                      <span
                        className="gx-text-danger"
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        Structure not found
                      </span>
                    ) : mapImages[targetId] ? (
                      <div className="search-page-image-preview">
                        <ImageCarousel
                          structureImage={[mapImages[targetId]]}
                          noBorder
                          setVisible={() => {}}
                        />
                      </div>
                    ) : (
                      <Spin />
                    )}
                  </>
                );
              },
            },
            {
              title: "Id",
              width: 150,
              render: (p, { sourceName, compoundId, processId }) => {
                const targetId = compoundId || processId;

                const isHighlighted = (targetId || "").includes(searchTerm);

                return (
                  <>
                    <Link
                      to={`/${sourceName}/${getCompoundTypeFromId(
                        targetId,
                        "COMPOUND"
                      )}/${targetId}`}
                      replace
                      target="_blank"
                    >
                      <span
                        style={{
                          color: isHighlighted ? "#389e0d" : "#1677ff",
                        }}
                      >
                        {targetId}
                      </span>
                    </Link>
                  </>
                );
              },
            },
            {
              title: "Source",
              dataIndex: "sourceName",
              width: 100,
              // sorter: (a, b) =>
              //   (a?.sourceName || "").localeCompare(b?.sourceName || ""),
            },
            {
              title: "Action",
              render: (p, cell) => {
                return (
                  <Button
                    size="small"
                    type="primary"
                    onClick={() => {
                      onSelectProcess(cell?.compoundId);
                    }}
                  >
                    Select
                  </Button>
                );
              },
            },
            {
              title: "Data",
              render: (p, cell) => {
                const { parameters = {}, widgetType } = cell;

                // if (_.isEmpty(_.omit(parameters, ["options"]))) {
                //   return (
                //     <span className="gx-text-warning">
                //       You don't have access to this widget.
                //     </span>
                //   );
                // }

                return (
                  <div>
                    {_.map(parameters, (item, key) => {
                      const targetParameter = _.find({}, { value: key }) || {};
                      let targetValue = targetParameter?.field || key;

                      try {
                        if (targetValue.includes("|")) {
                          targetValue = targetValue.split("|")[0];
                        }
                      } catch (error) {}

                      let targetItem = item;

                      if (typeof item === "object") {
                        targetItem = item?.id || "";
                      }

                      const regex = new RegExp(
                        `^\\d{4}-\\}d{2-\\d{2}T\\d{2}:\\d{2}:\\d{2}\\.\\d{3}Z$`
                      );

                      if (regex.test(targetItem)) {
                        targetItem = moment(targetItem).format(
                          "YYYY-MM-DD HH:mm:ss"
                        );
                      }

                      let isHighlighted = false;

                      try {
                        isHighlighted = _.includes(
                          (targetItem || "").toLowerCase(),
                          (searchTerm || "").toLowerCase()
                        );
                      } catch (error) {}

                      const label = findMappedDatabaseFieldName(
                        targetValue,
                        widgetType,
                        availableWidgets
                      );

                      return (
                        <>
                          {item ? (
                            <Popover
                              content={<span>{label || targetValue}</span>}
                            >
                              <Tag
                                key={uuidv4()}
                                style={{ whiteSpace: "break-spaces" }}
                                color={isHighlighted ? "#d9f7be" : "#f0f0f0"}
                              >
                                <span className="gx-text-black">
                                  {targetItem}
                                </span>
                              </Tag>
                            </Popover>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                );
              },
            },
          ]}
          pagination={{
            total,
            pageSize: perPage,
            current: page,
            defaultCurrent: 1,
            pageSizeOptions: [5, 10, 15, 20],
            responsive: true,
            showSizeChanger: true,
            onChange: (newPage, newPageSize) => {
              if (newPage !== page) {
                setPage(newPage);
              }

              if (perPage !== newPageSize) {
                setPerPage(newPageSize);
              }
            },
          }}
        />
      </div>
    </div>
  );
};

export default SearchByInput;
