import React, { memo } from "react";
import { useHWE } from "react-hwe-editor";

import "./style.css";

const HelmEditor = memo(function Editor() {
  const { Editor, editorProps } = useHWE("helm");

  return (
    <div id="helm-container">
      <Editor {...editorProps} />
    </div>
  );
});

export default HelmEditor;
