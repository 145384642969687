import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import Settings from "./Settings";
import Auth from "./Auth";
import Common from "./Common";
import StructureRegistration from "./StructureRegistration";
import Metadata from "./Metadata";
import SystemMetadata from "./SystemMetadata";
import Search from "./Search";
import Generic from "./Generic";
import Project from "./Project";
import CurrentUserData from "./CurrentUserData";
import Document from "./Document";
import Lookup from "./Lookup";
import Graph from "./Graph";
import Explore from "./Explore";

const createRootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: Settings,
    auth: Auth,
    common: Common,
    structureRegistration: StructureRegistration,
    metadata: Metadata,
    systemMetadata: SystemMetadata,
    search: Search,
    generic: Generic,
    project: Project,
    currentUserData: CurrentUserData,
    document: Document,
    lookup: Lookup,
    graph: Graph,
    explore: Explore,
  });

export default createRootReducer;
