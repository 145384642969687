import axios from "axios";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import {
  config,
  EXPERIMENT_DATA_WIDGET,
  PAGE_TYPES,
  SAFETY_SENTENCES_SAVED_DATA,
  TABLE_WIDGET_METADATA,
} from "../../constants/Config";

export const addCompoundSample = async (
  id,
  sourceName,
  setFn,
  notification,
  setLoadingFn = () => {},
  userName,
) => {
  setLoadingFn(true);
  return axios
    .post(
      `${config.SRD_API_URL}/sample?sourceName=${sourceName}&batchId=${id}&userName=${userName}`,
      {
        sampleId: "",
        samples: [],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
    .then((result) => {
      setFn(result.data);
    })
    .catch((e) => {
      if (notification) {
        notification.error(e?.response?.data?.message);
      }
    })
    .finally(() => {
      setLoadingFn(false);
    });
};

export const addCompoundInstance = async (
  id,
  sourceName,
  setFn,
  notification,
  setLoadingFn = () => {},
) => {
  setLoadingFn(true);
  return axios
    .post(
      `${config.SRD_API_URL}/compoundInstance?sourceName=${sourceName}&compoundId=${id}`,
      {
        instanceComponents: [],
        conceptComponents: [],
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
    .then((result) => {
      setFn(result.data);
    })
    .catch((e) => {
      if (notification) {
        notification.error(e?.response?.data?.message);
      }
    })
    .finally(() => {
      setLoadingFn(false);
    });
};

export const addExperiment = async (
  processId,
  sourceName,
  setFn,
  notification,
  setLoadingFn = () => {},
) => {
  setLoadingFn(true);
  return axios
    .post(
      `${config.SRD_API_URL}/experiment?sourceName=${sourceName}&processId=${processId}`,
      [],
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
    .then((result) => {
      setFn(result.data);
    })
    .catch((e) => {
      if (notification) {
        notification.error(e?.response?.data?.message);
      }
    })
    .finally(() => {
      setLoadingFn(false);
    });
};

/**
 * Get Smiles string from SCAS API
 * @param {*} id id of the structure
 * @param {*} setFn callback function
 * @param {*} sourceName source name
 * @returns
 */
export const getSmiles = async (
  id,
  setFn,
  sourceName,
  setErr = () => {},
  format = "smiles",
) => {
  return axios
    .get(
      `${config.SCAS_API_URL}/ontology/structure/${id}?sourceName=${sourceName}&originalMaterial=true&format=${format}`,
    )
    .then((result) => {
      setFn(result.data);
    })
    .catch(() => {
      setErr();
    });
};

export const getSources = async (setFn, includeOptions = false) => {
  return axios
    .get(`${config.SRD_API_URL}/sources`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      if (result) {
        if (includeOptions) {
          setFn(result?.data);
        } else {
          const options = result?.data.map((item) => ({
            field: item?.sourceName,
            value: item?.sourceName,
          }));
          setFn(options);
        }
      }
    });
};

export const saveMetadataRequestApi = async (
  compoundId,
  metadata,
  metadataName,
  sourceName,
  metadataType,
) => {
  let baseUrl = metadataType
    ? `${config.SRD_API_URL}/metadata/${metadataType}/${metadataName}?projectId=${compoundId}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}`;
  return axios.post(`${baseUrl}&sourceName=${sourceName}`, metadata, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

export const widgetMetadataRequestApi = async (
  compoundId,
  sourceName,
  metadataName,
  metadataType = null,
  currentVersion = null,
) => {
  let url = metadataType
    ? `${config.SRD_API_URL}/metadata/${metadataType}/${metadataName}?projectId=${compoundId}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}`;

  if (currentVersion) {
    url += `&version=${currentVersion}`;
  }

  return axios.get(`${url}&sourceName=${sourceName}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });
};

/**
 * Search for structure
 */
export const searchForStructure = ({ op, body, setFn, setLoading }) => {
  setLoading(true);
  axios
    .post(
      `${config.SCAS_API_URL}/ontology/structure/${op}?limit=-1&timeout=-1&skipSource=false&name=false&async=false`,
      body,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      },
    )
    .then((res) => {
      if (res.data) {
        setFn(res.data);
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

export const searchData = ({ body, setFn, setLoading }) => {
  setLoading(true);
  axios
    .post(`${config.SRD_API_URL}/search/compoundWidgets`, body, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((res) => {
      if (res.data) {
        setFn(res.data?.results || []);
      }
    })
    .finally(() => {
      setLoading(false);
    });
};

export const searchUniquenessField = async (body) => {
  const res = await axios.post(
    `${config.SRD_API_URL}/search/compoundWidgets`,
    body,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    },
  );

  return res.data?.results || [];
};

export const cloneExperimentMetadata = (
  sourceName,
  id,
  targetId,
  widgets,
  username,
) => {
  let hasSafetyData = [];

  const metadataNames = _.map(widgets, (item) => {
    if (item === "TableData") return TABLE_WIDGET_METADATA;
    if (item === "SafetySentences") {
      hasSafetyData = [SAFETY_SENTENCES_SAVED_DATA];
    }
    return item;
  });

  let promises = [];
  _.forEach([...metadataNames, [...hasSafetyData]], (widget) => {
    if (widget === "CompositionTable") {
      promises.push(
        axios.get(
          `${config.SRD_API_URL}/experiment?experimentId=${id}&source%20name=${sourceName}`,
        ),
      );
    } else {
      promises.push(
        axios.get(
          `${config.SRD_API_URL}/metadata/${widget}?compoundId=${id}&sourceName=${sourceName}`,
        ),
      );
    }
  });

  let targetPromises = [];

  Promise.allSettled(promises).then((results) => {
    const fulfilledResults = _.filter(
      results,
      (result) => result?.status === "fulfilled",
    );

    const targetData = _.map(fulfilledResults, (result) => {
      if (!_.get(result, "value.data[0].widgetType")) {
        return {
          id: targetId,
          widget: "CompositionTable",
          body: _.get(result, "value.data.components"),
        };
      } else {
        return {
          id: targetId,
          widget: _.get(result, "value.data[0].widgetType"),
          body: _.get(result, "value.data[0].parameters"),
        };
      }
    });

    _.forEach(targetData, (data) => {
      let body = data?.body;

      // Modify metadata for "ExperimentData" widget.
      if (data?.widget === EXPERIMENT_DATA_WIDGET) {
        body = {
          ...body,
          registrar: username,
          registrationType: "interactive",
          startDate: new Date(),
        };
      }

      if (data?.widget === "CompositionTable") {
        targetPromises.push(
          axios.put(
            `${config.SRD_API_URL}/experiment?experimentId=${data?.id}&sourceName=${sourceName}&SRD_USER=${username}`,
            JSON.stringify(body),
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          ),
        );
      } else {
        targetPromises.push(
          axios.post(
            `${config.SRD_API_URL}/metadata/${data?.widget}?compoundId=${data?.id}&sourceName=${sourceName}`,
            JSON.stringify(body),
            {
              headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            },
          ),
        );
      }
    });

    Promise.allSettled(targetPromises).then((targetResults) => {
      NotificationManager.success("Successfully cloned experiment widgets.");
    });
  });
};

export const getVersionNumbers = async (sourceName, id, pageType, setFn) => {
  if (pageType === PAGE_TYPES.COMPOUND) {
    return axios
      .get(
        `${config.SRD_API_URL}/compoundConceptVersionNumbers?source%20name=${sourceName}&id=${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )
      .then((result) => {
        setFn(result.data);
      });
  }

  if (pageType === PAGE_TYPES.QUALITY) {
    return axios
      .get(
        `${config.SRD_API_URL}/compoundInstanceVersionNumbers?source%20name=${sourceName}&id=${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )
      .then((result) => {
        setFn(result.data);
      });
  }

  if (pageType === PAGE_TYPES.EXPERIMENT) {
    return axios
      .get(
        `${config.SRD_API_URL}/metadata/experimentVersionNumbers/${id}?sourceName=${sourceName}&experimentId=${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )
      .then((result) => {
        setFn(result.data);
      });
  }

  if (pageType === PAGE_TYPES.PROCESS) {
    return axios
      .get(
        `${config.SRD_API_URL}/processVersionNumbers/${id}?sourceName=${sourceName}&processId=${id}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        },
      )
      .then((result) => {
        setFn(result.data);
      });
  }
};

export const getCompoundConceptShallow = async (sourceName, id) => {
  return axios.get(
    `${config.SRD_API_URL}/compoundConceptShallow?source%20name=${sourceName}&id=${id}`,
  );
};
