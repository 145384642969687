import _ from "lodash";
import React from "react";
import ResultCell from "./ResultCell";
import { Pagination, Spin } from "antd";

const AllTab = ({
  loading,
  tableData,
  page,
  perPage,
  setPage,
  setPerPage,
  total,
  onSelect,
  searchTerm,
  availableWidgets,
  mapImages,
  sources,
}) => {
  if (_.isEmpty(tableData) && !loading) {
    return (
      <span
        className="gx-text-primary"
        style={{
          width: "100%",
          textAlign: "center",
          verticalAlign: "middle",
          display: "block",
          paddingTop: "10px",
          marginTop: "10px",
        }}
      >
        No data.
      </span>
    );
  }

  return (
    <div>
      {!loading ? (
        <>
          {_.map(tableData, (item) => (
            <ResultCell
              title={item?.name || item?.compoundId || item?.processId}
              sourceName={item?.sourceName}
              type={item?.type}
              cellItem={item}
              onSelect={onSelect}
              searchTerm={searchTerm}
              availableWidgets={availableWidgets}
              systemWidgetName={item?.widgetType}
              mapImages={mapImages}
              sources={sources}
            />
          ))}
        </>
      ) : (
        <div
          style={{
            width: "100%",
            display: "flex",
            margin: "0 auto",
            justifyContent: "center",
            marginTop: "40px",
          }}
        >
          <Spin />
        </div>
      )}

      {!loading ? (
        <Pagination
          className="gx-mt-3"
          size="medium"
          pageSize={perPage}
          current={page}
          pageSizeOptions={[5, 10, 15, 20]}
          total={total}
          onChange={(newPage, newPageSize) => {
            if (page !== newPage) {
              setPage(newPage);
            }

            if (perPage !== newPageSize) {
              setPerPage(newPageSize);
            }
          }}
        />
      ) : null}
    </div>
  );
};

export default AllTab;
