import { Checkbox, Form, Input, Select } from "antd";
import _ from "lodash";
import React, { useContext, useEffect, useRef, useState } from "react";
import { componentRoleOptions } from "../../../../../constants/Config";
import { metadataDataIndexes } from "../helper";

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  handleSaveChecked,
  limitingReagent,
  experimentMetadata,
  type = "number",
  currentRound,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [isPristine, setIsPristine] = useState(true);

  const inputRef = useRef(null);
  const form = useContext(EditableContext);

  // These indexes should not be edited if it's process row.
  const coreDataIndexes = ["id", "role", "action"];

  useEffect(() => {
    if (editing) {
      setIsPristine(false);
      inputRef?.current?.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();

      // If mol field is set, empty weight.
      if (_.get(values, "mol")) {
        _.set(values, "weight", null);
      }
      // vice versa.
      if (_.get(values, "weight")) {
        _.set(values, "mol", null);
      }

      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  const saveChecked = async (id, checked) => {
    try {
      toggleEdit();

      handleSaveChecked({
        id,
        checked,
      });
    } catch (error) {}
  };

  let childNode = children;

  /**
   * Handle core data indexes.
   */
  if (editable && !(record?.isLocked && coreDataIndexes.includes(dataIndex))) {
    childNode = editing ? (
      <>
        {dataIndex === "limitingReagent" ? (
          <Form.Item name={dataIndex} className="center">
            <Checkbox
              ref={inputRef}
              checked={record?.id === limitingReagent}
              onChange={(e) => saveChecked(record?.id, e.target.checked)}
            />
          </Form.Item>
        ) : (
          <Form.Item
            style={{
              margin: 0,
            }}
            name={dataIndex}
          >
            <Input
              type={type}
              ref={inputRef}
              style={{
                width: "70px",
                marginLeft: "20px",
              }}
              onPressEnter={(e) => {
                e.preventDefault();
                e.stopPropagation();
                save();
              }}
              onBlur={(e) => {
                e.preventDefault();
                e.stopPropagation();
                save();
              }}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            />
          </Form.Item>
        )}
      </>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        <>
          {metadataDataIndexes.includes(dataIndex) ? (
            <>
              {dataIndex === "limitingReagent" ? (
                <div className="center">
                  <Checkbox
                    className="gx-ml-2"
                    checked={limitingReagent === record?.id}
                  />
                </div>
              ) : (
                <>{childNode}</>
              )}
            </>
          ) : (
            children
          )}
        </>
      </div>
    );
  }

  if (editable && type === "select") {
    childNode = editing ? (
      <>
        {type === "select" &&
        dataIndex === "role" &&
        record?.type === "main_component" ? (
          <span>{record?.role}</span>
        ) : (
          <Form.Item
            style={{
              marginLeft: "15px",
              marginTop: "10px",
            }}
            name={dataIndex}
          >
            <Select
              options={componentRoleOptions}
              dropdownMatchSelectWidth={false}
              size="small"
              type={type}
              ref={inputRef}
              onChange={(e) => {
                save();
              }}
              style={{
                display: "flex",
                justifyContent: "center",
                textAlign: "center",
                width: "120px",
              }}
            />
          </Form.Item>
        )}
      </>
    ) : (
      childNode
    );
  }

  return (
    <td {...restProps}>
      <span
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
        }}
      >
        {childNode}
      </span>
    </td>
  );
};

export { EditableCell, EditableRow };
