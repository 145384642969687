import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUserRoles } from "../../appRedux/actions";

/**
 * Hook for get user roles.
 * @returns {Array<Object>} roles
 */
function useGetRoles() {
  const { roles, isRoleInitialized } = useSelector(({ generic }) => generic);
  const dispatch = useDispatch();

  /**
   * Get User Roles if its not initialized.
   */
  useEffect(() => {
    if (!isRoleInitialized) {
      dispatch(getUserRoles());
    }
  }, [dispatch, isRoleInitialized]);

  return roles;
}

export default useGetRoles;
