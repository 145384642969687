import { LOGIN_REQUEST } from "../constants/Config";

export function RequestAccessToken({ accounts, instance, setAccessToken }) {
  const request = {
    ...LOGIN_REQUEST,
    account: accounts[0],
  };

  // Silently acquires an access token which is then attached to a request for Microsoft Graph data
  instance
    .acquireTokenSilent(request)
    .then((response) => {
      setAccessToken(response.accessToken);
    })
    .catch((e) => {
      instance.acquireTokenPopup(request).then((response) => {
        setAccessToken(response.accessToken);
      });
    });
}
