import _ from "lodash";
import PT from "prop-types";
import React from "react";
import {
  checkRowIsAccessible,
  checkRowIsEditable,
} from "../../../../util/auth";
import RowEditable from "../RowEditable";

const RowGroup = ({
  groupId,
  title,
  rows = [],
  form,
  addMode,
  systemWidgetName,
  hideInfo,
  sourceName,
}) => {
  const filteredRows = _.filter(rows, (row) => row?.groupId === groupId);

  const editModeStyle = addMode ? { flexWrap: "wrap" } : {};

  return (
    <div
      className="gx-d-flex"
      style={{
        ...editModeStyle,
        borderTop: "1px solid #e5e5e5",
      }}
    >
      {_.map(filteredRows, (row, index) => (
        <>
          {row?.type === "EDITABLE" && (
            <>
              <RowEditable
                field={row?.field}
                index={index}
                name={row?.name}
                value={_.get(form, row?.name)}
                limit={row?.limit}
                form={form}
                addMode={addMode}
                onChange={(val) => {
                  form[row?.name] = val;
                }}
                onChangeUniqueness={(val) => {
                  form[`${row?.name}__unique`] = val;
                }}
                info={row?.info}
                isEditable={checkRowIsEditable(row?.isEditable)}
                isAccessible={checkRowIsAccessible(row?.isAccessible)}
                systemWidgetName={systemWidgetName}
                isTrimmed={row?.isTrimmed || false}
                hideInfo={hideInfo}
                isCopiable={row?.isCopiable}
                fromGroup
                isUnique={row?.isUnique}
                sourceName={sourceName}
              />
              {/* <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  color: "#aaa",
                }}
              >
                |
              </span> */}
            </>
          )}
        </>
      ))}
    </div>
  );
};

RowGroup.defaultProps = {
  title: "",
};

RowGroup.propTypes = {
  title: PT.string,
};

export default RowGroup;
