import React from "react";
import ReactDOM from "react-dom";

import NextApp from "./NextApp";
import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import "rc-color-picker/assets/index.css";
import { MsalProvider } from "@azure/msal-react";
import { msalInstance } from "./util/auth";

if (process.env.NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
}

ReactDOM.render(
  // <React.StrictMode>
  <MsalProvider instance={msalInstance}>
    <NextApp />
  </MsalProvider>,
  // </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
