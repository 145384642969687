import PT from "prop-types";
import React, { useRef, useState } from "react";

import { Popover, Table } from "antd";
import DocumentColumn from "../DocumentColumn";

import { PlusCircleTwoTone, SearchOutlined } from "@ant-design/icons";
import _ from "lodash";
import pluralize from "pluralize";
import { checkField } from "../../../../util/Widget";
import TableFilter from "../../../TableFilter";
import "./style.css";

const DocumentRow = ({
  collectionName,
  collectionOptions = [],
  files,
  sourceName,
  onSaveFile,
  onDelete,
  onEdit,
  onSelect,
  onConvert,
  onSaveDescription,
  isLockingAllowed,
  onLock,
  hasPermission,
  summaryField,
  pageType,
  currentPage,
  currentPageSize,
  setCurrentPage,
  setCurrentPageSize,
  onOpenFromCollection,
  hasDownloadAccess,
  cloneCurrentDocument,
  onUploadSubsetFile,
}) => {
  const searchInput = useRef(null);
  const dataIndex = "name";
  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState();

  return (
    <>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {!_.isEmpty(filterValue) ? (
            <span className="gx-ml-2 gx-text-secondary">
              Filtered by "{filterValue}": {(filteredResult || []).length}{" "}
              {pluralize("result", (filteredResult || []).length)}
            </span>
          ) : null}
        </div>
        <Table
          pagination={{
            showSizeChanger: true,
            size: "small",
            current: currentPage,
            pageSize: currentPageSize,
          }}
          dataSource={files}
          className="documentCollectionTable"
          onChange={(
            { current, pageSize },
            filters,
            sorter,
            { currentDataSource }
          ) => {
            setFilterValue((_.get(filters, "0") || [])[0]);
            setFilteredResult(currentDataSource);
            setCurrentPage(current);
            setCurrentPageSize(pageSize);
          }}
        >
          <Table.Column
            title={
              <div>
                <span>Documents</span>
                <Popover content="Add document">
                  <PlusCircleTwoTone
                    className="gx-ml-2"
                    twoToneColor="#20c997"
                    onClick={() => onOpenFromCollection(collectionName)}
                  />
                </Popover>
              </div>
            }
            filterDropdown={({
              setSelectedKeys,
              selectedKeys,
              confirm,
              clearFilters,
              close,
            }) => (
              <TableFilter
                searchInput={searchInput}
                dataIndex={dataIndex}
                selectedKeys={selectedKeys}
                setSelectedKeys={setSelectedKeys}
                confirm={confirm}
                clearFilters={clearFilters}
                close={close}
              />
            )}
            filterIcon={(filtered) => (
              <SearchOutlined
                style={{ color: filtered ? "#1890ff" : undefined }}
              />
            )}
            onFilter={(value, record) => {
              let title = "";

              try {
                title = _.find(record["searchSubjects"], function (item) {
                  return (item?.value || "")
                    .toLowerCase()
                    .includes((value || "").toLowerCase());
                });
              } catch (error) {}

              const owners = (record["owners"] || []).includes(value);
              const entityIds = (record["entityIds"] || []).includes(value);

              return (
                entityIds ||
                owners ||
                checkField(value, record[dataIndex]) ||
                checkField(value, title?.value || "") ||
                checkField(value, record["fileName"]) ||
                checkField(value, record["mimeType"]) ||
                checkField(value, record["name"]) ||
                checkField(value, record["description"])
              );
            }}
            onFilterDropdownOpenChange={(visible) => {
              if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
              }
            }}
            render={(text, file) => {
              return (
                <DocumentColumn
                  file={file}
                  sourceName={sourceName}
                  onSaveFile={onSaveFile}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  onSaveDescription={onSaveDescription}
                  onSelect={onSelect}
                  onConvert={onConvert}
                  isLockingAllowed={isLockingAllowed}
                  locked={file?.locked}
                  onLock={onLock}
                  hasPermission={hasPermission}
                  summaryField={summaryField}
                  collectionOptions={collectionOptions}
                  pageType={pageType}
                  hasDownloadAccess={hasDownloadAccess}
                  cloneCurrentDocument={cloneCurrentDocument}
                  collectionName={collectionName}
                  onUploadSubsetFile={onUploadSubsetFile}
                />
              );
            }}
          ></Table.Column>
        </Table>
      </div>
    </>
  );
};

DocumentRow.defaultProps = {
  files: [],
  sourceName: "",
};

DocumentRow.propTypes = {
  files: PT.arrayOf(PT.shape),
  sourceName: PT.string,
  onSaveFile: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  onEdit: PT.func.isRequired,
};

export default DocumentRow;
