import React, { useEffect, useRef, useState } from "react";
import Header from "../Widget/Header";
import _ from "lodash";
import {
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { arrayMove } from "@dnd-kit/sortable";
import {
  CloseCircleTwoTone,
  MenuOutlined,
  PlusCircleOutlined,
  SaveTwoTone,
  SlidersOutlined,
} from "@ant-design/icons";
import {
  Button,
  Divider,
  Drawer,
  Form,
  List,
  Modal,
  Popover,
  Radio,
  Select,
} from "antd";
import ContactCell from "../../Contact/ContactList/ContactCell";
import ContactList from "../../Contact/ContactList";
import { getProcessRequest } from "../../../appRedux/sagas/Project";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import { PROJECT_PAGE_SETTINGS } from "../../../constants/Config";
import {
  fetchStepNoImages,
  getProjects,
  putProject,
} from "../../../appRedux/actions";
import ProcessSelection from "./ProcessSelection";
import { NotificationManager } from "react-notifications";
import { searchForProcess } from "../../../appRedux/services/Search";

const ProjectManagementWidget = ({
  id,
  widget,
  compoundDetail,
  sourceName,
  availableWidgets,
  availableDataSources,
}) => {
  const [open, setOpen] = useState(true);
  const [sortMode, setSortMode] = useState(false);
  const [orderProcesses, setOrderProcesses] = useState([]);
  const [currentProcessId, setCurrentProcessId] = useState();
  const [experiments, setExperiments] = useState([]);
  const [experimentModal, setExperimentModal] = useState(false);
  const { accounts } = useMsal();
  const [projectSettings, setProjectSettings] = useState([]);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [selectedExp, setSelectedExp] = useState();
  const [experimentOptions, setExperimentOptions] = useState([]);
  const { projects = [] } = useSelector(({ project }) => project);
  const [processModal, setProcessModal] = useState(false);
  const [orderProcessModal, setOrderProcessModal] = useState(false);
  const [currentProject, setCurrentProject] = useState({});
  const [viewType, setViewType] = useState("input");
  // eslint-disable-next-line no-unused-vars
  const [showStructureSearchForm, setShowStructureSearchForm] = useState(true);
  const [structureResultLoading, setStructureResultLoading] = useState(false);
  const [structureSearchResults, setStructureSearchResults] = useState([]);

  useEffect(() => {
    setCurrentProject(compoundDetail);
  }, [compoundDetail]);

  useEffect(() => {
    getSystemWidget(PROJECT_PAGE_SETTINGS, setProjectSettings);
  }, []);

  const processSteps = _.get(currentProject, "processSteps");
  const experimentFormRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    if (processSteps && (processSteps || []).length) {
      _.forEach(processSteps || [], (processStep) => {
        dispatch(fetchStepNoImages(sourceName, processStep?.processId));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [processSteps]);

  useEffect(() => {
    dispatch(getProjects(sourceName));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // exclude already added experiments.
    let exp = [];
    _.forEach(projects, (project) => {
      const { processSteps = [] } = project || {};

      _.forEach(processSteps, (processStep) => {
        const { experiments = [] } = processStep || {};

        _.forEach(experiments, (experiment) => {
          if (experiment?.experimentId) {
            exp.push(experiment?.experimentId);
          }
        });
      });
    });

    const filteredExperiments = _.filter(
      experiments,
      (experiment) => !exp.includes(experiment?.name)
    );

    setExperimentOptions(filteredExperiments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [experiments]);

  /**
   * Action to take when deleting experiment.
   * @param {Object} p process to delete
   * @param {Object} exp experiment to delete
   */
  const onDeleteExperiment = (p, exp) => {
    const newProcessSteps = _.map(currentProject?.processSteps || [], (ps) => {
      if (ps?.processId === p?.processId) {
        const newExperiments = _.filter(
          ps?.experiments || [],
          (psExperiment) => psExperiment?.experimentId !== exp?.experimentId
        );

        return {
          ...ps,
          experiments: newExperiments,
        };
      } else {
        return ps;
      }
    });

    const newProject = {
      ...currentProject,
      processSteps: newProcessSteps,
    };

    dispatch(
      putProject(
        sourceName,
        currentProject?.projectId,
        newProject,
        accounts[0]?.username
      )
    );

    setCurrentProject(newProject);
  };

  /**
   * Action to take when deleting experiment.
   * @param {Object} p process to delete
   */
  const onDeleteProcess = (p) => {
    const newProcessSteps = _.filter(
      currentProject?.processSteps || [],
      (ps) => ps?.processId !== p?.processId
    );

    const newProject = {
      ...currentProject,
      processSteps: newProcessSteps,
    };

    dispatch(
      putProject(
        sourceName,
        currentProject?.projectId,
        newProject,
        accounts[0]?.username
      )
    );

    setCurrentProject(newProject);
  };

  let form = {};

  const onChangeValue = (field, value) => {
    form = {
      ...form,
      [field]: value,
    };
  };

  const onAddExperiment = () => {
    let isExperimentExists = false;

    _.forEach(projects, (project) => {
      _.forEach(project?.processSteps || [], (pStep) => {
        _.forEach(pStep?.experiments || [], (exp) => {
          if (exp?.experimentId === selectedExp) {
            isExperimentExists = true;
          }
        });
      });
    });

    if (isExperimentExists) {
      experimentFormRef.current?.setFields([
        {
          name: "experimentId",
          errors: [`Experiment already added.`],
        },
      ]);
      return;
    } else {
      const currentProcess = processSteps.find(
        (item) => item?.processId === currentProcessId
      );

      const { experiments = [] } = currentProcess || {};

      const newExperiment = [
        ...experiments,
        {
          experimentId: selectedExp,
        },
      ];

      const newProcesses = processSteps.map((item) => {
        if (item?.processId === currentProcessId) {
          return {
            ...item,
            experiments: newExperiment,
          };
        }
        return item;
      });

      const data = {
        name: currentProject?.name,
        stepNo: currentProcess?.stepNo,
        processSteps: newProcesses,
      };

      dispatch(
        putProject(
          sourceName,
          compoundDetail?.projectId,
          data,
          accounts[0]?.username
        )
      );
      setExperimentModal(false);

      setCurrentProject(data);
    }
  };

  const onAddProcess = () => {
    const { processId } = form;
    const { name } = currentProject;

    const steps = [
      ...processSteps,
      {
        processId,
        stepNo: 0,
        experiments: [],
      },
    ];

    const newSteps = steps.map((item, index) => ({
      ...item,
      stepNo: index + 1,
    }));

    const data = {
      name,
      processSteps: newSteps,
    };

    dispatch(
      putProject(
        sourceName,
        currentProject?.projectId,
        data,
        accounts[0]?.username
      )
    );
    setProcessModal(false);
    setCurrentProcessId(null);

    setCurrentProject(data);
  };

  const renderOrderProcessModal = () => {
    return <SortableList helperClass="sortableHelper" onSortEnd={onSortEnd} />;
  };

  const onSelectProcess = (processId) => {
    const { name, processSteps = [] } = currentProject;

    // Check if process is already exists.
    let exists = false;
    for (const processStep of processSteps) {
      if (processStep?.processId === processId) {
        exists = true;
      }
    }

    if (exists) {
      NotificationManager.error(`Process ${processId} is already added.`);
    } else {
      const steps = [
        ...processSteps,
        {
          processId,
          stepNo: 0,
          experiments: [],
        },
      ];

      const newSteps = steps.map((item, index) => ({
        ...item,
        stepNo: index + 1,
      }));

      const data = {
        name,
        processSteps: newSteps,
      };

      dispatch(
        putProject(
          sourceName,
          compoundDetail?.projectId,
          data,
          accounts[0]?.username
        )
      );
      setProcessModal(false);
      setCurrentProcessId(null);

      setCurrentProject(data);
    }
  };

  const renderExperimentModal = () => {
    let allExperiments = [];
    const { processSteps = [] } = currentProject || {};

    processSteps.forEach((step) => {
      const { experiments = [] } = step;
      allExperiments = [...allExperiments, ...experiments];
    });

    return (
      <div className="gx-ml-5 gx-mr-4 gx-mt-3">
        <Form className="gx-p-2" ref={experimentFormRef}>
          <Form.Item name="experimentId" label="Experiment id" required>
            <Select
              placeholder="Selct experiment"
              name="experimentId"
              value={form?.experimentId}
              onChange={(e) => {
                onChangeValue("experimentId", e);
                setSelectedExp(e);
                setButtonDisabled(false);
                experimentFormRef.current?.setFields([
                  {
                    name: "experimentId",
                    errors: [],
                  },
                ]);
              }}
              options={_.map(experimentOptions, (op) => ({
                label: op?.name,
                value: op?.name,
              }))}
            />
            {/* <Input
              placeholder="Enter experiment id"
              name="experimentId"
              value={form?.experimentId}
              onChange={(e) => {
                onChangeValue("experimentId", e.target.value);
                setSelectedExp(e.target.value);
                setButtonDisabled(false);
                experimentFormRef.current?.setFields([
                  {
                    name: "experimentId",
                    errors: [],
                  },
                ]);
              }}
            /> */}
          </Form.Item>

          <Form.Item>
            <Divider />
          </Form.Item>

          <Form.Item name="actions">
            <Button
              type="primary"
              size="small"
              onClick={() => {
                onAddExperiment();
                form = {};
              }}
            >
              Save
            </Button>
            <Button
              type="secondary"
              size="small"
              onClick={() => {
                setExperimentModal(false);
                setCurrentProcessId(null);
                setButtonDisabled(true);
                form = {};
              }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };

  const saveOrder = () => {
    const newSteps = (orderProcesses || []).map((item, index) => ({
      ...item,
      stepNo: index + 1,
    }));

    const data = {
      name: currentProject?.name,
      processSteps: newSteps,
    };

    dispatch(
      putProject(
        sourceName,
        compoundDetail?.projectId,
        data,
        accounts[0]?.username
      )
    );
    setOrderProcessModal(false);
    setCurrentProcessId(null);
    setSortMode(false);
    setCurrentProject(data);
  };

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newSteps = arrayMove(orderProcesses, oldIndex, newIndex);
      setOrderProcesses(newSteps);
    }
  };

  const DragHandle = SortableHandle(() => (
    <span className="gx-pointer gx-mr-2">
      <MenuOutlined className="gx-fs-lg" />
    </span>
  ));

  const SortableItem = SortableElement(({ step, index }) => (
    <List.Item className="gx-mr-5" key={index} style={{ padding: 0 }}>
      <ContactCell key={index} contact={step} sourceName={sourceName} />
      <DragHandle />
    </List.Item>
  ));

  const SortableList = SortableContainer(() => {
    return (
      <>
        {orderProcesses.length > 0 ? (
          <div>
            {orderProcesses.map((step, index) => (
              <List
                bordered
                key={index}
                className="process-sort gx-ml-4 gx-mr-5 gx-mb-2"
              >
                <SortableItem key={`step-${index}`} index={index} step={step} />
              </List>
            ))}
          </div>
        ) : (
          <span>No processes to sort.</span>
        )}
      </>
    );
  });

  /**
   * Search by components
   * @param {*} components
   */
  const onSearchByProcess = async (components) => {
    setShowStructureSearchForm(false);

    const queryComponents = _.map(components, (component) => {
      const type = component?.type || "BINGO_EXACT";

      let componentBody = {
        role: _.upperCase(component?.role),
        searchType: type,
      };

      if (type === "BINGO_EXACT" && component?.compoundId) {
        componentBody = {
          ...componentBody,
          processId: component?.compoundId,
        };
      } else {
        componentBody = {
          ...componentBody,
          structure: component?.smiles,
          cutoff: component?.cutoff || 0,
        };
      }

      return componentBody;
    });

    let body = {
      processQuery: {
        sourceName,
        queryComponents,
        joinType: "AND",
      },
    };

    await searchForProcess(body, setStructureResultLoading, (items) => {
      setStructureSearchResults(items);
    });
  };

  return (
    <>
      <div className="widget-card ant-card ant-card-bordered gx-mr-3 gx-card-widget">
        <Header
          title={widget?.field}
          open={open}
          setOpen={setOpen}
          child={
            <>
              <Popover content="Add Step Number">
                <span
                  className="gx-fs-lg gx-mt-2 gx-pointer gx-text-primary"
                  onClick={() => setProcessModal(true)}
                >
                  <PlusCircleOutlined />
                </span>
              </Popover>

              {sortMode ? (
                <>
                  <Popover content="Save Sorting">
                    <SaveTwoTone
                      className="gx-fs-lg gx-pointer gx-ml-2"
                      onClick={saveOrder}
                      twoToneColor="#52c41a"
                    />
                  </Popover>
                  <Popover content="Close">
                    <CloseCircleTwoTone
                      className="gx-fs-lg gx-mt-2 gx-pointer gx-ml-2"
                      twoToneColor="#a94442"
                      onClick={() => {
                        setSortMode(false);
                      }}
                    />
                  </Popover>
                </>
              ) : (
                <Popover content="Sort Process Steps">
                  <SlidersOutlined
                    className="gx-fs-lg gx-mt-2 gx-pointer gx-ml-3"
                    onClick={() => {
                      setSortMode(true);
                      setOrderProcesses(processSteps || []);
                    }}
                  />
                </Popover>
              )}
            </>
          }
        />

        {open && (
          <div className="ant-card-body widget-body">
            <div>
              {(processSteps || []).length === 0 ? (
                <div className="gx-h-100 gx-d-flex gx-mt-4 gx-justify-content-center">
                  <span>
                    No Process step is found in {currentProject?.name}.
                  </span>
                </div>
              ) : (
                <>
                  {sortMode ? (
                    <SortableList
                      helperClass="sortableHelper"
                      onSortEnd={onSortEnd}
                    />
                  ) : (
                    <div className="gx-mt-2">
                      <ContactList
                        sortMode={sortMode}
                        contactList={processSteps || []}
                        onAddExperiment={(currentProcess) => {
                          setCurrentProcessId(currentProcess?.processId);

                          getProcessRequest({
                            sourceName: sourceName,
                            processId: currentProcess?.processId,
                          }).then((res) => {
                            setExperiments(res?.data?.experiments);
                          });

                          setExperimentModal(true);
                        }}
                        sourceName={sourceName}
                        onDeleteExperiment={onDeleteExperiment}
                        onDeleteProcess={onDeleteProcess}
                        projectSettings={projectSettings}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </div>

      <Drawer
        title={
          <div className="gx-d-flex">
            <span>
              Associate a process step to project {currentProject?.projectId}
            </span>
            <Radio.Group
              size="small"
              value={viewType}
              onChange={(e) => {
                setViewType(e.target.value);
              }}
              className="gx-mb-4"
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margin: "0 auto",
              }}
            >
              <Radio.Button value="input">Search by Value</Radio.Button>
              <Radio.Button value="structure">Search by Structure</Radio.Button>
            </Radio.Group>
          </div>
        }
        placement="right"
        open={processModal}
        width={900}
        className="settingsDrawer"
        okText="Add Process"
        onOk={onAddProcess}
        onClose={() => {
          setCurrentProcessId(null);
          setButtonDisabled(true);
          setProcessModal(false);
        }}
      >
        <ProcessSelection
          sourceName={sourceName}
          availableWidgets={availableWidgets}
          onSelectProcess={onSelectProcess}
          viewType={viewType}
          onSearchByProcess={onSearchByProcess}
          structureSearchResults={structureSearchResults}
          structureResultLoading={structureResultLoading}
          availableDataSources={availableDataSources}
        />
      </Drawer>

      <Drawer
        title={`Project: ${currentProject?.projectId}. Attach Experiment to process: ${currentProcessId}`}
        open={experimentModal}
        okText="Add Experiment"
        okButtonProps={{ disabled: buttonDisabled }}
        onClose={() => {
          setExperimentModal(false);
        }}
        onOk={onAddExperiment}
        afterClose={() => {
          setCurrentProcessId(null);
          setButtonDisabled(true);
        }}
      >
        {renderExperimentModal()}
      </Drawer>

      <Modal
        title={`Project Id: ${currentProject?.projectId}: Order Processes.`}
        open={orderProcessModal}
        okText="Save Order"
        okButtonProps={{ disabled: !(orderProcesses || []).length }}
        onCancel={() => setOrderProcessModal(false)}
        onOk={saveOrder}
      >
        {renderOrderProcessModal()}
      </Modal>
    </>
  );
};

export default ProjectManagementWidget;
