import _ from "lodash";
import { PAGE_TYPES } from "../constants/Config";
import { getParent } from "./Widget";
import { message } from "antd";

/**
 * Get SCAS image url generic parameters
 * @param {String} backgroundColor
 * @param {String} height
 * @param {String} width
 * @returns {String}
 */
export const getImageUrlParams = (
  backgroundColor = "transbg",
  height = 300,
  width = 300,
  originalMaterial = true
) => {
  return `width=${width}&height=${height}&background=${backgroundColor}&mono=false&nostereoflags=true&originalMaterial=${originalMaterial}`;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const getUploadPercentage = (progress) => {
  return parseFloat((progress?.loaded / progress?.total) * 100.0).toFixed(1);
};

export const capitalizeText = (text = "") => {
  if (text === "process") text = "process step";
  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getFolderName = (acceptedFiles = []) => {
  let folderName = "";
  const filePath = acceptedFiles.length > 0 ? acceptedFiles[0]?.path : "";
  const dirs = filePath.split("/");
  if (dirs.length > 2) {
    folderName = dirs[1];
  }

  return folderName;
};

export const getBackButtonLink = (id, source, pageType) => {
  if (pageType === PAGE_TYPES.EXPERIMENT) {
    return `/${source}/${PAGE_TYPES.PROCESS}/${getParent(id)}`;
  } else {
    return `/${source}/${PAGE_TYPES.COMPOUND}/${getParent(id)}`;
  }
};

export const generateExternalUrl = (url = "") => {
  if (url.includes("http")) {
    return url;
  }

  return `https://${url}`;
};

export const getCompoundType = ({ sources, sourceName, id }) => {
  const currentSource = _.find(sources, { sourceName });
  const isQuality = (id || "").includes(".");

  if (currentSource) {
    if (
      (currentSource?.compoundFormat || "").split("-")[0] ===
      (id || "").split("-")[0]
    ) {
      return isQuality ? "quality" : "compound";
    }

    if (
      (currentSource?.processFormat || "").split("-")[0] ===
      (id || "").split("-")[0]
    ) {
      return isQuality ? "experiment" : "process";
    }

    if (
      (currentSource?.projectFormat || "").split("-")[0] ===
      (id || "").split("-")[0]
    ) {
      return "project";
    }

    if (
      (currentSource?.sampleFormat || "").split("-")[0] ===
      (id || "").split("-")[0]
    ) {
      return "sample";
    }
  }

  return "compound";
};

/**
 * Gets correct redirect from its id and type.
 * If id contains dot(.) in it, it should be considered either experiment or quality.
 */
export const getCompoundTypeFromId = (id, type) => {
  const hasDot = (id || "").includes(".");

  let compoundType = "";

  switch (type) {
    case "COMPOUND":
    case "SPECIFIC_COMPOUND":
      compoundType = hasDot ? "quality" : "compound";
      break;
    case "PROCESS":
    case "SPECIFIC_PROCESS":
      compoundType = hasDot ? "experiment" : "process";
      break;
    case "PROJECT":
    case "SPECIFIC_PROJECTS":
      compoundType = "project";
      break;
    default:
      compoundType = "";
  }

  return compoundType;
};

export const copyToClipboard = (targetValue, copied, setCopied) => {
  message.success("Copied to clipboard.");
  const copyhelper = document.createElement("textarea");
  copyhelper.className = "copyhelper";
  document.body.appendChild(copyhelper);
  copyhelper.value = targetValue;
  copyhelper.select();
  document.execCommand("copy");
  document.body.removeChild(copyhelper);
  setCopied(true);
  setTimeout(() => {
    setCopied(false);
  }, 3000);
};

export const checkMolEmpty = (mol = "") => {
  const emptyMolString = `
  0  0  0  0  0  0  0  0  0  0999 V2000
`;
  return mol.includes(emptyMolString);
};
