import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "../Widget/Header";
import _ from "lodash";

import "./style.css";
import GenericEditor from "../../GenericEditor";
import { copyToClipboard } from "../../../util/url";
import { PAGE_TYPES } from "../../../constants/Config";
import Versioning from "../Widget/Versioning";

const ChemdrawWidget = ({
  id,
  title,
  hasDeleteButton,
  onDelete,
  widgetName,
  sourceName,
  childRef,
  currentEditor,
  compoundDetail,
  versionRoles = [],
  currentVersion,
  setCurrentVersion,
  versions,
  widget = {},
  widgetOption = {},
  pageType,
  onChangeVersion,
}) => {
  const [open, setOpen] = useState(true);
  const [copied, setCopied] = useState(false);

  const delayToLoadChemdraw = 4000;

  useEffect(() => {
    try {
      const cdxml = _.get(compoundDetail, "cdxml");
      const encoded = decodeURIComponent(escape(window.atob(cdxml)));

      if (encoded.length > 0 && open && childRef.current?.loadCDXML) {
        setTimeout(() => {
          childRef.current?.loadCDXML(encoded);
        }, delayToLoadChemdraw);
      }
    } catch (error) {}
  }, [childRef, compoundDetail, compoundDetail.version, open]);

  const renderCopyButton = () => {
    if (childRef.current?.getCDXML()) {
      childRef.current?.getCDXML().then((data) => {
        return (
          <>
            {copied ? (
              <i className="icon icon-check success" />
            ) : (
              <i
                className="icon icon-copy"
                onClick={() => {
                  copyToClipboard(data, copied, setCopied);
                }}
              />
            )}
          </>
        );
      });
    }
  };

  /**
   * If compound structure widget has versions and current version is not a last one, we should highlight header.
   */
  const isLastVersion = (versions || []).length === compoundDetail?.version;

  const titleColor = widgetOption?.titleColor || widget?.titleColor;

  let backgroundColor =
    widgetOption?.backgroundColor || widget?.backgroundColor;

  if (
    currentVersion &&
    (versions || []).length > 1 &&
    !isLastVersion &&
    pageType === PAGE_TYPES.PROCESS
  ) {
    backgroundColor = "#ffbb96";
  }

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
        open ? "isOpen" : ""
      }
  `}
      key={uuidv4()}
    >
      <Header
        title={title}
        titleColor={titleColor}
        backgroundColor={backgroundColor}
        open={open}
        setOpen={setOpen}
        hasDeleteButton={hasDeleteButton}
        onDelete={onDelete}
        child={renderCopyButton()}
      />

      {open && (
        <div>
          <GenericEditor
            currentEditor={currentEditor}
            id="chemdraw-widget"
            className="chemdraw-widget"
            ref={childRef}
            viewMode
          />
          <div className="gx-ml-3 gx-mr-3">
            <Versioning
              currentVersion={currentVersion}
              versions={versions}
              onChangeVersion={(page) => {
                onChangeVersion(page);
              }}
              // renderRestore={restoreMode && isCurator}
              // restoreCompound={restoreCompound}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ChemdrawWidget;
