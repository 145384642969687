import { Button, Form, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import {
  getSystemWidget,
  saveSystemWidget,
} from "../../../appRedux/services/SystemWidget";
import AutoCompleteSelect from "../../../components/AutoCompleteSelect";
import { DUPLICATE_REGISTRATION_USERS } from "../../../constants/Config";

const SpecialPermissions = () => {
  const [userEmails, setUserEmails] = useState(null);

  const [form] = useForm();

  const ref = useRef();

  useEffect(() => {
    getSystemWidget(DUPLICATE_REGISTRATION_USERS, (data) => {
      setUserEmails(data);
    });
  }, []);

  const onSubmit = () => {
    const data = ref.current.getValues();

    saveSystemWidget(
      DUPLICATE_REGISTRATION_USERS,
      (data) => {
        setUserEmails(data);
      },
      () => {},
      { options: data },
    );
  };
  console.log("userEmails", userEmails);

  return (
    <div className="gx-ml-3 gx-mt-2">
      {_.isNull(userEmails) ? (
        <Spin />
      ) : (
        <Form form={form} title="Duplicate Registration Permissions">
          <Form.Item
            label="User name: (Allow duplicate structure registration)"
            name="username"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <AutoCompleteSelect ref={ref} defaultValue={userEmails} />
          </Form.Item>

          <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
            <Button size="small" type="primary" onClick={onSubmit}>
              Save
            </Button>
          </Form.Item>
        </Form>
      )}
    </div>
  );
};

export default SpecialPermissions;
