import { DeleteTwoTone, SearchOutlined } from "@ant-design/icons";
import { Form, Input, Popconfirm, Popover, Select } from "antd";
import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import {
  IDENTIFIER_SEPERATOR,
  PAGE_TYPES,
  componentRoleOptions,
} from "../../../constants/Config";
import { getParent, getPrefix } from "../../../util/Widget";
import PopoverImage from "../Widget/RowArray/PopoverImage";
import CompositionImage from "./TableContent/Image";
import MolecularWeight from "./TableContent/MolecularWeight";
import NamesAndSynonymsContent from "./TableContent/NamesAndSynonymsContent";
import Quality from "./TableContent/Quality";
import SafetySentences from "./TableContent/SafetySentences";

/**
 * Render Image of Composition Table
 */
const renderImage = ({
  compoundId,
  instanceId,
  editMode,
  processData,
  qualityData,
  compositions = [],
}) => {
  const id = instanceId || compoundId;

  const parentId =
    editMode && (processData || qualityData) ? getParent(id) : id;

  return <CompositionImage id={parentId} compositions={compositions} />;
};

/**
 * render compound id
 */
const renderCompoundId = ({
  index,
  compoundId,
  instanceId,
  value,
  processData = false,
  qualityData,
  sourceName,
  onChangeQuality,
  editMode = false,
  newData = false,
  onChangeItem,
  dataSources,
  onOpenSearch,
  onChangeCompoundId,
  onChangeForm,
  dispatch,
}) => {
  const id = instanceId || compoundId;
  const target = (id || "").includes(".") ? "quality" : "compound";
  let idBody = "";
  try {
    idBody = (id || "").split("-")[1];
  } catch (error) {}
  const sourceNamePrefix = getPrefix(dataSources, sourceName);

  return (
    <>
      {editMode && (processData || qualityData) ? (
        <Quality
          option={{ compoundId: compoundId || instanceId, index, value }}
          isEdit
          onChangeQuality={(item) => onChangeQuality(index, item)}
          defaultValue={id}
        />
      ) : (
        <>
          {editMode && newData ? (
            <Form.Item className="table-form-item">
              <div className="gx-d-flex">
                <Input
                  id={`input-compoundId-${index}`}
                  size="small"
                  prefix={`${sourceNamePrefix} ${IDENTIFIER_SEPERATOR} `}
                  defaultValue={idBody}
                  onChange={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    onChangeItem(
                      index,
                      "compoundId",
                      `${sourceNamePrefix}${IDENTIFIER_SEPERATOR}${e.target.value}`
                    );
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      // onChangeCompoundId(e.target.value, index);
                      e.preventDefault();
                      // onChangeForm("compoundId", e.target.value);
                    }
                  }}
                  onPressEnter={(e) => {
                    e.preventDefault();
                    // dispatch(fetchLookupData({ id: `${sourceNamePrefix}${IDENTIFIER_SEPERATOR}${e.target.value}`, sourceName }));
                  }}
                />
                <SearchOutlined
                  style={{
                    marginLeft: "10px",
                    marginTop: "5px",
                    cursor: "pointer",
                  }}
                  onClick={() => onOpenSearch(index)}
                />
              </div>
            </Form.Item>
          ) : (
            <Link
              className={`${processData ? "gx-text-warning" : ""}`}
              to={`/${sourceName}/${target}/${id}`}
              replace
            >
              {id}
            </Link>
          )}
        </>
      )}
    </>
  );
};

/**
 * Render percent composition
 */
const renderPercentComposition = ({
  index,
  value,
  unit,
  processData,
  onChangeItem = () => {},
  editMode = false,
}) => (
  <>
    {processData || !editMode ? (
      <span>
        {value}
        {unit === "percentage" ? " %" : unit}
      </span>
    ) : (
      <Form.Item className="table-form-item" name={`value-${index}`}>
        <Input
          size="small"
          defaultValue={value}
          onChange={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onChangeItem(index, "value", e.target.value);
          }}
        />
      </Form.Item>
    )}
  </>
);

/**
 * Render role
 */
const renderRole = ({
  id,
  compoundId,
  index,
  role,
  type = "",
  processData,
  onChangeItem = () => {},
  editMode = false,
  qualityData,
}) => {
  const isParent = type === "main_component";

  return (
    <>
      {processData || !editMode || qualityData ? (
        <div>{(type || "").replace("_", " ")}</div>
      ) : (
        <>
          {isParent ? (
            <span>{_.capitalize((role || "").replace("_", " "))}</span>
          ) : (
            <Form.Item className="table-form-item" name={`role-${index}`}>
              <Select
                size="small"
                defaultValue={type}
                options={componentRoleOptions}
                onChange={(e) => onChangeItem(index, "type", e)}
              ></Select>
            </Form.Item>
          )}
        </>
      )}
    </>
  );
};

/**
 * Render Actions
 */
const renderAction = ({
  id,
  index,
  isCompound,
  compoundId,
  onRemoveComposition,
  onChangeItem,
}) => {
  const isParent = compoundId === getParent(id);

  return (
    <>
      {isParent ? null : (
        <div>
          {/* <ExperimentTwoTone
            className="gx-mr-2 gx-pointer"
            twoToneColor={isCompound ? "#eee" : "#52C41A"}
            onClick={() => {
              onChangeItem(index, "isCompound", !isCompound);
            }}
          /> */}

          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onRemoveComposition(index)}
            okText="Yes"
            cancelText="No"
          >
            <Popover content="Remove item">
              <DeleteTwoTone
                className="gx-pointer gx-mb-4"
                twoToneColor="#f44336"
              />
            </Popover>
          </Popconfirm>
        </div>
      )}
    </>
  );
};

// /**
//  * Render description
//  */
// const renderDescription = ({ compositions, index, sourceName, pageType }) => (
//   <NamesAndSynonymsContent
//     option={compositions[index]}
//     sourceName={sourceName}
//     pageType={pageType}
//     targetField="description"
//   />
// );

/**
 * Render name
 */
const renderName = ({
  compositions,
  index,
  sourceName,
  pageType,
  targetField,
}) => {
  console.log("compositions", compositions, index);

  return (
    <NamesAndSynonymsContent
      option={compositions[index]}
      sourceName={sourceName}
      pageType={pageType}
      targetField={targetField}
    />
  );
};

/**
 * render Molecular Weight
 */
const renderMolecularWeight = (record) => {
  const { compositions, pageType, currentRound } = record;

  const option = record?.key
    ? _.find(compositions, { key: record?.key })
    : _.find(compositions, { index: record?.index });

  return (
    <MolecularWeight
      option={option}
      pageType={pageType}
      currentRound={currentRound}
    />
  );
};

/**
 * Render safety sentences
 */
const renderSafetySentences = ({
  compositions,
  index,
  pageType,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
}) => {
  return (
    <SafetySentences
      option={compositions[index]}
      pageType={pageType}
      safetySentenceTypes={safetySentenceTypes}
      safetySentenceImageMetadata={safetySentenceImageMetadata}
      safetySentenceLanguageMap={safetySentenceLanguageMap}
    />
  );
};

/**
 * Composition table edit mode columns.
 */
export const renderTableFormColumns = ({
  id,
  pageType,
  sourceName,
  onChangeQuality,
  onChangeItem,
  onRemoveComposition,
  dataSources,
  onChangeCompoundId,
  onChangeForm,
  onOpenSearch,
  dispatch,
}) => [
  {
    title: "Image",
    key: "imagePreview",
    dataIndex: "imagePreview",
    render: (_, options) => renderImage({ ...options, editMode: true }),
  },
  {
    title: pageType === PAGE_TYPES.EXPERIMENT ? "Instance id" : "Compound Id",
    key: "compoundId",
    dataIndex: "compoundId",
    render: (_p, options) => {
      return renderCompoundId({
        ...options,
        sourceName,
        onChangeQuality,
        editMode: true,
        onChangeItem,
        dataSources,
        onChangeCompoundId,
        onChangeForm,
        onOpenSearch,
        index: options?.index,
        dispatch,
      });
    },
  },
  {
    title: "Percent Composition",
    key: "value",
    dataIndex: "value",
    render: (_, options) =>
      renderPercentComposition({ ...options, onChangeItem, editMode: true }),
  },
  {
    title: "Role",
    key: "type",
    dataIndex: "type",
    render: (_, options) =>
      renderRole({ ...options, onChangeItem, editMode: true, id }),
  },
  {
    title: "Action",
    key: "action",
    dataIndex: "action",
    render: (_, options) =>
      renderAction({
        ...options,
        onRemoveComposition,
        id,
        onChangeItem,
        index: options?.index,
      }),
  },
];

export const renderCustomColumn = ({
  allWidgets = [],
  widgetName,
  field,
  pageType,
  sourceName,
  compositions,
  displayName,
}) => {
  const targetWidget = _.find(allWidgets, { value: widgetName });
  const targetRows = _.get(targetWidget, "widget.rows") || [];

  return {
    title: displayName || _.find(targetRows || [], { name: field })?.field,
    key: field,
    dataIndex: field,
    width: 150,
    render: (_, options) =>
      renderName({
        ...options,
        pageType,
        sourceName,
        compositions,
        targetField: field,
      }),
  };
};

const popoverContent = (id, source) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        height: "100%",
      }}
    >
      <PopoverImage rmoId={id} sourceName={source} />
      <span className="gx-text-primary" style={{ textAlign: "center" }}>
        {id}
      </span>
    </div>
  );
};

export const renderPredefinedColumn = ({
  field,
  pageType,
  compositions,
  currentRound,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  sourceName,
}) => {
  switch (field) {
    case "imagePreview": {
      return {
        title: "Image",
        key: "imagePreview",
        dataIndex: "imagePreview",
        render: (_, options) =>
          renderImage({ ...options, compositions: compositions || [] }),
        width: 100,
      };
    }
    case "compoundId": {
      return {
        title:
          pageType === PAGE_TYPES.EXPERIMENT ? "Instance Id" : "Compound Id",
        key: "compoundId",
        dataIndex: "compoundId",
        editable: true,
        width: 150,
        render: (_p, { compoundId, instanceId }) => {
          const id = compoundId || instanceId;
          const type = (id || "").includes(".") ? "quality" : "compound";

          return (
            <Popover content={popoverContent(getParent(id), sourceName)}>
              <Link
                to={`/${sourceName}/${type}/${compoundId || instanceId}`}
                target="_blank"
                replace
              >
                {compoundId || instanceId}
              </Link>
            </Popover>
          );
        },
      };
    }
    case "weight": {
      return {
        title: "Molecular Weight",
        key: "weight",
        dataIndex: "weight",
        width: 150,
        render: (_, options) =>
          renderMolecularWeight({
            ...options,
            pageType,
            compositions,
            currentRound,
          }),
      };
    }
    case "safetySentences": {
      return {
        title: "Safety Sentences",
        key: "weight",
        dataIndex: "weight",
        width: 150,
        render: (_, options) =>
          renderSafetySentences({
            ...options,
            pageType,
            compositions,
            safetySentenceTypes,
            safetySentenceImageMetadata,
            safetySentenceLanguageMap,
          }),
      };
    }
    case "percentCompositions": {
      return {
        title: "Percent Composition",
        key: "value",
        dataIndex: "value",
        width: 150,
        render: (_, options) => renderPercentComposition({ ...options }),
        editable: true,
        type: "number",
      };
    }
    case "role": {
      return {
        title: "Role",
        key: "type",
        dataIndex: "type",
        width: 150,
        editable: true,
        name: "role",
        type: "select",
        render: (_, options) => renderRole({ ...options }),
      };
    }
    default:
      return {};
  }
};

/**
 * Composition table view mode column definitions.
 */
export const renderTableContentForm = ({
  compositions,
  pageType,
  sourceName,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  dataSources,
  onChangeCompoundId,
  onChangeForm,
  currentRound,
  namesAndSynonymsWidgetRows,
  handleDelete,
}) => [
  {
    title: "Image",
    key: "imagePreview",
    dataIndex: "imagePreview",
    render: (_, options) => renderImage({ ...options }),
    width: 100,
  },
  {
    title: pageType === PAGE_TYPES.EXPERIMENT ? "Instance Id" : "Compound Id",
    key: "compoundId",
    dataIndex: "compoundId",
    editable: true,
    width: 150,
    render: (_p, { compoundId, instanceId }) => {
      return <span>{compoundId || instanceId}</span>;
    },
    // render: (_, options) =>
    //   renderCompoundId({
    //     ...options,
    //     pageType,
    //     sourceName,
    //     dataSources,
    //     onChangeCompoundId,
    //     onChangeForm,
    //   }),
  },
  // {
  //   title: _.find(namesAndSynonymsWidgetRows || [], { name: "englishName" })
  //     ?.field,
  //   key: "name",
  //   dataIndex: "englishName",
  //   width: 150,
  //   render: (_, options) =>
  //     renderName({ ...options, pageType, sourceName, compositions }),
  // },
  // {
  //   title: _.find(namesAndSynonymsWidgetRows || [], { name: "description" })
  //     ?.field,
  //   key: "description",
  //   dataIndex: "description",
  //   width: 150,
  //   render: (_, options) =>
  //     renderDescription({ ...options, pageType, sourceName, compositions }),
  // },
  {
    title: "Molecular Weight",
    key: "weight",
    dataIndex: "weight",
    width: 150,
    render: (_, options) =>
      renderMolecularWeight({
        ...options,
        pageType,
        compositions,
        currentRound,
      }),
  },
  {
    title: "Safety Sentences",
    key: "weight",
    dataIndex: "weight",
    width: 150,
    render: (_, options) =>
      renderSafetySentences({
        ...options,
        pageType,
        compositions,
        safetySentenceTypes,
        safetySentenceImageMetadata,
        safetySentenceLanguageMap,
      }),
  },
  {
    title: "Percent Composition",
    key: "value",
    dataIndex: "value",
    width: 150,
    render: (_, options) => renderPercentComposition({ ...options }),
    editable: true,
    type: "number",
  },
  {
    title: "Role",
    key: "type",
    dataIndex: "type",
    width: 150,
    editable: true,
    name: "role",
    type: "select",
    render: (_, options) => renderRole({ ...options }),
  },
  // {
  //   title: "Action",
  //   dataIndex: "action",
  //   width: "15%",
  //   render: (_, record) => {
  //     const { isLocked } = record;

  //     if (isLocked) return null;

  //     return (
  //       <Popconfirm
  //         title="Sure to delete?"
  //         onConfirm={() => handleDelete(record)}
  //       >
  //         <DeleteTwoTone
  //           className="gx-mr-3 gx-pointer"
  //           twoToneColor="#f44336"
  //         />{" "}
  //       </Popconfirm>
  //     );
  //   },
  //   key: "actions",
  // },
];
