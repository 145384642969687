import React, { useState } from "react";
import PT from "prop-types";

import _ from "lodash";
import { Button, Collapse, Modal } from "antd";
import {
  getLanguageItem,
  renderSafetySentencesModal,
} from "../../../../../util/SafetySentences";
import { AppstoreTwoTone } from "@ant-design/icons";
import { useSelector } from "react-redux";

const SafetySentences = ({
  option,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
}) => {
  const [showTableModal, setShowTableModal] = useState(false);
  const [selectedTableId, setSelectedTableId] = useState();

  const pageId =
    _.get(option, "compoundId") ||
    _.get(option, "instanceId") ||
    _.get(option, "id");

  const { [pageId]: data = {} } = useSelector(({ lookup }) => lookup);

  const options = _.get(data, "safetySentences.parameters.data") || [];
  const parentOptions = _.get(data, "hskValue.data") || [];

  const totalData = _.uniqWith(
    [...parentOptions, ...options],
    (a, b) => a?.statement === b?.statement && a?.category === b?.category
  );

  const group = _.map(safetySentenceTypes || {}, (option) => option);
  const elements = _.flatten(
    group.map((groupItem) => groupItem?.elements)
  ).filter((item) => item?.active);

  return (
    <div>
      {(totalData || []).length > 0 ? (
        <Collapse ghost>
          <Collapse.Panel header="click to see details" key={1}>
            <>
              {(totalData || []).map((option) => {
                const { statement, category } = option;

                const foundOption = elements.filter(
                  (item) => item?.statementId === statement
                );

                let pictogram = "";

                if (foundOption.length) {
                  const { pictogramIds = [] } = foundOption[0];
                  pictogram = pictogramIds.length ? pictogramIds[0] : "";
                }

                const img = getLanguageItem(
                  pictogram,
                  safetySentenceLanguageMap
                );

                return (
                  <>
                    {img && (
                      <img
                        height={50}
                        width={50}
                        alt="composition item"
                        src={`data:image/jpeg;base64,${img}`}
                      />
                    )}
                    <p className="gx-ml-2 gx-mt-1" key={statement}>
                      <AppstoreTwoTone
                        className="gx-mr-2 gx-pointer"
                        twoToneColor="#20c997"
                        onClick={() => {
                          setShowTableModal(true);
                          setSelectedTableId({ id: statement, category });
                        }}
                      />
                      {statement}
                    </p>
                  </>
                );
              })}
            </>
          </Collapse.Panel>
        </Collapse>
      ) : (
        <span>Not Assigned</span>
      )}

      <Modal
        title="Safety Sentences"
        visible={showTableModal}
        onOk={() => setShowTableModal(false)}
        onCancel={() => setShowTableModal(false)}
        width="80%"
        footer={[
          <Button type="primary" onClick={() => setShowTableModal(false)}>
            OK
          </Button>,
        ]}
      >
        {renderSafetySentencesModal(
          elements,
          selectedTableId,
          safetySentenceLanguageMap,
          safetySentenceImageMetadata
        )}
      </Modal>
    </div>
  );
};

SafetySentences.defaultProps = {
  option: {},
  pageType: "",
  safetySentenceTypes: [],
  safetySentenceImageMetadata: [],
  safetySentenceLanguageMap: [],
};

SafetySentences.propTypes = {
  option: PT.shape(),
  pageType: PT.string,
  safetySentenceTypes: PT.arrayOf(PT.shape()),
  safetySentenceImageMetadata: PT.arrayOf(PT.shape()),
  safetySentenceLanguageMap: PT.arrayOf(PT.shape()),
};

export default SafetySentences;
