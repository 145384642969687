import {
  FileExcelTwoTone,
  InfoCircleTwoTone,
  PlusCircleTwoTone,
  PrinterTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tooltip,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import axios from "axios";
import { saveAs } from "file-saver";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import XlsxPopulate from "xlsx-populate";
import {
  fetchCollectionDetail,
  fetchDocumentDetail,
  resetCollectionSearch,
  resetDocumentDetailSearch,
} from "../../appRedux/actions";
import { getPreviewImageApi } from "../../appRedux/services/DetailPage";
import {
  editDocument,
  searchForDocumentCollection,
} from "../../appRedux/services/Document";
import { searchData, searchForStructure } from "../../appRedux/services/Widget";
import {
  DATA_SEARCH_OPERATORS,
  DEFAULT_PAGE_SIZE,
  NAMES_AND_SYNONYMS_WIDGET_PARAMS,
  PAGE_TYPES,
  config,
} from "../../constants/Config";
import RecentRegisteredCompounds from "../../routes/HomePage/RecentRegisteredCompounds";
import {
  addParametersToDocument,
  checkField,
  getPrefix,
} from "../../util/Widget";
import { getRole } from "../../util/auth";
import { paginate } from "../../util/generic";
import AutoCompleteSelect from "../AutoCompleteSelect";
import MetaWidget from "../DetailPage/DocumentCollection/MetaWidget";
import { FileDetailComponent } from "../DetailPage/DocumentCollection/helper";
import InputTag from "../InputTag";
import ImageCarousel from "../Registration/ImageCarousel";
import {
  PAGE_LIMIT,
  renderCompoundDataTable,
  renderDocumentTable,
  renderImage,
  renderSearchByTable,
} from "../SearchPage/helper";
import TableFilter from "../TableFilter";
import CollectionManager from "./CollectionManager";
import DataForm from "./DataForm";
import DocumentSearchForm from "./DocumentSearchForm";
import GenericDataSearchForm from "./GenericDataSearchForm";
import MolecularFormulaForm from "./MolecularFormulaForm";
import MolecularWeightForm from "./MolecularWeightForm";
import SearchByAllWidgetsForm from "./SearchByAllWidgetsForm";

const SearchWidget = ({
  defaultSourceName,
  value,
  sources,
  selectedSources,
  navigationCard,
  documentCollectionOptions,
  availableWidgetParams,
  availableSearchWidgetParams,
  widgetOption,
  widget = {},
  dataSourceOptions,
  availableWidgets,
  selectedCollection,
  setSelectedCollection = () => {},
  pageType,
}) => {
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [filteredResult, setFilteredResult] = useState(null);
  const [open, setOpen] = useState(true);
  const [targetValue, setTargetValue] = useState();
  const [mapImages, setMapImages] = useState({});
  const [currentType, setCurrentType] = useState(
    DATA_SEARCH_OPERATORS[1].value,
  );
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [filterValue, setFilterValue] = useState();
  const [tableKey, setTableKey] = useState(0);
  const [currentSelection, setCurrentSelection] = useState();
  const [showModal, setShowModal] = useState(false);
  const [currentDocument, setCurrentDocument] = useState();

  const [imageMap, setImageMap] = useState({});

  const [showEditDocument, setShowEditDocument] = useState(false);
  const [currentEditDocument, setCurrentEditDocument] = useState({});
  const [defaultEntityIds, setDefaultEntityIds] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalItems, setTotalItems] = useState();
  const [showDrawer, setShowDrawer] = useState(false);

  const [documentTableData, setDocumentTableData] = useState([]);

  const [option, setOption] = useState();
  const [operator, setOperator] = useState();
  const [searchValue, setSearchValue] = useState();

  const { accounts } = useMsal();
  const account = accounts[0];

  const searchByIdFormRef = useRef();

  const dispatch = useDispatch();

  const genericData = useSelector(({ generic }) => generic);

  const {
    searchResults,
    loading: documentLoading,
    collectionResults,
    loadingCollection,
  } = useSelector(({ document }) => document);

  const {
    type,
    title,
    info = "",
    field,
    label,
    widgets = [],
    targetPages = [],
  } = widget;

  const dataSources = _.map(sources, (item) => ({
    label: item?.sourceName,
    value: item?.sourceName,
  }));

  const documentWidgetResults = addParametersToDocument(searchResults);

  const collectionWidgetResults = addParametersToDocument(collectionResults);

  // useEffect(() => {
  //   console.log("documentWidgetResults", documentWidgetResults);

  //   if ((documentWidgetResults || []).length) {
  //     renderImageForDocumentTable({
  //       currentPage: 1,
  //       pageSize: PAGE_LIMIT,
  //       entityIds: documentWidgetResults,
  //       mapImages,
  //       setMapImages,
  //       forceUpdate,
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [documentWidgetResults]);

  useEffect(() => {
    if (type === "SEARCH_BY_ID" && !_.isEmpty(result)) {
      _.forEach(result, (item) => {
        const { child } = item;
        const currentItem =
          _.isArray(child) && child.length > 0 ? child[0] : {};

        if (
          currentItem?.compoundId &&
          !_.get(imageMap, currentItem?.compoundId)
        ) {
          getPreviewImageApi(
            currentItem?.compoundId,
            item?.source,
            () => {},
            (res) => {
              const newImageMap = {
                ...imageMap,
                [currentItem?.compoundId]: res,
              };
              setImageMap((previousInputs) => ({
                ...previousInputs,
                ...newImageMap,
              }));
            },
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result, currentPage, currentPageSize, type]);

  // eslint-disable-next-line no-unused-vars
  const onChangeTable = (
    current,
    pageSize,
    filters,
    sorter,
    currentDataSource,
  ) => {
    setCurrentPage(current);
    setCurrentPageSize(pageSize);
  };

  const onSearchMolecularWeight = (form = {}) => {
    const { lower, upper } = form;

    setResult([]);
    setMapImages({});

    const body = {
      databases: selectedSources,
      structureTypes: ["DS"],
    };

    if (currentType === "=") {
      body.mwTop = targetValue;
      body.mwBottom = targetValue;
    }

    if (currentType === "<=") {
      body.mwTop = targetValue;
    }

    if (currentType === ">=") {
      body.mwBottom = targetValue;
    }

    if (lower && currentType === "Between") {
      body.mwTop = lower;
    }

    if (upper && currentType === "Between") {
      body.mwBottom = targetValue;
    }

    searchForStructure({
      op: type,
      body,
      setFn: setResult,
      setLoading,
    });
  };

  const onSearchMolecularFormula = () => {
    setMapImages({});
    setResult([]);

    const body = {
      queryStructure: "",
      databases: selectedSources,
      structureTypes: ["DS"],
      mfQuery: `${currentType}${targetValue}`,
    };

    searchForStructure({
      op: type,
      body,
      setFn: setResult,
      setLoading,
    });
  };

  const onSearchData = (props = {}) => {
    const { inputType, targetValue } = props;
    setFilterValue(null);
    setFilteredResult(null);
    setTableKey((tableKey) => tableKey + 1);

    const foundWidgetParam = _.find(availableWidgetParams, {
      label: label || field,
    });

    let parameterQueries = {
      parameterName: "parameters",
      parameterField: foundWidgetParam?.name || field,
      operator: currentType,
      values: _.isNil(targetValue) ? null : _.flatten([targetValue]),
      queryJoinType: "AND",
      not: false,
    };

    if (currentType === "IS_DEFINED") {
      parameterQueries["operator"] = "IS_NULL";
      parameterQueries["not"] = true;
    }

    if (inputType === "null") {
      delete parameterQueries["values"];
    }

    const query = {
      type: "COMPOUND",
      sourceName: {
        values: selectedSources,
        operator: "IN",
        fieldType: "STRING",
        not: false,
      },
      joinType: "AND",
      parameterQueries: [parameterQueries],
    };

    searchData({
      op: type,
      body: query,
      setFn: setResult,
      setLoading,
    });
  };

  const onSearchGenericData = (props = {}) => {
    const { targetValue } = props;
    setFilterValue(null);
    setFilteredResult(null);
    setTableKey((tableKey) => tableKey + 1);

    let parameterQueries = {};

    console.log("targetValue", targetValue);

    const value = _.isNil(targetValue) ? null : _.flatten([targetValue]);

    if (_.isNumber(value) && !_.isNaN(Number(value))) {
      parameterQueries = {
        parameterName: "parameters",
        parameterField: currentSelection,
        operator: currentType,
        values: _.flatten([value, Number(value)]),
        queryJoinType: "IN",
        not: false,
      };
    } else {
      parameterQueries = {
        parameterName: "parameters",
        parameterField: currentSelection,
        operator: currentType,
        values: value,
        queryJoinType: "AND",
        not: false,
      };
    }

    if (currentType === "IS_DEFINED") {
      parameterQueries["operator"] = "IS_NULL";
      parameterQueries["not"] = true;

      delete parameterQueries["values"];
    }

    const query = {
      type: "COMPOUND",
      sourceName: {
        values: selectedSources,
        operator: "IN",
        fieldType: "STRING",
        not: false,
      },
      joinType: "AND",
      parameterQueries: [parameterQueries],
    };

    searchData({
      op: type,
      body: query,
      setFn: setResult,
      setLoading,
    });
  };

  const onSearchAllParameters = (prop = {}) => {
    const { targetValue } = prop;

    const namesAndSynonymsParams = _.filter(
      availableWidgetParams,
      (param) =>
        param?.widgetName === NAMES_AND_SYNONYMS_WIDGET_PARAMS &&
        param?.type === "EDITABLE",
    );

    let value = targetValue;

    if (_.isNumber(targetValue) && !_.isNaN(Number(targetValue))) {
      value = [targetValue, Number(targetValue)];
    }

    let parameterQueries = [];
    _.forEach(namesAndSynonymsParams, (param) => {
      let query = {};
      if (currentType === "IS_DEFINED") {
        query = {
          operator: "IS_NULL",
          parameterName: "parameters",
          parameterField: param?.value,
          queryJoinType: "OR",
          values: _.flatten([value]),
          not: true,
        };
      } else {
        query = {
          operator: currentType,
          parameterName: "parameters",
          parameterField: param?.value,
          queryJoinType: "OR",
          values: _.isNil(targetValue) ? null : _.flatten([value]),
          not: false,
        };
      }

      if (_.isNil(targetValue)) {
        delete query["values"];
      }

      parameterQueries.push(query);
    });

    const query = {
      type: "COMPOUND",
      parameterQueries,
      joinType: "AND",
      sourceName: {
        values: selectedSources,
        operator: "IN",
        fieldType: "STRING",
        not: false,
      },
    };

    searchData({
      op: type,
      body: query,
      setFn: setResult,
      setLoading,
    });
  };

  const onSearchDocument = (
    systemWidgetName,
    operator,
    option,
    searchValue,
  ) => {
    const documentQuery = {
      sources: {
        values: selectedSources,
        operator: "EQ",
      },
      collections: {
        values: [systemWidgetName],
        operator: "EQ",
      },
      subset: {
        values: ["string"],
        operator: "IS_NULL",
        fieldType: "STRING",
        not: false,
      },
    };

    if (operator && option && searchValue) {
      setCurrentSelection(option);

      let fieldType = "STRING";

      if (
        operator === "GT" ||
        operator === "GTE" ||
        operator === "LT" ||
        operator === "LTE"
      ) {
        fieldType = "NUMERIC";
      }

      /**
       * Document `accession number is tied to document `name` parameter.`
       */
      if (option === "accessionNumber") {
        const values = _.isArray(searchValue)
          ? _.map(searchValue, (val) => Number(val))
          : [Number(searchValue)];

        documentQuery["accessionNumber"] = {
          values,
          operator: operator === "CONTAINS" ? "IN" : operator,
          fieldType,
        };
      } else if (
        _.isArray(searchValue) &&
        searchValue.length &&
        operator === "IN"
      ) {
        documentQuery["searchSubjects"] = [
          {
            operator,
            subject: {
              key: option,
              values: searchValue,
            },
            fieldType,
          },
        ];
      } else {
        documentQuery["searchSubjects"] = [
          {
            operator,
            subject: {
              key: option,
              value: searchValue,
            },
            fieldType,
          },
        ];
      }
    }

    const body = {
      documentQueries: [
        {
          queryJoinType: "AND",
          documentQuery,
          order: 0,
          language: "en",
        },
      ],
    };

    searchForDocumentCollection(body, (results) => {
      setDocumentTableData(results);

      dispatch(
        resetDocumentDetailSearch({
          total: Math.min(currentPageSize, (results || []).length),
        }),
      );

      const resultsPaginated = paginate(results, currentPageSize, currentPage);

      if (resultsPaginated && _.isArray(resultsPaginated)) {
        _.forEach(resultsPaginated, (result) => {
          dispatch(fetchDocumentDetail(result));
        });
      }
    });
  };

  const onSearchCollection = (
    systemWidgetName,
    operator,
    option,
    searchValue,
  ) => {
    if (_.isEmpty(selectedSources)) {
      NotificationManager.error("Please select data source.");
    }

    const documentQuery = {
      sources: {
        values: selectedSources,
        operator: "EQ",
      },
      collections: {
        values: [systemWidgetName],
        operator: "EQ",
      },
      subset: {
        values: ["string"],
        operator: "IS_NULL",
        fieldType: "STRING",
        not: false,
      },
    };

    if (operator && option && searchValue) {
      setCurrentSelection(option);

      let fieldType = "STRING";

      if (
        operator === "GT" ||
        operator === "GTE" ||
        operator === "LT" ||
        operator === "LTE"
      ) {
        fieldType = "NUMERIC";
      }

      /**
       * Document `accession number is tied to document `name` parameter.`
       */
      if (option === "accessionNumber") {
        const values = _.isArray(searchValue)
          ? _.map(searchValue, (val) => Number(val))
          : [Number(searchValue)];

        documentQuery["accessionNumber"] = {
          values: values,
          operator: operator === "CONTAINS" ? "IN" : operator,
          fieldType,
        };
      } else if (
        _.isArray(searchValue) &&
        searchValue.length &&
        operator === "IN"
      ) {
        documentQuery["searchSubjects"] = [
          {
            operator,
            subject: {
              key: option,
              values: searchValue,
            },
            fieldType,
          },
        ];
      } else {
        documentQuery["searchSubjects"] = [
          {
            operator,
            subject: {
              key: option,
              value: searchValue,
            },
            fieldType,
          },
        ];
      }
    }

    const body = {
      documentQueries: [
        {
          queryJoinType: "AND",
          documentQuery,
          order: 0,
          language: "en",
        },
      ],
    };

    searchForDocumentCollection(body, (results) => {
      setDocumentTableData(results);

      dispatch(
        resetCollectionSearch({
          total: Math.min(currentPageSize, (results || []).length),
        }),
      );

      const resultsPaginated = paginate(results, currentPageSize, currentPage);

      if (resultsPaginated && _.isArray(resultsPaginated)) {
        _.forEach(resultsPaginated, (result) => {
          dispatch(fetchCollectionDetail(result));
        });
      }
    });
  };

  const updateDocuments = (systemWidgetName) => {
    onSearchCollection(systemWidgetName, operator, option, searchValue);
  };

  const onSearchById = (results) => {
    const dataSourceGrouped = _.groupBy(results, "compoundId");

    const dataSource = _.map(Object.entries(dataSourceGrouped), (row) => {
      const child = row[1];
      const firstItem = child[0] || {};

      const childGrouped = _.groupBy(child, "sourceName");
      const image = _.get(genericData, [firstItem?.name, field]);

      const childRow = _.map(Object.entries(childGrouped), (childRow) => {
        return {
          source: childRow[0],
          child: childRow[1],
          image,
        };
      });

      return {
        image,
        child: childRow,
      };
    });

    const normalizedDatasource = _.map(dataSource, (ds) => {
      const childLength = (ds?.child || []).length;

      return [
        _.map(ds?.child || [], (childRow, index) => {
          if (!index) {
            return {
              image: ds?.image,
              rowSpan: childLength,
              ...childRow,
            };
          }

          return {
            ...childRow,
          };
        }),
      ];
    });

    const tableDatasource = _.flattenDeep(normalizedDatasource);
    setResult(tableDatasource);
  };

  const onChangeSearchByIdTable = (
    current,
    pageSize,
    filters,
    sorter,
    currentDataSource,
  ) => {
    setCurrentPage(current);
    setCurrentPageSize(pageSize);

    const source = searchByIdFormRef.current.getFieldValue("source");
    const widgets = searchByIdFormRef.current.getFieldValue("widgets");

    const from = searchByIdFormRef.current.getFieldValue("from");
    const to = searchByIdFormRef.current.getFieldValue("to");

    aggregateWidgetDataByRange({
      source,
      widgets,
      from,
      to,
      currentPage: current,
      currentPageSize: pageSize,
    });
  };

  // Filter errors
  const dataSource = _.filter(result, (item) => !item?.error && !item?.message);
  const isErrorExists = _.find(result, (item) => item?.error);
  const searchInput = useRef(null);

  useEffect(() => {
    if ((result || []).length) {
      renderImage({
        currentPage: 1,
        pageSize: PAGE_LIMIT,
        searchResults: dataSource,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
        isEntityIds: true,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const aggregateWidgetDataByList = ({
    source,
    widgets = [],
    ids = [],
    currentPage,
    currentPageSize,
  }) => {
    let promises = [];

    _.forEach(ids, (id) => {
      // _.forEach(widgets, (widget) => {
      //   promises.push(
      //     axios.get(
      //       `${config.SRD_API_URL}/metadata/${widget}?compoundId=${id}&sourceName=${source}`
      //     )
      //   );
      // });
      promises.push({
        id,
        source,
        widgets,
      });
    });

    setTotalItems(promises.length);

    const promisesPaginated = promises.slice(
      (currentPage - 1) * currentPageSize,
      currentPage * currentPageSize,
    );

    setLoading(true);

    let pr = [];
    _.forEach(promisesPaginated, (item) => {
      _.forEach(item?.widgets || [], (widget) => {
        pr.push(
          axios.get(
            `${config.SRD_API_URL}/metadata/${widget}?compoundId=${item?.id}&sourceName=${item?.source}`,
          ),
        );
      });
    });

    Promise.allSettled(pr)
      .then((results) => {
        const normalizedList = _.map(results, (list) => {
          if (list.status === "fulfilled") {
            return {
              ...list?.value?.data[0],
            };
          } else {
            const url = _.get(list, "reason.config.url");
            const newParams = (url || "").split("?")[1];
            const body = (url || "").split("?")[0];
            const widgetType = (body || "").split(`/metadata/`)[1];
            const params = new Proxy(new URLSearchParams(newParams), {
              get: (searchParams, prop) => searchParams.get(prop),
            });

            return {
              ...list,
              parameters: {},
              widgetType,
              compoundId: params.compoundId || "",
              sourceName: params.sourceName,
            };
          }
        });

        onSearchById(normalizedList);
      })
      .finally(() => {
        setLoading(false);
        promises = null;
        pr = null;
      });
  };

  const aggregateWidgetDataByRange = ({
    source,
    widgets = [],
    from = "",
    to = "",
    currentPage,
    currentPageSize,
  }) => {
    const numFrom = from.includes("-")
      ? Number(from.split("-")[1])
      : Number(from);
    const numTo = to.includes("-") ? Number(to.split("-")[1]) : Number(to);
    const prefix = to.includes("-")
      ? to.split("-")[0]
      : getPrefix(dataSources, source);
    let promises = [];

    if (numFrom && numTo) {
      for (let id = numFrom; id <= numTo; id++) {
        const currentId = `${prefix}-${id}`;

        // eslint-disable-next-line no-loop-func
        // _.forEach(widgets, (widget) => {
        promises.push({
          currentId,
          source,
          widgets,
        });
        // });
      }
    }

    setTotalItems(promises.length);

    const promisesPaginated = promises.slice(
      (currentPage - 1) * currentPageSize,
      currentPage * currentPageSize,
    );

    let convertedAxiosPromises = [];

    _.forEach(promisesPaginated, (p) => {
      _.forEach(p?.widgets || [], (widget) => {
        convertedAxiosPromises.push(
          axios.get(
            `${config.SRD_API_URL}/metadata/${widget}?compoundId=${p.currentId}&sourceName=${p.source}`,
          ),
        );
      });
    });

    setLoading(true);
    Promise.allSettled(convertedAxiosPromises)
      .then((results) => {
        const normalizedList = _.map(results, (list) => {
          if (list.status === "fulfilled") {
            return {
              ...list?.value?.data[0],
            };
          } else {
            const url = _.get(list, "reason.config.url");
            const newParams = (url || "").split("?")[1];
            const body = (url || "").split("?")[0];
            const widgetType = (body || "").split(`/metadata/`)[1];
            const params = new Proxy(new URLSearchParams(newParams), {
              get: (searchParams, prop) => searchParams.get(prop),
            });

            return {
              ...list,
              parameters: {},
              widgetType,
              compoundId: params.compoundId || "",
              sourceName: params.sourceName,
            };
          }
        });

        onSearchById(normalizedList);
      })
      .finally(() => {
        setLoading(false);
        promises = null;
        convertedAxiosPromises = null;
      });
  };

  const exportWidgetData = () => {
    let currentResult = _.isEmpty(filteredResult) ? result : filteredResult;

    if (type === "SEARCH_BY_ID") {
      currentResult = _.map(currentResult, (item) => ({
        ...item,
        ..._.get(item, "child[0]"),
      }));
    }

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      let aggregatedKeys = [];
      _.forEach(currentResult, (item) => {
        const keys = item?.parameters || {};
        _.forEach(keys, (value, key) => {
          const targetParameter =
            _.find(availableWidgetParams, { value: key }) || {};

          const targetValue = targetParameter?.field || key;

          aggregatedKeys.push({
            key,
            value: targetValue,
          });
        });
      });

      let headers = [];

      const aggregatedKeysUniq = _.uniqWith(aggregatedKeys, _.isEqual);

      if (type === "DATA" || type === "GENERIC_DATA_SEARCH") {
        headers = [
          { key: "Id", value: "Id" },
          { key: "Source", value: "Source" },
          ...aggregatedKeysUniq,
        ];
      } else if (
        type === "BINGO_MW" ||
        type === "BINGO_MF" ||
        type === "SEARCH_BY_ID"
      ) {
        headers = [
          { key: "Compound Id", value: "Compound Id" },
          { key: "Source", value: "Source" },
          ...aggregatedKeysUniq,
        ];
      } else if (type === "SEARCH_BY_ALL_PARAMETERS") {
        headers = [
          { key: "Id", value: "Compound ID" },
          { key: "Source", value: "Source Name" },
          ...aggregatedKeysUniq,
        ];
      } else {
        headers = [
          { key: "File Name", value: "File Name" },
          { key: "Source", value: "Source" },
          ...aggregatedKeysUniq,
        ];
      }

      const sheet1 = workbook.sheet(0);
      const sheetData = _.map(currentResult, (item) => {
        let row = [];

        if (
          type === "DATA" ||
          type === "GENERIC_DATA_SEARCH" ||
          type === "SEARCH_BY_ALL_PARAMETERS" ||
          type === "SEARCH_BY_ID"
        ) {
          row = [item?.compoundId, item?.sourceName];
        } else if (type === "BINGO_MW" || type === "BINGO_MF") {
          row = [item?.vendorId, item?.source];
        } else {
          row = [item?.fileName, item?.source];
        }

        _.forEach(item?.parameters, (value, key) => {
          const index = _.findIndex(headers, (target) => target?.key === key);
          if (index !== -1) {
            // row = insert(row, index, value);
            row[index] = value;
          }
        });

        return row;
      });

      try {
        const totalColumns = (sheetData || []).length
          ? sheetData[0].length || 0
          : 0;

        const headerValues = _.map(headers, (head) => head?.value);
        sheetData.unshift(headerValues);
        sheet1.cell("A1").value(sheetData);
        const range = sheet1.usedRange();
        const endColumn = String.fromCharCode(64 + totalColumns);
        sheet1.row(1).style("bold", true);
        sheet1.range("A1:" + endColumn + "1").style("fill", "BFBFBF");
        range.style("border", true);
      } catch (error) {}

      return workbook.outputAsync().then((res) => {
        saveAs(res, `${title}.xlsx`);
      });
    });
  };

  const inputRef = useRef();
  const [form] = useForm();

  const typeOptions = [
    { label: "By ID List", value: "list" },
    { label: "By ID Range", value: "range" },
  ];

  const handleFormValuesChange = (changedValues) => {
    const fieldName = Object.keys(changedValues)[0];

    if (fieldName === "type") {
      const value = changedValues[fieldName];
      setCurrentType(value);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const availableWidgetsOptions = _.map(availableWidgets, (item) => ({
    label: item?.field,
    value: item?.value,
  }));

  const onFinish = () => {
    const ids = inputRef.current?.changeVal();
    const source = searchByIdFormRef.current.getFieldValue("source");
    const widgets = searchByIdFormRef.current.getFieldValue("widgets");

    const from = searchByIdFormRef.current.getFieldValue("from");
    const to = searchByIdFormRef.current.getFieldValue("to");

    form.validateFields();

    if (currentType === "list") {
      aggregateWidgetDataByList({
        source,
        widgets,
        ids,
        currentPage: 1,
        currentPageSize: DEFAULT_PAGE_SIZE,
      });
    } else {
      aggregateWidgetDataByRange({
        source,
        widgets,
        from,
        to,
        currentPage: 1,
        currentPageSize: currentPageSize,
      });
    }
  };

  const renderContent = () => {
    if (type === "RECENT") {
      return <RecentRegisteredCompounds />;
    }

    if (type === "NAVIGATE") {
      return (
        <>
          {navigationCard({
            option: widgetOption,
            targetPages,
          })}
        </>
      );
    }

    const titleColor = widgetOption?.titleColor;
    const backgroundColor = widgetOption?.backgroundColor;
    const maxHeight = widgetOption?.maxHeight;

    return (
      <div
        className="widget-card ant-card ant-card-bordered gx-mr-3"
        styleName="color-white gx-mr-3 gx-ml-3"
        style={
          maxHeight && open
            ? {
                height: `${maxHeight}px`,
                overflowY: "scroll",
              }
            : { height: "fit-content" }
        }
      >
        <div
          className="ant-card-head widget-header"
          style={{
            backgroundColor,
            borderBottom: "unset",
          }}
        >
          <div
            className="ant-row-flex gx-justify-content-between gx-dash-search"
            style={{ flexWrap: "nowrap" }}
          >
            <div
              className="gx-d-flex gx-justify-content-between"
              style={{ height: "fit-content" }}
            >
              <div className="gx-d-flex gx-mr-5 gx-mt-2">
                <h2
                  className="h4 gx-mr-2"
                  style={{ marginLeft: "8px", color: titleColor }}
                >
                  {title}
                </h2>
                {info.length ? (
                  <Tooltip title={info}>
                    <InfoCircleTwoTone className="gx-ml-2 gx-mt-1 gx-pointer" />
                  </Tooltip>
                ) : null}
              </div>

              {loading ? (
                <Spin
                  style={{
                    marginTop: "10px",
                  }}
                />
              ) : (
                <div>
                  {type === "DATA" ? (
                    <DataForm
                      type={type}
                      label={label}
                      field={field}
                      title={title}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchData}
                      setCurrentType={setCurrentType}
                    />
                  ) : null}

                  {type === "SEARCH_DOCUMENT" ? (
                    <DocumentSearchForm
                      type={type}
                      field={field}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchDocument}
                      documentOptions={documentCollectionOptions}
                      availableSearchWidgetParams={availableSearchWidgetParams}
                      selectedCollection={selectedCollection}
                      option={option}
                      setOption={setOption}
                      setSelectedCollection={setSelectedCollection}
                      setOperator={setOperator}
                      operator={operator}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  ) : null}

                  {type === "COLLECTION_MANAGER" ? (
                    <DocumentSearchForm
                      type={type}
                      field={field}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchCollection}
                      documentOptions={documentCollectionOptions}
                      availableSearchWidgetParams={availableSearchWidgetParams}
                      selectedCollection={selectedCollection}
                      setSelectedCollection={setSelectedCollection}
                      option={option}
                      setOption={setOption}
                      operator={operator}
                      setOperator={setOperator}
                      searchValue={searchValue}
                      setSearchValue={setSearchValue}
                    />
                  ) : null}

                  {type === "BINGO_MF" ? (
                    <MolecularFormulaForm
                      dataSources={dataSources}
                      type={type}
                      currentType={currentType}
                      setCurrentType={setCurrentType}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchMolecularFormula}
                    />
                  ) : null}

                  {type === "BINGO_MW" ? (
                    <MolecularWeightForm
                      dataSources={dataSources}
                      type={type}
                      currentType={currentType}
                      targetValue={targetValue}
                      setCurrentType={setCurrentType}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchMolecularWeight}
                    />
                  ) : null}

                  {type === "GENERIC_DATA_SEARCH" ? (
                    <GenericDataSearchForm
                      type={type}
                      label={label}
                      field={field}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchGenericData}
                      availableWidgetParams={availableWidgetParams}
                      currentSelection={currentSelection}
                      setCurrentSelection={setCurrentSelection}
                      currentType={currentType}
                      setCurrentType={setCurrentType}
                    />
                  ) : null}

                  {type === "SEARCH_BY_ALL_PARAMETERS" ? (
                    <SearchByAllWidgetsForm
                      type={type}
                      targetValue={targetValue}
                      setTargetValue={setTargetValue}
                      onSearch={onSearchAllParameters}
                      setCurrentType={(t, up = false) => {
                        setCurrentType(t);
                        if (t !== currentType && !up) {
                          setResult(null);
                        }
                      }}
                      currentType={currentType}
                    />
                  ) : null}
                </div>
              )}
            </div>

            <div className="gx-d-flex">
              {type === "COLLECTION_MANAGER" && (
                <PlusCircleTwoTone
                  className="gx-mt-2 gx-mr-2 gx-pointer"
                  onClick={() => setShowDrawer(true)}
                />
              )}

              <FileExcelTwoTone
                className="gx-mt-2"
                twoToneColor="#52c41a"
                onClick={exportWidgetData}
              />
              <i
                className={`icon gx-pl-2 gx-mt-2 gx-pointer ${
                  open ? "icon-chevron-up" : "icon-chevron-down"
                }`}
                onClick={() => setOpen(!open)}
              />
            </div>
          </div>
        </div>

        {open && (
          <>
            <div className="ant-card-body widget-body gx-mt-2 gx-ml-3 gx-mr-3">
              {type === "SEARCH_BY_ID" ? (
                <Form
                  layout="horizontal"
                  ref={searchByIdFormRef}
                  form={form}
                  onValuesChange={handleFormValuesChange}
                  initialValues={{
                    name: "",
                    type: typeOptions[0].value,
                  }}
                >
                  <Form.Item
                    name="source"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select database",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      options={dataSourceOptions}
                      placeholder="Select database"
                      dropdownMatchSelectWidth={false}
                    />
                  </Form.Item>

                  <Form.Item
                    name="type"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select type",
                      },
                    ]}
                  >
                    <Select
                      size="small"
                      options={typeOptions}
                      defaultValue={typeOptions[0]?.value}
                      placeholder="Select type"
                      dropdownMatchSelectWidth={false}
                    />
                  </Form.Item>

                  {currentType === "list" ? (
                    <Form.Item
                      required
                      rules={[
                        {
                          required: true,
                          message: "Please select ids",
                        },
                      ]}
                    >
                      <InputTag
                        size="small"
                        ref={inputRef}
                        sourceName={defaultSourceName}
                        placeholder="Select ids"
                      />
                    </Form.Item>
                  ) : (
                    <>
                      <Form.Item
                        name="from"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please select start id",
                          },
                        ]}
                      >
                        <Input placeholder="Start Id" />
                      </Form.Item>
                      <Form.Item
                        name="to"
                        required
                        rules={[
                          {
                            required: true,
                            message: "Please select end id",
                          },
                        ]}
                      >
                        <Input placeholder="End Id" />
                      </Form.Item>
                    </>
                  )}

                  <Form.Item
                    name="widgets"
                    required
                    rules={[
                      {
                        required: true,
                        message: "Please select widgets",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Select widgets"
                      options={widgets}
                      mode="multiple"
                      optionFilterProp="label"
                      dropdownMatchSelectWidth={false}
                    />
                  </Form.Item>

                  <Form.Item>
                    <Button
                      disabled={loading}
                      type="primary"
                      size="small"
                      onClick={() => {
                        setCurrentPage(1);
                        setCurrentPageSize(DEFAULT_PAGE_SIZE);
                        onFinish();
                      }}
                    >
                      Search
                    </Button>
                  </Form.Item>
                </Form>
              ) : null}

              {(type === "DATA" ||
                type === "GENERIC_DATA_SEARCH" ||
                type === "SEARCH_BY_ALL_PARAMETERS") &&
              !_.isNil(result) ? (
                <>
                  {renderCompoundDataTable({
                    sources,
                    dataSource: result,
                    searchInput,
                    field: type === "DATA" ? field : currentSelection,
                    filteredResult,
                    setFilteredResult,
                    filterValue,
                    setFilterValue,
                    tableKey,
                    mapImages,
                    setMapImages,
                    forceUpdate,
                    availableWidgetParams,
                  })}
                </>
              ) : null}

              {type === "SEARCH_DOCUMENT" &&
              !_.isEmpty(documentWidgetResults) ? (
                <>
                  {renderDocumentTable({
                    dataSource: documentWidgetResults,
                    onSelect: (document) => {
                      setShowModal(true);
                      setCurrentDocument(document);
                    },
                    searchInput,
                    loading: documentLoading,
                    filterValue,
                    setFilterValue,
                    filteredResult,
                    setFilteredResult,
                    mapImages,
                    setMapImages,
                    forceUpdate,
                    showModal: true,
                    documentCollectionOptions,
                    field: currentSelection,
                    sources,
                    setShowEditDocument: (doc) => {
                      setCurrentEditDocument(doc);

                      setDefaultEntityIds(doc?.entityIds || []);
                      setShowEditDocument(true);
                    },
                    onChangePagination: (newPage, newPageSize) => {
                      setCurrentPage(newPage);
                      setCurrentPageSize(newPageSize);

                      const resultsPaginated = paginate(
                        documentTableData,
                        newPageSize,
                        newPage,
                      );

                      dispatch(
                        resetDocumentDetailSearch({
                          total: Math.min(
                            currentPageSize,
                            (documentTableData || []).length,
                          ),
                        }),
                      );
                      if (resultsPaginated && _.isArray(resultsPaginated)) {
                        _.forEach(resultsPaginated, (result) => {
                          dispatch(fetchDocumentDetail(result));
                        });
                      }
                    },
                    total: (documentTableData || []).length,
                    page: currentPage,
                    pageSize: currentPageSize,
                  })}
                </>
              ) : null}

              {type === "COLLECTION_MANAGER" ? (
                <CollectionManager
                  selectedCollection={selectedCollection}
                  selectedSource={selectedSources}
                  dataSource={collectionWidgetResults}
                  loading={loadingCollection}
                  pageType={pageType}
                  documentCollectionOptions={documentCollectionOptions}
                  showDrawer={showDrawer}
                  setShowDrawer={setShowDrawer}
                  setLoading={() => {}}
                  updateDocuments={updateDocuments}
                  field={option}
                  targetValue={targetValue}
                  dataSources={dataSources}
                />
              ) : null}

              {type === "SEARCH_BY_ID" && !loading && !_.isEmpty(result)
                ? renderSearchByTable({
                    dataSource: result,
                    sources,
                    availableWidgetParams,
                    availableWidgets,
                    genericData,
                    currentPage,
                    currentPageSize,
                    onChangeTable: onChangeSearchByIdTable,
                    imageMap,
                    totalItems,
                  })
                : null}

              {!_.isEmpty(dataSource) &&
                type !== "GENERIC_DATA_SEARCH" &&
                type !== "SEARCH_BY_ID" &&
                type !== "DATA" &&
                type !== "SEARCH_DOCUMENT" &&
                type !== "SEARCH_BY_ALL_PARAMETERS" && (
                  <div>
                    <div
                      className="gx-text-success gx-mb-2"
                      style={{ textAlign: "right" }}
                    >
                      {dataSource.length}{" "}
                      {pluralize("result", dataSource.length)}
                    </div>
                    <Table
                      className="gx-table-responsive"
                      dataSource={dataSource}
                      pagination={{
                        pageSizeOptions: [5, 10, 15, 20],
                      }}
                      onChange={(
                        page,
                        filters,
                        sorter,
                        { currentDataSource },
                      ) => {
                        renderImage({
                          currentPage: page.current,
                          pageSize: page.pageSize,
                          searchResults: currentDataSource,
                          mapImages,
                          setMapImages,
                          forceUpdate,
                        });
                      }}
                      columns={[
                        {
                          title: "Compound ID",
                          data: "id",
                          key: "id",
                          width: 150,
                          sorter: (a, b) =>
                            (a?.vendorId || "").localeCompare(
                              b?.vendorId || "",
                            ),

                          render: (p, { source, vendorId }) => {
                            return (
                              <Link
                                to={`/${source}/compound/${vendorId}`}
                                replace
                              >
                                {vendorId}
                              </Link>
                            );
                          },
                        },
                        {
                          title: "Structure",
                          dataIndex: "structure",
                          key: "structure",
                          width: 150,
                          render: (_, { rmoId }) => {
                            return (
                              <>
                                {mapImages[rmoId] ? (
                                  <div className="search-page-image-preview">
                                    <ImageCarousel
                                      structureImage={[mapImages[rmoId]]}
                                      noBorder
                                      setVisible={() => {}}
                                      width={50}
                                      height={50}
                                    />
                                  </div>
                                ) : (
                                  <Spin />
                                )}
                              </>
                            );
                          },
                        },
                        {
                          title: "Source",
                          dataIndex: "source",
                          key: "source",
                          width: 150,
                          render: (_, { rmoId, source }) => {
                            return <span>{source}</span>;
                          },
                          sorter: (a, b) =>
                            (a?.source || "").localeCompare(b?.source || ""),
                          filterDropdown: ({
                            setSelectedKeys,
                            selectedKeys,
                            confirm,
                            clearFilters,
                            close,
                          }) => (
                            <TableFilter
                              searchInput={searchInput}
                              dataIndex={"compoundId"}
                              selectedKeys={selectedKeys}
                              setSelectedKeys={setSelectedKeys}
                              confirm={confirm}
                              clearFilters={clearFilters}
                              close={close}
                            />
                          ),
                          filterIcon: (filtered) => (
                            <SearchOutlined
                              style={{
                                color: filtered ? "#1890ff" : undefined,
                              }}
                            />
                          ),
                          onFilter: (value, record) => {
                            return (
                              checkField(value, record["vendorId"]) ||
                              checkField(value, record["source"])
                            );
                          },
                        },
                      ]}
                    ></Table>
                  </div>
                )}

              {isErrorExists && _.isEmpty(dataSource) ? (
                <span className="gx-text-danger">
                  {(isErrorExists?.error || "").split("bingo:")[1] ||
                    "A search error has occurred."}
                </span>
              ) : null}

              {!(dataSource || []).length &&
              result &&
              type !== "DATA" &&
              type !== "SEARCH_DOCUMENT" &&
              type !== "SEARCH_BY_ID" &&
              type !== "GENERIC_DATA_SEARCH" &&
              !loading &&
              !isErrorExists ? (
                <span
                  className="gx-text-warning"
                  style={{ marginLeft: "-15px" }}
                >
                  0 search results.
                </span>
              ) : null}
            </div>
          </>
        )}
      </div>
    );
  };

  const widgetOptions = _.find(documentCollectionOptions, {
    value: currentDocument?.collection,
  });

  const fileDetailRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => fileDetailRef.current,
  });

  const metaWidgetRef = useRef();
  const formRef = useRef();
  const entityIdsRef = useRef();
  const inputTagRef = useRef();
  const ownersTagRef = useRef();

  const onSaveFile = () => {
    const collection = formRef.current.getFieldValue("collection");
    const description = formRef.current.getFieldValue("description");
    const url = formRef.current.getFieldValue("url");
    const tags = inputTagRef.current.changeVal();
    const owners = ownersTagRef.current.getValues();
    const entityIds = entityIdsRef.current.changeVal();

    const metaWidgetForm = metaWidgetRef.current?.getMetaWidgetForm();

    const { id, sourceName } = currentEditDocument;

    const metaSubjects = _.map(metaWidgetForm, (k, v) => {
      return {
        key: v,
        value: k,
      };
    }).filter((item) => item?.field !== "tag");

    const searchSubjects = tags.map((tag) => ({
      key: "tag",
      value: tag,
    }));

    const targetSearchSubjects = _.uniq([...searchSubjects, ...metaSubjects]);

    editDocument(
      id,
      sourceName,
      currentEditDocument?.id,
      {
        entityIds: _.uniq(entityIds),
        collection: collection,
        description,
        owners,
        searchSubjects: targetSearchSubjects,
        uri: url,
      },
      () => {
        setShowEditDocument(false);
        NotificationManager.success("Document has been updated.");
      },
      (e) => {
        setShowModal(false);
        NotificationManager.error(e);
      },
      setLoading,
    );
  };

  const renderEditdrawer = () => {
    const { id, sourceName } = currentEditDocument;

    return (
      <div>
        <Form
          className="document-form gx-form-row0 gx-mt-3"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 20 }}
          autoComplete="off"
          ref={formRef}
        >
          <Form.Item
            label="Accession Number"
            name="accessionNumber"
            className="gx-mt-3"
          >
            <span>{currentDocument?.accessionNumber}</span>
          </Form.Item>
          <Form.Item name="entityIds" label="Shared with" className="gx-mt-3">
            <InputTag
              sourceName={sourceName}
              defaultValues={defaultEntityIds}
              ref={entityIdsRef}
            />
          </Form.Item>
          <Form.Item name="description" label="Description" className="gx-mt-3">
            <Input name="description" />
          </Form.Item>
          <Form.Item name="tags" label="Tags" className="gx-mt-3">
            <InputTag
              sourceName={sourceName}
              defaultValues={currentEditDocument?.tags || []}
              ref={inputTagRef}
            />
          </Form.Item>
          <Form.Item name="owners" label="Owners" className="gx-mt-3">
            <AutoCompleteSelect
              ref={ownersTagRef}
              defaultValue={
                (currentEditDocument?.owners || []).length
                  ? _.map(currentEditDocument?.owners || [], (item) => ({
                      label: item,
                      value: item,
                    }))
                  : {
                      label: account?.name || getRole(),
                      value: account?.username,
                    }
              }
            />
          </Form.Item>
        </Form>

        <MetaWidget
          ref={metaWidgetRef}
          id={id}
          systemWidgetName={targetValue || selectedCollection}
          onSaveWidget={(form) => {}}
          renderResidualData
          sourceName={sourceName}
          pageType={PAGE_TYPES.COMPOUND}
          modalView
          document={currentEditDocument}
          widgetOptions={widgetOptions}
          collectionOptions={documentCollectionOptions}
        />

        <Button type="primary" className="gx-mt-2" onClick={onSaveFile}>
          Save
        </Button>
      </div>
    );
  };

  return (
    <>
      {renderContent()}

      <Modal
        open={showModal}
        title={
          <div>
            <span className="gx-mr-3">Document Detail</span>
            <PrinterTwoTone onClick={handlePrint} />
          </div>
        }
        onCancel={() => setShowModal(false)}
        onOk={() => {
          setShowModal(false);
        }}
      >
        <FileDetailComponent
          ref={fileDetailRef}
          currentDocument={currentDocument}
          widgetOptions={widgetOptions}
          selectedDocument={currentDocument}
          sourceName={currentDocument?.source}
          collectionOptions={documentCollectionOptions}
          fileDetailRef={fileDetailRef}
        />
      </Modal>

      <Drawer
        placement="right"
        open={showEditDocument}
        title="Edit File"
        size="large"
        onClose={() => {
          setShowEditDocument(false);
        }}
      >
        {renderEditdrawer()}
      </Drawer>
    </>
  );
};

export default SearchWidget;
