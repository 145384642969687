import React from "react";
import { Col, Divider, Form, Input, Switch } from "antd";
import PT from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import { DOCUMENT_COLLECTION_OPTIONS } from "../../../../../constants/Config";

const UrlForm = ({ onChangeValue, form, roles, settingsWidgetName }) => {
  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  return (
    <>
      <Col>
        <Form.Item
          label="Display Field Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="baseUrl"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            value={form?.baseUrl}
            onChange={(e) => onChangeValue("baseUrl", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Params"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            style={{ height: "20px" }}
            value={
              _.isObject(form?.params)
                ? JSON.stringify(form?.params)
                : form?.params
            }
            onChange={(e) => onChangeValue("params", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Headers"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            style={{ height: "20px" }}
            value={
              _.isObject(form?.headers)
                ? JSON.stringify(form?.headers)
                : form?.headers
            }
            onChange={(e) => onChangeValue("headers", e.target.value)}
          />
        </Form.Item>
      </Col>

      <Divider className="gx-text-primary">Additional Settings</Divider>
      <Col className="gx-d-flex">
        <Form.Item
          label="Is Copiable"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.isCopiable}
            onChange={(e) => onChangeValue("isCopiable", e)}
          />
        </Form.Item>

        {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
          <Form.Item
            label="is Header Field"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isInlineField") || false}
              onChange={(e) => onChangeValue("isInlineField", e)}
            />
          </Form.Item>
        ) : null}
        <Form.Item
          label="Display as one line"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.isTrimmed || false}
            onChange={(e) => onChangeValue("isTrimmed", e)}
          />
        </Form.Item>
        <Form.Item
          label="Has Tooltip"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.hasTooltip}
            onChange={(e) => onChangeValue("hasTooltip", e)}
          />
        </Form.Item>

        {form?.hasTooltip ? (
          <Form.Item
            label="Tooltip"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={form?.info}
              onChange={(e) => onChangeValue("info", e.target.value)}
            />
          </Form.Item>
        ) : null}
      </Col>

      <Divider className="gx-text-primary">Roles Settings</Divider>

      <div>
        <p className="gx-mb-3">Access Roles:</p>

        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isAccessible, role?.id) || false}
                onChange={(e) => onChangeAccessRole(role?.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>
    </>
  );
};

UrlForm.defaultProps = {
  form: {},
};

UrlForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default UrlForm;
