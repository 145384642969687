import { QuestionCircleTwoTone, SettingOutlined } from "@ant-design/icons";
import { Divider, Popover, Modal, Spin, Pagination, List } from "antd";
import _ from "lodash";
import React from "react";
import {
  downloadDocument,
  openDocumentNewTab,
} from "../../appRedux/services/Document";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";
import { Document, Page } from "react-pdf";
import { SaveTwoTone } from "@ant-design/icons";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";

import "./style.css";

const DocumentationPopover = ({
  documentations,
  sourceName,
  setOpenDocumentationSettings,
  hasPermission,
}) => {
  const [pdfData, setPdfData] = useState();
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [currentDocument, setCurrentDocument] = useState({});

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <>
      <div
        className="gx-flex-row gx-align-items-center gx-avatar-row gx-mr-3"
        style={{
          marginTop: "-14px",
        }}
      >
        <Popover
          placement="bottomRight"
          content={() => {
            return (
              <div className="gx-d-flex">
                {loading ? (
                  <>
                    <Spin className="gx-ml-2 gx-mt-2" />
                    <span>Loading Documentation</span>
                  </>
                ) : (
                  <List size="small">
                    {_.isEmpty(documentations || []) && (
                      <li className="gx-list-group">No documents.</li>
                    )}
                    {_.map(documentations, (item) => (
                      <List.Item
                        className="gx-pointer"
                        key={item?.documentId}
                        onClick={() => {
                          setCurrentDocument(item);
                          setLoading(true);
                          openDocumentNewTab(
                            item?.documentId,
                            sourceName,
                            item?.fileName,
                            (blob) => {
                              setPdfData(blob);
                              setShowModal(true);
                              setLoading(false);
                            }
                          );
                        }}
                      >
                        {item?.name}
                      </List.Item>
                    ))}

                    {hasPermission && (
                      <>
                        <List.Item
                          className="gx-d-flex"
                          style={{
                            whiteSpace: "nowrap",
                            width: "auto",
                            cursor: "pointer",
                          }}
                          onClick={setOpenDocumentationSettings}
                          key={uuidv4()}
                        >
                          <span className="gx-text-primary gx-mt-2">
                            <SettingOutlined className="gx-mr-2" />
                            Manage Documents
                          </span>
                        </List.Item>
                      </>
                    )}
                  </List>
                )}
              </div>
            );
          }}
          trigger="click"
        >
          <QuestionCircleTwoTone
            twoToneColor="#d48806"
            className="gx-pointer gx-ml-3 gx-mt-3 compound-detail-header-icon"
          />
        </Popover>
      </div>
      <Modal
        open={showModal}
        title={
          <div
            className="gx-d-flex"
            style={{ justifyContent: "space-between" }}
          >
            <div>
              <span>{currentDocument?.name}</span>

              <Popover content="Save document">
                <SaveTwoTone
                  twoToneColor="#52c41a"
                  className="gx-ml-2 gx-mt-1"
                  onClick={() =>
                    downloadDocument(
                      currentDocument?.documentId,
                      sourceName,
                      currentDocument?.fileName
                    )
                  }
                />
              </Popover>
            </div>
            <div
              className="gx-d-flex gx-mr-4"
              style={{ flexDirection: "column" }}
            >
              <p className="gx-text-primary" style={{ fontSize: "12px" }}>
                Page {pageNumber} of {numPages}
              </p>
            </div>
          </div>
        }
        width="fit-content"
        onCancel={() => setShowModal(false)}
        onOk={() => setShowModal(false)}
      >
        <div>
          <Document
            file={
              pdfData
                ? URL.createObjectURL(pdfData, { oneTimeOnly: true })
                : null
            }
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page pageNumber={pageNumber} width="800" />
          </Document>
          <Divider />
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <Pagination
              size="small"
              total={numPages}
              pageSize={1}
              onChange={(currentPage) => {
                setPageNumber(currentPage);
              }}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DocumentationPopover;
