import React, { useEffect, useState } from "react";
import ExportIcon from "../../../assets/vendors/export.svg";
import {
  getDocument,
  searchForTemplate,
} from "../../../appRedux/services/Document";
import { Form, Modal, Radio, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { getDocuments } from "../../../appRedux/actions/Document";
import { NotificationManager } from "react-notifications";

import {
  populateCompositionData,
  populateControls,
  populateProductsData,
  populateSafetySentences,
  renderReactionImage,
} from "../../../util/excel";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { useMsal } from "@azure/msal-react";
import _ from "lodash";
import "./style.css";

const workbook = new Excel.Workbook();

const ExportExcel = ({
  source,
  id,
  processComponents = [],
  additionalComponents = [],
  safetySentenceLanguageMap,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  childRef,
}) => {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);
  const [collections, setCollections] = useState([]);
  const [selectedOption, setSelectedOption] = useState();
  const [selectedDecimalSeparator, setSelectedDecimalSeparator] = useState(1);
  // const { collections } = useSelector(({ document }) => document);
  const lookup = useSelector(({ lookup }) => lookup);
  const { accounts } = useMsal();

  useEffect(() => {
    if (source && id) {
      dispatch(getDocuments(id, source));
    }

    searchForTemplate(setCollections);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const templatesOptions = (collections || []).map((template) => ({
    label: template?.name,
    value: template?.id,
  }));

  const exportData = async (documentId) => {
    getDocument(documentId, source, async (file) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      const components = [...processComponents, ...additionalComponents];
      const separator = selectedDecimalSeparator === 1 ? "," : ".";

      const products = _.filter(
        components,
        (component) =>
          (component?.role || component?.type || "").toLowerCase() === "product"
      );

      const substances = _.filter(
        components,
        (component) =>
          (component?.role || component?.type || "").toLowerCase() !== "product"
      );

      const substancesOrdered = _.sortBy(substances, (o) => {
        const role = o?.role || o?.type || "";
        if (role.toLowerCase() === "reactant") return 1;
        return 2;
      });

      reader.onload = async (evt) => {
        const bstr = evt.target.result;
        await workbook.xlsx.load(bstr);

        var dataUrl = childRef?.current?.getImageUrl();

        const imageId = workbook.addImage({
          base64: dataUrl,
          extension: "png",
        });

        let disposalColor = null;
        let storageColor = null;
        let preventionColor = null;
        let responseColor = null;

        const controlWorksheet = workbook.getWorksheet("controls");

        const rows = _.get(controlWorksheet, "_rows");
        _.forEach(rows, (row) => {
          const cells = _.get(row, "_cells");

          _.forEach(cells, (cell = {}) => {
            const cellName = cell?.name || "";

            if (cellName.includes("disposalsColor")) {
              disposalColor = cell?.html;
            }
            if (cellName.includes("storagesColor")) {
              storageColor = cell?.html;
            }
            if (cellName.includes("preventionsColor")) {
              preventionColor = cell?.html;
            }
            if (cellName.includes("responsesColor")) {
              responseColor = cell?.html;
            }
          });
        });

        // 1. Loop through worksheets
        _.forEach(workbook.worksheets, (worksheet) => {
          let compositionStartingCell = null;
          let imageStartingCellCol = null;
          let imageStartingCellRow = null;
          let safetyStartingCell = null;
          let compositionProductsStartingCell = null;
          let rows = _.get(worksheet, "_rows");
          _.forEach(rows, (row) => {
            const cells = _.get(row, "_cells");

            _.forEach(cells, (cell = {}) => {
              const cellName = cell?.name || "";
              if (cellName.includes("compositions")) {
                compositionStartingCell = cell?.row;
              }

              if (cellName.includes("image")) {
                imageStartingCellCol = _.get(cell, "col");
                imageStartingCellRow = _.get(cell, "row");
              }
            });
          });

          // Populate composition table if exists. (Filtered products from list)
          if (compositionStartingCell) {
            populateCompositionData(
              worksheet,
              substancesOrdered,
              lookup,
              compositionStartingCell + 1,
              separator
            );
          }

          // Populate Image Rendering
          if (imageStartingCellRow && imageStartingCellCol) {
            renderReactionImage({
              imageId,
              targetSheet: worksheet,
              col: imageStartingCellCol + 2,
              row: imageStartingCellRow,
            });
          }

          rows = _.get(worksheet, "_rows");
          _.forEach(rows, (row) => {
            const cells = _.get(row, "_cells");

            _.forEach(cells, (cell = {}) => {
              const cellName = cell?.name || "";

              if (cellName.includes("safetySentences")) {
                safetyStartingCell = cell?.row;
              }
            });
          });

          // Populate Safety Sentences
          if (safetyStartingCell) {
            populateSafetySentences(
              worksheet,
              components,
              lookup,
              "__EMPTY",
              safetySentenceLanguageMap,
              safetySentenceTypes,
              safetySentenceImageMetadata,
              workbook,
              safetyStartingCell,
              disposalColor,
              storageColor,
              preventionColor,
              responseColor
            );
          }

          rows = _.get(worksheet, "_rows");
          _.forEach(rows, (row) => {
            const cells = _.get(row, "_cells");

            _.forEach(cells, (cell = {}) => {
              const cellName = cell?.name || "";

              if (cellName.includes("compositionProducts")) {
                compositionProductsStartingCell = cell?.row;
              }
            });
          });

          // Populate Products table
          if (compositionProductsStartingCell) {
            populateProductsData(
              worksheet,
              products,
              lookup,
              compositionProductsStartingCell + 1,
              compositionStartingCell,
              separator
            );
          }

          // Populate control variables
          if (worksheet.name === "controls") {
            populateControls(worksheet, id, accounts[0].username);
          }
        });

        try {
          // writing to file.
          const fileType =
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
          const buffer = await workbook.xlsx.writeBuffer();
          const blob = new Blob([buffer], { type: fileType });
          saveAs(blob, `${id}.xlsx`);
        } catch (error) {
          NotificationManager.error("There was error when exporting data.");
        }
      };
    });
  };

  console.log("templatesOptions", templatesOptions, collections);

  const renderModal = () => {
    return (
      <div className="gx-mt-4">
        <Form>
          <Form.Item label="Select Template">
            <Select
              options={templatesOptions}
              onSelect={(option) => {
                setSelectedOption(option);
              }}
            ></Select>
          </Form.Item>
          <Form.Item label="Decimal Separator">
            <Radio.Group
              value={selectedDecimalSeparator}
              onChange={(e) => setSelectedDecimalSeparator(e.target.value)}
            >
              <Radio value={1}>Period</Radio>
              <Radio value={2}>Comma</Radio>
            </Radio.Group>
          </Form.Item>
        </Form>
      </div>
    );
  };

  return (
    <>
      <img
        src={ExportIcon}
        alt="export experiment"
        width={20}
        height={20}
        className="gx-ml-3 gx-mr-2 export-icon gx-pointer"
        onClick={() => setShowModal(true)}
      />

      <Modal
        visible={showModal}
        title="Templates"
        okText="Download"
        onOk={() => {
          exportData(selectedOption);
          setShowModal(false);
        }}
        onCancel={() => setShowModal(false)}
      >
        {renderModal()}
      </Modal>
    </>
  );
};

export default ExportExcel;
