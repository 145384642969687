import axios from "axios";
import { config, PAGE_TYPES } from "../../constants/Config";
import _ from "lodash";

/**
 * get compound instance
 * @param {String} id
 * @param {String} sourceName
 * @param {Function} setFn
 */
export const getCompoundInstance = async (
  id,
  sourceName,
  setFn,
  pageType,
  version = null,
  setErrFn = () => {},
  setLoading = () => {}
) => {
  setLoading(true);
  if (
    pageType === PAGE_TYPES.QUALITY ||
    pageType === PAGE_TYPES.COMPOUND ||
    pageType === PAGE_TYPES.SAMPLE
  ) {
    return axios
      .get(
        `${
          config.SRD_API_URL
        }/compoundInstance?id=${id}&source%20name=${sourceName}${
          version ? "&version=" + version : ""
        }`,
        "",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setFn(result.data);
      })
      .catch(() => {
        setErrFn();
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (pageType === PAGE_TYPES.EXPERIMENT) {
    return axios
      .get(
        `${config.SRD_API_URL}/experiment/${id}?source%20name=${sourceName}${
          version ? "&version=" + version : ""
        }`,
        "",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setFn(result.data);
      })
      .finally(() => {
        setLoading(false);
      });
  } else {
    return axios
      .get(
        `${config.SRD_API_URL}/process?id=${id}&source%20name=${sourceName}${
          version ? "&version=" + version : ""
        }`,
        "",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        setFn(result.data);
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

export const getProcessComponents = async (
  id,
  sourceName,
  setFn,
  setProcessDetail,
  pageType,
  setProcessVersions = () => {},
  setCurrentProcessVersion = () => {},
  version = null,
  updateTotal = true
) => {
  const url = pageType === "quality" ? "compoundInstance" : "process";
  const idParam = pageType === "quality" ? "id" : "id";

  let fullUrl = `${config.SRD_API_URL}/${url}?${idParam}=${id}&source%20name=${sourceName}`;

  if (version) {
    fullUrl += `&version=${version}`;
  }

  return axios
    .get(fullUrl, "", {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      setFn(result.data?.processComponents);
      setProcessDetail(result.data);
      if (updateTotal) {
        setProcessVersions(
          _.times(result.data?.version || 1, (index) => index + 1)
        );
        setCurrentProcessVersion(result.data?.version || 1);
      }
    });
};

export const saveCompoundInstance = async (
  id,
  sourceName,
  body,
  pageType,
  create = false,
  userName = null
) => {
  if (create) {
    if (
      pageType === PAGE_TYPES.QUALITY ||
      pageType === PAGE_TYPES.COMPOUND ||
      pageType === PAGE_TYPES.SAMPLE
    ) {
      return axios.post(
        `${config.SRD_API_URL}/compoundInstance?instanceId=${id}&sourceName=${sourceName}&SRD_USER=${userName}`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      return axios.post(
        `${config.SRD_API_URL}/experiment/${id}?sourceName=${sourceName}&SRD_USER=${userName}`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    }
  } else {
    if (
      pageType === PAGE_TYPES.QUALITY ||
      pageType === PAGE_TYPES.COMPOUND ||
      pageType === PAGE_TYPES.SAMPLE
    ) {
      return axios.put(
        `${config.SRD_API_URL}/compoundInstance?instanceId=${id}&sourceName=${sourceName}&SRD_USER=${userName}`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    } else {
      return axios.put(
        `${config.SRD_API_URL}/experiment/${id}?sourceName=${sourceName}&SRD_USER=${userName}`,
        body,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
    }
  }
};

export const getAllCompoundInstancesData = async (
  instances,
  sourceName,
  setFn,
  targetWidgetName
) => {
  let promises = [];
  instances.forEach(async (instance) => {
    const { name } = instance;

    promises.push(
      axios.get(
        `${config.SRD_API_URL}/metadata/${targetWidgetName}?compoundId=${name}&sourceName=${sourceName}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "text/plain",
          },
        }
      )
    );
  });

  Promise.allSettled(promises).then((res) => {
    const fulfilledResults = res
      .filter((item) => item.status === "fulfilled")
      .map((item) => item?.value?.data[0]);

    setFn(fulfilledResults);
  });
};
