import _ from "lodash";
import {
  CREATE_PROJECT_FAILED,
  CREATE_PROJECT_REQUESTED,
  CREATE_PROJECT_SUCCESS,
  DELETE_PROJECT_FAILED,
  DELETE_PROJECT_REQUESTED,
  DELETE_PROJECT_SUCCESS,
  FETCH_STEP_NO_IMAGES_REQUESTED,
  FETCH_STEP_NO_IMAGES_SUCCESS,
  GET_PROJECTS_FAILED,
  GET_PROJECTS_REQUESTED,
  GET_PROJECTS_SUCCESS,
  PUT_PROJECT_FAILED,
  PUT_PROJECT_REQUESTED,
  PUT_PROJECT_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  triggerUpdate: false,
  projects: [],
  imageMap: {},
};

const ProjectsReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PROJECTS_REQUESTED: {
      return {
        ...state,
        projects: [],
        loading: true,
        triggerUpdate: false,
      };
    }
    case GET_PROJECTS_SUCCESS: {
      return {
        ...state,
        projects: action.payload,
        loading: false,
      };
    }
    case GET_PROJECTS_FAILED: {
      return {
        ...state,
        projects: [],
        loading: false,
      };
    }
    case DELETE_PROJECT_REQUESTED:
    case CREATE_PROJECT_REQUESTED:
    case PUT_PROJECT_REQUESTED: {
      return {
        ...state,
        loading: true,
      };
    }
    case DELETE_PROJECT_SUCCESS:
    case CREATE_PROJECT_SUCCESS:
    case PUT_PROJECT_SUCCESS: {
      return {
        ...state,
        loading: false,
        triggerUpdate: true,
        projects: _.map(state.projects, (project) =>
          project.id === action.payload.id ? action.payload : project
        ),
      };
    }
    case DELETE_PROJECT_FAILED:
    case PUT_PROJECT_FAILED:
    case CREATE_PROJECT_FAILED: {
      return {
        ...state,
        loading: false,
      };
    }
    case FETCH_STEP_NO_IMAGES_REQUESTED: {
      return {
        ...state,
      };
    }
    case FETCH_STEP_NO_IMAGES_SUCCESS: {
      return {
        ...state,
        imageMap: {
          ...state.imageMap,
          [action.payload.processId]: action.payload.image,
        },
      };
    }
    default:
      return state;
  }
};

export default ProjectsReducer;
