import React from "react";
import PT from "prop-types";
import { Switch } from "antd";
import _ from "lodash";

const SwitchForm = ({ role, sourceName, accessIdentifier, onChangeAccess }) => {
  const accessArray = (_.get(role, accessIdentifier) || []).map((item) =>
    item.toLowerCase()
  );

  const hasAccess = accessArray.includes(sourceName.toLowerCase());

  return (
    <div
      key={role?.id}
      className="gx-mt-1 gx-d-flex gx-justify-content-between gx-width-200"
    >
      <span>{role?.displayName}</span>
      <Switch
        size="small"
        checked={hasAccess}
        onChange={(val) => {
          onChangeAccess(sourceName, role, val, accessIdentifier);
        }}
      />
    </div>
  );
};

export default SwitchForm;

SwitchForm.defaultProps = {
  role: {},
  sourceName: "",
  accessIdentifier: "",
  isDatabase: false,
};

SwitchForm.propTypes = {
  role: PT.shape(),
  sourceName: PT.string,
  accessIdentifiers: PT.string,
  onChangeAccess: PT.func.isRequired,
};
