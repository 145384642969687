/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Divider, Drawer, Form, Input, Select, Switch } from "antd";
import CircularProgress from "components/CircularProgress";
import React, { useEffect, useRef, useState } from "react";
import SweetAlert from "react-bootstrap-sweetalert";

import {
  BarcodeOutlined,
  CheckOutlined,
  DatabaseOutlined,
  ExclamationCircleTwoTone,
  SaveOutlined,
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import FormItem from "antd/lib/form/FormItem";
import TextArea from "antd/lib/input/TextArea";
import { Option } from "antd/lib/mentions";
import { autoAssign } from "appRedux/actions/StructureRegistration";
import CompoundInputField from "components/Registration/CompoundInputField";
import DrawingResult from "components/Registration/DrawingResult";
import ExactMatchesCard from "components/Registration/ExactMatchesCard";
import RegisterCard from "components/Registration/RegisterCard";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import {
  getDataSources,
  getSystemWidgets,
  saveMetadata,
  saveUserData,
  setCurrentWidgets,
} from "../../appRedux/actions";
import {
  checkUniqueness,
  clearErrorMessage,
  clearRegisterData,
  generateImage,
  register,
  update,
  updateRecentCompound,
} from "../../appRedux/actions/StructureRegistration";
import { getCompoundConcept } from "../../appRedux/services/Metadata";
import {
  getSystemWidget,
  getUserWidget,
} from "../../appRedux/services/SystemWidget";
import {
  addCompoundInstance,
  getCompoundConceptShallow,
  getSmiles,
} from "../../appRedux/services/Widget";
import Widget from "../../components/DetailPage/Widget";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import GenericEditor from "../../components/GenericEditor";
import HelmEditor from "../../components/HelmEditor";
import PanelPortal from "../../components/PanelPortal";
import CopiableRowItem from "../../components/Registration/CopiableRowItem";
import UpdateCard from "../../components/Registration/UpdateCard";
import UpdateResult from "../../components/Registration/UpdateResult";
import {
  COORDINATIONS_WIDGET_PARAMS,
  CURRENT_EDITOR_OPTION,
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DIASTEREOMERS_WIDGET_PARAMS,
  DOCUMENT_COLLECTION_OPTIONS,
  DUPLICATE_REGISTRATION_USERS,
  EDITOR_OPTIONS,
  ENANTIOMERS_WIDGET_PARAMS,
  GEOMETRIC_ISOMERS_WIDGET_PARAMS,
  IDENTIFIER_SEPERATOR,
  ISOMER_WIDGET_PARAMS,
  ISOTOPES_WIDGET_PARAMS,
  RACEMATE_WIDGET_PARAMS,
  RECENT_REGISTERED_COMPOUND,
  REGISTRATION_DATA_WIDGET_NAME,
  SYSTEM_WIDGETS_METADATA,
  TAUTOMERS_WIDGET_PARAMS,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
} from "../../constants/Config";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import { getPrefix } from "../../util/Widget";
import { getRole } from "../../util/auth";
import { filterByAuthorizedRoles } from "../../util/search";
import { checkMolEmpty } from "../../util/url";
import {
  getCompoundConceptApi,
  searchDocumentation,
} from "../UploadPage/helper";
import "./style.css";

const renderStatus = ({ value, step }) => {
  let currentIcon;

  switch (step) {
    case 1:
      currentIcon = <CheckOutlined className="ant-steps-item-icon" />;
      break;
    case 2:
      currentIcon = <BarcodeOutlined className="ant-steps-item-icon" />;
      break;
    case 3:
      currentIcon = <SaveOutlined className="ant-steps-item-icon" />;
      break;
    default:
      currentIcon = <DatabaseOutlined className="ant-steps-item-icon" />;
      break;
  }

  return (
    <>
      {(value || "").length ? (
        <div className="gx-d-flex gx-mb-3">
          {currentIcon}
          <span
            className="gx-text-primary gx-ml-2"
            style={{ marginTop: "5px" }}
          >
            {value}
          </span>
        </div>
      ) : null}
    </>
  );
};

const filterByUpdateId = ({
  updateMode,
  targetArray,
  dataSources,
  selectedDataSource,
  compoundIdFormRef,
}) => {
  return updateMode
    ? _.filter(
        targetArray,
        (id) =>
          !_.isEqual(
            id,
            `${getPrefix(
              dataSources,
              selectedDataSource,
            )}-${compoundIdFormRef?.current?.getFieldValue("compoundId")}`,
          ),
      )
    : targetArray;
};

const RegisterNewCompound = () => {
  const dispatch = useDispatch();
  // const [roles, setRoles] = useState([]);
  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [recentCompounds, setRecentCompounds] = useState([]);

  const [isKnown, setIsKnown] = useState(true);
  const [result, setResult] = useState("");
  const [value, setValue] = useState("");
  const [message, setMessage] = useState("");
  const [registering, setRegistering] = useState(false);
  const { dataSources } = useSelector(({ search }) => search);
  // eslint-disable-next-line no-unused-vars
  const { registerPageEditor } = useSelector(
    ({ currentUserData }) => currentUserData,
  );
  const [currentStep, setCurrentStep] = useState(0);
  const [documentations, setDocumentations] = useState([]);
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);
  const [isIsomerRegistered, setIsIsomerRegistered] = useState(false);
  const [molecularFormula, setMolecularFormula] = useState();
  const [molecularWeight, setMolecularWeight] = useState();
  const [originalMolecularFormula, setOriginalMolecularFormula] = useState();
  const [originalMolecularWeight, setOriginalMolecularWeight] = useState();
  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const [isProcess, setIsProcess] = useState(false);
  const [showHelm, setShowHelm] = useState(false);
  const [showPanel, setShowPanel] = useState(true);
  const sidebarRef = useRef(null);
  const [isResizing, setIsResizing] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState("57%");
  const [defaultEditor, setDefaultEditor] = useState();
  const MAX_WIDTH = 876;

  const startResizing = React.useCallback((mouseDownEvent) => {
    setIsResizing(true);
  }, []);

  const stopResizing = React.useCallback(() => {
    setIsResizing(false);
  }, []);

  const resize = React.useCallback(
    (mouseMoveEvent) => {
      const currentWidth =
        mouseMoveEvent.clientX -
        sidebarRef.current.getBoundingClientRect().left;

      if (isResizing && currentWidth >= MAX_WIDTH) {
        setSidebarWidth(currentWidth);
      }
    },
    [isResizing],
  );

  React.useEffect(() => {
    window.addEventListener("mousemove", resize);
    window.addEventListener("mouseup", stopResizing);
    return () => {
      window.removeEventListener("mousemove", resize);
      window.removeEventListener("mouseup", stopResizing);
    };
  }, [resize, stopResizing]);

  const inputModeFormRef = useRef();

  const VIEW_TYPES = {
    CANVAS: 1,
    INPUT: 2,
  };

  const [currentView, setCurrentView] = useState(VIEW_TYPES.CANVAS);

  const [showIdInput, setShowIdInput] = useState(false);

  const [currentCompoundDetail, setCurrentCompoundDetail] = useState({});

  const [updateMode, setUpdateMode] = useState(false);
  const [isRegisterAllowed, setIsRegisterAllowed] = useState(false);
  const [idValue, setIdValue] = useState();

  const [helmEditorSettings, setHelmEditorSettings] = useState(false);
  const [userEmails, setUserEmails] = useState([]);

  const formRef = useRef();

  const compoundIdFormRef = useRef();

  const structureRegistration = useSelector(
    ({ structureRegistration }) => structureRegistration,
  );

  const {
    structure,
    exactMatches,
    enantiomer,
    diastereomer,
    isUnique,
    structureImage,
    loading,
    errorMessage,
    registerIds,
    updateIds = [],
    noStructure,
  } = structureRegistration;

  const isomers = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.isomers,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });

  const isotope = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.isotope,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });

  const geometricIsomer = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.geometricIsomer,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });

  const coordination = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.coordination,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });
  const racemate = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.racemate,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });
  const tautomer = filterByUpdateId({
    updateMode,
    targetArray: structureRegistration?.tautomer,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });

  const roles = useGetRoles();

  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const childRef = useRef();
  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const userNameWithRole = `${userName}-${getRole()}`;
  const pageName = "compound-registration";

  const curatorRoles = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "curateDatabase",
  );

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: "",
        database: "",
      }),
    );
  }, []);

  const isCurator =
    _.findIndex(curatorRoles || [], { sourceName: selectedDataSource }) !== -1;

  useEffect(() => {
    setSidebarWidth(showHelm ? "100%" : "57%");
  }, [showHelm]);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
  }, []);

  useEffect(() => {
    getSystemWidget(DUPLICATE_REGISTRATION_USERS, (data) => {
      setUserEmails(data);
    });
  }, []);

  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      pageName,
      setDocumentations,
    );
  }, []);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
  }, []);

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        setCurrentEditor(currentOption?.option);
        setHelmEditorSettings(currentOption?.helmEditor);
      },
      false,
    );
  }, []);

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userNameWithRole,
      (option) => {
        setSelectedDataSource(option?.registration);
        if (option?.defaultEditor) {
          setDefaultEditor(option?.defaultEditor);
        }
      },
      false,
      () => {
        setSelectedDataSource("");
      },
    );
  }, []);

  useEffect(() => {
    if (defaultEditor === "helm" && helmEditorSettings) {
      setShowHelm(true);
    }
  }, [defaultEditor]);

  const handleStructure = () => {
    if (
      structure.geometricIsomer === "defined" ||
      structure.geometricIsomer === "undefined"
    ) {
      setMessage(
        `Structure is Geometric Isomer with ${structure.geometricIsomer} stereo bonds.`,
      );
    }

    if (structure.achiral === true) {
      setMessage(`Structure is achiral`);
    }

    if (structure.isotope === true) {
      setMessage(`Structure is isotope`);
    }

    if (
      structure.opticalIsomer === "defined" ||
      structure.opticalIsomer === "undefined"
    ) {
      setMessage(
        `The structure is an optical isomer with ${structure.opticalIsomer} stereo atoms.`,
      );
    }
  };

  const clearStates = () => {
    dispatch(clearRegisterData());
    dispatch(clearErrorMessage());
    setResult("");
    setMessage("");
    setValue("");
    setIsIsomerRegistered(false);
    setMolecularFormula(null);
    setMolecularWeight(null);
    setOriginalMolecularFormula(null);
    setOriginalMolecularWeight(null);
    setIsIsomerRegistered(false);
    // setHasUpdateOnCanvas(false);
    // setCurrentView(VIEW_TYPES.CANVAS);
    // setShowIdInput(false);
  };

  useEffect(() => {
    setMessage("");
    clearStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!(recentCompounds || []).length) {
      getSystemWidget(RECENT_REGISTERED_COMPOUND, setRecentCompounds, true);
    }
  }, []);

  // useEffect(() => {
  //   if (childRef.current?.isBlankStructure() && childRef.current?.loadCDXML) {
  //     setTimeout(() => {
  //       childRef.current?.loadCDXML(registerPageEditor);
  //     }, 500);
  //   }
  // }, [registerPageEditor]);

  useEffect(() => {
    if (!_.isEmpty(result)) {
      if (isKnown) {
        dispatch(autoAssign(result));
      }
      dispatch(
        checkUniqueness(isKnown ? result : value, selectedDataSource, isKnown),
      );
    }
  }, [result]);

  useEffect(() => {
    if (!_.isEmpty(structure)) {
      handleStructure(structure);
    } else {
      setMessage("");
    }
  }, [structure]);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }
  }, []);

  const availableDataSources = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "registrationDatabases",
  );

  useEffect(() => {
    if ((availableDataSources || []).length === 1 && !selectedDataSource) {
      setSelectedDataSource(availableDataSources[0]?.sourceName);
    }
  }, [availableDataSources]);

  const saveRegistrationData = (modDate) => {
    dispatch(
      saveMetadata(
        registerIds[0]?.compoundId,
        {
          registrar: accounts[0]?.username,
          registrationType: "interactive",
          registrationDate: modDate || new Date(),
        },
        REGISTRATION_DATA_WIDGET_NAME,
        selectedDataSource,
      ),
    );
  };

  useEffect(() => {
    if ((registerIds || []).length) {
      getCompoundConcept(
        registerIds[0]?.compoundId,
        selectedDataSource,
        null,
        (compoundConcept) => {
          setCurrentCompoundDetail(compoundConcept);
          saveRegistrationData(compoundConcept?.modDate);
        },
        () => {
          saveRegistrationData(new Date());
        },
      );
    }
  }, [registerIds]);

  const generateCompoundImage = (id) => {
    if (!structureImage.length && !errorMessage && isKnown) {
      const backgroundColor = "transbg";
      // const target = isKnown ? result : value;
      dispatch(generateImage(id, backgroundColor, selectedDataSource));
    }
  };

  useEffect(() => {
    if ((registerIds || []).length && structureImage) {
      // Register Last 5 registered compounds
      dispatch(
        updateRecentCompound(recentCompounds, {
          dataSource: selectedDataSource || availableDataSources[0]?.sourceName,
          registrar: accounts[0]?.username,
          compoundId: registerIds[0]?.compoundId,
          imageUrl: structureImage,
        }),
      );
    }
  }, [structureImage]);

  const registerCompound = ({ id }) => {
    // let molecularWeight = null;
    // let molecularFormula = null;

    if (!isKnown) {
      // molecularWeight = formRef.current.getFieldValue("molecularWeight");
      // molecularFormula = formRef.current.getFieldValue("molecularFormula");
    }

    dispatch(
      register(
        isKnown ? result : value,
        selectedDataSource,
        false,
        isKnown,
        "",
        molecularWeight,
        molecularFormula,
        userName,
        id,
      ),
    );
    setIsRegisterAllowed(false);
  };

  const isUndefinedStereoAtoms =
    isUnique === false && structure.opticalIsomer === "undefined";

  const canRegisterAsRacemate =
    isUnique && structure.opticalIsomer === "undefined";

  const onRegisterNewQuality = (id) => {
    setRegistering(true);
    addCompoundInstance(id, selectedDataSource, (apiResult) => {
      const { instanceId } = apiResult;
      window.open(`${selectedDataSource}/quality/${instanceId}`, "_blank");
    }).finally(() => {
      setRegistering(false);
    });
  };

  const registerNewType = (dataSource, typeName, id) => {
    dispatch(
      register(
        result,
        dataSource,
        true,
        isKnown,
        typeName,
        null,
        null,
        userName,
        id,
      ),
    );
    setIsIsomerRegistered(true);
  };

  const onSaveTraits = (id, form, metadataName) => {
    dispatch(saveMetadata(id, form || {}, metadataName, selectedDataSource));
    NotificationManager.success("Widget saved successfully.");
  };

  let availableWidgets = [...systemWidgets, ...dataWidgets];

  const getWidgetData = (systemWidgetName) => {
    const targetWidget =
      _.find(availableWidgets, { value: systemWidgetName }) || {};

    return targetWidget?.widget;
  };

  const duplicatesMappings = [
    {
      name: racemate,
      systemWidgetName: RACEMATE_WIDGET_PARAMS,
      targetValue: "racemates",
    },
    {
      name: coordination,
      systemWidgetName: COORDINATIONS_WIDGET_PARAMS,
      targetValue: "coordinations",
    },
    {
      name: diastereomer,
      systemWidgetName: DIASTEREOMERS_WIDGET_PARAMS,
      targetValue: "diastereomers",
    },
    {
      name: enantiomer,
      systemWidgetName: ENANTIOMERS_WIDGET_PARAMS,
      targetValue: "enantiomers",
    },
    {
      name: geometricIsomer,
      systemWidgetName: GEOMETRIC_ISOMERS_WIDGET_PARAMS,
      targetValue: "geometricIsomers",
    },
    {
      name: isotope,
      systemWidgetName: ISOTOPES_WIDGET_PARAMS,
      targetValue: "isotopes",
    },
    {
      name: isomers,
      systemWidgetName: ISOMER_WIDGET_PARAMS,
      targetValue: "isomers",
    },
    {
      name: tautomer,
      systemWidgetName: TAUTOMERS_WIDGET_PARAMS,
      targetValue: "tautomers",
    },
  ];

  const validateStructure = async () => {
    // if (isKnown) {
    if (childRef.current?.isBlankStructure()) {
      setIsKnown(false);
      setCurrentStep(0);
    } else {
      childRef.current?.getMol();
      if (!_.isEmpty(structure)) {
        setCurrentStep(2);
      }
    }
    // } else {
    //   setResult(value);
    //   setCurrentStep(0);
    // }
  };

  const onUpdateDocumentations = () => {
    searchDocumentation(selectedDataSource, pageName, setDocumentations);
  };

  const trainingSettings =
    _.find(documentCollectionOptions?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  const onUpdateCompound = () => {
    const compoundId = compoundIdFormRef.current.getFieldValue("compoundId");

    let body = {
      compoundId: compoundId
        ? `${getPrefix(dataSources, selectedDataSource)}-${compoundId}`
        : exactMatches[0],
      sourceName: selectedDataSource,
      structure: result,
      molecularWeight,
      molecularFormula,
      structureUpdate: true,
    };

    /**
     * Structure update is true, when there is an update on canvas.
     * Otherwise, it should be false.
     */
    if (!isKnown) {
      body = {
        ...body,
        structure: "",
        conceptValue: value,
        molecularWeight,
        molecularFormula,
        structureUpdate: true,
      };
    }

    setOriginalMolecularFormula(molecularFormula);
    setOriginalMolecularWeight(molecularWeight);

    dispatch(update(body, userName));
  };

  const updateCanvas = (e) => {
    const id = `${getPrefix(dataSources, selectedDataSource)}-${
      e.target.value
    }`;

    getSmiles(
      id,
      (molResult) => {
        // If resulting moles are empty. Clear the canvas.
        if (checkMolEmpty(molResult)) {
          setIsKnown(false);
          childRef.current?.writeToCanvas("");
          clearStates();
          NotificationManager.info("This compound is a no-structure.");
        } else {
          setIsKnown(true);

          if (currentView === VIEW_TYPES.CANVAS) {
            childRef.current?.loadMOL(molResult);
          } else {
            inputModeFormRef?.current?.setFields([
              {
                name: "inputField",
                value: molResult,
              },
            ]);
          }

          setResult(molResult);
        }

        getCompoundConceptApi({
          selectedDataSource: selectedDataSource,
          targetId: id,
        })
          .then((compoundConcept) => {
            if (compoundConcept && compoundConcept.data) {
              setCurrentCompoundDetail(compoundConcept);

              const { conceptValue, molecularFormula, molecularWeight } =
                compoundConcept.data;

              if (conceptValue) {
                setValue(conceptValue);
                formRef?.current?.setFields([
                  {
                    name: "uniqueDescriptor",
                    value: conceptValue,
                  },
                  {
                    name: "molecularFormula",
                    value: molecularFormula,
                  },
                  {
                    name: "molecularWeight",
                    value: molecularWeight,
                  },
                ]);
              }

              if (molecularFormula) {
                setOriginalMolecularFormula(molecularFormula);
              }

              if (molecularWeight) {
                setOriginalMolecularWeight(molecularWeight);
              }
            }
          })
          .catch(() => {
            formRef?.current?.setFields([
              {
                name: "uniqueDescriptor",
                value: "",
              },
              {
                name: "molecularFormula",
                value: "",
              },
              {
                name: "molecularWeight",
                value: "",
              },
            ]);
          });
      },
      selectedDataSource,
      () => {
        NotificationManager.error("Compound does not exist.");

        // Empty the compound id field.
        compoundIdFormRef.current?.setFields([
          {
            name: "compoundId",
            value: "",
          },
        ]);
      },
      "mol",
    );
  };

  const [currentHelm, setCurrentHelm] = useState("");

  const getHelmString = () => {
    /*
    // Click on "Apply" button.
    const applyButton = document.querySelector(
      `button[title="Apply Sequence"]`
    );

    if (applyButton) {
      let evt = new MouseEvent("click");
      applyButton.dispatchEvent(evt);
    }
    */

    // Click on "HELM" tab.
    const tdNotation = document.querySelector(
      `td[key="notation"] table tbody tr td`,
    );

    if (tdNotation) {
      let evt = new MouseEvent("click");
      tdNotation.dispatchEvent(evt);
    }

    const contenteditableDivs = document.querySelectorAll(
      "div[contenteditable=true]",
    );

    if (contenteditableDivs && contenteditableDivs.length > 1) {
      const helmString = contenteditableDivs[1].innerHTML;
      return helmString;
    }

    return "";
  };

  const validateHELM = () => {
    setIsKnown(true);
    const helmString = getHelmString();

    const DEFAULT_HELM_VALUE = "PEPTIDE1{H.E.L.M}$$$$V2.0";

    if (helmString.endsWith("V2.0") && helmString !== DEFAULT_HELM_VALUE) {
      setCurrentHelm(helmString);
    } else {
      if (helmString === DEFAULT_HELM_VALUE) {
        NotificationManager.error("Use different value.");
      } else {
        NotificationManager.error("Please open HELM tab.");
      }
    }
  };

  const fetchDataFromHelm = () => {
    clearStates();

    // const helmString = getHelmString();

    console.warn("current helm", currentHelm);

    setResult(currentHelm);
    setCurrentStep(2);

    setCurrentHelm("");
  };

  const canvasChanged = () => {
    childRef.current?.getDrawing(true);

    setIsKnown(true);
    clearStates();
    // childRef.current?.getCDXML().then((cdxml) => {
    childRef.current?.getSmiles((smiles) => {
      if ((smiles || "").includes(">")) {
        setIsProcess(true);
      } else {
        setIsProcess(false);
      }
      dispatch(saveUserData("registerPageEditor", smiles));
    });

    // Disable save editor data.
    // dispatch(saveUserData("registerPageEditor", cdxml));

    // Validate and check uniqueness
    validateStructure();
    // });
  };

  const prefix = getPrefix(availableDataSources, selectedDataSource);

  const renderCheckButton = () => (
    <Button
      size="small"
      className="gx-btn-primary gx-ml-4 gx-btn-sm gx-mb-0"
      onClick={() => {
        getCompoundConceptShallow(
          selectedDataSource,
          `${prefix}${IDENTIFIER_SEPERATOR}${idValue}`,
        )
          .then(() => {
            NotificationManager.error(
              `The compound with ID ${idValue} already exists in the database. It cannot be used as a registration ID.`,
            );
          })
          .catch((e) => {
            if (e.response?.status === 404) {
              NotificationManager.success(
                `The compound with ID ${idValue} does not exist in the database. It can be used as a registration ID.`,
              );
              setIsRegisterAllowed(true);
            } else {
              NotificationManager.error(e.response?.data?.message);
            }
          });
      }}
    >
      Check
    </Button>
  );

  const renderRegisterAsIsomerButton = () => (
    <div className="gx-mt-2">
      <Button
        onClick={() => {
          if (showIdInput && _.isEmpty(idValue)) {
            NotificationManager.error("Please enter id.");
          } else {
            registerNewType(
              selectedDataSource,
              "RACEMATE",
              showIdInput ? `${prefix}${IDENTIFIER_SEPERATOR}${idValue}` : null,
            );
            setIsRegisterAllowed(false);
          }
        }}
        className="register-btn"
        disabled={isProcess}
        size="small"
        type="primary"
      >
        Register as racemate
      </Button>
      <Button
        onClick={() => {
          if (showIdInput && _.isEmpty(idValue)) {
            NotificationManager.error("Please enter id.");
          } else {
            registerNewType(
              selectedDataSource,
              "ISOMER",
              showIdInput ? `${prefix}${IDENTIFIER_SEPERATOR}${idValue}` : null,
            );
            setIsRegisterAllowed(false);
          }
        }}
        disabled={isProcess}
        className="register-btn"
        size="small"
        type="primary"
      >
        Register as isomer
      </Button>
    </div>
  );

  const matches = filterByUpdateId({
    targetArray: [...exactMatches, ...noStructure],
    updateMode,
    dataSources,
    selectedDataSource,
    compoundIdFormRef,
  });

  const isCurrentRegistrationUnique = updateMode
    ? _.isEmpty(matches)
    : isUnique;

  const registerDuplicate = () => {
    dispatch(
      register(
        isKnown ? result : value,
        selectedDataSource,
        false,
        isKnown,
        "",
        molecularWeight,
        molecularFormula,
        userName,
        null,
        true,
      ),
    );
  };

  return (
    <div>
      <div
        className={`gx-profile-banner sticky-header-component ${
          updateMode ? "sticky-header-secondary" : ""
        }`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
          }}
        >
          <h3>
            {updateMode
              ? "Update existing compound"
              : "Register a new compound"}
          </h3>

          <div className="gx-d-flex" style={{}}>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <DocumentationPopover
                documentations={documentations}
                sourceName={TRAINING_DOCUMENT_SOURCE}
                setOpenDocumentationSettings={setOpenDocumentationSettings}
                hasPermission={isUserAllowedToManageDocumentation}
              />
            ) : null}
          </div>

          {isCurator && (
            <div
              className="gx-d-flex gx-ml-5"
              style={{
                marginTop: "30px",
              }}
            >
              <Form.Item label="Update mode" className="registerProcess__label">
                <Switch
                  size="small"
                  checked={updateMode}
                  className="registerNewCompound__checkbox"
                  onChange={(e) => {
                    canvasChanged();
                    // clearStates();
                    setResult("");
                    childRef.current?.writeToCanvas("");
                    setUpdateMode(e);
                  }}
                />
              </Form.Item>
            </div>
          )}

          {isCurator && (
            <div
              className="gx-d-flex gx-ml-5"
              style={{
                marginTop: "30px",
              }}
            >
              <Form.Item
                label="Structure Input Mode"
                className="registerProcess__label"
              >
                <Switch
                  size="small"
                  checked={currentView === VIEW_TYPES.INPUT}
                  className="registerNewCompound__checkbox"
                  onChange={(e) => {
                    canvasChanged();
                    setResult("");
                    childRef.current?.writeToCanvas("");
                    setCurrentView(e ? VIEW_TYPES.INPUT : VIEW_TYPES.CANVAS);
                  }}
                />
              </Form.Item>
            </div>
          )}

          {isCurator && !updateMode && (
            <div
              className="gx-d-flex gx-ml-5"
              style={{
                marginTop: "30px",
              }}
            >
              <Form.Item
                label="Provide registration Id"
                className="registerProcess__label"
              >
                <Switch
                  size="small"
                  className="registerNewCompound__checkbox"
                  checked={showIdInput}
                  onChange={(e) => {
                    setShowIdInput(e);
                  }}
                />
              </Form.Item>
            </div>
          )}

          {helmEditorSettings && isCurator ? (
            <div
              className="gx-d-flex gx-ml-5"
              style={{
                marginTop: "30px",
              }}
            >
              <Form.Item
                label="Use HELM Editor"
                className="registerProcess__label"
              >
                <Switch
                  size="small"
                  className="registerNewCompound__checkbox"
                  checked={showHelm}
                  onChange={(e) => {
                    setShowHelm(e);
                  }}
                />
              </Form.Item>
            </div>
          ) : null}

          {updateMode && (
            <div
              className="gx-ml-5"
              style={{
                marginTop: "31px",
              }}
            >
              <Form ref={compoundIdFormRef}>
                <Form.Item
                  label="Update data for compound ID"
                  name="compoundId"
                  required
                  rules={[{ required: true, message: "Please enter id" }]}
                  className="registerProcess__label"
                >
                  <Input
                    type="number"
                    prefix={`${getPrefix(
                      dataSources,
                      selectedDataSource,
                    )} ${IDENTIFIER_SEPERATOR}`}
                    size="small"
                    placeholder="Enter id"
                    onPressEnter={updateCanvas}
                  />
                </Form.Item>
              </Form>
            </div>
          )}
        </div>

        {(availableDataSources || []).length === 1 ? (
          <div className="gx-mr-4 gx-mt-3">
            <span>Data Source:</span>
            <span className="gx-ml-2 gx-text-warning">
              {availableDataSources[0]?.sourceName}
            </span>
          </div>
        ) : (
          <Form
            className="gx-mt-2 gx-mr-5"
            style={{
              zIndex: 10000,
            }}
          >
            {!_.isNull(selectedDataSource) &&
            !_.isEmpty(availableDataSources) ? (
              <FormItem
                label="Select Data Source"
                initialValue={selectedDataSource}
                className="registerNewCompound__label"
              >
                <Select
                  placeholder="Choose Data Source"
                  onChange={(e) => {
                    setSelectedDataSource(e);
                    setCurrentStep(0);
                    setUpdateMode(false);
                    canvasChanged();
                  }}
                  value={selectedDataSource}
                  dropdownMatchSelectWidth={false}
                >
                  {availableDataSources.map((item, index) => (
                    <Option
                      key={`data-source-option-${index}`}
                      value={item.sourceName}
                    >
                      {item.sourceName}
                    </Option>
                  ))}
                </Select>
              </FormItem>
            ) : null}
            {!availableDataSources.length && (
              <div className="gx-text-warning gx-mt-2">
                No Data Sources Available. Please contact administrator.
              </div>
            )}
          </Form>
        )}
      </div>

      <div
        className="sticky-header-content"
        style={{
          marginTop: "100px",
          marginBottom: "30px",
        }}
      >
        <div className="gx-d-flex registerNewCompound-MainComponent">
          <div
            className="app-sidebar"
            ref={sidebarRef}
            style={{ width: sidebarWidth }}
            // onMouseDown={(e) => e.preventDefault()}
          >
            {showHelm && currentView === VIEW_TYPES.CANVAS ? (
              <HelmEditor />
            ) : (
              <>
                {currentView === VIEW_TYPES.CANVAS && !showHelm ? (
                  <GenericEditor
                    currentEditor={currentEditor}
                    ref={childRef}
                    setDrawing={(data) => {
                      setIsKnown(true);
                      setResult(data);
                    }}
                    // canvasChanged={canvasChanged}
                    canvasChanged={canvasChanged}
                  />
                ) : (
                  <div className="InputViewType">
                    <h4 className="gx-mb-2">Text input for structures</h4>
                    <Form
                      ref={inputModeFormRef}
                      onFinish={({ inputField }) => {
                        dispatch(clearRegisterData());
                        dispatch(clearErrorMessage());
                        if (inputField) {
                          // Trim trailing carriage returns.
                          const inputFieldTrimmed = (inputField || "").includes(
                            "M  END",
                          )
                            ? inputField
                            : (inputField || "").replace(/\s+/g, " ").trim();

                          setResult(inputFieldTrimmed);
                        }
                      }}
                      layout="vertical"
                    >
                      <Form.Item className="textarea" name="inputField">
                        <TextArea placeholder="Enter smiles or mol input" />
                      </Form.Item>

                      <Button type="primary" size="small" htmlType="submit">
                        Submit
                      </Button>
                    </Form>
                  </div>
                )}
              </>
            )}
            <div
              className="app-sidebar-resizer"
              id="resizer"
              onMouseDown={startResizing}
            />
          </div>
          <PanelPortal
            showPanel={showHelm ? showPanel : false}
            onDeletePanel={() => {
              setShowPanel(false);
            }}
            title="Registration Details"
          >
            <div>
              <Divider />

              <div className="gx-mt-3">
                {loading ? (
                  <div>
                    <CircularProgress className="gx-loader-400 loader customLoader" />
                  </div>
                ) : (
                  <div>
                    {currentView === VIEW_TYPES.CANVAS && !showHelm ? (
                      <Button
                        type="primary"
                        size="small"
                        onClick={canvasChanged}
                      >
                        Check structure
                      </Button>
                    ) : null}
                    {showHelm ? (
                      <Button
                        size="small"
                        type="primary"
                        onClick={validateHELM}
                      >
                        Check HELM string
                      </Button>
                    ) : null}

                    {showHelm && !_.isEmpty(currentHelm) ? (
                      <div className="gx-mb-2">
                        <span>Current HELM value: </span>
                        <span className="gx-text-success ">{currentHelm}</span>
                      </div>
                    ) : null}

                    {showHelm && !_.isEmpty(currentHelm) ? (
                      <Button
                        size="small"
                        type="primary"
                        onClick={fetchDataFromHelm}
                      >
                        Check Uniqueness
                      </Button>
                    ) : null}

                    {renderStatus({
                      value: `Selected Data Source: ${selectedDataSource}`,
                      step: 0,
                    })}
                    {originalMolecularFormula || originalMolecularWeight ? (
                      <div
                        className="widget-card ant-card ant-card-bordered"
                        style={{}}
                      >
                        <div
                          className="ant-card-head widget-header"
                          style={{ fontSize: "12px" }}
                        >
                          <span>
                            Original data for:{" "}
                            {getPrefix(dataSources, selectedDataSource)} -
                            {compoundIdFormRef?.current?.getFieldValue(
                              "compoundId",
                            )}{" "}
                          </span>

                          <Button
                            size="small"
                            type="primary"
                            className="gx-mt-2 gx-ml-3"
                            onClick={() => {
                              setMolecularFormula(originalMolecularFormula);
                              setMolecularWeight(originalMolecularWeight);
                              formRef?.current?.setFields([
                                {
                                  name: "molecularFormula",
                                  value: originalMolecularFormula,
                                },
                                {
                                  name: "molecularWeight",
                                  value: originalMolecularWeight,
                                },
                              ]);
                            }}
                          >
                            Apply data
                          </Button>
                        </div>
                        <div className="ant-card-body widget-body  ">
                          <CopiableRowItem
                            title="Molecular Formula"
                            data={originalMolecularFormula}
                          />
                          <CopiableRowItem
                            title="Molecular Weight"
                            data={originalMolecularWeight}
                          />
                        </div>
                      </div>
                    ) : null}
                    {(currentStep && !isKnown) ||
                    (isKnown &&
                      isCurator &&
                      !childRef.current?.isBlankStructure() &&
                      _.isEmpty(updateIds) &&
                      !showHelm &&
                      _.isEmpty(registerIds) &&
                      !errorMessage) ? (
                      <div className="gx-d-flex gx-mb-3">
                        <CheckOutlined className="ant-steps-item-icon" />
                        <CompoundInputField
                          value={value}
                          setValue={setValue}
                          disableTitle
                          onChangeValue={(currentValue) => {
                            setIsKnown(false);
                            setResult(currentValue);
                            setCurrentStep(2);
                            dispatch(clearRegisterData());
                            dispatch(clearErrorMessage());
                          }}
                          formRef={formRef}
                          molecularFormula={molecularFormula}
                          setMolecularFormula={setMolecularFormula}
                          molecularWeight={molecularWeight}
                          setMolecularWeight={setMolecularWeight}
                          disableUniqueDescriptorField={isKnown && isCurator}
                          canvasChanged={() => {
                            dispatch(clearRegisterData());
                          }}
                          isKnown={isKnown}
                        />
                      </div>
                    ) : (
                      <>
                        {renderStatus({
                          value: message,
                          step: 1,
                        })}
                      </>
                    )}
                    {result && !!errorMessage
                      ? renderStatus({
                          value: ` ${
                            isCurrentRegistrationUnique
                              ? "The submitted structure is unique"
                              : "The submitted structure already exists in the database"
                          }`,
                          step: 2,
                        })
                      : null}

                    {childRef.current?.isBlankStructure() && !currentStep ? (
                      <div className="gx-mb-3">
                        <CompoundInputField
                          value={value}
                          setValue={setValue}
                          onChangeValue={(currentValue) => {
                            setIsKnown(false);
                            setMessage(currentValue);
                            setResult(currentValue);
                            setCurrentStep(2);
                          }}
                          formRef={formRef}
                          molecularFormula={molecularFormula}
                          setMolecularFormula={setMolecularFormula}
                          molecularWeight={molecularWeight}
                          setMolecularWeight={setMolecularWeight}
                          canvasChanged={() => {
                            dispatch(clearRegisterData());
                          }}
                        />
                      </div>
                    ) : null}

                    {(registerIds || []).length ? (
                      <Widget
                        id={registerIds[0]?.compoundId}
                        widget={getWidgetData(REGISTRATION_DATA_WIDGET_NAME)}
                        systemWidgetName={REGISTRATION_DATA_WIDGET_NAME}
                        renderResidualData
                        onSaveWidget={(form, metadataName) =>
                          onSaveTraits(
                            registerIds[0]?.compoundId,
                            form,
                            metadataName,
                          )
                        }
                        setCurrentVersion={() => {}}
                      />
                    ) : null}
                    {(isCurrentRegistrationUnique ||
                      (!isCurrentRegistrationUnique &&
                        (molecularFormula || molecularWeight))) &&
                    !_.isEmpty(availableDataSources) &&
                    updateMode ? (
                      <UpdateCard
                        uniqueReason={
                          isCurrentRegistrationUnique
                            ? "The submitted structure is unique"
                            : ""
                        }
                        onUpdateCompound={onUpdateCompound}
                        updateIds={updateIds}
                        isProcess={isProcess}
                      />
                    ) : null}
                    {isCurrentRegistrationUnique &&
                    !canRegisterAsRacemate &&
                    !_.isEmpty(availableDataSources) ? (
                      <>
                        {updateMode ? null : (
                          <RegisterCard
                            uniqueReason={"The submitted structure is unique"}
                            registerCompound={registerCompound}
                            registerIds={registerIds}
                            selectedDataSource={selectedDataSource}
                            isKnown={isKnown}
                            value={value}
                            isProcess={isProcess}
                            showIdInput={showIdInput}
                            availableDataSources={availableDataSources}
                            renderCheckButton={renderCheckButton}
                            setIdValue={setIdValue}
                            idValue={idValue}
                            isRegisterAllowed={isRegisterAllowed}
                          />
                        )}
                      </>
                    ) : null}

                    {result.length && registerIds.length > 0 ? (
                      <DrawingResult
                        result={result}
                        registerIds={registerIds}
                        generateCompoundImage={generateCompoundImage}
                        structureImage={structureImage}
                        isKnown={isKnown}
                        sourceName={selectedDataSource}
                        currentCompoundDetail={currentCompoundDetail}
                      />
                    ) : null}

                    {(updateIds || []).length > 0 ? (
                      <UpdateResult
                        sourceName={selectedDataSource}
                        updateIds={updateIds}
                        generateCompoundImage={generateCompoundImage}
                        structureImage={structureImage}
                        molecularFormula={molecularFormula}
                        molecularWeight={molecularWeight}
                        result={updateIds[0]?.compoundId}
                      />
                    ) : null}

                    {canRegisterAsRacemate &&
                    !isIsomerRegistered &&
                    !updateMode &&
                    !_.isEmpty(availableDataSources) ? (
                      <div>
                        {showIdInput && (
                          <Input
                            size="small"
                            placeholder="Enter Id"
                            prefix={`${prefix} ${IDENTIFIER_SEPERATOR}`}
                            value={idValue}
                            onChange={(e) => setIdValue(e.target.value)}
                            style={{ maxWidth: "50%" }}
                            maxLength={10}
                          />
                        )}

                        {showIdInput ? (
                          <>
                            {isRegisterAllowed
                              ? renderRegisterAsIsomerButton()
                              : renderCheckButton()}
                          </>
                        ) : (
                          <>{renderRegisterAsIsomerButton()}</>
                        )}

                        {showIdInput && (
                          <p
                            className="gx-text-warning gx-mt-2"
                            style={{ width: "30vw" }}
                          >
                            <ExclamationCircleTwoTone
                              className="gx-mr-2"
                              twoToneColor="F44336"
                            />
                            The ID needs to be lower than highest registered
                            number. Otherwise the database will become
                            corrupted.
                          </p>
                        )}
                      </div>
                    ) : null}

                    {canRegisterAsRacemate &&
                    !isIsomerRegistered &&
                    isProcess ? (
                      <p className="gx-text-danger gx-mt-2">
                        <ExclamationCircleTwoTone
                          className="gx-mr-2"
                          twoToneColor="F44336"
                        />
                        Registering Process is restricted.
                      </p>
                    ) : null}

                    {(isomers || []).length &&
                    !isIsomerRegistered &&
                    !_.isEmpty(availableDataSources) &&
                    !(racemate || []).length ? (
                      <Button
                        onClick={() => {
                          if (showIdInput && _.isEmpty(idValue)) {
                            NotificationManager.error("Please enter id.");
                          } else {
                            registerNewType(
                              selectedDataSource,
                              "RACEMATE",
                              showIdInput
                                ? `${prefix}${IDENTIFIER_SEPERATOR}${idValue}`
                                : null,
                            );
                            setIsRegisterAllowed(false);
                          }
                        }}
                        className="register-btn"
                        size="small"
                        type="primary"
                      >
                        Register as racemate
                      </Button>
                    ) : null}

                    {isCurrentRegistrationUnique === false &&
                      !_.isEmpty(matches) && (
                        <ExactMatchesCard
                          title="The submitted structure already exists in the database"
                          matchedComponents={matches}
                          opticalIsomer={structure?.opticalIsomer}
                          isomers={structureRegistration?.opticalIsomer || []}
                          isUndefinedStereoAtoms={isUndefinedStereoAtoms}
                          onRegisterNewQuality={onRegisterNewQuality}
                          loading={registering}
                          renderImage
                          sourceName={selectedDataSource}
                          registerNewType={registerNewType}
                          isIsomerRegistered={isIsomerRegistered}
                          availableDataSources={availableDataSources}
                          isKnown={isKnown}
                          userEmails={userEmails}
                          currentUserEmail={userName}
                          registerDuplicate={registerDuplicate}
                        />
                      )}

                    {_.map(duplicatesMappings, (currentItem) => {
                      if ((currentItem.name || []).length > 0) {
                        return (
                          <Widget
                            systemWidgetName={currentItem.systemWidgetName}
                            sourceName={selectedDataSource}
                            readMode
                            widget={getWidgetData(currentItem.systemWidgetName)}
                            widgetData={{
                              compoundDetail: {
                                [currentItem.targetValue]: _.map(
                                  currentItem.name,
                                  (name) => ({
                                    name,
                                    source: selectedDataSource,
                                  }),
                                ),
                              },
                            }}
                          />
                        );
                      } else return null;
                    })}
                  </div>
                )}
              </div>
            </div>
          </PanelPortal>
        </div>
      </div>

      <SweetAlert
        show={!!errorMessage}
        warning
        title={errorMessage || ""}
        onConfirm={() => {
          dispatch(clearErrorMessage());
          dispatch(clearRegisterData());
        }}
      />

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>
    </div>
  );
};

export default RegisterNewCompound;
