import React from "react";
import { Button, Pagination } from "antd";
import PT from "prop-types";

const VersioningForCompositionTable = ({
  versions,
  currentVersion,
  onChangeVersion,
  renderRestore = false,
  restoreCompound = () => {},
}) => {
  const total = versions.length;

  return (
    <div className="card-item">
      <div className="card-item-field">
        {renderRestore ? (
          <Button
            size="small"
            type="primary"
            style={{ marginBottom: "0", marginTop: "5px" }}
            onClick={restoreCompound}
          >
            Restore this version
          </Button>
        ) : (
          <span>Version</span>
        )}
      </div>
      <div className="card-item-value">{currentVersion}</div>
      <Pagination
        defaultCurrent={currentVersion}
        total={total}
        pageSize={1}
        size="medium"
        onChange={onChangeVersion}
      />
    </div>
  );
};

VersioningForCompositionTable.defaultProps = {
  currentVersion: 1,
  versions: [],
};

VersioningForCompositionTable.propTypes = {
  currentVersion: PT.number,
  versions: PT.arrayOf(PT.number),
};

export default VersioningForCompositionTable;
