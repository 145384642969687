/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment-timezone";
import PT from "prop-types";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import pluralize from "pluralize";
import "react-dropzone-uploader/dist/styles.css";
import Header from "../Widget/Header";

import {
  AppstoreTwoTone,
  PlusCircleTwoTone,
  PrinterTwoTone,
  SearchOutlined,
} from "@ant-design/icons";
import { useMsal } from "@azure/msal-react";
import ProgressBar from "@ramonak/react-progress-bar";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  List,
  Modal,
  Popover,
  Progress,
  Select,
  Table,
  Tag,
} from "antd";
import CircularProgress from "components/CircularProgress";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import _ from "lodash";
import Dropzone from "react-dropzone-uploader";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { getDocuments } from "../../../appRedux/actions/Document";
import {
  convertFileToPDF,
  deleteDocument,
  downloadDocument,
  editDocument,
  getCounter,
  saveCounter,
  searchDocument,
  uploadDocument,
} from "../../../appRedux/services/Document";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import { getCompoundConceptShallow } from "../../../appRedux/services/Widget";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_PAGE_SIZE,
  DOCUMENT_COLLECTION_OPTIONS,
} from "../../../constants/Config";
import { RequestAccessToken } from "../../../util/Graph";
import { addParametersToDocument, checkField } from "../../../util/Widget";
import { getDocumentRole, getDocumentRoleId } from "../../../util/auth";
import {
  formatBytes,
  getFolderName,
  getUploadPercentage,
} from "../../../util/url";
import AutoCompleteSelect from "../../AutoCompleteSelect";
import DropzoneInputWrapper from "../../DropzoneInputWrapper";
import InputTag from "../../InputTag";
import TableFilter from "../../TableFilter";
import DocumentRow from "./DocumentRow";
import MetaWidget from "./MetaWidget";
import RowDropzoneWrapper from "./RowDropzoneWrapper";
import SearchForDocument from "./SearchForDocument";
import { FileDetailComponent } from "./helper";
import "./style.css";

moment().tz("America/Los_Angeles").format();

const DocumentCollection = ({
  id,
  title,
  sourceName,
  pageType,
  isCompoundExists,
  systemWidgetName,
}) => {
  const [open, setOpen] = useState(true);
  const [files, setFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [mimeTypes, setMimeTypes] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [parentId, setParentId] = useState(null);
  const [collectionOptions, setCollectionOptions] = useState([]);
  const [availableWidgets, setAvailableWidgets] = useState([]);
  const [defaultEntityIds, setDefaultEntityIds] = useState([id]);
  const [selectedDocument, setSelectedDocument] = useState([]);
  const [currentDocument, setCurrentDocument] = useState();
  const [currentSelectedCollection, setCurrentSelectedCollection] = useState();
  const [searchResults, setSearchResults] = useState();
  const [fileSelectionModal, setFileSelectionModal] = useState(false);
  const [widgetLoading, setWidgetLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [progress, setProgress] = useState({});
  const [editFileModal, setEditFileModal] = useState(false);
  const [currentCollection, setCurrentCollection] = useState({});
  const [uploadForbidden, setUploadForbidden] = useState(false);
  const [isUrl, setIsUrl] = useState(false);
  const formRef = useRef();
  const inputTagRef = useRef();
  const ownersTagRef = useRef();
  const entityIdsRef = useRef();
  const metaWidgetRef = useRef();
  const widgetType = "documentWidget";
  const [accessToken, setAccessToken] = useState(null);
  const [widget, setWidget] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const [filteredResult, setFilteredResult] = useState();

  const { accounts, instance } = useMsal();
  const account = accounts[0];
  const searchInput = useRef(null);
  const searchDocumentRef = useRef();
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);

  const dispatch = useDispatch();

  useEffect(() => {
    RequestAccessToken({ accounts, instance, setAccessToken });
  }, []);

  useEffect(() => {
    if (_.isEmpty(widget)) {
      getSystemWidget(systemWidgetName, setWidget, false);
    }
  }, []);

  useEffect(() => {
    if (!collectionOptions.length) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, setCollectionOptions);
    }
  }, []);

  useEffect(() => {
    if (sourceName && id) {
      dispatch(getDocuments(id, sourceName));
    }

    if (!availableWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setAvailableWidgets);
    }
  }, []);

  const fileDetailRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => fileDetailRef.current,
  });

  const updateDocuments = () => {
    dispatch(getDocuments(id, sourceName));
  };

  const widgets = availableWidgets.filter((item) =>
    (item?.belongsTo || []).includes(widgetType)
  );

  const { collections } = useSelector(({ document }) => document);
  const collectionsFiltered = collectionOptions.filter((option) => {
    return (
      (option?.allowedEntityTypes || []).includes(pageType) &&
      (option?.viewAccessRoles || []).includes(getDocumentRoleId() || "")
    );
  });

  const currentCollectionOptions = collectionsFiltered;
  // .filter((option) => {
  //   return (option?.sourcesBelongsTo || []).includes(sourceName);
  // });

  const collectionsList = Object.entries(collections).map(([key, value]) => {
    const currentCollection = _.find(collectionsFiltered, { value: key });

    const isLockingAllowed = (currentCollection?.allowedRoles || []).includes(
      getDocumentRoleId()
    );
    const hasPermission = (currentCollection?.userRoles || []).includes(
      getDocumentRoleId()
    );
    return {
      name: key,
      items: value,
      isLockingAllowed,
      hasPermission,
      summaryField: currentCollection?.summaryField,
      downloadAccessRoles: currentCollection?.downloadAccessRoles,
      viewAccessRoles: currentCollection?.viewAccessRoles,
    };
  });

  const documents = _.sortBy(collectionsList, ["name"]);

  const defaultCollections = _.filter(collectionsFiltered, (option) => {
    return (option?.defaultPageTypes || []).includes(pageType);
  });

  const documentsWithDefaultCollections = _.filter(
    defaultCollections,
    (defaultCollection) => {
      // Default set collection already exists ignore.
      if (_.findIndex(documents, { name: defaultCollection?.value }) !== -1) {
        return false;
      }
      return true;
    }
  );

  const documentsWithDefaultCollectionsWithMap = _.map(
    documentsWithDefaultCollections,
    (currentCollection) => {
      const isLockingAllowed = (currentCollection?.allowedRoles || []).includes(
        getDocumentRoleId()
      );
      const hasPermission = (currentCollection?.userRoles || []).includes(
        getDocumentRoleId()
      );

      return {
        name: currentCollection?.value,
        items: [],
        isLockingAllowed,
        hasPermission,
        summaryField: currentCollection?.summaryField,
        downloadAccessRoles: currentCollection?.downloadAccessRoles,
        viewAccessRoles: currentCollection?.viewAccessRoles,
      };
    }
  );

  const dataSource = [
    ...documents,
    ...documentsWithDefaultCollectionsWithMap,
  ].map((item) => ({
    name: item.name,
    items: item.items,
    isLockingAllowed: item.isLockingAllowed,
    hasPermission: item.hasPermission,
    summaryField: item?.summaryField,
    downloadAccessRoles: item?.downloadAccessRoles,
    viewAccessRoles: item?.viewAccessRoles,
  }));

  const onChangeCurrentCollection = (currentItem, currentValue) => {
    const { value } = currentValue;

    setCurrentSelectedCollection(value);
  };

  const onSaveDocumentTraits = (data, metadataName) => {
    // dispatch(saveMetadata(id, data || {}, metadataName, sourceName));
  };

  const onConvert = (documentItem) => {
    convertFileToPDF(
      documentItem?.id,
      (result) => {
        NotificationManager.success("File successfully converted.");
        onLock(documentItem, true);
        onLock(result, true);
      },
      (e) => {
        NotificationManager.error(e);
      }
    );
  };

  const getMinutes = (counterData, collection) => {
    const lastLockedTime = moment(
      _.get(counterData, `${collection}.timestamp`)
    );
    const currentTime = moment().tz("America/Los_Angeles");
    const duration = moment.duration(currentTime.diff(lastLockedTime));
    var minutes = duration.asMinutes();

    return minutes;
  };

  const onSaveFile = () => {
    const updateSubset = !_.isEmpty(parentId);

    // const name = formRef.current.getFieldValue("name");
    const collection = formRef.current.getFieldValue("collection");
    const description = formRef.current.getFieldValue("description");
    const url = formRef.current.getFieldValue("url");
    const tags = inputTagRef.current.changeVal();
    const owners = ownersTagRef.current.getValues();
    const entityIds = entityIdsRef.current.changeVal();
    const name = formRef.current.getFieldValue("name");

    const metaWidgetForm = metaWidgetRef.current?.getMetaWidgetForm();

    getCounter((counterData) => {
      const minutes = getMinutes(counterData, collection);

      if (_.get(counterData, `${collection}.locked`) && minutes < 5) {
        NotificationManager.error(
          `This collection is currently being updated. Please try again in ${
            5 - Math.floor(Number(minutes))
          } minutes`
        );
      } else {
        /*
          Validate Collection field
        */
        if (
          !formRef.current.isFieldTouched("collection") &&
          !(collection || "").length
        ) {
          formRef.current.setFields([
            {
              name: "collection",
              errors: ["Please enter collection."],
            },
          ]);
          return;
        }

        /*
          Validate File field
        */
        if (!files.length && !edit && !isUrl) {
          NotificationManager.error("Please upload or select file.");
          return;
        }

        let file;
        let mimeType;
        if (edit) {
          mimeType = currentDocument?.mimeType;
        } else {
          if (files.length) {
            file = files[0];
            mimeType = mimeTypes[0];
          }
        }

        const metaSubjects = _.map(metaWidgetForm, (k, v) => {
          return {
            key: v,
            value: k,
          };
        }).filter((item) => item?.field !== "tag");

        const searchSubjects = tags.map((tag) => ({
          key: "tag",
          value: tag,
        }));

        const targetSearchSubjects = _.uniq([
          ...searchSubjects,
          ...metaSubjects,
        ]);

        if (updateSubset) {
          // Lock the counter for current collection.
          saveCounter(
            collection,
            counterData,
            _.get(counterData, `${collection}.counter`) || 0,
            true,
            () => {
              // If user submitting document without file. It should increase counter by 1.
              const targetNum =
                Number(_.get(counterData, `${collection}.counter`) || 0) +
                (!files.length ? 1 : files.length);
              uploadDocument(
                id,
                sourceName,
                files,
                {
                  collection: collection,
                  mimeType: (mimeType || []).length
                    ? mimeType[0]
                    : currentDocument?.mimeType,
                  description,
                  accessionNumber: targetNum,
                  owners,
                  searchSubjects: targetSearchSubjects,
                  uri: url || "",
                  subset: currentDocument?.id,
                },
                () => {
                  setShowModal(false);
                  setParentId(null);
                  NotificationManager.success("Document has been added");
                  updateDocuments();
                },
                (e) => {
                  NotificationManager.error(
                    "A file with this name already exists in this collection."
                  );
                },
                setLoading,
                setProgress,
                false,
                [],
                isUrl,
                true,
                currentDocument?.id
              );

              saveCounter(collection, counterData, targetNum, false);
            }
          );
        } else if (edit && currentDocument?.id) {
          editDocument(
            id,
            sourceName,
            currentDocument?.id,
            {
              entityIds: _.uniq([id, ...entityIds]),
              collection: collection,
              // mimeType: currentDocument?.mimeType,
              description,
              owners,
              searchSubjects: targetSearchSubjects,
              uri: url,
              name,
            },
            () => {
              setShowModal(false);
              setParentId(null);
              NotificationManager.success("Document has been updated.");
              updateDocuments();
            },
            (e) => {
              setShowModal(false);
              setParentId(null);
              NotificationManager.error(e);
            },
            setLoading
          );
        } else {
          // Lock the counter for current collection.
          saveCounter(
            collection,
            counterData,
            _.get(counterData, `${collection}.counter`) || 0,
            true,
            () => {
              // If user submitting document without file. It should increase counter by 1.
              const targetNum =
                Number(_.get(counterData, `${collection}.counter`) || 0) +
                (!files.length ? 1 : files.length);
              uploadDocument(
                id,
                sourceName,
                files,
                {
                  entityIds: _.uniq([id, ...entityIds]),
                  collection: collection,
                  mimeType: (mimeType || []).length
                    ? mimeType[0]
                    : currentDocument?.mimeType,
                  description,
                  accessionNumber: targetNum,
                  owners,
                  searchSubjects: targetSearchSubjects,
                  uri: url || "",
                },
                () => {
                  setShowModal(false);
                  setParentId(null);
                  NotificationManager.success("Document has been added");
                  updateDocuments();
                },
                (e) => {
                  NotificationManager.error(
                    "A file with this name already exists in this collection."
                  );
                },
                setLoading,
                setProgress,
                false,
                [],
                isUrl
              );

              saveCounter(collection, counterData, targetNum, false);
            }
          );
        }
      }

      setIsUrl(false);
    });
  };

  const onSaveSubsetFile = () => {};

  const onDelete = (documentId) => {
    setWidgetLoading(true);
    deleteDocument(
      documentId,
      sourceName,
      () => {
        setShowModal(false);
        setParentId(null);
        NotificationManager.success("Document has been deleted.");
        updateDocuments();
        setWidgetLoading(false);
      },
      (e) => {
        setShowModal(false);
        setParentId(null);
        NotificationManager.error(e);
        setWidgetLoading(false);
      }
    );
  };

  const onEdit = (document) => {
    setShowModal(true);
    setParentId(null);
    setEditMode(true);
    setEdit(true);
    setCurrentDocument(document);
    setCurrentSelectedCollection(document?.collection);

    document["tags"] = (document?.searchSubjects || [])
      .filter((item) => item?.key === "tag")
      .map((item) => item?.value);

    setTimeout(() => {
      formRef.current?.setFields([
        {
          name: "collection",
          value: document?.collection,
        },
        {
          name: "name",
          value: document?.name,
        },
        {
          name: "description",
          value: document?.description,
        },
        {
          name: "tags",
          value: document?.tags,
        },
      ]);

      setDefaultEntityIds(document?.entityIds || []);
    }, 300);
  };

  const onLock = (document, locked = false, setFn = () => {}) => {
    editDocument(
      id,
      sourceName,
      document?.id,
      {
        entityIds: document?.entityIds,
        collection: document?.collection,
        description: document?.description,
        name: document?.name,
        owners: document?.owners,
        searchSubjects: document?.searchSubjects,
        locked,
        lockedBy: account?.username,
      },
      () => {
        setShowModal(false);
        setParentId(null);
        NotificationManager.success(
          `Document has been ${locked ? "locked" : "unlocked"}.`
        );
        updateDocuments();
        setFn();
      },
      (e) => {
        setShowModal(false);
        setParentId(null);
        NotificationManager.error(e);
      },
      setLoading
    );
  };

  const renderActionButtons = () => {
    return (
      <>
        <Popover content="Add document">
          <PlusCircleTwoTone
            className="gx-ml-2"
            twoToneColor="#20c997"
            onClick={() => {
              resetForm();
              setEditMode(false);
              setShowModal(true);
              setParentId(null);
            }}
          />
        </Popover>
      </>
    );
  };

  const cloneCurrentDocument = (currentDocument) => {
    const collection = currentDocument?.collection;
    const name = `Cloned from: ${currentDocument?.fileName}`;

    getCounter((counterData) => {
      const minutes = getMinutes(counterData, collection);

      if (_.get(counterData, `${collection}.locked`) && minutes < 5) {
        NotificationManager.error(
          `This collection is currently being updated. Please try again in ${
            5 - Math.floor(Number(minutes))
          } minutes`
        );
      } else {
        // Lock the counter for current collection.
        saveCounter(
          collection,
          counterData,
          _.get(counterData, `${collection}.counter`) || 0,
          true,
          () => {
            // If user submitting document without file. It should increase counter by 1.
            const targetNum =
              Number(_.get(counterData, `${collection}.counter`) || 0) +
              (!files.length ? 1 : files.length);
            uploadDocument(
              id,
              sourceName,
              files,
              {
                ...currentDocument,
                name,
                accessionNumber: targetNum,
              },
              () => {
                setShowModal(false);
                setParentId(null);
                NotificationManager.success("Document has been cloned.");
                updateDocuments();

                saveCounter(collection, counterData, targetNum, false);
              },
              (e) => {
                NotificationManager.error(
                  "A file with this name already exists in this collection."
                );
              },
              setLoading,
              setProgress,
              false,
              [],
              true
            );
          }
        );
      }
    });
  };

  /**
   * Upload a file inside a folder.
   * @param {String} parentDocument obj parent document.
   */
  const onUploadSubsetFile = (parentDocument) => {
    setCurrentDocument(parentDocument);
    setParentId(parentDocument?.id);
    setShowModal(true);

    setTimeout(() => {
      formRef.current?.setFields([
        {
          name: "collection",
          value: parentDocument?.collection,
        },
      ]);
    }, 200);
  };

  const renderContent = (
    p,
    {
      name,
      items,
      isLockingAllowed,
      hasPermission,
      unmappedCollection,
      summaryField,
      downloadAccessRoles = [],
    }
  ) => {
    return (
      <>
        <div
          className={`gx-mb-3 ${unmappedCollection ? "bg-disabled" : ""}`}
          style={{ marginLeft: "-5px" }}
        >
          {/* {renderDocumentName(true, {
            name: name,
            collectionOptions,
          })} */}
          {unmappedCollection && (
            <span className="gx-ml-2 document__name bg-disabled">
              (Unmapped)
            </span>
          )}
        </div>
        <DocumentRow
          collectionName={name}
          files={items}
          pageType={pageType}
          isLockingAllowed={isLockingAllowed}
          hasPermission={hasPermission}
          summaryField={summaryField}
          collectionOptions={collectionOptions}
          sourceName={sourceName}
          onSaveFile={onSaveFile}
          onDelete={onDelete}
          onEdit={onEdit}
          onConvert={onConvert}
          onLock={onLock}
          onSelect={(item) => {
            setSelectedDocument(item);
            setCurrentSelectedCollection(item?.collection);
            setModalOpen(true);
            setCurrentDocument(item);
          }}
          onSaveDescription={(file, description, uri) => {
            editDocument(
              id,
              sourceName,
              file?.id,
              {
                entityIds: _.uniq([id, ...file?.entityIds]),
                collection: file?.collection,
                description,
                name: file?.name,
                owners: file?.owners,
                searchSubjects: file?.searchSubjects,
                uri,
              },
              () => {
                setShowModal(false);
                NotificationManager.success("Document has been updated.");
                updateDocuments();
              },
              (e) => {
                setShowModal(false);
                NotificationManager.error(e);
              },
              setLoading
            );
          }}
          currentPage={currentPage}
          currentPageSize={currentPageSize}
          setCurrentPage={setCurrentPage}
          setCurrentPageSize={setCurrentPageSize}
          onOpenFromCollection={(collection) => {
            resetForm();

            setTimeout(() => {
              formRef.current?.setFields([
                {
                  name: "collection",
                  value: collection,
                },
              ]);
            }, 300);

            setEditMode(false);
            setShowModal(true);
          }}
          hasDownloadAccess={(downloadAccessRoles || []).includes(
            getDocumentRoleId()
          )}
          cloneCurrentDocument={cloneCurrentDocument}
          onUploadSubsetFile={onUploadSubsetFile}
        />
      </>
    );
  };

  const renderAction = (p, item) => {
    return (
      <>
        <Button
          size="small"
          className="gx-mt-2"
          type="primary"
          onClick={() => {
            setEdit(true);
            item["id"] = item?.documentId;
            setCurrentDocument(item);
            formRef.current.setFields([
              {
                name: "name",
                value: item?.name,
              },
              {
                name: "description",
                value: item?.description,
              },
              {
                name: "documentId",
                value: item?.documentId,
              },
              {
                name: "tags",
                value: item?.tags,
              },
              {
                name: "owners",
                value: item?.owners,
              },
              {
                name: "collection",
                value: item?.collection,
              },
              {
                name: "entityIds",
                value: item?.entityIds,
              },
            ]);

            setDefaultEntityIds(
              _.uniq([...defaultEntityIds, ...item?.entityIds])
            );

            setCurrentSelectedCollection(item?.collection);
            setFileSelectionModal(false);
          }}
        >
          Select
        </Button>

        <Button
          size="small"
          className="gx-mt-2"
          onClick={() => {
            downloadDocument(item?.documentId, item?.source, item?.fileName);
          }}
        >
          Download
        </Button>
      </>
    );
  };

  /**
   * Search Document
   * @param {String} search search query
   */
  const onSearchDocument = ({ search, operator, widgetOption, collection }) => {
    searchDocument(
      sourceName,
      currentDocument,
      (res = {}) => {
        // add parameters to dataSource table.
        const tableData = addParametersToDocument(res?.hitList);

        setSearchResults(tableData);
        setFileSelectionModal(true);
      },
      () => {},
      {
        search,
        operator,
        collection,
        widgetOption,
      }
    );
  };

  const renderFileSelectionModal = ({ collections = [] }) => {
    const collectionOptionsMapped = collections.map((param) => ({
      value: param?.value,
      label: param?.field,
      widget: param?.widget,
    }));

    const renderParameters = (p, { parameters }) => {
      const widgetOption =
        searchDocumentRef.current.getFieldValue("widgetOption");
      return (
        <div>
          {_.map(parameters, (item, key) => {
            return (
              <>
                {item ? (
                  <Popover content={<span>{key}</span>}>
                    <Tag
                      key={uuidv4()}
                      style={{ whiteSpace: "break-spaces", maxWidth: "400px" }}
                      color={widgetOption === key ? "#d9f7be" : "#f0f0f0"}
                    >
                      <span className="gx-text-black">{item}</span>
                    </Tag>
                  </Popover>
                ) : null}
              </>
            );
          })}
        </div>
      );
    };

    const resetTable = () => {
      setFilterValue("");
      setFilteredResult([]);
      setSearchResults([]);
    };

    return (
      <>
        <SearchForDocument
          onFinish={onSearchDocument}
          collectionOptions={collectionOptionsMapped}
          resetTable={resetTable}
          formRef={searchDocumentRef}
        />

        <div>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <span className="gx-text-success">
              Total: {(searchResults || []).length}{" "}
              {pluralize("result", (searchResults || []).length)}.
            </span>
            {!_.isEmpty(filterValue) ? (
              <span className="gx-ml-2 gx-text-secondary">
                Filtered by "{filterValue}": {(filteredResult || []).length}{" "}
                {pluralize("result", (filteredResult || []).length)}
              </span>
            ) : null}
          </div>
          <Table
            dataSource={searchResults}
            size="small"
            onChange={(pagination, filters, sorter, { currentDataSource }) => {
              setFilterValue((_.get(filters, "3") || [])[0]);
              setFilteredResult(currentDataSource);
            }}
          >
            <Table.Column
              title="File Name"
              render={(p, doc) => {
                const { documentId, source, fileName, name, uri } = doc;
                return (
                  <>
                    <AppstoreTwoTone
                      className="gx-mr-2"
                      onClick={() => {
                        setCurrentDocument(doc);
                        setSelectedDocument(doc);
                        setModalOpen(true);
                      }}
                    />
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        downloadDocument(documentId, source, fileName);
                      }}
                    >
                      <span>{fileName || name || uri || "name is empty"}</span>
                    </Link>
                  </>
                );
              }}
              sorter={(a, b) => {
                const nameB =
                  a?.fileName || a?.name || a?.uri || "name is empty";
                const nameA =
                  b?.fileName || b?.name || b?.uri || "name is empty";
                return (nameA || "").localeCompare(nameB || "");
              }}
            ></Table.Column>
            <Table.Column
              title="Source"
              dataIndex="source"
              key="source"
              sorter={(a, b) =>
                (a?.source || "").localeCompare(b?.source || "")
              }
            ></Table.Column>
            <Table.Column title="Data" render={renderParameters}></Table.Column>

            <Table.Column
              title="Action"
              render={renderAction}
              filterDropdown={({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close,
              }) => (
                <TableFilter
                  searchInput={searchInput}
                  dataIndex={"name"}
                  selectedKeys={selectedKeys}
                  setSelectedKeys={setSelectedKeys}
                  confirm={confirm}
                  clearFilters={clearFilters}
                  close={close}
                />
              )}
              filterIcon={(filtered) => (
                <SearchOutlined
                  style={{ color: filtered ? "#1890ff" : undefined }}
                />
              )}
              onFilter={(value, record) => {
                let containsParameter = false;
                const parameters = record["parameters"] || {};

                _.forEach(parameters, (item) => {
                  if (
                    (item || "")
                      .toLowerCase()
                      .includes((value || "").toLowerCase())
                  ) {
                    containsParameter = true;
                  }
                });
                return (
                  containsParameter ||
                  checkField(value, record["fileName"]) ||
                  checkField(value, record["name"]) ||
                  checkField(value, record["uri"]) ||
                  checkField(value, record["collection"])
                );
              }}
            ></Table.Column>
          </Table>
        </div>
      </>
    );
  };

  const resetForm = () => {
    setFiles([]);
    setMimeTypes([]);
    setCurrentSelectedCollection(null);
    setSelectedDocument({});
    setDefaultEntityIds([id]);
    setCurrentDocument({});
    setIsUrl(false);

    formRef.current?.setFields([
      {
        name: "name",
        value: "",
      },
      {
        name: "description",
        value: "",
      },
      {
        name: "documentId",
        value: "",
      },
      {
        name: "tags",
        value: "",
      },
      {
        name: "owners",
        value: "",
      },
      {
        nme: "url",
        value: "",
      },
      {
        name: "collection",
        value: "",
      },
      {
        name: "entityIds",
        value: [...defaultEntityIds],
      },
    ]);
  };

  const renderModal = () => {
    const handleChangeStatus = ({ meta, file }, status) => {
      if (status === "done") {
        formRef.current.setFields([
          {
            name: "fileName",
            value: file?.name,
          },
        ]);
        setFiles([...files, [file]]);
        setMimeTypes([...mimeTypes, [meta?.type]]);
      }
    };

    const onSearchDocument = () => {
      setFileSelectionModal(true);
    };

    const getFilesFromEvent = (e) => {
      return new Promise((resolve) => {
        getDroppedOrSelectedFiles(e).then((chosenFiles) => {
          resolve(chosenFiles.map((f) => f.fileObject));
        });
      });
    };

    const checkIfCompoundExists = () => {
      const entityIds = entityIdsRef.current.changeVal();

      let promises = [];

      _.forEach(entityIds, (entityId) => {
        promises.push(getCompoundConceptShallow(sourceName, entityId));
      });

      Promise.allSettled(promises).then((results) => {
        const failed = _.filter(
          results,
          (result) => result.status === "rejected"
        );

        const ids = _.map(failed, (item) => _.get(item, "reason.config.url"));

        let messages = [];

        _.forEach(ids, (id) => {
          const match = /id=([^&]+)/.exec(id);

          if (match) {
            const idValue = match[1];
            messages.push(idValue);
          }
        });

        NotificationManager.error(
          `Following ids are not found: ${messages.join(", ")}`
        );
      });
    };

    return (
      <>
        {loading ? (
          <div>
            <h3>Uploading File.</h3>
            <ProgressBar completed={getUploadPercentage(progress)} />
            <div>
              {formatBytes(progress?.loaded)} / {formatBytes(progress?.total)}
              <p>{progress?.progress}</p>
            </div>
          </div>
        ) : (
          <div>
            <>
              {!isUrl ? (
                <>
                  {edit ? (
                    <>
                      <Form className="gx-form-row0 gx-mt-3">
                        <Form.Item label="File Name:">
                          <div className="gx-d-flex edit-file-header">
                            <p className="gx-text-warning">
                              {currentDocument?.fileName}
                            </p>
                            {!editMode && (
                              <Button
                                size="small"
                                type="primary"
                                className="gx-mt-2"
                                onClick={() => {
                                  setEdit(false);
                                }}
                              >
                                Upload a new file
                              </Button>
                            )}
                          </div>
                        </Form.Item>
                      </Form>
                    </>
                  ) : (
                    <>
                      <Dropzone
                        onChangeStatus={handleChangeStatus}
                        InputComponent={DropzoneInputWrapper}
                        getFilesFromEvent={getFilesFromEvent}
                      />
                      <div className="gx-mt-3 gx-mb-3 browseFiles">
                        <Button
                          size="small"
                          type="primary"
                          onClick={onSearchDocument}
                        >
                          or Select from Document Repository
                        </Button>
                      </div>
                    </>
                  )}
                </>
              ) : null}
            </>

            <Form
              className="document-form gx-form-row0 gx-mt-3"
              labelCol={{ span: 6 }}
              wrapperCol={{ span: 20 }}
              autoComplete="off"
              ref={formRef}
            >
              <Form.Item
                name="isUrl"
                label="Submit without document"
                className="gx-mt-3"
                labelCol={{ span: 10 }}
              >
                <Checkbox
                  onChange={(e) => {
                    setIsUrl(e.target.checked);
                  }}
                />
              </Form.Item>

              {isUrl && (
                <Form.Item className="gx-mt-3" label="Enter Url" name="url">
                  <Input />
                </Form.Item>
              )}

              <Form.Item
                label="Collection"
                rules={[{ required: true, message: "Please enter collection" }]}
                name="collection"
                className="gx-mt-3"
              >
                <Select
                  onChange={onChangeCurrentCollection}
                  disabled={!!parentId}
                >
                  {currentCollectionOptions.map((item, index) => (
                    <Select.Option value={item?.value} key={index}>
                      {item?.field}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>

              <Form.Item name="name" label="Name" className="gx-mt-3">
                <Input name="name" />
              </Form.Item>

              <Form.Item
                label="Accession Number"
                name="accessionNumber"
                className="gx-mt-3"
              >
                <span>{currentDocument?.accessionNumber}</span>
              </Form.Item>

              <Form.Item
                name="entityIds"
                label="Shared with"
                className="gx-mt-3"
              >
                <div className="gx-d-flex">
                  <InputTag
                    sourceName={sourceName}
                    defaultValues={defaultEntityIds}
                    ref={entityIdsRef}
                  />
                  <Button
                    type="primary"
                    size="small"
                    className="gx-ml-3 gx-mt-3"
                    onClick={checkIfCompoundExists}
                  >
                    Check
                  </Button>
                </div>
              </Form.Item>
              <Form.Item
                name="description"
                label="Description"
                className="gx-mt-3"
              >
                <Input name="description" />
              </Form.Item>
              <Form.Item name="tags" label="Tags" className="gx-mt-3">
                <InputTag
                  sourceName={sourceName}
                  defaultValues={currentDocument?.tags || []}
                  ref={inputTagRef}
                />
              </Form.Item>
              <Form.Item name="owners" label="Owners" className="gx-mt-3">
                <AutoCompleteSelect
                  ref={ownersTagRef}
                  defaultValue={
                    (currentDocument?.owners || []).length
                      ? _.map(currentDocument?.owners || [], (item) => ({
                          label: item,
                          value: item,
                        }))
                      : {
                          label: account?.name || getDocumentRole(),
                          value: account?.username,
                        }
                  }
                />
              </Form.Item>
            </Form>
            {currentSelectedCollection ? (
              <div className="gx-mt-3">
                <MetaWidget
                  ref={metaWidgetRef}
                  id={id}
                  systemWidgetName={currentSelectedCollection}
                  onSaveWidget={(form) => onSaveDocumentTraits(form)}
                  renderResidualData
                  sourceName={sourceName}
                  pageType={pageType}
                  modalView
                  document={currentDocument}
                  collectionOptions={collectionOptions}
                />
              </div>
            ) : null}
            <Button type="primary" className="gx-mt-2" onClick={onSaveFile}>
              Save
            </Button>
          </div>
        )}
      </>
    );
  };

  const renderEditFileModal = () => {
    const { items } = currentCollection;
    let duplicatedFiles = [];
    let lockedFiles = [];
    _.forEach(files, (file) => {
      const currentDocument = _.find(items, { fileName: file?.name });

      if (currentDocument) {
        currentDocument?.locked
          ? lockedFiles.push(file?.name)
          : duplicatedFiles.push(file?.name);
      }
    });

    if (lockedFiles.length && !duplicatedFiles.length) {
      if (!uploadForbidden) {
        setUploadForbidden(true);
      }
      return (
        <div>
          <List
            size="small"
            bordered
            dataSource={lockedFiles}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />

          <p className="gx-mt-3 gx-text-warning">Cannot update locked files.</p>
        </div>
      );
    }

    return (
      <div>
        {(lockedFiles || []).length ? (
          <div>
            <p>Locked Files: Locked files cannot be updated.</p>
            <List
              size="small"
              bordered
              dataSource={lockedFiles}
              renderItem={(item) => (
                <List.Item className="gx-text-warning">{item}</List.Item>
              )}
            />
          </div>
        ) : null}
        <div className="gx-mt-3">
          <p>Duplicated Files:</p>

          <List
            size="small"
            bordered
            dataSource={duplicatedFiles}
            renderItem={(item) => <List.Item>{item}</List.Item>}
          />
        </div>
        <p className="gx-mt-3">
          The {pluralize("file", duplicatedFiles.length)} you are uploading
          already {pluralize("exists", duplicatedFiles.length === 1 ? 2 : 1)}.
          Do you want to overwrite?
        </p>
      </div>
    );
  };

  const onEditFiles = () => {
    const { items } = currentCollection;

    const updatedFiles = files.map((file) => {
      const currentDocument = _.find(items, { fileName: file?.name });

      if (currentDocument) {
        return {
          ...file,
          override: true,
          documentId: currentDocument?.id,
        };
      } else {
        return file;
      }
    });

    uploadDocument(
      id,
      sourceName,
      files,
      {
        entityIds: _.uniq([id, ...defaultEntityIds]),
        collection: currentCollection?.name,
        searchSubjects: [],
        mimeType: currentDocument?.mimeType || files[0]?.type,
        description: getFolderName(files),
        name: files[0]?.name,
        owners: [getDocumentRole()],
      },
      () => {
        setShowModal(false);
        NotificationManager.success("Document has been updated.");
        updateDocuments();
      },
      (e) => {
        setShowModal(false);
        NotificationManager.error(e);
      },
      setLoading,
      setProgress,
      true,
      updatedFiles
    );

    setEditFileModal(false);
  };

  const combinedDataSource = _.filter(dataSource, (current) => {
    const isCurrentCollectionExists = _.find(
      collectionsFiltered,
      (item) => item?.value === current?.name || item?.field === current?.name
    );

    if (isCurrentCollectionExists) {
      return true;
    } else {
      return false;
    }
  });

  function reducer(state, action) {
    if (action.type === "toggle") {
      return {
        ...state,
        [action.payload]: !(_.get(state, action.payload) || false),
      };
    }

    return state;
  }

  const [state, dispatchToggle] = useReducer(reducer, {});

  const currentProgress = Math.floor(
    (Number(progress?.loaded) / Number(progress?.total)) * 100
  );

  return (
    <>
      {/* If Document exists and instance is not, it should display. */}
      {/* {!isCompoundExists && getNotFoundCard({ pageType, renderContact: true })} */}
      {isCompoundExists ||
      (!isCompoundExists && (combinedDataSource || []).length) ? (
        <div
          className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 double-width ${
            open ? "isOpen" : ""
          }
  `}
          key={uuidv4()}
        >
          <Header
            // title={`${widget?.title} ${_.capitalize(pageType)} ${id}`}
            title={title}
            open={open}
            setOpen={setOpen}
            child={renderActionButtons()}
            backgroundColor={widget?.backgroundColor}
            titleColor={widget?.titleColor}
          />

          {open && (
            <div className="gx-ml-3 gx-mr-3 gx-mt-3 gx-pb-3">
              {widgetLoading ? (
                <CircularProgress className="gx-loader-400 loader" />
              ) : (
                <>
                  {loading ? (
                    <div>
                      <span>
                        {currentProgress === 100
                          ? "Processing a file."
                          : "Uploading a file."}
                      </span>
                      <Progress
                        size="small"
                        status="active"
                        percent={currentProgress}
                      />
                    </div>
                  ) : (
                    <>
                      {(combinedDataSource || []).length ? (
                        <>
                          {_.sortBy(combinedDataSource || [], "name").map(
                            (content, index) => {
                              return (
                                <RowDropzoneWrapper
                                  content={content}
                                  setCurrentCollection={setCurrentCollection}
                                  setFiles={setFiles}
                                  setEditFileModal={setEditFileModal}
                                  uploadDocument={uploadDocument}
                                  id={id}
                                  sourceName={sourceName}
                                  setShowModal={setShowModal}
                                  setParentId={setParentId}
                                  updateDocuments={updateDocuments}
                                  defaultEntityIds={defaultEntityIds}
                                  pageType={pageType}
                                  setLoading={setLoading}
                                  setProgress={setProgress}
                                  renderContent={renderContent}
                                  collectionOptions={collectionOptions}
                                  currentOpen={_.get(state, index) || false}
                                  setCurrentOpen={() => {
                                    dispatchToggle({
                                      type: "toggle",
                                      payload: index,
                                    });
                                  }}
                                />
                              );
                            }
                          )}
                        </>
                      ) : (
                        <span className="gx-ml-2">
                          No Documents.{" "}
                          <Button
                            type="primary"
                            style={{ marginTop: "10px", marginLeft: "10px" }}
                            size="small"
                            onClick={() => {
                              resetForm();
                              setEditMode(false);
                              setShowModal(true);
                              setParentId(null);
                            }}
                          >
                            Add Document
                          </Button>
                        </span>
                      )}
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      ) : null}

      <Drawer
        placement="right"
        open={showModal}
        title="Upload File"
        size="large"
        onOk={onSaveFile}
        onClose={() => {
          setEdit(false);
          setShowModal(false);
          setParentId(null);
          resetForm();
        }}
      >
        {renderModal()}
      </Drawer>

      <Modal
        open={modalOpen}
        title={
          <div>
            <span className="gx-mr-3">File Detail</span>
            <PrinterTwoTone onClick={handlePrint} />
          </div>
        }
        width={820}
        onOk={() => setModalOpen(false)}
        onCancel={() => setModalOpen(false)}
      >
        <FileDetailComponent
          ref={fileDetailRef}
          currentDocument={currentDocument}
          selectedDocument={selectedDocument}
          sourceName={sourceName}
          collectionOptions={collectionOptions}
          fileDetailRef={fileDetailRef}
        />
      </Modal>

      <Modal
        open={fileSelectionModal}
        title="Search Results"
        onCancel={() => setFileSelectionModal(false)}
        onOk={() => setFileSelectionModal(false)}
        width={820}
      >
        {renderFileSelectionModal({
          collections: collectionsFiltered,
        })}
      </Modal>
      <Modal
        visible={editFileModal}
        title="Overwrite File"
        onCancel={() => setEditFileModal(false)}
        onOk={() => {
          if (!uploadForbidden) {
            onEditFiles();
          } else {
            setEditFileModal(false);
          }
          setUploadForbidden(false);
        }}
        okText={uploadForbidden ? "OK" : "YES"}
        cancelText="NO"
      >
        {renderEditFileModal()}
      </Modal>
      <NotificationContainer />
    </>
  );
};

DocumentCollection.defaultProps = {
  title: "",
  sourceName: "",
  pageType: "",
};

DocumentCollection.propTypes = {
  id: PT.string.isRequired,
  title: PT.string,
  sourceName: PT.string,
  pageType: PT.string,
};

export default DocumentCollection;
