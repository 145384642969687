import _ from "lodash";
import React, { useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { populateImageMapping } from "../../../ExplorePage/helper";
import { DEFAULT_PAGE_SIZE } from "../../../../constants/Config";
import { fetchWidgetData } from "../../../../appRedux/actions";
import { paginate } from "../../../../util/generic";
import { getRole } from "../../../../util/auth";
import SearchByInput from "./SearchByInput";
import SearchByStructure from "./SearchByStructure";

const ProcessSelection = ({
  availableWidgets,
  sourceName,
  onSelectProcess,
  viewType,
  onSearchByProcess,
  structureSearchResults,
  structureResultLoading,
  availableDataSources,
}) => {
  const { loading, tableData, isInitialized, widgetIdsNormalized } =
    useSelector(({ explore }) => explore);

  const [mapImages, setMapImages] = useState({});
  const [perPage, setPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [imageRendered, setImageRendered] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const dispatch = useDispatch();

  useEffect(() => {
    if (sourceName && isInitialized && (widgetIdsNormalized || []).length) {
      const fil = _.filter(
        widgetIdsNormalized,
        (item) => item?.type !== "system" || item?.type !== "user"
      );
      const paginated = paginate(fil, perPage, page);

      _.forEach(paginated, (widget, index) => {
        if (widget?.type && !_.isEmpty(widget?.id)) {
          dispatch(
            fetchWidgetData({
              widget,
              total: (paginated || []).length,
              currentIndex: index,
              availableWidgets,
              role: getRole(),
            })
          );
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, sourceName, isInitialized, page, perPage, widgetIdsNormalized]);

  useEffect(() => {
    if ((tableData || []).length && !loading) {
      setImageRendered(true);

      populateImageMapping({
        currentPage: page,
        pageSize: perPage,
        searchResults: tableData,
        mapImages,
        setMapImages,
        forceUpdate,
        loadWithPagination: true,
        isEntityIds: page === "documents",
      });
    }
  }, [tableData, page, perPage, loading, mapImages, setMapImages]);

  return (
    <div>
      {viewType === "input" ? (
        <SearchByInput
          sourceName={sourceName}
          mapImages={mapImages}
          setMapImages={setMapImages}
          onSelectProcess={onSelectProcess}
          page={page}
          setPage={setPage}
          perPage={perPage}
          setPerPage={setPerPage}
          availableWidgets={availableWidgets}
        />
      ) : (
        <SearchByStructure
          onSearchByProcess={onSearchByProcess}
          sourceName={sourceName}
          structureSearchResults={structureSearchResults}
          structureResultLoading={structureResultLoading}
          availableDataSources={availableDataSources}
          onSelectProcess={onSelectProcess}
        />
      )}
    </div>
  );
};

export default ProcessSelection;
