import React, { useEffect, useReducer, useRef, useState } from "react";
import GenericEditor from "../../GenericEditor";
import {
  CURRENT_EDITOR_OPTION,
  DATA_WIDGETS_METADATA,
  DOCUMENT_COLLECTION_OPTIONS,
  EDITOR_OPTIONS,
  PROCESS_STEP_COMPONENTS_ROLES,
  ProcessRegistrationTypes,
  REGISTRATION_DATA_WIDGET_NAME,
  SEARCH_OPERATOR_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
} from "../../../constants/Config";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import CircularProgress from "components/CircularProgress";
import {
  BarcodeOutlined,
  CheckOutlined,
  DatabaseOutlined,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { getSourceNamePrefix } from "../../../util/api";
import { getImageFromCompoundId } from "../../../appRedux/services/Search";
import {
  buildForm,
  getImageFromSmilesApi,
  updateSmiles,
} from "../../../appRedux/services/RegisterProcess";
import {
  getDataSources,
  getSourceMap,
  getSystemWidgets,
  setCurrentWidgets,
} from "../../../appRedux/actions";
import { NotificationManager } from "react-notifications";
import { getParent, getPrefix } from "../../../util/Widget";
import { getProcess } from "../../../appRedux/services/Process";
import ProcessTable from "../../RegisterProcess/ProcessTable";
import Widget from "../../DetailPage/Widget";
import { Button, Form, Input } from "antd";
import TextArea from "antd/lib/input/TextArea";
import "./style.css";
import { getCompoundType } from "../../../util/url";
import { getCompoundConceptShallow } from "../../../appRedux/services/Widget";

const ProcessSearch = ({
  onSearch,
  setStructureImage,
  selectedDataSources,
  result,
  setResult,
  components,
  setComponents,
}) => {
  const dispatch = useDispatch();
  const childRef = useRef();
  const [firstRender, setFirstRender] = useState(true);
  const [searchLoading, setSearchLoading] = useState(false);
  const [selectOptions, setSelectOptions] = useState();
  const [cdxml, setCdxml] = useState("");
  const [registeredId, setRegisteredId] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const { dataSources } = useSelector(({ search }) => search);
  const { sourceMap = [] } = useSelector(({ generic }) => generic);
  const [processRegistrationTypes, setProcessRegistrationTypes] = useState([]);

  const [currentProcessId, setCurrentProcessId] = useState();
  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const [searchOps, setSearchOps] = useState([]);

  let defaultSourceName = "";
  if (
    selectedDataSources &&
    _.isArray(selectedDataSources) &&
    selectedDataSources.length
  ) {
    defaultSourceName = selectedDataSources[0];
  }

  /**
   * Fetch search operator options from system widget settings.
   */
  useEffect(() => {
    if (!searchOps.length) {
      getSystemWidget(SEARCH_OPERATOR_OPTIONS, setSearchOps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(
      setCurrentWidgets({
        widgets: [],
        pageName: "",
        database: "",
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (
      components &&
      _.isArray(components) &&
      components.length &&
      childRef &&
      childRef?.current?.isBlankStructure()
    ) {
      await drawToCanvas(components);
    }
  }, [components]);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSystemWidget(PROCESS_STEP_COMPONENTS_ROLES, setSelectOptions);
  }, []);

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        setCurrentEditor(currentOption?.option);
      },
      false
    );
  }, []);

  useEffect(() => {
    let subscribed = true;

    if (subscribed && firstRender) {
      childRef.current?.getMol();
      setFirstRender(false);
    }

    return () => {
      subscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!dataSources.length) {
      dispatch(getDataSources());
    }

    if (!processRegistrationTypes.length) {
      getSystemWidget(ProcessRegistrationTypes, setProcessRegistrationTypes);
    }

    if (!(sourceMap || []).length) {
      dispatch(getSourceMap());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let availableWidgets = [...systemWidgets, ...dataWidgets];

  const getWidgetData = (systemWidgetName) => {
    const targetWidget =
      _.find(availableWidgets, { value: systemWidgetName }) || {};

    return targetWidget?.widget;
  };

  const onUpdateCanvas = async (ops) => {
    await childRef.current?.getDrawing();

    const form = {
      ops,
      database: defaultSourceName,
      sourceNamePrefix: getSourceNamePrefix(defaultSourceName, sourceMap),
    };

    await buildForm(form, setSearchLoading, setComponents, defaultSourceName);
  };

  const canvasChanged = () => {
    childRef.current.getDrawing();
    setRegisteredId(null);
    setCdxml("");

    const image = childRef.current?.getSvg();
    setStructureImage(image);
  };

  useEffect(() => {
    if (!defaultSourceName) {
      NotificationManager.error("Please select data source.");
    } else if (result.length > 0) {
      const item = _.isArray(result) ? result[0] : result;
      const details = item.split(">");

      const types = ["reactant", "catalyst", "product"];
      let ops = [];
      details.forEach((detail, index) => {
        if (!_.isEmpty(detail)) {
          const smiles = detail.split(".");
          smiles.forEach((smile) => {
            ops.push({
              smiles: smile,
              role: types[index],
              index: uuidv4(),
            });
          });
        }
      });
      if (ops.length > 0) {
        onUpdateCanvas(ops);
      }
    } else {
      onUpdateCanvas([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [result]);

  const drawToCanvas = async (newComponents) => {
    let reactants = [];
    let catalysts = [];
    let products = [];

    newComponents.forEach((item) => {
      switch (item?.role) {
        case "reactant":
          reactants.push(item?.smiles);
          break;
        case "product":
        case "by-product":
        case "byproduct":
          products.push(item?.smiles);
          break;
        case "catalyst":
        case "solvent":
          catalysts.push(item?.smiles);
          break;
        default:
          break;
      }
    });

    const finalString = `${reactants.join(".")}>${catalysts.join(
      "."
    )}>${products.join(".")}`;

    childRef.current?.writeToCanvas(finalString);
    setRegisteredId(null);
  };

  const onChangeTypes = async (val, cutoff = 0, index) => {
    let newComponents = components;
    const ind = components.findIndex((item) => item.index === index);

    if (ind !== -1) {
      newComponents[ind].type = val;
      newComponents[ind].cutoff = cutoff;
    }
    setComponents(newComponents);
  };

  const onChangeSubstanceNumber = async (value, index) => {
    let newComponents = components;
    const ind = components.findIndex((item) => item.index === index);

    if (ind !== -1) {
      newComponents[ind].substanceNumber = value;
      const sourceName = getSourceNamePrefix(defaultSourceName, sourceMap);
      const comp = `${sourceName}-${value}`;
      newComponents[ind].compoundId = comp;

      // If component is found in the system, fetch image from component id
      if (newComponents[ind]?.isFound) {
        try {
          const image = await getImageFromCompoundId(comp, defaultSourceName);
          if (image?.data) {
            newComponents[ind].imagePreview = image?.data;
            const smiles = await updateSmiles(comp, sourceMap);
            if (smiles?.data) {
              newComponents[ind].smiles = smiles?.data;
              await drawToCanvas(newComponents);
            }
          }
        } catch (error) {
          newComponents[ind].imagePreview = null;
          newComponents[ind].smiles = "";
        }
      } else {
        const image = await getImageFromSmilesApi(newComponents[ind]?.smiles);
        if (image && image?.data) {
          newComponents[ind].smiles = image?.data;
          await drawToCanvas(newComponents);
        }
      }
    }

    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onChangeRole = async (currentRole, index) => {
    let newComponents = components;
    const ind = components.findIndex((item) => item.index === index);

    if (ind !== -1) {
      newComponents[ind].role = currentRole;
      await drawToCanvas(newComponents);
    }

    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onAddRow = () => {
    let newComponents = components;
    newComponents.push({
      index: uuidv4(),
      smiles: "",
      role: "reactant",
      imagePreview: "",
      substanceNumber: null,
      compoundId: "",
      isFound: true,
    });
    setComponents(newComponents);
    setRegisteredId(null);
    forceUpdate();
  };

  const onDeleteRow = async (index) => {
    const newComponents = components.filter((item) => item.index !== index);
    setComponents(newComponents);
    await drawToCanvas(newComponents);
  };

  const renderStatus = ({ value, step }) => {
    let currentIcon;

    switch (step) {
      case 1:
        currentIcon = <CheckOutlined className="ant-steps-item-icon" />;
        break;
      case 2:
        currentIcon = <BarcodeOutlined className="ant-steps-item-icon" />;
        break;
      default:
        currentIcon = <DatabaseOutlined className="ant-steps-item-icon" />;
        break;
    }

    return (
      <>
        {(value || "").length ? (
          <div className="gx-d-flex gx-mb-3">
            {currentIcon}
            <span
              className="gx-text-primary gx-ml-2"
              style={{ marginTop: "5px" }}
            >
              {value}
            </span>
          </div>
        ) : null}
      </>
    );
  };

  const fetchCdxml = (e) => {
    const val = e.target.value;
    setCurrentProcessId(val);

    let sourceName = (selectedDataSources || []).length
      ? selectedDataSources[0]
      : "";

    const type = getCompoundType({
      sources: dataSources,
      sourceName,
      id: val,
    });

    // Is Component.
    if (type === "experiment" || type === "process") {
      getProcess(
        getParent(val),
        sourceName,
        (res) => {
          if (res && res?.cdxml) {
            childRef.current?.loadCDXML(res?.cdxml);
            canvasChanged();
          } else {
            NotificationManager.error(
              `Reaction drawing for ${val} is not found.`
            );
          }
        },
        () => {}
      );
    } else {
      getCompoundConceptShallow(sourceName, getParent(val))
        .then((res) => {
          if (res && res.data && res.data.smiles) {
            childRef.current?.writeToCanvas(res.data.smiles);
            canvasChanged();
          }
        })
        .catch(() => {
          NotificationManager.error(
            `Reaction drawing for ${val} is not found.`
          );
        });
    }
  };

  const registerProcessContent = (
    <>
      <div className="gx-d-flex">
        <div className="right-content process-components">
          {searchLoading ? (
            <CircularProgress className="gx-loader-400 loader customLoader" />
          ) : (
            <>
              {(selectedDataSources || []).length === 1 &&
                renderStatus({
                  value: `Selected Data Source: ${defaultSourceName}`,
                  step: 0,
                })}
              <ProcessTable
                tableContent={components}
                selectedDataSource={defaultSourceName}
                sourceName={defaultSourceName}
                onChangeSubstanceNumber={onChangeSubstanceNumber}
                selectOptions={selectOptions}
                onChangeRole={onChangeRole}
                onDeleteRow={onDeleteRow}
                sourceIdentifier={getPrefix(sourceMap, defaultSourceName)}
                renderSearchTypes
                searchOps={searchOps}
                onChangeTypes={onChangeTypes}
                selectedDataSources={selectedDataSources}
              />

              {registeredId ? (
                <Widget
                  id={registeredId}
                  systemWidgetName={REGISTRATION_DATA_WIDGET_NAME}
                  renderResidualData
                  widget={getWidgetData(REGISTRATION_DATA_WIDGET_NAME)}
                  onSaveWidget={() => {}}
                  setCurrentVersion={() => {}}
                />
              ) : null}

              <div className="gx-d-flex">
                {!registeredId ? (
                  <Button
                    className="gx-mt-2"
                    type="secondary"
                    size="small"
                    onClick={onAddRow}
                  >
                    Add Row
                  </Button>
                ) : null}

                {childRef?.current?.isBlankStructure() ? (
                  <Form.Item className="gx-ml-3">
                    <Input
                      type="text"
                      size="small"
                      placeholder="or Enter Reaction ID"
                      onPressEnter={fetchCdxml}
                      defaultValue={currentProcessId}
                    />
                  </Form.Item>
                ) : (
                  <Button
                    type="primary"
                    className="gx-mt-2"
                    size="small"
                    onClick={() => onSearch(components)}
                  >
                    Search
                  </Button>
                )}
              </div>
              {cdxml.length ? (
                <TextArea height={400} value={cdxml}></TextArea>
              ) : null}
            </>
          )}
        </div>
      </div>
    </>
  );

  return (
    <div>
      <div
        className="sticky-header-content processSearch__content"
        style={{
          marginTop: "30px",
        }}
      >
        <div className="canvasWrapper">
          <GenericEditor
            currentEditor={currentEditor}
            ref={childRef}
            setDrawing={(data) => setResult(data)}
            setCdxml={(value) => {
              setCdxml(value);
            }}
            canvasChanged={() => canvasChanged()}
          />
        </div>

        <div className="gx-mt-3">
          <>{registerProcessContent}</>
        </div>
      </div>
    </div>
  );
};

export default ProcessSearch;
