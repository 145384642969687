import React from "react";
import PT from "prop-types";
import { EDITOR_OPTIONS } from "../../../constants/Config";
import { Button, Divider, Form, Input, Select, Switch } from "antd";

const EditorSettings = ({
  currentEditor,
  currentRound,
  helmEditor,
  onSaveEditor,
}) => {
  const onFinish = ({ editor, roundSettings, helmEditor }) => {
    onSaveEditor(editor, roundSettings, helmEditor);
  };

  return (
    <Form
      style={{
        maxWidth: "30%",
      }}
      className="gx-ml-3 gx-mt-3"
      onFinish={onFinish}
      initialValues={{
        editor: currentEditor,
        roundSettings: currentRound,
        helmEditor: helmEditor,
      }}
    >
      <Divider>Editor Settings</Divider>

      {/* <Form.Item
        label="Select Editor"
        name="editor"
        required
        rules={{
          required: true,
          message: "Select editor option",
        }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Radio.Group>
          <Radio.Button value={EDITOR_OPTIONS.CHEMDRAW}>Chemdraw</Radio.Button>
          <Radio.Button value={EDITOR_OPTIONS.KETCHER}>Ketcher</Radio.Button>
        </Radio.Group>
      </Form.Item> */}
      <Form.Item
        label="Select Editor"
        name="editor"
        required
        rules={{
          required: true,
          message: "Select editor option",
        }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Select>
          <Select.Option value={EDITOR_OPTIONS.CHEMDRAW}>
            Chemdraw
          </Select.Option>
          <Select.Option value={EDITOR_OPTIONS.KETCHER}>Ketcher</Select.Option>
          {/* <Select.Option value={EDITOR_OPTIONS.HELM}>Helm</Select.Option> */}
        </Select>
      </Form.Item>

      <Divider>HELM Editor Settings</Divider>

      <Form.Item
        label="Enable HELM"
        name="helmEditor"
        required
        rules={{
          required: true,
          message: "Select editor option",
        }}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Switch defaultChecked={helmEditor} />
      </Form.Item>

      <Divider>Rounding Precision Settings</Divider>

      <Form.Item
        label="Rounding precision"
        name="roundSettings"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Input
          type="number"
          placeholder="Enter round"
          style={{ width: "180px" }}
        />
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          size="small"
          htmlType="submit"
          className="gx-ml-3"
        >
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

EditorSettings.propTypes = {
  currentEditor: PT.oneOf(EDITOR_OPTIONS.CHEMDRAW, EDITOR_OPTIONS.KETCHER),
  onSaveEditor: PT.func.isRequired,
};

export default EditorSettings;
