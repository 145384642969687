import React, { useState } from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";

import { DatePicker, Form, Input, Tooltip } from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";
import { trimInput } from "../../../../util/generic";

const ProjectReference = ({
  field,
  name,
  value,
  onChange,
  index,
  addMode,
  type,
  info = "",
  isEditable,
  limit,
  form,
  isRemovable,
  onRemove,
  hideInfo = false,
}) => {
  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const [editMode, setEditMode] = useState(false);
  const onChangeForm = (e) => {
    onChange(e.target.value);
  };

  const setInputValue = (val) => {
    onChange(val);
  };

  // const disabledDate = (current) => {
  //   return current && current.valueOf() > Date.now();
  // };

  return (
    <>
      {addMode ? (
        <div className={"card-item"} key={`editable-input-${index}`}>
          <div className="card-item-field">
            <span>{field}</span>
            {info.length && !hideInfo ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
          </div>
          <div className="card-item-value">
            {value && !editMode ? (
              <div className="gx-d-flex">
                <span className="gx-mr-1">
                  {type === "date" ? moment(value).format(dateFormat) : value}
                </span>
                {isEditable && (
                  <EditTwoTone
                    twoToneColor="#FCB53B"
                    onClick={() => setEditMode(true)}
                  />
                )}
              </div>
            ) : (
              <Form onChange={onChangeForm}>
                <Form.Item>
                  {type === "date" ? (
                    <DatePicker
                      id={`editable-date-input-${index}`}
                      name={name}
                      key={uuidv4()}
                      onChange={(e) => {
                        const date = moment(e).format(dateFormat);
                        setInputValue(date);
                      }}
                      format={dateFormat}
                      defaultValue={value ? moment(value) : ""}
                    />
                  ) : (
                    <Input
                      id={`editable-input-${index}`}
                      type="text"
                      name={name}
                      defaultValue={trimInput(value)}
                      key={uuidv4()}
                      maxLength={limit}
                      onChange={(e) => {
                        e.preventDefault();
                        const val = trimInput(e.target.value);
                        setInputValue(val);
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
            )}
          </div>
        </div>
      ) : (
        <>
          {value ? (
            <div className={"card-item"} key={`editable-input-${index}`}>
              <div className="card-item-field">
                <span>{field}</span>
                {info.length ? (
                  <Tooltip title={info}>
                    <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
                  </Tooltip>
                ) : null}
              </div>
              <div className="card-item-value">
                <div className="gx-d-flex">
                  <span>
                    {type === "date" ? moment(value).format(dateFormat) : value}
                  </span>
                  {isRemovable && (
                    <DeleteTwoTone
                      className="gx-ml-1"
                      twoToneColor="#f44336"
                      onClick={() => onRemove(field)}
                    />
                  )}
                </div>
              </div>
            </div>
          ) : null}
        </>
      )}
    </>
  );
};

ProjectReference.defaultProps = {
  field: "",
  value: "",
  addMode: true,
  type: "",
  info: "",
  isEditable: false,
  limit: null,
  isRemovable: false,
  onRemove: () => {},
};

ProjectReference.propTypes = {
  field: PT.string,
  value: PT.string,
  addMode: PT.bool,
  onChange: PT.func.isRequired,
  type: PT.string,
  info: PT.string,
  isEditable: PT.bool,
  limit: PT.number,
  isRemovable: PT.bool,
  onRemove: PT.func,
};

export default ProjectReference;
