import axios from "axios";
import { NotificationManager } from "react-notifications";
import { config } from "../../constants/Config";

export const getProcess = async (id, sourceName, setFn, setLoadingFn) => {
  return axios
    .get(`${config.SRD_API_URL}/process?id=${id}&source%20name=${sourceName}`, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      setFn(result.data);
    })
    .catch(() => {
      setFn({
        processId: id,
      });
    })
    .finally(() => {
      setLoadingFn(false);
    });
};

export const getCdxml = async (
  processId,
  sourceName,
  setFn,
  setCatchFn = () => {}
) => {
  return axios
    .get(
      `${config.SRD_API_URL}/metadata/process/cdxmlRendering?processId=${processId}&sourceName=${sourceName}`
    )
    .then((result) => {
      setFn(result.data?.parameters?.cdxml);
    })
    .catch((e) => {
      NotificationManager.error(e?.response?.data?.message);
      setCatchFn();
    });
};

export const saveCdxml = async (processId, sourceName, cdxml, setFn) => {
  return axios
    .post(
      `${config.SRD_API_URL}/metadata/process/cdxmlRendering?processId=${processId}&sourceName=${sourceName}`,
      { cdxml: JSON.stringify(cdxml) },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => {
      setFn(result.data?.parameters?.cdxml);
    })
    .catch((e) => {
      console.log(e);
      NotificationManager.error(e?.response?.data?.message);
    });
};

export const saveProcessData = async (
  processId,
  sourceName,
  widgetName,
  body,
  setFn
) => {
  return axios
    .post(
      `${config.SRD_API_URL}/metadata/process/${widgetName}?processId=${processId}&sourceName=${sourceName}`,
      JSON.stringify(body),
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => {
      setFn(result.data?.parameters);
    })
    .catch((e) => {
      console.log(e);
      NotificationManager.error(e?.response?.data?.message);
    });
};

export const getProcessData = async (
  processId,
  sourceName,
  widgetName,
  setFn
) => {
  return axios
    .get(
      `${config.SRD_API_URL}/metadata/process/${widgetName}?processId=${processId}&sourceName=${sourceName}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => {
      setFn(result.data?.parameters);
    })
    .catch((e) => {
      console.log(e);
      // NotificationManager.error(e?.response?.data?.message);
    });
};
