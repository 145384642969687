/* eslint-disable no-unused-vars */
import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  OrderedListOutlined,
  PlusCircleTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import { Popover } from "antd";

const AdditionalComponentHeader = ({
  title,
  open,
  setOpen,
  addNew,
  setAddNew,
  hasDeleteButton,
  onDelete,
  onSave,
  setEditMode,
  onSort,
}) => {
  return (
    <div className="ant-card-head widget-header" key={uuidv4()}>
      <div className="ant-card-head-wrapper gx-d-flex justify-content-between">
        <div className="ant-card-head-title widget-header-title">
          <span>{title}</span>
        </div>

        <div className={`ant-card-extra toggle-button`}>
          {addNew ? (
            <>
              <Popover content="Save component">
                <SaveTwoTone
                  className="gx-ml-2"
                  twoToneColor="#52c41a"
                  onClick={onSave}
                />
              </Popover>
              <Popover content="Close">
                <CloseCircleTwoTone
                  className="gx-ml-2"
                  twoToneColor="#a94442"
                  onClick={() => {
                    setAddNew(false);
                    setEditMode(false);
                  }}
                />
              </Popover>
            </>
          ) : (
            <>
              <Popover content="Add new component">
                <PlusCircleTwoTone
                  className="gx-ml-2"
                  onClick={() => {
                    setAddNew(true);
                    setEditMode(true);
                  }}
                />
              </Popover>
              <Popover content="Edit component">
                <EditTwoTone
                  className="gx-ml-2"
                  twoToneColor="#52c41a"
                  onClick={() => setEditMode(true)}
                />
              </Popover>

              <Popover content="Sort component">
                <OrderedListOutlined
                  size="small"
                  className="gx-ml-2"
                  onClick={onSort}
                />
              </Popover>
            </>
          )}

          {hasDeleteButton && (
            <Popover content="Delete component">
              <DeleteTwoTone twoToneColor="#ba141a" onClick={onDelete} />
            </Popover>
          )}

          <i
            className={`icon gx-pl-2 ${
              open ? "icon-chevron-up" : "icon-chevron-down"
            }`}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
    </div>
  );
};

AdditionalComponentHeader.defaultProps = {
  title: "",
  open: true,
  loading: false,
  addNew: false,
};

AdditionalComponentHeader.propTypes = {
  title: PT.string,
  open: PT.bool,
  setOpen: PT.func.isRequired,
  addNew: PT.bool,
  setAddNew: PT.func.isRequired,
  onSave: PT.func.isRequired,
  setEditMode: PT.func.isRequired,
  onSort: PT.func.isRequired,
};

export default AdditionalComponentHeader;
