import React, { useEffect, useReducer, useState } from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import Header from "../Widget/Header";
import CompoundNotFound from "../../../assets/vendors/notfound.png";
import ImageCarousel from "../../Registration/ImageCarousel";
import { imagePreviewApi } from "../../../appRedux/services/Search";
import _ from "lodash";
import { getRoleId } from "../../../util/auth";
import CircularProgress from "components/CircularProgress";

import { SlidersTwoTone } from "@ant-design/icons";
import { Drawer, Popover } from "antd";
import SortableTable from "../CompositionTable/SortableTable";
import {
  getMedataApi,
  saveMedataApi,
} from "../../../appRedux/services/Metadata";
import { REACTION_COMPONENT_ORDER } from "../../../constants/Config";
import { NotificationManager } from "react-notifications";
import PopoverImage from "../Widget/RowArray/PopoverImage";

const ReactionComponents = ({
  id,
  title,
  sourceName,
  hasDeleteButton,
  onDelete,
  roles,
  processComponents,
}) => {
  const [open, setOpen] = useState(true);
  const [items, setItems] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [sortModalOpen, setSortModalOpen] = useState(false);
  const [compositionOrder, setCompositionOrder] = useState([]);
  const [orderLoaded, setOrderLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!compositionOrder.length && !orderLoaded) {
      setOrderLoaded(true);
      getMedataApi(
        REACTION_COMPONENT_ORDER,
        id,
        sourceName,
        (result = {}) => {
          const orders = result?.parameters?.options || [];
          setCompositionOrder(orders);
          setLoading(false);
        },
        () => {
          setLoading(false);
          setCompositionOrder([{}]);
        }
      );
    }
  }, [compositionOrder.length, id, items, orderLoaded, sourceName]);

  useEffect(() => {
    let current = processComponents || [];
    current.forEach((item) => {
      if (!item?.imagePreview) {
        imagePreviewApi(item?.id, () => {}, true, sourceName).then((res) => {
          if (res.data) {
            item.imagePreview = res.data;
            forceUpdate();
          }
        });
      }
    });

    setItems(current);
  }, [processComponents, sourceName]);

  const hasAccess = (roles || []).includes(getRoleId());

  const renderActionButtons = () => {
    if (!hasAccess) return null;

    return (
      <>
        <SlidersTwoTone
          className="gx-ml-2"
          onClick={() => setSortModalOpen(true)}
        />
      </>
    );
  };

  const sortedReactionComponents = (items || []).sort((a, b) => {
    const firstId = a?.instanceId || a?.compoundId || a?.id;
    const secondId = b?.instanceId || b?.compoundId || b?.id;

    const firstIndex = (_.find(compositionOrder, { id: firstId }) || {})?.order;
    const secondIndex = (_.find(compositionOrder, { id: secondId }) || {})
      ?.order;

    return firstIndex - secondIndex;
  });

  const onSaveSorting = (sortedOptions) => {
    const targetSortedOptions = _.map(sortedOptions, (option, index) => ({
      ...option,
      order: index,
    }));

    saveMedataApi(
      REACTION_COMPONENT_ORDER,
      id,
      sourceName,
      targetSortedOptions
    );

    setCompositionOrder(targetSortedOptions);

    NotificationManager.success("Successfully saved compositions.");

    setSortModalOpen(false);

    forceUpdate();
  };

  const popoverContent = (id, source) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-start",
          height: "100%",
        }}
      >
        <PopoverImage rmoId={id} sourceName={source} />
        <span className="gx-text-primary" style={{ textAlign: "center" }}>
          {id}
        </span>
      </div>
    );
  };

  return (
    <>
      <div
        className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
          open ? "isOpen" : ""
        }
  `}
        key={uuidv4()}
      >
        <Header
          title={title}
          open={open}
          setOpen={setOpen}
          hasDeleteButton={hasDeleteButton}
          onDelete={onDelete}
          child={renderActionButtons()}
        />

        {open && (
          <div>
            {loading ? (
              <div
                style={{
                  height: "150px",
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress className="custom-loader" />
              </div>
            ) : (
              <div className="ant-card-body widget-body">
                {(sortedReactionComponents || []).map((item) => (
                  <div
                    className="card-item additional-component-card-item"
                    style={{ height: "64px" }}
                  >
                    <div
                      className="card-item-field"
                      style={{ marginTop: "-15px" }}
                    >
                      {item?.imagePreview ? (
                        <ImageCarousel
                          structureImage={[item?.imagePreview]}
                          setVisible={() => {}}
                          width={47}
                        />
                      ) : (
                        <img
                          src={CompoundNotFound}
                          alt="compound-not-found"
                          width={47}
                          height={47}
                          className="compound-not-found"
                        />
                      )}
                    </div>
                    <div className="gx-flex" style={{ width: "40%" }}>
                      <div
                        className="card-item-field gx-flex-row"
                        style={{
                          justifyContent: "space-between !important",
                          flexDirection: "row",
                          paddingTop: "10px",
                        }}
                      >
                        <span className="gx-text-primary">Role</span>
                        <span className="gx-ml-5">{item?.role}</span>
                      </div>
                      <div
                        className="card-item-field gx-flex-row"
                        style={{
                          justifyContent: "space-between !important",
                          flexDirection: "row",
                        }}
                      >
                        <span className="gx-text-primary">Substance Id</span>
                        <Popover content={popoverContent(item?.id, sourceName)}>
                          <span
                            className="gx-ml-3 gx-text-primary gx-pointer"
                            onClick={() => {
                              window.location.assign(
                                `/${sourceName}/compound/${item?.id}`
                              );
                            }}
                          >
                            {item?.id}
                          </span>
                        </Popover>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>

      <Drawer
        title="Sort Reaction Component Items"
        open={sortModalOpen}
        okText="Save"
        onClose={() => setSortModalOpen(false)}
        footer={null}
      >
        <SortableTable
          options={sortedReactionComponents}
          onCancel={() => setSortModalOpen(false)}
          onSaveSorting={onSaveSorting}
          decomposeTable={() => {}}
          disableDecompose
        />
      </Drawer>
    </>
  );
};

ReactionComponents.defaultProps = {
  title: "",
  sourceName: "",
  pageType: "",
};

ReactionComponents.propTypes = {
  id: PT.string.isRequired,
  title: PT.string,
  sourceName: PT.string,
  pageType: PT.string,
};

export default ReactionComponents;
