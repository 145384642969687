import { all } from "redux-saga/effects";
import authSagas from "./Auth";
import structureRegistrationSagas from "./StructureRegistration";
import metadataSagas from "./Metadata";
import systemMetadataSagas from "./SystemMetadata";
import searchSagas from "./Search";
import genericSagas from "./Generic";
import projectSagas from "./Project";
import currentUserDataSagas from "./CurrentUserData";
import documentSagas from "./Document";
import lookupSagas from "./Lookup";
import graphSagas from "./Graph";
import exploreSagas from "./Explore";

export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    structureRegistrationSagas(),
    metadataSagas(),
    systemMetadataSagas(),
    searchSagas(),
    genericSagas(),
    projectSagas(),
    currentUserDataSagas(),
    documentSagas(),
    lookupSagas(),
    graphSagas(),
    exploreSagas(),
  ]);
}
