import { Button, Checkbox, Divider, Form, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import _ from "lodash";
import React, { useState } from "react";

const CloneInstance = ({
  collections,
  onCloneInstance,
  setShowModal,
  collectionOptions,
  availableWidgets,
}) => {
  const [form] = useForm();
  const [fileOptions, setFileOptions] = useState([]);

  const handleFormValuesChange = ({ collection, document }) => {
    if (collection) {
      setFileOptions(_.get(collections, collection) || []);
    }
  };

  const widgets = _.map(availableWidgets, (item) => ({
    label: item?.field,
    value: item?.value,
  }));

  const filterDocumentWidget = _.filter(
    widgets,
    (item) =>
      item?.value !== "DocumentCollections" &&
      item?.value !== "cdxmlRendering" &&
      item?.value !== "Qualities"
  );

  const defaultOptions = _.map(filterDocumentWidget, (item) => item?.value);

  const filteredCollectionOptions = _.filter(
    collectionOptions || [],
    (option) => {
      return (_.get(collections, option?.value) || []).length;
    }
  );

  return (
    <Form
      form={form}
      name="selectCollection"
      onValuesChange={handleFormValuesChange}
      className="gx-form-row0 gx-mt-3"
      initialValues={{
        options: defaultOptions,
      }}
      onFinish={({ options, collection, document }) => {
        onCloneInstance(options, collection, document);
        setShowModal(false);
      }}
    >
      <Form.Item
        name="options"
        label="Select data to be cloned"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
      >
        <Checkbox.Group
          className="checkboxGroup"
          options={filterDocumentWidget}
        ></Checkbox.Group>
      </Form.Item>

      <Form.Item
        label="Select Collection"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="collection"
      >
        <Select
          showSearch
          options={_.map(filteredCollectionOptions, (option) => ({
            ...option,
            label: option?.field,
          }))}
        />
      </Form.Item>

      {(fileOptions || []).length ? (
        <Form.Item
          label="Select Document"
          name="document"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            defaultValue={fileOptions[0]?.value}
            options={_.map(fileOptions, (item) => ({
              label: item?.fileName,
              value: item?.id,
            }))}
          />
        </Form.Item>
      ) : null}

      <Form.Item>
        <Divider />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "-30px",
          }}
        >
          <Button type="secondary" onClick={() => setShowModal(false)}>
            Cancel
          </Button>
          <Button type="primary" htmlType="submit">
            Clone
          </Button>
        </div>
      </Form.Item>
    </Form>
  );
};

export default CloneInstance;
