import React from "react";
import ImageCarousel from "components/Registration/ImageCarousel";

import "./style.css";
import { useSelector } from "react-redux";
import { Popover } from "antd";
import _ from "lodash";

const CompositionImage = ({ id, compositions = [] }) => {
  const lookupData = useSelector(({ lookup }) => lookup);
  const { [id]: data = {} } = lookupData;
  const imagePreview = data?.image;

  let loading = false;
  _.forEach(compositions, (composition) => {
    if (_.get(lookupData, `${composition?.compoundId}.loading`)) {
      loading = true;
    }
  });

  if (loading) {
    return (
      <div
        style={{
          width: "100px",
          height: "100px",
        }}
      >
        <div
          style={{
            width: "100px",
            height: "100px",
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              fontSize: "10px",
              verticalAlign: "middle",
              height: "100%",
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            <span className="gx-text-warning">Loading data...</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {imagePreview ? (
        <div
          style={{
            width: "100px",
            height: "100px",
          }}
        >
          <ImageCarousel
            structureImage={[imagePreview]}
            className="compound-image-preview width-80"
            setVisible={() => {}}
            noBorder
          />
        </div>
      ) : (
        <div
          style={{
            width: "100px",
            height: "100px",
            textAlign: "center",
            justifyContent: "center",
            flexDirection: "column",
            alignSelf: "center",
            alignItems: "center",
          }}
        >
          <span
            className="gx-text-danger"
            style={{
              fontSize: "10px",
              verticalAlign: "middle",
              height: "100%",
              justifyContent: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            {id !== undefined ? (
              <Popover content={`${id} cannot be found or doesn't exist.`}>
                <span>Not found</span>
              </Popover>
            ) : (
              <Popover content="Please enter id">
                <span>Not specified</span>
              </Popover>
            )}
          </span>
        </div>
      )}
    </>
  );
};

export default CompositionImage;
