import React from "react";
import { Image, Popconfirm, Popover, Tag } from "antd";

import { useSelector } from "react-redux";
import _ from "lodash";
import CompoundNotFound from "../../../../assets/vendors/notfound.png";
import { Link } from "react-router-dom";
import { DeleteTwoTone, PlusCircleOutlined } from "@ant-design/icons";
import { checkRowIsAccessible } from "../../../../util/auth";
import { v4 as uuidv4 } from "uuid";
import ExperimentList from "../../../ExperimentList";

import "./style.css";

const ContactCell = ({
  contact,
  sourceName,
  onDeleteProcess = () => {},
  onAddExperiment,
  projectSettings = [],
  renderExperiments = false,
  isSearchResult = false,
  hideAction = false,
}) => {
  const { processId, stepNo, foundProcess } = contact;
  const { imageMap } = useSelector(({ project }) => project);
  const { [processId]: lookupData } = useSelector(({ lookup }) => lookup);

  const filterSettingsByRole = _.filter(projectSettings, (setting) => {
    return checkRowIsAccessible(setting?.isAccessible);
  });

  const filterByType = _.filter(filterSettingsByRole, (setting) => {
    return setting?.type === "Process";
  });

  return (
    <div className="gx-mt-2">
      <div className="gx-contact-item" style={{ width: "100%" }}>
        <div className="gx-module-list-icon">
          <div className="gx-d-none gx-d-sm-flex"></div>
          <div className="gx-ml-2 gx-d-none gx-d-sm-flex">
            {_.get(imageMap, processId) ? (
              <Image
                src={`data:image/svg+xml;utf8,${encodeURIComponent(
                  _.get(imageMap, processId)
                )}`}
                className="project-image"
                onClick={(e) => e.stopPropagation()}
              />
            ) : (
              <img
                src={CompoundNotFound}
                alt="compound-not-found"
                className="compound-not-found"
                width={64}
                height={64}
              />
            )}
          </div>
        </div>

        <div className="gx-module-list-info gx-contact-list-info">
          <div className="gx-module-contact-content">
            <div className="gx-d-flex">
              <p className="gx-mb-1">
                <span className="gx-text-truncate gx-contact-name">
                  {" "}
                  <Link
                    to={`/${sourceName}/process/${processId}`}
                    target="_blank"
                  >
                    {isSearchResult && foundProcess ? (
                      <span className="gx-text-warning">{processId}</span>
                    ) : (
                      <span>{processId}</span>
                    )}
                  </Link>{" "}
                </span>
                <span className="gx-toolbar-separator">&nbsp;</span>
                <span className="gx-text-truncate gx-job-title">
                  Step No: {stepNo}
                </span>
              </p>

              {hideAction ? null : (
                <>
                  <Popover content="Add experiment">
                    <PlusCircleOutlined
                      className="gx-ml-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        onAddExperiment(contact);
                      }}
                    />
                  </Popover>

                  <Popconfirm
                    title="Are you sure to delete this process?"
                    onConfirm={(e) => {
                      e.stopPropagation();
                      onDeleteProcess(contact);
                    }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteTwoTone
                      className="gx-pointer gx-ml-3"
                      style={{
                        marginTop: "1px",
                      }}
                      twoToneColor="#f44336"
                      onClick={(e) => e.stopPropagation()}
                    />
                  </Popconfirm>
                </>
              )}

              {!_.isEmpty(filterByType) && _.isArray(filterByType) ? (
                <div className="gx-ml-3">
                  {_.map(filterByType, (setting) => {
                    const val = _.get(lookupData, `metadata.${setting?.field}`);
                    if (_.isEmpty(val)) {
                      return null;
                    }
                    return (
                      <Popover content={setting?.name}>
                        <Tag key={uuidv4()} color="#2db7f5">
                          {val}
                        </Tag>
                      </Popover>
                    );
                  })}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      {renderExperiments ? (
        <div>
          <ExperimentList
            experiments={contact?.experiments || []}
            process={contact}
            sourceName={sourceName}
            isSearchResult={isSearchResult}
          ></ExperimentList>
        </div>
      ) : null}
    </div>
  );
};

export default ContactCell;
