/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Divider, Form, Input, Row, Select, Switch } from "antd";
import PT from "prop-types";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import {
  DOCUMENT_COLLECTION_OPTIONS,
  TargetObjectOptions,
} from "../../../../../constants/Config";

const ArrayForm = ({
  onChangeValue,
  form,
  roles,
  settingsWidgetName,
  isColumnSetting = false,
}) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.id) ? currentRole[item?.id] : false;
      role[item?.id] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  return (
    <>
      <Col>
        <Form.Item
          label="Source Object"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            options={TargetObjectOptions}
            onChange={(e) => {
              onChangeValue("targetObj", e);
            }}
            value={form?.targetObj}
          ></Select>
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Value"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.value}
            onChange={(e) => onChangeValue("value", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Preview Image"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            style={{ height: "20px" }}
            value={
              _.isObject(form?.previewImage)
                ? JSON.stringify(form?.previewImage)
                : form?.previewImage
            }
            onChange={(e) => onChangeValue("previewImage", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Options"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            style={{ height: "20px" }}
            value={
              _.isObject(form?.options)
                ? JSON.stringify(form?.options)
                : form?.options
            }
            onChange={(e) => onChangeValue("options", e.target.value)}
          />
        </Form.Item>
      </Col>
      {/* <Col>
        <Form.Item
          label="Tooltip"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.info}
            onChange={(e) => onChangeValue("info", e.target.value)}
          />
        </Form.Item>
      </Col> */}

      <Col>
        {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
          <Form.Item
            label="is Header Field"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isInlineField") || false}
              onChange={(e) => onChangeValue("isInlineField", e)}
            />
          </Form.Item>
        ) : null}
      </Col>

      <Divider className="gx-text-primary">Additional Settings</Divider>

      <Col className="gx-d-flex">
        {/* <Form.Item
          label="Is Required Field"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.required || false}
            onChange={(e) => onChangeValue("required", e)}
          />
        </Form.Item> */}
        <Form.Item
          label="Is Copiable"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={_.get(form, "isCopiable") || false}
            onChange={(e) => onChangeValue("isCopiable", e)}
          />
        </Form.Item>

        <Form.Item
          label="Display as one line"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.isTrimmed || false}
            onChange={(e) => onChangeValue("isTrimmed", e)}
          />
        </Form.Item>

        <Form.Item
          label="Is Searchable"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={_.get(form, "isSearchable") || false}
            onChange={(e) => onChangeValue("isSearchable", e)}
          />
        </Form.Item>

        <Row>
          {!isColumnSetting ? (
            <Form.Item
              label="Has Tooltip"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={form?.hasTooltip}
                onChange={(e) => onChangeValue("hasTooltip", e)}
              />
            </Form.Item>
          ) : null}
          {!isColumnSetting && form?.hasTooltip ? (
            <Form.Item
              label="Tooltip"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                value={form?.info}
                onChange={(e) => onChangeValue("info", e.target.value)}
              />
            </Form.Item>
          ) : null}
        </Row>
      </Col>

      <Divider className="gx-text-primary">Roles Settings</Divider>

      <div>
        <p className="gx-mb-3 gx-text-secondary">Edit Roles:</p>
        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isEditable, role?.id) || false}
                onChange={(e) => onChangeRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>

      <div>
        <p className="gx-mb-3 gx-text-secondary">Access Roles:</p>

        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isAccessible, role?.id) || false}
                onChange={(e) => onChangeAccessRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>
    </>
  );
};

ArrayForm.defaultProps = {
  form: {},
};

ArrayForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default ArrayForm;
