import React, { useEffect, useRef, useState } from "react";
import PT from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import {
  closeAlert,
  getElementalAnalysis,
  getSystemWidgets,
  saveMetadata,
  setCurrentWidgets,
} from "../../appRedux/actions";
import SweetAlert from "react-bootstrap-sweetalert";
import _ from "lodash";

import "./style.css";
import {
  getCompoundConcept,
  getCompoundConceptForSpecificVersion,
} from "../../appRedux/services/Metadata";
import {
  CURRENT_EDITOR_OPTION,
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  EDITOR_OPTIONS,
  EXCEL_TEMPLATE_SOURCE_NAME,
  EXPERIMENT_DATA_WIDGET,
  GLOBAL_LAYOUT_SETTINGS,
  NAVIGATION_URL_PREFIX,
  PAGE_TYPES,
  REGISTRATION_DATA_WIDGET_NAME,
  SAFETY_SENTENCES_IMAGE_METADATA,
  SAFETY_SENTENCES_METADATA,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  USER_LAYOUT_SETTINGS,
} from "../../constants/Config";
import { Button, Drawer, Popover } from "antd";
import NavButton from "components/DetailPage/NavButton";
import {
  getNotFoundCard,
  getParent,
  isChildComponent,
} from "../../util/Widget";
import {
  cloneExperimentMetadata,
  getSources,
  getVersionNumbers,
} from "../../appRedux/services/Widget";
import { EditOutlined, UpOutlined } from "@ant-design/icons";
import DefaultLayout from "../../components/DetailPage/DefaultLayout";
import { getRole, getUserName } from "../../util/auth";
import {
  getSystemWidget,
  getUserWidget,
  saveSystemWidget,
} from "../../appRedux/services/SystemWidget";
import CustomizableLayout from "../../components/DetailPage/CustomizableLayout";
import { capitalizeText, getBackButtonLink } from "../../util/url";
import {
  getCompoundInstance,
  getProcessComponents,
} from "../../appRedux/services/CompoundInstance";
import DeleteIcon from "../../assets/vendors/bin.png";
import { getProcess } from "../../appRedux/services/Process";
import ExportExcel from "../../components/DetailPage/ExportExcel";
import WidgetPlaceHolder from "../../components/WidgetPlaceholder";
import { NotificationManager } from "react-notifications";
import { useMsal } from "@azure/msal-react";
import DocumentationPopover from "../../components/DocumentationPopover";
import DocumentationSettings from "../../components/DocumentationSettings";
import { searchDocumentation } from "../UploadPage/helper";
import { getProjectDetail, getProjects } from "../../appRedux/services/Project";
import { cloneDocumentApi } from "../../appRedux/services/Document";
import useGetRoles from "../../hooks/detailPage/useGetRoles";
import { filterByAuthorizedRoles } from "../../util/search";
import DeleteDrawer from "../../components/DetailPage/DeleteDrawer";
import {
  deleteCompoundConceptApi,
  getCompoundConceptGraph,
  restoreCompoundConceptApi,
  restoreQualityApi,
} from "../../appRedux/services/Lookup";
import { getAssociates } from "../../appRedux/services/DetailPage";
import { getSample } from "../../appRedux/services/Sample";

const DetailPage = (props) => {
  const dispatch = useDispatch();
  const { id, source, pageType } = props.match.params;

  const [loading, setLoading] = useState(false);
  const [compoundInstanceLoading, setCompoundInstanceLoading] = useState(false);
  const [compoundDetail, setCompoundDetail] = useState({});
  const [compoundInstance, setCompoundInstance] = useState({});

  const [pageSettings, setPageSettings] = useState({});
  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [currentSettings, setCurrentSettings] = useState(null);
  const [globalSettings, setGlobalSettings] = useState([]);
  const [additionalComponents, setAdditionalComponents] = useState([]);
  const [processComponents, setProcessComponents] = useState([]);
  const [processVersions, setProcessVersions] = useState(1);
  const [processDetail, setProcessDetail] = useState({});
  const [currentProcessVersion, setCurrentProcessVersion] = useState(1);
  const [documentations, setDocumentations] = useState([]);
  const [defaultSourceName, setDefaultSourceName] = useState({});
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);

  const [conceptGraph, setConceptGraph] = useState({});

  const [sampleDetail, setSampleDetail] = useState(null);

  const [samples, setSamples] = useState([]);
  const [sampleVersions, setSampleVersions] = useState();

  const [deleteMode, setDeleteMode] = useState(false);
  const [showDeleteDrawer, setShowDeleteDrawer] = useState(false);

  const [projects, setProjects] = useState([]);

  const [dataSources, setDataSources] = useState([]);
  const [navigationMapping, setNavigationMapping] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [layout, setLayout] = useState([]);
  const [visible, setVisible] = useState(false);
  const [selectedCollection, setSelectedCollection] = useState();
  const [drawnStructureId, setDrawnStructureId] = useState();
  const [isUndefinedVersion, setIsUndefinedVersion] = useState(false);
  const [currentCompoundVersion, setCurrentCompoundVersion] = useState({});

  const [currentEditor, setCurrentEditor] = useState(EDITOR_OPTIONS.CHEMDRAW);
  const [currentRound, setCurrentRound] = useState();

  const [currentVersion, setCurrentVersion] = useState();
  const [versions, setVersions] = useState([]);
  const [compoundDetailVersions, setCompoundDetailVersions] = useState([]);
  const [compoundDetailCurrentVersion, setCompoundDetailCurrentVersion] =
    useState([]);
  const [compoundDetailQuality, setCompoundDetailQuality] = useState([]);
  const [compoundInstanceLoaded, setCompoundInstanceLoaded] = useState(false);

  const isChild = isChildComponent(pageType);
  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const userNameWithRole = `${userName}-${getRole()}`;
  const childRef = useRef();
  const [compoundImageLoading, setCompoundImageLoading] = useState(false);
  const [associates, setAssociates] = useState([]);

  const showTraining = pageSettings?.showTraining !== "hide";

  // composition table data
  const [safetySentenceTypes, setSafetySentenceTypes] = useState([]);
  const [safetySentenceImageMetadata, setSafetySentenceImageMetadata] =
    useState([]);
  // eslint-disable-next-line no-unused-vars
  const [selectedLanguage, setSelectedLanguage] = useState(
    "SafetySentencesLangEn"
  );
  const [safetySentenceLanguageMap, setSafetySentenceLanguageMap] = useState(
    []
  );
  const pageSettingsMetadata = `PageSettingsMetadata-${
    accounts[0]?.username || getUserName()
  }`;

  const { [DOCUMENT_COLLECTION_OPTIONS]: documentCollectionOptions = {} } =
    useSelector(({ systemMetadata }) => systemMetadata);

  const { currentView } = useSelector(({ auth }) => auth);

  const roles = useGetRoles();

  let sourceName = defaultSourceName?.option;

  useEffect(() => {
    if (pageType === PAGE_TYPES.QUALITY) {
      getAssociates(id, source, (items) => {
        // Filter main component from return object.
        const mainComponent = getParent(id);
        const filteredItems = _.filter(items, (item) => item !== mainComponent);
        setAssociates(filteredItems);
      });
    }
  }, [id, pageType, source]);

  useEffect(() => {
    if (pageType === PAGE_TYPES.SAMPLE && _.isEmpty(sampleDetail)) {
      getSample({
        id,
        sourceName: source,
        setFn: (detail) => {
          setSampleDetail(detail);
          setSamples(detail?.samples || []);
          setSampleVersions(detail?.version);
        },
      });
    }
  }, [id, pageType, sampleDetail, source]);

  useEffect(() => {
    if (pageType === PAGE_TYPES.SAMPLE && !_.isEmpty(sampleDetail)) {
      const currentId =
        pageType === PAGE_TYPES.SAMPLE
          ? getParent(sampleDetail?.instanceId)
          : id;

      if (currentId && source) {
        getCompoundConceptGraph({ id: currentId, sourceName: source }).then(
          (res) => {
            setConceptGraph(res?.data?.graph);
          }
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sampleDetail]);

  useEffect(() => {
    let currentPageId =
      pageType === PAGE_TYPES.PROCESS || pageType === PAGE_TYPES.EXPERIMENT
        ? getParent(compoundDetail?.processId)
        : getParent(compoundDetail?.compoundId);

    if (pageType === PAGE_TYPES.PROJECT) {
      currentPageId = id;

      if (pageType === PAGE_TYPES.PROJECT && _.isEmpty(compoundDetail)) {
        getProjectDetail(
          id,
          source,
          (detail) => {
            setCompoundDetail(detail);
          },
          () => {}
        );
      }
    }

    if (pageType && source && getParent(currentPageId) !== getParent(id)) {
      if (
        pageType === PAGE_TYPES.PROCESS ||
        pageType === PAGE_TYPES.EXPERIMENT
      ) {
        getProcess(
          getParent(id),
          source,
          (detail) => {
            setCompoundDetail(detail);
            setCompoundDetailQuality(detail);
            setCurrentVersion(detail?.version || 1);
          },
          setLoading
        );
      } else if (
        pageType === PAGE_TYPES.COMPOUND ||
        pageType === PAGE_TYPES.QUALITY
      ) {
        getCompoundConcept(
          getParent(id),
          source,
          null,
          (detail) => {
            setCompoundDetail(detail);
            setCompoundDetailQuality(detail);
            setCompoundDetailCurrentVersion(detail?.version || 1);
            // setCurrentVersion(detail?.version || 1);
            setDrawnStructureId(detail?.drawnStructureId);

            if (
              pageType === PAGE_TYPES.COMPOUND &&
              (detail?.components || []).length
            ) {
              setAdditionalComponents(detail?.components || []);
            }
          },
          () => {
            // setCurrentVersion(1);
            getCompoundConcept(getParent(id), source, 1, (detail) => {
              setCurrentCompoundVersion(detail);
              setDrawnStructureId(detail?.drawnStructureId);
            });
          },
          setLoading,
          setCompoundInstanceLoading
        );
      }
    }

    return () => {
      // setCurrentVersion(null);
    };
  }, [compoundDetail, id, pageType, source]);

  useEffect(() => {
    if (!versions.length && id && source) {
      getVersionNumbers(source, id, pageType, setVersions);
    }
  }, [compoundDetail, id, pageType, source, versions.length]);

  useEffect(() => {
    if (
      pageType === PAGE_TYPES.QUALITY &&
      id &&
      source &&
      !compoundDetailVersions.length
    ) {
      getVersionNumbers(
        source,
        getParent(id),
        PAGE_TYPES.COMPOUND,
        setCompoundDetailVersions
      );
    }
  }, [compoundDetailVersions.length, id, pageType, source]);

  const updateConceptGraph = () => {
    if (pageType === PAGE_TYPES.SAMPLE && !_.isEmpty(sampleDetail)) {
      const currentId =
        pageType === PAGE_TYPES.SAMPLE
          ? getParent(sampleDetail?.instanceId)
          : id;

      if (currentId && source) {
        getCompoundConceptGraph({ id: currentId, sourceName: source }).then(
          (res) => {
            setConceptGraph(res?.data?.graph);
          }
        );
      }
    }
  };

  const onChangeVersion = async (page) => {
    const total = versions.length;
    let current = page;

    if (page === total) {
      current = null;
    }

    setCompoundImageLoading(true);
    getCompoundConceptForSpecificVersion(
      getParent(id),
      source,
      current,
      (detail) => {
        setCompoundDetail(detail);
        setCurrentVersion(detail?.version || 1);
        setDrawnStructureId(detail?.drawnStructureId);
        setCompoundImageLoading(false);
      },
      (e) => {
        NotificationManager.error(e?.response?.data?.message);
        setCompoundImageLoading(false);
      },
      () => {},
      setCompoundInstanceLoading
    );
  };

  const onChangeVersionWithQualityPage = async (page) => {
    const total = compoundDetailVersions.length;
    let current = page;

    if (page === total) {
      current = null;
    }

    setCompoundImageLoading(true);
    getCompoundConceptForSpecificVersion(
      getParent(id),
      source,
      current,
      (detail) => {
        setCompoundDetailQuality(detail);
        setCompoundDetailCurrentVersion(detail?.version || 1);
        setDrawnStructureId(detail?.drawnStructureId);
        setCompoundImageLoading(false);
      },
      (e) => {
        NotificationManager.error(e?.response?.data?.message);
        setCompoundImageLoading(false);
      },
      () => {},
      setCompoundInstanceLoading
    );
  };

  const onChangeQualityVersion = async (page) => {
    const total = versions.length;
    let current = page;

    setCurrentVersion(page);

    if (page === total) {
      current = null;
    }

    let compoundInstanceId = id;

    if (pageType === PAGE_TYPES.SAMPLE) {
      compoundInstanceId = sampleDetail?.instanceId;
    }

    getCompoundInstance(
      compoundInstanceId,
      source,
      (detail) => {
        setCompoundInstance(detail);
        if (pageType === PAGE_TYPES.COMPOUND) {
          setAdditionalComponents([detail?.components || []]);
        } else if (
          pageType === PAGE_TYPES.QUALITY ||
          pageType === PAGE_TYPES.SAMPLE
        ) {
          const { instanceComponents = [], conceptComponents = [] } = detail;
          setAdditionalComponents([
            ...instanceComponents,
            ...conceptComponents,
          ]);
        } else if (pageType === PAGE_TYPES.EXPERIMENT) {
          setAdditionalComponents(detail?.experimentComponents || []);
        } else {
          setCompoundDetail(detail);
        }

        // setCurrentVersion(detail?.version || 1);
      },
      pageType,
      current,
      () => {}
    );
  };

  const onChangeChemdrawVersion = async (page) => {
    const total = (processVersions || []).length;
    let current = page;

    setCurrentProcessVersion(page);

    if (page === total) {
      current = null;
    }

    getProcessComponents(
      getParent(id),
      source,
      setProcessComponents,
      setProcessDetail,
      pageType,
      setProcessVersions,
      setCurrentProcessVersion,
      current,
      false
    );
  };

  const onChangeQualityVersionRestored = async (page) => {
    setCurrentVersion(page);
    getCompoundInstance(
      id,
      source,
      (detail) => {
        setCompoundInstance(detail);
        if (pageType === PAGE_TYPES.QUALITY || pageType === PAGE_TYPES.SAMPLE) {
          const { instanceComponents = [], conceptComponents = [] } = detail;
          setAdditionalComponents([
            ...instanceComponents,
            ...conceptComponents,
          ]);
        } else if (pageType === PAGE_TYPES.EXPERIMENT) {
          setAdditionalComponents(detail?.experimentComponents || []);
        } else {
          setCompoundDetail(detail);
        }

        // setCurrentVersion(detail?.version || 1);
      },
      pageType,
      page,
      () => {}
    );
  };

  const onChangeRestoreVersion = (page) => {
    getCompoundConcept(
      getParent(id),
      source,
      page,
      (detail) => {
        setDrawnStructureId(detail?.drawnStructureId);
        setCurrentVersion(detail?.version || 1);
        setCurrentCompoundVersion(detail);

        if ((detail?.conceptValue || "").length) {
          setIsUndefinedVersion(true);
        } else {
          setIsUndefinedVersion(false);
        }
      },
      () => {},
      () => {},
      setCompoundInstanceLoading
    );
  };

  const restoreCompound = () => {
    let body = {
      structure: currentCompoundVersion?.drawnStructureId,
      compoundId: currentCompoundVersion?.compoundId,
      sourceName: currentCompoundVersion?.sourceName || source,
      structureUpdate: true,
    };

    if (currentCompoundVersion?.conceptValue) {
      body["conceptValue"] = currentCompoundVersion?.conceptValue;
      delete body["structureUpdate"];
      delete body["structure"];
    }

    if (currentCompoundVersion?.molecularWeight) {
      body["molecularWeight"] = currentCompoundVersion?.molecularWeight;
    }

    if (currentCompoundVersion?.molecularFormula) {
      body["molecularFormula"] = currentCompoundVersion?.molecularFormula;
    }

    setLoading(true);
    restoreCompoundConceptApi(body)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        NotificationManager.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const restoreQuality = () => {
    setLoading(true);

    const body = {
      instanceComponents: additionalComponents || [],
      conceptComponents: [],
    };

    restoreQualityApi(source, id, body)
      .then(() => {
        window.location.reload();
      })
      .catch((e) => {
        NotificationManager.error(e?.response?.data?.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getSystemWidget(
      CURRENT_EDITOR_OPTION,
      (currentOption = {}) => {
        setCurrentEditor(currentOption?.option);
        setCurrentRound(currentOption?.roundSettings);
      },
      false
    );
  }, []);

  useEffect(() => {
    if (_.isEmpty(navigationMapping)) {
      getSystemWidget(
        `${NAVIGATION_URL_PREFIX}${source}`,
        setNavigationMapping,
        true
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageType === PAGE_TYPES.PROJECT) {
      getProjects(source, setProjects);
    }
  }, [pageType, source]);

  useEffect(() => {
    if (!(dataSources || []).length) getSources(setDataSources, true);
  }, [dataSources]);

  useEffect(() => {
    getSystemWidget(pageSettingsMetadata, setPageSettings, false);
  }, [pageSettingsMetadata]);

  useEffect(() => {
    dispatch(getSystemWidgets(DOCUMENT_COLLECTION_OPTIONS));
  }, [dispatch]);

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userNameWithRole,
      setDefaultSourceName,
      false
    );
  }, [userNameWithRole]);

  useEffect(() => {
    if (!safetySentenceTypes.length) {
      getSystemWidget(SAFETY_SENTENCES_METADATA, setSafetySentenceTypes, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !safetySentenceLanguageMap.length &&
      (pageType === PAGE_TYPES.EXPERIMENT ||
        pageType === PAGE_TYPES.QUALITY ||
        pageType === PAGE_TYPES.SAMPLE)
    ) {
      getSystemWidget(selectedLanguage, setSafetySentenceLanguageMap, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      !safetySentenceImageMetadata.length &&
      (pageType === PAGE_TYPES.EXPERIMENT ||
        pageType === PAGE_TYPES.QUALITY ||
        pageType === PAGE_TYPES.SAMPLE)
    ) {
      getSystemWidget(
        SAFETY_SENTENCES_IMAGE_METADATA,
        setSafetySentenceImageMetadata,
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (_.isNull(currentSettings)) {
      getUserWidget(
        USER_LAYOUT_SETTINGS,
        userNameWithRole,
        (cur) => {
          dispatch(
            setCurrentWidgets({
              widgets: cur,
              pageName: pageType,
              database: source,
            })
          );
          setCurrentSettings(cur);
        },
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSettings, userNameWithRole]);

  useEffect(() => {
    getUserWidget(GLOBAL_LAYOUT_SETTINGS, "Admin", setGlobalSettings, false);
  }, []);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
  }, [dataWidgets]);

  useEffect(() => {
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
  }, [systemWidgets]);

  useEffect(() => {
    const currentPageId =
      pageType === PAGE_TYPES.PROCESS || pageType === PAGE_TYPES.EXPERIMENT
        ? getParent(compoundDetail?.processId)
        : getParent(compoundDetail?.compoundId);

    if (
      (pageType === PAGE_TYPES.EXPERIMENT ||
        pageType === PAGE_TYPES.QUALITY ||
        pageType === PAGE_TYPES.SAMPLE) &&
      currentPageId !== id &&
      !compoundInstanceLoaded
    ) {
      let instanceId = id;

      if (pageType === PAGE_TYPES.SAMPLE) {
        instanceId = sampleDetail?.instanceId;
      }

      if (instanceId) {
        getCompoundInstance(
          instanceId,
          source,
          (detail) => {
            setCompoundInstance(detail);
            setCurrentVersion(detail?.version || 1);

            if (detail?.samples) {
              setSamples(detail?.samples);
            }

            if (
              pageType === PAGE_TYPES.QUALITY ||
              pageType === PAGE_TYPES.SAMPLE
            ) {
              const { instanceComponents = [], conceptComponents = [] } =
                detail;
              setAdditionalComponents([
                ...instanceComponents,
                ...conceptComponents,
              ]);
            } else {
              setAdditionalComponents(detail?.experimentComponents || []);
            }

            setCompoundInstanceLoaded(true);
          },
          pageType,
          null,
          () => {
            getCompoundInstance(
              id,
              source,
              (detail) => {
                setCompoundInstance(compoundInstance);
                if (pageType === PAGE_TYPES.QUALITY) {
                  const { instanceComponents = [], conceptComponents = [] } =
                    detail;
                  setAdditionalComponents([
                    ...instanceComponents,
                    ...conceptComponents,
                  ]);
                } else {
                  setAdditionalComponents(detail?.experimentComponents || []);
                }
                setCurrentVersion(detail?.version || 1);
              },
              pageType,
              1
            );
          }
        );
      }
    }

    if (
      (pageType === PAGE_TYPES.PROCESS || pageType === PAGE_TYPES.EXPERIMENT) &&
      currentPageId !== id
    ) {
      getProcessComponents(
        getParent(id),
        source,
        setProcessComponents,
        setProcessDetail,
        pageType,
        setProcessVersions,
        setCurrentProcessVersion
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [compoundDetail, id, pageType, source, sampleDetail]);

  useEffect(() => {
    if (compoundDetail && compoundDetail?.smiles) {
      const { smiles } = compoundDetail;
      dispatch(getElementalAnalysis(smiles));
    }
  }, [compoundDetail, dispatch]);

  useEffect(() => {
    searchDocumentation(TRAINING_DOCUMENT_SOURCE, pageType, setDocumentations);
  }, [pageType]);

  let metadataType =
    pageType === PAGE_TYPES.EXPERIMENT || pageType === PAGE_TYPES.PROCESS
      ? "process"
      : null;

  if (pageType === PAGE_TYPES.PROJECT) {
    metadataType = "project";
  }

  const compoundAdded = (compound) => {
    if (pageType === PAGE_TYPES.PROCESS || pageType === PAGE_TYPES.EXPERIMENT) {
      dispatch(
        saveMetadata(
          compound?.experimentId,
          {
            registrar: accounts[0]?.username,
            registrationType: "interactive",
            registrationDate: new Date(),
          },
          EXPERIMENT_DATA_WIDGET,
          compound?.source,
          metadataType
        )
      );

      getProcess(
        getParent(id),
        source,
        setCompoundDetail,
        setCompoundInstanceLoading
      );
    } else {
      dispatch(
        saveMetadata(
          compound?.instanceId,
          {
            registrar: accounts[0]?.username,
            registrationType: "interactive",
            registrationDate: new Date(),
          },
          REGISTRATION_DATA_WIDGET_NAME,
          compound?.source,
          metadataType
        )
      );

      getCompoundConcept(
        getParent(id),
        source,
        null,
        setCompoundDetail,
        () => {},
        setCompoundInstanceLoading
      );
    }
  };

  const updateSample = (newSamples) => {
    const updatedSamples = _.map(samples, (sample) =>
      sample?.id === newSamples?.id ? newSamples : sample
    );

    setCompoundInstanceLoading(false);
    setSamples(updatedSamples);
    setSampleVersions(newSamples?.version);

    updateConceptGraph();
  };

  const filteredInstances = (
    (pageType === PAGE_TYPES.PROCESS || pageType === PAGE_TYPES.EXPERIMENT
      ? compoundDetail?.experiments
      : compoundDetail?.compoundInstances) || []
  ).map((item) => (item.name === id ? { ...item, highlighted: true } : item));

  const compoundInstances = _.sortBy(filteredInstances || [], "name");

  const { metadataLoading, errorMessage, elementalAnalysis } = useSelector(
    ({ metadata }) => metadata
  );

  const isUnkown = !_.isEmpty(compoundDetail?.conceptValue);

  const onCloseAlert = () => {
    dispatch(closeAlert());
  };

  const onSaveTraits = (form, metadataName, widgetSettings = {}) => {
    // If current widget's `includeUpdatedInfo` parameter is true.
    // Include "updatedBy" and "updatedDate" info.

    // let targetMetadata = form;
    // if (widgetSettings?.includeUpdatedInfo) {
    //   targetMetadata["updatedBy"] = userName;
    //   targetMetadata["updatedDate"] = moment();
    // }

    dispatch(
      saveMetadata(id, form || {}, metadataName, source, metadataType, userName)
    );
    NotificationManager.success("Widget saved successfully.");
  };

  const checkExist =
    pageType === PAGE_TYPES.SAMPLE
      ? !_.isEmpty(sampleDetail)
      : pageType === PAGE_TYPES.PROJECT
      ? !_.isEmpty(compoundDetail)
      : pageType === PAGE_TYPES.QUALITY
      ? _.findIndex(compoundDetail?.compoundInstances || [], { name: id }) !==
        -1
      : pageType === PAGE_TYPES.EXPERIMENT
      ? _.findIndex(compoundDetail?.experiments || [], { name: id }) !== -1
      : !_.isEmpty(_.omit(compoundDetail, ["processId", "compoundId"]));

  let availableWidgets = [...systemWidgets, ...dataWidgets];

  let existingLayoutSettings = null;
  let existingGlobalSettings = null;

  _.forEach(currentSettings, (k, v) => {
    // Check if database and pageName matches
    if (
      (k?.database || []).includes(source) &&
      k?.pageName === pageType &&
      k?.selected
    ) {
      existingLayoutSettings = k;
    }
  });

  if (!_.isEmpty(currentView)) {
    existingLayoutSettings = _.get(currentSettings, currentView);
  }

  _.forEach(globalSettings, (k, v) => {
    if (k?.role === getRole() && k?.pageName === pageType) {
      existingGlobalSettings = k;
    }
  });

  if (existingLayoutSettings) {
    availableWidgets = availableWidgets.filter((item) => {
      const widgetOptions = existingLayoutSettings?.widgetOptions || [];
      const widgets =
        _.map(widgetOptions, (widgetOption) => widgetOption?.widget) || [];
      const databases = existingLayoutSettings?.database || [];

      if (databases.length) {
        return widgets.includes(item?.value) && databases.includes(source);
      } else {
        return widgets.includes(item?.value);
      }
    });
  } else if (existingGlobalSettings) {
    const widgets = existingGlobalSettings?.widgets || [];
    const databases = existingGlobalSettings?.database || [];

    availableWidgets = availableWidgets.filter((item) => {
      if (databases.length) {
        return widgets.includes(item?.value) && databases.includes(source);
      } else {
        return widgets.includes(item?.value);
      }
    });
  } else {
    availableWidgets = [];
  }

  let sortedWidgets = availableWidgets;
  let widgetOptions = [];

  if (existingLayoutSettings) {
    sortedWidgets = availableWidgets.sort((a, b) => {
      const widgets = _.map(
        existingLayoutSettings?.widgetOptions,
        (item) => item?.widget
      );

      return (widgets || []).indexOf(a?.value) >
        (widgets || []).indexOf(b?.value)
        ? 1
        : -1;
    });

    widgetOptions = existingLayoutSettings?.widgetOptions;
  } else if (existingGlobalSettings) {
    sortedWidgets = availableWidgets.sort((a, b) => {
      const widgets = _.map(
        existingGlobalSettings?.widgetOptions,
        (item) => item?.widget
      );

      return (widgets || []).indexOf(a?.value) >
        (widgets || []).indexOf(b?.value)
        ? 1
        : -1;
    });
    widgetOptions = existingGlobalSettings?.widgetOptions;
  }

  const isWidgetsFound =
    _.isEmpty(existingGlobalSettings) && _.isEmpty(existingLayoutSettings);

  if (isWidgetsFound) {
    sortedWidgets = [];
  }

  const widgets = sortedWidgets;
  // .filter((item) => (item?.belongsTo || []).includes(pageType))
  // .filter((item) => (item?.sourcesBelongsTo || []).includes(source));

  const widgetData = {
    compound: {
      id,
    },
    elementalAnalysis,
    compoundDetail,
    sampleDetail,
    compoundInstance,
  };

  const updateCompoundInstance = () => {
    setCompoundInstanceLoading(false);

    let compoundInstanceId = id;

    if (pageType === PAGE_TYPES.SAMPLE) {
      compoundInstanceId = sampleDetail?.instanceId;
    }

    getCompoundInstance(
      compoundInstanceId,
      source,
      (detail) => {
        setCompoundInstance(compoundInstance);

        if (detail?.samples) {
          setSamples(detail?.samples);
        }

        if (pageType === PAGE_TYPES.QUALITY || pageType === PAGE_TYPES.SAMPLE) {
          const { instanceComponents = [], conceptComponents = [] } = detail;
          setAdditionalComponents([
            ...instanceComponents,
            ...conceptComponents,
          ]);
        } else {
          setAdditionalComponents(detail?.experimentComponents || []);
        }
        setCurrentVersion(detail?.version || 1);

        getVersionNumbers(source, id, pageType, setVersions);
      },
      pageType,
      null
    );
  };

  const cloneExperiment = (id, targetId, selectedWidgets) => {
    cloneExperimentMetadata(
      sourceName,
      id,
      targetId,
      selectedWidgets,
      accounts[0].username
    );
  };

  const cloneDocuments = (targetId, targetFile) => {
    cloneDocumentApi(targetId, source, targetFile);
  };

  const shouldRenderNotFoundCard =
    _.findIndex(availableWidgets, {
      value: "DocumentCollectionsWidgetParams",
    }) === -1;

  const trainingSettings =
    _.find(documentCollectionOptions?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  const onUpdateDocumentations = () => {
    searchDocumentation(defaultSourceName?.option, pageType, setDocumentations);
  };

  const layoutMetadataName = `LayoutMetadata-${pageType}-${
    isChild ? "child" : "main"
  }-${accounts[0].username || getUserName()}`;

  const saveLayout = () => {
    const data = JSON.stringify({
      options: layout,
    });
    saveSystemWidget(layoutMetadataName, setLayout, setLoading, data);
  };

  const projectName = _.find(projects, { projectId: id }) || {};

  const curatorRoles = filterByAuthorizedRoles(
    dataSources || [],
    roles || [],
    getRole(),
    "curateDatabase"
  );

  const isCurator =
    _.findIndex(curatorRoles || [], { sourceName: source }) !== -1;

  const handleDelete = () => {
    setDeleteMode(true);
    setShowDeleteDrawer(true);
  };

  const deleteCompound = () => {
    setLoading(true);
    deleteCompoundConceptApi({ id, sourceName: source, pageType })
      .then(() => {
        setShowDeleteDrawer(false);
        setDeleteMode(false);

        window.location.reload();
      })
      .catch((e) => {
        NotificationManager.error(
          e?.response?.data?.message || "Error has occured."
        );
        setDeleteMode(false);
      })
      .finally(() => setLoading(false));
  };

  const deleteWidgetParameter = (
    widgetName,
    sourceName,
    compoundId,
    body,
    parameter
  ) => {
    const newBody = body;
    delete newBody[parameter];

    dispatch(
      saveMetadata(compoundId, newBody, widgetName, sourceName, metadataType)
    );
  };

  return (
    <div>
      <div
        className="gx-profile-banner gx-d-flex header sticky-header-component"
        style={{
          width: "105%",
        }}
      >
        <div
          className={`sticky-header-component ${
            deleteMode ? "sticky-header-secondary" : ""
          }`}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="gx-ml-3">
            <div className="gx-d-flex compound-detail-left-content">
              <h3>
                {capitalizeText(pageType)}:{" "}
                {pageType === PAGE_TYPES.PROJECT ? (
                  <>
                    {id}:{" "}
                    <span className="gx-text-primary">{projectName?.name}</span>
                  </>
                ) : (
                  id
                )}
              </h3>
              <h6 className="gx-mt-1 gx-text-white">
                Database: <span className="gx-text-success">{source}</span>
              </h6>
            </div>

            {pageSettings?.layout === "dynamic" ? (
              <div className="grid-layout-header">
                {editMode ? (
                  <>
                    <Button
                      size="small"
                      onClick={() => {
                        setEditMode(false);
                        setVisible(false);
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      size="small"
                      type="primary"
                      onClick={() => {
                        setEditMode(false);
                        setVisible(false);
                        saveLayout();
                      }}
                    >
                      Save
                    </Button>
                  </>
                ) : (
                  <Button
                    size="small"
                    onClick={() => {
                      setEditMode(true);
                      setVisible(true);
                    }}
                  >
                    <EditOutlined />
                    Edit
                  </Button>
                )}
                {editMode ? (
                  <>
                    {visible ? (
                      <Button size="small" onClick={() => setVisible(false)}>
                        Close Drawer
                      </Button>
                    ) : (
                      <Button size="small" onClick={() => setVisible(true)}>
                        Open Drawer
                      </Button>
                    )}
                  </>
                ) : null}
              </div>
            ) : null}
          </div>
          <div className="gx-d-flex gx-mr-5" style={{ marginTop: "12px" }}>
            {pageType === PAGE_TYPES.EXPERIMENT && (
              <ExportExcel
                id={id}
                source={EXCEL_TEMPLATE_SOURCE_NAME}
                processComponents={processComponents}
                additionalComponents={additionalComponents}
                safetySentenceLanguageMap={safetySentenceLanguageMap}
                safetySentenceTypes={safetySentenceTypes}
                safetySentenceImageMetadata={safetySentenceImageMetadata}
                childRef={childRef}
              />
            )}
            {showTraining && (
              <div className="g-d-flex">
                {(documentations || []).length ||
                isUserAllowedToManageDocumentation ? (
                  <DocumentationPopover
                    documentations={documentations}
                    sourceName={TRAINING_DOCUMENT_SOURCE}
                    setOpenDocumentationSettings={setOpenDocumentationSettings}
                    hasPermission={isUserAllowedToManageDocumentation}
                  />
                ) : null}
              </div>
            )}
            {(isCurator &&
              pageType === PAGE_TYPES.COMPOUND &&
              !_.isEmpty(_.omit(compoundDetail, ["compoundId", "id"]))) ||
            (isCurator &&
              pageType === PAGE_TYPES.QUALITY &&
              !_.isEmpty(additionalComponents)) ? (
              <img
                src={DeleteIcon}
                alt="delete compound"
                width={20}
                height={20}
                className="gx-mr-3 gx-pointer"
                twoToneColor="#ba141a"
                onClick={handleDelete}
                style={{
                  fontSize: "15px",
                  marginTop: "2px",
                }}
              />
            ) : null}
            <NavButton
              sourceName={source}
              pageType={pageType}
              id={id}
              navigationMapping={navigationMapping}
            />
            {isChild && (
              <div
                className="gx-pointer back-button"
                onClick={() => {
                  window.location.assign(
                    getBackButtonLink(id, source, pageType)
                  );
                }}
              >
                <Popover
                  content={
                    <span>
                      Go to{" "}
                      {pageType === PAGE_TYPES.EXPERIMENT
                        ? "process step"
                        : "compound"}
                    </span>
                  }
                >
                  <UpOutlined
                    size="large"
                    twoToneColor="#337ab7"
                    className="gx-ml-2 gx-mt-1 gx-mb-2 gx-pointer"
                  />
                </Popover>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="sticky-header-content"
        style={{
          marginTop: "85px",
          zIndex: 1000,
        }}
      >
        {!checkExist &&
          shouldRenderNotFoundCard &&
          !loading &&
          getNotFoundCard({
            pageType,
            title:
              (versions || []).length > 0
                ? " has been deleted."
                : " does not exist.",
          })}

        {isWidgetsFound && !loading && (
          <span
            className="gx-text-primary"
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            No widgets are defined in this page.
          </span>
        )}

        {loading ? (
          <WidgetPlaceHolder />
        ) : (
          <>
            {pageSettings?.layout === "dynamic" ? (
              <CustomizableLayout
                id={id}
                compoundDetail={compoundDetail}
                setLoading={setLoading}
                compoundInstanceLoading={compoundInstanceLoading}
                widgetData={widgetData}
                metadataLoading={metadataLoading}
                compoundAdded={compoundAdded}
                onSaveTraits={onSaveTraits}
                isChild={isChild}
                availableWidgets={widgets}
                pageType={pageType}
                sourceName={source}
                hideInfo={pageSettings?.hideInfo === "hide"}
                additionalComponents={additionalComponents}
                processComponents={processComponents}
                updateCompoundInstance={updateCompoundInstance}
                safetySentenceTypes={safetySentenceTypes}
                safetySentenceImageMetadata={safetySentenceImageMetadata}
                selectedLanguage={selectedLanguage}
                safetySentenceLanguageMap={safetySentenceLanguageMap}
                isCompoundExists={checkExist}
                roles={roles}
                editMode={editMode}
                setEditMode={setEditMode}
                layoutMetadataName={layoutMetadataName}
                layout={layout}
                setLayout={setLayout}
                visible={visible}
                setVisible={setVisible}
                dataSources={dataSources}
                cloneExperiment={cloneExperiment}
                cloneDocuments={cloneDocuments}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                currentEditor={currentEditor}
                samples={samples}
              />
            ) : (
              <DefaultLayout
                id={id}
                widgetData={widgetData}
                sampleDetail={sampleDetail}
                onSaveTraits={onSaveTraits}
                metadataLoading={metadataLoading}
                isChild={isChild}
                compoundDetail={compoundDetail}
                isUnkown={isUnkown}
                samples={samples}
                compoundInstances={compoundInstances}
                compoundAdded={compoundAdded}
                sampleAdded={updateCompoundInstance}
                updateSample={updateSample}
                compoundInstanceLoading={compoundInstanceLoading}
                setCompoundInstanceLoading={setCompoundInstanceLoading}
                availableWidgets={widgets}
                pageType={pageType}
                sourceName={source}
                hideInfo={pageSettings?.hideInfo === "hide"}
                additionalComponents={additionalComponents}
                processComponents={processComponents}
                updateCompoundInstance={updateCompoundInstance}
                roles={roles}
                safetySentenceTypes={safetySentenceTypes}
                safetySentenceImageMetadata={safetySentenceImageMetadata}
                selectedLanguage={selectedLanguage}
                safetySentenceLanguageMap={safetySentenceLanguageMap}
                isCompoundExists={checkExist}
                childRef={childRef}
                dataSources={dataSources}
                cloneExperiment={cloneExperiment}
                cloneDocuments={cloneDocuments}
                selectedCollection={selectedCollection}
                setSelectedCollection={setSelectedCollection}
                currentEditor={currentEditor}
                widgetOptions={widgetOptions}
                currentVersion={currentVersion}
                versions={versions}
                onChangeVersion={onChangeVersion}
                onChangeQualityVersion={onChangeQualityVersion}
                onChangeQualityVersionRestored={onChangeQualityVersionRestored}
                onChangeRestoreVersion={onChangeRestoreVersion}
                drawnStructureId={drawnStructureId}
                setDrawnStructureId={setDrawnStructureId}
                isUndefinedVersion={isUndefinedVersion}
                setIsUndefinedVersion={setIsUndefinedVersion}
                setCurrentVersion={setCurrentVersion}
                setCurrentCompoundVersion={setCurrentCompoundVersion}
                currentCompoundVersion={currentCompoundVersion}
                restoreCompound={restoreCompound}
                deleteWidgetParameter={deleteWidgetParameter}
                restoreQuality={restoreQuality}
                setAdditionalComponents={setAdditionalComponents}
                currentRound={currentRound}
                isCurator={isCurator}
                setCompoundImageLoading={setCompoundImageLoading}
                compoundImageLoading={compoundImageLoading}
                allWidgets={[...systemWidgets, ...dataWidgets]}
                compoundDetailVersions={compoundDetailVersions}
                setCompoundDetailVersions={setCompoundDetailVersions}
                onChangeVersionWithQualityPage={onChangeVersionWithQualityPage}
                compoundDetailCurrentVersion={compoundDetailCurrentVersion}
                setCompoundDetailCurrentVersion={
                  setCompoundDetailCurrentVersion
                }
                compoundDetailQuality={compoundDetailQuality}
                setCompoundDetailQuality={setCompoundDetailQuality}
                processVersions={processVersions}
                currentProcessVersion={currentProcessVersion}
                onChangeChemdrawVersion={onChangeChemdrawVersion}
                processDetail={processDetail}
                sampleVersions={sampleVersions}
                setSamples={setSamples}
                conceptGraph={conceptGraph}
              />
            )}
          </>
        )}
      </div>

      <SweetAlert
        show={!!errorMessage}
        warning
        title={errorMessage || ""}
        onConfirm={onCloseAlert}
      />

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageType}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>

      <Drawer
        placement="right"
        open={showDeleteDrawer}
        onClose={() => {
          setDeleteMode(false);
          setShowDeleteDrawer(false);
        }}
        title={`Delete ${pageType}: ${id}`}
      >
        <DeleteDrawer
          compoundDetail={compoundDetail}
          deleteCompound={deleteCompound}
          closeDrawer={() => {
            setDeleteMode(false);
            setShowDeleteDrawer(false);
          }}
          sourceName={source}
          widgetData={widgetData}
          availableWidgets={availableWidgets}
          allWidgets={[...systemWidgets, ...dataWidgets]}
          currentRound={currentRound}
          pageType={pageType}
          associates={associates}
        />
      </Drawer>
    </div>
  );
};

DetailPage.defaultProps = {
  pageType: "",
};

DetailPage.propTypes = {
  pageType: PT.string,
};

export default DetailPage;
