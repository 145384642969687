import {
  CLOSE_ALERT,
  GET_ELEMENTAL_ANALYSIS_REQUESTED,
  GET_METADATA_REQUESTED,
  SAVE_METADATA_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Get Metadata for respective widget
 * @param {String} compoundId compound id
 * @param {String} sourceName source name
 * @param {String} metadataName metadata name
 * @param {String | Null} metadataType metadata type
 */
export const getMetadata = (
  compoundId,
  sourceName,
  metadataName,
  metadataType = null,
  currentVersion,
  systemWidgetName
) => {
  return {
    type: GET_METADATA_REQUESTED,
    payload: {
      compoundId,
      sourceName,
      metadataName,
      metadataType,
      currentVersion,
      systemWidgetName,
    },
  };
};

/**
 * Get Metadata for respective widget
 * @param {String} compoundId compound id
 * @param {Object} metadata metadata form object
 * @param {String} metadataName metadata name
 * @param {String} sourceName source name
 * @param {String | Null} metadataType metadata type
 * @param {String | Null} pageType page type
 */
export const saveMetadata = (
  compoundId,
  metadata,
  metadataName,
  sourceName,
  metadataType = null,
  userName,
  pageType
) => {
  return {
    type: SAVE_METADATA_REQUESTED,
    payload: {
      compoundId,
      metadata,
      metadataName,
      sourceName,
      metadataType,
      userName,
      pageType,
    },
  };
};

/**
 * Get Metadata for respective widget
 * @param {String} compoundId compound id
 * @param {Object} metadata metadata form object
 * @param {String} metadataName metadata name
 * @param {String} sourceName source name
 */
export const saveUserMetadata = (
  compoundId,
  metadata,
  metadataName,
  sourceName
) => {
  return {
    type: SAVE_METADATA_REQUESTED,
    payload: {
      compoundId,
      metadata,
      metadataName,
      sourceName,
    },
  };
};

/**
 * Get Elemental Analysis
 * @param {String} smiles smiles formatted string
 */
export const getElementalAnalysis = (smiles) => {
  return {
    type: GET_ELEMENTAL_ANALYSIS_REQUESTED,
    payload: smiles,
  };
};

/**
 * Cloase Alert for error modal
 * @param {String} smiles smiles formatted string
 */
export const closeAlert = () => {
  return {
    type: CLOSE_ALERT,
  };
};
