import axios from "axios";
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
  GET_METADATA_REQUESTED,
  GET_METADATA_SUCCESS,
  CLOSE_ALERT,
  CLOSE_ALERT_SUCCESS,
  SAVE_METADATA_REQUESTED,
  SAVE_METADATA_SUCCESS,
  SAVE_METADATA_FAILED,
  GET_ELEMENTAL_ANALYSIS_REQUESTED,
  GET_ELEMENTAL_ANALYSIS_SUCCESS,
} from "../../constants/ActionTypes";

import { config } from "../../constants/Config";

export const elementalAnalysisRequest = async (smiles, precision = 2) => {
  return axios.post(
    `${config.REACT_APP_PROPERTIES_API_URL}/api/v1/elementalAnalysis?precision=${precision}`,
    smiles,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
    }
  );
};

/**
 * API Request for get metadata
 * @param {String} compoundId compound id
 * @param {String} sourceName source name
 * @param {String} metadataName metadata name
 * @param {String | Null} metadataType metadata type
 */
export const widgetMetadataRequest = async (
  compoundId,
  sourceName,
  metadataName,
  metadataType,
  currentVersion
) => {
  let idType = "compoundId";

  switch (metadataType) {
    case "project":
      idType = "projectId";
      break;
    case "process":
    case "experiment":
      idType = "processId";
      break;
    default:
      idType = "compoundId";
  }

  let url = metadataType
    ? `${config.SRD_API_URL}/metadata/${metadataType}/${metadataName}?${idType}=${compoundId}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}`;

  if (currentVersion) {
    url = metadataType
      ? `${config.SRD_API_URL}/metadata/${metadataType}/${metadataName}?${idType}=${compoundId}&version=${currentVersion}`
      : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}&version=${currentVersion}`;
  }

  return axios.get(`${url}&sourceName=${sourceName}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });
};

/**
 * Request for saving Metadata for respective widget
 * @param {String} compoundId compound id
 * @param {Object} metadata metadata form object
 * @param {String} metadataName metadata name
 * @param {String} sourceName source name
 * @param {String | Null} metadataType metadata type
 */
export const saveMetadataRequest = async ({
  compoundId,
  metadata,
  metadataName,
  sourceName,
  metadataType,
  userName,
}) => {
  let idType = "compoundId";

  switch (metadataType) {
    case "project":
      idType = "projectId";
      break;
    case "process":
      idType = "processId";
      break;
    default:
      idType = "compoundId";
  }

  let baseUrl = metadataType
    ? `${config.SRD_API_URL}/metadata/${metadataType}/${metadataName}?${idType}=${compoundId}`
    : `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}`;
  return axios.post(
    `${baseUrl}&sourceName=${sourceName}&SRD_USER=${userName}`,
    metadata,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );
};

/**
 * Request to fetch metadata
 * @param {Object} action
 */
function* fetchMetadata(action) {
  try {
    const result = yield call(
      widgetMetadataRequest,
      action.payload?.compoundId,
      action.payload?.sourceName,
      action.payload?.metadataName,
      action?.payload?.metadataType,
      action?.payload?.currentVersion
    );
    if (result) {
      yield put({
        type: GET_METADATA_SUCCESS,
        payload: {
          [action.payload?.metadataName]: result.data,
        },
      });

      const targetId = action?.payload?.systemWidgetName;
      if (targetId) {
        document.getElementById(targetId).scrollIntoView();
      }
    }
  } catch (e) {
    yield put({
      type: GET_METADATA_SUCCESS,
      payload: {
        [action.payload?.metadataName]: [],
      },
    });
  }
}

function* fetchElementalAnalysis(action) {
  try {
    const elementalAnalysis = yield call(
      elementalAnalysisRequest,
      action.payload
    );
    if (elementalAnalysis) {
      yield put({
        type: GET_ELEMENTAL_ANALYSIS_SUCCESS,
        payload: elementalAnalysis.data,
      });
    }
  } catch (e) {}
}

function* fetchCloseAlert() {
  yield put({
    type: CLOSE_ALERT_SUCCESS,
  });
}

function* fetchSaveMetadata(action) {
  try {
    const result = yield call(saveMetadataRequest, {
      ...action.payload,
    });
    if (result.data) {
      yield put({
        type: SAVE_METADATA_SUCCESS,
        payload: {
          metadataName: action.payload?.metadataName,
          form: result.data,
        },
      });
    }
  } catch (e) {
    yield put({
      type: SAVE_METADATA_FAILED,
      payload: e.response.message,
    });
  }
}

export function* getMetadata() {
  yield takeEvery(GET_METADATA_REQUESTED, fetchMetadata);
}

export function* closeAlert() {
  yield takeEvery(CLOSE_ALERT, fetchCloseAlert);
}

export function* saveMetadata() {
  yield takeEvery(SAVE_METADATA_REQUESTED, fetchSaveMetadata);
}

export function* getElementalAnalysis() {
  yield takeEvery(GET_ELEMENTAL_ANALYSIS_REQUESTED, fetchElementalAnalysis);
}

export default function* rootSaga() {
  yield all([
    fork(getMetadata),
    fork(closeAlert),
    fork(saveMetadata),
    fork(getElementalAnalysis),
  ]);
}
