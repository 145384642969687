/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Form, Input, Row, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import PT from "prop-types";
import React from "react";
import { DOCUMENT_COLLECTION_OPTIONS } from "../../../../../constants/Config";

const NestedForm = ({ onChangeValue, form, roles, settingsWidgetName }) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.id) ? currentRole[item?.id] : false;
      role[item?.id] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  return (
    <>
      <Col>
        <Form.Item
          label="Display Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Items"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <TextArea
            style={{ height: "20px" }}
            value={form?.items}
            onChange={(e) => onChangeValue("items", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
          />
        </Form.Item>
      </Col>

      <Divider className="gx-text-primary">Additional Settings</Divider>

      <Col className="gx-d-flex">
        <Form.Item
          label="Is Searchable"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={_.get(form, "isSearchable") || false}
            onChange={(e) => onChangeValue("isSearchable", e)}
          />
        </Form.Item>

        <Row>
          <Form.Item
            label="Has Tooltip"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={form?.hasTooltip}
              onChange={(e) => onChangeValue("hasTooltip", e)}
            />
          </Form.Item>
          {form?.hasTooltip ? (
            <Form.Item
              label="Tooltip"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                value={form?.info}
                onChange={(e) => onChangeValue("info", e.target.value)}
              />
            </Form.Item>
          ) : null}

          {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
            <Form.Item
              className="gx-ml-3"
              label="Is Header Field"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form, "isInlineField") || false}
                onChange={(e) => onChangeValue("isInlineField", e)}
              />
            </Form.Item>
          ) : null}
        </Row>
      </Col>

      <Divider className="gx-text-primary">Roles Settings</Divider>

      <div>
        <p className="gx-mb-3 gx-text-secondary">Edit Roles:</p>
        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isEditable, role?.id) || false}
                onChange={(e) => onChangeRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>

      <div>
        <div>
          <p className="gx-mb-3 gx-text-secondary">Access Roles:</p>

          <Col className="gx-d-flex">
            {roles.map((role) => (
              <Form.Item
                className="gx-mr-1"
                id={role.id}
                label={role.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Switch
                  checked={_.get(form?.isAccessible, role?.id) || false}
                  onChange={(e) => onChangeAccessRole(role.id, e)}
                />
              </Form.Item>
            ))}
          </Col>
        </div>
      </div>
    </>
  );
};

NestedForm.defaultProps = {
  form: {},
};

NestedForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default NestedForm;
