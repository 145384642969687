import React, { forwardRef } from "react";
import { EDITOR_OPTIONS } from "../../constants/Config";
import KetcherEditor from "../KetcherEditor";
import ChemdrawEditor from "../ChemdrawEditor";

const GenericEditor = forwardRef((props, ref) => {
  const { currentEditor } = props;

  return (
    <>
      {currentEditor === EDITOR_OPTIONS.CHEMDRAW ? (
        <ChemdrawEditor {...props} ref={ref} />
      ) : (
        <KetcherEditor {...props} ref={ref} />
      )}
    </>
  );
});

export default GenericEditor;
