import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ProtectedRoute from "../util/ProtectedRoute";
import AccountSettings from "./AccountSettings";
import AdminDashboard from "./AdminDashboard";
import ProjectPage from "./ProjectPage";
import RegisterNewCompound from "./RegisterNewCompound";
import RegisterProcess from "./RegisterProcess";
import UploadPage from "./UploadPage";
import useGetRoles from "../hooks/detailPage/useGetRoles";
import DetailPage from "./DetailPage";
import HomePage from "./HomePage";
import Error404 from "./Error404";
import ExplorePage from "./ExplorePage";
import CollectionPage from "./CollectionPage";
import DemoPage from "./DemoPage";

const App = ({ match }) => {
  const roles = useGetRoles();

  return (
    <div className="gx-main-content-wrapper">
      <Switch>
        <Route path={`/home`} exact component={HomePage} />
        <Route path={`${match.url}/`} exact>
          <Redirect to={`/home`} />
        </Route>

        <ProtectedRoute path={`/compound-registration`} roles={roles} exact>
          <RegisterNewCompound />
        </ProtectedRoute>

        <Route
          path={`/:source/:pageType(compound|quality|process|experiment|project|sample)/:id`}
          exact
          component={DetailPage}
        />

        <ProtectedRoute path={`/collection`} roles={roles} exact>
          <CollectionPage />
        </ProtectedRoute>

        {/* <ProtectedRoute path={`/search`} roles={roles} exact>
          <SearchPage />
        </ProtectedRoute> */}

        <ProtectedRoute path={`/search`} roles={roles} exact>
          <ExplorePage />
        </ProtectedRoute>

        <ProtectedRoute path={`/register-process`} roles={roles} exact>
          <RegisterProcess />
        </ProtectedRoute>

        <ProtectedRoute path={`/page-settings`} roles={roles} exact>
          <AccountSettings />
        </ProtectedRoute>

        <ProtectedRoute path={`/projects`} roles={roles} exact>
          <ProjectPage />
        </ProtectedRoute>

        <Route path={`/404`} exact component={Error404} />

        <ProtectedRoute path={`/dashboard`} roles={roles} exact>
          <AdminDashboard />
        </ProtectedRoute>

        <ProtectedRoute path={`/upload`} roles={roles} exact>
          <UploadPage />
        </ProtectedRoute>

        <ProtectedRoute path={`/graph-demo`} roles={roles} exact>
          <DemoPage />
        </ProtectedRoute>

        <Route path={`/*`}>
          <Redirect to={`/404`} />
        </Route>

        <Route path={`/ui/*`}>
          <Redirect to={`/home`} />
        </Route>
      </Switch>
    </div>
  );
};

export default App;
