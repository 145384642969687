/* eslint-disable react-hooks/exhaustive-deps */
import { useMsal } from "@azure/msal-react";
import { Form, Select } from "antd";
import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { searchUsers } from "../../appRedux/actions";
import { RequestAccessToken } from "../../util/Graph";

const AutoCompleteSelect = (props, ref) => {
  const [accessToken, setAccessToken] = useState(null);
  const [currentTerm, setCurrentTerm] = useState("");
  const { accounts, instance } = useMsal();
  const [selections, setSelections] = useState([]);
  const dispatch = useDispatch();
  const { defaultValue } = props;

  const { users } = useSelector(({ graph }) => graph);

  const usersOptions = _.map(users || [], (user) => ({
    label: user?.displayName,
    value: user?.userPrincipalName,
  }));

  useEffect(() => {
    RequestAccessToken({ accounts, instance, setAccessToken });
  }, []);

  useEffect(() => {
    if (accessToken && currentTerm.length) {
      _.delay(() => {
        dispatch(searchUsers(currentTerm, accessToken));
      }, 100);
    }
  }, [currentTerm]);

  useImperativeHandle(ref, () => ({
    getValues: () => {
      return selections;
    },
  }));

  return (
    <Form.Item ref={ref}>
      <Select
        name="owners"
        showSearch
        mode="tags"
        options={usersOptions}
        defaultValue={defaultValue}
        onSearch={setCurrentTerm}
        onChange={setSelections}
      />
    </Form.Item>
  );
};

export default forwardRef(AutoCompleteSelect);
