import {
  GET_DATA_SOURCES_FAILED,
  GET_DATA_SOURCES_REQUESTED,
  GET_DATA_SOURCES_SUCCESS,
  SEARCH_COMPOUND_FAILED,
  SEARCH_COMPOUND_REQUESTED,
  SEARCH_COMPOUND_SUCCESS,
  SEARCH_DOCUMENTS_FAILED,
  SEARCH_DOCUMENTS_REQUESTED,
  SEARCH_DOCUMENTS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  dataSources: [],
  documents: [],
  concepts: [],
};

const SearchReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_DATA_SOURCES_REQUESTED: {
      return {
        ...state,
        loading: false,
      };
    }
    case GET_DATA_SOURCES_SUCCESS: {
      return {
        ...state,
        dataSources: action.payload,
        loading: false,
      };
    }
    case GET_DATA_SOURCES_FAILED: {
      return {
        ...state,
        dataSources: [],
        loading: false,
      };
    }

    case SEARCH_DOCUMENTS_REQUESTED: {
      return {
        ...state,
        loading: true,
        documents: [],
      };
    }

    case SEARCH_DOCUMENTS_FAILED: {
      return {
        ...state,
        loading: false,
        documents: [],
      };
    }

    case SEARCH_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        [action.payload.type]:
          action.payload?.data?.documentSearchResponse?.hitList,
      };
    }

    case SEARCH_COMPOUND_REQUESTED: {
      console.log("SEARCH_COMPOUND_REQUESTED called");
      return {
        ...state,
        loading: true,
        concepts: [],
      };
    }

    case SEARCH_COMPOUND_FAILED: {
      return {
        ...state,
        loading: false,
        concepts: [],
      };
    }

    case SEARCH_COMPOUND_SUCCESS: {
      return {
        ...state,
        loading: false,
        concepts: action.payload?.data || [],
      };
    }

    default:
      return state;
  }
};

export default SearchReducer;
