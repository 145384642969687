import _ from "lodash";
import React from "react";
import { getRole } from "../../../util/auth";

import "./style.css";
import { Popover, Space, Tag } from "antd";

const ResourceLinks = ({ resourceLinks = [] }) => {
  const currentRole = getRole();

  const linksFiltered = _.filter(resourceLinks, (link) => {
    const { isEditable } = link;

    return _.get(isEditable, currentRole);
  });

  return (
    <>
      {_.isEmpty(linksFiltered) ? null : (
        <div className="resource-links">
          <Space size={[0, 8]} wrap>
            {_.map(linksFiltered, (link, index) => {
              return (
                <Popover content={link?.description}>
                  <Tag color="blue" bordered={false}>
                    <a
                      key={index}
                      href={link?.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {link?.name}
                    </a>
                  </Tag>
                </Popover>
              );
            })}
          </Space>
        </div>
      )}
    </>
  );
};

export default ResourceLinks;
