import React from "react";
import Widget from "../Widget";
import { XMasonry, XBlock } from "react-xmasonry";
import CompoundInstanceWidget from "../CompoundInstanceWidget";
import AdditionalComponent from "../AdditionalComponent";
import ReactionComponents from "../ReactionComponents";
import ChemdrawWidget from "../ChemdrawWidget";
import { getParent } from "../../../util/Widget";
import ProjectSettingsWidget from "../ProjectSettingsWidget";
import CompositionTable from "../CompositionTable";
import TableWidget from "../TableWidget";

import "./style.css";
import SafetySentencesWidget from "../SafetySentencesWidget";
import DocumentCollection from "../DocumentCollection";
import pluralize from "pluralize";
import _ from "lodash";
import {
  GRAPH_WIDGET,
  PAGE_TYPES,
  PROJECT_MANAGEMENT_WIDGET,
  STRUCTURE_WIDGET_PARAMS,
} from "../../../constants/Config";
import { getRole } from "../../../util/auth";
import ExperimentTable from "../ExperimentTable";
import ProjectManagementWidget from "../ProjectManagementWidget";
import SampleWidget from "../SampleWidget";
import GraphWidget from "../GraphWidget";

const DefaultLayout = ({
  id,
  widgetData,
  onSaveTraits,
  metadataLoading,
  compoundDetail,
  compoundInstances,
  sampleAdded,
  updateSample,
  samples,
  additionalComponents,
  processComponents,
  compoundAdded,
  compoundInstanceLoading,
  setCompoundInstanceLoading,
  updateCompoundInstance,
  availableWidgets,
  pageType,
  sourceName,
  hideInfo,
  roles,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  selectedLanguage,
  safetySentenceLanguageMap,
  isCompoundExists,
  childRef,
  dataSources,
  cloneExperiment,
  cloneDocuments,
  selectedCollection,
  setSelectedCollection,
  currentEditor,
  widgetOptions,
  currentVersion,
  versions,
  onChangeVersion,
  onChangeRestoreVersion,
  onChangeQualityVersion,
  onChangeQualityVersionRestored,
  drawnStructureId,
  setDrawnStructureId,
  isUndefinedVersion,
  setIsUndefinedVersion,
  setCurrentVersion,
  currentCompoundVersion,
  setCurrentCompoundVersion,
  restoreCompound,
  deleteWidgetParameter,
  restoreQuality,
  setAdditionalComponents,
  currentRound,
  isCurator,
  setCompoundImageLoading,
  compoundImageLoading,
  allWidgets,
  compoundDetailVersions,
  setCompoundDetailVersions,
  onChangeVersionWithQualityPage,
  compoundDetailCurrentVersion,
  setCompoundDetailCurrentVersion,
  compoundDetailQuality,
  setCompoundDetailQuality,
  processVersions,
  currentProcessVersion,
  onChangeChemdrawVersion,
  processDetail,
  addCompoundSample,
  sampleDetail,
  sampleVersions,
  setSamples,
  conceptGraph,
}) => {
  /**
   * Render child widget
   * @param {*} widget
   * @param {*} widgetOption
   * @returns
   */
  const renderChild = (widget, widgetOption) => {
    const targetMetadata = widget?.value;

    // Show Only Document Collection Widget if compound is not found.
    if (!isCompoundExists && widget?.value !== "DocumentCollections") {
      return null;
    }

    if (widget?.value === "Samples") {
      return (
        <SampleWidget
          id={id}
          title={`${pluralize("Sample", (samples || []).length)} (${
            (samples || []).length
          })`}
          loading={compoundInstanceLoading}
          roles={widget?.roles || []}
          pageType={pageType}
          sourceName={sourceName}
          samples={samples}
          addCompoundSample={addCompoundSample}
          addSample={sampleAdded}
          setLoading={setCompoundInstanceLoading}
          updateSample={updateSample}
          sampleDetail={sampleDetail}
          allWidgets={allWidgets}
          widget={widget?.widget}
          sampleVersions={sampleVersions}
          setSamples={setSamples}
        />
      );
    }

    if (widget?.value === "Qualities") {
      return (
        <CompoundInstanceWidget
          id={id}
          title={`${
            pageType === "process" || pageType === "experiment"
              ? pluralize("Experiment", (compoundInstances || []).length)
              : pluralize("Quality", (compoundInstances || []).length)
          } (${(compoundInstances || []).length})`}
          compoundInstances={compoundInstances}
          addInstance={compoundAdded}
          loading={compoundInstanceLoading}
          setLoading={setCompoundInstanceLoading}
          sourceName={sourceName}
          pageType={pageType}
          hideInfo={hideInfo}
          roles={widget?.roles || []}
          cloneExperiment={cloneExperiment}
          cloneDocuments={cloneDocuments}
          availableWidgets={availableWidgets}
          selectedCollection={selectedCollection}
          setSelectedCollection={setSelectedCollection}
          allWidgets={allWidgets}
          widgetData={widgetData}
          currentRound={currentRound}
          widget={widget?.widget}
        />
      );
    }

    if (widget?.value === "AdditionalComponents") {
      return (
        <AdditionalComponent
          id={id}
          title="Composition"
          options={additionalComponents || []}
          disableVersion
          withImage
          disableControl
          rmoId={compoundDetail.rmoId}
          componentUpdated={updateCompoundInstance}
          sourceName={sourceName}
          pageType={pageType}
          hideInfo={hideInfo}
        />
      );
    }

    if (widget?.value === "ReactionComponents") {
      return (
        <ReactionComponents
          id={id}
          title="Reaction Component"
          sourceName={sourceName}
          pageType={pageType}
          roles={widget?.roles}
          processComponents={compoundDetail?.processComponents || []}
        />
      );
    }

    if (widget?.value === "cdxmlRendering") {
      return (
        <ChemdrawWidget
          id={getParent(id)}
          title={widget?.field}
          widgetName={widget?.value}
          sourceName={sourceName}
          childRef={childRef}
          currentEditor={currentEditor}
          pageType={pageType}
          compoundDetail={processDetail}
          versionRoles={widget?.versionRoles || []}
          currentVersion={currentProcessVersion}
          setCurrentVersion={setCurrentVersion}
          versions={processVersions}
          widget={widget?.widget || {}}
          widgetOption={widgetOption}
          onChangeVersion={onChangeChemdrawVersion}
        />
      );
    }

    if (widget?.value === "projectSettings") {
      return (
        <ProjectSettingsWidget
          id={getParent(id)}
          title={widget?.field}
          widgetName={widget?.value}
          sourceName={sourceName}
        />
      );
    }

    if (widget?.value === "CompositionTable") {
      let compositionTableId = id;

      if (pageType === PAGE_TYPES.SAMPLE) {
        compositionTableId = sampleDetail?.instanceId;
      }

      return (
        <CompositionTable
          id={compositionTableId}
          title={widget?.field}
          roles={widget?.roles || []}
          components={additionalComponents || []}
          processComponents={processComponents || []}
          sourceName={sourceName}
          pageType={pageType}
          componentUpdated={updateCompoundInstance}
          safetySentenceTypes={safetySentenceTypes}
          safetySentenceImageMetadata={safetySentenceImageMetadata}
          selectedLanguage={selectedLanguage}
          safetySentenceLanguageMap={safetySentenceLanguageMap}
          dataSources={dataSources}
          compoundDetail={compoundDetail}
          currentVersion={currentVersion}
          versions={versions}
          onChangeVersion={onChangeQualityVersion}
          setCurrentVersion={setCurrentVersion}
          setAdditionalComponents={setAdditionalComponents}
          widgetOption={widgetOption}
          currentRound={currentRound}
          allWidgets={allWidgets}
          widget={widget?.widget}
        />
      );
    }

    if (
      widget?.value === "ExperimentTable" &&
      pageType === PAGE_TYPES.EXPERIMENT
    ) {
      return (
        <ExperimentTable
          id={id}
          title={widget?.field}
          versions={versions}
          widgetOption={widgetOption}
          currentVersion={currentVersion}
          roles={widget?.roles || []}
          compoundDetail={compoundDetail}
          sourceName={sourceName}
          pageType={pageType}
          safetySentenceTypes={safetySentenceTypes}
          safetySentenceImageMetadata={safetySentenceImageMetadata}
          safetySentenceLanguageMap={safetySentenceLanguageMap}
          dataSources={dataSources}
          allWidgets={allWidgets}
          widget={widget?.widget}
        />
      );
    }

    if (widget?.value === "SafetySentences") {
      return (
        <SafetySentencesWidget
          id={id}
          title={widget?.field}
          sourceName={sourceName}
          pageType={pageType}
          widget={widget?.widget || {}}
          widgetOption={widgetOption}
          components={additionalComponents || []}
          versionRoles={widget?.versionRoles || []}
          safetySentenceTypes={safetySentenceTypes}
        />
      );
    }

    if (widget?.value === "DocumentCollections") {
      return (
        <DocumentCollection
          id={id}
          title={widget?.field}
          sourceName={sourceName}
          pageType={pageType}
          isCompoundExists={isCompoundExists}
          systemWidgetName={widget?.value}
        />
      );
    }

    if (widget?.widgetType === "table") {
      return (
        <TableWidget
          id={id}
          title={widget?.field}
          systemWidgetName={widget?.value}
          components={additionalComponents || []}
          sourceName={sourceName}
          pageType={pageType}
          componentUpdated={updateCompoundInstance}
          roles={roles}
          widget={widget?.widget || {}}
          versionRoles={widget?.versionRoles || []}
          widgetOption={widgetOption}
        />
      );
    }

    if (
      widget?.value === STRUCTURE_WIDGET_PARAMS &&
      isCompoundExists &&
      pageType === PAGE_TYPES.QUALITY
    ) {
      return (
        <Widget
          id={id}
          systemWidgetName={widget?.value}
          widgetData={widgetData}
          onSaveWidget={onSaveTraits}
          loading={metadataLoading}
          renderResidualData
          sourceName={sourceName}
          pageType={pageType}
          hideInfo={hideInfo}
          targetMetadata={targetMetadata}
          widget={widget?.widget || {}}
          widgetOption={widgetOption}
          currentVersion={compoundDetailCurrentVersion}
          setCurrentVersion={setCompoundDetailCurrentVersion}
          versions={compoundDetailVersions}
          onChangeVersion={onChangeVersionWithQualityPage}
          compoundDetail={compoundDetailQuality}
          widgetMetadata={widget}
          drawnStructureId={drawnStructureId}
          setDrawnStructureId={setDrawnStructureId}
          deleteWidgetParameter={deleteWidgetParameter}
          versionRoles={widget?.versionRoles || []}
          currentRound={currentRound}
          setCompoundImageLoading={setCompoundImageLoading}
          compoundImageLoading={compoundImageLoading}
        />
      );
    }

    if (
      widget?.value === PROJECT_MANAGEMENT_WIDGET &&
      pageType === PAGE_TYPES.PROJECT
    ) {
      return (
        <ProjectManagementWidget
          id={id}
          widget={widget}
          compoundDetail={compoundDetail}
          sourceName={sourceName}
          availableWidgets={availableWidgets}
          availableDataSources={dataSources}
        />
      );
    }

    if (widget?.value === GRAPH_WIDGET) {
      return (
        <GraphWidget
          id={id}
          sourceName={sourceName}
          title={widget?.field}
          systemWidgetName={widget?.value}
          components={additionalComponents || []}
          pageType={pageType}
          componentUpdated={updateCompoundInstance}
          roles={roles}
          widget={widget?.widget || {}}
          versionRoles={widget?.versionRoles || []}
          widgetOption={widgetOption}
          compoundDetail={compoundDetail}
          sampleDetail={sampleDetail}
          conceptGraph={conceptGraph}
        />
      );
    }

    return (
      <Widget
        id={id}
        systemWidgetName={widget?.value}
        widgetData={widgetData}
        onSaveWidget={onSaveTraits}
        loading={metadataLoading}
        renderResidualData
        sourceName={sourceName}
        pageType={pageType}
        hideInfo={hideInfo}
        targetMetadata={targetMetadata}
        widget={widget?.widget || {}}
        widgetOption={widgetOption}
        currentVersion={currentVersion}
        setCurrentVersion={setCurrentVersion}
        versions={versions}
        onChangeVersion={onChangeVersion}
        compoundDetail={compoundDetail}
        widgetMetadata={widget}
        drawnStructureId={drawnStructureId}
        setDrawnStructureId={setDrawnStructureId}
        deleteWidgetParameter={deleteWidgetParameter}
        versionRoles={widget?.versionRoles || []}
        currentRound={currentRound}
        setCompoundImageLoading={setCompoundImageLoading}
        compoundImageLoading={compoundImageLoading}
      />
    );
  };

  return (
    <>
      <XMasonry
        maxColumns={24}
        targetBlockWidth={200}
        center={false}
        responsive
        smartUpdate
        smartUpdateCeil={50}
      >
        {availableWidgets.map((widget) => {
          const widgetOption =
            _.find(widgetOptions, { widget: widget?.value }) || {};
          const { width = 1 } = widgetOption;

          return (
            <XBlock id={widget?.value} width={width}>
              {renderChild(widget, widgetOption)}
            </XBlock>
          );
        })}
      </XMasonry>
      {!isCompoundExists &&
      pageType === PAGE_TYPES.COMPOUND &&
      (versions || []).length &&
      (
        _.find(allWidgets, { value: STRUCTURE_WIDGET_PARAMS })?.versionRoles ||
        []
      ).includes(getRole()) ? (
        <Widget
          id={id}
          systemWidgetName={STRUCTURE_WIDGET_PARAMS}
          widgetData={widgetData}
          onSaveWidget={onSaveTraits}
          loading={metadataLoading}
          renderResidualData
          sourceName={sourceName}
          pageType={pageType}
          hideInfo={hideInfo}
          targetMetadata={STRUCTURE_WIDGET_PARAMS}
          widget={{
            ..._.find(allWidgets, { value: STRUCTURE_WIDGET_PARAMS })?.widget,
            title: "Restore versions",
            hasVersion: true,
            image: { width: 250 },
          }}
          versionRoles={
            _.find(allWidgets, { value: STRUCTURE_WIDGET_PARAMS })
              ?.versionRoles || []
          }
          widgetOption={{}}
          currentVersion={currentVersion}
          setCurrentVersion={setCurrentVersion}
          versions={versions}
          onChangeVersion={onChangeRestoreVersion}
          compoundDetail={compoundDetail}
          widgetMetadata={{}}
          restoreMode
          drawnStructureId={drawnStructureId}
          setDrawnStructureId={setDrawnStructureId}
          isUndefinedVersion={isUndefinedVersion}
          setIsUndefinedVersion={setIsUndefinedVersion}
          currentCompoundVersion={currentCompoundVersion}
          setCurrentCompoundVersion={setCurrentCompoundVersion}
          restoreCompound={restoreCompound}
          deleteWidgetParameter={deleteWidgetParameter}
          currentRound={currentRound}
          isCurator={isCurator}
          setCompoundImageLoading={setCompoundImageLoading}
          compoundImageLoading={compoundImageLoading}
        />
      ) : null}
      {!isCompoundExists &&
      pageType === PAGE_TYPES.QUALITY &&
      (versions || []).length &&
      isCurator ? (
        <CompositionTable
          id={id}
          components={additionalComponents || []}
          setAdditionalComponents={setAdditionalComponents}
          title={"Restore Versions"}
          roles={roles || []}
          processComponents={processComponents || []}
          sourceName={sourceName}
          pageType={pageType}
          componentUpdated={updateCompoundInstance}
          safetySentenceTypes={safetySentenceTypes}
          safetySentenceImageMetadata={safetySentenceImageMetadata}
          selectedLanguage={selectedLanguage}
          safetySentenceLanguageMap={safetySentenceLanguageMap}
          dataSources={dataSources}
          compoundDetail={compoundDetail}
          currentVersion={currentVersion || 1}
          versions={versions}
          onChangeVersion={onChangeQualityVersionRestored}
          renderRestore={isCurator}
          restoreCompound={restoreQuality}
          setCurrentVersion={setCurrentVersion}
          currentRound={currentRound}
          widget={{}}
        />
      ) : null}
    </>
  );
};

export default DefaultLayout;
