import _ from "lodash";

/**
 * Normalize table data for helping to search from explore page.
 * Used by Table Widget.
 * @param {Object} data
 * @param {Object} widget
 * @returns normalized data
 */
export const normalizeTableData = (data, widget) => {
  // Convert display Name to "database" name
  const convertedData = _.map(data, (item) => {
    let newObj = {};

    for (const [key, value] of Object.entries(item)) {
      if (key === "index") {
        newObj["index"] = value;
      } else {
        const row = _.find(_.get(widget, "rows") || [], { field: key });

        if (row && row?.name) {
          newObj[row?.name] = value;
        }
      }
    }

    return newObj;
  });

  try {
    const removeRedundants = _.map(convertedData, (row) => ({
      ..._.omit(row, ["0", 0]),
    }));

    let targetObj = {};

    _.forEach(removeRedundants, (row, index) => {
      for (const [key, value] of Object.entries(row)) {
        targetObj[`${key}|${index}`] = value;
      }
    });

    return targetObj;
  } catch (error) {
    return convertedData;
  }
};

/**
 * Denormalize data to consume by Table Widget.
 * Used by Table Widget.
 * @param {Object} data
 * @param {Object} widget
 * @returns de-normalized data
 */
export const deNormalizeTableData = (data, widget) => {
  let targetArr = [];

  try {
    for (const [key, value] of Object.entries(data)) {
      const field = key.split("|")[0];
      const index = key.split("|")[1];

      targetArr[index] = {
        ...(targetArr[index] || {}),
        [field]: value,
      };
    }

    const convertedArr = _.map(targetArr, (arr) => {
      let newObj = {};

      for (const [key, value] of Object.entries(arr)) {
        const row = _.find(_.get(widget, "rows") || [], { name: key });

        if (row && row?.field) {
          newObj[row?.field] = value;
        }
      }

      return newObj;
    });

    return convertedArr;
  } catch (error) {
    return data;
  }
};
