/* eslint-disable react-hooks/exhaustive-deps */
import { DatePicker, Input, Table } from "antd";
import _ from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { checkRowIsEditable } from "../../../../util/auth";
import moment from "moment";
import InputTag from "../../../InputTag";

const TableForm = forwardRef((props, ref) => {
  const { tableColumns, data, sourceName } = props;
  const [form, setForm] = useState([]);

  const onChangeValue = (index, field, value) => {
    const current = form[index];
    const newForm = form;

    const newItem = {
      ...current,
      [field]: value,
    };

    newForm[index] = newItem;

    setForm(newForm);
  };

  useEffect(() => {
    setForm(data);
  }, []);

  const dateFormat = "YYYY-MM-DD HH:mm:ss";
  const idsRef = useRef();

  const columns = tableColumns.map((tc) => {
    if (tc?.key === "actions") return tc;

    return {
      key: tc?.key,
      title: tc?.title,
      render: (_p, item, index) => {
        const defaultValue = _.get(item, tc?.key);

        const { isEditable, rowType } = tc || {};

        if (checkRowIsEditable(isEditable)) {
          if (rowType === "EDITABLE_DATE") {
            return (
              <DatePicker
                index={`${tc?.key}-${index}`}
                name={tc?.key}
                onChange={(e) => {
                  const date = moment(e).format(dateFormat);
                  onChangeValue(index, tc?.key, date);
                }}
                format={dateFormat}
                defaultValue={defaultValue ? moment(defaultValue) : ""}
              />
            );
          }

          if (rowType === "LIST") {
            return (
              <InputTag
                sourceName={sourceName}
                defaultValues={_.isArray(defaultValue) ? defaultValue : []}
                ref={idsRef}
                onChange={(values) => {
                  onChangeValue(index, tc?.key, values);
                }}
              />
            );
          }

          return (
            <Input
              index={`${tc?.key}-${index}`}
              size="small"
              defaultValue={defaultValue}
              onChange={(e) => onChangeValue(index, tc?.key, e.target.value)}
            />
          );
        } else {
          return <span>{defaultValue}</span>;
        }
      },
    };
  });

  useImperativeHandle(ref, () => ({
    getForm() {
      return form;
    },
  }));

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      pagination={false}
    />
  );
});

export default TableForm;
