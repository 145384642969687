import React from "react";
import { Form, Input } from "antd";
import PT from "prop-types";

import "./style.css";

const CompoundInputField = ({
  value,
  setValue,
  disableTitle = false,
  onChangeValue,
  formRef,
  molecularFormula,
  setMolecularFormula,
  molecularWeight,
  setMolecularWeight,
  disableUniqueDescriptorField = false,
  canvasChanged,
  isKnown,
}) => {
  const changeHandler = (event) => {
    event.preventDefault();
    setValue(event.target.value);
  };

  const onKeyChange = (event) => {
    if (!value) {
      formRef.current?.setFields([
        {
          name: "uniqueDescriptor",
          errors: [`Please enter unique descriptor.`],
        },
      ]);
    } else {
      if (event.key === "Enter" || event.keyCode === 13) {
        onChangeValue(value);
      }
    }
    canvasChanged();
  };

  return (
    <Form ref={formRef}>
      {!disableUniqueDescriptorField && (
        <Form.Item
          required
          label={disableTitle ? "" : "Enter Unique Descriptor"}
          name="uniqueDescriptor"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please enter unique descriptor",
            },
          ]}
        >
          <Input
            size="small"
            placeholder="Enter a unique short description"
            onChange={changeHandler}
            onKeyDown={onKeyChange}
            onBlur={(e) => onChangeValue(e.target.value)}
            value={value}
            defaultValue={value}
            className="unknown-structure-input-field"
            margin="normal"
            prefix="Unique descriptor: "
          />
        </Form.Item>
      )}
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="molecularFormula"
      >
        <div className="gx-d-flex">
          {/* <div className="compound-input-field-wrapper">
            <span className="title">Molecular Formula</span>
            <Tooltip title="ss">
              <InfoCircleTwoTone className="gx-pointer gx-ml-2 gx-mr-1 gx-mt-1 icon" />
            </Tooltip>
          </div> */}
          <Input
            size="small"
            className="unknown-structure-input-field"
            margin="normal"
            type="string"
            value={molecularFormula}
            defaultValue={molecularFormula}
            onChange={(e) => setMolecularFormula(e.target.value)}
            onKeyDown={onKeyChange}
            placeholder={isKnown ? "to be calculated" : "enter value"}
            prefix={
              <>
                <span className="title">Molecular Formula</span>
                {/* <Tooltip title="ss">
                  <InfoCircleTwoTone className="gx-pointer gx-ml-2 gx-mr-1 gx-mt-1 icon" />
                </Tooltip> */}
              </>
            }
          />
        </div>
      </Form.Item>
      <Form.Item
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        name="molecularWeight"
      >
        <Input
          size="small"
          className="unknown-structure-input-field"
          margin="normal"
          type="number"
          value={molecularWeight}
          defaultValue={molecularWeight}
          onChange={(e) => setMolecularWeight(e.target.value)}
          onKeyDown={onKeyChange}
          prefix="Molecular Weight: "
          placeholder={isKnown ? "to be calculated" : "enter value"}
        />
      </Form.Item>
    </Form>
  );
};

CompoundInputField.propTypes = {
  value: PT.string.isRequired,
  setValue: PT.func.isRequired,
  onChangeValue: PT.func.isRequired,
};

export default CompoundInputField;
