/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  getSystemWidget,
  getUserWidget,
} from "../../appRedux/services/SystemWidget";
import {
  DATA_WIDGETS_METADATA,
  DEFAULT_SOURCE_NAME,
  DOCUMENT_COLLECTION_OPTIONS,
  GLOBAL_LAYOUT_SETTINGS,
  SEARCH_PAGE_WIDGETS_OPTIONS,
  SYSTEM_WIDGETS_METADATA,
  TRAINING_DOCUMENTS_COLLECTION,
  TRAINING_DOCUMENT_SOURCE,
  USER_LAYOUT_SETTINGS,
  USER_ROLES,
} from "../../constants/Config";
import _ from "lodash";
import { getRole, getRoleId } from "../../util/auth";
import { searchDocumentation } from "../UploadPage/helper";
import DocumentationPopover from "../../components/DocumentationPopover";
import CircularProgress from "components/CircularProgress";
import { Drawer, Form, Select } from "antd";
import DocumentationSettings from "../../components/DocumentationSettings";
import { useMsal } from "@azure/msal-react";
import { filterByAuthorizedRoles } from "../../util/search";
import { getSources } from "../../appRedux/services/Widget";
import { XBlock, XMasonry } from "react-xmasonry";
import SearchWidget from "../../components/SearchWidget";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentWidgets } from "../../appRedux/actions";
import FormItem from "antd/es/form/FormItem";
import { Option } from "antd/lib/mentions";

const CollectionPage = () => {
  const [collections, setCollections] = useState([]);
  const [sources, setSources] = useState([]);

  const [loading, setLoading] = useState([]);
  const [currentSettings, setCurrentSettings] = useState([]);

  const [selectedDataSource, setSelectedDataSource] = useState(null);
  const [selectedCollection, setSelectedCollection] = useState(null);

  const [documentations, setDocumentations] = useState([]);
  const [openDocumentationSettings, setOpenDocumentationSettings] =
    useState(false);

  const [roles, setRoles] = useState([]);
  const [widgetOptions, setWidgetOptions] = useState([]);
  const [searchWidgets, setSearchWidgets] = useState([]);

  const { accounts } = useMsal();
  const userName = accounts[0]?.username;
  const userNameWithRole = `${userName}-${getRole()}`;
  const pageName = "collection";
  const dispatch = useDispatch();
  const { currentView } = useSelector(({ auth }) => auth);
  const [availableWidgetParams, setAvailableWidgetParams] = useState([]);
  const [dataWidgets, setDataWidgets] = useState([]);
  const [systemWidgets, setSystemWidgets] = useState([]);
  const [availableSearchWidgetParams, setAvailableSearchWidgetParams] =
    useState([]);

  useEffect(() => {
    if (_.isEmpty(roles)) {
      getSystemWidget(USER_ROLES, setRoles);
    }
  }, []);

  useEffect(() => {
    if (!dataWidgets.length) {
      getSystemWidget(DATA_WIDGETS_METADATA, setDataWidgets);
    }
    if (!systemWidgets.length) {
      getSystemWidget(SYSTEM_WIDGETS_METADATA, setSystemWidgets);
    }
  }, []);

  useEffect(() => {
    if (!(sources || []).length) getSources(setSources, true);
  }, []);

  useEffect(() => {
    if (!searchWidgets.length) {
      getSystemWidget(SEARCH_PAGE_WIDGETS_OPTIONS, setSearchWidgets);
    }
  }, []);

  useEffect(() => {
    getUserWidget(
      DEFAULT_SOURCE_NAME,
      userNameWithRole,
      (option) => {
        setSelectedDataSource(option?.registration);
        setSelectedCollection(option?.collection);
      },
      false,
      () => {
        setSelectedDataSource("");
      }
    );
  }, []);

  useEffect(() => {
    setLoading(true);
    getUserWidget(
      USER_LAYOUT_SETTINGS,
      userNameWithRole,
      (cur) => {
        dispatch(
          setCurrentWidgets({
            widgets: cur,
            pageName: pageName,
            database: "",
          })
        );

        setCurrentSettings(cur);
        setLoading(false);
      },
      false,
      () => {
        setLoading(false);
      }
    );
  }, []);

  useEffect(() => {
    if (_.isEmpty(collections)) {
      getSystemWidget(DOCUMENT_COLLECTION_OPTIONS, (allCollections) => {
        // Filter by edit role,
        const filteredCollections = _.filter(allCollections, (collection) =>
          (collection?.userRoles || []).includes(getRoleId())
        );
        setCollections(filteredCollections);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(async () => {
    await searchDocumentation(
      TRAINING_DOCUMENT_SOURCE,
      "collection",
      setDocumentations
    );
  }, []);

  const [availableWidgets, setAvailableWidgets] = useState([...searchWidgets]);
  const [existingLayoutSettings, setExistingLayoutSettings] = useState(null);
  const [existingGlobalSettings, setExistingGlobalSettings] = useState(null);
  const [globalSettings, setGlobalSettings] = useState([]);

  useEffect(() => {
    getUserWidget(GLOBAL_LAYOUT_SETTINGS, "Admin", setGlobalSettings, false);
  }, []);

  useEffect(() => {
    _.forEach(currentSettings, (k, v) => {
      // Check if database and pageName matches
      if (k?.pageName === pageName && k?.selected) {
        setExistingLayoutSettings(k);
      }
    });

    if (!_.isEmpty(currentView)) {
      setExistingLayoutSettings(_.get(currentSettings, currentView));
    }

    _.forEach(globalSettings, (k, v) => {
      if (k?.role === getRole() && k?.pageName === pageName) {
        setExistingGlobalSettings(k);
      }
    });

    if (existingLayoutSettings) {
      const targetWidgets = searchWidgets.filter((item) => {
        const widgetOptions = existingLayoutSettings?.widgetOptions || [];
        const widgets =
          _.map(widgetOptions, (widgetOption) => widgetOption?.widget) || [];
        return widgets.includes(item?.value);
      });
      setAvailableWidgets(targetWidgets);
    } else if (existingGlobalSettings) {
      const widgets = existingGlobalSettings?.widgets || [];
      const databases = existingGlobalSettings?.database || [];

      const targetWidgets = searchWidgets.filter((item) => {
        if (databases.length) {
          return widgets.includes(item?.value);
        } else {
          return widgets.includes(item?.value);
        }
      });
      setAvailableWidgets(targetWidgets);
    } else {
      setAvailableWidgets([]);
      setWidgetOptions([]);
    }

    if (existingLayoutSettings) {
      setWidgetOptions(existingLayoutSettings?.widgetOptions || []);
    } else if (existingGlobalSettings) {
      setWidgetOptions(existingGlobalSettings?.widgetOptions || []);
    }
  }, [
    currentSettings,
    searchWidgets,
    currentView,
    existingLayoutSettings,
    existingGlobalSettings,
    globalSettings,
  ]);

  useEffect(() => {
    if (
      (dataWidgets || []).length &&
      (systemWidgets || []).length &&
      !availableWidgetParams.length
    ) {
      let availableWidgets = [...systemWidgets, ...dataWidgets];
      let availableWidgetOptions = [];

      _.forEach(availableWidgets, (widget) => {
        const rows = _.get(widget, "widget.rows") || [];
        const widgetName = widget?.field;

        _.forEach(rows, (row) => {
          const acceptedRowTypes = ["EDITABLE", "EDITABLE_DATE", "LIST"];

          if (acceptedRowTypes.includes(row?.type)) {
            const option = `${widgetName} - ${row?.field || row?.value}`;
            const name = row?.name || row?.value || row?.field;

            availableWidgetOptions.push({
              ...row,
              label: option,
              value: row?.name,
              name,
              field: row?.field,
              widgetName: widget?.value,
            });
          }
        });
      });

      setAvailableWidgetParams(availableWidgetOptions);
    }
  }, [dataWidgets, systemWidgets]);

  useEffect(() => {
    if ((collections || []).length) {
      let allWidgets = [...(collections || [])];

      let allSearchWidgetOptions = [];

      _.forEach(allWidgets, (widget) => {
        const rows = _.get(widget, "widget.rows") || [];
        _.forEach(rows, (row) => {
          const option = `${row?.field || row?.value}`;
          const name = row?.value || row?.name || row?.field;

          if (_.get(row, `isAccessible.${getRole()}`)) {
            allSearchWidgetOptions.push({
              label: option,
              value: name,
              widgetName: widget?.value,
            });
          }
        });
      });

      setAvailableSearchWidgetParams(allSearchWidgetOptions);
    }
  }, [collections]);

  const trainingSettings =
    _.find(collections?.options || [], {
      value: TRAINING_DOCUMENTS_COLLECTION,
    }) || {};

  const isUserAllowedToManageDocumentation = (
    trainingSettings?.userRoles || []
  ).includes(getRole());

  const onUpdateDocumentations = () => {
    searchDocumentation(
      selectedDataSource?.option,
      pageName,
      setDocumentations
    );
  };

  const filteredDataSources = filterByAuthorizedRoles(
    sources,
    roles,
    getRole(),
    "access"
  );

  // const filteredWidgets = _.filter(collections || [], (widget) =>
  //   (widget?.userRoles || []).includes(getRole())
  // );

  const dataSourceOptions = _.map(filteredDataSources, (item) => ({
    label: item?.sourceName,
    value: item?.sourceName,
  }));

  const allWidgets = [...systemWidgets, ...dataWidgets];

  return (
    <div>
      <div
        className="gx-profile-banner sticky-header-component"
        style={{
          display: "flex",
          justifyContent: "space-between",
          height: "80px",
        }}
      >
        <div
          className="sticky-header-component-title"
          style={{
            display: "flex",
            paddingTop: "15px",
          }}
        >
          <h3>Collection Page</h3>
          <div>
            {(documentations || []).length ||
            isUserAllowedToManageDocumentation ? (
              <DocumentationPopover
                documentations={documentations}
                sourceName={TRAINING_DOCUMENT_SOURCE}
                setOpenDocumentationSettings={setOpenDocumentationSettings}
                hasPermission={isUserAllowedToManageDocumentation}
              />
            ) : null}
          </div>
        </div>

        <div className="gx-d-flex">
          {(filteredDataSources || []).length === 1 ? (
            <div className="gx-mr-4 gx-mt-3">
              <span>Data Source:</span>
              <span className="gx-ml-2 gx-text-warning">
                {filteredDataSources[0]?.sourceName}
              </span>
            </div>
          ) : (
            <Form
              className="gx-mt-2 gx-mr-5"
              style={{
                zIndex: 10000,
              }}
            >
              {!_.isNull(selectedDataSource) &&
              !_.isEmpty(filteredDataSources) ? (
                <FormItem
                  label="Select Data Source"
                  initialValue={selectedDataSource}
                  className="registerNewCompound__label"
                >
                  <Select
                    placeholder="Choose Data Source"
                    onChange={(e) => {
                      setSelectedDataSource(e);
                    }}
                    value={selectedDataSource}
                    dropdownMatchSelectWidth={false}
                  >
                    {filteredDataSources.map((item, index) => (
                      <Option
                        key={`data-source-option-${index}`}
                        value={item.sourceName}
                      >
                        {item.sourceName}
                      </Option>
                    ))}
                  </Select>
                </FormItem>
              ) : null}
              {!filteredDataSources.length && (
                <div className="gx-text-warning gx-mt-2">
                  No Data Sources Available. Please contact administrator.
                </div>
              )}
            </Form>
          )}
        </div>
      </div>

      {loading ? (
        <CircularProgress className="gx-loader-400 loader" />
      ) : (
        <div className="gx-mt-5 sticky-header-content">
          <XMasonry
            maxColumns={10}
            targetBlockWidth={200}
            center={false}
            responsive
            smartUpdate
            smartUpdateCeil={50}
          >
            {_.map(widgetOptions, (widgetOption) => {
              const currentWidget =
                _.find(availableWidgets, {
                  value: widgetOption?.widget,
                }) || {};

              const { widget } = currentWidget || {};

              const width = widgetOption?.width || 3;

              return (
                <XBlock width={width} key={widgetOption?.key}>
                  <SearchWidget
                    title={currentWidget?.field}
                    value={currentWidget?.value}
                    info={widget?.info}
                    description={widget?.description}
                    type={widget?.type}
                    sources={sources}
                    selectedSources={[
                      selectedDataSource || filteredDataSources[0]?.sourceName,
                    ]}
                    selectedCollection={selectedCollection}
                    documentCollectionOptions={collections}
                    navigationCard={null}
                    sourceMap={sources}
                    availableWidgetParams={availableWidgetParams}
                    availableSearchWidgetParams={availableSearchWidgetParams}
                    widgetOption={widgetOption}
                    widget={widget}
                    dataSourceOptions={dataSourceOptions}
                    availableWidgets={allWidgets}
                    pageType={pageName}
                    setSelectedCollection={setSelectedCollection}
                  />
                </XBlock>
              );
            })}
          </XMasonry>
        </div>
      )}

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setOpenDocumentationSettings(false)}
        open={openDocumentationSettings}
        title="Manage Documentations"
      >
        <DocumentationSettings
          documentations={documentations}
          onUpdateDocumentations={onUpdateDocumentations}
          pageName={pageName}
          sourceName={TRAINING_DOCUMENT_SOURCE}
        />
      </Drawer>
    </div>
  );
};

export default CollectionPage;
