import _ from "lodash";
import moment from "moment-timezone";
import React from "react";
import { NotificationManager } from "react-notifications";
import { v4 as uuidv4 } from "uuid";
import {
  getCounter,
  saveCounter,
} from "../../../../appRedux/services/Document";
import { PAGE_TYPES } from "../../../../constants/Config";
import { getRole } from "../../../../util/auth";
import { getFolderName } from "../../../../util/url";
import DropzoneWrapper from "../DropzoneWrapper";
import { renderDocumentName } from "../helper";

import "./style.css";

const RowDropzoneWrapper = ({
  content,
  setCurrentCollection,
  setFiles,
  setEditFileModal,
  uploadDocument,
  id,
  sourceName,
  setShowModal,
  setParentId,
  updateDocuments,
  defaultEntityIds,
  pageType,
  setLoading,
  setProgress,
  renderContent,
  collectionOptions,
  currentOpen,
  setCurrentOpen,
}) => {
  return (
    <div className="widget-card ant-card ant-card-bordered gx-card-widget document__widget rowDropzoneContainer">
      <div
        className="rowDropzoneArrowContainer"
        onClick={() => setCurrentOpen(!currentOpen)}
      >
        <div className="rowDropzoneArrowContainer__title">
          {renderDocumentName(true, {
            name: content?.name,
            collectionOptions,
            documentCount: (content?.items || []).length,
          })}
        </div>
        <div className="rowDropzoneArrowContainer__arrow">
          <i
            className={`icon gx-pl-2 ${
              currentOpen ? "icon-chevron-up" : "icon-chevron-down"
            }`}
          />
        </div>
      </div>
      {currentOpen ? (
        <DropzoneWrapper
          onDrop={(acceptedFiles) => {
            const { items } = content;

            let hasFolder =
              (acceptedFiles[0]?.path || "")?.split("/").length >= 3;

            const folderName = hasFolder
              ? (acceptedFiles[0]?.path || "")?.split("/")[1]
              : "";

            if (hasFolder) {
              uploadDocument(
                null,
                sourceName,
                [],
                {
                  collection: content?.name,
                  mimeType: "text/x-uri",
                  name: `${folderName}-${uuidv4()}`,
                  accessionNumber: 1,
                  entityIds: _.uniq([id, ...defaultEntityIds]),
                  searchSubjects: [],
                  owners: [getRole()],
                },
                (uploadedFolder) => {
                  const subsetId = uploadedFolder[0]?.data?.id;

                  let fileExists = false;
                  _.forEach(acceptedFiles, (file) => {
                    const foundFile = _.find(items, {
                      fileName: file?.name,
                    });
                    if (foundFile) {
                      fileExists = true;
                    }
                  });

                  if (fileExists) {
                    setCurrentCollection(content);
                    setFiles(acceptedFiles);
                    setEditFileModal(true);
                  } else {
                    getCounter((counterData) => {
                      const lastLockedTime = moment(
                        _.get(counterData, `${content?.name}.timestamp`)
                      );
                      const currentTime = moment().tz("America/Los_Angeles");
                      const duration = moment.duration(
                        currentTime.diff(lastLockedTime)
                      );
                      var minutes = duration.asMinutes();

                      if (
                        _.get(counterData, `${content?.name}.locked`) &&
                        minutes < 5
                      ) {
                        NotificationManager.error(
                          `This collection is currently being updated. Please try again in ${
                            5 - Math.floor(Number(minutes))
                          } minutes`
                        );
                      } else {
                        // Lock the counter for current collection.
                        saveCounter(
                          content?.name,
                          counterData,
                          _.get(counterData, `${content?.name}.counter`) || 0,
                          true,
                          () => {
                            const targetNum =
                              Number(
                                _.get(
                                  counterData,
                                  `${content?.name}.counter`
                                ) || 0
                              ) + acceptedFiles.length;

                            uploadDocument(
                              id,
                              sourceName,
                              acceptedFiles,
                              {
                                // entityIds: _.uniq([id, ...defaultEntityIds]),
                                collection: content?.name,
                                searchSubjects: [],
                                mimeType: acceptedFiles[0]?.type,
                                description: getFolderName(acceptedFiles),
                                fileName: acceptedFiles[0]?.name,
                                owners: [getRole()],
                                locked: true,
                                subset: subsetId,
                                accessionNumber: Number(
                                  _.get(
                                    counterData,
                                    `${content?.name}.counter`
                                  ) || 0
                                ),
                              },
                              () => {
                                setShowModal(false);
                                setParentId(null);
                                NotificationManager.success(
                                  "Document has been added"
                                );
                                updateDocuments();
                              },
                              (e) => {
                                setShowModal(false);
                                setParentId(null);
                                NotificationManager.error(e);
                                if (
                                  e === "File already exists." &&
                                  pageType === PAGE_TYPES.EXPERIMENT
                                ) {
                                  setCurrentCollection(content);
                                  setFiles(acceptedFiles);
                                  setEditFileModal(true);
                                }
                              },
                              setLoading,
                              setProgress
                            );

                            saveCounter(
                              content?.name,
                              counterData,
                              targetNum,
                              false
                            );
                          }
                        );
                      }
                    });
                  }
                },
                (err) => {
                  console.log("err", err);
                  NotificationManager.error(err);
                },

                () => {},
                () => {},
                false,
                [],
                true
              );
            } else {
              let fileExists = false;
              _.forEach(acceptedFiles, (file) => {
                const foundFile = _.find(items, {
                  fileName: file?.name,
                });
                if (foundFile) {
                  fileExists = true;
                }
              });

              if (fileExists) {
                setCurrentCollection(content);
                setFiles(acceptedFiles);
                setEditFileModal(true);
              } else {
                getCounter((counterData) => {
                  const lastLockedTime = moment(
                    _.get(counterData, `${content?.name}.timestamp`)
                  );
                  const currentTime = moment().tz("America/Los_Angeles");
                  const duration = moment.duration(
                    currentTime.diff(lastLockedTime)
                  );
                  var minutes = duration.asMinutes();

                  if (
                    _.get(counterData, `${content?.name}.locked`) &&
                    minutes < 5
                  ) {
                    NotificationManager.error(
                      `This collection is currently being updated. Please try again in ${
                        5 - Math.floor(Number(minutes))
                      } minutes`
                    );
                  } else {
                    // Lock the counter for current collection.
                    saveCounter(
                      content?.name,
                      counterData,
                      _.get(counterData, `${content?.name}.counter`) || 0,
                      true,
                      () => {
                        const targetNum =
                          Number(
                            _.get(counterData, `${content?.name}.counter`) || 0
                          ) + acceptedFiles.length;

                        uploadDocument(
                          id,
                          sourceName,
                          acceptedFiles,
                          {
                            entityIds: _.uniq([id, ...defaultEntityIds]),
                            collection: content?.name,
                            searchSubjects: [],
                            mimeType: acceptedFiles[0]?.type,
                            description: getFolderName(acceptedFiles),
                            fileName: acceptedFiles[0]?.name,
                            owners: [getRole()],
                            locked: true,

                            accessionNumber: Number(
                              _.get(counterData, `${content?.name}.counter`) ||
                                0
                            ),
                          },
                          () => {
                            setShowModal(false);
                            setParentId(null);
                            NotificationManager.success(
                              "Document has been added"
                            );
                            updateDocuments();
                          },
                          (e) => {
                            setShowModal(false);
                            setParentId(null);
                            NotificationManager.error(e);
                            if (
                              e === "File already exists." &&
                              pageType === PAGE_TYPES.EXPERIMENT
                            ) {
                              setCurrentCollection(content);
                              setFiles(acceptedFiles);
                              setEditFileModal(true);
                            }
                          },
                          setLoading,
                          setProgress
                        );

                        saveCounter(
                          content?.name,
                          counterData,
                          targetNum,
                          false
                        );
                      }
                    );
                  }
                });
              }
            }
          }}
        >
          <div className="document__content">
            <div>
              {renderContent(true, {
                items: content?.items,
                name: content?.name,
                isLockingAllowed: content?.isLockingAllowed,
                hasPermission: content?.hasPermission,
                unmappedCollection: content?.unmappedCollection,
                summaryField: content?.summaryField,
                downloadAccessRoles: content?.downloadAccessRoles,
                viewAccessRoles: content?.viewAccessRoles,
              })}
            </div>
          </div>
        </DropzoneWrapper>
      ) : null}
    </div>
  );
};

export default RowDropzoneWrapper;
