import React, { useRef, useState } from "react";
import PanelHeader from "./PanelHeader";
import { Resizable } from "react-resizable";

import "./style.css";
import { ExpandOutlined } from "@ant-design/icons";

const Panel = ({
  children,
  title = "Process Details",
  onDeletePanel,
  defaultWidth,
  defaultHeight,
}) => {
  const panelRef = useRef(null);
  const [width, setWidth] = useState(defaultWidth || 500);
  const [height, setHeight] = useState(defaultHeight || 500);
  const [hidden, setHidden] = useState(false);

  console.log("defaultHeight", defaultHeight);

  const handleDrag = (movementX, movementY) => {
    const panel = panelRef.current;
    if (!panel) return;

    const { x, y } = panel.getBoundingClientRect();

    panel.style.left = `${x + movementX}px`;
    panel.style.top = `${y + movementY}px`;
  };

  const handleResize = (event, { node, size, handle }) => {
    setWidth(size.width);
    setHeight(size.height);
  };

  const onClosePanel = () => {
    setHidden(true);
  };

  return (
    <>
      {hidden ? (
        <div class="panel__hidden ant-card">
          <div className="panel__header__content">
            <div>
              <span>{title}</span>
            </div>
            <div>
              <ExpandOutlined
                className="panel__header__right"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();

                  setHidden(false);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        <Resizable
          height={height}
          width={width}
          onResize={handleResize}
          className="ant-card ant-card-bordered gx-card-widget gx-card-tabs gx-card-prefix"
        >
          <div
            className="panel"
            ref={panelRef}
            style={{
              width: `${width}px`,
              height: `${height}px`,
              zIndex: 10000,
            }}
          >
            <PanelHeader
              onDrag={handleDrag}
              title={title}
              onClosePanel={onClosePanel}
              onDeletePanel={onDeletePanel}
            />

            <div className="panel__content ant-card-body">{children}</div>
          </div>
        </Resizable>
      )}
    </>
  );
};

export default Panel;
