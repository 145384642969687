import PT from "prop-types";
import React, { useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  DeleteTwoTone,
  EditTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { DatePicker, Form, Tooltip } from "antd";
import moment from "moment";
import { trimInput } from "../../../../util/generic";

import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import { searchUniquenessField } from "../../../../appRedux/services/Widget";
import { copyToClipboard } from "../../../../util/url";
import "./style.css";

const RowEditable = ({
  field,
  name,
  value = "",
  onChange,
  index,
  addMode,
  type,
  info = "",
  isEditable,
  limit,
  form,
  isRemovable,
  onRemove,
  systemWidgetName,
  isAccessible,
  isTrimmed,
  hideInfo,
  isCopiable,
  fromGroup = false,
  required = false,
  isUnique = false,
  sourceName,
  onChangeUniqueness = () => {},
}) => {
  const dateFormat = "YYYY-MM-DD";
  const [editMode, setEditMode] = useState(false);
  const formRef = useRef();

  const onChangeForm = (e) => {
    onChange(e.target.value);
  };

  const [copied, setCopied] = useState(false);

  const handleInputChange = (val) => {
    onChange(val);
  };

  const additionalFormRequirement = isUnique
    ? [
        {
          message: `${field} is not unique`,
          validator: async (_p, value) => {
            const body = {
              joinType: "AND",
              type: "COMPOUND",
              sourceName: {
                fieldType: "STRING",
                not: false,
                operator: "IN",
                values: [sourceName],
              },
              parameterQueries: [
                {
                  not: false,
                  operator: "EQ",
                  parameterField: name,
                  parameterName: "parameters",
                  queryJoinType: "AND",
                  values: [value],
                },
              ],
            };

            try {
              const res = await searchUniquenessField(body);
              if (res && _.isArray(res) && res.length) {
                console.log(res);
                onChangeUniqueness(`${field} is not unique.`);
                return Promise.reject(new Error("Field is not unique"));
              }
            } catch (error) {
              return Promise.reject(new Error("Error has occurred."));
            }

            onChangeUniqueness("");
            return Promise.resolve();
          },
        },
      ]
    : [];

  // const disabledDate = (current) => {
  //   return current && current.valueOf() > Date.now();
  // };

  // If value is not editable and value is empty, return null.
  if (!isEditable && !value) {
    return null;
  }

  if (!isAccessible) {
    return null;
  }

  const textAreaLimit = !!limit
    ? (value || "").length > limit
      ? (value || "").length
      : limit
    : null;

  const containerStyle = fromGroup
    ? !_.isEmpty(field)
      ? {
          marginRight: "30px",
          border: "none",
        }
      : { marginLeft: "-50px", border: "none" }
    : {};

  return (
    <>
      {addMode && isEditable ? (
        <div
          className={"card-item"}
          style={{
            ...containerStyle,
            justifyContent: "flex-start !important",
          }}
          key={`editable-input-${index}`}
        >
          <div className="card-item-field" style={{ marginRight: "10px" }}>
            <span>{field}</span>
            {required ? (
              <Tooltip title="Required field">
                <span
                  className="gx-text-danger"
                  style={{
                    fontSize: "15px",
                    fontWeight: "bold",
                    marginLeft: "5px",
                  }}
                >
                  {" "}
                  *
                </span>
              </Tooltip>
            ) : null}

            {info.length && !hideInfo ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
          </div>
          {value && !editMode ? (
            <div className="card-item-value">
              <div
                className="gx-d-flex"
                style={{
                  overflowWrap: "anywhere",
                }}
              >
                <span className="gx-mr-1">
                  {type === "date" ? moment(value).format(dateFormat) : value}
                </span>
                {isEditable && (
                  <EditTwoTone
                    twoToneColor="#FCB53B"
                    onClick={() => setEditMode(true)}
                  />
                )}
              </div>
            </div>
          ) : (
            <div className="card-item-value full-width">
              <Form onChange={onChangeForm} ref={formRef}>
                <Form.Item
                  name="value"
                  required={required}
                  rules={[
                    {
                      required: true,
                      message: `Please enter ${name}`,
                    },
                    ...additionalFormRequirement,
                  ]}
                >
                  {type === "date" ? (
                    <DatePicker
                      id={`editable-date-input-${index}`}
                      name={name}
                      key={uuidv4()}
                      onChange={(e) => {
                        const date = moment(e).format(dateFormat);
                        handleInputChange(date);
                      }}
                      format={dateFormat}
                      defaultValue={value ? moment(value) : ""}
                      // disabledDate={disabledDate}
                      size="small"
                      className="datePicketInput"
                      style={{ width: "100%" }}
                    />
                  ) : (
                    <TextArea
                      id={`editable-input-${index}`}
                      allowClear
                      type="text"
                      name={name}
                      defaultValue={_.isNull(value) ? "" : trimInput(value)}
                      key={uuidv4()}
                      maxLength={textAreaLimit}
                      width={100}
                      size="small"
                      onChange={(e) => {
                        e.preventDefault();
                        const val = trimInput(e.target.value);
                        handleInputChange(val);
                      }}
                    />
                  )}
                </Form.Item>
              </Form>
            </div>
          )}
        </div>
      ) : (
        <>
          {isEditable || isAccessible ? (
            <>
              {value || (_.isNumber(value) && value === 0) ? (
                <div
                  className={"card-item"}
                  style={{
                    ...containerStyle,
                    justifyContent: "flex-start !important",
                  }}
                  key={`editable-input-${index}`}
                >
                  <div
                    className="card-item-field"
                    style={{ marginRight: "10px" }}
                  >
                    <span>{field}</span>
                    {info.length ? (
                      <Tooltip title={info}>
                        <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
                      </Tooltip>
                    ) : null}
                    {isCopiable && (
                      <>
                        {copied ? (
                          <i
                            className="icon icon-check success"
                            style={{ marginTop: "3px" }}
                          />
                        ) : (
                          <i
                            className="icon icon-copy"
                            style={{ marginTop: "8px" }}
                            onClick={() =>
                              copyToClipboard(value, copied, setCopied)
                            }
                          />
                        )}
                      </>
                    )}
                  </div>
                  <div className="card-item-value half-width">
                    <>
                      {isTrimmed ? (
                        <Tooltip title={value} color="#038fdd">
                          <span className={isTrimmed ? "trimmed" : ""}>
                            {value}
                          </span>
                        </Tooltip>
                      ) : (
                        <div
                          className="gx-d-flex"
                          style={{
                            overflowWrap: "anywhere",
                          }}
                        >
                          <span>
                            {type === "date"
                              ? moment(value).format(dateFormat)
                              : value}
                          </span>
                          {isRemovable && (
                            <DeleteTwoTone
                              className="gx-ml-1"
                              twoToneColor="#f44336"
                              onClick={() => onRemove(field)}
                            />
                          )}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              ) : null}
            </>
          ) : null}
        </>
      )}
    </>
  );
};

RowEditable.defaultProps = {
  field: "",
  value: "",
  addMode: true,
  type: "",
  info: "",
  isEditable: false,
  limit: null,
  isRemovable: false,
  onRemove: () => {},
  isAccessible: false,
};

RowEditable.propTypes = {
  field: PT.string,
  value: PT.string,
  addMode: PT.bool,
  onChange: PT.func.isRequired,
  type: PT.string,
  info: PT.string,
  isEditable: PT.bool,
  limit: PT.number,
  isRemovable: PT.bool,
  onRemove: PT.func,
  isAccessible: PT.bool,
};

export default RowEditable;
