import {
  GET_DOCUMENT_ROLES_REQUESTED,
  GET_GENERIC_REQUESTED,
  GET_SOURCE_MAP_REQUESTED,
  GET_USER_ROLES_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Create action to get generic api request
 * @param {String} id current widget id
 * @param {String} sourceName source name
 * @param {String} field target field
 * @param {String} baseUrl base url of api
 * @param {Object} params defined query parameters
 * @param {Object} headers defined header parameters
 */
export const getGenericApi = (
  id,
  sourceName,
  field,
  baseUrl,
  params,
  headers
) => {
  return {
    type: GET_GENERIC_REQUESTED,
    payload: {
      id,
      sourceName,
      field,
      baseUrl,
      params,
      headers,
    },
  };
};

/**
 * Get Source Map
 */
export const getSourceMap = () => {
  return {
    type: GET_SOURCE_MAP_REQUESTED,
  };
};

/**
 * Get User Roles
 */
export const getUserRoles = () => {
  return {
    type: GET_USER_ROLES_REQUESTED,
  };
};

export const getDocumentRoles = () => {
  return {
    type: GET_DOCUMENT_ROLES_REQUESTED,
  };
};
