import React from "react";
import { Avatar, Popover } from "antd";
import AvatarImage from "../../assets/vendors/avatar.png";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { LOGIN_REQUEST } from "../../constants/Config";
import { isAdmin, signOutClickHandler } from "../../util/auth";

import "./style.css";

const UserInfo = ({ roles = [] }) => {
  const isAuthenticated = useIsAuthenticated();
  const { accounts, instance } = useMsal();

  const userMenuOptions = (
    <ul
      className="gx-user-popover"
      style={{ width: "200px", whiteSpace: "nowrap" }}
    >
      {isAdmin({ roles, targetUrl: "/page-settings" }) ? (
        <li>
          <span
            className="gx-text-primary gx-pointer"
            onClick={() => {
              window.location.assign(`/page-settings`);
            }}
          >
            Page Settings
          </span>
        </li>
      ) : null}

      {isAdmin({ roles, targetUrl: "/dashboard" }) ? (
        <li>
          <span
            className="gx-text-primary gx-pointer"
            onClick={() => {
              window.location.assign(`/dashboard`);
            }}
          >
            System Administration
          </span>
        </li>
      ) : null}
      <li
        className="gx-text-secondary"
        onClick={() =>
          signOutClickHandler(instance, accounts[0]?.homeAccountId)
        }
      >
        Logout
      </li>
    </ul>
  );

  const MenuOptions = (
    <ul className="gx-user-popover">
      <li
        onClick={() => {
          return instance.loginRedirect(LOGIN_REQUEST);
        }}
      >
        Login
      </li>
    </ul>
  );

  return (
    <Popover
      overlayClassName="gx-popover-horizantal zIndex-10000"
      placement="bottomRight"
      content={isAuthenticated ? userMenuOptions : MenuOptions}
      trigger="click"
    >
      <div
        className="gx-flex-row gx-align-items-center gx-avatar-row"
        style={{ fontSize: "14px" }}
      >
        <Avatar src={AvatarImage} className="gx-avatar gx-pointer" alt="" />
        <span className="gx-avatar-name gx-ml-2">
          {accounts[0]?.name}
          <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
        </span>
      </div>
    </Popover>
  );
};

export default UserInfo;
