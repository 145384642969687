import React from "react";
import PT from "prop-types";
import { Table } from "antd";

import "./style.css";

const TableModal = ({ columns, dataSource }) => {
  return (
    <Table
      className="compound-instance-table"
      columns={columns}
      bordered
      dataSource={dataSource}
      scroll={{
        x: "max-content",
      }}
      pagination={{
        showSizeChanger: true,
      }}
    />
  );
};

TableModal.defaultProps = {
  availableWidgets: [],
  data: {},
  targetWidgetName: "",
};

TableModal.propTypes = {
  availableWidgets: PT.arrayOf(PT.shape()),
  data: PT.shape(),
  targetWidgetName: PT.string,
};

export default TableModal;
