/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import PT from "prop-types";
import React, { useRef, useState } from "react";
import { NotificationManager } from "react-notifications";
import { ReactComponent as DownloadCloud } from "../../../../assets/vendors/downloadCloud.svg";
import { ReactComponent as PdfIcon } from "../../../../assets/vendors/pdf.svg";

import {
  Checkbox,
  Collapse,
  Descriptions,
  Divider,
  Form,
  Input,
  Popconfirm,
  Popover,
  Table,
  Tooltip,
} from "antd";
import Column from "antd/lib/table/Column";
import { v4 as uuidv4 } from "uuid";

import {
  CopyTwoTone,
  DeleteTwoTone,
  DownloadOutlined,
  FolderOpenTwoTone,
  FolderTwoTone,
  LockTwoTone,
  ProfileTwoTone,
  UnlockTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import { ReactComponent as ExpandIcon } from "../../../../assets/vendors/expand.svg";

import _ from "lodash";
import {
  downloadDocument,
  fetchDocumentContent,
} from "../../../../appRedux/services/Document";
import { generateExternalUrl } from "../../../../util/url";

import { useForm } from "antd/lib/form/Form";
import { fetchDocumentApi } from "../../../../appRedux/sagas/Document";
import { PAGE_TYPES } from "../../../../constants/Config";
import { isExcelFile } from "../../../../util/excel";
import CurrentSubsetDocument from "./CurrentSubsetDocument";
import "./style.css";

const DocumentColumnSettings = ({
  file,
  sourceName,
  onSaveFile,
  onDelete,
  onEdit,
  onSelect,
  onSaveDescription,
  isLockingAllowed,
  onLock,
  onConvert,
  locked,
  hasPermission,
  pageType,
  hasDownloadAccess = false,
  cloneCurrentDocument,
  collectionName,
  collectionOptions = [],
  onUploadSubsetFile,
}) => {
  const [editMode, setEditMode] = useState(false);
  const [collapseActiveKey, setCollapseActiveKey] = useState("1");
  const activeKey = "2";
  const formRef = useRef();
  const urlFormRef = useRef();
  const [externalDocuments, setExternalDocuments] = useState([]);

  const isExternalDocumentsEmpty = _.isEmpty(externalDocuments);

  const onSearchExternalDocuments = () => {
    fetchDocumentContent(file?.id, file?.collection, sourceName).then((res) => {
      const filteredDocuments = _.filter(res?.data?.hitList, (item) => {
        if (
          item?.collection === file?.collection &&
          item?.subset === file?.id
        ) {
          return true;
        }
        return false;
      });

      setExternalDocuments(res?.data?.hitList || []);
    });
  };

  const isCurrentItemUrl = (item) =>
    _.has(item, "uri") && item?.mimeType === "text/x-uri";

  const renderDocument = (p, item) => {
    const { id, fileName, uri, name } = item;
    const isUrl = isCurrentItemUrl(item);

    return (
      <>
        {editMode ? null : (
          <>
            {hasPermission ? (
              <div className="gx-d-flex">
                <ProfileTwoTone
                  style={
                    isExternalDocumentsEmpty
                      ? {
                          marginLeft: "5px",
                        }
                      : {}
                  }
                  className={`gx-mr-3`}
                  onClick={() => {
                    onSelect(file);
                  }}
                />

                {isUrl ? (
                  <>
                    {(uri || "").length ? (
                      <a
                        href={generateExternalUrl(uri)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {name || uri || "name is empty"}
                      </a>
                    ) : (
                      <span>{name || uri || "name is empty"}</span>
                    )}
                  </>
                ) : (
                  <>
                    {hasDownloadAccess ? (
                      <DownloadCloud
                        className="gx-mr-3 cloud-download-icon gx-pointer"
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          downloadDocument(id, sourceName, fileName);
                        }}
                      />
                    ) : null}
                    <span
                      style={{
                        whiteSpace: "nowrap",
                        width: "100px",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                      }}
                    >
                      {fileName || uri || name || "name is empty"}
                    </span>
                  </>
                )}
              </div>
            ) : (
              <>
                {isUrl ? (
                  <a
                    href={generateExternalUrl(uri)}
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      textOverflow: "ellipsis",
                    }}
                  >
                    {name || uri || "name is empty"}
                  </a>
                ) : (
                  <span
                    className="gx-text-primary"
                    style={{
                      textOverflow: "ellipsis",
                    }}
                  >
                    {fileName}
                  </span>
                )}
              </>
            )}
          </>
        )}
      </>
    );
  };

  const renderActionButtons = (p, item) => {
    const showActionButton = !locked && hasPermission;
    return (
      <div className="gx-d-flex">
        {showActionButton &&
          isExcelFile(item?.mimeType) &&
          pageType === PAGE_TYPES.EXPERIMENT && (
            <Popover content="Convert to PDF.">
              <PdfIcon
                className="gx-mr-3  expand-icon"
                onClick={() => onConvert(item)}
              />
            </Popover>
          )}

        {/* {showActionButton && (
          <>
            {editMode ? (
              <>
                <Popover content="Save document">
                  <SaveTwoTone
                    twoToneColor="#52c41a"
                    className="gx-mr-3"
                    onClick={() => {
                      setEditMode(false);
                      onSaveDescription(
                        file,
                        formRef?.current?.getFieldValue("description"),
                        urlFormRef?.current?.getFieldValue("url")
                      );
                    }}
                  />
                </Popover>

                <Popover content="Close document">
                  <CloseCircleTwoTone
                    twoToneColor="#f44336"
                    className="gx-mr-3"
                    onClick={() => setEditMode(false)}
                  />
                </Popover>
              </>
            ) : (
              <Popover content="Edit document">
                <EditTwoTone
                  twoToneColor="#038FDE"
                  className="gx-mr-3"
                  onClick={() => setEditMode(true)}
                />
              </Popover>
            )}
          </>
        )} */}

        {showActionButton && (
          <Popover content="Clone Document">
            <CopyTwoTone
              className="gx-mr-3 gx-pointer"
              onClick={() => cloneCurrentDocument(item)}
            />
          </Popover>
        )}

        {showActionButton && (
          <Popover content="Show details">
            <ExpandIcon
              className="gx-mr-3 expand-icon"
              onClick={() => onEdit(item)}
            />
          </Popover>
        )}
        {showActionButton && (
          <Popconfirm
            title="Are you sure to delete?"
            onConfirm={() => onDelete(item.id)}
            okText="Yes"
            cancelText="No"
          >
            <DeleteTwoTone
              className="gx-mr-3 gx-pointer"
              twoToneColor="#f44336"
            />
          </Popconfirm>
        )}
        {isLockingAllowed && hasPermission && (
          <>
            {locked ? (
              <Popover content="Unlock document">
                <UnlockTwoTone
                  onClick={() => onLock(item, false)}
                  twoToneColor="#f44336"
                  className="gx-ml-5"
                />
              </Popover>
            ) : (
              <Popover content="Lock document">
                <LockTwoTone
                  twoToneColor="#52c41a"
                  onClick={() => onLock(item, true)}
                />
              </Popover>
            )}
          </>
        )}
      </div>
    );
  };

  const renderDescription = (p, { description }) => {
    return (
      <>
        {editMode ? (
          <Form
            ref={formRef}
            className="gx-mr-4"
            style={{ paddingTop: "20px" }}
            size="small"
            layout="horizontal"
          >
            <Form.Item name="description" label="Description: ">
              <Input
                defaultValue={description}
                width="80%"
                onChange={(e) => {
                  e.stopPropagation();
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Popover
            placement="top"
            content={
              <span>
                <span className="gx-text-primary">description</span>:{" "}
                {description}
              </span>
            }
            trigger="hover"
          >
            <span
              style={{
                textOverflow: "ellipsis",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inherit",
              }}
            >
              {description}
            </span>
          </Popover>
        )}
      </>
    );
  };
  const renderAccessionNumber = (p, { accessionNumber }) => {
    return (
      <>
        {editMode ? (
          <Form
            ref={formRef}
            className="gx-mr-4"
            style={{ paddingTop: "20px" }}
            size="small"
            layout="horizontal"
          >
            <Form.Item name="accessionNumber" label="Accession Number: ">
              <Input
                defaultValue={accessionNumber}
                width="80%"
                onChange={(e) => {
                  e.stopPropagation();
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Popover
            placement="top"
            content={
              <span>
                <span className="gx-text-primary">Accession Number</span>:{" "}
                {accessionNumber}
              </span>
            }
            trigger="hover"
          >
            <span
              style={{
                textOverflow: "ellipsis",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inherit",
              }}
            >
              {accessionNumber}
            </span>
          </Popover>
        )}
      </>
    );
  };

  const renderUrl = (p, item) => {
    return (
      <>
        {editMode && isCurrentItemUrl(item) ? (
          <Form ref={urlFormRef} size="small" layout="horizontal">
            <Form.Item name="url" label="Url: ">
              <Input
                defaultValue={item?.uri}
                placeholder="Enter uri"
                style={{ width: "80%" }}
              />
            </Form.Item>
          </Form>
        ) : null}
      </>
    );
  };

  const renderAdditionalData = (
    fieldName,
    additionalData,
    additionalDataPopup
  ) => {
    return (
      <>
        {editMode ? null : (
          <Popover
            placement="top"
            content={
              <span>
                <span className="gx-text-primary">{fieldName}</span>:{" "}
                {additionalDataPopup}
              </span>
            }
            trigger="hover"
          >
            <span
              style={{
                textOverflow: "ellipsis",
                maxWidth: "200px",
                whiteSpace: "nowrap",
                overflow: "hidden",
                display: "inherit",
              }}
            >
              {additionalData}
            </span>
          </Popover>
        )}
      </>
    );
  };

  const { searchSubjects } = file;

  const currentCollection = _.find(collectionOptions, {
    value: collectionName,
  });

  const allRows = _.get(currentCollection || {}, "widget.rows") || [];
  const inlineRows = _.map(
    _.filter(allRows, (row) => row?.isInlineField),
    (i) => ({ name: i?.name, field: i?.field, type: i?.type })
  );

  const documentInlineFields =
    _.get(currentCollection || {}, "widget.inlineFields") || [];

  const renderInline = (f) => {
    if (f === "url") {
      <Column title="url" key="url" render={renderUrl} />;
    }
    if (f === "description") {
      return (
        <Column
          title="description"
          key="description"
          width={200}
          render={renderDescription}
        />
      );
    }

    if (f === "accessionNumber") {
      return (
        <Column
          title="Accession Number"
          key="accessionNumber"
          width={200}
          render={renderAccessionNumber}
        />
      );
    }
  };

  const table = (
    <Table
      dataSource={[file]}
      showHeader={false}
      pagination={false}
      className="document-row-table"
    >
      <Column title="File Name" key="document" render={renderDocument} />
      {_.map(inlineRows, (inlineField) => {
        const additionalDataToDisplay =
          _.get(file, inlineField?.name) ||
          _.find(searchSubjects, { key: inlineField?.name })?.value;

        if (inlineField?.type === "LIST") {
          let targetData = additionalDataToDisplay;
          try {
            targetData = JSON.parse(additionalDataToDisplay);

            return additionalDataToDisplay ? (
              <Column
                title={""}
                key={uuidv4()}
                render={() =>
                  renderAdditionalData(
                    inlineField?.field,
                    `${targetData[0]} ...`,
                    _.join(targetData, ", ")
                  )
                }
              />
            ) : null;
          } catch (error) {}
        }

        return additionalDataToDisplay ? (
          <Column
            title={""}
            key={uuidv4()}
            render={() =>
              renderAdditionalData(
                inlineField?.field,
                additionalDataToDisplay,
                additionalDataToDisplay
              )
            }
          />
        ) : null;
      })}
      {_.map(documentInlineFields || [], (f) => {
        return renderInline(f);
      })}
      <Column width={50} key="actionButtons" render={renderActionButtons} />
    </Table>
  );

  const [form] = useForm();

  const downloadAll = () => {
    const downloadIds = _.filter(externalDocuments || [], (ed) => {
      return form.getFieldValue(ed);
    });

    if (_.isEmpty(downloadIds)) {
      NotificationManager.error("Please select document.");
    }

    let promises = [];
    _.forEach(downloadIds, (dId) => {
      promises.push(fetchDocumentApi({ documentId: dId }));
    });

    Promise.allSettled(promises).then((res) => {
      _.forEach(res, (r) => {
        if (r.status === "fulfilled") {
          downloadDocument(
            r.value?.data?.id,
            sourceName,
            r.value?.data?.fileName || "externalDocument"
          );
        }
      });
    });
  };

  return (
    <div key={uuidv4()}>
      <>
        <Collapse
          defaultActiveKey={activeKey}
          onChange={(key) => {
            if (collapseActiveKey !== activeKey) {
              setCollapseActiveKey(activeKey);
              onSearchExternalDocuments();
            } else {
              setCollapseActiveKey("1");
            }
          }}
          expandIcon={({ isActive }) => {
            return (
              <div className="gx-mr-2">
                {isActive ? (
                  <FolderOpenTwoTone
                    style={{
                      fontSize: "14px",
                      alignSelf: "center",
                      display: "flex",
                    }}
                    className="gx-ml-2"
                  />
                ) : (
                  <FolderTwoTone
                    style={{
                      fontSize: "14px",
                      alignSelf: "center",
                      display: "flex",
                    }}
                    className="gx-ml-2"
                  />
                )}
              </div>
            );
          }}
        >
          <Collapse.Panel
            key={collapseActiveKey}
            header={table}
            forceRender={true}
            collapsible="icon"
          >
            <Descriptions
              size="small"
              bordered
              layout="vertical"
              style={{ fontSize: "10px" }}
              title={null}
            >
              <div>
                <Form form={form} size="small">
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    className="gx-d-flex"
                  >
                    <div className="gx-d-flex ">
                      {(externalDocuments || []).length && hasDownloadAccess ? (
                        <div
                          label="Select All"
                          style={{ padding: 0, margin: 0 }}
                        >
                          <Checkbox
                            onChange={(e) => {
                              form.setFields(
                                _.map(externalDocuments, (ed) => ({
                                  name: ed,
                                  value: e.target.checked,
                                }))
                              );
                            }}
                          />
                          <span className="gx-ml-1 gx-mb-1 gx-text-cyan">
                            Select All
                          </span>
                          <Divider />
                        </div>
                      ) : null}

                      {hasDownloadAccess ? (
                        <div
                          className="gx-pointer gx-mt-1 gx-ml-3"
                          onClick={downloadAll}
                        >
                          <DownloadOutlined />
                          <span className="gx-ml-2 gx-text-primary">
                            Download selected
                          </span>
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    {(externalDocuments || []).length ? (
                      <div>
                        <Tooltip title="Upload a file">
                          <UploadOutlined
                            className="gx-mr-2 gx-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              onUploadSubsetFile(file);
                            }}
                          />
                          <span className="gx-text-primary">Upload</span>
                        </Tooltip>
                      </div>
                    ) : null}
                  </div>

                  {_.map(externalDocuments || [], (currentDocument) => (
                    <div className="gx-d-flex" key={uuidv4()}>
                      <Form.Item
                        key={currentDocument}
                        valuePropName="checked"
                        name={currentDocument}
                        style={{ padding: 0, margin: 0 }}
                      >
                        <Checkbox
                          disabled={!hasDownloadAccess}
                          className="gx-mr-3 gx-ml-3"
                        />
                      </Form.Item>
                      <CurrentSubsetDocument
                        key={uuidv4()}
                        documentId={currentDocument}
                        sourceName={sourceName}
                        hasDownloadAccess={hasDownloadAccess}
                        form={form}
                      />
                    </div>
                  ))}
                </Form>
              </div>
              {!(externalDocuments || []).length && (
                <Descriptions.Item
                  label={
                    <>
                      <span>No documents found for this folder.</span>
                      <Tooltip title="Upload a file">
                        <UploadOutlined
                          className="gx-mt-2 gx-ml-2 gx-pointer"
                          onClick={(e) => {
                            // e.stopPropagation();
                            e.preventDefault();
                            onUploadSubsetFile(file);
                          }}
                        />
                      </Tooltip>
                    </>
                  }
                  style={{ fontSize: "12px" }}
                ></Descriptions.Item>
              )}
            </Descriptions>
          </Collapse.Panel>
        </Collapse>
      </>
    </div>
  );
};

DocumentColumnSettings.defaultProps = {
  file: {},
  sourceName: "",
};

DocumentColumnSettings.propTypes = {
  file: PT.shape(),
  sourceName: PT.string,
  onSaveFile: PT.func.isRequired,
  onDelete: PT.func.isRequired,
  onEdit: PT.func.isRequired,
};

export default DocumentColumnSettings;
