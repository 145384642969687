import { Table } from "antd";
import Column from "antd/lib/table/Column";
import _ from "lodash";

export const getLanguageItem = (name = "", mapping = {}) => {
  if (_.get(mapping, name)) {
    return mapping[name];
  }

  return "";
};

/**
 * Renders Safety Sentences Modal
 * @param {*} elements
 * @param {*} selectedSentence
 * @param {*} languageMap
 * @param {*} imageMap
 * @returns
 */
export const renderSafetySentencesModal = (
  elements,
  selectedSentence,
  languageMap,
  imageMap
) => {
  const statementId = selectedSentence?.id;
  const category = selectedSentence?.category;

  const tableData = findSafetySentenceFromMap({
    elements,
    statementId,
    classification: category,
  });

  const renderListItem = (statements) => {
    return (
      <>
        {statements.map((item) => (
          <>
            {getLanguageItem(item, languageMap) ? (
              <>
                <span
                  dangerouslySetInnerHTML={{
                    __html: `<strong>${item}</strong> - ${getLanguageItem(
                      item,
                      languageMap
                    )}`,
                  }}
                ></span>
                <br />
              </>
            ) : null}
          </>
        ))}
      </>
    );
  };

  // const renderCategory = (p, { classification }) => {
  //   return (
  //     <div
  //       dangerouslySetInnerHTML={{
  //         __html: getLanguageItem(classification, languageMap),
  //       }}
  //     ></div>
  //   );
  // };

  const renderPictogramId = (p, { pictogramIds = [], signalWord }) => {
    return pictogramIds.map((pictogram) => {
      const img = getLanguageItem(pictogram, imageMap);

      return (
        <>
          <img
            className="gx-mr-1"
            alt="pictorgram"
            height={50}
            width={50}
            src={`data:image/jpeg;base64,${img}`}
          />
          <div
            className="gx-mt-1 gx-ml-2"
            dangerouslySetInnerHTML={{
              __html: getLanguageItem(signalWord, languageMap),
            }}
          ></div>
        </>
      );
    });
  };

  const renderHazardStatement = (p, { hazard = [], classification }) => {
    const hazardStatements = [...[statementId], hazard];
    return (
      <>
        {renderListItem(hazardStatements)}
        <span
          dangerouslySetInnerHTML={{
            __html: getLanguageItem(classification, languageMap),
          }}
        ></span>
      </>
    );
  };

  // const renderSignalWord = (p, { signalWord }) => {
  //   return (
  //     <div
  //       dangerouslySetInnerHTML={{
  //         __html: getLanguageItem(signalWord, languageMap),
  //       }}
  //     ></div>
  //   );
  // };

  const renderPrevention = (p, { prevention = [] }) => {
    return renderListItem(prevention);
  };

  const renderResponse = (p, { response = [] }) => {
    return renderListItem(response);
  };

  const renderStorage = (p, { storage = [] }) => {
    return renderListItem(storage);
  };

  const renderDisposal = (p, { disposal = [] }) => {
    return renderListItem(disposal);
  };

  if (tableData) {
    return (
      <>
        <Table
          dataSource={[tableData]}
          scroll
          pagination={false}
          showHeader={false}
          size="small"
          className="safetySentences__table"
        >
          <Column
            title="Pictogram"
            render={renderPictogramId}
            width={100}
          ></Column>
          <Column
            title="Hazard Statement"
            render={renderHazardStatement}
          ></Column>
          {/* <Column title="Category" render={renderCategory}></Column> */}
          {/* <Column title="Signal Word" render={renderSignalWord}></Column> */}
        </Table>
        <Table
          dataSource={[tableData]}
          scroll
          pagination={false}
          size="small"
          className="safetySentences__table"
        >
          <Column
            title="Precautionary Statement Prevention"
            render={renderPrevention}
            width={200}
          ></Column>
          <Column
            title="Precautionary Statement Response"
            render={renderResponse}
            width={200}
          ></Column>
          <Column
            title="Precautionary Statement Storage"
            render={renderStorage}
            width={200}
          ></Column>
          <Column
            title="Precautionary Statement Disposal"
            render={renderDisposal}
            width={200}
          ></Column>
        </Table>
      </>
    );
  }
};

/**
 *
 */
export const findSafetySentenceFromMap = ({
  elements,
  statementId,
  classification,
}) => {
  const foundStatement = _.find(elements, { statementId });

  if (foundStatement && (foundStatement?.category || []).length) {
    const result = _.find(foundStatement?.category, {
      classification,
    });

    if (result) {
      return result;
    }
  }

  return [];
};
