import axios from "axios";
import axiosInstance from "./api";
import { PAGE_TYPES, config } from "../../constants/Config";
import { getParent } from "../../util/Widget";
import { getImageUrlParams } from "../../util/url";

/**
 * Get Smiles Format Definitions from compound
 *
 * @param {String} id compound id
 * @param {String} sourceName source name
 *
 * @returns {Promise}
 */
export const getSmilesApi = async ({ id, sourceName }) =>
  axios.get(
    `${config.SCAS_API_URL}/ontology/structure/${getParent(
      id
    )}?sourceName=${sourceName}&originalMaterial=true&format=smiles`
  );

/**
 * Get Metadata from compound
 * @param {String} metadataName meta data name
 * @param {String} id compound id
 * @param {String} sourceName source name
 *
 * @returns {Promise}
 */
export const getMetadataApi = async ({
  metadataName,
  id,
  sourceName,
  isProcess,
}) => {
  if (isProcess) {
    return axiosInstance.get(
      `/metadata/process/${metadataName}?processId=${id}&sourceName=${sourceName}`
    );
  } else {
    return axiosInstance.get(
      `/metadata/${metadataName}?compoundId=${id}&sourceName=${sourceName}`
    );
  }
};

/**
 * Get image data from compound id
 *
 * @param {String} id compound id
 * @param {String} sourceName source name
 * @param {String} backgroundColor background color. Default to transparent.
 *
 * @returns {Promise}
 */
export const getImageApi = async ({
  id,
  sourceName,
  backgroundColor = "transbg",
  originalMaterial = true,
}) => {
  if (id && sourceName) {
    return axios.get(
      `${
        config.SCAS_API_URL
      }/ontology/image/svg/${id}?sourceName=${sourceName}&originalMaterial=${originalMaterial}&${getImageUrlParams(
        backgroundColor
      )}`,
      {
        headers: {
          Accept: "image/*",
          "Content-Type": "*/*",
        },
      }
    );
  }
};

export const getCompoundInstances = ({ id, sourceName }) => {
  return axiosInstance.get(
    `/compoundInstances?id=${id}&source%20name=${sourceName}`
  );
};

export const getCompoundConceptGraph = ({ id, sourceName }) => {
  return axiosInstance.get(
    `/compoundConceptGraph?id=${id}&source%20name=${sourceName}`
  );
};

/**
 * get compound concept
 *
 * @param {String} id compound id
 * @param {String} sourceName source name
 *
 * @returns {Promise}
 */
export const getCompoundConceptApi = async ({ id, sourceName }) =>
  axiosInstance.get(`/compoundConcept?id=${id}&source%20name=${sourceName}`);

/**
 * delete compound concept
 *
 * @param {String} id compound id
 * @param {String} sourceName source name
 * @param {String} pageType page type
 *
 * @returns {Promise}
 */
export const deleteCompoundConceptApi = async ({
  id,
  sourceName,
  pageType,
}) => {
  if (pageType === PAGE_TYPES.QUALITY) {
    return axiosInstance.delete(
      `/compoundInstance?instanceId=${id}&source=${sourceName}`
    );
  } else {
    return axiosInstance.delete(
      `/compoundConcept?compoundConcept=${id}&sourceName=${sourceName}`
    );
  }
};

/**
 * restore compound concept
 *
 * @param {String} id compound id
 * @param {String} sourceName source name
 *
 * @returns {Promise}
 */
export const restoreCompoundConceptApi = async (body) =>
  axiosInstance.put(`/compoundConcept`, body);

export const restoreQualityApi = async (sourceName, instanceId, body) =>
  axiosInstance.put(
    `/compoundInstance?instanceId=${instanceId}&sourceName=${sourceName}`,
    body
  );
