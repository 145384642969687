// Customizer const
export const TOGGLE_COLLAPSED_NAV = "TOGGLE_COLLAPSE_MENU";
export const WINDOW_WIDTH = "WINDOW-WIDTH";
export const SWITCH_LANGUAGE = "SWITCH-LANGUAGE";

//Contact Module const
export const FETCH_START = "fetch_start";
export const FETCH_SUCCESS = "fetch_success";
export const FETCH_ERROR = "fetch_error";
export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
export const ON_SHOW_LOADER = "ON_SHOW_LOADER";
export const ON_HIDE_LOADER = "ON_HIDE_LOADER";

// VALIDATE STRUCTURE
export const AUTO_ASSIGN_REQUESTED = "AUTO_ASSIGN_REQUESTED";
export const AUTO_ASSIGN_SUCCESS = "AUTO_ASSIGN_SUCCESS";
export const AUTO_ASSIGN_FAILED = "AUTO_ASSIGN_FAILED";

export const CLEAR_ERROR_MESSAGE_REQUESTED = "CLEAR_ERROR_MESSAGE_REQUESTED";
export const CLEAR_ERROR_MESSAGE_SUCCESS = "CLEAR_ERROR_MESSAGE_SUCCESS";

export const CHECK_UNIQUENESS_REQUESTED = "CHECK_UNIQUENESS_REQUESTED";
export const CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS =
  "CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS";
export const CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS =
  "CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS";
export const CHECK_UNIQUENESS_FAILED = "CHECK_UNIQUENESS_FAILED";

export const REGISTER_REQUESTED = "REGISTER_REQUESTED";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const UPDATE_REQUESTED = "UPDATE_REQUESTED";
export const UPDATE_SUCCESS = "UPDATE_SUCCESS";
export const UPDATE_FAILED = "UPDATE_FAILED";

export const UPDATE_LATEST_COMPOUND_REQUESTED =
  "UPDATE_LATEST_COMPOUND_REQUESTED";
export const UPDATE_LATEST_COMPOUND_SUCCESS = "UPDATE_LATEST_COMPOUND_SUCCESS";
export const UPDATE_LATEST_COMPOUND_FAILED = "UPDATE_LATEST_COMPOUND_FAILED";

export const CLEAR_REGISTER_DATA_REQUESTED = "CLEAR_REGISTER_DATA_REQUESTED";
export const CLEAR_REGISTER_DATA_SUCCESS = "CLEAR_REGISTER_DATA_SUCCESS";

export const GENERATE_IMAGE_REQUESTED = "GENERATE_IMAGE_REQUESTED";
export const GENERATE_IMAGE_SUCCESS = "GENERATE_IMAGE_SUCCESS";
export const GENERATE_IMAGE_FAILED = "GENERATE_IMAGE_FAILED";

// Metadata
export const GET_METADATA_REQUESTED = "GET_METADATA_REQUESTED";
export const GET_METADATA_SUCCESS = "GET_METADATA_SUCCESS";
export const GET_METADATA_FAILED = "GET_METADATA_FAILED";

export const GET_ELEMENTAL_ANALYSIS_REQUESTED =
  "GET_ELEMENTAL_ANALYSIS_REQUESTED";
export const GET_ELEMENTAL_ANALYSIS_SUCCESS = "GET_ELEMENTAL_ANALYSIS_SUCCESS";
export const GET_ELEMENTAL_ANALYSIS_FAILED = "GET_ELEMENTAL_ANALYSIS_FAILED";

export const CLOSE_ALERT = "CLOSE_ALERT";
export const CLOSE_ALERT_SUCCESS = "CLOSE_ALERT_SUCCESS";

export const SAVE_METADATA_REQUESTED = "SAVE_METADATA_REQUESTED";
export const SAVE_METADATA_SUCCESS = "SAVE_METADATA_SUCCESS";
export const SAVE_METADATA_FAILED = "SAVE_METADATA_FAILED";

// System Widget
export const GET_SYSTEM_WIDGETS_REQUESTED = "GET_SYSTEM_WIDGETS_REQUESTED";
export const GET_SYSTEM_WIDGETS_SUCCESS = "GET_SYSTEM_WIDGETS_SUCCESS";
export const GET_SYSTEM_WIDGETS_FAILED = "GET_SYSTEM_WIDGETS_FAILED";

// System Widget
export const GET_PROCESS_WIDGETS_REQUESTED = "GET_PROCESS_WIDGETS_REQUESTED";
export const GET_PROCESS_WIDGETS_SUCCESS = "GET_PROCESS_WIDGETS_SUCCESS";
export const GET_PROCESS_WIDGETS_FAILED = "GET_PROCESS_WIDGETS_FAILED";

// Search Page
export const GET_DATA_SOURCES_REQUESTED = "GET_DATA_SOURCES_REQUESTED";
export const GET_DATA_SOURCES_SUCCESS = "GET_DATA_SOURCES_SUCCESS";
export const GET_DATA_SOURCES_FAILED = "GET_DATA_SOURCES_FAILED";

// Generic API
export const GET_GENERIC_REQUESTED = "GET_GENERIC_REQUESTED";
export const GET_GENERIC_SUCCESS = "GET_GENERIC_SUCCESS";
export const GET_GENERIC_FAILED = "GET_GENERIC_FAILED";

// Get source map
export const GET_SOURCE_MAP_REQUESTED = "GET_SOURCE_MAP_REQUESTED";
export const GET_SOURCE_MAP_SUCCESS = "GET_SOURCE_MAP_SUCCESS";
export const GET_SOURCE_MAP_FAILED = "GET_SOURCE_MAP_FAILED";

// Get Roles
export const GET_USER_ROLES_REQUESTED = "GET_USER_ROLES_REQUESTED";
export const GET_USER_ROLES_SUCCESS = "GET_USER_ROLES_SUCCESS";
export const GET_USER_ROLES_FAILED = "GET_USER_ROLES_FAILED";

// Get Document Roles
export const GET_DOCUMENT_ROLES_REQUESTED = "GET_DOCUMENT_ROLES_REQUESTED";
export const GET_DOCUMENT_ROLES_SUCCESS = "GET_DOCUMENT_ROLES_SUCCESS";
export const GET_DOCUMENT_ROLES_FAILED = "GET_DOCUMENT_ROLES_FAILED";

// PROJECT API
export const GET_PROJECTS_REQUESTED = "GET_PROJECTS_REQUESTED";
export const GET_PROJECTS_SUCCESS = "GET_PROJECTS_SUCCESS";
export const GET_PROJECTS_FAILED = "GET_PROJECTS_FAILED";
export const DELETE_PROJECT_REQUESTED = "DELETE_PROJECT_REQUESTED";
export const DELETE_PROJECT_SUCCESS = "DELETE_PROJECT_SUCCESS";
export const DELETE_PROJECT_FAILED = "DELETE_PROJECT_FAILED";
export const CREATE_PROJECT_REQUESTED = "CREATE_PROJECT_REQUESTED";
export const CREATE_PROJECT_SUCCESS = "CREATE_PROJECT_SUCCESS";
export const CREATE_PROJECT_FAILED = "CREATE_PROJECT_FAILED";
export const PUT_PROJECT_REQUESTED = "PUT_PROJECT_REQUESTED";
export const PUT_PROJECT_SUCCESS = "PUT_PROJECT_SUCCESS";
export const PUT_PROJECT_FAILED = "PUT_PROJECT_FAILED";
export const FETCH_STEP_NO_IMAGES_REQUESTED = "FETCH_STEP_NO_IMAGES_REQUESTED";
export const FETCH_STEP_NO_IMAGES_SUCCESS = "FETCH_STEP_NO_IMAGES_SUCCESS";
export const FETCH_STEP_NO_IMAGES_FAILED = "FETCH_STEP_NO_IMAGES_FAILED";

// Current User Data
export const GET_USER_DATA_REQUESTED = "GET_USER_DATA_REQUESTED";
export const GET_USER_DATA_SUCCESS = "GET_USER_DATA_SUCCESS";
export const SAVE_USER_DATA_REQUESTED = "SAVE_USER_DATA_REQUESTED";
export const SAVE_USER_DATA_SUCCESS = "SAVE_USER_DATA_SUCCESS";

// Document Related Constants
export const GET_DOCUMENTS_REQUESTED = "GET_DOCUMENTS_REQUESTED";
export const GET_DOCUMENTS_SUCCESS = "GET_DOCUMENTS_SUCCESS";
export const GET_DOCUMENTS_FAILED = "GET_DOCUMENTS_FAILED";

// Lookup get smiles
export const LOOKUP_COMPOSITION_TABLE_REQUESTED =
  "LOOKUP_COMPOSITION_TABLE_REQUESTED";
export const LOOKUP_COMPOSITION_TABLE_SUCCESS =
  "LOOKUP_COMPOSITION_TABLE_SUCCESS";
export const LOOKUP_COMPOSITION_TABLE_FAILED =
  "LOOKUP_COMPOSITION_TABLE_FAILED";

// Lookup get images
export const LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED =
  "LOOKUP_COMPOSITION_TABLE_IMAGE_REQUESTED";
export const LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS =
  "LOOKUP_COMPOSITION_TABLE_IMAGE_SUCCESS";
export const LOOKUP_COMPOSITION_TABLE_IMAGE_FAILED =
  "LOOKUP_COMPOSITION_TABLE_IMAGE_FAILED";

// Lookup get smiles
export const LOOKUP_ADD_IMAGE_REQUESTED = "LOOKUP_ADD_IMAGE_REQUESTED";
export const LOOKUP_ADD_IMAGE_SUCCESS = "LOOKUP_ADD_IMAGE_SUCCESS";
export const LOOKUP_ADD_IMAGE_FAILED = "LOOKUP_ADD_IMAGE_FAILED";

// Document Related Constants
export const SEARCH_DOCUMENTS_REQUESTED = "SEARCH_DOCUMENTS_REQUESTED";
export const SEARCH_DOCUMENTS_SUCCESS = "SEARCH_DOCUMENTS_SUCCESS";
export const SEARCH_DOCUMENTS_FAILED = "SEARCH_DOCUMENTS_FAILED";

// FETCH DOCUMENT DETAILS
export const FETCH_DOCUMENT_DETAILS_RESET = "FETCH_DOCUMENT_DETAILS_RESET";
export const FETCH_DOCUMENT_DETAILS_RESET_SUCCESS =
  "FETCH_DOCUMENT_DETAILS_RESET_SUCCESS";
export const FETCH_DOCUMENT_DETAILS_REQUESTED =
  "FETCH_DOCUMENT_DETAILS_REQUESTED";
export const FETCH_DOCUMENT_DETAILS_SUCCESS = "FETCH_DOCUMENT_DETAILS_SUCCESS";
export const FETCH_DOCUMENT_DETAILS_FAILED = "FETCH_DOCUMENT_DETAILS_FAILED";

// FETCH DOCUMENT DETAILS
export const FETCH_COLLECTION_RESET = "FETCH_COLLECTION_RESET";
export const FETCH_COLLECTION_RESET_SUCCESS = "FETCH_COLLECTION_RESET_SUCCESS";
export const FETCH_COLLECTION_REQUESTED = "FETCH_COLLECTION_REQUESTED";
export const FETCH_COLLECTION_SUCCESS = "FETCH_COLLECTION_SUCCESS";
export const FETCH_COLLECTION_FAILED = "FETCH_COLLECTION_FAILED";

export const SEARCH_COMPOUND_REQUESTED = "SEARCH_COMPOUND_REQUESTED";
export const SEARCH_COMPOUND_SUCCESS = "SEARCH_COMPOUND_SUCCESS";
export const SEARCH_COMPOUND_FAILED = "SEARCH_COMPOUND_FAILED";

// Get Security Groups from Microsoft Graph API
export const GET_GROUPS_REQUESTED = "GET_GROUPS_REQUESTED";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_FAILED = "GET_GROUPS_FAILED";

// Get List of Members from Microsoft Graph API
export const GET_MEMBER_GROUPS_REQUESTED = "GET_MEMBER_GROUPS_REQUESTED";
export const GET_MEMBER_GROUPS_SUCCESS = "GET_MEMBER_GROUPS_SUCCESS";
export const GET_MEMBER_GROUPS_FAILED = "GET_MEMBER_GROUPS_FAILED";

// Set Roles
export const SET_ROLES_REQUESTED = "SET_ROLES_REQUESTED";
export const SET_ROLES_SUCCESS = "SET_ROLES_SUCCESS";
export const SET_ROLES_FAILED = "SET_ROLES_FAILED";

// Set Roles
export const SET_DOCUMENT_ROLES_REQUESTED = "SET_DOCUMENT_ROLES_REQUESTED";
export const SET_DOCUMENT_ROLES_SUCCESS = "SET_DOCUMENT_ROLES_SUCCESS";
export const SET_DOCUMENT_ROLES_FAILED = "SET_ROLES_FAILED";

// Set Roles
export const SET_CURRENT_WIDGETS_REQUESTED = "SET_CURRENT_WIDGETS_REQUESTED";
export const SET_CURRENT_WIDGETS_SUCCESS = "SET_CURRENT_WIDGETS_SUCCESS";
export const SET_CURRENT_WIDGETS_FAILED = "SET_CURRENT_WIDGETS_FAILED";

export const SET_VIEW_REQUESTED = "SET_VIEW_REQUESTED";
export const SET_VIEW_SUCCESS = "SET_VIEW_SUCCESS";
export const SET_VIEW_FAILED = "SET_VIEW_FAILED";

// Search for users from Microsoft Graph API
export const SEARCH_USERS_REQUESTED = "SEARCH_USERS_REQUESTED";
export const SEARCH_USERS_SUCCESS = "SEARCH_USERS_SUCCESS";
export const SEARCH_USERS_FAILED = "SEARCH_USERS_FAILED";

// Search parameters
export const EXPLORE_PAGE_SEARCH_REQUESTED = "EXPLORE_PAGE_SEARCH_REQUESTED";
export const EXPLORE_PAGE_SEARCH_SUCCESS = "EXPLORE_PAGE_SEARCH_SUCCESS";
export const EXPLORE_PAGE_SEARCH_FAILED = "EXPLORE_PAGE_SEARCH_FAILED";

// Search Reset
export const EXPLORE_PAGE_RESET_SEARCH_REQUESTED =
  "EXPLORE_PAGE_RESET_SEARCH_REQUESTED";
export const EXPLORE_PAGE_RESET_SEARCH_SUCCESS =
  "EXPLORE_PAGE_RESET_SEARCH_SUCCESS";
export const EXPLORE_PAGE_RESET_SEARCH_FAILED =
  "EXPLORE_PAGE_RESET_SEARCH_FAILED";

// Search parameters
export const EXPLORE_PAGE_FILTER_REQUESTED = "EXPLORE_PAGE_FILTER_REQUESTED";
export const EXPLORE_PAGE_FILTER_SUCCESS = "EXPLORE_PAGE_FILTER_SUCCESS";
export const EXPLORE_PAGE_FILTER_FAILED = "EXPLORE_PAGE_FILTER_FAILED";

// Search parameters
export const EXPLORE_PAGE_GET_DATA_REQUESTED =
  "EXPLORE_PAGE_GET_DATA_REQUESTED";
export const EXPLORE_PAGE_GET_DATA_SUCCESS = "EXPLORE_PAGE_GET_DATA_SUCCESS";
export const EXPLORE_PAGE_GET_DATA_FAILED = "EXPLORE_PAGE_GET_DATA_FAILED";

// Chemdraw actions
export const START_CHEMDRAW_REQUESTED = "START_CHEMDRAW_REQUESTED";
export const START_CHEMDRAW_SUCCESS = "START_CHEMDRAW_SUCCESS";
export const START_CHEMDRAW_FAILED = "START_CHEMDRAW_FAILED";
