/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DATA_SEARCH_OPERATORS } from "../../../constants/Config";
import { useForm } from "antd/lib/form/Form";
import InputTag from "../../InputTag";
import { handleFormValuesChange } from "../../../util/search";

const DataForm = ({
  setTargetValue,
  onSearch,
  field,
  label,
  setCurrentType,
  title,
}) => {
  const [form] = useForm();
  const entityIdsRef = useRef();

  useEffect(() => {
    setCurrentType(DATA_SEARCH_OPERATORS[1].value);
  }, []);

  const [targetInputType, setTargetInputType] = useState("text");

  return (
    <Form
      layout="inline"
      initialValues={{
        operator: DATA_SEARCH_OPERATORS[1].value,
      }}
      form={form}
      onFinish={({ selectedField }) => {
        if (targetInputType === "tag") {
          const ids = entityIdsRef.current.changeVal();
          onSearch({ inputType: targetInputType, targetValue: ids });
        } else {
          onSearch({ inputType: targetInputType, targetValue: selectedField });
        }
      }}
      onValuesChange={(changedValues) =>
        handleFormValuesChange(changedValues, setTargetInputType)
      }
    >
      <Form.Item
        name="operator"
        rules={[
          {
            required: true,
            message: "Please select operator",
          },
        ]}
      >
        <Select
          size="small"
          onChange={setCurrentType}
          placeholder="Select Operator"
          options={DATA_SEARCH_OPERATORS}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>

      {targetInputType === "text" || targetInputType === "number" ? (
        <Form.Item
          name="selectedField"
          rules={[
            {
              required: true,
              message: `Please select ${title || field}`,
            },
          ]}
        >
          <Input
            size="small"
            type={targetInputType}
            placeholder={`Enter ${title || field} value`}
            onChange={(e) => setTargetValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch({
                  inputType: targetInputType,
                  targetValue: e.target.value
                });
              }
            }}
          />
        </Form.Item>
      ) : null}

      {targetInputType === "tag" ? (
        <div style={{ marginTop: "6px" }}>
          <InputTag sourceName={"ACT"} size="small" ref={entityIdsRef} />
        </div>
      ) : null}

      <Form.Item style={{ marginTop: "6px" }}>
        <Button
          className="gx-ml-3"
          type="primary"
          size="small"
          htmlType="submit"
          // onClick={() => {
          //   if (targetInputType === "tag") {
          //     const ids = entityIdsRef.current.changeVal();
          //     setTargetValue(ids);
          //   }

          //   onSearch({ inputType: targetInputType });
          // }}
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default DataForm;
