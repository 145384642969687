import React, { useState } from "react";
import PT from "prop-types";
import { Button, Form, Input, Select } from "antd";
import _ from "lodash";
import { DATA_SEARCH_OPERATORS } from "../../../../constants/Config";

const SearchForDocument = ({
  formRef,
  onFinish,
  collectionOptions,
  resetTable,
}) => {
  const [selectedWidget, setSelectedWidget] = useState();

  const onChangeCollection = (systemWidgetName) => {
    const selected =
      _.find(collectionOptions, { value: systemWidgetName }) || {};
    setSelectedWidget(selected?.widget);

    formRef.current.setFieldsValue({
      widgetOption: null,
      search: "",
    });

    resetTable();
  };

  const searchOptions = _.map(selectedWidget?.rows || [], (option) => ({
    label: option?.field,
    value: option?.name,
  }));

  return (
    <Form
      layout="inline"
      name="selectFile"
      ref={formRef}
      onFinish={onFinish}
      initialValues={{
        search: "",
      }}
    >
      <Form.Item
        name="collection"
        required
        rules={[
          {
            required: true,
            message: "Select Widget Option",
          },
        ]}
      >
        <Select
          size="small"
          showSearch
          placeholder="Select Collection"
          options={collectionOptions}
          onChange={onChangeCollection}
        />
      </Form.Item>
      <Form.Item name="operator">
        <Select
          size="small"
          placeholder="Select Operator"
          options={DATA_SEARCH_OPERATORS}
        />
      </Form.Item>
      <Form.Item name="widgetOption">
        <Select
          size="small"
          showSearch
          placeholder="Select Value"
          options={searchOptions}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      <Form.Item name="search">
        <Input placeholder="Enter Value" size="small" />
      </Form.Item>
      <Form.Item style={{ marginTop: "6px" }}>
        <Button type="primary" htmlType="submit" size="small">
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

SearchForDocument.propTypes = {
  onFinish: PT.func.isRequired,
  widgetParamsOptions: PT.arrayOf(PT.shape()),
};

export default SearchForDocument;
