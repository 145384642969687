import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI_SIDEBAR,
  NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
  TAB_SIZE,
  THEME_TYPE_LITE,
} from "../../constants/ThemeSetting";

import "./style.css";

const SidebarLogo = ({ sidebarCollapsed, setSidebarCollapsed }) => {
  const { width, themeType } = useSelector(({ settings }) => settings);
  let navStyle = useSelector(({ settings }) => settings.navStyle);
  if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
    navStyle = NAV_STYLE_DRAWER;
  }

  // const isSRD = window.location.href.includes("srd");
  // const isLocalHost = window.location.href.includes("localhost");
  const imageUrl = "logo-chemantics.png";

  return (
    <div className="gx-layout-sider-header sidebar-menu-header">
      {navStyle === NAV_STYLE_FIXED || navStyle === NAV_STYLE_MINI_SIDEBAR ? (
        <div className="gx-linebar">
          <i
            className={`gx-icon-btn icon-${
              !sidebarCollapsed ? "menu-unfold" : "menu-fold"
            } ${
              themeType !== THEME_TYPE_LITE
                ? "gx-text-white sidebar-menu-text"
                : ""
            }`}
            onClick={() => {
              setSidebarCollapsed(!sidebarCollapsed);
            }}
          />
        </div>
      ) : null}

      <Link
        to={`/home`}
        className="gx-site-logo"
        style={{ justifyContent: "center" }}
      >
        {navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR && width >= TAB_SIZE ? (
          <img alt="lo" src={`/assets/images/logo.png`} />
        ) : themeType === THEME_TYPE_LITE ? (
          <img alt="logo1" src={`/assets/images/logo-white.png`} />
        ) : (
          <img alt="logo2" src={`/assets/images/${imageUrl}`} width={100} />
        )}
      </Link>
    </div>
  );
};

export default SidebarLogo;
