import React, {
  forwardRef,
  useImperativeHandle,
  useEffect,
  useState,
} from "react";

import license from "./ChemDraw-JS-License.xml";

import "./style.css";
import _ from "lodash";

let globalChemdrawjsInstance;

const ChemDrawEditor = forwardRef((props, ref) => {
  const [chemdrawjs, setChemdrawjs] = useState(globalChemdrawjsInstance);

  const { id = "chemdrawjs-container", className, viewMode } = props;

  useEffect(() => {
    if (global.perkinelmer) {
      global.perkinelmer.ChemdrawWebManager.attach({
        id,
        callback: (instance) => {
          const containerEls = document.getElementsByClassName("cdd-container");
          if (containerEls.length > 1) {
            const len = containerEls.length;
            for (let i = 1; i < len; i++) {
              containerEls[i].remove();
            }
          }
          globalChemdrawjsInstance = instance;
          setChemdrawjs(instance);
          return instance;
        },
        licenseUrl: license,
        viewonly: viewMode,
      });
    } else {
      // TODO: Instead of refreshing. Find better way to deal with this problem.
      window.location.reload();
    }

    return () => {
      const container = document.getElementById(id);
      if (container) {
        container.innerHTML = "";
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [window.chemdrawjs, global.perkinelmer]);

  useImperativeHandle(ref, () => ({
    getDrawing() {
      if (chemdrawjs) {
        chemdrawjs.getSMILES(function (smiles, error) {
          if (!error) {
            props.setDrawing(smiles);
          }
        });
      }
    },
    getMol() {
      if (chemdrawjs) {
        chemdrawjs.getMOL(function (mol, error) {
          if (!error) {
            props.setDrawing(mol);
          }
        });
      }
    },
    getSmiles(callbackFn) {
      if (chemdrawjs) {
        return chemdrawjs.getSMILES(function (smiles, error) {
          if (!error) {
            callbackFn(smiles);
          }
        });
      }
    },
    getRXN() {
      if (chemdrawjs) {
        chemdrawjs.getRXN(function (rxn, error) {
          if (!error) {
            props.setRxn(rxn);
          }
        });
      }
    },
    getCDXML() {
      if (chemdrawjs) {
        const cdxml = chemdrawjs.getCDXML();
        return Promise.resolve(cdxml);
      }
    },
    checkInstance() {
      return chemdrawjs;
    },
    writeToCanvas(target) {
      if (!_.isNull(target)) {
        chemdrawjs?.loadSMILES(target, function (cdxml, error) {});
      }
    },
    loadMOL(target) {
      if (!_.isNull(target)) {
        chemdrawjs?.loadMOL(target);
      }
    },
    loadCDXML(target) {
      if (target) {
        try {
          chemdrawjs?.loadCDXML(target);
        } catch (error) {}
      }
    },
    // TODO: https://github.com/Chemantics-GmbH/srd_ui/issues/212
    // getProperties(callbackFn) {
    //   if (chemdrawjs) {
    //     chemdrawjs?.getProperties((props, error) => {
    //       console.log("props", props, error);

    //       if (!error) {
    //         callbackFn(props);
    //       }
    //     });
    //   }
    // },
    isBlankStructure() {
      if (chemdrawjs) {
        return chemdrawjs.isBlankStructure();
      }
      return true;
    },
    getImageUrl() {
      if (chemdrawjs) {
        return chemdrawjs?.getImgUrl();
      }
    },

    getSvg() {
      if (chemdrawjs) {
        return chemdrawjs?.getSVG();
      }
    },
  }));

  // useEffect(() => {
  //   if (chemdrawjs) {
  //     chemdrawjs.setContentChangedHandler(function (event) {
  //       props.canvasChanged();
  //     });
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [chemdrawjs]);

  if (chemdrawjs) {
    chemdrawjs.setContentChangedHandler(function (event) {
      props.canvasChanged();
    });
  }

  return <div id={id} className={className} />;
});

export default ChemDrawEditor;
