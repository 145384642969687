import React from "react";

import "./style.css";

const Drawer = ({ title, visible, children }) => {
  return (
    <>
      {visible ? (
        <div className="custom-drawer-wrapper">
          <nav id="drawer">
            <div className="drawer-header">
              <h3>{title}</h3>
            </div>
            <div className="ant-drawer-body">{children}</div>
          </nav>
        </div>
      ) : null}
    </>
  );
};

Drawer.defaultProps = {};

Drawer.propTypes = {};

export default Drawer;
