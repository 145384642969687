/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Form, Input, Switch } from "antd";
import PT from "prop-types";
import _ from "lodash";
import { DOCUMENT_COLLECTION_OPTIONS } from "../../../../../constants/Config";

const LinkForm = ({ onChangeValue, form, roles, settingsWidgetName }) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.name)
        ? currentRole[item?.name]
        : false;
      role[item?.name] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  return (
    <>
      <Col>
        <Form.Item
          label="Display Field Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Display Value"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.displayValue}
            onChange={(e) => onChangeValue("displayValue", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="url"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.url}
            onChange={(e) => onChangeValue("url", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="isCopiable"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.isCopiable}
            onChange={(e) => onChangeValue("isCopiable", e)}
          />
        </Form.Item>
      </Col>
      <Col>
        {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
          <Form.Item
            label="is Header Field"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isInlineField") || false}
              onChange={(e) => onChangeValue("isInlineField", e)}
            />
          </Form.Item>
        ) : null}
      </Col>
      <Col>
        <Form.Item
          label="hasTooltip"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Switch
            checked={form?.hasTooltip}
            onChange={(e) => onChangeValue("hasTooltip", e)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Tooltip"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.info}
            onChange={(e) => onChangeValue("info", e.target.value)}
          />
        </Form.Item>
      </Col>

      <Col className="gx-d-flex">
        {roles.map((role) => (
          <Form.Item
            className="gx-mr-1"
            id={role.id}
            label={role.name}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form?.isEditable, role?.name) || false}
              onChange={(e) => onChangeRole(role.name, e)}
            />
          </Form.Item>
        ))}
      </Col>
    </>
  );
};

LinkForm.defaultProps = {
  form: {},
};

LinkForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default LinkForm;
