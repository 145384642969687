import PT from "prop-types";
import React from "react";

import _ from "lodash";
import { useSelector } from "react-redux";
import { PAGE_TYPES } from "../../../../../constants/Config";

const NamesAndSynonymsContent = ({ option, pageType, targetField }) => {
  let pageId =
    pageType === PAGE_TYPES.EXPERIMENT
      ? _.get(option, "instanceId") || _.get(option, "id")
      : _.get(option, "compoundId") || _.get(option, "id");

  if (pageType === PAGE_TYPES.SAMPLE) {
    pageId =
      _.get(option, "sampleId") ||
      _.get(option, "instanceId") ||
      _.get(option, "compoundId") ||
      _.get(option, "id");
  }

  const { [pageId]: data = {} } = useSelector(({ lookup }) => lookup);

  const fieldValue = _.get(data?.metadata || {}, targetField);

  return <div>{fieldValue}</div>;
};

NamesAndSynonymsContent.defaultProps = {
  option: {},
};

NamesAndSynonymsContent.propTypes = {
  option: PT.shape(),
  targetField: PT.string,
};

export default NamesAndSynonymsContent;
