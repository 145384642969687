/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  createRef,
  useEffect,
  useReducer,
  useRef,
  useState,
} from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import { Form, Input, Modal, Select } from "antd";
import { saveCompoundInstance } from "../../../appRedux/services/CompoundInstance";
import CircularProgress from "components/CircularProgress";
import {
  getImageFromCompoundId,
  imagePreviewApi,
} from "../../../appRedux/services/Search";
import CompoundNotFound from "../../../assets/vendors/notfound.png";
import {
  additionalComponentTypeOptions,
  componentRoleOptions,
} from "../../../constants/Config";
import "./style.css";
import { NotificationManager } from "react-notifications";

import _ from "lodash";
import { checkActionAllowed } from "../../../util/auth";
import AdditionalComponentItem from "./AdditionalComponentItem";
import { getPreviewImageApi } from "../../../appRedux/services/DetailPage";
import { getOriginal } from "../../../util/Widget";
import AdditionalComponentHeader from "./AdditionalComponentHeader";
import ImageCarousel from "../../../components/Registration/ImageCarousel";
import {
  arrayMove,
  SortableContainer,
  SortableElement,
  SortableHandle,
} from "react-sortable-hoc";
import { useMsal } from "@azure/msal-react";

const AdditionalComponent = ({
  id,
  options,
  title,
  componentUpdated,
  pageType,
  sourceName,
  hasDeleteButton,
  onDelete,
  isDefaultMode,
}) => {
  const [open, setOpen] = useState(true);
  let form = {};
  let isFormValid = true;
  const formRef = useRef();
  const [loading, setLoading] = useState(false);
  const [componentImages, setComponentsImages] = useState({});
  const [mainComponentImage, setMainComponentImage] = useState();
  const [addNew, setAddNew] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [sortOptions, setSortOptions] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);

  const [elRefs, setElRefs] = useState([]);

  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  useEffect(() => {
    setElRefs((elRefs) => options.map((_, i) => elRefs[i] || createRef()));
    setSortOptions(options);
  }, [options]);

  useEffect(() => {
    if (id) {
      const parentId = getOriginal(id);
      getPreviewImageApi(
        parentId,
        sourceName,
        setLoading,
        setMainComponentImage
      );
    }
  }, [id]);

  useEffect(() => {
    if (options.length > 0) {
      options.forEach((option) => {
        if (option.compoundId && !option.imagePreview) {
          imagePreviewApi(
            getOriginal(option.compoundId),
            () => {},
            true,
            sourceName
          )
            .then((res) => {
              if (res.data) {
                option.imagePreview = res.data;
                setComponentsImages(
                  _.extend(componentImages, {
                    [option.compoundId]: res.data,
                  })
                );
              }
              forceUpdate();
            })
            .catch(() => {
              option.imagePreview = "";
            });
        }
      });
    }
  }, [options]);

  const checkUniqueness = () => {
    const currentOptions = options.filter(
      (item) => item?.compoundId === form?.compoundId
    );

    if (currentOptions.length > 0) {
      return false;
    }
    return true;
  };

  const checkOriginalCompound = () => {
    if (getOriginal(id) === form?.compoundId) {
      return false;
    }
    return true;
  };

  const checkRoleExistence = () => {
    const compound = form?.role || "";

    if (!compound.length) {
      return false;
    }

    return true;
  };

  const checkCompoundExistence = () => {
    const compound =
      form?.compoundId || formRef.current?.getFieldValue("compoundId") || "";

    if (!compound.length) {
      return false;
    }

    return true;
  };

  const checkCompoundValidity = () => {
    const compound = form?.compoundId || "";

    if (compound.includes(".")) {
      return false;
    }
    return true;
  };

  const checkPercentage = () => {
    const percentage = Number(form?.percentage);

    if (!_.isNumber(form?.percentage)) {
      return true;
    }

    if (percentage > 0 && percentage < 100) {
      return true;
    }

    return false;
  };

  const checkComponentsPercentageSum = () => {
    let sum = 0;
    elRefs.forEach((item) => {
      const currentValue = item.current?.getPercentage();
      if (currentValue) {
        sum += Number(currentValue);
      }
    });
    if (Number(sum) + Number(form?.percentage || 0) <= 100) {
      return { valid: true };
    }

    if (100 - Number(sum) - Number(form?.percentage || 0) < 0) {
      return { valid: false, value: 100 - Number(sum) };
    }

    return { valid: true };
  };

  const onChangeCompoundId = async (value, index) => {
    try {
      getImageFromCompoundId(value, sourceName).then((image) => {
        if (image?.data) {
          setCurrentImage(image?.data);
          formRef.current.setFields([{ name: "compoundId", value }]);
        }

        document.getElementById(`input-compoundId-${index}`).focus();
      });
    } catch (error) {
      setCurrentImage(null);
      formRef.current.setFields([{ name: "compoundId", value }]);
      document.getElementById(`input-compoundId-${index}`).focus();
    }
  };

  const deleteAdditionalComponent = (index) => {
    setAddNew(false);
    const deletedOptions = options.filter((item, i) => i !== index);
    const newOptions = deletedOptions.map((item) =>
      _.pick(item, "compoundId", "type", "unit", "value")
    );
    setLoading(true);
    saveCompoundInstance(id, sourceName, newOptions, pageType, false, userName)
      .then(() => {
        componentUpdated();
        setLoading(false);
        forceUpdate();
      })
      .catch(() => {
        setLoading(false);
        forceUpdate();
      });
  };

  const checkFormValid = () => {
    let currentFormValid = true;
    if (!checkPercentage()) {
      formRef.current.setFields([
        {
          name: "percentage",
          errors: [
            form?.percentage
              ? "Percentage must be less than 100%."
              : "Percentage must be greater than 0%.",
          ],
        },
      ]);
      currentFormValid = false;
    }

    const rem = checkComponentsPercentageSum().value || 0;
    if (!checkComponentsPercentageSum().valid) {
      formRef.current?.setFields([
        {
          name: "percentage",
          errors: [`Only ${rem} percentage points can be added.`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkUniqueness()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound is already added.`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkOriginalCompound()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be original.`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkCompoundExistence()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be a empty`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkRoleExistence()) {
      formRef.current?.setFields([
        {
          name: "role",
          errors: [`Role cannot be a empty`],
        },
      ]);
      currentFormValid = false;
    }

    if (!checkCompoundValidity()) {
      formRef.current?.setFields([
        {
          name: "compoundId",
          errors: [`Compound cannot be a quality`],
        },
      ]);
      currentFormValid = false;
    }

    return currentFormValid;
  };

  const onChangeForm = (item, value) => {
    form = {
      ...form,
      [item]: value,
    };
    isFormValid = checkFormValid();
  };

  const onEdit = (id, compoundId, role, percentage) => {
    const currentOption = {
      compoundId,
      type: role,
      value: percentage,
      unit: additionalComponentTypeOptions[0].value,
    };

    const filteredOptions = options.map((item) => {
      if (item?.compoundId === compoundId) {
        return currentOption;
      } else {
        return item;
      }
    });
    const savedOptions = filteredOptions.map((item) =>
      _.pick(item, "compoundId", "type", "unit", "value")
    );

    setLoading(true);
    if (pageType === "quality") {
      saveCompoundInstance(
        id,
        sourceName,
        savedOptions,
        pageType,
        false,
        userName
      )
        .then(() => {
          componentUpdated();
          setLoading(false);
          forceUpdate();
        })
        .catch(() => {
          setLoading(false);
          forceUpdate();
        });
    }
  };

  const save = () => {
    let hasError = false;
    let filteredOptions = options.map((option, index) => {
      return elRefs[index].current?.saveItem();
    });

    if (addNew) {
      if (addNew && checkFormValid()) {
        hasError = true;
      }
      const newForm = {
        compoundId: formRef.current?.getFieldValue("compoundId"),
        type: formRef.current?.getFieldValue("role"),
        value: formRef.current?.getFieldValue("percentage"),
        unit:
          formRef.current.getFieldValue("unit") ||
          additionalComponentTypeOptions[0].value,
      };

      filteredOptions.push(newForm);
    }

    if (checkFormValid()) {
      const savedOptions = filteredOptions.map((item) =>
        _.pick(item, "compoundId", "type", "unit", "value")
      );
      setLoading(true);
      if (pageType === "quality" || pageType === "experiment") {
        saveCompoundInstance(
          id,
          sourceName,
          savedOptions,
          pageType,
          false,
          userName
        )
          .then(() => {
            componentUpdated();
            setLoading(false);
            forceUpdate();
          })
          .catch((e) => {
            NotificationManager.error(e.response?.data?.message);
            setLoading(false);
            forceUpdate();
          });
      }
      setEditMode(false);
      setAddNew(false);
    }
  };

  const saveSort = () => {
    const savedOptions = sortOptions.map((item) =>
      _.pick(item, "compoundId", "type", "unit", "value")
    );
    setLoading(true);
    if (pageType === "quality" || pageType === "experiment") {
      saveCompoundInstance(
        id,
        sourceName,
        savedOptions,
        pageType,
        false,
        userName
      )
        .then(() => {
          componentUpdated();
          setLoading(false);
          forceUpdate();
        })
        .catch((e) => {
          NotificationManager.error(e.response?.data?.message);
          setLoading(false);
          forceUpdate();
        });
    }
    setEditMode(false);
    setAddNew(false);
    setShowModal(false);
  };

  const DragHandle = SortableHandle(() => (
    <span className="gx-pointer gx-mr-2">
      <i className="icon icon-expand" style={{ fontSize: 12 }} />
    </span>
  ));

  const SortableItem = SortableElement(({ option, index }) => (
    <div className="gx-ml-3" key={index}>
      <DragHandle />
      <span>
        {option?.compoundId} - {option?.type}
      </span>
    </div>
  ));

  const SortableList = SortableContainer(() => {
    return (
      <>
        {sortOptions.length > 0 ? (
          <div>
            {sortOptions.map((option, index) => (
              <div key={index} className="process-sort">
                <SortableItem
                  key={`step-${index}`}
                  index={index}
                  option={option}
                />
              </div>
            ))}
          </div>
        ) : (
          <span>No processes to sort.</span>
        )}
      </>
    );
  });

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newOptions = arrayMove(sortOptions, oldIndex, newIndex);
      setSortOptions(newOptions);
    }
  };

  const renderSortOptionsModal = () => {
    return <SortableList helperClass="sortableHelper" onSortEnd={onSortEnd} />;
  };

  return (
    <>
      <div
        className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
          open ? "isOpen" : ""
        }`}
        key={uuidv4()}
      >
        <AdditionalComponentHeader
          title={title}
          open={open}
          setOpen={setOpen}
          loading={loading}
          addNew={addNew || editMode}
          setAddNew={(val) => {
            setCurrentImage(null);
            setAddNew(val);
          }}
          hasDeleteButton={hasDeleteButton}
          onDelete={onDelete}
          isDefaultMode={isDefaultMode}
          setEditMode={setEditMode}
          onSave={save}
          onSort={() => setShowModal(true)}
        />

        {open && (
          <div key={uuidv4()} className="ant-card-body widget-body">
            {loading ? (
              <CircularProgress className="gx-loader-400 loader" />
            ) : (
              <>
                {(options || []).length > 0 ? (
                  <>
                    {options.map((option, index) => (
                      <>
                        <AdditionalComponentItem
                          id={id}
                          option={option}
                          index={index}
                          deleteAdditionalComponent={deleteAdditionalComponent}
                          onEdit={onEdit}
                          sourceName={sourceName}
                          editMode={editMode}
                          setEditMode={setEditMode}
                          ref={elRefs[index]}
                          mainComponent={option?.type === "main_component"}
                        />
                      </>
                    ))}
                  </>
                ) : null}
              </>
            )}
            {checkActionAllowed() && (
              <>
                {addNew ? (
                  <div className="card-item additional-component-card-item">
                    <div className="card-item-field" style={{ width: "160px" }}>
                      {currentImage ? (
                        <ImageCarousel
                          structureImage={[currentImage]}
                          setVisible={() => {}}
                          width={100}
                        />
                      ) : (
                        <img
                          src={CompoundNotFound}
                          alt="compound-not-found"
                          width={100}
                          height={100}
                          className="compound-not-found gx-mb-4"
                        />
                      )}
                    </div>
                    <div className="additional-component-roles-wrapper">
                      <Form ref={formRef}>
                        <div className="additional-component-roles">
                          <div className="card-item-value">
                            <span>Compound ID: </span>
                          </div>
                          <Form.Item
                            required
                            name="compoundId"
                            rules={[
                              {
                                required: true,
                                message: "Please enter compound id",
                              },
                            ]}
                            style={{ marginRight: "0" }}
                          >
                            <Input
                              type="text"
                              name="compoundId"
                              className="addtional-component-percentage-input"
                              value={form?.compoundId}
                              onChange={(e) => {
                                e.preventDefault();
                              }}
                              onPressEnter={(e) => {
                                onChangeCompoundId(e.target.value);
                                e.preventDefault();
                                onChangeForm("compoundId", e.target.value);
                              }}
                            />
                          </Form.Item>
                        </div>
                        <div className="additional-component-roles">
                          <div className="card-item-value">Role: </div>
                          <Form.Item
                            required
                            name="role"
                            rules={[
                              {
                                required: true,
                                message: "Please select role",
                              },
                            ]}
                            className="additional-component-select"
                          >
                            <Select
                              name="role"
                              size="small"
                              options={componentRoleOptions}
                              className="custom-addtional-role"
                              onChange={(val) => onChangeForm("role", val)}
                            />
                          </Form.Item>
                        </div>

                        <div className="additional-component-roles">
                          <div className="card-item-value">
                            Percent Composition:
                          </div>
                          <Form.Item
                            required
                            name="percentage"
                            rules={[
                              {
                                required: true,
                                message: "Please enter percentage",
                              },
                            ]}
                            style={{ marginRight: "0" }}
                          >
                            <Input
                              className="addtional-component-percentage-input"
                              type="number"
                              name="percentage"
                              onChange={(e) => {
                                e.preventDefault();
                                onChangeForm("percentage", e.target.value);
                              }}
                              step={0.1}
                            />
                          </Form.Item>
                        </div>
                      </Form>
                    </div>
                  </div>
                ) : null}
              </>
            )}
          </div>
        )}
      </div>
      <Modal
        title="Sort Composition"
        visible={showModal}
        okText="Save Order"
        onCancel={() => setShowModal(false)}
        onOk={() => saveSort()}
      >
        {renderSortOptionsModal()}
      </Modal>
    </>
  );
};

AdditionalComponent.defaultProps = {
  title: "",
  isEditable: false,
  addMode: false,
  open: true,
  loading: false,
  sourceName: "",
  pageType: "",
};

AdditionalComponent.propTypes = {
  title: PT.string,
  isEditable: PT.bool,
  addMode: PT.bool,
  onSave: PT.func.isRequired,
  setAddMode: PT.func.isRequired,
  open: PT.bool,
  setOpen: PT.func.isRequired,
  loading: PT.bool,
  sourceName: PT.string,
  pageType: PT.string,
};

export default AdditionalComponent;
