/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Form, Input, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { DATA_SEARCH_OPERATORS } from "../../../constants/Config";
import { handleFormValuesChange } from "../../../util/search";
import { useForm } from "antd/lib/form/Form";
import InputTag from "../../InputTag";

const SearchByAllWidgetsForm = ({
  targetValue,
  setTargetValue,
  onSearch,
  setCurrentType,
}) => {
  const [form] = useForm();
  const entityIdsRef = useRef();

  const [targetInputType, setTargetInputType] = useState("text");

  useEffect(() => {
    setCurrentType(DATA_SEARCH_OPERATORS[1].value, true);
  }, []);

  return (
    <Form
      layout="inline"
      className="gx-d-flex"
      onFinish={({ selectedField }) => {
        if (targetInputType === "tag") {
          const ids = entityIdsRef.current.changeVal();
          onSearch({ inputType: targetInputType, targetValue: ids });
        } else {
          onSearch({ inputType: targetInputType, targetValue: selectedField });
        }
      }}
      initialValues={{
        operator: DATA_SEARCH_OPERATORS[1].value,
        selectedField: targetValue,
      }}
      form={form}
      onValuesChange={(changedValues) =>
        handleFormValuesChange(changedValues, setTargetInputType)
      }
    >
      <Form.Item
        name="operator"
        rules={[
          {
            required: true,
            message: "Please select operator",
          },
        ]}
      >
        <Select
          size="small"
          onChange={(t) => setCurrentType(t, false)}
          placeholder="Select Operator"
          options={DATA_SEARCH_OPERATORS}
          dropdownMatchSelectWidth={false}
        />
      </Form.Item>
      {targetInputType === "text" || targetInputType === "number" ? (
        <Form.Item
          name="selectedField"
          required
          rules={[
            {
              required: true,
              message: "Please enter value",
            },
          ]}
        >
          <Input
            size="small"
            type={targetInputType}
            placeholder={`Enter value`}
            defaultValue={targetValue}
            value={targetValue}
            onChange={(e) => setTargetValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                onSearch({
                  targetValue: e.target.value,
                });
              }
            }}
          />
        </Form.Item>
      ) : null}
      {targetInputType === "tag" ? (
        <div style={{ marginTop: "6px" }}>
          <InputTag sourceName={"ACT"} size="small" ref={entityIdsRef} />
        </div>
      ) : null}
      <Form.Item style={{ marginTop: "6px" }}>
        <Button
          className="gx-ml-3"
          type="primary"
          size="small"
          htmlType="submit"
        >
          Search
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SearchByAllWidgetsForm;
