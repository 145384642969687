import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_GROUPS_REQUESTED,
  GET_GROUPS_SUCCESS,
  GET_MEMBER_GROUPS_REQUESTED,
  GET_MEMBER_GROUPS_SUCCESS,
  SEARCH_USERS_REQUESTED,
  SEARCH_USERS_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "axios";
import { config } from "../../constants/Config";
import _ from "lodash";

/**
 * Get Groups
 * @param {String} accessToken
 */
const getGroupsRequest = async ({ accessToken }) => {
  let prefix = [];

  try {
    prefix = (config.REACT_APP_GROUPS_PREFIX || "").split(",");
  } catch (error) {}

  let params = "";
  _.forEach(prefix || [], (group, index) => {
    params += `${index ? " or " : ""}startswith(displayName, '${group}')`;
  });

  return axios.get(`${config.GRAPH_API}/groups?$filter=${params}`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  });
};

/**
 * Search users
 * @param {String} searchTerm
 * @param {String} accessToken
 */
const searchUsersRequest = async ({ searchTerm, accessToken }) => {
  return axios.get(
    `${config.GRAPH_API}/users?$search="displayName:${searchTerm}"&$orderby=displayName`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        ConsistencyLevel: "eventual",
      },
    }
  );
};

const getMemberGroupsRequest = async ({ accessToken }) => {
  return axios.get(`${config.GRAPH_API}/me/memberOf/microsoft.graph.group`, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      ConsistencyLevel: "eventual",
    },
  });
};

function* fetchGroups(action) {
  try {
    const result = yield call(getGroupsRequest, action.payload);
    if (result.data) {
      yield put({
        type: GET_GROUPS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {}
}

function* fetchUsers(action) {
  try {
    const result = yield call(searchUsersRequest, action.payload);
    if (result.data) {
      yield put({
        type: SEARCH_USERS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {}
}

function* fetchMemberGroups(action) {
  try {
    const result = yield call(getMemberGroupsRequest, action.payload);

    if (result.data) {
      yield put({
        type: GET_MEMBER_GROUPS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {}
}

export function* getGroups() {
  yield takeLatest(GET_GROUPS_REQUESTED, fetchGroups);
}

export function* searchForUsers() {
  yield takeLatest(SEARCH_USERS_REQUESTED, fetchUsers);
}

export function* getMemberGroups() {
  yield takeLatest(GET_MEMBER_GROUPS_REQUESTED, fetchMemberGroups);
}

export default function* rootSaga() {
  yield all([fork(getGroups), fork(searchForUsers), fork(getMemberGroups)]);
}
