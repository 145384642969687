import { InfoCircleTwoTone } from "@ant-design/icons";
import { Form, Select, Tooltip } from "antd";
import React from "react";

const RowSelectInput = ({
  field,
  info = "",
  addMode,
  options,
  name,
  index,
  value,
  onChange,
}) => {
  const onChangeForm = (e) => {
    onChange(e.target.value);
  };

  const setInputValue = (val) => {
    onChange(val);
  };

  let selectOptions = [];
  try {
    selectOptions = JSON.parse(options);
  } catch (error) {}

  return (
    <>
      {addMode ? (
        <div className={"card-item"} key={`editable-input-${index}`}>
          <div className="card-item-field" style={{ marginRight: "10px" }}>
            {" "}
            <span>{field}</span>
          </div>
          <div className="card-item-value">
            <Form
              onChange={onChangeForm}
              style={{ width: "100px", marginRight: "15px" }}
            >
              <Form.Item>
                <Select
                  id={`editable-input-${index}`}
                  options={selectOptions}
                  name={name}
                  defaultValue={value}
                  width={150}
                  size="small"
                  onChange={(e) => {
                    setInputValue(e);
                  }}
                />
              </Form.Item>
            </Form>
          </div>
        </div>
      ) : (
        <div className={"card-item"} key={`editable-input-${index}`}>
          <div className="card-item-field" style={{ marginRight: "10px" }}>
            <span>{field}</span>
            {info.length ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
          </div>
          <div className="card-item-value">
            <div className="gx-d-flex">
              <span>{value}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RowSelectInput;
