import {
  CREATE_PROJECT_REQUESTED,
  DELETE_PROJECT_REQUESTED,
  FETCH_STEP_NO_IMAGES_REQUESTED,
  GET_PROJECTS_REQUESTED,
  PUT_PROJECT_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Get All Projects
 * @param {String} sourceName source name
 */
export const getProjects = (sourceName) => {
  return {
    type: GET_PROJECTS_REQUESTED,
    payload: {
      sourceName,
    },
  };
};

/**
 * Create Project
 * @param {String} sourceName source name
 * @param {String} projectId project id
 */
export const createProject = (sourceName, projectId) => {
  return {
    type: CREATE_PROJECT_REQUESTED,
    payload: {
      sourceName,
      projectId,
    },
  };
};

/**
 * Send PUT request to projects API
 * @param {String} sourceName source name
 * @param {String} projectId project id
 * @param {Object} data data to insert
 * @returns
 */
export const putProject = (sourceName, projectId, data, userName) => {
  return {
    type: PUT_PROJECT_REQUESTED,
    payload: {
      sourceName,
      projectId,
      data,
      userName,
    },
  };
};

/**
 * Delete Project
 * @param {String} sourceName source name
 * @param {String} projectId project Id
 */
export const deleteProject = (sourceName, projectId) => {
  return {
    type: DELETE_PROJECT_REQUESTED,
    payload: {
      sourceName,
      projectId,
    },
  };
};

/**
 * Fetch images of process steps from rmo ids.
 * @param {*} sourceName
 * @param {*} processId
 */
export const fetchStepNoImages = (sourceName, processId) => {
  return {
    type: FETCH_STEP_NO_IMAGES_REQUESTED,
    payload: {
      sourceName,
      processId,
    },
  };
};
