import { SOURCE_NAME_MAP } from "../constants/Config";

export const getSourceName = (name = "") => {
  let sourceName = "";
  SOURCE_NAME_MAP.forEach((source) => {
    if (name.startsWith(source.prefix)) {
      sourceName = source.value;
    }
  });

  if (!sourceName.length) {
    sourceName = "UI";
  }

  return sourceName;
};

export const getSourceNameWithMap = (name = "", sourceNameMap) => {
  let sourceName = null;
  if (!name.length) {
    return null;
  }
  const namePrefix = name.split("-")[0];
  sourceNameMap.forEach((source) => {
    const prefix = source?.compoundFormat;
    if (prefix.startsWith(namePrefix)) {
      sourceName = source?.sourceName;
    }
  });

  return sourceName;
};

export const getProcessFormatWithMap = (name = "", sourceNameMap) => {
  let sourceName = null;
  if (!name.length) {
    return null;
  }
  const namePrefix = name.split("-")[0];
  sourceNameMap.forEach((source) => {
    if (source?.processFormat) {
      const prefix = source?.processFormat;
      if (prefix.startsWith(namePrefix)) {
        sourceName = source?.sourceName;
      }
    }
  });

  return sourceName;
};

export const getProjectFormatWithMap = (name = "", sourceNameMap) => {
  let sourceName = null;
  if (!name.length) {
    return null;
  }
  const namePrefix = name.split("-")[0];
  sourceNameMap.forEach((source) => {
    if (source?.projectFormat && !sourceName) {
      const prefix = source?.projectFormat;
      if (prefix.startsWith(namePrefix)) {
        sourceName = source?.sourceName;
      }
    }
  });

  return sourceName;
};

export const getSourceNamePrefix = (name = "", sourceNameMap = []) => {
  let sourceName = null;
  sourceNameMap.forEach((source) => {
    if (
      (source?.sourceName || "").toLowerCase() === (name || "").toLowerCase()
    ) {
      const prefix = source?.compoundFormat.split("-")[0];
      sourceName = prefix;
    }
  });

  return sourceName;
};

export const mockRequest = ({ file, onSuccess }) => {
  setTimeout(() => {
    onSuccess("ok");
  }, 0);
};

/**
 * Execute API requests sequentially. It used for creating multiple
 * compound instances. It requres sequential operation.
 */
export function executeSequentially(promiseLikeArray) {
  var result = Promise.resolve();
  promiseLikeArray.forEach(function (promiseLike) {
    result = result.finally(promiseLike);
  });
  return result;
}
