/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef, useState } from "react";
import PT from "prop-types";
import _ from "lodash";
import CircularProgress from "components/CircularProgress";
import { v4 as uuidv4 } from "uuid";
import {
  Button,
  Drawer,
  Form,
  Input,
  Modal,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
} from "antd";
import { TABLE_MAX_HEIGHT } from "../../../constants/Config";
import Column from "antd/lib/table/Column";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  FilterTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import { getSources } from "../../../appRedux/services/Widget";
import { getFieldValueFromDisplayName } from "../../../util/Widget";
import SearchRowSettings from "./SearchRowSettings";
import { ReactComponent as ExpandIcon } from "../../../assets/vendors/expand.svg";
import EditableCell from "../../../components/EditableCell";

import "./style.css";

const SearchWidgets = ({
  title,
  widgetName,
  loading,
  availableWidgets,
  onSave,
  disableEdit,
  roles,
  onSaveSystemWidget,
  availableWidgetParams,
  allWidgets,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editIndex, setEditIndex] = useState();
  const [sources, setSources] = useState([]);
  const [currentWidget, setCurrentWidget] = useState();
  const [showCurrentDrawer, setShowCurrentDrawer] = useState(false);
  const [editingKey, setEditingKey] = useState("");
  const [form] = Form.useForm();

  const isEditing = (record) => record.key === editingKey;

  useEffect(() => {
    if (!(sources || []).length) getSources(setSources);
  }, []);

  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const formRef = useRef();

  const availableWidgetsWithKeys = availableWidgets.map((widget, index) => ({
    ...widget,
    key: index,
  }));

  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    setDataSource(_.sortBy(availableWidgetsWithKeys, "field"));
  }, [availableWidgets]);

  const onChangeValue = (field, value) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const onRemove = (field, index) => {
    const newRoles = dataSource.filter((item, i) => i !== index);
    onSave(widgetName, newRoles);
  };

  const onEdit = (currentItem, index) => {
    setEditIndex(index);
    setShowModal(true);
    forceUpdate();
    setTimeout(() => {
      if (formRef.current) {
        const { title, field, value, belongsTo, description, width } =
          currentItem;

        formRef.current.setFieldsValue({
          title,
          field,
          value,
          belongsTo,
          description,
          width,
        });
      }
    }, 20);
  };

  const save = async (key) => {
    const row = await form.validateFields();
    const newData = [...dataSource];
    const index = newData.findIndex((item) => key === item.key);

    if (index > -1) {
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...row,
      });
      setDataSource(newData);
      setEditingKey("");
    } else {
      newData.push(row);
      setDataSource(newData);
      setEditingKey("");
    }

    onSave(widgetName, newData);
  };

  const renderActions = (p, cWidget, index) => {
    const { field } = cWidget;

    const editable = isEditing(cWidget);

    return (
      <>
        {editable ? (
          <>
            <Popover content="Save widget">
              <SaveTwoTone
                twoToneColor="#52c41a"
                className="gx-pointer"
                onClick={() => save(cWidget.key)}
              />
            </Popover>
            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => setEditingKey("")}
            >
              <CloseCircleTwoTone className="gx-pl-2" twoToneColor="#a94442" />
            </Popconfirm>
          </>
        ) : (
          <>
            <Popover content="Edit widget">
              <EditTwoTone
                twoToneColor="#038FDE"
                onClick={() => {
                  setEditingKey(cWidget.key);
                  setEditIndex(index);

                  form.setFieldsValue({
                    name: "",
                    age: "",
                    address: "",
                    ...cWidget,
                  });
                }}
                className="gx-mr-3"
              />
            </Popover>

            <Popover content="Show detail">
              <ExpandIcon
                className="expand-icon gx-mr-3"
                onClick={() => {
                  setCurrentWidget(cWidget);
                  setShowCurrentDrawer(true);
                }}
              />
            </Popover>
            {!disableEdit && (
              <Popconfirm
                title="Are you sure to delete this widget?"
                onConfirm={() => onRemove(field, index)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
              </Popconfirm>
            )}
          </>
        )}
      </>
    );
  };

  const renderBelongsTo = (p, { belongsTo }) => {
    return <div>{(belongsTo || []).join(",")}</div>;
  };

  const renderWidth = (p, { width }) => {
    return <div>{width || 1}</div>;
  };

  const onConfirmModal = () => {
    const field = formRef.current.getFieldValue("field");
    const value = formRef.current.getFieldValue("value");
    const belongsTo = formRef.current.getFieldValue("belongsTo");
    const width = formRef.current.getFieldValue("width");
    const description = formRef.current.getFieldValue("description");
    const roles = formRef.current.getFieldValue("roles");

    const fieldIndex = _.findIndex(dataSource, { field });
    const valueIndex = _.findIndex(dataSource, { value });

    if (!field.length) {
      formRef.current.setFields([
        {
          name: "field",
          errors: ["Field cannot be empty"],
        },
      ]);
      return;
    }

    if (!value.length) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Value cannot be empty"],
        },
      ]);
      return;
    }

    if (valueIndex !== -1 && !isEditing) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Widget is already added"],
        },
      ]);
      return;
    }

    if (width && (Number(width) < 0 || Number(width) > 12)) {
      formRef.current.setFields([
        {
          name: "width",
          errors: ["Width must be between 1 to 12"],
        },
      ]);
      return;
    }

    const targetRole = {
      field,
      value,
      belongsTo,
      width,
      description,
      roles,
    };

    let newAvailableWidgets = dataSource;

    newAvailableWidgets.push(targetRole);

    onSave(widgetName, newAvailableWidgets);
    forceUpdate();
    setShowModal(false);
    setDataSource(newAvailableWidgets);
  };

  const addWidget = () => {
    setEditingKey(null);
    setShowModal(true);
    forceUpdate();

    setTimeout(() => {
      formRef.current.setFieldsValue({
        field: "",
        value: "",
        belongsTo: [],
        width: null,
        description: "",
        roles: [],
      });
    }, 20);
  };

  /**
   * Render current row detail.
   * @returns
   */
  const renderRow = () => {
    return (
      <div className="gx-ml-5">
        <SearchRowSettings
          availableWidgets={dataSource}
          loading={loading}
          roles={roles}
          currentWidget={currentWidget}
          onSave={(widgetName, data) => {
            onSaveSystemWidget(widgetName, data);
            setShowCurrentDrawer(false);
          }}
          widgetName={widgetName}
          availableWidgetParams={availableWidgetParams}
          allWidgets={allWidgets}
        />
      </div>
    );
  };

  const renderRoles = (p, { roles }) => {
    return _.map(roles, (source) => {
      return (
        <Tag key={uuidv4()} color="blue">
          {source}
        </Tag>
      );
    });
  };

  const rolesOptions = roles.map((item) => ({
    field: item.displayName,
    value: item.name,
  }));

  const sourcesOptions = sources.map((source) => ({
    label: source?.field,
    value: source?.value,
  }));

  const applyToAll = (targetField, ops) => {
    const newAvailableWidgets = _.map(dataSource, (ds) => {
      return {
        ...ds,
        [targetField]: ops,
      };
    });

    setDataSource(newAvailableWidgets);
    onSave(widgetName, newAvailableWidgets);
  };

  const widgetAccessRoles = useRef();

  const columns = [
    {
      title: "Display Name",
      key: "field",
      dataIndex: "field",
      editable: true,
      responsive: ["sm"],
    },
    {
      title: "Database Name",
      key: "value",
      dataIndex: "value",
      responsive: ["sm"],
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      editable: true,
      responsive: ["sm"],
      required: false,
      render: (p, { description }) => (
        <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
          {description}
        </div>
      ),
    },
    {
      key: "roles",
      dataIndex: "roles",
      render: renderRoles,
      mode: "multiple",
      editable: true,
      responsive: ["sm"],
      title: () => {
        return (
          <div>
            <span>Widget Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={widgetAccessRoles}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = widgetAccessRoles.current.getFieldValue("options");
                applyToAll("roles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: renderActions,
      responsive: ["sm"],
    },
  ];

  const mergedColumns = columns.map((col) => {
    let options = [];

    if (!col.editable) {
      return col;
    }

    let inputType = "text";
    switch (col.dataIndex) {
      case "width":
        inputType = "number";
        break;
      case "roles":
        inputType = "select";
        options = rolesOptions;
        break;
      case "databases":
        inputType = "select";
        options = sourcesOptions;
        break;
      default:
        inputType = "text";
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options,
        mode: col.mode,
        required: col.required || true,
      }),
    };
  });

  return (
    <>
      <h3>{title}: </h3>
      {loading && <CircularProgress />}
      <Form form={form} component={false} className="gx-ml-3 gx-mt-3">
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          rowClassName="editable-row"
          pagination={false}
          dataSource={dataSource}
          size="small"
          scroll={{ y: TABLE_MAX_HEIGHT, x: true }}
          columns={mergedColumns}
        />
      </Form>
      <Button
        type="primary"
        size="small"
        className="gx-mt-2"
        onClick={addWidget}
      >
        Add New Widget
      </Button>

      <Modal
        title={"Add New Widget"}
        open={showModal}
        okText="Save"
        onCancel={() => {
          setShowModal(false);
        }}
        onOk={onConfirmModal}
      >
        <Form
          name="id"
          initialValues={{
            description: "",
            field: "",
            value: "",
            belongsTo: [],
          }}
          ref={formRef}
        >
          <Form.Item
            label="Display Name:"
            name="field"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Display Name should not be empty",
              },
            ]}
          >
            <Input
              placeholder="Enter display name"
              name="field"
              value={form?.field}
              onChange={(e) => {
                onChangeValue("field", e.target.value);
                formRef.current.setFields([
                  {
                    name: "value",
                    value: getFieldValueFromDisplayName(e.target.value),
                  },
                ]);
              }}
            />
          </Form.Item>
          <Form.Item
            label="Widget Name:"
            className="gx-mt-3"
            name="value"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Widget name should not be empty",
              },
            ]}
          >
            <Input
              placeholder="Enter widget name"
              value={form?.value}
              disabled
              // onChange={(e) => onChangeValue("value", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Description:"
            className="gx-mt-3"
            name="description"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: "Description should not be empty",
              },
            ]}
          >
            <Input
              placeholder="Enter description for widget"
              value={form?.description}
              disabled={disableEdit}
              onChange={(e) => onChangeValue("description", e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Widget Access Roles:"
            className="gx-mt-3"
            name="roles"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={_.map(roles, (role) => ({
                label: role?.displayName,
                value: role?.name,
              }))}
              mode="multiple"
              placeholder="Select roles to apply"
              value={form?.roles}
              onChange={(e) => onChangeValue("roles", e)}
            />
          </Form.Item>
          {/* <Form.Item
            label="Pages to apply:"
            className="gx-mt-3"
            name="belongsTo"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={SEARCH_PAGE_BELONGS_TO_OPTIONS}
              mode="multiple"
              placeholder="Select Pages to apply"
              value={form?.belongsTo}
              onChange={(e) => onChangeValue("belongsTo", e)}
            />
          </Form.Item> */}
          {/* <Form.Item
            label="Width (1 to 12)"
            className="gx-mt-3"
            name="width"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              type="number"
              max={12}
              min={0}
              value={form?.width}
              onChange={(e) => onChangeValue("width", e)}
            />
          </Form.Item> */}
        </Form>
      </Modal>

      <Drawer
        placement="right"
        title="Edit Widget"
        open={showCurrentDrawer}
        onClose={() => setShowCurrentDrawer(false)}
      >
        {renderRow()}
      </Drawer>
    </>
  );
};

export default SearchWidgets;

SearchWidgets.defaultProps = {
  title: "",
  widgetName: "",
  availableWidgets: [],
  loading: false,
  roles: [],
  disableEdit: false,
  onSaveSystemWidget: () => {},
};

SearchWidgets.propTypes = {
  title: PT.string,
  widgetName: PT.string,
  availableWidgets: PT.arrayOf(PT.shape()),
  loading: PT.bool,
  onSave: PT.func.isRequired,
  disableEdit: PT.bool,
  roles: PT.arrayOf(PT.shape()),
  onSaveSystemWidget: PT.func,
};
