import React, { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import CompoundInstanceHeader from "../CompoundInstanceWidget/CompoundInstanceHeader";
import { checkRowIsAccessible, getRoleId } from "../../../util/auth";
import CircularProgress from "components/CircularProgress";
import { Table, Tag } from "antd";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import _ from "lodash";
import { addCompoundSample } from "../../../appRedux/services/Widget";
import { NotificationManager } from "react-notifications";
import { PAGE_TYPES } from "../../../constants/Config";
import { getSample, putSample } from "../../../appRedux/services/Sample";
import pluralize from "pluralize";
import { renderCustomColumn } from "../CompositionTable/helper";
import { useDispatch, useSelector } from "react-redux";
import { fetchLookupData } from "../../../appRedux/actions";
import { useMsal } from "@azure/msal-react";

const SampleWidget = ({
  id,
  title,
  loading,
  setLoading,
  roles = [],
  pageType,
  samples,
  setSamples,
  sourceName,
  addSample,
  updateSample,
  sampleDetail,
  allWidgets,
  widget,
  sampleVersions,
}) => {
  const [open, setOpen] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [showModal, setShowModal] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [currentVersion, setCurrentVersion] = useState(sampleVersions);
  const lookupData = useSelector(({ lookup }) => lookup);

  const { accounts } = useMsal();
  const userName = `${accounts[0]?.username}`;

  // useEffect(() => {
  //   setCurrentVersion(sampleVersions);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // eslint-disable-next-line no-unused-vars
  const onChangeVersion = (ver) => {
    setCurrentVersion(ver);

    getSample({
      id,
      sourceName,
      version: ver,
      setFn: (detail) => {
        setSamples(detail?.samples || []);
      },
    });
  };

  const addNewSample = () => {
    setLoading(true);

    if (pageType === PAGE_TYPES.SAMPLE) {
      const instanceId = sampleDetail?.instanceId;

      const sampleIds = _.map(
        sampleDetail?.samples || [],
        (sample) => sample?.sampleId
      );

      addCompoundSample(
        instanceId,
        sourceName,
        (newSampleInstance) => {
          putSample({
            id,
            sourceName,
            samples: [...sampleIds, newSampleInstance?.sampleId],
            setFn: updateSample,
            userName,
          });
        },
        NotificationManager,
        setLoading,
        userName
      );
    } else {
      addCompoundSample(
        id,
        sourceName,
        addSample,
        NotificationManager,
        setLoading,
        userName
      );
    }
  };

  const onDelete = () => {};

  const hasPermissionForAction = (roles || []).includes(getRoleId());
  const dispatch = useDispatch();

  const { rows = [] } = widget;

  const optionsWithRole = _.filter(rows, (option) =>
    checkRowIsAccessible(option?.isAccessible)
  );

  const widgetNamesToResolve = _.uniq(
    _.map(
      _.filter(optionsWithRole, (role) => role?.widgetName),
      (i) => i?.widgetName
    )
  );

  useEffect(() => {
    // Update
    _.forEach(samples || [], (component) => {
      const id = component?.sampleId;

      if (id && _.isEmpty(_.get(lookupData, `${id}`))) {
        dispatch(fetchLookupData({ id, sourceName, widgetName: null }));
        // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));

        _.forEach(widgetNamesToResolve, (widgetName) => {
          dispatch(fetchLookupData({ id, sourceName, widgetName }));
          // dispatch(fetchLookupImage({ id, sourceName, widgetName: null }));
        });
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [samples, dispatch, lookupData, sourceName]);

  const customColumns = _.map(optionsWithRole || [], (row) => {
    return renderCustomColumn({
      widgetName: row?.widgetName,
      field: row?.field,
      pageType,
      allWidgets,
      sourceName,
      compositions: _.map(samples, (sample, ind) => ({
        ...sample,
        index: ind,
      })),
      displayName: row?.displayName,
    });
  });

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 has-scrollbar ${
        open ? "isOpen" : ""
      }`}
      key={uuidv4()}
    >
      <CompoundInstanceHeader
        id={id}
        title={
          pageType === PAGE_TYPES.SAMPLE
            ? `${pluralize("Sample", (samples || []).length)} (${
                (samples || []).length
              })`
            : title
        }
        open={open}
        setOpen={setOpen}
        onAddInstance={addNewSample}
        onDelete={onDelete}
        hasDeleteButton={false}
        loading={loading}
        hasPermissionForAction={hasPermissionForAction}
        setShowModal={setShowModal}
        pageType={pageType}
        setShowTableModal={() => {}}
        isSample
        disableDetail
      />

      {open && (
        <div
          className="ant-card-body widget-body"
          style={loading ? { height: "200px" } : {}}
        >
          {" "}
          {loading ? (
            <CircularProgress
              className="gx-loader-400 loader"
              style={loading ? { height: "280px" } : {}}
            />
          ) : (
            <>
              <Table
                pagination={false}
                className="gx-mt-2"
                dataSource={_.map(samples, (sample, ind) => ({
                  ...sample,
                  index: ind,
                }))}
                scroll={{ y: 240 }}
                columns={[
                  {
                    key: "id",
                    title: "Sample ID",
                    dataIndex: "sampleId",
                    render: (p, { sampleId }) => {
                      return (
                        <Link
                          className={
                            sampleId !== id
                              ? `gx-text-primary`
                              : `gx-text-warning`
                          }
                          target="_blank"
                          to={`/${sourceName}/sample/${sampleId}`}
                        >
                          {sampleId}
                        </Link>
                      );
                    },
                    sorter: (a, b) => {
                      return (a?.sampleId || "").localeCompare(
                        b?.sampleId || ""
                      );
                    },
                  },
                  {
                    key: "instanceId",
                    title: "Instance ID",
                    dataIndex: "instanceId",
                    render: (p, { instanceId }) => {
                      return (
                        <Link
                          className="gx-text-primary"
                          target="_blank"
                          to={`/${sourceName}/quality/${instanceId}`}
                        >
                          {instanceId}
                        </Link>
                      );
                    },
                    sorter: (a, b) => {
                      return (a?.instanceId || "").localeCompare(
                        b?.instanceId || ""
                      );
                    },
                  },
                  {
                    key: "samples",
                    title: "Samples",
                    sorter: (a, b) => {
                      const aa = (a?.samples || []).length
                        ? a?.samples[0]?.sampleId
                        : "";
                      const bb = (b?.samples || []).length
                        ? b?.samples[0]?.sampleId
                        : "";

                      return (aa || "").localeCompare(bb || "");
                    },
                    render: (p, { samples = [] }) => {
                      return _.map(samples, (s) => {
                        return (
                          <Tag color="blue" key={s?.id}>
                            <Link
                              className="gx-text-primary"
                              target="_blank"
                              to={`/${sourceName}/sample/${s?.sampleId}`}
                            >
                              {s?.sampleId}
                            </Link>{" "}
                          </Tag>
                        );
                      });
                    },
                  },

                  ...customColumns,
                ]}
              ></Table>
              {/* <Versioning
                currentVersion={currentVersion}
                versions={_.times(sampleVersions)}
                onChangeVersion={onChangeVersion}
              /> */}
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default SampleWidget;
