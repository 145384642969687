import React, { useContext } from "react";
import SearchBox from "../SearchBox";
import { MainContext } from "../../containers/App";
import { NotificationManager } from "react-notifications";

const ExploreInput = ({ style = {} }) => {
  const { searchTerm, setSearchTerm } = useContext(MainContext);

  const MIN_INPUT_LENGTH = 3;

  const validateInput = (inputText) => {
    return (inputText || "").length >= MIN_INPUT_LENGTH;
  };

  return (
    <>
      <SearchBox
        styleName="gx-popover-search-bar"
        placeholder="Search..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onKeyUp={(e) => {
          if (e.keyCode === 13) {
            if (!validateInput(e.target.value)) {
              NotificationManager.error(
                `Please enter at least ${MIN_INPUT_LENGTH} letters`
              );
            } else {
              window.location.assign(`/search?search=${searchTerm}`);
            }
          }
        }}
        style={style}
      />

      {/* <Form className="gx-d-flex gx-mt-3 gx-ml-5">
        <Form.Item label="Include document">
          <Checkbox
            checked={includeDocument}
            onChange={(e) => {
              setIncludeDocument(e.target.checked);
            }}
          />
        </Form.Item>
      </Form> */}
    </>
  );
};

export default ExploreInput;
