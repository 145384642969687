import axiosThrottle from "axios-request-throttle";
import throttleAxios from "../../../appRedux/services/apiThrottle";

import { config, MAX_CONCURRENT_REQUEST } from "../../../constants/Config";

axiosThrottle.use(throttleAxios, { requestsPerSecond: MAX_CONCURRENT_REQUEST });

export const autoAssignApi = ({ result }) => {
  return throttleAxios.post(`${config.SRD_API_URL}/autoAssign`, result, {
    headers: {
      Accept: "application/json",
      "Content-Type": "text/plain",
    },
  });
};

export const checkUniqApi = ({ result, sourceName }) => {
  return throttleAxios.post(
    `${config.SRD_API_URL}/uniqueness?sourceName=${sourceName}`,
    result,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
      },
    }
  );
};

export const registerRequestApi = async (
  result,
  dataSource,
  isIsomer = false,
  isKnown,
  typeName
) => {
  const body = isKnown ? result : "";
  const isomer = isIsomer ? { newRegistrationType: typeName } : {};
  const conceptValue = isKnown
    ? {}
    : {
        conceptValue: result,
      };

  const queryParams = new URLSearchParams({
    ...isomer,
    ...conceptValue,
    sourceName: dataSource || config.SOURCE_NAME,
  });

  return throttleAxios.post(
    `${config.SRD_API_URL}/register?${queryParams}`,
    body,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "text/plain",
      },
    }
  );
};
