import {
  GET_USER_DATA_REQUESTED,
  SAVE_USER_DATA_REQUESTED,
} from "../../constants/ActionTypes";

/**
 * Get user saved data
 * @param {String} type type of page.
 */
export const getUserData = (type) => {
  return {
    type: GET_USER_DATA_REQUESTED,
    payload: type,
  };
};

/**
 * Save user data
 * @param {String} type type of page.
 * @param {Object} data data to be saved
 */
export const saveUserData = (type, data) => {
  return {
    type: SAVE_USER_DATA_REQUESTED,
    payload: {
      type,
      data,
    },
  };
};
