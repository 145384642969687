import PT from "prop-types";
import React from "react";
import { v4 as uuidv4 } from "uuid";

import { InfoCircleTwoTone } from "@ant-design/icons";
import { Collapse, Tooltip } from "antd";
import _ from "lodash";
import RowEditable from "../RowEditable";
import "./style.css";

const RowNested = ({
  field,
  value,
  items,
  info,
  addMode,
  isEditable,
  onChange,
}) => {
  let form = {};

  const renderHeader = (
    <>
      <span>{field || "Sources"}</span>
      {info.length ? (
        <Tooltip title={info}>
          <InfoCircleTwoTone size="small" className="gx-ml-2 gx-pointer" />
        </Tooltip>
      ) : null}
    </>
  );

  return (
    <div className={"card-item"} key={uuidv4()}>
      <Collapse defaultActiveKey={["1"]} ghost>
        <Collapse.Panel header={renderHeader} key="1">
          {(items || []).map((item, index) => (
            <RowEditable
              field={item?.field}
              index={index}
              value={_.get(value, item?.field)}
              name={item?.field}
              onChange={(val) => {
                form[item?.field] = val;
                onChange(form);
              }}
              isEditable={isEditable}
              addMode={addMode}
            />
          ))}
        </Collapse.Panel>
      </Collapse>
    </div>
  );
};

RowNested.defaultProps = {
  field: "",
  items: [],
  info: "",
  isEditable: false,
  addMode: false,
  value: {},
};

RowNested.propTypes = {
  field: PT.string,
  items: PT.arrayOf(PT.shape()),
  info: PT.string,
  isEditable: PT.bool,
  addMode: PT.bool,
  onChange: PT.func.isRequired,
  value: PT.shape(),
};

export default RowNested;
