import React, { useEffect, useState } from "react";
import { Image, Spin } from "antd";
import { fetchImagePreview } from "../../../../../appRedux/services/Search";
import CompoundNotFound from "../../../../../assets/vendors/notfound.png";
import _ from "lodash";

// eslint-disable-next-line no-unused-vars
let first = true;

const PopoverImage = ({
  rmoId,
  sourceName,
  popoverWidth = 200,
  popoverHeight = 200,
  isStructure = false,
}) => {
  const [popoverImage, setPopoverImage] = useState(null);

  useEffect(() => {
    if (rmoId) {
      // first = false;
      if (isStructure) {
        fetchImagePreview(rmoId, setPopoverImage, false, sourceName);
      } else {
        fetchImagePreview(rmoId, setPopoverImage, true, sourceName);
      }
    }

    return () => {
      setPopoverImage(null);
      first = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rmoId]);

  if (!rmoId) {
    return null;
  }

  return (
    <>
      {popoverImage ? (
        <div
          style={{
            textAlign: "center",
            alignItems: "center !imporant",
            alignSelf: "center !imporant",
            height: `${popoverHeight} !important`,
            width: `${popoverWidth} !important`,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image
            preview={{ visible: false }}
            alt="preview"
            className="disable-preview"
            src={`data:image/svg+xml;utf8,${encodeURIComponent(popoverImage)}`}
            height={popoverHeight}
            width={popoverWidth}
          />
        </div>
      ) : (
        <div style={{ width: popoverWidth, height: popoverHeight }}>
          <>
            {_.isNull(popoverImage) ? (
              <Spin />
            ) : (
              <img
                src={CompoundNotFound}
                alt="compound-not-found"
                width={50}
                height={50}
                className="compound-not-found gx-mb-4"
              />
            )}
          </>
        </div>
      )}
    </>
  );
};

export default PopoverImage;
