import {
  CLOSE_ALERT_SUCCESS,
  GET_METADATA_REQUESTED,
  GET_METADATA_FAILED,
  GET_METADATA_SUCCESS,
  SAVE_METADATA_REQUESTED,
  SAVE_METADATA_SUCCESS,
  SAVE_METADATA_FAILED,
  GET_ELEMENTAL_ANALYSIS_REQUESTED,
  GET_ELEMENTAL_ANALYSIS_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  metadataLoading: false,
  errorMessage: "",
  compoundImage: "",
  elementalAnalysis: {},
  compoundDetail: {},
};

const MetadataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_METADATA_REQUESTED: {
      return {
        ...state,
        structureTypes: [],
        errorMessage: "",
        metadataLoading: true,
      };
    }
    case GET_METADATA_FAILED: {
      return {
        ...state,
        errorMessage: action.payload,
        metadataLoading: false,
        structureTypes: [],
      };
    }
    case GET_METADATA_SUCCESS: {
      let newState = state;
      const entries = Object.entries(action.payload);
      const widgetKeys = entries.length ? entries[0] : [];
      if (widgetKeys.length > 1) {
        const key = widgetKeys[0];
        const value = widgetKeys[1];
        newState = {
          ...state,
          metadataLoading: false,
          [key]: value.length ? value[0] : value,
        };
      }
      return newState;
    }
    case GET_ELEMENTAL_ANALYSIS_REQUESTED: {
      return {
        ...state,
        elementalAnalysis: {},
      };
    }
    case GET_ELEMENTAL_ANALYSIS_SUCCESS: {
      return {
        ...state,
        elementalAnalysis: action.payload,
      };
    }
    case CLOSE_ALERT_SUCCESS: {
      return {
        ...state,
        errorMessage: "",
        metadataLoading: false,
        namesAndSynonyms: [],
      };
    }
    case SAVE_METADATA_REQUESTED: {
      return {
        ...state,
        [action.payload?.metadataName]: {
          loading: true,
        },
      };
    }
    case SAVE_METADATA_SUCCESS: {
      return {
        ...state,
        [action.payload?.metadataName]: action.payload?.form,
      };
    }
    case SAVE_METADATA_FAILED: {
      return {
        ...state,
        structureTypes: [],
        namesAndSynonyms: [],
        errorMessage: action.payload,
      };
    }
    default:
      return state;
  }
};

export default MetadataReducer;
