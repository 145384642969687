import { Button, Divider, Form, Select } from "antd";
import _ from "lodash";
import React from "react";
import { NotificationManager } from "react-notifications";
import { saveUserWidget } from "../../../appRedux/services/SystemWidget";

const AddWidgetModal = ({
  modalFormRef,
  current,
  setCurrent,
  currentSettings,
  setCurrentSettings,
  userName,
  setLoading,
  selectAllWidgets,
  setShowAddWidgetModal,
  optionsWithUniqueKeys,
  layoutName,
}) => {
  return (
    <div>
      <Form
        ref={modalFormRef}
        onFinish={({ option }) => {
          // Check if widget is already added.
          const isFound = _.find(current?.widgetOptions || [], {
            widget: option,
          });

          const values = _.map(option, (op) =>
            _.isObject(op) ? op?.value : op
          );

          if (isFound) {
            NotificationManager.error("This widget is already added.");
            return;
          } else {
            // Set of new objects should be unique.
            const newOptions = _.uniq([
              ...(_.get(current, "widgets") || []),
              ...values,
            ]);

            const newWidgetOptions = _.uniqBy(
              [
                ...(_.get(current, "widgetOptions") || []),
                ..._.map(values, (op) => ({
                  widget: op,
                  width: 3,
                })),
              ],
              "widget"
            );

            const newCurrentSettings = {
              ...currentSettings,
              [current?.name]: {
                ...current,
                widgets: newOptions,
                widgetOptions: newWidgetOptions,
              },
            };

            saveUserWidget(
              layoutName,
              userName,
              (setting) => {
                setCurrentSettings(setting);
                const currentWidget = {
                  name: current?.name,
                  ..._.get(setting, current?.name),
                };

                setCurrent(currentWidget);
              },
              setLoading,
              newCurrentSettings,
              false,
              NotificationManager
            );

            setShowAddWidgetModal(false);
          }

          modalFormRef.current.setFields([
            {
              name: "option",
              value: [],
            },
          ]);
        }}
      >
        <Form.Item
          className="gx-mt-3"
          label={
            <div>
              <span>Select Widget</span>
              <Button
                size="small"
                type="primary"
                className="gx-ml-3 gx-mt-2"
                onClick={selectAllWidgets}
              >
                Select All
              </Button>
            </div>
          }
          name="option"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          rules={[
            {
              required: true,
              message: "Please select widget",
            },
          ]}
        >
          <Select
            showSearch
            mode="multiple"
            value={null}
            options={optionsWithUniqueKeys}
            filterOption={(input, option) =>
              (option.props?.label || "")
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0 ||
              (option.props?.value || "")
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          />
        </Form.Item>

        <Divider />

        <Form.Item style={{ marginBottom: "-10px" }}>
          <div className="gx-mr-2" style={{ textAlign: "right" }}>
            <Button onClick={() => setShowAddWidgetModal(false)}>Cancel</Button>
            <Button htmlType="submit" type="primary">
              Save
            </Button>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddWidgetModal;
