import axios from "axios";
import _ from "lodash";
import moment from "moment";
import {
  config,
  COUNTER_WIDGET,
  EXCEL_TEMPLATE_SOURCE_NAME,
  EXCEL_TEMPLATES_COLLECTION,
} from "../../constants/Config";
import { getSystemWidget, saveSystemWidget } from "./SystemWidget";

/**
 * Get documents
 * @param {String} documentId document id
 * @param {String} sourceName source name
 * @param {String} fileName file name
 * @returns
 */
export const downloadDocument = async (documentId, sourceName, fileName) => {
  return axios
    .get(
      `${config.SRD_API_URL}/repository/file?source=${sourceName}&documentId=${documentId}`,
      {
        responseType: "blob",
        headers: {
          "Accept-Ranges": "bytes",
          "Content-Type": "application/octet-stream",
        },
      }
    )
    .then((response) => {
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      link.target = "_blank";
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
    });
};

/**
 * Get documents
 * @param {String} documentId document id
 * @param {String} sourceName source name
 * @param {String} fileName file name
 * @returns
 */
export const openDocumentNewTab = async (
  documentId,
  sourceName,
  fileName,
  setFn
) => {
  return axios
    .get(
      `${config.SRD_API_URL}/repository/file?source=${sourceName}&documentId=${documentId}`,
      {
        responseType: "blob",
        headers: {
          "Accept-Ranges": "bytes",
          "Content-Type": "application/pdf",
          "content-disposition": "attachment;filename=944883.pdf",
        },
      }
    )
    .then((response) => {
      setFn(response.data);
      // const href = URL.createObjectURL(response.data, { oneTimeOnly: true });
      // const link = document.createElement("a");
      // link.href = href;
      // link.target = "_blank";
      // link.setAttribute("download", fileName);
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);
    });
};

/**
 * Get documents
 * @param {String} documentId document id
 * @param {String} sourceName source name
 * @param {String} fileName file name
 * @returns
 */
export const getDocument = async (documentId, sourceName, setFn) => {
  return axios
    .get(
      `${config.SRD_API_URL}/repository/file?source=${sourceName}&documentId=${documentId}`,
      {
        responseType: "blob",
        headers: {
          "Accept-Ranges": "bytes",
          "Content-Type": "application/octet-stream",
        },
      }
    )
    .then(async (response) => {
      await setFn(response.data);
    });
};

/**
 * Get External Documents
 * @param {String} documentId document id
 * @param {String} collection collection name
 * @param {String} sourceName source name
 * @returns
 */
export const fetchDocumentContent = async (
  documentId,
  collection,
  sourceName
) => {
  const query = {
    documentQueries: [
      {
        queryJoinType: "AND",
        documentQuery: {
          sources: {
            values: [sourceName],
            operator: "EQ",
            fieldType: "STRING",
          },
          subset: {
            values: [documentId],
            operator: "EQ",
            fieldType: "STRING",
          },
          collections: {
            values: [collection],
            operator: "EQ",
            fieldType: "STRING",
          },
        },
        order: 0,
        language: "en",
      },
    ],
  };

  return axios.post(`${config.SRD_API_URL}/search/documents`, query, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
};

/**
 * Delete document
 * @param {String} documentId document id
 * @param {String} sourceName sourceName
 * @returns
 */
export const deleteDocument = async (
  documentId,
  sourceName,
  setFn,
  SetErrFn
) => {
  return axios
    .delete(
      `${config.SRD_API_URL}/repository/document?documentId=${documentId}&source=${sourceName}`,
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      setFn(response);
    })
    .catch((e) => {
      SetErrFn(e?.response?.data?.message || "Error has occurred");
    });
};

/**
 * Update document
 * @param {String} source source id
 * @param {String} sourceName source id
 * @param {Object} file file
 * @param {Object} request request object
 * @returns
 */
export const uploadDocument = async (
  source,
  sourceName,
  files = [],
  request,
  setFn,
  SetErrFn,
  setLoading = () => {},
  setProgress = () => {},
  override = false,
  documentIds = [],
  isUrl = false
) => {
  let promises = [];
  setLoading(true);

  if (isUrl) {
    files = [[]];
    request.mimeType = "text/x-uri";
  } else {
    delete request.uri;
  }

  files.forEach((file, index) => {
    const formData = new FormData();
    let currentFile = null;

    if (file.length) {
      currentFile = file[0];
    } else {
      currentFile = file;
    }

    const requestWithAccessionNumber = {
      ...request,
      accessionNumber: request?.accessionNumber + index + 1,
    };

    if (currentFile) {
      formData.append("file", currentFile);
    }
    formData.append("request", JSON.stringify(requestWithAccessionNumber));

    if (override && documentIds[index]?.documentId) {
      formData.delete("request");
      promises.push(
        axios.put(
          `${config.SRD_API_URL}/repository/file?source=${sourceName}&documentId=${documentIds[index]?.documentId}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data;charset=UTF-8",
            },
            onUploadProgress: (axiosProgressEvent) => {
              setProgress(axiosProgressEvent);
            },
          }
        )
      );
    } else {
      promises.push(
        axios.post(
          `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data;charset=UTF-8",
            },
            onUploadProgress: (axiosProgressEvent) => {
              setProgress(axiosProgressEvent);
            },
          }
        )
      );
    }
  });

  Promise.all(promises)
    .then((response) => {
      const responseMessage =
        _.isArray(response) && response.length
          ? response[0]?.data?.message
          : response?.data?.message;
      if (responseMessage === "file already exists") {
        SetErrFn("File already exists.");
      } else {
        setFn(response);
      }
    })
    .catch((e) => {
      SetErrFn(e?.response?.data?.message || "Error has occurred");
    })
    .finally(() => {
      setLoading(false);
    });
};

/**
 * Edit document
 * @param {String} source source id
 * @param {String} sourceName source id
 * @param {Object} file file
 * @param {Object} request request object
 * @returns
 */
export const editDocument = async (
  source,
  sourceName,
  documentId,
  request,
  setFn,
  SetErrFn,
  setLoading
) => {
  setLoading(true);
  return axios
    .put(
      `${config.SRD_API_URL}/repository/document?source=${sourceName}&documentId=${documentId}`,
      JSON.stringify(request),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then((response) => {
      setFn(response);
    })
    .catch((e) => {
      SetErrFn(e?.response?.data?.message || "Error has occurred");
    })
    .finally(() => {
      setLoading(false);
    });
};

/**
 * Search for document
 * @param {String} sourceName
 * @param {Array} entityIds
 * @param {String} fileName
 * @returns
 */
export const searchDocument = (
  sourceName,
  _document,
  setFn,
  SetErrFn,
  searchQuery = null
) => {
  const query = {
    sources: {
      values: [sourceName],
      operator: "EQ",
    },
  };

  if (searchQuery) {
    const { search, operator, widgetOption, collection } = searchQuery;
    if (widgetOption && search && operator) {
      query["searchSubjects"] = [
        {
          subject: {
            key: widgetOption,
            value: search,
          },
          operator: operator,
        },
      ];
    }

    query["collections"] = {
      values: [collection],
      operator: "EQ",
    };
  }

  const data = {
    documentQueries: [
      {
        queryJoinType: "AND",
        documentQuery: {
          ...query,
        },
        order: 0,
        language: "en",
      },
    ],
  };

  return axios
    .post(`${config.SRD_API_URL}/search/documents`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setFn(response?.data);
    })
    .catch((e) => {
      SetErrFn(e?.response?.data?.message || "Error has occurred");
    });
};

export const searchForTemplate = async (setFn, SetErrFn = () => {}) => {
  return axios
    .post(
      `${config.SRD_API_URL}/search/documents`,
      JSON.stringify({
        documentQueries: [
          {
            queryJoinType: "AND",
            documentQuery: {
              sources: {
                values: [EXCEL_TEMPLATE_SOURCE_NAME],
                operator: "EQ",
              },
              collections: {
                values: [EXCEL_TEMPLATES_COLLECTION],
                operator: "EQ",
              },
              queryJoinType: "AND",
            },
            order: 0,
            language: "en",
          },
        ],
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
    .then(async (response) => {
      // setFn(response?.data?.hitList);
      const documents = response?.data?.hitList || [];

      console.log("documents", documents);

      if (documents.length) {
        let promises = [];
        _.forEach(documents, (documentId) => {
          console.log("document id", documentId);

          promises.push(
            axios.get(
              `${config.SRD_API_URL}/repository/document?documentId=${documentId}`
            )
          );
        });

        await Promise.allSettled(promises).then(async (results) => {
          const data = _.map(
            _.filter(results, (result) => result.status === "fulfilled"),
            (result) => result.value?.data
          );

          setFn(data);
        });
      }
    })
    .catch((e) => {
      SetErrFn(e?.response?.data?.message || "Error has occurred");
    });
};

export const searchForDocumentCollection = (data, setFn) => {
  return axios
    .post(`${config.SRD_API_URL}/search/documents`, JSON.stringify(data), {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then((response) => {
      setFn(response?.data?.hitList || []);
    });
};

export const convertFileToPDF = (id, setFn, setErrFn) => {
  return axios
    .post(`${config.SRD_API_URL}/repository/convert?documentId=${id}`)
    .then((response) => {
      setFn(response.data);
    })
    .catch((e) => {
      setErrFn(e?.response?.data?.message || "Error has occurred.");
    });
};

/**
 * Get latest counter id from collection
 * @param widgetName
 */
export const getCounter = (setFn, widgetName = COUNTER_WIDGET) => {
  getSystemWidget(
    widgetName,
    (res) => {
      setFn(res);
    },
    false
  );
};

/**
 * Get latest counter id from collection
 * @param widgetName
 */
export const saveDefaultCounter = (
  setFn,
  data,
  id,
  prefix,
  widgetName = COUNTER_WIDGET
) => {
  const newData = {
    ...data,
    [prefix]: id,
  };

  saveSystemWidget(
    widgetName,
    (res) => {
      setFn(res);
    },
    () => {},
    JSON.stringify(newData)
  );
};

/**
 * Save counter id to collection
 * @param widgetName
 * @param id
 */
export const saveCounter = async (
  widgetName,
  data = {},
  id = 1,
  locked = false,
  setFn = () => {},
  counterWidgetName = COUNTER_WIDGET
) => {
  const newData = {
    ...data,
    [widgetName]: {
      locked,
      timestamp: moment(),
      counter: id,
    },
  };

  await saveSystemWidget(
    counterWidgetName,
    () => {
      setFn();
    },
    () => {},
    JSON.stringify(newData)
  );
};

/**
 * Send Get Documents from widget
 * @param {String} id
 * @param {String} sourceName source Name
 */
export const cloneDocumentApi = async (targetId, sourceName, file) => {
  return axios
    .get(
      `${config.SRD_API_URL}/repository/file?source=${sourceName}&documentId=${file?.id}`,
      {
        responseType: "blob",
        headers: {
          "Accept-Ranges": "bytes",
          "Content-Type": "application/octet-stream",
        },
      }
    )
    .then((response) => {
      const formData = new FormData();

      const newFile = {
        ..._.omit(file, ["id", "entityIds", "name", "fileName"]),
        entityIds: [targetId],
        name: `${file?.name}-${targetId}`,
        fileName: file?.fileName,
      };

      formData.append("file", response.data, file?.fileName);
      formData.append("request", JSON.stringify(newFile));

      return axios.post(
        `${config.SRD_API_URL}/repository/document?source=${sourceName}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data;charset=UTF-8",
          },
        }
      );
    });
};
