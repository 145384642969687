import { PublicClientApplication } from "@azure/msal-browser";
import _ from "lodash";
import { MSAL_CONFIG } from "../constants/Config";

/**
 * Check if whether user is admin.
 */
export const isAdmin = ({ roles, targetUrl = "" }) => {
  const role = getRole() || "";
  const targetRole = _.find(roles, { name: role });

  if ((role || "").toLowerCase() === "admin") {
    return true;
  }

  const { pagesAccess } = targetRole || [];

  const hasAccess = _.filter(pagesAccess, (item) =>
    targetUrl.includes(item)
  ).length;

  return hasAccess;
};
/**
 * Check if whether current logged in user has tab option permitted.
 */
export const checkTabPermitted = (roles, tabName) => {
  const role = getRole() || "";
  const targetRole = _.find(roles, { name: role });

  const r = (role || "").toLowerCase();

  if (r === "admin" || r === "administrator") {
    return true;
  }

  const { searchPageRoles } = targetRole || [];

  const hasAccess = _.includes(searchPageRoles, tabName);

  return hasAccess;
};

/**
 * Get current selected user's role
 */
export const getCurrentRole = () => {
  return localStorage.getItem("current_role_name");
};

/**
 * Get current selected user's role id
 */
export const getCurrentRoleId = () => {
  return localStorage.getItem("current_role_id");
};

/**
 * Get user's role. Default by user
 */
export const getRole = () => {
  return getCurrentRole() || localStorage.getItem("role_name") || "User";
};

/**
 * Get user's role id.
 */
export const getRoleId = () => {
  return getCurrentRoleId() || localStorage.getItem("role_id") || "";
};

/**
 * Get current selected user's role
 */
export const getCurrentDocumentRole = () => {
  return localStorage.getItem("current_document_role_name");
};

/**
 * Get current selected user's role Id
 */
export const getCurrentDocumentRoleId = () => {
  return localStorage.getItem("current_document_role_id");
};

/**
 * Get document role. Default by user
 */
export const getDocumentRole = () => {
  return (
    getCurrentDocumentRole() ||
    localStorage.getItem("document_role_name") ||
    "User"
  );
};

/**
 * Get document role id.
 */
export const getDocumentRoleId = () => {
  return (
    getCurrentDocumentRoleId() || localStorage.getItem("document_role_id") || ""
  );
};

/**
 * Get user's role name.
 */
export const getRoleName = () => {
  const role = getRole();

  switch (role) {
    case "admin":
      return "Admin";
    case "power_user":
      return "Power User";
    default:
      return "User";
  }
};

/**
 * Get user's role. Default by user
 */
export const getUserRole = () => {
  return localStorage.getItem("user_role") || "User";
};

/**
 * Get user's name. Integrate it to Azure
 */
export const getUserName = () => {
  return localStorage.getItem("user_role") || "user";
};

/**
 * Get current user's role. Default by user
 */
export const checkActionAllowed = () => {
  const role = getRole();

  if (role === "user") return false;

  return true;
};

/**
 * Change current role
 */
export const changeCurrentRole = ({ name, id }) => {
  localStorage.setItem("current_role_name", name);
  localStorage.setItem("current_role_id", id);
};

/**
 * Change current role
 */
export const changeCurrentDocumentRole = ({ name, id }) => {
  localStorage.setItem("current_document_role_name", name);
  localStorage.setItem("current_document_role_id", id);
};

export const msalInstance = new PublicClientApplication(MSAL_CONFIG);

export function signOutClickHandler(instance, homeAccountId) {
  localStorage.removeItem("current_role_id");
  localStorage.removeItem("role_id");
  localStorage.removeItem("current_user_id");
  localStorage.removeItem("role_name");
  localStorage.removeItem("current_role_name");
  localStorage.removeItem("current_document_role_id");
  localStorage.removeItem("current_document_role_name");
  localStorage.removeItem("document_role_name");
  localStorage.removeItem("document_role_id");

  const logoutRequest = {
    account: instance.getAccountByHomeId(homeAccountId),
    postLogoutRedirectUri: "https://arxada.com",
  };
  instance.logoutRedirect(logoutRequest);
}

/**
 * Check if row is editable
 * @param {*} isEditable
 * @returns
 */
export const checkRowIsEditable = (isEditable) => {
  const obj = _.isObject(isEditable) ? isEditable : {};
  const currentRole = getRoleId();
  const access = _.get(obj || {}, currentRole) || false;

  return access;
};

/**
 * Check if row is accessible
 * @param {*} isAccessible
 * @returns
 */
export const checkRowIsAccessible = (isAccessible) => {
  const obj = _.isObject(isAccessible) ? isAccessible : {};
  const currentRole = getRoleId();
  const access = _.get(obj, currentRole) || false;

  return access;
};

/**
 * Checks through available widgets and check if current user has access to it.
 * @param {Object} availableWidgets available widgets
 * @param {String} widgetName widget name
 * @param {String} role current user role
 */
export const checkWidgetAccess = (availableWidgets, widgetName, role, type) => {
  // Find desired widget.
  const widget = _.find(availableWidgets, { value: widgetName });

  if (type === "documents") {
    if (widget && widget?.userRoles && _.isArray(widget.userRoles)) {
      return (widget.userRoles || []).includes(role);
    }
  } else {
    if (widget && widget?.roles && _.isArray(widget.roles)) {
      return (widget.roles || []).includes(role);
    }
  }

  return false;
};

/**
 * Checks through available widgets and check if current user has access to row.
 * @param {Object} availableWidgets available widgets
 * @param {String} widgetName widget name
 * @param {Object} paramaters parameters object
 * @param {String} role current user role
 */
export const filterParameterByRole = (
  availableWidgets,
  widgetName,
  parameters = {},
  role
) => {
  // Find desired widget.
  const currentWidget = _.find(availableWidgets, { value: widgetName }) || {};
  const rows = _.get(currentWidget, "widget.rows");

  let newParameters = {};

  for (const [key, value] of Object.entries(parameters)) {
    // Find desired row.
    const row = _.find(rows, { name: key });

    if (row && row?.isAccessible && _.get(row?.isAccessible, role)) {
      _.set(newParameters, key, value);
    }
  }

  return newParameters;
};

/**
 * Checks through available widgets and check if current user has access to row.
 * @param {Object} collectionOptions collection options
 * @param {String} widgetName widget name
 * @param {Array<Object>} paramaters parameters
 * @param {String} role current user role
 */
export const filterDocumentDataByRole = (
  collectionOptions,
  widgetName,
  parameters,
  role
) => {
  // Find desired widget.
  const currentWidget = _.find(collectionOptions, { value: widgetName }) || {};
  const rows = _.get(currentWidget, "widget.rows");

  const newData = _.filter(parameters, (parameter) => {
    const { key } = parameter;

    const row = _.find(rows, { name: key });

    if (row && row?.isAccessible && _.get(row?.isAccessible, role)) {
      return true;
    }

    return false;
  });

  return newData;
};

/**
 * Check document access by current assigned roles.
 */
export const checkDocumentAccess = ({
  targetRoleIds = [],
  documentRoles = [],
}) => {
  const int = _.intersection(targetRoleIds, documentRoles);

  return (int || []).length;
};
