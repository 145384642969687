import {
  AUTO_ASSIGN_FAILED,
  AUTO_ASSIGN_REQUESTED,
  AUTO_ASSIGN_SUCCESS,
  CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS,
  CHECK_UNIQUENESS_FAILED,
  CHECK_UNIQUENESS_REQUESTED,
  CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS,
  CLEAR_ERROR_MESSAGE_REQUESTED,
  CLEAR_ERROR_MESSAGE_SUCCESS,
  CLEAR_REGISTER_DATA_REQUESTED,
  CLEAR_REGISTER_DATA_SUCCESS,
  GENERATE_IMAGE_FAILED,
  GENERATE_IMAGE_REQUESTED,
  GENERATE_IMAGE_SUCCESS,
  REGISTER_FAILED,
  REGISTER_REQUESTED,
  REGISTER_SUCCESS,
  UPDATE_FAILED,
  UPDATE_REQUESTED,
  UPDATE_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  loading: false,
  errorMessage: null,
  structure: {},
  uniqueReason: "",
  exactMatches: [],
  isUnique: null,
  registerIds: [],
  structureImage: [],
  noStructure: [],
  uniqunessSubmitted: false,
  updateIds: [],
};

const StructureRegistrationReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case AUTO_ASSIGN_REQUESTED: {
      return {
        ...state,
        errorMessage: null,
      };
    }
    case AUTO_ASSIGN_FAILED: {
      return {
        ...state,
        errorMessage: action.payload,
        structure: {},
      };
    }
    case AUTO_ASSIGN_SUCCESS: {
      return {
        ...state,
        structure: action.payload,
        errorMessage: null,
      };
    }
    case CHECK_UNIQUENESS_REQUESTED: {
      return {
        ...state,
        errorMessage: null,
        loading: true,
        uniqunessSubmitted: false,
      };
    }
    case CHECK_UNIQUENESS_EXACT_MATCH_SUCCESS: {
      return {
        ...state,
        loading: false,
        uniqueReason: "",
        isUnique: false,
        exactMatches: action.payload?.exactMatch,
        noStructure: action.payload?.noStructure,
        enantiomer: action.payload?.enantiomer,
        diastereomer: action.payload?.diastereomer,
        opticalIsomer: action.payload?.opticalIsomer,
        coordination: action.payload?.coordination,
        geometricIsomer: action.payload?.geometricIsomer,
        racemate: action.payload?.racemate,
        isotope: action.payload?.isotope,
        tautomer: action.payload?.tautomer,
        uniqunessSubmitted: true,
      };
    }
    case CHECK_UNIQUENESS_UNIQUE_REASON_SUCCESS: {
      return {
        ...state,
        loading: false,
        uniqueReason: action.payload?.reason,
        enantiomer: action.payload?.enantiomer,
        diastereomer: action.payload?.diastereomer,
        exactMatches: action.payload?.exactMatch,
        noStructure: action.payload?.noStructure,
        opticalIsomer: action.payload?.opticalIsomer,
        coordination: action.payload?.coordination,
        geometricIsomer: action.payload?.geometricIsomer,
        racemate: action.payload?.racemate,
        isotope: action.payload?.isotope,
        tautomer: action.payload?.tautomer,
        isUnique: true,
      };
    }
    case CHECK_UNIQUENESS_FAILED: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        exactMatches: [],
        noStructure: [],
        uniqueReason: "",
        isUnique: null,
      };
    }
    case REGISTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        updateIds: [],
      };
    }

    case UPDATE_REQUESTED: {
      return {
        ...state,
        loading: true,
        registerIds: [],
        updateIds: [],
      };
    }

    case CLEAR_REGISTER_DATA_REQUESTED:
    case CLEAR_REGISTER_DATA_SUCCESS: {
      return {
        ...state,
        loading: false,
        registerIds: [],
        updateIds: [],
      };
    }

    case REGISTER_SUCCESS: {
      return {
        ...state,
        loading: false,
        registerIds: action.payload,
        exactMatches: [],
      };
    }

    case UPDATE_SUCCESS: {
      return {
        ...state,
        loading: false,
        updateIds: action.payload,
      };
    }

    case REGISTER_FAILED: {
      return {
        ...state,
        loading: false,
        errorMessage: action.payload,
        exactMatches: [],
        uniqueReason: "",
        registerIds: [],
      };
    }

    case UPDATE_FAILED: {
      return {
        ...state,
        loading: false,
        updateIds: [],
        errorMessage: action.payload,
      };
    }

    case GENERATE_IMAGE_REQUESTED: {
      return {
        ...state,
      };
    }
    case GENERATE_IMAGE_SUCCESS: {
      return {
        ...state,
        loading: false,
        structureImage: [action.payload],
      };
    }
    case GENERATE_IMAGE_FAILED: {
      return {
        ...state,
        loading: false,
        structureImage: [null],
        errorMessage: action.payload,
      };
    }
    case CLEAR_ERROR_MESSAGE_SUCCESS:
    case CLEAR_ERROR_MESSAGE_REQUESTED: {
      return {
        ...state,
        errorMessage: null,
        structure: {},
        loading: false,
        isUnique: null,
        exactMatches: [],
        uniqueReason: "",
        structureImage: [],
        registerIds: [],
        updateIds: [],
      };
    }
    default:
      return state;
  }
};

export default StructureRegistrationReducer;
