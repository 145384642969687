/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import {
  Button,
  Checkbox,
  Col,
  Descriptions,
  Divider,
  Form,
  Input,
  Modal,
  Popover,
  Row,
  Select,
} from "antd";
import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import PT from "prop-types";
import InputColor from "../../../../components/InputColor";
import { DOCUMENT_COLLECTION_OPTIONS } from "../../../../constants/Config";
import SortableRows from "../SortableRows";

const SystemWidgetForm = ({
  widgetName,
  widgetType,
  data,
  onSave,
  onRowChange,
  roles,
  settingsWidgetName,
  availableWidgets = [],
  closeDialog = () => {},
}) => {
  const childRef = useRef();
  const [form, setForm] = useState([]);

  const [mainSettingsModal, setMainSettingsModal] = useState(false);
  const [imageSettingsModal, setImageSettingsModal] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [inlineFields, setInlineFields] = useState([]);

  const isColumnConfigurable = [
    "CompositionTable",
    "ExperimentTable",
    "Qualities",
    "Experiments",
    "Samples",
  ].includes(widgetName);

  useEffect(() => {
    setForm({ ...form, ...data });
  }, [data]);

  const onChangeValue = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });
  };

  const onChangeDepthValue = (parentField, field, value) => {
    const parentObj = _.get(form, parentField);

    setForm({
      ...form,
      [parentField]: {
        ...parentObj,
        [field]: value,
      },
    });
  };

  const addRow = () => {
    let newRows = form?.rows || [];

    try {
      newRows = childRef.current.getCurrentRows();
    } catch (error) {}

    newRows.push({
      key: uuidv4(),
    });

    setForm({
      ...form,
      rows: newRows,
    });
  };

  const convertJSON = (targetData) => {
    try {
      const res = JSON.parse(targetData);
      return res;
    } catch (error) {
      return targetData;
    }
  };

  const renderMainSettingsPreview = () => {
    return (
      <Descriptions
        title="Main Settings Preview"
        bordered
        layout="horizontal"
        size="small"
        column={1}
        style={{
          maxHeight: "300px",
          overflow: "scroll",
        }}
      >
        <Descriptions.Item label="Title">
          {_.get(form, "title")}
        </Descriptions.Item>
        <Descriptions.Item label="Title Color">
          {_.get(form, "titleColor")}
        </Descriptions.Item>
        <Descriptions.Item label="Background Color">
          {_.get(form, "backgroundColor")}
        </Descriptions.Item>
        <Descriptions.Item label="Tooltip">
          {_.get(form, "info")}
        </Descriptions.Item>
        <Descriptions.Item label="Minimum Height">
          {_.get(form, "maxHeight")
            ? `${_.get(form, "maxHeight")} px`
            : "not defined"}
        </Descriptions.Item>
        <Descriptions.Item label="Has version">
          {_.get(form, "hasVersion") ? "True" : "False"}
        </Descriptions.Item>
      </Descriptions>
    );
  };
  const renderImageSettingsPreview = () => {
    return (
      <Descriptions
        title="Image Settings Preview"
        bordered
        layout="horizontal"
        size="small"
        column={1}
      >
        <Descriptions.Item label="Base Url">
          {_.get(form, "image.baseUrl")}
        </Descriptions.Item>
        {/* <Descriptions.Item label="Params">
          {_.get(form, "image.params")}
        </Descriptions.Item>
        <Descriptions.Item label="Headers">
          {_.get(form, "image.headers")}
        </Descriptions.Item> */}
      </Descriptions>
    );
  };

  useEffect(() => {
    if (!_.isEqual(data?.inlineFields || [], inlineFields)) {
      setInlineFields(data?.inlineFields || []);
    }
  }, [data]);

  console.log("data", data);

  return (
    <>
      {!_.isEmpty(data) ? (
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <Button
              className="gx-mt-3 gx-mr-5"
              type="primary"
              size="small"
              onClick={() => {
                const rows = childRef.current.getCurrentRows();
                const image = form?.image;
                const newForm = {
                  ...form,
                  rows,
                  image: {
                    baseUrl: image?.baseUrl,
                    params: convertJSON(image?.params),
                    headers: convertJSON(image?.headers),
                  },
                };
                onSave(widgetName, newForm);
                closeDialog();
              }}
            >
              Save and Close
            </Button>
          </div>
          <Form>
            {widgetType === "table" ? (
              <Divider orientation="left">TABLE HEADER SETTINGS: </Divider>
            ) : (
              <>
                {isColumnConfigurable ? (
                  <Divider orientation="left">COLUMN SETTINGS: </Divider>
                ) : (
                  <Divider orientation="left">ROW SETTINGS: </Divider>
                )}
              </>
            )}
            <>
              <SortableRows
                ref={childRef}
                parentForm={form}
                sortableRows={form?.rows || []}
                onRowChange={onRowChange}
                roles={roles}
                widgetName={widgetName}
                widgetType={widgetType}
                settingsWidgetName={settingsWidgetName}
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                isColumnSettings={isColumnConfigurable}
                availableWidgets={availableWidgets}
              />

              {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
                <Form.Item
                  label="Additional Document Fields"
                  labelCol={{ span: 24 }}
                  wrapperCol={{ span: 24 }}
                  className="gx-mt-3"
                >
                  <Select
                    value={inlineFields}
                    mode="multiple"
                    style={{
                      width: "30vw",
                    }}
                    options={[
                      { label: "Description", value: "description" },
                      { label: "URL", value: "url" },
                      { label: "Accession number", value: "accessionNumber" },
                    ]}
                    onChange={(fields) => setInlineFields(fields)}
                  ></Select>
                </Form.Item>
              ) : null}

              <div className="gx-mt-4">
                <Button type="primary" size="small" onClick={addRow}>
                  Add New Item
                </Button>

                <Button
                  type="primary"
                  size="small"
                  onClick={() => {
                    const rows = childRef.current.getCurrentRows();

                    const image = form?.image;
                    const newForm = {
                      ...form,
                      rows,
                      image: {
                        baseUrl: image?.baseUrl,
                        params: convertJSON(image?.params),
                        headers: convertJSON(image?.headers),
                        width: image?.width,
                      },
                      inlineFields,
                    };
                    onSave(widgetName, newForm);
                  }}
                >
                  Save
                </Button>

                {isColumnConfigurable ? (
                  <>
                    <Popover content={renderMainSettingsPreview()}>
                      <Button
                        size="small"
                        onClick={() => setMainSettingsModal(true)}
                      >
                        Open Main Settings
                      </Button>
                    </Popover>

                    <Popover content={renderImageSettingsPreview()}>
                      <Button
                        size="small"
                        onClick={() => setImageSettingsModal(true)}
                      >
                        Open Image Settings
                      </Button>
                    </Popover>
                  </>
                ) : null}
              </div>
            </>
          </Form>
        </div>
      ) : null}
      {/* 
      <PreviewModal
        open={openPreview}
        onCancel={() => {
          setOpenPreview(false);
        }}
      /> */}

      <Modal
        title="Main Settings"
        open={mainSettingsModal}
        onOk={() => setMainSettingsModal(false)}
        onCancel={() => setMainSettingsModal(false)}
      >
        <div>
          <Row>
            <Col span={12}>
              <Form.Item
                label="Title"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  value={form?.title}
                  onChange={(e) => onChangeValue("title", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Title Color"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputColor
                  color={form?.titleColor}
                  onChange={(e) => onChangeValue("titleColor", e?.color)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Background Color"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <InputColor
                  color={form?.backgroundColor}
                  onChange={(e) => onChangeValue("backgroundColor", e?.color)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Toolip"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  value={form?.info}
                  onChange={(e) => onChangeValue("info", e.target.value)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Maximum Height (px)"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  type="number"
                  value={form?.maxHeight}
                  onChange={(e) => onChangeValue("maxHeight", e.target.value)}
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="Summary Field"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  type="string"
                  value={form?.summaryField}
                  onChange={(e) =>
                    onChangeValue("summaryField", e.target.value)
                  }
                />
              </Form.Item>
            </Col> */}
            <Col span={12}>
              <Form.Item
                label="Include date & user information"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Checkbox
                  value={form?.includeUpdatedInfo}
                  onChange={(e) =>
                    onChangeValue("includeUpdatedInfo", e.target.checked)
                  }
                />
              </Form.Item>
            </Col>
            {/* <Col span={12}>
              <Form.Item
                label="Summary Field"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Input
                  type="string"
                  value={form?.summaryField}
                  onChange={(e) =>
                    onChangeValue("summaryField", e.target.value)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                label="Has Version"
              >
                <Switch
                  checked={form?.hasVersion}
                  onChange={(e) => onChangeValue("hasVersion", e)}
                />
              </Form.Item>
            </Col> */}
          </Row>
        </div>
      </Modal>
      <Modal
        title="Image Settings"
        open={imageSettingsModal}
        onOk={() => setImageSettingsModal(false)}
        onCancel={() => setImageSettingsModal(false)}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="Base URL"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                value={form?.image?.baseUrl}
                onChange={(e) =>
                  onChangeDepthValue("image", "baseUrl", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Params"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                style={{ height: "20px" }}
                value={
                  _.isObject(form?.image?.params)
                    ? JSON.stringify(form?.image?.params)
                    : form?.image?.params
                }
                onChange={(e) =>
                  onChangeDepthValue("image", "params", e.target.value)
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              label="Headers"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <TextArea
                style={{ height: "20px" }}
                value={
                  _.isObject(form?.image?.headers)
                    ? JSON.stringify(form?.image?.headers)
                    : form?.image?.headers
                }
                onChange={(e) =>
                  onChangeDepthValue("image", "headers", e.target.value)
                }
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              label="Width (px)"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                type="number"
                value={form?.image?.width}
                onChange={(e) =>
                  onChangeDepthValue("image", "width", e.target.value)
                }
              />
            </Form.Item>
          </Col>
        </Row>
      </Modal>
    </>
  );
};

export default SystemWidgetForm;

SystemWidgetForm.defaultProps = {
  data: {},
  roles: [],
};

SystemWidgetForm.propTypes = {
  data: PT.shape(),
  setData: PT.func.isRequired,
  onSave: PT.func.isRequired,
  roles: PT.arrayOf(PT.shape()),
};
