import { DeleteTwoTone } from "@ant-design/icons";
import { Col, Form, Input, Row, Select, Slider } from "antd";
import React, { useState } from "react";
import {
  IDENTIFIER_SEPERATOR,
  SEARCH_CUTOFF_MAX,
  SEARCH_CUTOFF_MIN,
} from "../../../../constants/Config";
import { marks, similarityOptions } from "../../../SearchPage/helper";

const ProcessRow = ({
  role,
  substanceNumber,
  index,
  sourceIdentifier,
  onChangeSubstanceNumber,
  selectOptions,
  onChangeRole,
  onDeleteRow,
  renderSearchTypes,
  searchOps,
  onChangeTypes,
  selectedDataSources,
}) => {
  const [cutoff, setCutoff] = useState(role?.cutoff || 0);
  const [type, setType] = useState(role?.type || "BINGO_EXACT");
  const [form] = Form.useForm();
  const matchSearch = Form.useWatch("searchOps", form);

  return (
    <Form form={form}>
      <Form.Item label="Substance Number" className="gx-mb-1">
        <Input
          prefix={`${sourceIdentifier} ${IDENTIFIER_SEPERATOR} `}
          maxLength={4}
          name="substanceNumber"
          type="text"
          defaultValue={substanceNumber}
          size="small"
          placeholder="number"
          onPressEnter={(e) => {
            onChangeSubstanceNumber(e.target.value, index);
          }}
        />
      </Form.Item>
      <Form.Item label="Role">
        <div className="gx-d-flex">
          <Select
            options={selectOptions}
            className="gx-width-100"
            name="role"
            size="small"
            value={role}
            onChange={(e) => onChangeRole(e, index)}
          />
          <DeleteTwoTone
            className="gx-mt-2 gx-ml-2"
            twoToneColor="red"
            onClick={() => onDeleteRow(index)}
          />
        </div>
      </Form.Item>

      {renderSearchTypes ? (
        <>
          <Form.Item name="searchOps" label="Search Types" className="gx-mt-3">
            <Select
              dropdownMatchSelectWidth={false}
              size="small"
              placeholder="Select type"
              defaultValue="BINGO_EXACT"
              value={type}
              onChange={(e) => {
                setType(e);
                onChangeTypes(e, cutoff, index);
              }}
            >
              {searchOps.map((item, index) => (
                <Select.Option
                  key={`search-ops-option-${index}`}
                  value={item.value}
                >
                  {item.field}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          {similarityOptions.includes(matchSearch) && (
            <Form.Item required name="cutoffs" className="gx-mt-3">
              <Row>
                <Col sm={8} xs={8}>
                  <Input
                    type="number"
                    value={cutoff}
                    min={Number(SEARCH_CUTOFF_MIN)}
                    max={Number(SEARCH_CUTOFF_MAX)}
                    maxLength={3}
                    minLength={1}
                    onChange={(e) => {
                      let cur = Number(e.target.value);
                      setCutoff(cur);
                    }}
                  />
                </Col>
                <Col sm={14} xs={10} className="gx-ml-3">
                  <Slider
                    min={SEARCH_CUTOFF_MIN}
                    max={SEARCH_CUTOFF_MAX}
                    value={cutoff}
                    onChange={(e) => {
                      setCutoff(e);
                      onChangeTypes(type, e, index);
                    }}
                    trackStyle={{ backgroundColor: "coral" }}
                    handleStyle={{ backgroundColor: "coral" }}
                    tooltipPlacement="bottom"
                    tooltipVisible={false}
                    marks={marks}
                  />
                </Col>
              </Row>
            </Form.Item>
          )}
        </>
      ) : null}
    </Form>
  );
};

export default ProcessRow;
