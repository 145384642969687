import React from "react";
import PT from "prop-types";
import { Form, Select } from "antd";
import { useRef } from "react";
import _ from "lodash";

const SelectMatches = ({ exactMatch, onChange }) => {
  const formRef = useRef();

  return (
    <>
      {(exactMatch || []).length ? (
        <div>
          <Form ref={formRef} layout="vertical">
            <Form.Item
              name="match"
              label={<span className="gx-text-primary">Exact matches:</span>}
            >
              <Select
                mode="multiple"
                allowClear
                style={{ width: "100%" }}
                placeholder="Please select"
                defaultValue={exactMatch}
                options={_.map(exactMatch || [], (item) => ({
                  label: item,
                  value: item,
                }))}
                onChange={(values) => {
                  if (values.length !== 1) {
                    formRef.current?.setFields([
                      {
                        name: "match",
                        errors: [`Please select only one item.`],
                      },
                    ]);
                  } else {
                    formRef.current?.setFields([
                      {
                        name: "match",
                        errors: [],
                      },
                    ]);
                  }

                  onChange(values);
                }}
              />
            </Form.Item>
          </Form>
        </div>
      ) : null}
    </>
  );
};

SelectMatches.defaultTypes = {
  exactMatch: [],
};

SelectMatches.propTypes = {
  exactMatch: PT.arrayOf(PT.string),
};

export default SelectMatches;
