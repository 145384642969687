import axios from "axios";
import { config } from "../../constants/Config";
import { getImageUrlParams } from "../../util/url";

/**
 * Get Image of Given Component
 * @param {String} id id
 * @param {String} sourceName source name
 * @param {Function} setLoading call function to set loading
 * @param {Function} onFinish callback function after finished
 * @param {Function} onError callback function after error occured
 * @returns
 */
export const getPreviewImageApi = async (
  id,
  sourceName,
  setLoading,
  onFinish
) => {
  setLoading(true);
  return axios
    .get(
      `${
        config.SCAS_API_URL
      }/ontology/image/svg/${id}?sourceName=${sourceName}&${getImageUrlParams()}`,
      "",
      {
        headers: {
          accept: "image/*",
          "Content-Type": "*/*",
        },
      }
    )
    .then((res) => {
      onFinish(res.data);
    })
    .finally(() => {
      setLoading(false);
    });
};

export const getBuildId = async (setFn) => {
  return axios.get(`${config.SRD_API_URL}/buildId`).then((response) => {
    setFn(response?.data);
  });
};

export const getAssociates = async (instanceId, sourceName, setFn) => {
  return axios
    .get(
      `${config.SRD_API_URL}/instanceAssociates?instanceId=${instanceId}&source%20name=${sourceName}`
    )
    .then((response) => {
      setFn(response?.data);
    });
};
