import { InfoCircleTwoTone } from "@ant-design/icons";
import { Tooltip } from "antd";
import React, { useRef } from "react";
import TableForm from "../../TableWidget/TableForm";

const RowTableWidget = ({
  info = "",
  addMode,
  index,
  field,
  isEditable,
  isAccessible,
}) => {
  const formRef = useRef();

  // If value is not editable and value is empty, return null.
  if (!isEditable) {
    return null;
  }

  if (!isAccessible) {
    return null;
  }

  return (
    <div>
      {addMode ? (
        <div className={"card-item"} key={`editable-input-${index}`}>
          <div className="card-item-field" style={{ marginRight: "10px" }}>
            <span>{field}</span>
            {info.length ? (
              <Tooltip title={info}>
                <InfoCircleTwoTone className="gx-ml-2 gx-pointer" />
              </Tooltip>
            ) : null}
          </div>
          <div className="card-item-value full-width">
            <TableForm ref={formRef} tableColumns={[]} data={[]} />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default RowTableWidget;
