import _ from "lodash";
import React from "react";
import PT from "prop-types";

import { Form, Select } from "antd";
import { useSelector } from "react-redux";
import { getParent } from "../../../../../util/Widget";

const Quality = ({ option, isEdit, onChangeQuality, defaultValue }) => {
  const pageId =
    _.get(option, "instanceId") ||
    _.get(option, "compoundId") ||
    _.get(option, "id");

  const { [getParent(pageId)]: data = {} } = useSelector(
    ({ lookup }) => lookup
  );

  const { compoundInstances = [] } = data || {};

  const options = compoundInstances.map((item) => ({
    name: item?.name,
    value: item?.name,
  }));

  return (
    <div>
      {isEdit ? (
        <Form.Item className="table-form-item">
          <Select
            size="small"
            options={options}
            onChange={onChangeQuality}
            defaultValue={defaultValue}
          ></Select>
        </Form.Item>
      ) : null}
    </div>
  );
};

Quality.defaultProps = {
  option: {},
  isEdit: false,
  pageType: "",
  defaultValue: "",
};

Quality.propTypes = {
  option: PT.shape(),
  isEdit: PT.bool,
  defaultValue: PT.string,
  onChangeQuality: PT.func.isRequired,
};

export default Quality;
