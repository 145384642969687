/* eslint-disable react-hooks/exhaustive-deps */
import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { DELAY_TO_LOAD_CANVAS } from "../../constants/Config";

const loadKetcherSingleton = () => {
  var ketcherFrame = document.getElementById("ifKetcher");

  try {
    window.ketcher = ketcherFrame.contentWindow.ketcher;
  } catch (error) {
    window.ketcher = (document.frames || {})["ifKetcher"]?.window?.ketcher;
  }
};

const KetcherEditor = forwardRef((props, ref) => {
  useEffect(() => {
    const onPageLoad = () => {
      setTimeout(() => {
        loadKetcherSingleton();
      }, DELAY_TO_LOAD_CANVAS);
    };

    // Check if the page has already loaded
    if (document.readyState === "complete") {
      onPageLoad();
    } else {
      window.addEventListener("load", onPageLoad);
      // Remove the event listener when component unmounts
      return () => window.removeEventListener("load", onPageLoad);
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (window.ketcher) {
        window.ketcher.editor.subscribe("change", (e) => {
          props.canvasChanged();
        });
      }
    }, DELAY_TO_LOAD_CANVAS);

    return () => {
      window.ketcher?.editor?.unsubscribe();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    getDrawing(isMol = false) {
      if (window.ketcher) {
        if (isMol) {
          window.ketcher?.getMolfile().then((res) => {
            props.setDrawing(res);
          });
        } else {
          window.ketcher?.getSmiles().then((res) => {
            props.setDrawing(res);
          });
        }
      }
    },
    getMol() {
      if (window.ketcher) {
        window.ketcher?.getMolfile().then((res) => {
          props.setDrawing(res);
        });
      }
    },
    loadMOL(target) {
      if (window.ketcher) {
        window.ketcher?.setMolecule(target);
      }
    },
    getSmiles(callbackFn) {
      if (window.ketcher) {
        return window.ketcher.getSmiles().then((res) => {
          callbackFn(res);
        });
      }
    },
    getRXN() {},
    getCDXML() {
      if (window.ketcher) {
        return window.ketcher?.getSmiles();
      }
    },
    checkInstance() {
      return window.ketcher;
    },
    writeToCanvas(target) {
      if (target) {
      }
    },
    loadCDXML(target) {
      if (target) {
        return window.ketcher?.setMolecule(target);
      }
    },
    isBlankStructure() {
      return true;
    },
    getImageUrl() {},
  }));

  return (
    <iframe
      title="Ketcher"
      id="ifKetcher"
      src="/ketcher/index.html"
      width="800"
      height="600"
    ></iframe>
  );
});

export default KetcherEditor;
