import React from "react";

import { Button, Form, Radio } from "antd";
import PT from "prop-types";

const PageSettingsForm = ({ formRef, pageSettings, savePageSettings }) => {
  return (
    <>
      <Form className="gx-p-2" ref={formRef}>
        <Form.Item name="layout-settings" label="Layout Type">
          <Radio.Group
            size="small"
            className="gx-mt-1"
            defaultValue={pageSettings?.layout || "static"}
          >
            <Radio.Button value="static" checked={[true]}>
              Static
            </Radio.Button>
            <Radio.Button value="dynamic">Dynamic</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="hide-info-settings" label="Hide Info">
          <Radio.Group
            size="small"
            className="gx-mt-1"
            defaultValue={pageSettings?.hideInfo || "show"}
          >
            <Radio.Button value="hide" checked={[true]}>
              Hide
            </Radio.Button>
            <Radio.Button value="show">Show</Radio.Button>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="training-settings" label="Display Tranining">
          <Radio.Group
            size="small"
            className="gx-mt-1"
            defaultValue={pageSettings?.showTraining || "show"}
          >
            <Radio.Button value="hide" checked={[true]}>
              Hide
            </Radio.Button>
            <Radio.Button value="show">Show</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" size="small" onClick={savePageSettings}>
            Save
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

PageSettingsForm.defaultProps = {
  pageSettings: {},
};

PageSettingsForm.propTypes = {
  formRef: PT.oneOfType([PT.func, PT.shape({ current: PT.any })]).isRequired,
  pageSettings: PT.shape(),
};

export default PageSettingsForm;
