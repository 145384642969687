import { Descriptions } from "antd";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { v4 as uuidv4 } from "uuid";
import { fetchDocumentApi } from "../../../../../appRedux/sagas/Document";
import { downloadDocument } from "../../../../../appRedux/services/Document";

const CurrentSubsetDocument = ({
  documentId,
  sourceName,
  hasDownloadAccess = false,
  form,
}) => {
  const [documentDetail, setDocumentDetail] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (_.isEmpty(documentDetail) && documentId && !isLoaded) {
      setIsLoaded(true);
      fetchDocumentApi({
        documentId,
      }).then((res) => {
        if (res && res.data) {
          setDocumentDetail(res.data);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const name =
    _.get(documentDetail, "name") || _.get(documentDetail, "fileName");

  return (
    <>
      <div
        style={{
          marginLeft: "10px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <Descriptions.Item
          key={uuidv4()}
          label={null}
          style={{ fontSize: "10px", marginLeft: "10px" }}
        >
          {hasDownloadAccess ? (
            <Link
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
                downloadDocument(
                  documentDetail?.id,
                  sourceName,
                  documentDetail?.fileName || "externalDocument"
                );
              }}
            >
              {name || "name not found"}
            </Link>
          ) : (
            <span> {name || "name not found"}</span>
          )}
        </Descriptions.Item>
      </div>
    </>
  );
};

export default CurrentSubsetDocument;
