/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import Header from "../Widget/Header";

import "./style.css";

const ProjectSettingsWidget = ({
  id,
  title,
  hasDeleteButton,
  onDelete,
  widgetName,
  sourceName,
}) => {
  const [open, setOpen] = useState(true);

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 ${
        open ? "isOpen" : ""
      }
  `}
      key={uuidv4()}
    >
      <Header
        title={title}
        open={open}
        setOpen={setOpen}
        hasDeleteButton={hasDeleteButton}
        onDelete={onDelete}
      />

      {open && <span>TODO</span>}
    </div>
  );
};

export default ProjectSettingsWidget;
