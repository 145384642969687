/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckSquareTwoTone,
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  FilterTwoTone,
  InfoCircleTwoTone,
  SaveTwoTone,
  UploadOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Drawer,
  Form,
  Input,
  Popconfirm,
  Popover,
  Select,
  Table,
  Tag,
  Tooltip,
  Upload,
} from "antd";
import CircularProgress from "components/CircularProgress";
import _ from "lodash";
import PT from "prop-types";
import React, { useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as ExpandIcon } from "../../../assets/vendors/expand.svg";
import {
  ALLOWED_ENTITY_TYPES,
  ALL_PAGES_LIST,
  DOCUMENT_ROLES,
  EXCEL_TEMPLATE_SOURCE_NAME,
  TABLE_MAX_HEIGHT,
} from "../../../constants/Config";

import { NotificationManager } from "react-notifications";
import { uploadDocument } from "../../../appRedux/services/Document";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import EditableCell from "../../../components/EditableCell";
import { getFieldValueFromDisplayName } from "../../../util/Widget";
import { mockRequest } from "../../../util/api";
import { getRole } from "../../../util/auth";
import SystemWidgetSettings from "../SystemWidgetSettings";
import ExcelSettingsForm from "./ExcelSettingsForm";

const DocumentCollectionSettings = ({
  title,
  widgetName,
  loading,
  availableWidgets,
  onSave,
  disableEdit,
  onSaveSystemWidget,
  sources,
  excelTemplates,
  onUpdateDocumentations,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [editingKey, setEditingKey] = useState("");

  const isEditing = (record) => record.key === editingKey;
  const [editIndex, setEditIndex] = useState();
  const [acceptedFiles, setAcceptedFiles] = useState([]);
  const [showExcelSettings, setShowExcelSettings] = useState(false);
  const [templateForm] = Form.useForm();
  const templateFormRef = useRef();
  const [settingsDetailOpen, setSettingsDetailOpen] = useState(false);
  const [editInlineMode, setEditInlineMode] = useState(false);

  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [form] = Form.useForm();
  const [currentWidget, setCurrentWidget] = useState({});
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (!roles.length) {
      getSystemWidget(DOCUMENT_ROLES, setRoles);
    }
  }, []);

  const formRef = useRef();

  const onChangeValue = (field, value) => {
    form.setFieldsValue({
      [field]: value,
    });
  };

  const rolesOptions = roles.map((item) => ({
    label: item.displayName,
    value: item.id,
  }));

  const onRemove = (field) => {
    const newRoles = availableWidgets.filter((item) => item?.field !== field);
    onSave(widgetName, newRoles, true);
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }

      onSave(widgetName, newData);
    } catch (errInfo) {}
  };

  const onEditInline = () => {
    setEditInlineMode(true);
  };

  const renderDelete = (p, cWidget, index) => {
    const {
      key,
      field,
      value,
      allowedEntityTypes,
      width,
      sourcesBelongsTo,
      defaultPageTypes,
      allowedRoles,
      userRoles,
      template,
      summaryField,
    } = cWidget;

    const editable = isEditing(cWidget);

    return (
      <>
        {editable ? (
          <>
            <Popover content="Save document">
              <SaveTwoTone
                twoToneColor="#52c41a"
                className="gx-pointer"
                onClick={() => save(cWidget.key)}
              />
            </Popover>

            <Popconfirm
              title="Sure to cancel?"
              onConfirm={() => setEditingKey("")}
            >
              <CloseCircleTwoTone className="gx-pl-2" twoToneColor="#a94442" />
            </Popconfirm>
            {!disableEdit && (
              <Popconfirm
                title="Are you sure to delete this widget?"
                onConfirm={() => onRemove(field, index)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteTwoTone
                  className="gx-pointer gx-pl-2"
                  twoToneColor="#f44336"
                />
              </Popconfirm>
            )}
          </>
        ) : (
          <>
            <Popover content="Edit document">
              <EditTwoTone
                twoToneColor="#038FDE"
                onClick={() => {
                  form.setFieldsValue({
                    name: "",
                    age: "",
                    address: "",
                    sourcesBelongsTo: "",
                    defaultPageTypes: "",
                    allowedRoles: "",
                    allowedPageType: "",
                    ...cWidget,
                  });
                  setEditingKey(cWidget.key);
                  onEditInline();
                  setEditIndex(index);
                }}
                className="gx-mr-3"
              />
            </Popover>

            <Popover content="Show detail">
              <ExpandIcon
                className="expand-icon"
                onClick={() => {
                  setCurrentWidget(cWidget);
                  setSettingsDetailOpen(true);
                }}
              />
            </Popover>
          </>
        )}
      </>
    );
  };

  const renderallowedEntityTypes = (p, { allowedEntityTypes }) => {
    return _.map(allowedEntityTypes, (source) => {
      return (
        <Tag key={uuidv4()} color="blue">
          {source}
        </Tag>
      );
    });
  };

  const renderSourcesBelongsTo = (p, { sourcesBelongsTo }) => {
    return _.map(sourcesBelongsTo, (source) => {
      return (
        <Tag key={uuidv4()} color="blue">
          {source}
        </Tag>
      );
    });
  };

  const renderDefaultPageTypes = (p, { defaultPageTypes }) => {
    return _.map(defaultPageTypes, (source) => {
      return (
        <Tag key={uuidv4()} color="blue">
          {source}
        </Tag>
      );
    });
  };

  const renderUserRoles = (p, { userRoles }) => {
    return _.map(userRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });
    // return (
    //   <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
    //     {(userRoles || []).join(",")}
    //   </div>
    // );
  };

  const renderDownloadAccessRoles = (p, { downloadAccessRoles }) => {
    return _.map(downloadAccessRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });
  };

  const renderViewAccessRoles = (p, { viewAccessRoles }) => {
    return _.map(viewAccessRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });

    // return (
    //   <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
    //     {(viewAccessRoles || []).join(",")}
    //   </div>
    // );
  };

  const renderAllowedRoles = (p, { allowedRoles }) => {
    return _.map(allowedRoles, (versionRole) => {
      const roleDisplayName = (_.find(roles, { id: versionRole }) || {})
        ?.displayName;

      return (
        <Tag key={uuidv4()} size="small" color="blue">
          {roleDisplayName}
        </Tag>
      );
    });
    // return (
    //   <div style={{ wordWrap: "break-word", wordBreak: "break-word" }}>
    //     {(allowedRoles || []).join(",")}
    //   </div>
    // );
  };

  const renderTemplate = (p, { template }) => {
    return <div>{template ? <CheckSquareTwoTone /> : null}</div>;
  };

  const sourceOptions = sources.map((source) => ({
    label: source?.sourceName,
    value: source?.sourceName,
  }));

  const onConfirmModal = () => {
    const field = formRef.current.getFieldValue("field");
    const value = formRef.current.getFieldValue("value");
    const allowedEntityTypes =
      formRef.current.getFieldValue("allowedEntityTypes");
    const width = formRef.current.getFieldValue("width");
    const sourcesBelongsTo = formRef.current.getFieldValue("sourcesBelongsTo");
    const defaultPageTypes = formRef.current.getFieldValue("defaultPageTypes");
    const templateInstance = formRef.current.getFieldInstance("template");
    const allowedRoles = formRef.current.getFieldValue("allowedRoles");
    const userRoles = formRef.current.getFieldValue("userRoles");
    const summaryField = formRef.current.getFieldValue("summaryField");

    const template = templateInstance?.state?.checked;

    const fieldIndex = _.findIndex(availableWidgets, { field });
    const valueIndex = _.findIndex(availableWidgets, { value });

    if (!field.length) {
      formRef.current.setFields([
        {
          name: "field",
          errors: ["Field cannot be empty"],
        },
      ]);
      return;
    }

    if (!value.length) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Value cannot be empty"],
        },
      ]);
      return;
    }

    if (valueIndex !== -1 && !isEditing) {
      formRef.current.setFields([
        {
          name: "value",
          errors: ["Widget is already added"],
        },
      ]);
      return;
    }

    const targetRole = {
      field,
      value,
      allowedEntityTypes,
      sourcesBelongsTo,
      defaultPageTypes,
      allowedRoles,
      userRoles,
      template,
      summaryField,
    };

    let newAvailableWidgets = availableWidgets;
    newAvailableWidgets.push(targetRole);

    onSave(widgetName, newAvailableWidgets);

    forceUpdate();
    setShowModal(false);
    setDataSource(newAvailableWidgets);
  };

  const addWidget = () => {
    setEditIndex(null);
    setShowModal(true);
    forceUpdate();

    setTimeout(() => {
      formRef.current.setFieldsValue({
        field: "",
        value: "",
        allowedEntityTypes: [],
        width: null,
      });
    }, 20);
  };

  const availableWidgetsWithKeys = availableWidgets.map((widget, index) => ({
    ...widget,
    key: index,
  }));

  const [dataSource, setDataSource] = useState(
    _.sortBy(availableWidgetsWithKeys, "field")
  );

  useEffect(() => {
    setDataSource(
      availableWidgets.map((widget, index) => ({
        ...widget,
        key: index,
      }))
    );
  }, [availableWidgets]);

  const uploadTemplate = (widget) => {
    const value = templateFormRef.current.getFieldValue("value");
    const topic = templateFormRef.current.getFieldValue("topic");

    const searchSubjects = [{ key: "topic", value: topic }];

    uploadDocument(
      "SC-1",
      "SRD",
      acceptedFiles,
      {
        entityIds: [],
        subset: value,
        collection: widget?.value,
        searchSubjects,
        mimeType: acceptedFiles[0]?.type,
        description: "template",
        name: acceptedFiles[0]?.name,
        owners: [getRole()],
      },
      () => {
        NotificationManager.success("Document has been added");
      },
      (e) => {
        NotificationManager.error(e);
      }
    );
  };

  const applyToAll = (targetField, ops) => {
    const newAvailableWidgets = _.map(dataSource, (ds) => {
      return {
        ...ds,
        [targetField]: ops,
      };
    });

    setDataSource(newAvailableWidgets);
    onSave(widgetName, newAvailableWidgets);
  };

  const renderRow = (currentWidget, index, indent, expanded) => {
    if (expanded) {
      if (currentWidget?.template) {
        return (
          <Form
            form={templateForm}
            ref={templateFormRef}
            name="template"
            className="gx-mb-4"
          >
            <Form.Item
              label="Value:"
              name="value"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select options={ALL_PAGES_LIST} />
            </Form.Item>
            <Form.Item
              label="Topic:"
              name="topic"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Enter Topic" />
            </Form.Item>
            <Form.Item
              label="Upload File:"
              name="file"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Upload
                name="file"
                type="file"
                customRequest={mockRequest}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setAcceptedFiles(
                      _.map(info.fileList, (item) => item?.originFileObj)
                    );
                  } else {
                    setAcceptedFiles([]);
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>
            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                size="small"
                onClick={() => uploadTemplate(currentWidget)}
              >
                Upload
              </Button>
            </Form.Item>
          </Form>
        );
      } else {
        return (
          <div className="gx-ml-5">
            <SystemWidgetSettings
              availableWidgets={availableWidgets}
              loading={loading}
              roles={roles}
              setData={() => {}}
              currentWidget={currentWidget}
              onSave={onSaveSystemWidget}
              widgetName={widgetName}
              closeDialog={() => setSettingsDetailOpen(false)}
            />
          </div>
        );
      }
    }
  };

  const renderTitle = ({ title, description }) => {
    return (
      <>
        <span className="gx-mr-2">{title}</span>
        <Tooltip placement="top" title={description}>
          <InfoCircleTwoTone size="small" className="gx-pointer" />
        </Tooltip>
      </>
    );
  };

  const databaseAccessRef = useRef();
  const allowedPageTypeRef = useRef();
  const defaultPageTypesRef = useRef();
  const editRolesRef = useRef();
  const downloadAccessRolesRef = useRef();
  const viewAccessRolesRef = useRef();
  const enableLockingRoles = useRef();

  const sourcesOptions = sources.map((source) => ({
    label: source?.sourceName,
    value: source?.sourceName,
  }));

  const columns = [
    {
      title: "Display Name",
      dataIndex: "field",
      width: "5%",
      editable: true,
    },
    {
      title: "Database Name",
      dataIndex: "value",
      width: "5%",
      editable: false,
    },
    {
      dataIndex: "allowedEntityTypes",
      width: "5%",
      editable: true,
      mode: "multiple",
      render: renderallowedEntityTypes,
      title: () => {
        return (
          <div>
            <span>Allowed Page Type</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={allowedPageTypeRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={ALLOWED_ENTITY_TYPES}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = allowedPageTypeRef.current.getFieldValue("options");
                applyToAll("allowedEntityTypes", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "defaultPageTypes",
      width: "5%",
      editable: true,
      render: renderDefaultPageTypes,
      mode: "multiple",
      required: true,
      title: () => {
        return (
          <div>
            <span>Default Page Types</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={defaultPageTypesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={ALLOWED_ENTITY_TYPES}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops =
                  defaultPageTypesRef.current.getFieldValue("options");
                applyToAll("defaultPageTypes", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "sourcesBelongsTo",
      width: "5%",
      editable: true,
      render: renderSourcesBelongsTo,
      mode: "multiple",
      required: true,
      title: () => {
        return (
          <div>
            <span>Database Access</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={databaseAccessRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={sourcesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = databaseAccessRef.current.getFieldValue("options");
                applyToAll("sourcesBelongsTo", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "userRoles",
      width: "5%",
      editable: true,
      mode: "multiple",
      render: renderUserRoles,
      title: () => {
        return (
          <div>
            <span>Edit Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={editRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = editRolesRef.current.getFieldValue("options");
                applyToAll("userRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "downloadAccessRoles",
      width: "5%",
      editable: true,
      mode: "multiple",
      render: renderDownloadAccessRoles,
      title: () => {
        return (
          <div>
            <span>Download Access Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={downloadAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops =
                  downloadAccessRolesRef.current.getFieldValue("options");
                applyToAll("downloadAccessRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "viewAccessRoles",
      width: "5%",
      editable: true,
      mode: "multiple",
      render: renderViewAccessRoles,
      title: () => {
        return (
          <div>
            <span>View Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={viewAccessRolesRef}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = viewAccessRolesRef.current.getFieldValue("options");
                applyToAll("viewAccessRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      dataIndex: "allowedRoles",
      width: "5%",
      editable: true,
      mode: "multiple",
      render: renderAllowedRoles,
      title: () => {
        return (
          <div>
            <span>Enable Locking Roles</span>
            <Popconfirm
              okText="Apply"
              title={
                <Form ref={enableLockingRoles}>
                  <Form.Item
                    label="Apply to all"
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    name="options"
                  >
                    <Select
                      options={rolesOptions}
                      allowClear
                      dropdownMatchSelectWidth
                      mode="multiple"
                    />
                  </Form.Item>
                </Form>
              }
              onConfirm={() => {
                const ops = enableLockingRoles.current.getFieldValue("options");
                applyToAll("allowedRoles", ops);
              }}
            >
              <FilterTwoTone className="gx-ml-1 gx-pointer" />
            </Popconfirm>
          </div>
        );
      },
    },
    {
      title: "Actions",
      width: "5%",
      editable: false,
      render: renderDelete,
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }

    let options = rolesOptions;

    let inputType = "text";
    switch (col.dataIndex) {
      case "width":
        inputType = "number";
        break;
      case "allowedPageType":
      case "allowedEntityTypes":
        inputType = "select";
        options = ALLOWED_ENTITY_TYPES;
        break;
      case "defaultPageTypes":
        inputType = "select";
        options = ALLOWED_ENTITY_TYPES;
        break;
      case "sourcesBelongsTo":
        inputType = "select";
        options = sourceOptions;
        break;
      case "roles":
        inputType = "select";
        options = rolesOptions;
        break;
      case "lockingRoles":
      case "allowedRoles":
      case "userRoles":
      case "viewAccessRoles":
      case "downloadAccessRoles":
        inputType = "select";
        options = rolesOptions;
        break;
      default:
        inputType = "text";
    }

    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options,
        mode: col.mode,
        required: col.required || true,
      }),
    };
  });

  return (
    <>
      <h3>{title}: </h3>
      {loading && <CircularProgress />}
      <Form form={form} className="gx-ml-3 gx-mt-3">
        <Form.Item>
          <Table
            pagination={false}
            dataSource={dataSource}
            columns={mergedColumns}
            size="small"
            scroll={{ y: TABLE_MAX_HEIGHT, x: true }}
            components={{
              body: {
                cell: EditableCell,
              },
            }}
          ></Table>
        </Form.Item>
      </Form>
      <Button
        type="primary"
        size="small"
        className="gx-mt-2"
        onClick={addWidget}
      >
        Add New Widget
      </Button>

      <Button
        type="secondary"
        size="small"
        className="gx-mt-2"
        onClick={() => setShowExcelSettings(true)}
      >
        Manage Excel Templates
      </Button>

      <Drawer
        title={`Create New Document Collection`}
        open={showModal}
        okText="Save"
        onClose={() => {
          setShowModal(false);
        }}
        onOk={onConfirmModal}
      >
        <div className="gx-ml-4">
          <Form
            name="id"
            initialValues={{ field: "", value: "", allowedEntityTypes: [] }}
            ref={formRef}
          >
            <Form.Item
              label="Display Name:"
              name="field"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Display name should not be empty",
                },
              ]}
            >
              <Input
                placeholder="Enter field"
                value={form?.field}
                disabled={disableEdit}
                onChange={(e) => {
                  onChangeValue("field", e.target.value);

                  formRef.current.setFields([
                    {
                      name: "value",
                      value: getFieldValueFromDisplayName(e.target.value),
                    },
                  ]);
                }}
              />
            </Form.Item>
            <Form.Item
              className="gx-mt-3"
              label="Widget Name:"
              name="value"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Widget Name should not be empty",
                },
              ]}
            >
              <Input
                placeholder="Enter value"
                name="value"
                value={form?.value}
                disabled
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Allowed Page Types:",
                description: "List of page types that can be displayed.",
              })}
              className="gx-mt-3"
              name="allowedEntityTypes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={ALLOWED_ENTITY_TYPES}
                mode="multiple"
                placeholder="Select Entity Types"
                value={form?.allowedEntityTypes}
                onChange={(e) => onChangeValue("allowedEntityTypes", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Default Page Types:",
                description: "Page types that should be displayed by default.",
              })}
              className="gx-mt-3"
              name="defaultPageTypes"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={ALLOWED_ENTITY_TYPES}
                mode="multiple"
                placeholder="Select Default Page Types"
                value={form?.defaultPageTypes}
                onChange={(e) => onChangeValue("defaultPageTypes", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Edit Roles:",
                description:
                  "Allowed user roles to enable delete and edit files.",
              })}
              className="gx-mt-3"
              name="userRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={rolesOptions}
                mode="multiple"
                placeholder="Select User Role"
                value={form?.userRoles}
                onChange={(e) => onChangeValue("userRoles", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Download Access Roles:",
                description:
                  "Allowed user roles see the document detail and download the file.",
              })}
              className="gx-mt-3"
              name="downloadAccessRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={rolesOptions}
                mode="multiple"
                placeholder="Select User Role"
                value={form?.downloadAccessRoles}
                onChange={(e) => onChangeValue("downloadAccessRoles", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "View Access Roles:",
                description: "Allowed user roles see only the document detail.",
              })}
              className="gx-mt-3"
              name="viewAccessRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={rolesOptions}
                mode="multiple"
                placeholder="Select User Role"
                value={form?.viewAccessRoles}
                onChange={(e) => onChangeValue("viewAccessRoles", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Enable Locking Roles:",
                description:
                  "Allowed user roles to enable locking/unlocking functionality.",
              })}
              className="gx-mt-3"
              name="allowedRoles"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={rolesOptions}
                mode="multiple"
                placeholder="Select Role"
                value={form?.allowedRoles}
                onChange={(e) => onChangeValue("allowedRoles", e)}
              />
            </Form.Item>
            <Form.Item
              label={renderTitle({
                title: "Database access:",
                description: "Database access.",
              })}
              className="gx-mt-3"
              name="sourcesBelongsTo"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Select
                options={sourceOptions}
                mode="multiple"
                placeholder="Select Pages to apply"
                value={form?.sourcesBelongsTo}
                onChange={(e) => onChangeValue("sourcesBelongsTo", e)}
              />
            </Form.Item>
            {/* <Form.Item
              label="Summary Field:"
              className="gx-mt-3"
              name="summaryField"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                placeholder="Enter desired summary field name"
                name="value"
                value={form?.summaryField}
                onChange={(e) => onChangeValue("summaryField", e.target.value)}
              />
            </Form.Item> */}
            <Form.Item
              label="Template Widget:"
              className="gx-mt-3"
              name="template"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Checkbox
                value={form?.template}
                onChange={(e) => {
                  onChangeValue("template", e.target.checked);
                }}
              />
            </Form.Item>

            <Form.Item>
              <Button size="small" type="primary" onClick={onConfirmModal}>
                Save
              </Button>
              <Button size="small" onClick={() => setShowModal(false)}>
                Close
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>

      <Drawer
        placement="right"
        closable={false}
        onClose={() => setShowExcelSettings(false)}
        open={showExcelSettings}
        title="Manage Excel Template Settings"
      >
        <ExcelSettingsForm
          documents={excelTemplates}
          sourceName={EXCEL_TEMPLATE_SOURCE_NAME}
          onUpdateDocumentations={onUpdateDocumentations}
        />
      </Drawer>

      <Drawer
        placement="right"
        onClose={() => setSettingsDetailOpen(false)}
        open={settingsDetailOpen}
        width={900}
        className="settingsDrawer"
        destroyOnClose
        autoFocus
        title={`${currentWidget?.field} widget settings`}
      >
        <div className="gx-ml-5">
          <SystemWidgetSettings
            loading={loading}
            roles={roles}
            currentWidget={currentWidget}
            onSave={onSaveSystemWidget}
            widgetName={widgetName}
            availableWidgets={availableWidgets}
            closeDialog={() => setSettingsDetailOpen(false)}
          />
        </div>
      </Drawer>
    </>
  );
};

export default DocumentCollectionSettings;

DocumentCollectionSettings.defaultProps = {
  title: "",
  widgetName: "",
  availableWidgets: [],
  loading: false,
  disableEdit: false,
  onSaveSystemWidget: () => {},
};

DocumentCollectionSettings.propTypes = {
  title: PT.string,
  widgetName: PT.string,
  availableWidgets: PT.arrayOf(PT.shape()),
  loading: PT.bool,
  onSave: PT.func.isRequired,
  disableEdit: PT.bool,
  onSaveSystemWidget: PT.func,
};
