import { Switch, Table } from "antd";
import _ from "lodash";
import PT from "prop-types";
import React from "react";
import {
  EXPLORE_PAGE_MAIN_TAB_OPTIONS,
  EXPLORE_PAGE_TAB_LAYOUT,
} from "../../../constants/Config";

const accessIdentifier = "searchPageRoles";

const SearchPageTabSettings = ({ roles = [], loading, onSave, widgetName }) => {
  /**
   * Modify access array when switch input changes.
   * @param {*} pageName
   * @param {*} role
   * @param {*} checked
   * @returns
   */
  const onChangeAccess = (pageName, role, checked) => {
    if (loading) {
      return;
    }

    let accessArray = _.get(role, accessIdentifier) || [];

    if (checked) {
      accessArray = [...accessArray, pageName];
    } else {
      accessArray = accessArray.filter((arr) => arr !== pageName);
    }

    const newRole = {
      ...role,
      [accessIdentifier]: accessArray,
    };

    const newRoles = roles.map((item) => {
      if (item.id === newRole.id) {
        return newRole;
      } else {
        return item;
      }
    });

    onSave(newRoles, widgetName);
  };

  const dataSource = _.map(
    Object.entries(EXPLORE_PAGE_MAIN_TAB_OPTIONS),
    (item) => {
      const children = _.map(
        Object.entries(EXPLORE_PAGE_TAB_LAYOUT[item[0]] || {}),
        (child, index) => ({ key: index, label: child[1], value: child[0] })
      );
      return { key: item[0], value: item[0], label: item[1], children };
    }
  );

  return (
    <Table dataSource={dataSource}>
      <Table.Column
        title="Tab"
        key="tabName"
        dataIndex="value"
        render={(_p, { label }) => <span>{label}</span>}
      />
      <Table.Column
        title="Access"
        key="access"
        dataIndex="access"
        render={(_p, { value }) => (
          <div>
            {roles.map((role) => {
              const isAdmin = (role?.name || "").toLowerCase() === "admin";

              if (isAdmin) return null;

              const accessArray = _.get(role, accessIdentifier) || [];
              const isChecked = _.includes(accessArray, value);
              return (
                <div
                  key={role?.id}
                  className="gx-mt-1 gx-d-flex gx-justify-content-between gx-width-200"
                >
                  <span className="gx-mr-3">{role?.displayName}</span>
                  <Switch
                    size="small"
                    checked={isChecked}
                    onChange={(e) => onChangeAccess(value, role, e)}
                  />
                </div>
              );
            })}
          </div>
        )}
      />
    </Table>
  );
};

SearchPageTabSettings.defaultProps = {
  roles: [],
  loading: false,
  widgetName: "",
};

SearchPageTabSettings.propTypes = {
  roles: PT.arrayOf(PT.shape()),
  onSave: PT.func.isRequired,
  loading: PT.bool,
  widgetName: PT.string,
};

export default SearchPageTabSettings;
