import { CloudUploadOutlined } from "@ant-design/icons";
import React from "react";

import "./style.css";

const DropzoneInputWrapper = ({
  accept,
  onFiles,
  files = [],
  onChangeStatus,
  getFilesFromEvent,
}) => {
  const text =
    files.length > 0 ? "Add more files" : "Drop file(s) to attach, or";

  return (
    <div
      className="gx-d-flex dropzoneInputWrapper"
      style={{
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <label style={{ verticalAlign: "middle" }}>
        <CloudUploadOutlined className="gx-mr-3" />
        {text} <span className="gx-text-blue gx-pointer">browse</span>
        <input
          style={{ display: "none" }}
          type="file"
          accept={accept}
          multiple
          onChange={(e) => {
            getFilesFromEvent(e).then((chosenFiles) => {
              onFiles(chosenFiles);
            });
          }}
        />
      </label>
    </div>
  );
};

export default DropzoneInputWrapper;
