import React, { forwardRef, useImperativeHandle, useRef } from "react";

import PT from "prop-types";
import { Form, Table } from "antd";

import "./style.css";
import _ from "lodash";
import { renderTableFormColumns } from "../helper";
import { useDispatch } from "react-redux";

const TableForm = forwardRef((props, ref) => {
  const {
    id,
    sourceName,
    pageType,
    dataSources,
    onChangeCompoundId,
    onChangeForm,
    form,
    setForm,
    onOpenSearch,
  } = props;

  const formRef = useRef();

  const dispatch = useDispatch();

  let clonedForm = form;

  /**
   * Change form when input fields are updated.
   * @param {Number} index
   * @param {String} field
   * @param {String} currentRole
   */
  const onChangeItem = (index, field, currentRole) => {
    const newForm = _.map(clonedForm, (item) => {
      if (index !== item?.index) return item;
      return {
        ...item,
        [field]: currentRole,
      };
    });

    clonedForm = newForm;
    // setForm(newForm);
  };

  /**
   * Change composition's quality value when dropdown changes.
   * @param {Number} index
   * @param {Object} item
   */
  const onChangeQuality = (index, item) => {
    onChangeItem(index, "instanceId", item);
  };

  /**
   * Removes composition from composition table
   * @param {Number} index
   */
  const onRemoveComposition = (index) => {
    const newForm = _.filter(form, (p) => p?.index !== index);
    setForm(newForm);
  };

  useImperativeHandle(ref, () => ({
    getForm() {
      return clonedForm;
    },
  }));

  const columns = renderTableFormColumns({
    id,
    pageType,
    sourceName,
    onChangeQuality,
    onChangeItem,
    onRemoveComposition,
    dataSources,
    onChangeCompoundId,
    onChangeForm,
    dispatch,
    onOpenSearch,
  });

  return (
    <div className="gx-ml-3 gx-mr-3 gx-mt-3 gx-pb-3">
      <Form ref={formRef}>
        <Table
          className="gx-table-responsive register-process-table"
          dataSource={form}
          pagination={false}
          size="small"
          rowKey="index"
          columns={columns}
          scroll={{
            x: 600,
          }}
        />
      </Form>
    </div>
  );
});

TableForm.defaultProps = {
  id: "",
  sourceName: "",
  compositions: [],
};

TableForm.propTypes = {
  id: PT.string,
  sourceName: PT.string,
  compositions: PT.arrayOf(PT.shape()),
};

export default TableForm;
