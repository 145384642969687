import axios from "axios";
import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import {
  GET_PROCESS_WIDGETS_REQUESTED,
  GET_PROCESS_WIDGETS_SUCCESS,
  GET_SYSTEM_WIDGETS_REQUESTED,
  GET_SYSTEM_WIDGETS_SUCCESS,
} from "../../constants/ActionTypes";
import { config } from "../../constants/Config";

const systemWidgetRequest = async (widgetName) =>
  axios.get(`${config.SRD_API_URL}/metadata/system/${widgetName}`, {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });

const processWidgetRequest = async ({ widgetName, processId, sourceName }) =>
  axios.get(
    `${config.SRD_API_URL}/metadata/process/${widgetName}?processId=${processId}&sourceName=${sourceName}`,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }
  );

function* fetchSystemWidgets(action) {
  try {
    const result = yield call(systemWidgetRequest, action.payload);

    if (result.data) {
      yield put({
        type: GET_SYSTEM_WIDGETS_SUCCESS,
        payload: {
          name: result.data.name,
          parameters: result.data.parameters,
        },
      });
    }
  } catch (error) {}
}

function* fetchProcessWidgets(action) {
  try {
    const result = yield call(processWidgetRequest, action.payload);

    if (result?.data) {
      yield put({
        type: GET_PROCESS_WIDGETS_SUCCESS,
        payload: {
          name: result.data?.processId,
          parameters: result.data?.parameters?.cdxml,
        },
      });
    }
  } catch (error) {}
}

export function* getSystemWidgets() {
  yield takeLatest(GET_SYSTEM_WIDGETS_REQUESTED, fetchSystemWidgets);
}

export function* getProcessWidgets() {
  yield takeLatest(GET_PROCESS_WIDGETS_REQUESTED, fetchProcessWidgets);
}

export default function* rootSaga() {
  yield all([fork(getSystemWidgets), fork(getProcessWidgets)]);
}
