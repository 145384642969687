import React, { useEffect, useRef } from "react";
import { Button, Form, Input, Select } from "antd";
import PT from "prop-types";

const RolesForm = ({
  showModal,
  onFinish,
  groupsOptions,
  setShowModal,
  editMode = false,
  currentEditingObj = {},
}) => {
  const formRef = useRef();

  useEffect(() => {
    if (showModal) {
      if (editMode) {
        formRef.current.setFields([
          {
            name: "name",
            errors: [],
          },
          {
            name: "displayName",
            errors: [],
          },
          {
            name: "group",
            errors: [],
          },
        ]);

        formRef.current.setFieldsValue({
          name: currentEditingObj?.name,
          displayName: currentEditingObj?.displayName,
          group: currentEditingObj?.groupName,
        });
      } else {
        formRef.current.setFields([
          {
            name: "name",
            errors: [],
          },
          {
            name: "displayName",
            errors: [],
          },
          {
            name: "group",
            errors: [],
          },
        ]);

        formRef.current.setFieldsValue({
          name: "",
          displayName: "",
          group: null,
        });
      }
    }
  }, [
    currentEditingObj?.displayName,
    currentEditingObj.group,
    currentEditingObj?.groupName,
    currentEditingObj?.name,
    editMode,
    showModal,
  ]);

  return (
    <Form
      name="role"
      initialValues={{ name: "", displayName: "", group: null }}
      onFinish={(props) =>
        editMode
          ? onFinish({
              ...currentEditingObj,
              ...props,
            })
          : onFinish(props)
      }
      ref={formRef}
    >
      <Form.Item
        label="Name:"
        name="name"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Name should not be empty",
          },
        ]}
      >
        <Input placeholder="Enter role name" />
      </Form.Item>
      <Form.Item
        label="Display Name:"
        className="gx-mt-3"
        name="displayName"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Display Name should not be empty",
          },
        ]}
      >
        <Input placeholder="Enter role display name" />
      </Form.Item>
      <Form.Item
        label="Select Group:"
        className="gx-mt-3"
        name="group"
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        rules={[
          {
            required: true,
            message: "Group should not be empty",
          },
        ]}
      >
        <Select options={groupsOptions} />
      </Form.Item>
      <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
        <Button type="primary" htmlType="submit" size="small">
          Save
        </Button>
        <Button size="small" onClick={() => setShowModal(false)}>
          Close
        </Button>
      </Form.Item>
    </Form>
  );
};

RolesForm.defaultProps = {
  groupsOptions: [],
};

RolesForm.propTypes = {
  onFinish: PT.func.isRequired,
  groupsOptions: PT.arrayOf(PT.shape()),
};

export default RolesForm;
