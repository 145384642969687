import React from "react";
import PT from "prop-types";
import { v4 as uuidv4 } from "uuid";
import {
  AppstoreTwoTone,
  CopyTwoTone,
  DeleteTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import { PAGE_TYPES } from "../../../../constants/Config";
import { Popover } from "antd";

const CompoundInstanceHeader = ({
  id,
  title,
  open,
  setOpen,
  onAddInstance,
  onDelete,
  hasDeleteButton,
  loading,
  hasPermissionForAction,
  pageType,
  setShowModal,
  setShowTableModal,
  isSample = false,
  disableDetail = false,
}) => {
  return (
    <div className="ant-card-head widget-header" key={uuidv4()}>
      <div className="ant-card-head-wrapper gx-d-flex justify-content-between">
        <div className="ant-card-head-title">
          <span>{title}</span>
          {hasPermissionForAction && !loading && (
            <Popover
              content={`Create new ${
                isSample
                  ? "sample"
                  : pageType === PAGE_TYPES.COMPOUND ||
                    pageType === PAGE_TYPES.QUALITY
                  ? "quality"
                  : "experiment"
              }`}
            >
              <PlusCircleTwoTone
                className="gx-ml-2 gx-pointer"
                twoToneColor="#20c997"
                onClick={onAddInstance}
              />
            </Popover>
          )}
          {disableDetail ? null : (
            <AppstoreTwoTone
              className="gx-ml-2"
              onClick={() => setShowTableModal(true)}
            />
          )}

          {hasPermissionForAction &&
            pageType === PAGE_TYPES.EXPERIMENT &&
            !loading && (
              <Popover content={`Clone experiment ${id}`}>
                <CopyTwoTone
                  className="gx-ml-2 gx-pointer"
                  onClick={() => setShowModal(true)}
                />
              </Popover>
            )}
        </div>
        <div className={`ant-card-extra toggle-button`}>
          {hasDeleteButton && (
            <DeleteTwoTone twoToneColor="#ba141a" onClick={onDelete} />
          )}
          <i
            className={`icon gx-pl-2 ${
              open ? "icon-chevron-up" : "icon-chevron-down"
            }`}
            onClick={() => setOpen(!open)}
          />
        </div>
      </div>
    </div>
  );
};

CompoundInstanceHeader.defaultProps = {
  title: "",
  open: true,
  addMode: false,
  hasPermissionForAction: false,
};

CompoundInstanceHeader.propTypes = {
  title: PT.string,
  open: PT.bool,
  setOpen: PT.func.isRequired,
  onAddInstance: PT.func.isRequired,
  hasPermissionForAction: PT.bool,
};

export default CompoundInstanceHeader;
