import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AppModuleHeader = (props) => {
  const { currentProject, sourceName } = props;

  return (
    <div className="gx-module-box-header-inner">
      {!_.isEmpty(currentProject) && (
        <div className="gx-search-bar gx-lt-icon-search-bar-lg gx-module-search-bar gx-d-none gx-d-sm-block">
          <h5>
            Project ID:{" "}
            <Link
              className="gx-text-primary"
              target="_blank"
              to={`${sourceName}/project/${currentProject?.projectId}`}
            >
              {currentProject?.projectId}
            </Link>
          </h5>
          <h5>
            Project name: <span className="">{currentProject?.name}</span>
          </h5>
          <h6>Description: {currentProject?.description}</h6>
        </div>
      )}
      {/* <div className="gx-module-box-header-right gx-mr-5">
        {!_.isEmpty(currentProject) ? (
          <>
            {sortMode ? (
              <>
                <Popover content="Save Sorting">
                  <SaveTwoTone
                    className="gx-fs-lg gx-pointer"
                    onClick={onSaveSort}
                  />
                </Popover>
                <Popover content="Close">
                  <CloseCircleTwoTone
                    className="gx-fs-lg gx-ml-4"
                    onClick={() => setSortMode(false)}
                  />
                </Popover>
              </>
            ) : (
              <>
                <Popover content="Add Step Number">
                  <span
                    className="gx-fs-lg gx-mt-2 gx-pointer gx-text-primary"
                    onClick={onAddStepNo}
                  >
                    <PlusCircleOutlined />
                  </span>
                </Popover>

                <Popover content="Sort Process Steps">
                  <SlidersOutlined
                    className="gx-fs-lg gx-mt-2 gx-pointer gx-ml-3"
                    onClick={() => {
                      setSortMode(true);
                    }}
                  />
                </Popover>
              </>
            )}
          </>
        ) : null}
      </div> */}
    </div>
  );
};

export default AppModuleHeader;

AppModuleHeader.defaultProps = {
  styleName: "",
  value: "",
  notification: true,
  apps: true,
};
