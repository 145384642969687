/* eslint-disable react-hooks/exhaustive-deps */
import { Col, Divider, Form, Input, Select, Switch } from "antd";
import _ from "lodash";
import PT from "prop-types";
import React from "react";
import { DOCUMENT_COLLECTION_OPTIONS } from "../../../../../constants/Config";

const EditableForm = ({
  onChangeValue,
  form,
  roles,
  hideLimit = false,
  groupOptions = [],
  settingsWidgetName,
}) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.id) ? currentRole[item?.id] : false;
      role[item?.id] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  return (
    <div className="gx-ml-2">
      {!_.isEmpty(groupOptions) ? (
        <Col>
          <Form.Item
            label="Assign to group"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Select
              options={groupOptions}
              allowClear
              value={form?.groupId}
              onChange={(e) => onChangeValue("groupId", e)}
            />
          </Form.Item>
        </Col>
      ) : null}
      <Col>
        <Form.Item
          label="Display Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.field}
            onChange={(e) => onChangeValue("field", e.target.value)}
          />
        </Form.Item>
      </Col>
      <Col>
        <Form.Item
          label="Database Field Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.name}
            onChange={(e) => onChangeValue("name", e.target.value)}
          />
        </Form.Item>
      </Col>
      {!hideLimit && (
        <Col>
          <Form.Item
            label="Maximum length of characters"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={form?.limit}
              type="number"
              onChange={(e) => onChangeValue("limit", e.target.value)}
            />
          </Form.Item>
        </Col>
      )}

      <Col>
        <div className="gx-d-flex">
          <Form.Item
            label="Is Required Field"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={form?.required || false}
              onChange={(e) => onChangeValue("required", e)}
            />
          </Form.Item>
          <Form.Item
            label="Is Copiable"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isCopiable") || false}
              onChange={(e) => onChangeValue("isCopiable", e)}
            />
          </Form.Item>
          <Form.Item
            label="Display as one line"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={form?.isTrimmed || false}
              onChange={(e) => onChangeValue("isTrimmed", e)}
            />
          </Form.Item>
          <Form.Item
            label="Is Searchable"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isSearchable") || false}
              onChange={(e) => onChangeValue("isSearchable", e)}
            />
          </Form.Item>

          <Form.Item
            label="Has Tooltip"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={form?.hasTooltip}
              onChange={(e) => onChangeValue("hasTooltip", e)}
            />
          </Form.Item>

          {form?.hasTooltip ? (
            <Form.Item
              label="Tooltip"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input
                value={form?.info}
                onChange={(e) => onChangeValue("info", e.target.value)}
              />
            </Form.Item>
          ) : null}

          <Form.Item
            label="Is Unique"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={form?.isUnique}
              onChange={(e) => onChangeValue("isUnique", e)}
            />
          </Form.Item>

          {settingsWidgetName === DOCUMENT_COLLECTION_OPTIONS ? (
            <Form.Item
              label="Is Header Field"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form, "isInlineField") || false}
                onChange={(e) => onChangeValue("isInlineField", e)}
              />
            </Form.Item>
          ) : null}
        </div>
      </Col>

      <Divider className="gx-text-primary">Roles Settings</Divider>

      <div>
        <p className="gx-mb-3 gx-text-secondary">Edit Roles:</p>
        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isEditable, role?.id) || false}
                onChange={(e) => onChangeRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>

      <div>
        <p className="gx-mb-3 gx-text-secondary">Access Roles:</p>

        <Col className="gx-d-flex">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isAccessible, role?.id) || false}
                onChange={(e) => onChangeAccessRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>
    </div>
  );
};

EditableForm.defaultProps = {
  form: {},
};

EditableForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default EditableForm;
