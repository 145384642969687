import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import PT from "prop-types";
import { Button, Divider, Input, Popover, Table } from "antd";
import { DeleteTwoTone, MenuOutlined } from "@ant-design/icons";
import { CSS } from "@dnd-kit/utilities";
import {
  SortableContext,
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { DndContext } from "@dnd-kit/core";
import _ from "lodash";
import InputColor from "../../../components/InputColor";

const ManageWidgets = forwardRef((props, ref) => {
  const {
    currentWidgetName,
    widgets,
    onClose,
    onSaveSettings,
    setShowAddWidgetModal,
    onRemoveWidget,
    availableWidgets = [],
    searchWidgets = [],
  } = props;

  const [dataSource, setDataSource] = useState([]);

  let internalDataSource = dataSource;

  useEffect(() => {
    const widgetsWithId = _.map(widgets, (widget, key) => ({
      ...widget,
      widget: widget?.widget,
      width: widget?.width,
      titleColor: widget?.titleColor,
      backgroundColor: widget?.backgroundColor,
      maxHeight: widget?.maxHeight,
      key,
    }));

    setDataSource(widgetsWithId);
  }, [widgets]);

  useImperativeHandle(ref, () => ({
    saveSettings() {
      setDataSource(internalDataSource);
      onSaveSettings(dataSource);
    },
  }));

  /**
   * Update form from data source
   * @param {*} targetValue
   * @param {*} index
   */
  const updateForm = (targetValue, targetField, index) => {
    const updatedDataSource = _.map(dataSource, (item, itemIndex) => {
      if (itemIndex !== index) {
        return item;
      } else {
        return {
          ...item,
          [targetField]: targetValue,
        };
      }
    });

    internalDataSource = updatedDataSource;

    setDataSource(updatedDataSource);
  };

  const columns = [
    {
      key: "sort",
    },
    {
      title: "Widget Name",
      width: "20%",
      render: (p, item, index) => {
        const { widget } = item;

        const allWidgets = [...availableWidgets, ...searchWidgets];

        const cur = _.find(allWidgets, { value: widget }) || {};

        return (
          <div>
            <Popover
              placement="top"
              content={<span>{cur?.description || ""}</span>}
            >
              <span>{cur?.field || widget || ""}</span>
            </Popover>

            {/* <Popconfirm
              title="Are you sure to delete this widget?"
              onConfirm={() =>
                onRemoveWidget(dataSource, item, index, currentWidgetName)
              }
              okText="Yes"
              cancelText="No"
            > */}
            <DeleteTwoTone
              className="gx-ml-1 gx-pointer"
              twoToneColor="#ba141a"
              onClick={() =>
                onRemoveWidget(dataSource, item, index, currentWidgetName)
              }
            />
            {/* </Popconfirm> */}
          </div>
        );
      },
    },
    {
      title: "Database Name",
      dataIndex: "widget",
      key: "widget",
    },
    {
      title: "Width",
      width: "20%",
      render: (p, { width }, index) => {
        return (
          <Input
            type="number"
            defaultValue={width}
            max={12}
            min={1}
            onChange={(e) => updateForm(e?.target?.value, "width", index)}
          />
        );
      },
    },
    {
      title: "Title Color",
      width: "15%",
      render: (p, { titleColor }, index) => {
        return (
          <InputColor
            color={titleColor}
            onChange={(e) => updateForm(e?.color, "titleColor", index)}
            placeholder="no value"
          />
        );
      },
    },
    {
      title: "Background Color",
      width: "15%",
      render: (p, { backgroundColor }, index) => {
        return (
          <InputColor
            placeholder="no value"
            color={backgroundColor}
            onChange={(e) => {
              updateForm(e?.color, "backgroundColor", index);
            }}
          />
        );
      },
    },

    {
      title: "Height(px)",
      width: "20%",
      render: (p, { maxHeight }, index) => {
        return (
          <Input
            key={index}
            defaultValue={maxHeight}
            type="number"
            // defaultValue={_.isEmpty(maxHeight) ? "no value" : maxHeight}
            placeholder="no value"
            allowClear
            onBlur={(e) => {
              e.preventDefault();
              updateForm(e.target.value, "maxHeight", index);
            }}
          />
        );
      },
    },
  ];

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      )?.replace(/translate3d\(([^,]+),/, "translate3d(0,"),
      transition,
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr {...props} ref={setNodeRef} style={style} {...attributes}>
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <>
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={dataSource.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            className="gx-mt-3 gx-ml-3"
            bordered
            pagination={false}
            size="small"
            dataSource={dataSource}
            columns={columns}
          />
        </SortableContext>
      </DndContext>

      <Divider />

      <div>
        <Button
          size="small"
          type="primary"
          className="gx-mt-3 gx-ml-3"
          onClick={() => setShowAddWidgetModal(true)}
        >
          Add Widget
        </Button>
        <Button
          size="small"
          type="primary"
          className="gx-mt-3"
          onClick={() => onSaveSettings(dataSource)}
        >
          Save
        </Button>
        <Button
          size="small"
          type="secondary"
          className="gx-mt-3"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
    </>
  );
});

ManageWidgets.defaultProps = {
  widgets: [],
  showAddWidgetModal: false,
  currentWidgetName: "",
};

ManageWidgets.propTypes = {
  widgets: PT.arrayOf(PT.shape()),
  onClose: PT.func.isRequired,
  onSaveSettings: PT.func.isRequired,
  showAddWidgetModal: PT.bool,
  setShowAddWidgetModal: PT.func.isRequired,
  currentWidgetName: PT.string,
  onRemoveWidget: PT.func.isRequired,
};

export default ManageWidgets;
