/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Button, Form } from "antd";
import _ from "lodash";
import CircularProgress from "components/CircularProgress";
import PT from "prop-types";

import "./style.css";
import SystemWidgetForm from "./SystemWidgetForm";

const SystemWidgetSettings = ({
  setData,
  onSave,
  loading,
  roles,
  currentWidget,
  widgetName,
  closeDialog,
  allWidgets = [],
}) => {
  const [dataLoading, setDataLoading] = useState(false);

  const [widget, setWidget] = useState(_.get(currentWidget, "widget") || {});

  return (
    <>
      {dataLoading ? (
        <CircularProgress />
      ) : (
        <Form>
          <SystemWidgetForm
            widgetName={currentWidget?.value}
            widgetType={currentWidget?.widgetType || "default"}
            data={_.isEmpty(widget) ? [{ rows: [] }] : widget}
            onSave={onSave}
            roles={roles}
            onRowChange={(newRows) => {
              setWidget({
                ...widget,
                rows: newRows,
              });
            }}
            settingsWidgetName={widgetName}
            closeDialog={closeDialog}
            availableWidgets={allWidgets}
          />
        </Form>
      )}
    </>
  );
};

export default SystemWidgetSettings;

SystemWidgetSettings.defaultProps = {
  loading: false,
  roles: [],
  availableWidgets: [],
  currentWidget: {},
};

SystemWidgetSettings.propTypes = {
  setData: PT.func.isRequired,
  onSave: PT.func.isRequired,
  loading: PT.bool,
  roles: PT.arrayOf(PT.shape()),
  availableWidgets: PT.arrayOf(PT.shape()),
  currentWidget: PT.shape(),
};
