import { Skeleton } from "antd";
import _ from "lodash";
import React from "react";

const GhostWidget = ({ key }) => (
  <div
    key={key}
    className="widget-card ant-card ant-card-bordered gx-card-widget"
    style={{ width: "400px" }}
  >
    <div className="ant-card-head widget-header"></div>
    <div className="ant-card-body widget-body">
      <Skeleton active className="gx-mt-4" />
    </div>
  </div>
);

const WidgetPlaceHolder = () => {
  return (
    <div className="gx-d-flex" style={{ gap: "16px", flexWrap: "wrap" }}>
      {_.times(6, (index) => (
        <GhostWidget key={index} />
      ))}
    </div>
  );
};

export default WidgetPlaceHolder;
