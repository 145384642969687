import { Button, Divider, Table } from "antd";
import React, { useEffect, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  rectSortingStrategy,
  SortableContext,
  useSortable,
} from "@dnd-kit/sortable";
import _ from "lodash";
import { MenuOutlined } from "@ant-design/icons";

const SortableTable = ({
  options = [],
  onSaveSorting,
  onCancel,
  decomposeTable,
  disableDecompose = false,
}) => {
  const [form, setForm] = useState([]);

  useEffect(() => {
    const optionsWithId = _.map(options, (option, key) => ({
      ...option,
      id: option?.instanceId || option?.compoundId || option?.id,
      key,
    }));
    setForm(optionsWithId);
  }, [options]);

  const columns = [
    {
      key: "sort",
    },
    {
      title: "Instance Id",
      dataIndex: "id",
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (p, record) => {
        return (
          <span>{(record?.role || record?.type || "").replace("_", " ")}</span>
        );
      },
    },
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setForm((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const Row = ({ children, ...props }) => {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
      isDragging,
    } = useSortable({
      id: props["data-row-key"],
    });
    const style = {
      ...props.style,
      transform: CSS.Transform.toString(
        transform && {
          ...transform,
          scaleY: 1,
        }
      ),
      transition,
      cursor: "move",
      ...(isDragging
        ? {
            position: "relative",
            zIndex: 9999,
          }
        : {}),
    };
    return (
      <tr
        {...props}
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
      >
        {React.Children.map(children, (child) => {
          if (child.key === "sort") {
            return React.cloneElement(child, {
              children: (
                <MenuOutlined
                  ref={setActivatorNodeRef}
                  style={{
                    touchAction: "none",
                    cursor: "move",
                  }}
                  {...listeners}
                />
              ),
            });
          }
          return child;
        })}
      </tr>
    );
  };

  return (
    <div className="gx-mt-4">
      <DndContext onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={form.map((i) => i.key)}
          strategy={rectSortingStrategy}
        >
          <Table
            dataSource={form}
            pagination={false}
            bordered
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            columns={columns}
          />
        </SortableContext>
      </DndContext>

      <Divider />

      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <Button type="primary" size="small" onClick={() => onSaveSorting(form)}>
          Save
        </Button>
        <Button size="small" onClick={onCancel}>
          Cancel
        </Button>
        {!disableDecompose && (
          <Button
            type="primary"
            size="small"
            onClick={() => {
              decomposeTable();
              onCancel();
            }}
          >
            Decompose
          </Button>
        )}
      </div>
    </div>
  );
};

export default SortableTable;
