import React, {
  useState,
  useImperativeHandle,
  useRef,
  forwardRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { Input, Tag } from "antd";

import "./style.css";
import { Link } from "react-router-dom";
import _ from "lodash";

const InputTag = (props, ref) => {
  const [value, setValue] = useState(props.defaultValues || []);
  const [valueInput, setValueInput] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    setValue(props.defaultValues || []);
  }, [props.defaultValues]);

  /* Maximum number of tags can be added. Default is 5. */
  const {
    max = 30,
    disabled,
    sourceName,
    size = "medium",
    disableLink = false,
    onTagUpdated = () => {},
    placeholder = "",
  } = props;

  useImperativeHandle(ref, () => ({
    changeVal: () => {
      // If current input has value in it. Push it to current values array.
      if (!_.isEmpty(valueInput)) {
        return [...value, valueInput];
      } else {
        return value;
      }
    },
  }));

  useEffect(() => {
    if (props?.onChange) {
      props.onChange(value);
    }
  }, [props, value]);

  function pressEnter(e) {
    if (value.length < max && e.target.value) {
      setValue([...value, e.target.value]);
      setValueInput("");
    }
  }
  function preventDefault(str, e) {
    e.preventDefault();
    setValue(value.filter((item) => item !== str));
  }
  function focus() {
    inputRef.current && inputRef.current.focus();
  }

  function handleChange(e) {
    let elm = e.target;
    setValueInput(elm.value);
  }

  function keyDown(e) {
    if (e.keyCode === 8 && !valueInput) {
      setValue(
        value.filter(function (v, i, ar) {
          return i !== ar.length - 1;
        })
      );
    }

    if (e.keyCode === 13) {
      setTimeout(() => {
        onTagUpdated();
      }, 100);
    }
  }

  const onPaste = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const pastedTagsAsString = e.clipboardData.getData("text") || "";
    const tags = pastedTagsAsString.split(/\r?\n|\r|\n|:|;|,/g);

    setValueInput("");
    setValue([...value, ...tags]);

    if (props?.onTagUpdated) {
      props?.onTagUpdated(tags);
    }
  };

  return (
    <div>
      <div onClick={focus} className="wrap">
        <ul className="ulClass">
          {value &&
            value.map((item, index) => (
              <li key={index} style={{ float: "left", marginBottom: "8px" }}>
                <Tag closable onClose={(e) => preventDefault(item, e)}>
                  {disableLink ? (
                    <span>{item}</span>
                  ) : (
                    <Link
                      replace
                      to={`/${sourceName}/compound/${item}`}
                      target="_blank"
                    >
                      {item}
                    </Link>
                  )}
                </Tag>
              </li>
            ))}
          {value.length < max && (
            <li style={{ float: "left" }}>
              <Input
                disabled={disabled}
                onKeyDown={keyDown}
                ref={inputRef}
                value={valueInput}
                className="inputClass"
                onPressEnter={pressEnter}
                placeholder={_.isEmpty(value) ? placeholder : ""}
                onChange={handleChange}
                size={size}
                onPaste={onPaste}
              />
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

InputTag.propTypes = {
  value: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
};
InputTag.defaultProps = {
  value: [],
  disabled: false,
};

export default forwardRef(InputTag);
