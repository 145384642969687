/* eslint-disable no-unused-vars */
import React, { useEffect, useReducer, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import PT from "prop-types";
import Header from "../Widget/Header";
import {
  CloseCircleTwoTone,
  DeleteTwoTone,
  EditTwoTone,
  PlusCircleTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";

import "./style.css";
import _ from "lodash";
import { Popconfirm, Popover, Table, Tag } from "antd";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import {
  saveMetadataRequestApi,
  widgetMetadataRequestApi,
} from "../../../appRedux/services/Widget";
import TableForm from "./TableForm";
import { checkRowIsAccessible, getRole, getRoleId } from "../../../util/auth";
import Versioning from "../Widget/Versioning";
import { deNormalizeTableData, normalizeTableData } from "./helper";

const TableWidget = ({
  id,
  title,
  sourceName,
  widget,
  versionRoles,
  widgetOption,
  systemWidgetName,
}) => {
  const tableFormRef = useRef();

  const [open, setOpen] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [data, setData] = useState([]);
  const [p, forceUpdate] = useReducer((x) => x + 1, 0);
  const [widgetVersions, setWidgetVersions] = useState(null);
  const [currentWidgetVersion, setCurrentWidgetVersion] = useState();

  const { rows } = widget || {};

  const hasVersion = (versionRoles || []).includes(getRoleId());
  const titleColor = widgetOption?.titleColor || widget?.titleColor;
  let backgroundColor = widgetOption?.backgroundColor;
  const maxHeight = widgetOption?.maxHeight || widget?.maxHeight || null;

  useEffect(() => {
    if (_.isEmpty(data)) {
      widgetMetadataRequestApi(id, sourceName, systemWidgetName).then(
        (newData) => {
          const version = _.get(newData, "data[0].version");
          const dt = deNormalizeTableData(
            _.get(newData, "data[0].parameters") || [],
            widget
          );
          const rowsWithIndex = dt.map((row, index) => ({ ...row, index }));
          setData(rowsWithIndex);
          setWidgetVersions(version);
          setCurrentWidgetVersion(version);
        }
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateData = (cVersion = null) => {
    widgetMetadataRequestApi(
      id,
      sourceName,
      systemWidgetName,
      null,
      cVersion
    ).then((newData) => {
      const rows = _.get(newData, "data[0].parameters");
      const dt = deNormalizeTableData(rows, widget);
      const newVersion = _.get(newData, "data[0].version");
      setData(dt);
      setCurrentWidgetVersion(newVersion);
    });
  };

  const saveForm = () => {
    const form = tableFormRef.current.getForm();

    saveMetadataRequestApi(
      id,
      normalizeTableData(form, widget, widget),
      systemWidgetName,
      sourceName
    )
      .then((res) => {
        setCurrentWidgetVersion(_.get(res, "data.version"));
        setWidgetVersions(_.get(res, "data.version"));
        NotificationManager.success("Successfully saved data.");
        setEditMode(false);
      })
      .catch((e) => {
        NotificationManager.error(
          e?.response?.data?.message || "Error has occured."
        );
      });
  };

  const addNewRow = () => {
    const newData = data;
    const lastIndex = data.length;
    newData.push([{ index: lastIndex }]);
    setData(newData);
    forceUpdate();
  };

  const renderActionButtons = () => {
    return (
      <>
        {editMode ? (
          <>
            <Popover content="Add new row">
              <PlusCircleTwoTone onClick={addNewRow} />
            </Popover>

            <Popover content="Save table">
              <SaveTwoTone
                className="gx-ml-2"
                twoToneColor="#52c41a"
                onClick={saveForm}
              />
            </Popover>

            <Popover content="Close table">
              <CloseCircleTwoTone
                twoToneColor="#a94442"
                className="gx-ml-2"
                onClick={() => setEditMode(false)}
              />
            </Popover>
          </>
        ) : (
          <>
            <Popover content="Close table">
              <EditTwoTone
                twoToneColor="#038FDE"
                className="gx-ml-2"
                onClick={() => setEditMode(true)}
              />
            </Popover>
          </>
        )}
      </>
    );
  };

  const onDelete = (item, index) => {
    const newData = data.filter((item, i) => i !== index);
    saveMetadataRequestApi(
      id,
      normalizeTableData(newData, widget),
      systemWidgetName,
      sourceName
    ).then((res) => {
      if (res.data) {
        setCurrentWidgetVersion(_.get(res, "data.version"));
        setWidgetVersions(_.get(res, "data.version"));

        NotificationManager.success("Successfully deleted data.");
        updateData();
      }
    });
  };

  const filteredTableColumns = _.filter(rows, (row) => {
    return checkRowIsAccessible(row?.isAccessible);
  });

  const tableColumns = (filteredTableColumns || []).map((item) => ({
    key: item?.field,
    dataIndex: item?.field,
    title: item?.field,
    isEditable: item?.isEditable,
    rowType: item?.rowType,
    render: (p) => {
      if (item?.rowType === "LIST" && _.isArray(p)) {
        return (
          <div>
            {_.map(p, (tag) => (
              <Tag color="#2db7f5">{tag}</Tag>
            ))}
          </div>
        );
      }

      if (item?.rowType === "EDITABLE_DATE") {
        return <span>{p}</span>;
      }

      return <span>{p}</span>;
    },
  }));

  if (editMode) {
    tableColumns.push({
      key: "actions",
      title: "Action",
      render: (p, item, index) => {
        return (
          <>
            {editMode ? (
              <Popconfirm
                className="gx-ml-2"
                title="Are you sure to delete?"
                onConfirm={() => onDelete(item, index)}
                okText="Yes"
                cancelText="No"
              >
                <DeleteTwoTone
                  className="gx-mr-3 gx-pointer"
                  twoToneColor="#f44336"
                />
              </Popconfirm>
            ) : null}
          </>
        );
      },
    });
  }

  /**
   * If compound structure widget has versions and current version is not a last one, we should highlight header.
   */
  const isLastVersion = currentWidgetVersion === widgetVersions;

  if (currentWidgetVersion && widgetVersions && !isLastVersion) {
    backgroundColor = "#ffbb96";
  }

  return (
    <div
      className={`widget-card ant-card ant-card-bordered gx-card-widget gx-mr-3 double-width ${
        open ? "isOpen" : ""
      }
  `}
      key={uuidv4()}
    >
      <Header
        title={title}
        titleColor={titleColor}
        backgroundColor={backgroundColor}
        open={open}
        setOpen={setOpen}
        child={renderActionButtons()}
      />

      {open && (
        <div className="gx-m-2">
          <>
            {editMode ? (
              <TableForm
                ref={tableFormRef}
                tableColumns={tableColumns}
                data={data}
                sourceName={sourceName}
              />
            ) : (
              <Table
                size="small"
                columns={tableColumns}
                dataSource={data}
                pagination={false}
              />
            )}
          </>

          {hasVersion && widgetVersions ? (
            <div className="gx-ml-2 gx-mr-2 gx-mt-3">
              <Versioning
                currentVersion={currentWidgetVersion}
                versions={_.times(widgetVersions, (index) => index + 1)}
                renderRestore={null}
                onChangeVersion={(cVersion) => {
                  updateData(cVersion);
                }}
              />
            </div>
          ) : null}
        </div>
      )}
      <NotificationContainer />
    </div>
  );
};

TableWidget.defaultProps = {
  id: "",
  title: "Composition Table",
  components: [],
  sourceName: "",
  pageType: "",
};

TableWidget.propTypes = {
  id: PT.string,
  title: PT.string,
  components: PT.arrayOf(PT.shape()),
  sourceName: PT.string,
  pageType: PT.string,
  componentUpdated: PT.func.isRequired,
};

export default TableWidget;
