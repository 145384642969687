import {
  CloseCircleOutlined,
  EditTwoTone,
  PlusCircleTwoTone,
  SaveTwoTone,
} from "@ant-design/icons";
import { Popover } from "antd";
import React from "react";

const ActionButtons = ({
  hasAccess,
  onAddRow,
  onSave,
  editMode,
  setEditMode,
}) => {
  if (!hasAccess) return null;

  return (
    <>
      {editMode ? (
        <>
          <Popover content="Add row">
            <PlusCircleTwoTone className="gx-ml-2" onClick={onAddRow} />
          </Popover>
          <Popover content="Save experiment">
            <SaveTwoTone
              className="gx-ml-2"
              twoToneColor="#52c41a"
              onClick={onSave}
            />
          </Popover>
          <Popover content="Exit edit mode">
            <CloseCircleOutlined
              className="gx-ml-2 gx-text-danger"
              twoToneColor="#a94442"
              onClick={() => {
                setEditMode(false);
              }}
            />
          </Popover>
        </>
      ) : (
        <>
          <Popover content="Enter edit mode">
            <EditTwoTone
              className="gx-ml-2"
              twoToneColor="#52c41a"
              onClick={() => {
                setEditMode(true);
              }}
            />
          </Popover>
        </>
      )}
    </>
  );
};

export default ActionButtons;
