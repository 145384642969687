import axios from "axios";
import _ from "lodash";
import { config } from "../../constants/Config";

/**
 * Get compound concept
 * @param {String} id structure id
 * @param {String} sourceName source name
 * @param {*} setFn
 * @param {*} setError
 * @param {*} setLoading
 * @returns
 */
export const getCompoundConcept = async (
  id,
  sourceName,
  currentVersion,
  setFn,
  setError = () => {},
  setLoading = () => {},
  setLoadingFn = () => {}
) => {
  setLoading(true);
  setLoadingFn(true);

  let params = { id, "source name": sourceName };

  if (currentVersion) {
    params = {
      ...params,
      version: currentVersion,
    };
  }

  const queryParams = new URLSearchParams(params);

  return axios
    .get(`${config.SRD_API_URL}/compoundConcept?${queryParams}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((result) => {
      if (result.data) {
        setFn(result.data);
      }
    })
    .catch((e) => {
      setFn({
        compoundId: id,
      });
      if (e.response?.status === 404) {
        setError(e.response.data);
      }
    })
    .finally(() => {
      setLoading(false);
      setLoadingFn(false);
    });
};

export const getCompoundConceptForSpecificVersion = async (
  id,
  sourceName,
  currentVersion,
  setFn,
  setError = () => {},
  setLoading = () => {},
  setLoadingFn = () => {}
) => {
  setLoading(true);
  setLoadingFn(true);

  let params = { id, "source name": sourceName };

  if (currentVersion) {
    params = {
      ...params,
      version: currentVersion,
    };
  }

  const queryParams = new URLSearchParams(params);

  return axios
    .get(`${config.SRD_API_URL}/compoundConcept?${queryParams}`, {
      headers: {
        Accept: "application/json",
      },
    })
    .then((result) => {
      if (result.data) {
        setFn(result.data);
      }
    })
    .catch((e) => {
      setError(e);
    })
    .finally(() => {
      setLoading(false);
      setLoadingFn(false);
    });
};

/**
 * Get process detail
 * @param {*} id
 * @param {*} sourceName
 * @param {*} setFn
 * @param {*} setError
 * @param {*} setLoading
 * @param {*} pageType
 * @returns
 */
export const getProcessDetail = async (
  id,
  sourceName,
  setFn,
  setError = () => {},
  setLoading = () => {},
  pageType = "process"
) => {
  if (sourceName) {
    setLoading(true);
    return axios
      .get(
        `${config.SRD_API_URL}/process?id=${id}&source%20name=${sourceName}`,
        {
          headers: {
            Accept: "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data) {
          setFn(result.data);
        }
      })
      .catch((e) => {
        if (e.response?.status === 404) {
          setError(e.response.data);
        } else {
          setError("Page not found.");
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }
};

/**
 * Get Metadata API
 * @param {*} metadataName
 * @param {*} compoundId
 * @param {*} sourceName
 * @param {*} setFn
 * @param {*} setError
 * @returns
 */
export const getMedataApi = async (
  metadataName,
  compoundId,
  sourceName = "",
  setFn,
  setError = () => {}
) => {
  return axios
    .get(
      `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}&sourceName=${sourceName}`,
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "text/plain",
        },
      }
    )
    .then((res) => {
      if (res && res?.data && res?.data.length > 0) {
        setFn(res.data[0]);
      }
    })
    .catch(() => {
      setError({});
    });
};

/**
 * Save metadata api
 * @param {*} metadataName
 * @param {*} compoundId
 * @param {*} sourceName
 * @param {*} body
 * @param {*} setFn
 * @param {*} setError
 * @returns
 */
export const saveMedataApi = async (
  metadataName,
  compoundId,
  sourceName = "",
  body,
  setFn = () => {},
  setError = () => {}
) => {
  return axios
    .post(
      `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}&sourceName=${sourceName}`,
      {
        options: body,
      },
      {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((res) => {
      if (res && res?.data && res?.data.length > 0) {
        setFn(res.data[0]);
      }
    })
    .catch(() => {
      setError({});
    });
};

/**
 * get metadata batch api
 * @param {*} metadataName
 * @param {*} compoundIds
 * @param {*} sourceName
 * @param {*} setFn
 * @param {*} setError
 */
export const getMetadataBatchApi = async (
  metadataName,
  compoundIds,
  sourceName,
  setFn,
  setError = () => {}
) => {
  let promises = [];
  compoundIds.forEach((compoundId) => {
    promises.push(
      axios.get(
        `${config.SRD_API_URL}/metadata/${metadataName}?compoundId=${compoundId}&sourceName=${sourceName}`,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "text/plain",
          },
        }
      )
    );
  });

  Promise.allSettled(promises)
    .then((res) => {
      const fulfilledResults = res
        .filter((item) => item.status === "fulfilled")
        .map((item) => {
          return item?.value?.data && item?.value?.data.length > 0
            ? item?.value?.data[0]
            : null;
        });

      const items = fulfilledResults.map((currentData) => {
        return (currentData || {})?.parameters?.data || [];
      });

      const body = {
        parameters: {
          data: _.flattenDeep(items),
        },
      };

      setFn(body);
    })
    .catch(() => {
      setError({});
    });
};
