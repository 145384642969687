/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Form } from "antd";
import _ from "lodash";
import PT from "prop-types";

import SearchRowSettingsForm from "../SearchRowSettingsForm";

const SearchRowSettings = ({
  onSave,
  loading,
  roles,
  currentWidget,
  widgetName,
  availableWidgetParams,
  allWidgets,
}) => {
  const [widget, setWidget] = useState({});

  useEffect(() => {
    if (currentWidget && currentWidget?.widget) {
      setWidget(currentWidget?.widget);
    } else {
      setWidget({});
    }
  }, [currentWidget]);

  return (
    <Form>
      <SearchRowSettingsForm
        widgetName={currentWidget?.value}
        data={_.isEmpty(widget) ? [{ rows: [] }] : widget}
        onSave={onSave}
        roles={roles}
        onRowChange={(newRows) => {
          setWidget({
            ...widget,
            rows: newRows,
          });
        }}
        settingsWidgetName={widgetName}
        availableWidgetParams={availableWidgetParams}
        allWidgets={allWidgets}
      />
    </Form>
  );
};

export default SearchRowSettings;

SearchRowSettings.defaultProps = {
  loading: false,
  roles: [],
  availableWidgets: [],
  currentWidget: {},
};

SearchRowSettings.propTypes = {
  setData: PT.func.isRequired,
  onSave: PT.func.isRequired,
  loading: PT.bool,
  roles: PT.arrayOf(PT.shape()),
  availableWidgets: PT.arrayOf(PT.shape()),
  currentWidget: PT.shape(),
};
