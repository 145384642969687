/* eslint-disable eqeqeq */
import { Button } from "antd";
import _ from "lodash";
import { NotificationManager } from "react-notifications";
import { Link } from "react-router-dom";
import { CONTACT_EMAIL } from "../constants/Config";
import { getRoleId } from "./auth";

/**
 * Try to get value defined from system widget.
 * We expect `targetObj` and `value` are available from parent.
 *
 * @param {String} widgetData widget data
 * @param {String} targetObj target object from system widget
 * @param {String} value value of target target object
 */
export const getValue = (widgetData, targetObj, value) => {
  const targetData = _.get(widgetData, targetObj);
  const data = _.get(targetData, value);

  return data;
};

/**
 * Check if component is child
 *
 * @param {String} pageType page Type
 */
export const isChildComponent = (pageType) =>
  pageType === "quality" || pageType === "experiment";

/**
 * Check if current id is process component
 *
 * @param {String} id component id
 */
export const checkIsProcess = (id) => (id || "").startsWith("RXN");

/**
 * Get id for compound name
 *
 * @param {String} id component id
 * @param {String} pageType page Type
 */
export const getId = (id, pageType) => {
  if (isChildComponent(pageType)) {
    const parentId = id.split(".")[0];
    return parentId;
  }
  return id;
};

/**
 * Get Parent URL If it is child component
 *
 * @param {String} id component id
 */
export const getParent = (id = "") => {
  const splitted = id.split("-");
  if (splitted.length > 1) {
    const prefix = splitted[0];
    const linkId = splitted[1] || "";
    if (linkId.includes(".")) {
      const parent = linkId.split(".")[0];
      return `${prefix}-${parent}`;
    } else {
      return id;
    }
  } else {
    return id;
  }
};

/**
 * Check if given widget data is editable
 *
 * @param {Array} row rows
 */
export const checkFormEditable = (rows) => {
  let isFormEditable = false;
  rows.forEach((row) => {
    const role = getRoleId();
    const currentAccess = _.get(row?.isEditable, role);
    if (
      (row.type === "EDITABLE" ||
        row.type === "EDITABLE_DATE" ||
        row.type === "LIST") &&
      currentAccess
    ) {
      isFormEditable = true;
    }
  });

  return isFormEditable;
};

export const getPreviousLink = (id, navigationMapping = []) => {
  const targetItem = _.findIndex(navigationMapping, (item) => id == item);
  const prefix = id.split("-")[0];
  const linkId = id.split("-")[1] || "";

  if (targetItem !== -1 && targetItem > 0) {
    return navigationMapping[targetItem - 1];
  }

  if (linkId.includes(".")) {
    const parent = linkId.split(".")[0];
    const child = Number(linkId.split(".")[1]);
    return `${prefix}-${parent}.${child > 1 ? child - 1 : child}`;
  } else {
    return `${prefix}-${
      Number(linkId) > 1 ? Number(linkId) - 1 : Number(linkId)
    }`;
  }
};

export const getNextLink = (id, navigationMapping = []) => {
  const targetItem = _.findIndex(navigationMapping, (item) => id == item);
  const prefix = id.split("-")[0];
  const linkId = id.split("-")[1] || "";

  if (targetItem !== -1 && targetItem !== navigationMapping.length - 1) {
    return navigationMapping[targetItem + 1];
  }

  if (linkId.includes(".")) {
    const parent = linkId.split(".")[0];
    const child = Number(linkId.split(".")[1]);
    return `${prefix}-${parent}.${child + 1}`;
  } else {
    return `${prefix}-${Number(linkId) + 1}`;
  }
};

export const getOriginal = (child) => {
  const parent = child.split(".");
  if (parent.length > 0) {
    return parent[0];
  }
  return child;
};

export const getShortIdFromPage = (pageId) => {
  const id = pageId.split("-");
  if (id.length > 1) {
    return id[1];
  }

  if (id.length > 0) {
    return id[0];
  }

  return pageId;
};

export const parseItems = (items) => {
  try {
    return JSON.parse(items);
  } catch (error) {
    return items;
  }
};

export const parseSubstance = (substance, prefix) => {
  const splitted = substance.split(".");

  if (splitted.length > 1 && splitted[1] === "0") {
    const substanceNumber = splitted[0];
    return `${prefix}-${substanceNumber}`;
  } else {
    return `${prefix}-${substance}`;
  }
};

export const getPrefix = (dataSources, selectedSource) => {
  const targetSource = _.find(dataSources, { sourceName: selectedSource });

  if (targetSource?.compoundFormat) {
    return targetSource?.compoundFormat.split("-")[0];
  }

  return "";
};

export const getProcessPrefix = (dataSources, selectedSource) => {
  const targetSource = _.find(dataSources, { sourceName: selectedSource });

  if (targetSource?.processFormat) {
    return targetSource?.processFormat.split("-")[0];
  }

  return "";
};

export const getFieldValueFromDisplayName = (displayName) => {
  const val = _.startCase(displayName || "");
  return val.replace(/\s/g, "");
};

export const getNotFoundCard = ({
  title = "Not Found.",
  pageType,
  renderContact = false,
}) => {
  return (
    <div className="gx-page-error-container">
      <div className="gx-d-flex gx-flex-column gx-text-center">
        <span>
          This {pageType} {title}
        </span>

        {renderContact && (
          <>
            <a href={CONTACT_EMAIL} className="gx-text-primary gx-mb-4">
              Contact Administrator.
            </a>
          </>
        )}

        {!renderContact && (
          <div className="gx-mt-2">
            <Button size="small" className="gx-text-center" type="secondary">
              <Link to={`/home`}>Go to Home</Link>
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

/**
 * Check Field if it's exists.
 * @param {*} value
 * @param {*} field
 * @returns
 */
export const checkField = (value, field) => {
  return (field || "")
    .toString()
    .toLowerCase()
    .includes((value || "").toLowerCase());
};

export const addParametersToDocument = (documents) => {
  return _.map(documents || [], (currentDocument) => {
    let parameters = {
      description: _.get(currentDocument, "description"),
      entityIds: (_.get(currentDocument, "entityIds") || []).join(","),
    };

    _.forEach(_.get(currentDocument, "searchSubjects") || [], (item) => {
      try {
        const parsedObj = JSON.parse(item?.value);
        if (_.isArray(parsedObj)) {
          // remove extra data from abstract table.
          const mappedValues = _.map(parsedObj, (item) => {
            if (_.get(item, "Abstract")) {
              return item["Abstract"];
            } else {
              return item;
            }
          });
          parameters[item?.key] = mappedValues.join(",");
        } else {
          if (!_.isEmpty(parameters[item?.key])) {
            parameters[item?.key] = `${parameters[item?.key]},${item?.value}`;
          } else {
            parameters[item?.key] = item?.value;
          }
        }
      } catch (error) {
        if (!_.isEmpty(parameters[item?.key])) {
          parameters[item?.key] = `${parameters[item?.key]},${item?.value}`;
        } else {
          parameters[item?.key] = item?.value;
        }
      }
    });

    return {
      ...currentDocument,
      parameters,
    };
  });
};

export function convertMolecularFormulaToHTMLSubscript(molecularFormula) {
  // Regular expression to find numbers in the formula
  const numberRegex = /(?<=[A-Za-z])\d+/g;

  // Replace the numbers with HTML subscript tags
  const formulaWithSubscript = molecularFormula.replace(
    numberRegex,
    (match) => {
      return `<sub>${match}</sub>`;
    },
  );

  return formulaWithSubscript;
}

export const checkFormValidation = (rows = [], form = {}) => {
  console.log(rows, form);

  let valid = true;
  _.forEach(rows, (row) => {
    if (row?.required && _.isEmpty(_.get(form, row?.name))) {
      NotificationManager.error(
        "Incomplete form: Enter data for mandatory fields.",
      );
      valid = false;
    }

    if (row?.isUnique && !_.isEmpty(_.get(form, `${row?.name}__unique`))) {
      NotificationManager.error(`${row?.name} is not unique.`);
      valid = false;
    }
  });

  return valid;
};
