import axios from "axios";
import _ from "lodash";
import { config } from "../../constants/Config";
import { getImageUrlParams } from "../../util/url";

export const searchForCompound = async (form, loadingFn, setFn) => {
  const { queryStructure, cutoff, databases, searchOps, structureTypes } = form;

  loadingFn(true);

  const payload = {
    queryStructure,
    databases,
    structureTypes,
    cutoff,
  };

  if (searchOps) {
    return axios
      .post(
        `${config.SCAS_API_URL}/ontology/structure/${searchOps}?limit=-1&timeout=-1&skipSource=false&name=false&async=false`,
        _.omitBy(payload, _.isNull),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => {
        if (result.data) {
          const searchResults = result.data.filter(
            (item) => item.rmoId && item.source
          );

          setFn(searchResults);
        }
      })
      .finally(() => {
        loadingFn(false);
      });
  } else {
    // wait for initialize chemdraw editor
    setTimeout(() => {
      loadingFn(false);
      setFn([]);
    }, 2000);
  }
};

export const searchForProcess = async (body, loadingFn, setFn) => {
  loadingFn(true);

  return axios
    .post(`${config.SRD_API_URL}/search/processes`, JSON.stringify(body), {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => {
      if (result && result.data && result.data.hits) {
        setFn(result.data.hits);
      }
    })
    .finally(() => {
      loadingFn(false);
    });
};

export const fetchImagePreview = async (
  rmoId,
  setFn,
  originalMaterial = false,
  sourceName
) => {
  return axios
    .get(
      `${
        config.SCAS_API_URL
      }/ontology/image/svg/${rmoId}?originalMaterial=${originalMaterial}&${getImageUrlParams()}${
        sourceName ? `&sourceName=${sourceName}` : ""
      }`,
      {
        headers: {
          Accept: "image/*",
          "Content-Type": "image/svg+xml",
        },
      }
    )
    .then((result) => {
      setFn(result.data);
    })
    .catch(() => {
      setFn("");
    });
};

export const imagePreviewApi = async (
  rmoId,
  setFn,
  originalMaterial = false,
  sourceName
) => {
  return axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/image/svg/${rmoId}?originalMaterial=${originalMaterial}&${getImageUrlParams()}${
      sourceName ? `&sourceName=${sourceName}` : ""
    }`,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "image/*",
      },
    }
  );
};

export const getCompoundIdFromRmoId = async (sourceName, rmoId) => {
  return axios.get(
    `${config.SRD_API_URL}/compoundConceptsByRmoId?source%20name=${sourceName}&id=${rmoId}`
  );
};

export const getImageFromRmoId = async (
  rmoId,
  originalMaterial = false,
  sourceName
) => {
  return axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/image/svg/${rmoId}?originalMaterial=${originalMaterial}&${getImageUrlParams()}${
      sourceName ? `&sourceName=${sourceName}` : ""
    }`,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "image/svg+xml",
      },
    }
  );
};

export const getImageFromCompoundId = async (
  imageName,
  sourceName,
  backgroundColor = "transbg"
) => {
  return axios.get(
    `${
      config.SCAS_API_URL
    }/ontology/image/svg/${imageName}?sourceName=${sourceName}&originalMaterial=true&${getImageUrlParams(
      backgroundColor
    )}`,
    {
      headers: {
        Accept: "image/*",
        "Content-Type": "*/*",
      },
    }
  );
};
