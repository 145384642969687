import _ from "lodash";
import {
  EXPLORE_PAGE_FILTER_REQUESTED,
  EXPLORE_PAGE_FILTER_SUCCESS,
  EXPLORE_PAGE_GET_DATA_FAILED,
  EXPLORE_PAGE_GET_DATA_REQUESTED,
  EXPLORE_PAGE_GET_DATA_SUCCESS,
  EXPLORE_PAGE_RESET_SEARCH_REQUESTED,
  EXPLORE_PAGE_RESET_SEARCH_SUCCESS,
  EXPLORE_PAGE_SEARCH_FAILED,
  EXPLORE_PAGE_SEARCH_REQUESTED,
  EXPLORE_PAGE_SEARCH_SUCCESS,
} from "../../constants/ActionTypes";

const INIT_STATE = {
  isInitialized: false,
  loading: false,
  widgetIds: [],
  tableData: [],
  structuresTableData: [],
  currentIndex: 0,
};

const ExploreReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case EXPLORE_PAGE_SEARCH_REQUESTED: {
      return {
        ...state,
        loading: true,
        isInitialized: true,
        widgetIds: [],
        structuresTableData: [],
      };
    }

    case EXPLORE_PAGE_SEARCH_SUCCESS: {
      if (!_.isEmpty(action.payload?.structuresTableData || [])) {
        return {
          ...state,
          loading: false,
          widgetIds: action.payload?.widgetIds,
          widgetIdsNormalized: action.payload?.widgetIdsNormalized,
          structuresTableData: action.payload?.structuresTableData,
          tableData: [],
        };
      } else {
        return {
          ...state,
          loading: false,
          widgetIds: action.payload?.widgetIds,
          widgetIdsNormalized: action.payload?.widgetIdsNormalized,
          tableData: [],
        };
      }
    }

    case EXPLORE_PAGE_SEARCH_FAILED: {
      return {
        ...state,
        loading: false,
        tableData: [],
        widgetIds: [],
      };
    }

    case EXPLORE_PAGE_GET_DATA_REQUESTED: {
      return {
        ...state,
        tableData: [],
        currentIndex: 0,
        loading: true,
      };
    }

    case EXPLORE_PAGE_GET_DATA_SUCCESS: {
      const isLoading = action.payload?.total < state.currentIndex + 1;

      if (action.payload?.isStructure) {
        return {
          ...state,
          loading: false,
          currentIndex: state.currentIndex + 1,
          tableData: [
            ...state.tableData,
            {
              ...action.payload,
            },
          ],
          structuresTableData: _.uniq([
            ...state.structuresTableData,
            {
              ...action.payload,
            },
          ]),
        };
      } else {
        return {
          ...state,
          loading: isLoading,
          currentIndex: state.currentIndex + 1,
          tableData: [
            ...state.tableData,
            {
              ...action.payload,
            },
          ],
        };
      }
    }

    case EXPLORE_PAGE_GET_DATA_FAILED: {
      return {
        ...state,
        loading: false,
        currentIndex: state.currentIndex + 1,
      };
    }

    case EXPLORE_PAGE_FILTER_REQUESTED: {
      return {
        ...state,
        loading: true,
        currentIndex: 0,
      };
    }

    case EXPLORE_PAGE_FILTER_SUCCESS: {
      let widgetIdsNormalized = [];
      for (const [key, value] of Object.entries(state.widgetIds)) {
        let targetValue = value;

        // `Structures` and `Processes` keys have special format in API response.
        if (key === "structures" || key === "processes") {
          targetValue = _.get(value, "hits");
          _.forEach(targetValue, (item) => {
            widgetIdsNormalized.push({
              id: item,
              type: key,
            });
          });
        } else {
          // eslint-disable-next-line no-loop-func
          _.forEach(value, (item) => {
            widgetIdsNormalized.push({
              id: item,
              type: key,
            });
          });
        }
        // }
      }
      let targetKey = action.payload?.type;
      if (
        action.payload?.type === "structures.hits" ||
        action.payload?.type === "structures"
      ) {
        targetKey = "structures";
      }
      if (
        action.payload?.type === "processes.hits" ||
        action.payload?.type === "processes"
      ) {
        targetKey = "processes";
      }
      const filtered = _.filter(widgetIdsNormalized, (id) => {
        if (targetKey === "all") return true;

        return id?.type === targetKey;
      });

      const structuresTableData = _.filter(widgetIdsNormalized, (id) => {
        return id?.type === targetKey;
      });

      // let normalized = _.map(filtered || [], (row) => ({ ...row }));

      return {
        ...state,

        loading: false,
        widgetIdsNormalized: filtered,
        structuresTableData: _.map(
          structuresTableData || [],
          (data) => data?.id
        ),
        tableData: [],
      };
    }

    case EXPLORE_PAGE_RESET_SEARCH_REQUESTED: {
      return {
        ...state,
        isInitialized: false,
        loading: false,
        widgetIds: [],
        tableData: [],
        structuresTableData: [],
        currentIndex: 0,
      };
    }

    case EXPLORE_PAGE_RESET_SEARCH_SUCCESS: {
      return {
        ...state,
        isInitialized: false,
        loading: false,
        widgetIds: [],
        tableData: [],
        structuresTableData: [],
        currentIndex: 0,
      };
    }

    default:
      return state;
  }
};

export default ExploreReducer;
