/* eslint-disable react-hooks/exhaustive-deps */
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { getSystemWidget } from "../../../appRedux/services/SystemWidget";
import ImageCarousel from "../../../components/Registration/ImageCarousel";
import Slider from "react-slick";
import CompoundNotFound from "../../../assets/vendors/notfound.png";

import { RECENT_REGISTERED_COMPOUND } from "../../../constants/Config";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import "../style.css";
import { Link } from "react-router-dom";
import CardBox from "../../../components/CardBox";

const RecentRegisteredCompounds = () => {
  const [recentCompounds, setRecentCompounds] = useState([]);

  useEffect(() => {
    if (!(recentCompounds || []).length) {
      getSystemWidget(RECENT_REGISTERED_COMPOUND, setRecentCompounds, true);
    }
  }, []);

  const settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    marginLeft: 10,
    marginRight: 10,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      {recentCompounds.length ? (
        <CardBox
          styleName="ant-card ant-card-bordered gx-card-widget color-white gx-mr-3"
          heading={"Recently Registered Compounds"}
        >
          <Slider className="gx-slick-slider" {...settings}>
            {_.map(recentCompounds || [], (compound, index) => (
              <div className="gx-slider" key={index}>
                <div className="gx-d-flex gx-justify-content-around gx-align-items-center gx-mb-3">
                  {compound?.imageUrl && (compound?.imageUrl || []).length ? (
                    <ImageCarousel
                      structureImage={compound?.imageUrl || []}
                      width={100}
                      height={100}
                      className="compound-image-preview"
                    />
                  ) : (
                    <img
                      src={CompoundNotFound}
                      alt="compound-not-found"
                      width={100}
                      height={100}
                      className="compound-not-found homePage-carousel-img"
                    />
                  )}
                </div>
                <div
                  className="gx-mb-3 gx-align-items-center"
                  style={{ textAlign: "center" }}
                >
                  <h2>
                    <Link
                      target="_blank"
                      to={`/${compound?.dataSource}/compound/${compound?.compoundId}`}
                    >
                      {compound?.compoundId}
                    </Link>
                  </h2>
                  <p className="gx-text-grey">{compound?.registrar}</p>
                </div>
              </div>
            ))}
          </Slider>
        </CardBox>
      ) : null}
    </>
  );
};

export default RecentRegisteredCompounds;
