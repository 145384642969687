import React from "react";
import PT from "prop-types";
import { Table } from "antd";
import { renderCustomColumn, renderPredefinedColumn } from "../helper";

import "./style.css";
import _ from "lodash";

const TableContent = ({
  compositions,
  sourceName,
  pageType,
  safetySentenceTypes,
  safetySentenceImageMetadata,
  safetySentenceLanguageMap,
  currentRound,
  rows,
  allWidgets,
}) => {
  const filterInvalidData = _.filter(compositions, (item) => {
    if (Number(item?.value) === 0) return false;

    if (item?.compoundId || item?.instanceId) {
      return true;
    } else return false;
  });
  const compositionsWithIndex = filterInvalidData.map((item, index) => ({
    ...item,
    index,
  }));

  // const options = renderTableContentForm({
  //   compositions: compositionsWithIndex,
  //   pageType,
  //   sourceName,
  //   safetySentenceTypes,
  //   safetySentenceImageMetadata,
  //   safetySentenceLanguageMap,
  //   dataSources,
  //   onChangeCompoundId,
  //   onChangeForm,
  //   currentRound,
  //   namesAndSynonymsWidgetRows,
  // });

  const options = _.map(rows, (op) => {
    if (op?.type === "Predefined Column") {
      return renderPredefinedColumn({
        field: op?.field,
        pageType,
        compositions: compositionsWithIndex,
        currentRound,
        safetySentenceTypes,
        safetySentenceImageMetadata,
        safetySentenceLanguageMap,
        sourceName,
      });
    } else {
      return renderCustomColumn({
        widgetName: op?.widgetName,
        field: op?.field,
        allWidgets,
        pageType,
        sourceName,
        compositions: compositionsWithIndex,
        safetySentenceTypes,
        safetySentenceImageMetadata,
        safetySentenceLanguageMap,
        displayName: op?.displayName,
      });
    }
  });

  return (
    <div className="gx-ml-3 gx-mr-3 gx-mt-3 gx-pb-3">
      <Table
        className="gx-table-responsive register-process-table"
        dataSource={compositionsWithIndex}
        pagination={false}
        size="small"
        columns={options}
        rowClassName={(item, index) =>
          item?.processData ? "gx-text-warning" : ""
        }
      />
    </div>
  );
};

TableContent.defaultProps = {
  compositions: [],
  sourceName: "",
  pageType: "",
  safetySentenceTypes: [],
  safetySentenceImageMetadata: [],
  safetySentenceLanguageMap: [],
};

TableContent.propTypes = {
  compositions: PT.arrayOf(PT.shape()),
  sourceName: PT.string,
  pageType: PT.string,
  safetySentenceTypes: PT.arrayOf(PT.shape),
  safetySentenceImageMetadata: PT.arrayOf(PT.shape),
  safetySentenceLanguageMap: PT.arrayOf(PT.shape),
};

export default TableContent;
