import {
  all,
  call,
  fork,
  put,
  takeEvery,
  takeLatest,
} from "redux-saga/effects";
import {
  FETCH_DOCUMENT_DETAILS_FAILED,
  FETCH_DOCUMENT_DETAILS_REQUESTED,
  FETCH_DOCUMENT_DETAILS_RESET,
  FETCH_DOCUMENT_DETAILS_SUCCESS,
  GET_DOCUMENTS_REQUESTED,
  GET_DOCUMENTS_SUCCESS,
  FETCH_DOCUMENT_DETAILS_RESET_SUCCESS,
  FETCH_COLLECTION_REQUESTED,
  FETCH_COLLECTION_SUCCESS,
  FETCH_COLLECTION_FAILED,
  FETCH_COLLECTION_RESET,
  FETCH_COLLECTION_RESET_SUCCESS,
} from "../../constants/ActionTypes";
import axios from "axios";
import { config } from "../../constants/Config";

/**
 * Send Get Documents from widget
 * @param {String} entityId
 * @param {String} sourceName source Name
 */
const getDocumentRequest = async ({ entityId, sourceName }) => {
  return axios.get(
    `${config.SRD_API_URL}/repository/documents?entityId=${entityId}&source%20name=${sourceName}`
  );
};

export const fetchDocumentApi = async ({ documentId }) => {
  return axios.get(
    `${config.SRD_API_URL}/repository/document?documentId=${documentId}`
  );
};

function* fetchDocuments(action) {
  try {
    const result = yield call(getDocumentRequest, action.payload);
    if (result.data) {
      yield put({
        type: GET_DOCUMENTS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {}
}

function* getDocumentDetailApi(action) {
  try {
    const result = yield call(fetchDocumentApi, action.payload);

    if (result && result.data) {
      yield put({
        type: FETCH_DOCUMENT_DETAILS_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_DOCUMENT_DETAILS_FAILED,
    });
  }
}

function* getCollectionDetailApi(action) {
  try {
    const result = yield call(fetchDocumentApi, action.payload);

    if (result && result.data) {
      yield put({
        type: FETCH_COLLECTION_SUCCESS,
        payload: result.data,
      });
    }
  } catch (error) {
    yield put({
      type: FETCH_COLLECTION_FAILED,
    });
  }
}

function* resetDocumentSearch(action) {
  yield put({
    type: FETCH_DOCUMENT_DETAILS_RESET_SUCCESS,
    payload: action.payload,
  });
}

function* resetCollectionSearchApi(action) {
  yield put({
    type: FETCH_COLLECTION_RESET_SUCCESS,
    payload: action.payload,
  });
}

export function* getDocuments() {
  yield takeLatest(GET_DOCUMENTS_REQUESTED, fetchDocuments);
}

export function* fetchDocumentDetail() {
  yield takeEvery(FETCH_DOCUMENT_DETAILS_REQUESTED, getDocumentDetailApi);
}

export function* fetchCollectionDetail() {
  yield takeEvery(FETCH_COLLECTION_REQUESTED, getCollectionDetailApi);
}

export function* resetDocumentDetailSearch() {
  yield takeLatest(FETCH_DOCUMENT_DETAILS_RESET, resetDocumentSearch);
}

export function* resetCollectionSearch() {
  yield takeLatest(FETCH_COLLECTION_RESET, resetCollectionSearchApi);
}

export default function* rootSaga() {
  yield all([
    fork(getDocuments),
    fork(fetchDocumentDetail),
    fork(resetDocumentDetailSearch),
    fork(fetchCollectionDetail),
    fork(resetCollectionSearch),
  ]);
}
