import { SAVE_USER_DATA_SUCCESS } from "../../constants/ActionTypes";

const INIT_STATE = {
  registerPageEditor: null,
  registerProcessPageEditor: null,
  searchPageEditor: null,
  searchPageResult: [],
};

const CurrentUserDataReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SAVE_USER_DATA_SUCCESS: {
      return {
        ...state,
        [action.payload.type]: action.payload.data,
      };
    }
    default:
      return state;
  }
};

export default CurrentUserDataReducer;
