/* eslint-disable no-unused-vars */
import { UploadOutlined } from "@ant-design/icons";
import { Button, Form, Select, Upload } from "antd";
import React, { useState } from "react";
import { mockRequest } from "../../../util/api";
import {
  DOCUMENT_UPLOAD_OPTIONS,
  TYPE_OPTIONS,
} from "../../../constants/Config";

const SelectFile = ({
  onChange,
  options,
  selectedOption,
  setSelectedOption,
  documentUploadtype,
}) => {
  const [selectedFileList, setSelectedFileList] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const onChangeFile = (info) => {
    switch (info.file.status) {
      case "uploading":
        setSelectedFileList([info.file]);
        break;
      case "done":
        setSelectedFile(info.file);
        setSelectedFileList([info.file]);
        onChange(info.file, selectedOption);
        break;
      default:
        // error or removed
        setSelectedFile(null);
        setSelectedFileList([]);
    }
  };

  return (
    <>
      <div className="gx-d-flex">
        <h3 className="gx-ml-2 gx-mb-4">Upload Data from File.</h3>
      </div>
      <div className="gx-d-flex">
        <Form className="gx-ml-4">
          {documentUploadtype !== DOCUMENT_UPLOAD_OPTIONS.STRUCTURES && (
            <Form.Item label="Select Type">
              <Select
                options={options.map((option) => ({
                  label: option,
                  value: option,
                }))}
                value={selectedOption}
                placeholder="Select Type"
                onChange={setSelectedOption}
              ></Select>
            </Form.Item>
          )}
          <Form.Item
            label={`Upload ${
              documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES
                ? "File (JSON, SDF)"
                : selectedOption
            }`}
            name="upload"
          >
            <Upload
              fileList={selectedFileList}
              accept={
                documentUploadtype === DOCUMENT_UPLOAD_OPTIONS.STRUCTURES ||
                selectedOption === TYPE_OPTIONS.SDF
                  ? ".sdf, .smiles"
                  : selectedOption === options[0]
                  ? ".xlsx, .xls, .csv, .json"
                  : "application/JSON;charset=utf-8"
              }
              className="gx-ml-1"
              name="file"
              type="file"
              onChange={onChangeFile}
              customRequest={mockRequest}
            >
              <Button icon={<UploadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>
        </Form>
      </div>
    </>
  );
};

SelectFile.defaultProps = {};

SelectFile.propTypes = {};

export default SelectFile;
