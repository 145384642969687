/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Col, Divider, Form, Input, Select, Switch } from "antd";
import PT from "prop-types";
import _ from "lodash";

const WidgetForm = ({
  onChangeValue,
  form,
  roles,
  isColumnSetting = false,
  availableWidgets = [],
}) => {
  const onChangeRole = (val, e) => {
    const currentRole = _.isObject(form?.isEditable) ? form?.isEditable : {};

    let role = {};

    roles.forEach((item) => {
      const cur = _.get(currentRole, item?.id) ? currentRole[item?.id] : false;
      role[item?.id] = cur;
    });
    role[val] = e;

    onChangeValue("isEditable", role);
  };

  const onChangeAccessRole = (val, e) => {
    const currentAccessRole = _.isObject(form?.isAccessible)
      ? form?.isAccessible
      : {};

    let accessRole = {};

    roles.forEach((item) => {
      const cur = _.get(currentAccessRole, item?.id)
        ? currentAccessRole[item?.id]
        : false;
      accessRole[item?.id] = cur;
    });
    accessRole[val] = e;

    onChangeValue("isAccessible", accessRole);
  };

  const widgets = _.filter(
    availableWidgets,
    (item) => item?.field !== "CompositionTable"
  );

  const foundWidget =
    _.find(availableWidgets, { value: form?.widgetName }) || {};

  const rows = _.filter(
    _.get(foundWidget, "widget.rows") || [],
    (item) => item?.type === "EDITABLE"
  );

  const rowsFields = _.map(rows, (row) => ({
    label: row?.field,
    value: row?.name,
  }));

  const rowFieldsUniq = _.uniqBy(rowsFields, "value");

  return (
    <>
      <Col>
        <Form.Item
          label="Widget Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Select
            value={form?.widgetName}
            options={widgets}
            showSearch
            onChange={(e) => onChangeValue("widgetName", e)}
          />
        </Form.Item>
      </Col>

      <Col>
        <Form.Item
          label="Display Name"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
        >
          <Input
            value={form?.displayName}
            onChange={(e) => onChangeValue("displayName", e.target.value)}
          />
        </Form.Item>
      </Col>

      {form?.widgetName ? (
        <Col>
          <>
            {_.isEmpty(rowsFields) ? (
              <span className="gx-text-warning">
                No fields available for this widget. Please select different
                widget.
              </span>
            ) : (
              <Form.Item
                label="Target Row"
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Select
                  value={form?.row}
                  options={rowFieldsUniq}
                  showSearch
                  onChange={(e) => onChangeValue("field", e)}
                />
              </Form.Item>
            )}
          </>
        </Col>
      ) : null}

      {!isColumnSetting ? (
        <Col>
          <Form.Item
            label="Tooltip"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Input
              value={form?.info}
              onChange={(e) => onChangeValue("info", e.target.value)}
            />
          </Form.Item>
        </Col>
      ) : null}

      {!isColumnSetting ? (
        <div>
          <p className="gx-mb-3">Edit Roles:</p>
          <Col className="gx-d-flex">
            {roles.map((role) => (
              <Form.Item
                className="gx-mr-1"
                id={role.id}
                label={role.name}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
              >
                <Switch
                  checked={_.get(form?.isEditable, role?.id) || false}
                  onChange={(e) => onChangeRole(role.id, e)}
                />
              </Form.Item>
            ))}
          </Col>
        </div>
      ) : null}

      <Divider />

      <div>
        <p className="gx-mb-3">Access Roles:</p>
        <Col className="gx-d-flex gx-ml-3">
          {roles.map((role) => (
            <Form.Item
              className="gx-mr-1"
              id={role.id}
              label={role.name}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Switch
                checked={_.get(form?.isAccessible, role?.id) || false}
                onChange={(e) => onChangeAccessRole(role.id, e)}
              />
            </Form.Item>
          ))}
        </Col>
      </div>

      {!isColumnSetting ? (
        <Col className="gx-d-flex">
          <Form.Item
            label="Is Copiable"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
          >
            <Switch
              checked={_.get(form, "isCopiable") || false}
              onChange={(e) => onChangeValue("isCopiable", e)}
            />
          </Form.Item>
        </Col>
      ) : null}
    </>
  );
};

WidgetForm.defaultProps = {
  form: {},
};

WidgetForm.propTypes = {
  onChangeValue: PT.func.isRequired,
  form: PT.shape,
};

export default WidgetForm;
