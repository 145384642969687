import React, { useEffect, useRef, useState } from "react";
import CircularProgress from "components/CircularProgress";
import { Button, Form, Input, Popconfirm, Table, Upload } from "antd";
import { mockRequest } from "../../../../util/api";
import { DeleteTwoTone, UploadOutlined } from "@ant-design/icons";
import _ from "lodash";
import {
  deleteDocument,
  downloadDocument,
  uploadDocument,
} from "../../../../appRedux/services/Document";
import { NotificationManager } from "react-notifications";
import { EXCEL_TEMPLATES_COLLECTION } from "../../../../constants/Config";
import { getRole } from "../../../../util/auth";

const ExcelSettingsForm = ({
  documents = [],
  sourceName,
  onUpdateDocumentations,
}) => {
  const [templateForm] = Form.useForm();
  const templateFormRef = useRef();

  const [loading, setLoading] = useState(false);
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  const onRemoveDocument = (id, source) => {
    setLoading(true);
    deleteDocument(
      id,
      source,
      () => {
        NotificationManager.success("Document has been deleted.");
        onUpdateDocumentations();
        setLoading(false);
      },
      (e) => {
        NotificationManager.error(e);
        setLoading(false);
      }
    );
  };

  const renderAction = (p, { documentId, source }) => {
    return (
      <Popconfirm
        title="Are you sure to remove this sentence?"
        onConfirm={() => onRemoveDocument(documentId, source)}
        okText="Yes"
        cancelText="No"
      >
        <DeleteTwoTone className="gx-pointer" twoToneColor="#f44336" />
      </Popconfirm>
    );
  };

  const uploadTemplate = () => {
    const topic = templateFormRef.current.getFieldValue("topic");
    const name = templateFormRef.current.getFieldValue("name");

    const searchSubjects = [{ key: "topic", value: topic }];

    setLoading(true);
    uploadDocument(
      null,
      sourceName,
      acceptedFiles,
      {
        entityIds: [],
        collection: EXCEL_TEMPLATES_COLLECTION,
        searchSubjects,
        mimeType: acceptedFiles[0]?.type,
        description: "template",
        name: name,
        owners: [getRole()],
      },
      () => {
        NotificationManager.success("Document has been added");
        onUpdateDocumentations();
        setLoading(false);
      },
      (e) => {
        NotificationManager.error(e);
        setLoading(false);
      }
    );
  };

  return (
    <div className="gx-mt-3 gx-ml-4">
      {loading ? (
        <CircularProgress className="gx-loader-400 loader" />
      ) : (
        <>
          <Form
            form={templateForm}
            ref={templateFormRef}
            name="excelTemplate"
            className="gx-mb-4"
          >
            <Form.Item
              label="Name:"
              name="name"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Input placeholder="Enter Name" />
            </Form.Item>

            <Form.Item
              label="Upload File:"
              name="file"
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
            >
              <Upload
                name="file"
                accept=".xlsx,.xls,.xlsm"
                type="file"
                customRequest={mockRequest}
                onChange={(info) => {
                  if (info.file.status === "done") {
                    setAcceptedFiles(
                      _.map(info.fileList, (item) => item?.originFileObj)
                    );
                  } else {
                    setAcceptedFiles([]);
                  }
                }}
              >
                <Button icon={<UploadOutlined />}>Click to Upload</Button>
              </Upload>
            </Form.Item>

            <Form.Item labelCol={{ span: 24 }} wrapperCol={{ span: 24 }}>
              <Button
                type="primary"
                size="small"
                onClick={() => uploadTemplate()}
              >
                Upload
              </Button>
            </Form.Item>
          </Form>
          <Table
            dataSource={documents}
            pagination={false}
            size="small"
            bordered
          >
            <Table.Column
              title="Display Name"
              key="name"
              dataIndex="name"
            ></Table.Column>
            <Table.Column
              title="File name"
              key="fileName"
              dataIndex="fileName"
              render={(p, file) => {
                const { fileName, documentId, source } = file;
                return (
                  <span
                    className="gx-text-primary gx-pointer"
                    onClick={() => {
                      downloadDocument(documentId, source, fileName);
                    }}
                  >
                    {fileName}
                  </span>
                );
              }}
            ></Table.Column>
            <Table.Column title="Action" render={renderAction}></Table.Column>
          </Table>
        </>
      )}
    </div>
  );
};

export default ExcelSettingsForm;
