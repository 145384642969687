import _ from "lodash";
import React from "react";
import { useSelector } from "react-redux";

import PT from "prop-types";

const MolecularWeight = ({ option, pageType, currentRound = 4 }) => {
  const pageId =
    _.get(option, "instanceId") ||
    _.get(option, "compoundId") ||
    _.get(option, "id");

  const { [pageId]: data = {} } = useSelector(({ lookup }) => lookup);

  let mass = _.get(data, "molecularWeight");

  try {
    mass = Number(mass).toFixed(currentRound);
  } catch (error) {}

  return <div>{mass}</div>;
};

MolecularWeight.defaultProps = {
  option: {},
  pageType: "",
  sourceName: "",
  processData: false,
};

MolecularWeight.propTypes = {
  option: PT.shape(),
  pageType: PT.string,
  sourceName: PT.string,
  processData: PT.bool,
};

export default MolecularWeight;
