import { all, fork, put, takeLatest } from "redux-saga/effects";

import {
  SET_CURRENT_WIDGETS_REQUESTED,
  SET_CURRENT_WIDGETS_SUCCESS,
  SET_DOCUMENT_ROLES_REQUESTED,
  SET_DOCUMENT_ROLES_SUCCESS,
  SET_ROLES_REQUESTED,
  SET_ROLES_SUCCESS,
  SET_VIEW_REQUESTED,
  SET_VIEW_SUCCESS,
} from "../../constants/ActionTypes";

function* fetchRoles(action) {
  const roles = action.payload;

  if ((roles || []).length) {
    localStorage.setItem("role_id", roles[0]?.id);
    localStorage.setItem("role_name", roles[0]?.name);
  }

  yield put({
    type: SET_ROLES_SUCCESS,
    payload: roles,
  });
}

function* fetchDocumentRoles(action) {
  const roles = action.payload;

  if ((roles || []).length) {
    localStorage.setItem("document_role_id", roles[0]?.id);
    localStorage.setItem("document_role_name", roles[0]?.name);
  }

  yield put({
    type: SET_DOCUMENT_ROLES_SUCCESS,
    payload: roles,
  });
}

function* fetchCurrentWidgets(action) {
  yield put({
    type: SET_CURRENT_WIDGETS_SUCCESS,
    payload: action.payload,
  });
}

function* fetchView(action) {
  yield put({
    type: SET_VIEW_SUCCESS,
    payload: action.payload,
  });
}

export function* setRoles() {
  yield takeLatest(SET_ROLES_REQUESTED, fetchRoles);
}

export function* setDocumentRoles() {
  yield takeLatest(SET_DOCUMENT_ROLES_REQUESTED, fetchDocumentRoles);
}

export function* setCurrentWidgets() {
  yield takeLatest(SET_CURRENT_WIDGETS_REQUESTED, fetchCurrentWidgets);
}

export function* setView() {
  yield takeLatest(SET_VIEW_REQUESTED, fetchView);
}

export default function* rootSaga() {
  yield all([
    fork(setRoles),
    fork(setCurrentWidgets),
    fork(setView),
    fork(setDocumentRoles),
  ]);
}
