import { Card, Form, Input, Select } from "antd";
import Meta from "antd/lib/card/Meta";
import React from "react";
import {
  DATA_SEARCH_OPERATORS,
  JOIN_TYPE_OPERATORS,
} from "../../../../../constants/Config";
import { DeleteTwoTone } from "@ant-design/icons";

const FormItem = ({
  id,
  key,
  widgetName,
  field,
  name,
  handleFormItemChange,
  handleRemoveFormItem,
  isLastItem,
  ref,
  advancedSearchJoinType,
  setAdvancedSearchJoinType,
  op,
}) => {
  const isDefinedOrUndefined = op === "IS_DEFINED" || op === "IS_NULL";

  return (
    <Card key={key} size="small">
      <Card.Grid
        style={{
          width: "30%",
        }}
      >
        <Meta
          avatar={null}
          title={widgetName}
          description={field}
          style={{
            fontSize: "12px",
          }}
        />
      </Card.Grid>
      <Card.Grid
        style={{
          width: "70%",
        }}
      >
        <Form size="small" layout="inline" ref={ref}>
          <Form.Item name="op">
            <Select
              options={DATA_SEARCH_OPERATORS}
              defaultValue={"CONTAINS"}
              dropdownMatchSelectWidth={false}
              onChange={(e) => handleFormItemChange(id, "op", e)}
            />
          </Form.Item>
          {isDefinedOrUndefined ? null : (
            <Form.Item
              name="value"
              required
              rules={[
                {
                  required: true,
                  message: "Please select value",
                },
              ]}
            >
              <Input
                placeholder="value"
                value={name}
                onChange={(e) =>
                  handleFormItemChange(id, "value", e.target.value)
                }
              />
            </Form.Item>
          )}

          {isLastItem ? null : (
            <Form.Item>
              <Select
                options={JOIN_TYPE_OPERATORS}
                defaultValue={"AND"}
                value={advancedSearchJoinType}
                dropdownMatchSelectWidth={false}
                onChange={(e) => setAdvancedSearchJoinType(e)}
              />
            </Form.Item>
          )}

          <Form.Item>
            <DeleteTwoTone
              twoToneColor="#ba141a"
              className="gx-pointer"
              onClick={() => handleRemoveFormItem(id)}
            />
          </Form.Item>
        </Form>
      </Card.Grid>
    </Card>
  );
};

export default FormItem;
